import React, { FC } from "react";
import {
  AuthPageWrapper,
  AuthLogoWrapper,
  AuthFormContainer,
  AuthFormCard,
  AuthLogo,
  AuthFooter,
  AuthParagraph,
  AuthDivider
} from "pages/Auth/styled";

interface AuthContainerProps {
  header: React.ReactNode;
  emailComponent?: React.ReactNode;
  firstLastNameComponent?: React.ReactNode;
  linkComponent: React.ReactNode;
  resetPasswordComponent?: React.ReactNode;
  passwordConfirm?: React.ReactNode;
  forgotPassword?: React.ReactNode;
  signInButton?: React.ReactNode;
  termsOfPolicy?: React.ReactNode;
  signButtonHelpText?: React.ReactNode;
  haveAccountText?: string;
}

const AuthContainer: FC<AuthContainerProps> = (props) => {
  const {
    header,
    emailComponent,
    firstLastNameComponent,
    resetPasswordComponent,
    passwordConfirm,
    forgotPassword,
    signInButton,
    termsOfPolicy,
    signButtonHelpText,
    haveAccountText,
    linkComponent
  } = props;

  return (
    <AuthPageWrapper>
      <AuthFormContainer>
        <AuthFormCard>
          <AuthLogoWrapper to="/auth">
            <AuthLogo src="/logo-square.svg" alt="logo" />
          </AuthLogoWrapper>
          <>
            <AuthDivider>{header}</AuthDivider>
            {firstLastNameComponent}
            {emailComponent}
            {resetPasswordComponent}
            {passwordConfirm}
            {forgotPassword}
            {signButtonHelpText}
            {signInButton}
            <AuthParagraph>{termsOfPolicy}</AuthParagraph>
          </>
        </AuthFormCard>
        <AuthFooter>
          <AuthParagraph>
            {haveAccountText}
            <div>
              {linkComponent}
            </div>
          </AuthParagraph>
        </AuthFooter>
      </AuthFormContainer>
    </AuthPageWrapper>
  );
};

export default AuthContainer;
