import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BoardInterface } from "lib/types/boardTypes";
import { EntityId } from "lib/types/entity";
import { WorkspaceInterface } from "lib/types/types";

interface InitialStateType {
  workspaces: WorkspaceInterface[];
  loading: boolean | null;
}

const initialState: InitialStateType = {
  workspaces: [],
  loading: null
};

export const workspaceSlice = createSlice({
  name: "workspaces",
  initialState,
  reducers: {
    setWorkspacesLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setWorkspaces: (state, action: PayloadAction<WorkspaceInterface[]>) => {
      state.workspaces = action.payload;
    },
    addWorkspace: (state, action: PayloadAction<WorkspaceInterface>) => {
      state.workspaces.push(action.payload);
    },
    addBoard: (state, action: PayloadAction<{ board: BoardInterface, workspaceId: EntityId }>) => {
      const workspace = state.workspaces.find(item => item.id === action.payload.workspaceId);

      if (workspace) {
        workspace.boards.push(action.payload.board);
      }
    },
    changeName: (state, action: PayloadAction<{ id: EntityId, newName: string }>) => {
      const workspace = state.workspaces.find(item => item.id === action.payload.id);
      if (workspace) {
        workspace.name = action.payload.newName;
      }
    },
    updateWorkspace: (state, action: PayloadAction<{ id: EntityId, workspace: WorkspaceInterface }>) => {
      const index = state.workspaces.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.workspaces[index] = action.payload.workspace;
      } else {
        state.workspaces.push(action.payload.workspace);
      }
    },
    updateWorkspaceBoard: (state, action: PayloadAction<{ workspaceId: EntityId, boardId: EntityId, newBoard: BoardInterface }>) => {
      const workspace = state.workspaces.find(item => item.id === action.payload.workspaceId);
      if (workspace) {
        const index = workspace.boards.findIndex(board => board.id === action.payload.boardId);
        if (index !== -1) {
          workspace.boards[index] = action.payload.newBoard;
        }
      }
    },
    performWorkspaceOptimisticUpdate: (state, action: PayloadAction<{ id?: EntityId; update: Partial<WorkspaceInterface> }>) => {
      const workspaceIndex = state.workspaces.findIndex(item => item.id === action.payload.id);
      if (workspaceIndex !== -1) {
        const data = action.payload.update;
        for (const [code, value] of Object.entries(data)) {
          state.workspaces[workspaceIndex][code as keyof WorkspaceInterface] = value as never;
        }
      }
    },
    performWorkspaceBoardOptimisticUpdate: (state, action: PayloadAction<{ workspaceId?: EntityId, boardId?: EntityId, update: Partial<BoardInterface> }>) => {
      const workspaceIndex = state.workspaces.find(item => item.id === action.payload.workspaceId);
      if (workspaceIndex) {
        const boardIndex = workspaceIndex.boards.findIndex(board => board.id === action.payload.boardId);
        if (boardIndex !== -1) {
          const data = action.payload.update;
          for (const [code, value] of Object.entries(data)) {
            workspaceIndex.boards[boardIndex][code as keyof BoardInterface] = value as never;
          }
        }
      }
    },
    removeWorkspaceBoard: (state, action: PayloadAction<{ workspaceId: EntityId, boardId: EntityId }>) => {
      const workspace = state.workspaces.find(item => item.id === action.payload.workspaceId);
      if (workspace) {
        workspace.boards = workspace.boards.filter(board => board.id !== action.payload.boardId);
      }
    },
    deleteWorkspace: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.workspaces = state.workspaces.filter(workspace => workspace.id !== action.payload.id);
    }
  },
});

export const {
  setWorkspaces,
  addWorkspace,
  addBoard,
  changeName,
  updateWorkspace,
  updateWorkspaceBoard,
  removeWorkspaceBoard,
  deleteWorkspace,
  setWorkspacesLoading,
  performWorkspaceOptimisticUpdate,
  performWorkspaceBoardOptimisticUpdate,
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
