import { FC } from "react";
import { Tooltip } from "antd";
import { UserInterface } from "lib/types/types";
import { getUserInitials } from "lib/helpers/userUtils";
import { getUserFullName } from "lib/helpers/userUtils";
import {
  SingleUserContainer,
  SingleUserAvatar,
  SingleUserDetailsContainer,
  SingleUserNameContainer,
  SingleUserEmailContainer,
  SingleUserAvatarContainer
} from "components/ui/SingleUser/styled";

interface SingleUserProps {
  user: UserInterface | null;
  type?: "avatar" | "name" | "full";
  size?: "small" | "medium" | "large";
  disableTooltip?: boolean;
  fullWidth?: boolean;
}

const SingleUser: FC<SingleUserProps> = (props) => {

  const { user, disableTooltip, fullWidth } = props;
  const backgroundColor = user?.color || null;
  const imageUrl = user?.picture?.url || null;

  let avatarSize;
  let avatarFontSize;
  const size = props?.size || "medium";
  if (size === "large") {
    avatarSize = 36;
    avatarFontSize = 14;
  } else if (size === "small") {
    avatarSize = 28;
    avatarFontSize = 12;
  } else {
    avatarSize = 32;
    avatarFontSize = 14;
  }

  let nameFontWidth;
  const type = props?.type || "avatar";
  if (type === "full") {
    nameFontWidth = 600;
  } else {
    nameFontWidth = 400;
  }

  return (
    <SingleUserContainer size={avatarSize} fullWidth={fullWidth}>
      <Tooltip title={(type !== "avatar" || disableTooltip) ? "" : getUserFullName(user)}>
        <SingleUserAvatarContainer>
          <SingleUserAvatar
            size={avatarSize}
            fontSize={avatarFontSize}
            backgroundColor={backgroundColor}
            imageUrl={imageUrl}
          >
            {!user?.picture?.url &&
              getUserInitials(user)
            }
          </SingleUserAvatar>
        </SingleUserAvatarContainer>
      </Tooltip>
      <SingleUserDetailsContainer>
        {(type === "name" || type === "full") &&
          <SingleUserNameContainer fontWeight={nameFontWidth}>
            {getUserFullName(user)}
          </SingleUserNameContainer>
        }
        {(type === "full") &&
          <SingleUserEmailContainer>
            {user?.email}
          </SingleUserEmailContainer>
        }
      </SingleUserDetailsContainer>
    </SingleUserContainer>
  );
};

export default SingleUser;
