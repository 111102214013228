import { batch } from "react-redux";

import { useAppDispatch } from "app/store";
import { JSendResponse } from "lib/types/jsend";
import { performApiRequestWithStore } from "lib/API/http-redux";
import { BoardInterface, BoardInterfaceExtended } from "lib/types/boardTypes";
import { performWorkspaceBoardOptimisticUpdate, updateWorkspaceBoard } from "app/slices/workspaceSlice";
import { performBoardOptimisticUpdate, setBoard } from "app/slices/currentBoardSlice";
import { enqueueTransaction, dequeueTransaction } from "app/slices/progressSlice";

export interface BoardPerformRequestHookProps {
  updateDetailed?: boolean;
  board?: BoardInterface | BoardInterfaceExtended | null;
}

const useBoardPerformRequest = (props: BoardPerformRequestHookProps) => {
  const { board, updateDetailed } = props;
  const dispatch = useAppDispatch();

  const performRequest = (requestPayload: Partial<BoardInterface>, requestFn: () => Promise<JSendResponse<any>>) => {
    if (!board) {
      return undefined;
    }

    const onMutate = () => {
      batch(() => {
        dispatch(enqueueTransaction());
        dispatch(performWorkspaceBoardOptimisticUpdate({
          workspaceId: (board as BoardInterfaceExtended)?.workspace?.id,
          boardId: board?.id,
          update: requestPayload,
        }));
        if (updateDetailed) {
          dispatch(performBoardOptimisticUpdate({
            id: board?.id,
            update: requestPayload,
          }));
        }
      });
    };

    const onError = () => {
      batch(() => {
        dispatch(updateWorkspaceBoard({
          workspaceId: (board as BoardInterfaceExtended).workspace.id,
          boardId: board?.id,
          newBoard: board as BoardInterface,
        }));
        if (updateDetailed) {
          dispatch(setBoard(board as BoardInterfaceExtended));
        }
      });
    };

    const onSettled = () => {
      dispatch(dequeueTransaction());
    };

    onMutate();

    performApiRequestWithStore(requestFn, onError, onSettled);
  };

  return performRequest;
};

export default useBoardPerformRequest;
