import { orderBy } from "lodash";
import { useAppSelector } from "app/store";
import AddFileContainerModal from "components/blocks/AddFileContainerModal";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import useGetFileContainersCollection from "lib/customHooks/useGetFileContainersCollection";
import useWSCreateFileContainer from "lib/customHooks/websocketFileContainer/useWSCreateFileContainer";
import useWSDeleteFileContainer from "lib/customHooks/websocketFileContainer/useWSDeleteFileContainer";
import useWSUpdateFileContainer from "lib/customHooks/websocketFileContainer/useWSUpdateFileContainer";
import { FC, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { PageContainer, PageContent } from "styles/common";
import PageLoader from "components/blocks/PageLoader";
import { SecondaryBarContainer } from "pages/Board/SecondaryBar/styled";
import { ProjectFileContainersGrid } from "pages/Project/ProjectOverview/ProjectFileContainers/styled";
import FileContainerCard from "components/blocks/FileContainerCard";
import ButtonIcon from "components/ui/ButtonIcon";
import NotFoundSection from "components/blocks/NotFoundSection";
import { FileInterface, MemberRoles } from "lib/types/types";
import FileContainerService from "services/FileContainerService";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import UploadSvg from "components/icons/resizable/upload";


const BoardFiles: FC = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const boardFiles = useAppSelector(state => state.fileContainers.collection);
  const { board } = useBoardContext() as BoardContextInterface;
  const { isLoading } = useGetFileContainersCollection({ board: board.id });
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const boardFilesSortedList = orderBy(boardFiles, file => file.updated_at, "desc");

  useWSCreateFileContainer();
  useWSDeleteFileContainer();
  useWSUpdateFileContainer();

  const uploadHandler = async (file: FileInterface) => {
    return await FileContainerService.create({
      file: file,
      board: board.id
    });
  };

  return (
    <>
      <SecondaryBarContainer>
        {allowedToEdit &&
          <ButtonIcon
            text="Upload file"
            icon={<UploadSvg />}
            onClick={() => setIsAddModalVisible(true)}
          />
        }
      </SecondaryBarContainer>
      <Scrollbars autoHide>
        <PageContainer>
          {isLoading && <PageLoader />}
          {!isLoading &&
            <PageContent style={{ width: 800 }}>
              {isAddModalVisible &&
                <AddFileContainerModal
                  visible={isAddModalVisible}
                  onClose={() => setIsAddModalVisible(false)}
                  uploadFn={uploadHandler}
                />
              }
              {boardFiles.length > 0 &&
                <ProjectFileContainersGrid>
                  {boardFilesSortedList.map(file => (
                    <FileContainerCard key={file.id} fileContainer={file} />
                  ))}
                </ProjectFileContainersGrid>
              }
              {!boardFiles.length &&
                <NotFoundSection text="No files" />
              }
            </PageContent>
          }
        </PageContainer>
      </Scrollbars>
    </>
  );
};

export default BoardFiles;
