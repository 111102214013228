import { getKeyByValueFromEnum } from "lib/helpers/helpers";
import { BoardAutomationEvents, BoardAutomationInterface } from "lib/types/boardTypes";
import { EntityId } from "lib/types/entity";
import { isArray } from "lodash";
import { Action, TFormType, Trigger } from "./types";

export const getEventType = (event: Trigger | Action): BoardAutomationEvents => {
  if (event === Trigger.toThisBoard) {
    return BoardAutomationEvents.Create;
  } else {
    return BoardAutomationEvents.Update;
  }
};

export const checkIfSaveEnabled = (
  triggerProperty: Trigger,
  actionProperty: Action,
  triggerValue?: string | number,
  actionValue?: string | number | string[],
) => {
  let isValidTrigger = false;
  let isValidAction = false;

  if ([Trigger.toThisBoard, Trigger.completed, Trigger.comments].includes(triggerProperty)) {
    isValidTrigger = true;
  } else if (
    [Trigger.assignees, Trigger.dueDate, Trigger.status].includes(triggerProperty) &&
    triggerValue
  ) {
    isValidTrigger = true;
  }

  if (
    [Action.completed, Action.assignees, Action.comments, Action.status].includes(actionProperty) &&
    actionValue
  ) {
    isValidAction = true;
  } else if (
    actionProperty === Action.collaborators &&
    isArray(actionValue) &&
    actionValue.length !== 0
  ) {
    isValidAction = true;
  } else if (actionProperty === Action.dueDate) {
    isValidAction = true;
  }

  return isValidTrigger && isValidAction;
};

export const prepareUpdateValue = (automation: BoardAutomationInterface): string => {
  let updateValue = automation.updateValue;

  if (
    // @ts-ignore
    Action[automation.updateProperty] === Action.collaborators ||
    // @ts-ignore
    Action[automation.updateProperty] === Action.assignees
  ) {
    updateValue = JSON.parse(updateValue);
  }
  return updateValue;
};

export const prepareAutomationData = (
  values: TFormType,
  triggerProperty: Trigger,
  actionProperty: Action,
  id?: EntityId
): Partial<BoardAutomationInterface> => {
  let updateValue = values.updateValue;

  if ([Action.collaborators, Action.assignees].includes(actionProperty)) {
    updateValue = JSON.stringify(updateValue);
  }

  if (id) {
    return {
      ...values,
      updateValue,
      id,
      event: getEventType(triggerProperty),
      triggerProperty: getKeyByValueFromEnum(Trigger, triggerProperty),
      updateProperty: getKeyByValueFromEnum(Action, actionProperty),
    };
  }

  return {
    ...values,
    updateValue,
    event: getEventType(triggerProperty),
    triggerProperty: getKeyByValueFromEnum(Trigger, triggerProperty),
    updateProperty: getKeyByValueFromEnum(Action, actionProperty),
  };
};
