import { FC, useCallback, useEffect, useRef } from "react";
import { createReactEditorJS } from "react-editor-js";
import { EditorJsCore, EditorJsSaveFormat } from "components/blocks/EditorJS/lib";
import { Form } from "antd";
import { EditorJsTaskDescriptionConfig, EditorJSInitialState } from "components/blocks/EditorJS/tools";
import { FormComponentProps } from "../lib";
import useEventBus from "lib/customHooks/useEventBus";
import { CreateFlowFormEventBusMessages } from "components/blocks/TaskModals/CreateFlowForm/lib";

type RichTextEditorProps = FormComponentProps

const RichTextEditorField: FC<RichTextEditorProps> = (props) => {
  const { fieldName } = props;

  const ReactEditorJS = createReactEditorJS();
  const editorCore = useRef<EditorJsCore | null>(null);
  const form = Form.useFormInstance();
  const eventBus = useEventBus<CreateFlowFormEventBusMessages>();

  const initialValue = form.getFieldValue(fieldName) ?? EditorJSInitialState;

  useEffect(() => {
    return () => {
      editorCore.current = null;
    };
  }, []);

  useEffect(() => {
    const loaderListener = eventBus.subscribe("RecordDescriptionRTE", async (payload) => {
      if (payload.fieldName === fieldName) {
        const currentValue = await editorCore?.current?.save();
        const nextState: EditorJsSaveFormat = {
          ...currentValue,
          blocks: [
            ...(currentValue?.blocks ?? []),
            ...(payload.data.blocks ?? []),
          ]
        };
        editorCore.current?.render(nextState);
      }
    });
    return () => {
      loaderListener.unsubscribe();
    };
  }, []);

  const handleInitialize = useCallback((instance: EditorJsCore) => {
    editorCore.current = instance;
  }, []);

  const handleOnChange = useCallback(async () => {
    const nextState = await editorCore?.current?.save();
    form.setFields([{ name: fieldName, value: nextState }]);
  }, [fieldName, form]);

  return (
    <>
      <Form.Item name={fieldName} style={{ display: "none" }} />
      <ReactEditorJS
        tools={EditorJsTaskDescriptionConfig}
        defaultValue={initialValue}
        onChange={handleOnChange}
        onInitialize={handleInitialize}
      />
    </>
  );
};

export default RichTextEditorField;
