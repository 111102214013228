import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapper from "components/icons/IconSvgWrapper";

const ViewSwitchCheckSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M9.95914 2.42635L3.75886 8.62664C3.70424 8.68125 3.61581 8.68125 3.56132 8.62664L0.0408033 5.10599C-0.0136825 5.05162 -0.0136825 4.9632 0.0408033 4.90859L0.896243 4.05314C0.950857 3.99865 1.03928 3.99865 1.09377 4.05314L3.66022 6.61947L8.90629 1.37338C8.96104 1.3189 9.04921 1.3189 9.10382 1.37338L9.95914 2.22882C10.0138 2.2833 10.0138 2.3716 9.95914 2.42635Z" />
    </IconSvgWrapper>
  );
};

export default ViewSwitchCheckSvg;
