import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const RarSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.3112 20.4357C64.0423 20.4324 61.8672 19.5293 60.2635 17.9246C58.6593 16.3198 57.7573 14.1444 57.7552 11.8755V7.71041e-07H19.527C18.0603 -0.000544262 16.6078 0.287871 15.2527 0.848768C13.8975 1.40967 12.6661 2.23204 11.6287 3.26895C10.5914 4.30585 9.7686 5.53697 9.2072 6.89195C8.64579 8.24693 8.35686 9.6993 8.35686 11.166V78.4772C8.35686 79.944 8.64579 81.3963 9.2072 82.751C9.7686 84.1062 10.5914 85.3374 11.6287 86.3743C12.6661 87.4112 13.8975 88.2336 15.2527 88.7946C16.6078 89.3552 18.0603 89.6436 19.527 89.6432H65.4191C68.3805 89.6432 71.2208 88.4668 73.3145 86.3726C75.4087 84.2788 76.5851 81.4386 76.5851 78.4772V20.4357H66.3112Z" fill="#A140FF"/>
        <path d="M76.5851 20.4357H66.3112C64.0423 20.4324 61.8672 19.5293 60.2635 17.9246C58.6593 16.3198 57.7573 14.1444 57.7552 11.8755V0L76.5851 20.4357Z" fill="#A140FF"/>
        <path d="M84.6846 70.7925H32.8008C29.1205 70.7925 26.1369 73.7759 26.1369 77.4564V92.9253C26.1369 96.6058 29.1205 99.5892 32.8008 99.5892H84.6846C88.3651 99.5892 91.3485 96.6058 91.3485 92.9253V77.4564C91.3485 73.7759 88.3651 70.7925 84.6846 70.7925Z" fill="#A140FF"/>
        <path d="M49.6604 93.4034C49.4276 93.5515 49.2054 93.5971 48.9938 93.5407C48.7822 93.4843 48.6023 93.3399 48.4542 93.1071L45.1741 87.6473H42.2115V92.4722C42.2115 93.0648 41.9152 93.361 41.3227 93.361C40.7301 93.361 40.4339 93.0648 40.4339 92.4722V79.9021C40.4339 79.3096 40.7301 79.0133 41.3227 79.0133H46.2745C47.5654 79.0133 48.553 79.359 49.2372 80.0502C49.9286 80.7345 50.2743 81.722 50.2743 83.0129V83.6477C50.2743 84.7693 50.0133 85.6652 49.4911 86.3353C48.9691 86.9984 48.2144 87.4108 47.2268 87.5731L49.9992 92.1971C50.2672 92.6909 50.1544 93.093 49.6604 93.4034ZM42.2115 85.8697H46.2745C47.0787 85.8697 47.6501 85.7004 47.9886 85.3619C48.3272 85.0233 48.4965 84.4519 48.4965 83.6477V83.0129C48.4965 82.2158 48.3272 81.6477 47.9886 81.3092C47.6501 80.9635 47.0787 80.7909 46.2745 80.7909H42.2115V85.8697Z" fill="white"/>
        <path d="M52.983 93.2764C52.4187 93.0577 52.2494 92.6697 52.4751 92.1125L57.5116 79.7116C57.7232 79.2461 58.0548 79.0133 58.5062 79.0133H58.5485C59.007 79.0345 59.3174 79.2672 59.4797 79.7116L64.6008 92.1125C64.8266 92.6697 64.6643 93.0577 64.1141 93.2764C63.5568 93.4951 63.1689 93.3328 62.9502 92.7897L61.8075 90.0175H55.2153L54.1257 92.7897C53.907 93.3399 53.5261 93.5021 52.983 93.2764ZM55.9137 88.2399H61.0772L58.4427 81.8278L55.9137 88.2399Z" fill="white"/>
        <path d="M76.9805 93.4034C76.7477 93.5515 76.5257 93.5971 76.3141 93.5407C76.1025 93.4843 75.9224 93.3399 75.7743 93.1071L72.4942 87.6473H69.5315V92.4722C69.5315 93.0648 69.2353 93.361 68.6427 93.361C68.0502 93.361 67.7539 93.0648 67.7539 92.4722V79.9021C67.7539 79.3096 68.0502 79.0133 68.6427 79.0133H73.5946C74.8855 79.0133 75.873 79.359 76.5573 80.0502C77.2485 80.7345 77.5942 81.722 77.5942 83.0129V83.6477C77.5942 84.7693 77.3332 85.6652 76.8112 86.3353C76.2892 86.9984 75.5344 87.4108 74.5469 87.5731L77.3191 92.1971C77.5871 92.6909 77.4743 93.093 76.9805 93.4034ZM69.5315 85.8697H73.5946C74.3988 85.8697 74.9701 85.7004 75.3087 85.3619C75.6473 85.0233 75.8166 84.4519 75.8166 83.6477V83.0129C75.8166 82.2158 75.6473 81.6477 75.3087 81.3092C74.9701 80.9635 74.3988 80.7909 73.5946 80.7909H69.5315V85.8697Z" fill="white"/>
        <path d="M47.7635 62.5892H21.8216C21.4096 62.588 21.0149 62.4237 20.724 62.1319C20.4331 61.8402 20.2697 61.4452 20.2697 61.0332C20.2697 60.6216 20.4332 60.227 20.7242 59.9357C21.0153 59.6448 21.41 59.4813 21.8216 59.4813H47.7635C47.9676 59.4809 48.1699 59.5203 48.3586 59.5983C48.5474 59.6759 48.7189 59.79 48.8635 59.9344C49.008 60.0784 49.1227 60.2498 49.201 60.4386C49.2792 60.627 49.3195 60.829 49.3195 61.0332C49.3195 61.4461 49.1556 61.8415 48.8637 62.1336C48.5719 62.4253 48.1761 62.5892 47.7635 62.5892Z" fill="#A140FF"/>
        <path d="M47.7635 53.4233H21.8216C21.4096 53.422 21.0149 53.2577 20.724 52.966C20.4331 52.6743 20.2697 52.2793 20.2697 51.8672C20.2708 51.456 20.4347 51.0618 20.7255 50.771C21.0163 50.4801 21.4103 50.3166 21.8216 50.3154H47.7635C48.1754 50.3154 48.5706 50.4789 48.8623 50.7697C49.154 51.0606 49.3184 51.4552 49.3195 51.8672C49.3195 52.2801 49.1556 52.6755 48.8637 52.9677C48.5719 53.2594 48.1761 53.4233 47.7635 53.4233Z" fill="#A140FF"/>
        <path d="M47.7635 44.2572H21.8216C21.41 44.2572 21.0153 44.0938 20.7242 43.8029C20.4332 43.5116 20.2697 43.117 20.2697 42.7054C20.2697 42.2933 20.4331 41.8983 20.724 41.6066C21.0149 41.3149 21.4096 41.1505 21.8216 41.1494H47.7635C48.1761 41.1494 48.5719 41.3133 48.8637 41.605C49.1556 41.8971 49.3195 42.2925 49.3195 42.7054C49.3195 42.9095 49.2792 43.1116 49.201 43.3004C49.1227 43.4888 49.008 43.6601 48.8635 43.8041C48.7189 43.9485 48.5474 44.0626 48.3586 44.1402C48.1699 44.2182 47.9676 44.2577 47.7635 44.2572Z" fill="#A140FF"/>
        <path d="M47.7635 35.0913H21.8216C21.41 35.0913 21.0153 34.9278 20.7242 34.6368C20.4332 34.3457 20.2697 33.951 20.2697 33.5394C20.2697 33.1278 20.4332 32.7331 20.7242 32.4421C21.0153 32.1511 21.41 31.9876 21.8216 31.9876H47.7635C47.9676 31.987 48.1699 32.0268 48.3586 32.1045C48.5474 32.1822 48.7189 32.2965 48.8635 32.4406C49.008 32.5848 49.1227 32.7561 49.201 32.9446C49.2792 33.1332 49.3195 33.3353 49.3195 33.5394C49.3195 33.7436 49.2792 33.9457 49.201 34.1343C49.1227 34.3228 49.008 34.4941 48.8635 34.6383C48.7189 34.7824 48.5474 34.8966 48.3586 34.9744C48.1699 35.0521 47.9676 35.0918 47.7635 35.0913Z" fill="#A140FF"/>
        <path d="M59.3983 63.2863C58.0021 63.2851 56.6635 62.7303 55.6764 61.7427C54.6888 60.7556 54.134 59.417 54.1328 58.0207V53.5851C54.1328 53.1266 54.5042 52.7552 54.9627 52.7552H63.8299C64.2884 52.7552 64.6598 53.1266 64.6598 53.5851V58.0207C64.6585 59.4162 64.1042 60.7544 63.1178 61.7415C62.1315 62.7286 60.7938 63.2842 59.3983 63.2863ZM57.6556 55.4481C57.1971 55.4481 56.8257 55.8195 56.8257 56.278V58.0207C56.827 58.7025 57.0983 59.3564 57.5805 59.8386C58.0627 60.3207 58.7162 60.5921 59.3983 60.5934C60.0805 60.5921 60.734 60.3207 61.2162 59.8386C61.6983 59.3564 61.9697 58.7025 61.971 58.0207V56.278C61.971 55.8195 61.5996 55.4481 61.1411 55.4481H57.6556Z" fill="#A140FF"/>
        <path d="M55.3776 51.805H58.166C58.8535 51.805 59.4108 51.2477 59.4108 50.5602V47.7718C59.4108 47.0842 58.8535 46.527 58.166 46.527H55.3776C54.69 46.527 54.1328 47.0842 54.1328 47.7718V50.5602C54.1328 51.2477 54.69 51.805 55.3776 51.805Z" fill="#A140FF"/>
        <path d="M60.639 46.5311H63.4274C64.1149 46.5311 64.6722 45.9739 64.6722 45.2863V42.4979C64.6722 41.8104 64.1149 41.2531 63.4274 41.2531H60.639C59.9515 41.2531 59.3942 41.8104 59.3942 42.4979V45.2863C59.3942 45.9739 59.9515 46.5311 60.639 46.5311Z" fill="#A140FF"/>
        <path d="M55.3776 41.2573H58.166C58.8535 41.2573 59.4108 40.6999 59.4108 40.0124V37.2241C59.4108 36.5366 58.8535 35.9792 58.166 35.9792H55.3776C54.69 35.9792 54.1328 36.5366 54.1328 37.2241V40.0124C54.1328 40.6999 54.69 41.2573 55.3776 41.2573Z" fill="#A140FF"/>
        <path d="M60.639 35.9834H63.4274C64.1149 35.9834 64.6722 35.426 64.6722 34.7386V31.9502C64.6722 31.2627 64.1149 30.7054 63.4274 30.7054H60.639C59.9515 30.7054 59.3942 31.2627 59.3942 31.9502V34.7386C59.3942 35.426 59.9515 35.9834 60.639 35.9834Z" fill="#A140FF"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default RarSvg;
