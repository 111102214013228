import { css } from "@emotion/react/macro";

export const squareWrapperCss = css`
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-size: 13px;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: #f2f2f2;
  transition: opacity 200ms ease;
  &:hover {
    background-color: #e5e5e5;
  }
`;

export const styledInputCss = css`
  margin-left: 1px;
  background-color: transparent;
  border-color: transparent;
  font-size: 13px;
  &:focus,
  &:hover {
    background-color: white
  }
`;

export const selectTagCss = css`
  padding: 2px 8px;
  border-radius: 20px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 13px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
