import { FC } from "react";
import { Skeleton } from "antd";

const title = Math.floor(Math.random() * (290 - 170)) + 170;
const tab = { width: "60px", marginRight: "7px" };

const SkeletonHeader: FC = () => (
  <>
    <div style={{ display: "flex", alignItems: "center" }}>
      <Skeleton.Avatar active size="small" shape="square" style={{ width: "45px", height: "45px", marginRight: "15px", borderRadius: "7px" }} />
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginRight: "10px" }}>
        <Skeleton.Button active size="small" shape="round" style={{ width: `${title}px`, height: "18px", margin: "4px 0" }} />
        <Skeleton.Button active size="small" shape="round" style={{ width: "75px", height: "14px", margin: "2px 0" }} />
      </div>
      <Skeleton.Button active size="small" shape="round" style={tab} />
      <Skeleton.Button active size="small" shape="round" style={tab} />
      <Skeleton.Button active size="small" shape="round" style={tab} />
    </div>
  </>
);

export default SkeletonHeader;
