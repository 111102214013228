import { BoardInterface } from "./boardTypes";
import { ContentType } from "./contentTypes";
import { EntityId } from "./entity";
import { TaskInterface } from "./tasksTypes";
import { WorkspaceInterface } from "./types";

export interface QueryPayload<ContentType> {
  _limit?: number;
  _start?: number;
  _sort?: `${string & keyof ContentType}:${QuerySortDirections}`;
  _where?: Record<`${string & keyof ContentType}_${QueryFilterSuffixes}`, string>
}

export enum QuerySortDirections {
  Ascending = "ASC",
  Descending = "DESC",
}

export enum QueryFilterSuffixes {
  Equal = "eq",
  NotEqual = "ne",
  LessThan = "lt",
  GreaterThan = "gt",
  LessThanOrEqualTo = "lte",
  GreaterThanOrEqualTo = "gte",
  IncludedInAnArray = "in",
  NotIncludedInAnArray = "nin",
  Contains = "contains",
  DoesntContain = "ncontains",
  ContainsCaseSensitive = "containss",
  DoesntContainCaseSensitive = "ncontainss",
  Null = "null",
}

export interface EntityQueryPayload<T = any> extends QueryPayload<T> {
  task?: EntityId;
  board?: EntityId;
  workspace?: EntityId;
  id?: EntityId;
  uid?: EntityId;
}

export interface MultiRelationalEntityFilter {
  contentType: ContentType | string;
  id: EntityId;
}

export interface MultiRelationalEntityCommonFields {
  workspace?: Partial<WorkspaceInterface>;
  board?: Partial<BoardInterface>;
  task?: Partial<TaskInterface>;
}

export type MultiRelationalContentTypeCode = ContentType.Workspace | ContentType.Board | ContentType.Task

export const MultiRelationalEntityKeys = [
  ContentType.Workspace,
  ContentType.Board,
  ContentType.Task,
];
