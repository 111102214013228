import { UserInterface } from "lib/types/types";
import { AssigneesType } from "pages/Board/BoardKanban/types";

export const getUserFullName = (user: UserInterface | AssigneesType | null) => {
  return (user?.firstName)
    ? user?.firstName + " " + user?.lastName
    : user?.email;
};

export const getUserFirstName = (user: UserInterface | null) => {
  return (user?.firstName)
    ? user?.firstName
    : user?.email;
};

export const getUserInitials = (user: UserInterface | null) => {
  if (user?.firstName) {
    const letters = user.firstName[0];
    return letters.toUpperCase();
  } else if (user?.email) {
    return user.email.slice(0, 1).toUpperCase();
  }
};
