import { FC } from "react";
import styled from "@emotion/styled/macro";
import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import { dataInput } from "components/blocks/TaskTable/styled";
import { InputStyled } from "components/blocks/TaskTable/components/TaskTitle/styled";
import SingleLineTextGeneric from "./Display/generic";
import { fieldDetailedInput } from "../styled";

const SingleLineTextListWrapper = styled.div`
  ${dataInput};
  white-space: nowrap;
  &:hover {
    ${InputStyled}
  }
  .ant-input-disabled {
    border-color: transparent;
    background-color: transparent;
    color: black;
    cursor: default;
  }
`;

const SingleLineTextDetailedWrapper = styled.div`
  ${fieldDetailedInput};
  .ant-input-disabled {
    border-color: transparent;
    background-color: transparent;
    color: black;
    cursor: default;
  }
`;

const SingleLineTextField: FC<TaskFieldPropsGeneric> = (props) => {
  const { component } = props;

  switch (component) {
    case TaskSourceComponent.Card:
      return null;
    case TaskSourceComponent.Cell:
      return (
        <SingleLineTextListWrapper>
          <SingleLineTextGeneric {...props} />
        </SingleLineTextListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <SingleLineTextDetailedWrapper>
          <SingleLineTextGeneric {...props} />
        </SingleLineTextDetailedWrapper>
      );
    default:
      return null;
  }
};

export default SingleLineTextField;
