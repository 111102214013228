import { CSSProperties, FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, Menu, Modal } from "antd";
import { copyLink, getFileDetailUri } from "lib/helpers/navigationUtils";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import LoadingMessage from "components/blocks/LoadingMessage";
import ButtonIcon from "components/ui/ButtonIcon";
import { okButtonProps, cancelButtonProps } from "styles/common-vars";
import { ProjectFileContainersControls } from "../styled";
import { FileContainerInterface } from "lib/types/fileContainer";
import FileContainerService from "services/FileContainerService";
import { useAppDispatch } from "app/store";
import { deleteFileContainerFromCollection } from "app/slices/fileContainerSlice";
import MoreSvg from "components/icons/resizable/more";
import MenuItemIcon from "components/ui/MenuItemIcon";
import TrashSvg from "components/icons/resizable/trash";
import UrlSvg from "components/icons/resizable/url";
import OpenSvg from "components/icons/resizable/open";

interface ProjectFileContainersDropdownProps {
  fileContainer: FileContainerInterface;
  containerStyles?: CSSProperties;
}

const ProjectFileContainersDropdown: FC<ProjectFileContainersDropdownProps> = (props) => {
  const { fileContainer: file } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const allowedToDelete = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);

  const url = file.file?.url;
  const fileDetailedPath = getFileDetailUri(file.uid);

  const handleDropdownMenuOnClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsMenuVisible(!isMenuVisible);
  };

  const onAfterDelete = () => {
    dispatch(deleteFileContainerFromCollection(file.id));
  };

  const menu = [
    {
      title: "Open in new tab",
      icon: <OpenSvg size={12} />,
      key: "open-in-new-tab",
      onClick: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(fileDetailedPath, "_blank");
        setIsMenuVisible(false);
      },
    },
    {
      title: "Copy file link",
      icon: <UrlSvg size={12} />,
      key: "copy-file-link",
      onClick: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        copyLink(fileDetailedPath);
        setIsMenuVisible(false);
      }
    },
    {
      title: "Delete file",
      icon: <TrashSvg size={12} />,
      key: "Delete-file",
      onClick: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        handleConfirmDelete();
        setIsMenuVisible(false);
      },
      hidden: !allowedToDelete,
    },
  ];

  const handleConfirmDelete = () => {
    Modal.confirm({
      title: "Permanently delete the file?",
      content: "This will permanently delete the file. This item will no longer be accessible to you or anyone else. This action is irreversible.",
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: okButtonProps,
      cancelButtonProps: cancelButtonProps,
      async onOk() {
        await handleFileContainerDelete();
      },
      zIndex: 1040,
    });
  };

  const handleFileContainerDelete = async () => {
    await FileContainerService.delete(file.uid);
    onAfterDelete();
    LoadingMessage.complete("This file is deleted");
  };

  const FileContainerMenuOverlay = (
    <Menu>
      {menu.map((item, index) => {
        if (item.hidden) {
          return (null);
        }
        return (
          <Menu.Item key={item.key} onClick={({ domEvent }) => item.onClick(domEvent)}>
            <MenuItemIcon text={item.title} icon={item.icon} />
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <ProjectFileContainersControls style={props.containerStyles}>
      <Dropdown
        overlay={FileContainerMenuOverlay}
        trigger={["click", "contextMenu"]}
        placement="bottomRight"
        open={isMenuVisible}
        onOpenChange={() => setIsMenuVisible(!isMenuVisible)}
      >
        <ButtonIcon
          icon={<MoreSvg size={12} />}
          onClick={handleDropdownMenuOnClick}
        />
      </Dropdown>
    </ProjectFileContainersControls>
  );
};

export default ProjectFileContainersDropdown;
