import { FC, ReactNode, useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { useAppSelector } from "app/store";
import { TaskProvider } from "lib/contexts/TaskContext";
import { useDocumentTitle } from "lib/customHooks/useDocumentTitle";
import useGetTaskDetailed from "lib/customHooks/useGetTaskDetailed";
import useWSDeleteTaskDetailed from "lib/customHooks/websocketTaskDetailed/useWSDeleteTaskDetailed";
import useWSUpdateTaskDetailed from "lib/customHooks/websocketTaskDetailed/useWSUpdateTaskDetailed";
import { DisplayView } from "lib/types/applicationTypes";
import { PropertyValueType } from "lib/types/dataTypes";
import { TaskInterface } from "lib/types/tasksTypes";
import { MemberPermissionProvider } from "lib/contexts/MemberPermissionContext";
import useTaskSetValue from "lib/customHooks/dataLayer/task/useTaskSetValue";
import TaskDrawer from "../TaskDrawer";

interface TaskDrawerInRouteProps {
  displayView: DisplayView
  onClose: () => void
  showMask?: boolean
  drawerWidth?: number
  isRenderInCurrentDom?: boolean
  customization?: Partial<Record<PropertyValueType, ReactNode>>
}

const TaskDrawerInRoute: FC<TaskDrawerInRouteProps> = (props) => {
  const task = useAppSelector(state => state.currentTaskReducer.task);
  const { taskId } = useParams<{ taskId: string }>();

  useGetTaskDetailed(taskId, false);
  useDocumentTitle(task?.title);

  const { setTaskValue } = useTaskSetValue({ task, updateDetailed: true });
  const [isOpen, setIsOpen] = useState(true);

  const onClose = () => {
    setIsOpen(false);
  };

  const afterVisibleChange = (visible: boolean) => {
    if (!visible) {
      props.onClose();
    }
  };

  const afterDeleteCallback = useCallback((deletedTask: TaskInterface) => {
    if (String(deletedTask.id) === String(task?.id)) {
      setIsOpen(false);
    }
  }, [task]);

  useWSUpdateTaskDetailed();
  useWSDeleteTaskDetailed(afterDeleteCallback);

  return (
    <MemberPermissionProvider workspaceId={String(task?.board?.workspace)}>
      <TaskProvider task={task} displayView={props.displayView} setValue={setTaskValue}>
        <TaskDrawer
          visible={isOpen}
          onClose={onClose}
          afterVisibleChange={afterVisibleChange}
          showMask={props.showMask}
          drawerWidth={props.drawerWidth}
          isRenderInCurrentDom={props.isRenderInCurrentDom}
          customization={props.customization}
        />
      </TaskProvider>
    </MemberPermissionProvider>
  );
};

export default TaskDrawerInRoute;
