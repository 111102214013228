import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationInterface, NotificationStatus } from "lib/types/notificationTypes";

interface InitialStateType {
  notifications: Array<NotificationInterface>;
  count: number
}

const initialState: InitialStateType = {
  notifications: [],
  count: 0
};

const getUnreadCount = (notifications: Array<NotificationInterface>) => {
  return notifications.filter(notification => notification.status === NotificationStatus.New).length;
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<Array<NotificationInterface>>) => {
      state.notifications = action.payload;
      state.count = getUnreadCount(action.payload);
    },
    addNotification: (state, action: PayloadAction<NotificationInterface>) => {
      state.notifications.push(action.payload);
      state.count = getUnreadCount(state.notifications);
    },
    updateNotification: (state, action: PayloadAction<NotificationInterface>) => {
      const findIndex = state.notifications.findIndex(notification => notification.id === action.payload.id);
      if (findIndex !== -1) {
        state.notifications[findIndex] = action.payload;
        state.count = getUnreadCount(state.notifications);
      }
    },
    deleteNotification: (state, action: PayloadAction<NotificationInterface>) => {
      const findIndex = state.notifications.findIndex(notification => notification.id === action.payload.id);
      if (findIndex !== -1) {
        state.notifications.splice(findIndex, 1);
        state.count = getUnreadCount(state.notifications);
      }
    },
  },
});

export const { setNotifications, addNotification, updateNotification, deleteNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
