import { FC, useEffect, useState } from "react";
import { ItemProps } from "react-virtuoso";

const DnDHeightPreservingItem: FC<ItemProps> = (props) => {
  const [size, setSize] = useState(0);
  const knownSize = props["data-known-size"];

  useEffect(() => {
    setSize((prevSize) => {
      return knownSize === 0 ? prevSize : knownSize;
    });
  }, [knownSize]);

  return (
    <>
      <style>
        {`
          .height-preserving-container:empty {
            min-height: calc(var(--child-height));
            box-sizing: border-box;
          }
      `}
      </style>
      <div
        {...props}
        className="height-preserving-container"
        // check styling in the style tag below
        // @ts-ignore
        style={{ "--child-height": `${size}px`, }}
      >
        {props.children}
      </div>
    </>
  );
};

export default DnDHeightPreservingItem;
