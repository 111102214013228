import styled from "@emotion/styled/macro";
import { noSelect } from "components/blocks/TaskTable/styled";
import { css } from "@emotion/react/macro";

export const SingleUserContainer = styled.div<{ size: number, fullWidth?: boolean }>`
  display: flex;
  max-width: 180px;
  align-items: center;
  overflow: hidden;
  ${({ fullWidth }) => fullWidth && css`
    max-width: unset;
  `}
`;

export const SingleUserAvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SingleUserAvatar = styled.div<{
  size: number;
  fontSize?: number;
  backgroundColor?: string | null;
  imageUrl?: string | null;
}>`
  ${noSelect};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  width: ${({ size }) => `${size}px` || "28px"};
  height: ${({ size }) => `${size}px` || "28px"};
  border-radius: ${({ size }) => `${size / 2}px` || "14px"};
  font-size: ${({ fontSize }) => `${fontSize}px` || "12px"};
  color: white;
  font-weight: 600;
  background-color: ${({ backgroundColor }) => backgroundColor || "#8dceef"};
  background-image: ${({ imageUrl }) => `url(${imageUrl})` || ""};
  background-position: 50% 50%;
  background-size: cover;
`;

export const SingleUserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  line-height: 1.3;
`;

export const SingleUserNameContainer = styled.div<{ fontWeight?: number }>`
  color: #333;
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  margin-left: 7px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const  SingleUserEmailContainer = styled.div`
  color: #999;
  font-size: 12px;
  font-weight: 600;
  margin-left: 7px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
