import { setLoading } from "app/slices/progressSlice";
import { useAppDispatch } from "app/store";
import { captureException } from "lib/utils/sentry";
import { useCallback, useEffect, useState } from "react";
import { ErrorMessages } from "../helpers/ErrorMessages";
import { JSendError, JSendStatus } from "../types/jsend";

interface propsType<T, R> {
  loadOnMount?: boolean;
  fetchFn: (...params: R[]) => Promise<T> | null;
  defaultDataValue?: unknown;
  isShowProgress?: boolean;
  showErrorMessage?: boolean;
}

interface returnType<T, R> {
  data: T
  isLoading: boolean
  isError: boolean | null
  isLoaded: boolean
  loadData: (...params: R[]) => Promise<T | null>
}

/*
* fetchFn should be wrapped in useCallback
* if use loadOnMount you can not provide params
* fetchFn and loadData - works the same way
*/
const useAsyncData = <T, R = void>({ loadOnMount, fetchFn, defaultDataValue, isShowProgress = true, showErrorMessage = true }: propsType<T, R>): returnType<T, R> => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>(defaultDataValue);
  const [isError, setIsError] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const loadData = useCallback(async (...params: R[]) => {
    setIsLoading(true);
    setIsLoaded(false);
    if (isShowProgress) {
      dispatch(setLoading(true));
    }
    setIsError(false);

    try {
      const resp: any = await fetchFn(...params);

      if (resp?.status === JSendStatus.Error) {
        throw resp;
      }

      setData(resp);
      setIsLoaded(true);
      setIsLoading(false);
      if (isShowProgress) {
        dispatch(setLoading(false));
      }

      return resp as T;
    } catch (e: any) {
      setIsError(true);
      let errorMessage = "";
      if (e?.status && e?.message) {
        const jsendError: JSendError = e;
        errorMessage = ErrorMessages[jsendError.message];
      }

      const errorText = errorMessage || "Oops! Something went wrong…";
      captureException(e, showErrorMessage, errorText);

      // TODO support or delete this logic
      // if (e.error === "Forbidden") {
      //   history.push("/home");
      // }
      setIsLoading(false);
      if (isShowProgress) {
        dispatch(setLoading(false));
      }
      return null;
    }

  }, [dispatch, fetchFn, isShowProgress]);

  useEffect(() => {
    if (loadOnMount) {
      loadData();
    }
  }, [fetchFn, loadData, loadOnMount]);

  return { data, isLoading, isError, isLoaded, loadData };
};

export default useAsyncData;
