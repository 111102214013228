import { Checkbox, InputNumber, Select } from "antd";

import { selectFilterTypes, setFilterValue, setFilterValueMultiple } from "app/slices/tasksFilterAndSortSlice";
import { useAppDispatch, useAppSelector } from "app/store";
import ReusableSelectOption from "components/blocks/ReusableFields/ReusableMultiSelectOption";
import useFilterValues from "lib/customHooks/filters/useFilterValues";
import { FilterFunction } from "lib/filters/filterFunctions";
import { FilterValueNone, noneFilterValue, TAnyFilters } from "lib/filters/filters";
import { FilterType, getFilterType } from "lib/filters/filterTypes";
import moment from "moment";
import {
  FilterMultipleSelectFieldWrapper,
  FilterSingleSelectFieldWrapper,
  FilterTypesCheckboxContainer,
  FilterTypesDatePicker,
  FilterTypesInput
} from "pages/Board/SecondaryBar/FilterTypes/styled";
import { FC } from "react";

interface MultiSelectItem {
  children: string;
  key: string;
  value: string;
  color?: string;
}

interface FilterTypesProps {
  currentFilter: TAnyFilters
  disabled?: boolean;
  id?: string // if component has an id, that means the component is work with multiple filter
}

const selectMultipleWidth = 225;
const selectSingleWidth = 125;
const selectDropdownWidth = 150;
const selectMarginRight = 7;

const FilterTypes: FC<FilterTypesProps> = (props) => {
  const { disabled, currentFilter, id } = props;

  const dispatch = useAppDispatch();
  const types = useAppSelector(selectFilterTypes);

  const filterType = getFilterType(currentFilter, types);
  const filterValues = useFilterValues({ currentFilter });
  const isNotRender = [FilterFunction["Is empty"], FilterFunction["Is not empty"]].includes(currentFilter.function as FilterFunction);

  const onChange = (item: string | number | MultiSelectItem[]) => {
    let value: any = noneFilterValue;

    switch (filterType) {
      case FilterType.Text:
      case FilterType.Number:
      case FilterType["Due date"]:
      case FilterType.Date: {
        value = {
          text: item,
          valueKey: item
        };
        break;
      }

      case FilterType.Users:
      case FilterType.Assignees:
      case FilterType.MultipleSelect: {
        if (Array.isArray(item)) {
          value = {
            text: item.map(i => i.value),
            valueKey: item.map(i => i.key),
            color: item.map(i => i.color)
          };
        }
        break;
      }

      default: {
        value = filterValues.find(filterValue => filterValue.text === item) || noneFilterValue;
        break;
      }
    }

    id
      ? dispatch(setFilterValueMultiple({ value, id }))
      : dispatch(setFilterValue(value));
  };

  if (isNotRender) {
    return null;
  }

  switch (filterType) {
    case FilterType.Text: {
      return (
        <FilterTypesInput
          placeholder="Enter value"
          value={(currentFilter.value.text === FilterValueNone) ? "" : currentFilter.value.text}
          onChange={(e) => onChange(e.currentTarget.value)}
          onBlur={(e) => onChange(e.currentTarget.value)}
          disabled={disabled}
        />
      );
    }

    case FilterType.Boolean: {
      return (
        <FilterTypesCheckboxContainer>
          <Checkbox
            defaultChecked={(currentFilter.value.text === FilterValueNone) ? false : !!currentFilter.value.valueKey}
            onChange={(e) => onChange(e.target.checked ? "True" : "False")}
            disabled={disabled}
          />
        </FilterTypesCheckboxContainer>
      );
    }

    case FilterType.Number: {
      return (
        <InputNumber
          style={{ width: selectMultipleWidth, marginRight: selectMarginRight }}
          placeholder="Enter value"
          defaultValue={(currentFilter.value.text === FilterValueNone) ? undefined : Number(currentFilter.value.valueKey)}
          onChange={(value) => onChange(value as number)}
          type="number"
          disabled={disabled}
        />
      );
    }

    case FilterType["Due date"]:
    case FilterType["Created time"]:
    case FilterType["Last modified"]:
    case FilterType["Closing date"]:
    case FilterType.Date: {
      if (currentFilter.function === FilterFunction["This week"]) {
        return null;
      }

      return (
        <FilterTypesDatePicker
          defaultValue={(currentFilter.value.text === FilterValueNone) ? undefined : moment(currentFilter.value.valueKey as string)}
          allowClear={false}
          placeholder="Select date"
          onChange={(date, dateString) => onChange(dateString)}
          disabled={disabled}
        />
      );
    }

    case FilterType.SingleSelect: {
      return (
        <FilterSingleSelectFieldWrapper>
          <Select
            id="single-select-filter"
            showSearch
            style={{ width: selectSingleWidth, marginRight: selectMarginRight }}
            filterOption={(input, option) =>
              String(option?.value)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              String(optionA.value)?.toLowerCase().localeCompare(String(optionB.value)?.toLowerCase())
            }
            disabled={(currentFilter.titleKey === FilterValueNone) || disabled}
            placeholder="Select value"
            defaultValue={(currentFilter.value.text === FilterValueNone) ? undefined : currentFilter.value.text}
            onSelect={(item: string) => onChange(item)}
            dropdownStyle={{ minWidth: selectDropdownWidth }}
          >
            {filterValues?.filter(item => item.text !== FilterValueNone)
              .map(item => (
                <Select.Option value={item.text} key={item.valueKey as string}>
                  <ReusableSelectOption selectTitle={item.text} selectColor={item.color} />
                </Select.Option>
              ))}
          </Select>
        </FilterSingleSelectFieldWrapper>
      );
    }

    case FilterType.Assignees:
    case FilterType.Users:
    case FilterType.MultipleSelect: {
      return (
        <FilterMultipleSelectFieldWrapper>
          <Select
            // id="multiple-select-filter"
            mode="multiple"
            placeholder="Select values"
            allowClear
            showArrow={false}
            showSearch
            disabled={(currentFilter.titleKey === FilterValueNone) || disabled}
            onChange={(value: string, items: any[]) => onChange(items)}
            filterOption={(input, option) =>
              String(option?.value)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              String(optionA.value)?.toLowerCase().localeCompare(String(optionB.value)?.toLowerCase())
            }
            value={(currentFilter.value.text === FilterValueNone) ? [] : currentFilter.value.text}
            dropdownStyle={{ minWidth: selectDropdownWidth }}
            style={{ width: selectMultipleWidth, marginRight: selectMarginRight }}
          >
            {filterValues?.filter(item => item.text !== FilterValueNone)
              .map(item => (
                <Select.Option value={item.text} key={item.valueKey as string}>
                  <ReusableSelectOption selectTitle={item.text} selectColor={item.color} />
                </Select.Option>
              ))}
          </Select>
        </FilterMultipleSelectFieldWrapper>
      );
    }

    // case FilterType.Users: {
    //   return (
    //     <FilterMultipleSelectFieldWrapper>
    //       <Select
    //         id="multiple-select-filter"
    //         showSearch
    //         mode="multiple"
    //         style={{ width: selectMultipleWidth, marginRight: selectMarginRight }}
    //         filterOption={(input, option) =>
    //           String(option?.value)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //         }
    //         filterSort={(optionA, optionB) =>
    //           String(optionA.value)?.toLowerCase().localeCompare(String(optionB.value)?.toLowerCase())
    //         }
    //         disabled={(currentFilter.titleKey === FilterValueNone) || disabled}
    //         placeholder="Select values"
    //         value={(currentFilter.value.text === FilterValueNone) ? [] : currentFilter.value.text}
    //         onChange={(value: string, items: any[]) => onChange(items)}
    //         dropdownStyle={{ minWidth: selectDropdownWidth }}
    //       >
    //         {filterValues?.filter(item => item.text !== FilterValueNone)
    //           .map(item => (
    //             <Select.Option value={item.valueKey} key={item.valueKey as string}>
    //               <ReusableSelectOption selectTitle={item.text} selectColor={item.color} />
    //             </Select.Option>
    //           ))}
    //       </Select>
    //     </FilterMultipleSelectFieldWrapper>
    //   );
    // }

    default: {
      return (
        <Select
          showSearch
          showArrow
          style={{ width: selectSingleWidth, marginRight: selectMarginRight }}
          filterOption={(input, option) =>
            String(option?.value)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            String(optionA.value)?.toLowerCase().localeCompare(String(optionB.value)?.toLowerCase())
          }
          disabled={(currentFilter.titleKey === FilterValueNone) || disabled}
          placeholder="Select value"
          defaultValue={(currentFilter.value.text === FilterValueNone) ? undefined : currentFilter.value.text}
          value={(currentFilter.value.text === FilterValueNone) ? undefined : currentFilter.value.text}
          onSelect={(item: string) => onChange(item)}
          dropdownStyle={{ minWidth: selectDropdownWidth }}
        >
          {filterValues?.filter(item => item.text !== FilterValueNone).map(item =>
            <Select.Option value={item.text} key={item.valueKey as string}>
              <ReusableSelectOption selectTitle={item.text} selectColor={item.color} />
            </Select.Option>
          )}
        </Select>
      );
    }
  }

};

export default FilterTypes;
