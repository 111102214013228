import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { BoardFeatureCodes } from "lib/types/applicationTypes";
import { BoardFeatures, BoardFeaturesToggled } from "lib/types/boardTypes";
import KanbanSvg from "components/icons/kanban";
import ModelSvg from "components/icons/model";
import CompletionSvg from "components/icons/completion";
import AssigneesSvg from "components/icons/assignees";
import DueDatesSvg from "components/icons/due-date";
import { DefaultBoardUnit } from "pages/Board/BoardFields/lib";
import AnnotationSvg from "components/icons/resizable/annotation";

export const BoardDefaultFeatures: BoardFeatures = {
  completion: true,
  statuses: true,
  assignees: true,
  dueDates: true,
  models: false,
  annotations: true,
  unit: DefaultBoardUnit.code,
};

export type IBoardFeatureItem = keyof typeof BoardDefaultFeatures;

export interface IBoardFeature {
  code: keyof BoardFeaturesToggled;
  title: string;
  description: string;
  icon: EmotionJSX.Element;
}

export interface BoardViewItemClickHandler {
  (item: IBoardFeatureItem, checked: boolean): void;
}

export const BoardFeaturesItems: Array<IBoardFeature> = [
  {
    code: BoardFeatureCodes.Completion,
    title: "Completion",
    description: "Description",
    icon: <CompletionSvg />,
  },
  {
    code: BoardFeatureCodes.Statuses,
    title: "Statuses",
    description: "Description",
    icon: <KanbanSvg />,
  },
  {
    code: BoardFeatureCodes.Assignees,
    title: "Assignees",
    description: "Description",
    icon: <AssigneesSvg />,
  },
  {
    code: BoardFeatureCodes.DueDates,
    title: "Due Dates",
    description: "Description",
    icon: <DueDatesSvg />,
  },
  {
    code: BoardFeatureCodes.Models,
    title: "Models",
    description: "Description",
    icon: <ModelSvg />,
  },
  {
    code: BoardFeatureCodes.Annotations,
    title: "Annotations",
    description: "Description",
    icon: <AnnotationSvg size={12} />,
  },
];
