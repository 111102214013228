import { useAppSelector } from "app/store";
import { setSentryUser } from "lib/utils/sentry";

// TODO: ditch this hook and replace it with a function
const useSentry = () => {
  const user = useAppSelector(state => state.currentUserReducer.user);
  setSentryUser(user);
};

export default useSentry;
