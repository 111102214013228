import React, { FC, RefObject, useEffect, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import { PickerProps } from "antd/lib/date-picker/generatePicker";

import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import DateValue from "components/blocks/TaskFields/Components/Date/Display/DateValue";
import { useBoardContext } from "lib/contexts/BoardContext";
import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import {
  DatePickerHidden, DatePickerWrapper,
  DueDateWrapper, PlaceholderImage, PlaceholderWrapper
} from "./styled";
import { DefaultColumnCodes } from "lib/types/boardTypes";
import TimePickerCustom from "components/blocks/TaskFields/Components/Date/Display/TimePickerCustom";
import { TaskPropertyTimeFormat } from "lib/types/kanbanTypes";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import useUpdateEffect from "lib/customHooks/useUpdateEffect";
import { compareMomentDates } from "lib/helpers/dateUtils";

interface CalendarProps {
  kanbanContext: Record<string, any>;
  disabled?: boolean;
}

const Calendar: FC<CalendarProps> = (props) => {
  const { kanbanContext, disabled } = props;
  const { task, setValue } = useTaskContext() as TaskContextInterface;
  const { board } = useBoardContext();

  const initialValue = task.dueDate ? moment(task.dueDate) : null;
  const [isDateOpened, setIsDateOpened] = useState(false);
  const [dateTimeValue, setDateTimeValue] = useState(initialValue);

  const { isCompletionEnabled } = useBoardFeatures(board || task.board);

  const wrapperRef = useRef(null) as RefObject<any>;
  const selectDropdownParent = (wrapperRef?.current) ? wrapperRef.current.offsetParent : wrapperRef.current;

  const isSyncedBoard = !!board?.externalDataSource;
  const isDisabled = disabled || isSyncedBoard;

  const dateColumn = board?.columns.find(column => column.code === DefaultColumnCodes.DueDate);

  const calendarPickerProps: PickerProps<Moment> = {
    ...((dateColumn?.dateFormat && dateColumn?.timeFormat !== TaskPropertyTimeFormat.None) && {
      renderExtraFooter: (mode) => (
        <TimePickerCustom
          value={dateTimeValue}
          saveTime={setTime}
          dateFormat={dateColumn?.dateFormat}
          timeFormat={dateColumn?.timeFormat}
        />
      )
    })
  };

  useUpdateEffect(() => {
    if (!isDateOpened && !compareMomentDates(dateTimeValue, initialValue)) {
      setValue({ cellName: DefaultColumnCodes.DueDate, cellValue: dateTimeValue?.toISOString() });
    }
  }, [isDateOpened]);

  useEffect(() => {
    const nextState = task.dueDate ? moment(task.dueDate) : null;
    setDateTimeValue(nextState);
  }, [task.dueDate]);

  const setDate = (momentObject: Moment | null) => {
    if (momentObject) {
      const nextState = dateTimeValue?.clone() || moment();
      nextState?.year(momentObject?.year());
      nextState?.month(momentObject?.month());
      nextState?.date(momentObject?.date());

      setDateTimeValue(nextState);
    }
  };

  const setTime = (momentObject: Moment | null) => {
    if (momentObject) {
      const nextState = dateTimeValue?.clone() || moment();
      nextState?.hour(momentObject?.hour());
      nextState?.minute(momentObject?.minute());
      nextState?.second(momentObject?.second());

      setDateTimeValue(nextState);
    }
  };

  const setDateNull = () => {
    setDateTimeValue(null);
    setValue({ cellName: "dueDate", cellValue: null });
  };

  const handleOnOutsideClick = (e: MouseEvent) => {
    const dropdownElement = (e.target as HTMLElement).closest(".ant-picker-dropdown");

    if (dropdownElement) {
      return null;
    }

    if (selectDropdownParent && !selectDropdownParent.contains(e.target)) {
      kanbanContext.setOverlay(false);
      return setIsDateOpened(false);
    }

    kanbanContext.setOverlay(false);
    return setIsDateOpened(false);
  };

  const handleCalendarOnChange = (value: moment.Moment | null, dateString: string) => {
    setDate(value);
  };

  const onCalendarOpen = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    const btn_type = target.dataset.btn_type || target.parentElement?.dataset.btn_type;
    if (btn_type === "close_btn") {
      return;
    }

    setIsDateOpened(true);
    kanbanContext.setOverlay(true);
  };

  return (
    <DatePickerWrapper>
      <OutsideClickHandler onOutsideClick={handleOnOutsideClick}>
        <DatePickerHidden>
          <DatePicker
            {...calendarPickerProps}
            open={isDateOpened}
            bordered={false}
            disabled={isDisabled}
            value={dateTimeValue}
            onChange={handleCalendarOnChange}
            getPopupContainer={() => kanbanContext.kanbanWrapperRef.current as HTMLElement}
          />
        </DatePickerHidden>
      </OutsideClickHandler>
      {!dateTimeValue && !isDisabled && (
        <PlaceholderWrapper onClick={!isDisabled ? onCalendarOpen : () => false}>
          <PlaceholderImage src={process.env.PUBLIC_URL + "/icons/calendar.svg"} alt="menu" />
        </PlaceholderWrapper>
      )}
      {dateTimeValue && (
        <DueDateWrapper
          onClick={!isDisabled ? onCalendarOpen : () => false}
          clickable={!isDisabled}
        >
          <DateValue
            dateValue={dateTimeValue}
            dateFormat={dateColumn?.dateFormat}
            timeFormat={dateColumn?.timeFormat}
            isCompleted={task.completed}
            propertyType={TaskPropertyClass.Default}
            setDateNull={setDateNull}
            disableColors={!isCompletionEnabled}
            disableClear={isDisabled}
          />
        </DueDateWrapper>
      )}
    </DatePickerWrapper>
  );
};

export default Calendar;
