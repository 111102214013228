import styled from "@emotion/styled/macro";

// Additional styles in "TaskTitleDragDrop"

export const DragDropContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 100%;
  opacity: 0;
`;
