import styled from "@emotion/styled/macro";

export const FieldTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const FieldWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 4px 24px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background-color: #f6f6f6;
  }
  
`;

export const DrawerTitle = styled.div`
  font-weight: 600;
`;
