import styled from "@emotion/styled/macro";

export const FieldAdditionalContainer = styled.div`
  position: relative;
`;

export const FieldAdditionalDescription = styled.div`
  color: #999;
  margin: 7px 0 10px 0;
`;

export const FieldAdditionalSettings = styled.div`
  color: #999;
`;
