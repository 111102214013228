export enum ContentType {
  Workspace = "workspace",
  Board = "board",
  Task = "task",
  Notification = "notification",
  Document = "document",
  FileContainer = "file-container",
  User = "user",
}

export const ContentTypeDisplayName: Record<ContentType, string> = {
  [ContentType.Workspace]: "project",
  [ContentType.Board]: "board",
  [ContentType.Task]: "task",
  [ContentType.Notification]: "notification",
  [ContentType.Document]: "document",
  [ContentType.FileContainer]: "file",
  [ContentType.User]: "user",
};

export const getContentTypeDisplayName = (contentType: ContentType | string, capitalize?: boolean): string => {
  let renderText = ContentTypeDisplayName[contentType as ContentType] || "record";
  if (capitalize) {
    renderText = renderText.charAt(0).toUpperCase() + renderText.slice(1);
  }

  return renderText;
};
