import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";

export const HeaderCustomWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  max-width: 100%;
  height: 100%;
  .ant-select-single {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    max-height: 0;
    visibility: hidden;
  }
`;

export const HeaderCustomRoot = styled.div`
  width: 100%;
`;

export const HeaderCustomDivider = styled.div`
  width: 1px;
  height: 40px;
  margin-right: 20px;
  margin-left: 15px;
  background-color: #f2f2f2;
`;

export const HeaderCustomControls = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderCustomSettingsBtn = styled(Link)`
  color: #999;
  font-size: 13px;
  font-weight: 500;
  &:hover, :focus {
    color: #999;
    font-size: 13px;
    text-decoration: none;
  }
`;

export const HeaderCustomSearchContainer = styled.div`
  width: 160px;
  min-width: 160px;
  padding-right: 10px;
`;
