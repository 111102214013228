import styled from "@emotion/styled/macro";
import { notificationsWrapper } from "pages/Notifications/styled";
import { NotificationsButtonsWrapper } from "pages/Notifications/styled";

export const NotificationCardContainer = styled.div`
  ${notificationsWrapper};
  &:hover {
    ${NotificationsButtonsWrapper} {
      opacity: 100%;
    }
  }
`;
