import styled from "@emotion/styled/macro";

export const ExportOptionWrapper = styled.div<{ inactive?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ inactive }) => inactive ? "default" : "pointer"};
`;

export const ExportOptionTag = styled.div`
  color: #01a5ed;
  font-size: 12px;
  font-weight: 600;
  border-radius: 20px;
  cursor: default;
`;

