import { PropertyValueType } from "lib/types/dataTypes";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";

export const ColumnTypeDisplayMessages = (propertyType: PropertyValueType | string, propertyClass: TaskPropertyClass | string) => {
  switch (propertyType) {
    case PropertyValueType.Boolean:
      return "Checkbox";
    case PropertyValueType.Currency:
      return "Currency";
    case PropertyValueType.Date:
      return (propertyClass === TaskPropertyClass.Default) ? "Due date" : "Date";
    case PropertyValueType.DateTime:
      return "Date-time";
    case PropertyValueType.Element:
      return "Element";
    case PropertyValueType.Elements:
      return "Elements";
    case PropertyValueType.File:
      return "Attachment";
    case PropertyValueType.Files:
      return "Attachments";
    case PropertyValueType.Formula:
      return "Formula";
    case PropertyValueType.Guid:
      return "GUID";
    case PropertyValueType.Id:
      return "ID";
    case PropertyValueType.Barcode:
      return "QR Code";
    case PropertyValueType.Link:
      return "Link";
    case PropertyValueType.MultiLineText:
      return "Multi-line text";
    case PropertyValueType.MultiSelect:
      return "Multiple select";
    case PropertyValueType.Number:
      return "Number";
    case PropertyValueType.Percent:
      return "Percent";
    case PropertyValueType.SingleLineText:
      return (propertyClass === TaskPropertyClass.Default) ? "Title" : "Single-line text";
    case PropertyValueType.SingleSelect:
      return (propertyClass === TaskPropertyClass.Default) ? "Status" : "Single select";
    case PropertyValueType.Url:
      return "URL";
    case PropertyValueType.User:
      return "User";
    case PropertyValueType.Users:
      return (propertyClass === TaskPropertyClass.Default) ? "Assignees" : "Users";
    case PropertyValueType.Viewpoint:
      return "Viewpoint";
    case PropertyValueType.Viewpoints:
      return "Viewpoints";
    default:
      return propertyType;
  }
};
