import { Select } from "antd";
import { selectFilterOperator, setFilterOperator } from "app/slices/tasksFilterAndSortSlice";
import { useAppDispatch, useAppSelector } from "app/store";
import { FilterOperatorEnum } from "lib/filters/filterOperators";
import React, { FC } from "react";
import { FilterOperatorText } from "../styled";

type FilterOperatorProps = {
  index: number
}

const FilterOperator: FC<FilterOperatorProps> = (props) => {
  const dispatch = useAppDispatch();
  const { index } = props;
  const operator = useAppSelector(selectFilterOperator);

  const onSelectFunction = (filterOperator: FilterOperatorEnum) => {
    dispatch(setFilterOperator(filterOperator));
  };

  if (!operator) {return null;}

  if (index === 0) {
    return <FilterOperatorText>Where</FilterOperatorText>;
  }

  if (index === 1) {
    return (
      <Select
        disabled={index !== 1}
        style={{ width: 70, marginRight: 7 }}
        value={operator}
        onSelect={(item) => onSelectFunction(item as FilterOperatorEnum)}
        dropdownStyle={{ minWidth: 70 }}
      >
        {Object.keys(FilterOperatorEnum)?.map(item => {
          return (
            <Select.Option value={item} key={item}>
              {item}
            </Select.Option>
          );
        })}
      </Select>
    );
  }

  return <FilterOperatorText>{operator}</FilterOperatorText>;
};

export default FilterOperator;
