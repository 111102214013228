import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { ForgeMarkupToolbarColorsProps } from "pages/Viewer/ForgeMarkupToolbar/ForgeMarkupToolbarColors";
import { noSelect } from "components/blocks/TaskTable/styled";

export const UnifiedToolbarContainer = styled.div`
  ${noSelect};
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  pointer-events: none;
`;


export const UnifiedToolbarFirstColumn = styled.div<{ marginLeft?: number | null }>`
  position: absolute;
  left: 0;
  top: auto;
  right: auto;
  bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  pointer-events: none;
  transition: margin 100ms ease;
  margin-left: ${({ marginLeft }) => `${(marginLeft)}px`};
`;

export const UnifiedToolbarExpander = styled(UnifiedToolbarFirstColumn) <{ isHidden?: boolean }>`
  height: 60px;
  width: 12px;
  border: 1px solid #e6e6e6;
  border-left: none;
  border-radius: 0 12px 12px 0;
  background-color: white;
  transition: all 300ms ease;
  pointer-events: auto;
  cursor: pointer;
  z-index: 40;
  &:hover {
    width: 14px;
  }
  ${({ isHidden }) => isHidden && css`
    display: none;
  `}
`;

export const UnifiedToolbarSecondColumn = styled(UnifiedToolbarFirstColumn)`
  margin-left: ${({ marginLeft }) => marginLeft ? marginLeft : "60px"};
`;

export const UnifiedToolbarBottomRight = styled(UnifiedToolbarFirstColumn) <{ marginRight?: number | null }>`
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  margin-right: ${({ marginRight }) => `${(marginRight)}px`};
`;

export const UnifiedToolbarTopRight = styled(UnifiedToolbarBottomRight)`
  top: 0;
  bottom: auto;
`;

export const UnifiedToolbarItemsContainer = styled.div<{ marginLeft?: number | null }>`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  margin: 4px 8px;
  padding: 4px;
`;

export const UnifiedToolbarItemsContainerRight = styled(UnifiedToolbarItemsContainer)`
  flex-direction: row;
  margin: 8px;
`;

export const UnifiedToolbarMainItemContainer = styled.div<{ active?: boolean, disabled?: boolean, size?: number, margin?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => `${size}px` || "40px"};
  height: ${({ size }) => `${size}px` || "40px"};
  margin: 2px;
  padding: 6px;
  border-radius: 7px;
  transition: background-color 200ms ease, color 200ms ease;
  cursor: pointer;
  color: #b0b0b0;
  font-size: 13px;
  font-weight: 500;
  pointer-events: auto;
  &:hover {
    color: #8966f6;
    background-color: #efebfd;
    path {
      fill: #8966f6;
    }
  }
  ${({ active }) => active && css`
    color: white;
    background-color: #8966f6;
    path {
      fill: #ffffff !important;
    }
    &:hover {
      color: white;
      background-color: #8966f6;
      path {
        fill: #ffffff;
      }
    }
  `}
  ${({ disabled }) => disabled && css`
    cursor: auto;
    path {
      fill: #b0b0b0;
      opacity: 0.5;
    }
    &:hover {
      background-color: white;
      path {
        fill: #b0b0b0;
      }
    }
  `}
`;

export const UnifiedToolbarSubItemContainer = styled(UnifiedToolbarMainItemContainer)`
  min-width: 30px;
  width: 30px;
  height: 30px;
`;

export const UnifiedToolbarDivider = styled.div`
  height: 1px;
  margin: 6px;
  background-color: #e6e6e6;
`;

export const UnifiedToolbarColorContainer = styled.div<ForgeMarkupToolbarColorsProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  ${({ active }) => active && css`
    border: 2px solid #8966f6;
    border-radius: 10px;
    pointer-events: none;
  `}
`;

export const UnifiedToolbarColorBody = styled.div<ForgeMarkupToolbarColorsProps>`
  width: 16px;
  height: 16px;
  border: 2px solid white;
  border-radius: 8px;
  ${({ color }) => color && css`
    background-color: ${color};
  `}
`;
