import { Column, TaskInterface } from "lib/types/tasksTypes";
import { EntityId } from "./entity";

export interface StatusInterface {
  id: EntityId;
  title: string;
  color: string;
  isFinal: boolean;
  sort: number;
  system: boolean;
  cards?: TaskInterface[];
}

export type assignee = number;

/** @deprecated */
export interface SortInterface {
  kanban: number;
  list: number;
  model: number;
}

export interface KanbanStructureInterface {
  lanes: StatusInterface[];
}

export interface ReorderTasksInterface {
  id: EntityId;
  sort: number | string;
}

export interface ReorderStatusInterface {
  id: EntityId;
  sort: number | string;
}

export interface SortTaskObject {
  id: EntityId;
  sort: number;
}

export interface SortObject {
  status: EntityId;
  tasks: SortTaskObject[];
}

export interface TaskPropertyValue extends Omit<Column, "accessor" | "maxWidth"> {
  columnId: string;
  value: any;
}

export enum TaskPropertyDateTimeFormat {
  Default = "default",
  Usa = "usa",
  Europe = "europe",
  Iso = "iso",
  Friendly = "friendly",
}

export enum TaskPropertyTimeFormat {
  Usa = "usa",
  Europe = "europe",
  None = "none",
}

export interface TaskPropertyOption {
  id: string;
  title: string;
  color: string;
  sort: number;
}
