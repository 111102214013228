import { updateNotification } from "app/slices/notificationsSlice";
import { useAppDispatch } from "app/store";
import { NotificationInterface } from "lib/types/notificationTypes";
import { WsEvents } from "lib/types/wsTypes";
import { useCallback } from "react";
import useWSSubscription from "../useWSSubscription";

const useWSUpdateNotification = () => {
  const dispatch = useAppDispatch();

  const callback = useCallback((data: NotificationInterface) => {
    dispatch(updateNotification(data));
  }, [dispatch]);

  useWSSubscription<NotificationInterface>({
    eventName: WsEvents.Notification.Update,
    callback,
    fnKey: "useWSUpdateNotification"
  });
};

export default useWSUpdateNotification;
