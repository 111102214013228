import { useCallback } from "react";
import { WorkspaceInterface } from "../../types/types";
import { updateWorkspace } from "app/slices/workspaceSlice";
import useWSSubscription from "../useWSSubscription";
import { useAppDispatch } from "app/store";
import { WsEvents } from "lib/types/wsTypes";
import { isCurrentInitiator, isAllowedWorkspaceUpdateMethod } from "lib/helpers/websocketUtils";

const useWSUpdateWorkspace = () => {
  const dispatch = useAppDispatch();

  const callback = useCallback((data: WorkspaceInterface) => {
    const updateAvailable = !isCurrentInitiator(data.transactionInitiator) || isAllowedWorkspaceUpdateMethod(data.transactionInitiator);
    if (updateAvailable) {
      dispatch(updateWorkspace({ id: data.id, workspace: data }));
    }
  }, [dispatch]);

  useWSSubscription<WorkspaceInterface>({
    eventName: WsEvents.Workspace.Update,
    callback,
    fnKey: "useWSUpdateWorkspace"
  });
};

export default useWSUpdateWorkspace;
