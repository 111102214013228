import { BoardInterface } from "./boardTypes";
import { PropertyValueType } from "./dataTypes";
import { OrganizationInterface } from "./organization";
import { BaseEntityInterface, EntityId } from "./entity";
import { ModelExtensions } from "./viewerModelExtensions";
import { TransactionInitiatorInterface } from "./wsTypes";
import { DisplayMode } from "./applicationTypes";
import { ForgeViewerState } from "./ForgeViewer";
import { TaskCreateFlow, TaskCreateFlowTab } from "components/blocks/TaskModals/TaskModalCreateAnnotation/lib";

export interface LoginInterface {
  email: string;
  username?: string;
  password: string;
  identifier?: string;
}

export interface RegisterInterface extends LoginInterface {
  firstName: string;
  lastName: string;
  version: UserPlatformVersion;
}

export interface UserResponse {
  jwt: string;
  user: UserInterface;
}

export interface UserInterface extends BaseEntityInterface {
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean | null;
  role: number;
  organization: string | null;
  firstName: string;
  lastName: string;
  description: string | null;
  phone: string | null;
  job: string | null;
  department: string | null;
  api_token: string;
  picture: PictureInterface | null;
  color: string | null;
  emailNotifications: EmailNotificationsType | null;
  preferences: UserPreferencesInterface | null;
  locale: UserLocalePreference | null;
  version: UserPlatformVersion | null;
}

export enum UserLocalePreference {
  English = "en",
  French = "fr",
}

export enum UserPlatformVersion {
  Amvp = "amvp",
  Atsk = "atsk",
}

export interface UserPreferencesInterface {
  fileContainer?: {
    displayMode?: {
      [key: string]: DisplayMode;
    };
    createFlow?: {
      flow?: TaskCreateFlow | null;
      tab?: TaskCreateFlowTab;
    }
  };
}

export type EmailNotificationsType = {
  activityUpdates: boolean;
  dailySummaries: boolean;
  mentionsOnly: boolean;
  reminders: boolean;
}

export interface UserInterfaceExtended extends Omit<UserInterface, "role"> {
  boards: BoardInterface[];
  ownerWorkspaces: WorkspaceInterface[];
  provider: string;
  role: {
    id: EntityId;
    description: string;
    name: string;
    type: string;
  }
  workspaces: WorkspaceInterface;
}

export interface PictureInterface {
  mime: string;
  size: number;
  url: string;
  previewUrl: string | null;
  provider: string;
  provider_metadata: string | null;
  created_at: string;
  updated_at: string;
  formats: {
    large: PictureFormatType;
    small: PictureFormatType;
    medium: PictureFormatType;
    thumbnail: PictureFormatType;
  }
}

type PictureFormatType = {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: string | null;
  size: number;
  width: number;
  height: number;
}

export interface ForgeBucketInterface {
  bucketKey: string;
  bucketName: string;
}

export interface ForgeObjectInterface {
  id: EntityId;
  name: string;
  visible: boolean;
  lastUpdated: string;
  objectKey: string;
  objectName: string;
  objectId: string;
  type: ForgeObjectTypes;
  extension: ModelExtensions;
  extractionFinished: boolean;
  picture: PictureInterface;
  status: ForgeObjectStatuses | null;
}

export enum ForgeObjectStatuses {
  Uploading = "uploading",
  Uploaded = "uploaded",
  Transferring = "transferring",
  Transferred = "transferred",
  Translating = "translating",
  Ready = "ready",
  FailedUpload = "failed_upload",
  FailedTransfer = "failed_transfer",
  FailedTranslate = "failed_translate",
}

export enum ForgeObjectTypes {
  Svf = "svf",
  Svf2 = "svf2",
}

export const ForgeObjectStatusDisplayNames: Record<ForgeObjectStatuses, string> = {
  [ForgeObjectStatuses.Uploading]: "Uploading...",
  [ForgeObjectStatuses.Uploaded]: "Uploaded",
  [ForgeObjectStatuses.Translating]: "Translating...",
  [ForgeObjectStatuses.Ready]: "Ready",
  [ForgeObjectStatuses.FailedUpload]: "Uploading error...",
  [ForgeObjectStatuses.FailedTranslate]: "Processing error...",
  [ForgeObjectStatuses.Transferring]: "Transferring...",
  [ForgeObjectStatuses.Transferred]: "Transferred",
  [ForgeObjectStatuses.FailedTransfer]: "Transferring error"
};

export interface ForgeSignedUrlInterface {
  signedUrl: string;
  expiration: number;
  singleUse: boolean;
}

export interface ForgeSettingsInterface {
  limitAccess: boolean;
  setInitialState: boolean;
  viewpoint: string | null;
  picture: PictureInterface | null;
  viewerVersion: string;
  viewerConfig?: Partial<ForgeSettingsViewerConfig>;
}

export interface ForgeSettingsViewerConfig {
  enableSvf2: boolean;
  disableIndexedDb: boolean;
  enableOpfs: boolean;
}

export interface ViewpointPayload {
  id?: EntityId;
  uid: string;
  viewpoint: ForgeViewerState;
  picture: EntityId | null;
  isLoading?: boolean;
}

export interface WorkspaceConnectionInterface {
  id?: EntityId;
  title: string;
  provider: OAuthProviders;
  email: string;
  flow: "ApiToken" | "OAuth" | "OAuth2";
  credentials: string;
  connectedId?: string;
  connectedTitle?: string;
  connectedData?: any;
  createdAt: Date;
  updatedAt: Date;
}

export enum OAuthProviders {
  Autodesk = "autodesk",
  Google = "google",
  Procore = "procore",
}

export interface WorkspacePlanInterface {
  id?: EntityId;
  code: ProjectPlans;
  trial?: boolean;
  expirationDate?: string;
}

export enum ProjectPlans {
  Free = "free",
  Plus = "plus",
  Enterprise = "enterprise",
}

export const ProjectPlanDisplayNames = {
  [ProjectPlans.Free]: "Free",
  [ProjectPlans.Plus]: "Plus",
  [ProjectPlans.Enterprise]: "Enterprise",
};

export interface WorkspaceInterface extends BaseEntityInterface {
  name: string;
  description: string | null;
  organization: OrganizationInterface | null;
  owner: UserInterface;
  /** @deprecated */
  collaborators: UserInterface[];
  members: Array<WorkspaceMemberInterface>;
  forgeBucket: ForgeBucketInterface
  forgeObjects: ForgeObjectInterface[]
  forgeViewerSettings?: ForgeSettingsInterface;
  boards: BoardInterface[]
  connections: WorkspaceConnectionInterface[];
  plan?: WorkspacePlanInterface;
  transactionInitiator: TransactionInitiatorInterface;
}

export interface WorkspaceMemberInterface {
  id: EntityId;
  user: UserInterface;
  role: MemberRoles
}

export enum MemberRoles {
  Owner = "owner",
  Admin = "admin",
  Editor = "editor",
  Commenter = "commenter",
  Viewer = "viewer",
}

export interface FileInterface extends BaseEntityInterface {
  name: string;
  alternativeText?: any;
  caption?: any;
  width: number;
  height: number;
  formats?: PictureFormatsInterface;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl?: any;
  provider: string;
  provider_metadata?: any;
  related: any[];
  author: UserInterface | null;
  lastEditor: UserInterface | null;
}

export interface PictureThumbnailInterface {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  width: number;
  height: number;
  size: number;
  path?: any;
  url: string;
}

export interface PictureFormatsInterface {
  small?: PictureThumbnailInterface;
  medium?: PictureThumbnailInterface;
  thumbnail?: PictureThumbnailInterface;
}

export interface CommentReactionInterface {
  id: EntityId;
  reaction: string;
  user: UserInterface;
}

export interface CommentInterface {
  id: EntityId;
  text: string;
  author: UserInterface;
  createdAt: Date;
  modifiedAt: Date | null;
  edited: boolean;
  attachments: (FileInterface)[];
  source?: TaskChangeSources;
  reactions?: Array<CommentReactionInterface>;
  /** dummy data indicating `comment` or `change` (not stored in DB) */
  item?: string;
}

export interface ChangeInterface {
  id: EntityId;
  user: UserInterface;
  columnId: string;
  createdAt: Date;
  hidden: boolean;
  newValue?: ChangeValue;
  oldValue?: ChangeValue;
  source?: TaskChangeSources;
  /** dummy data indicating `comment` or `change` (not stored in DB) */
  item?: string;
}

export enum TaskChangeSources {
  Automation = "automation",
  Sync = "sync",
}

export interface ChangeValue {
  type: PropertyValueType;
  value: any;
}

export enum ChangelogItemType {
  Change = "change",
  Comment = "comment",
}
