import { FC, useState } from "react";
import { Menu, Typography, Image } from "antd";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import MenuItemIcon from "components/ui/MenuItemIcon";
import TrashSvg from "components/icons/resizable/trash";
import UrlSvg from "components/icons/resizable/url";
import ExpandSvg from "components/icons/resizable/expand";
import { copyLink, getFileRevisionAnnotationUri } from "lib/helpers/navigationUtils";
import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import { extractQueryParams, unsetQueryParams } from "lib/helpers/urlUtils";
import { useHistory } from "react-router-dom";
import { AnnotationInterface } from "lib/types/fileContainer";
import FilesSvg from "components/icons/resizable/files";
import useTaskSetAnnotations from "lib/customHooks/dataLayer/task/useTaskSetAnnotations";
import { AnnotationContextMenuItems } from "./lib";

interface AnnotationContextMenuProps {
  menuItems: Array<AnnotationContextMenuItems>;
  annotation: AnnotationInterface;
  toggleVisibility: () => void;
}

const AnnotationCardMenuItems: FC<AnnotationContextMenuProps> = (props) => {
  const { annotation, menuItems, toggleVisibility } = props;
  const history = useHistory();

  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const { task } = useTaskContext() as TaskContextInterface;
  const { deleteAnnotation } = useTaskSetAnnotations({ task, updateDetailed: true });
  const { a: annotationId } = extractQueryParams();

  const allowedToDelete = useMemberPermission([MemberRoles.Owner]);
  const allowedToCreate = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const detailedUrl = getFileRevisionAnnotationUri(annotation.fileContainerId, annotation.revisionId, annotation.uid);

  const handleAnnotationPreview: MenuClickEventHandler = (info) => {
    info.domEvent.stopPropagation();
    setIsPreviewVisible(!isPreviewVisible);
  };

  const handleAnnotationNavigate: MenuClickEventHandler = (info) => {
    window.open(detailedUrl, "_blank");
  };

  const handleAnnotationCopyLink: MenuClickEventHandler = (info) => {
    copyLink(detailedUrl);
  };

  const handleAnnotationDelete: MenuClickEventHandler = (info) => {
    info.domEvent.stopPropagation();

    if (annotation) {
      deleteAnnotation(annotation.id);
    }
    if (annotation.uid === annotationId) {
      unsetQueryParams(history);
    }
  };

  return (
    <>
      <Menu onClick={toggleVisibility}>

        {/*Open Image Preview*/}
        {menuItems.includes(AnnotationContextMenuItems.OpenImagePreview) &&
          <Menu.Item key="preview" onClick={handleAnnotationPreview} disabled={!annotation?.picture}>
            <MenuItemIcon text="Preview image" disabled={!annotation?.picture} icon={<ExpandSvg size={12} />} />
          </Menu.Item>
        }

        {/*Open in Viewer*/}
        {menuItems.includes(AnnotationContextMenuItems.OpenInViewer) &&
          <Menu.Item key="navigate" onClick={handleAnnotationNavigate} >
            <MenuItemIcon text="Open on the page" icon={<FilesSvg size={12} />} />
          </Menu.Item>
        }

        {/*Copy link*/}
        {menuItems.includes(AnnotationContextMenuItems.CopyLink) &&
          <Menu.Item key="copyLink" onClick={handleAnnotationCopyLink}>
            <MenuItemIcon text="Copy annotation link" icon={<UrlSvg size={12} />} />
          </Menu.Item>
        }

        {/*Delete*/}
        {menuItems.includes(AnnotationContextMenuItems.Delete) && allowedToDelete &&
          <>
            <Menu.Divider />
            <Menu.Item key="delete" onClick={handleAnnotationDelete}>
              <Typography.Text>
                <MenuItemIcon text="Delete annotation" icon={<TrashSvg size={12} />} red />
              </Typography.Text>
            </Menu.Item>
          </>
        }
      </Menu>

      {menuItems.includes(AnnotationContextMenuItems.OpenImagePreview) &&
        <Image
          key={annotation.uid}
          src={annotation?.picture?.url}
          style={{ display: "none" }}
          preview={{
            visible: isPreviewVisible,
            src: annotation?.picture?.url,
            destroyOnClose: true,
            onVisibleChange: (visible) => setIsPreviewVisible(visible),
          }}
        />
      }
    </>
  );
};

export default AnnotationCardMenuItems;
