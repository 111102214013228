import { Select } from "antd";
import { SelectFieldStatusOption } from "components/blocks/FormComponents/SelectField/styled";
import useCurrentBoardCollaborators from "lib/customHooks/useCurrentBoardCollaborators";
import useCurrentBoardStatuses from "lib/customHooks/useCurrentBoardStatuses";
import { getUserFullName } from "lib/helpers/userUtils";
import { useContext } from "react";
import { TActiveTab, Trigger } from "../../../types";
import { AutomationActiveTabContext, RemovePropertyContext } from "../../automationContext";
import { ActionCard } from "../../styled";
import Card, { TCardOption } from "../Card/Card";

const { Option } = Select;

const Default = () => {
  const { onClickTriggerHeader } = useContext(RemovePropertyContext);
  const activeTab = useContext(AutomationActiveTabContext);
  return (
    <ActionCard onClick={onClickTriggerHeader} activeCard={activeTab === TActiveTab.Triggers}>
      Select a trigger
    </ActionCard>
  );
};

const ToThisBoard = () => (
  <Card type="triggerValue" header="Task added to this board" />
);

const Completed = () => (
  <Card type="triggerValue" header="Task marked complete" defaultValue="true" />
);

const Assignees = () => {
  const collaborators = useCurrentBoardCollaborators();
  const options = collaborators.map(collaborator => ({
    value: String(collaborator.id),
    text: getUserFullName(collaborator) || ""
  }));

  return (
    <Card
      type="triggerValue"
      header="Task assigned to"
      helpText="Choose assignee"
      options={options}
    />
  );
};

const DueDate = () => {
  const options = [
    { value: "updated", text: "New due date is set" },
    { value: "removed", text: "Due date is removed" }
  ];

  return (
    <Card
      type="triggerValue"
      header="Due date"
      helpText="Set task due date"
      options={options}
    />
  );
};

const Status = () => {
  const statuses = useCurrentBoardStatuses();
  const options: Array<TCardOption> = statuses.map(status => ({
    value: String(status.id),
    text: status.title || "",
    renderFn: () => (
      <Option value={String(status.id)} key={String(status.id)}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <SelectFieldStatusOption color={status.color} />
          {status?.title}
        </div>
      </Option>
    )
  }));

  return (
    <Card
      type="triggerValue"
      header="Status changed to"
      helpText="Choose status"
      options={options}
    />
  );
};

const Comment = () => (
  <Card
    type="triggerValue"
    header="Comment added"
    defaultValue="created"
  />
);

const Triggers = {
  [Trigger.default]: <Default />,
  [Trigger.toThisBoard]: <ToThisBoard />,
  [Trigger.completed]: <Completed />,
  [Trigger.assignees]: <Assignees />,
  [Trigger.dueDate]: <DueDate />,
  [Trigger.status]: <Status />,
  [Trigger.comments]: <Comment />,
  [Trigger.dueDateIsApproaching]: <ToThisBoard />,
  [Trigger.taskIsOverdue]: <ToThisBoard />
};

export default Triggers;
