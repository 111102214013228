import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const ArrowSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M59.5544 34.5542L13.721 80.3875C12.962 81.1733 12.5421 82.2259 12.5515 83.3183C12.561 84.4108 12.9992 85.4559 13.7718 86.2284C14.5443 87.0009 15.5894 87.4392 16.6819 87.4487C17.7743 87.4581 18.8268 87.0382 19.6127 86.2792L65.446 40.4458L75.0002 50L87.5002 12.5L50.0002 25L59.5544 34.5542Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default ArrowSvg;
