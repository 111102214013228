import { FC, memo, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from "react-beautiful-dnd";
import { Drawer } from "antd";
import { sortBy } from "lodash";

import { useAppSelector } from "app/store";
import ButtonControls from "components/ui/ButtonControls";
import HideSvg from "components/ui/ButtonControls/icons/hide";
import { useBoardViewContext } from "lib/contexts/BoardViewContext";
import useBoardSetColumns from "lib/customHooks/dataLayer/board/useBoardSetColumns";
import useBoardViewColumns from "lib/customHooks/views/useBoardViewColumns";
import { DefaultColumnCodes } from "lib/types/boardTypes";
import { EntityId } from "lib/types/entity";
import { Column } from "lib/types/tasksTypes";

import { activeHiddenFieldsClass } from "../styled";
import FieldComponent from "./FieldComponent";
import { DrawerTitle } from "./styled";

const FieldsDrawer: FC = () => {
  const board = useAppSelector(state => state.currentBoardReducer.board);
  const { isCustomView, viewData } = useBoardViewContext();
  const { updateBoardColumn, reorderBoardColumns } = useBoardSetColumns({ board, updateDetailed: true });

  const excludedColumns: string[] = [DefaultColumnCodes.Completion, DefaultColumnCodes.Description, DefaultColumnCodes.DescriptionRTE];
  const columnsFromBoard = board?.columns?.filter(column => (column.active && !excludedColumns.includes(column.code)));

  const { appliedColumns, saveViewColumns, toggleHidden } = useBoardViewColumns(String(board?.id), columnsFromBoard || []);

  let columnsData: Column[] = Array.from(appliedColumns);
  columnsData = sortBy(columnsData, item => item.sort);

  const [visible, setVisible] = useState(false);
  const [columns, setColumns] = useState<Array<Column>>(columnsData);

  useEffect(() => {
    if (columnsData) {
      setColumns(columnsData);
    }
    // TODO: fix dependencies
  }, [board, viewData]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onDragEnd: OnDragEndResponder = (result) => {
    const { source, destination } = result;
    if ((!destination) || (result.destination?.index === result.source.index)) {
      return;
    }

    const newData = Array.from(columns);
    const [movedRow] = newData.splice(source.index, 1);
    newData.splice(destination.index, 0, movedRow);
    const nextState = newData.map((item, index) => ({ ...item, sort: index }));
    setColumns(nextState);

    if (isCustomView) {
      saveViewColumns(nextState);
    } else {
      reorderBoardColumns(nextState);
    }
  };

  const onUpdateVisibility = (columnId: EntityId, hidden: boolean) => {
    isCustomView
      ? toggleHidden(columnId, hidden)
      : updateBoardColumn(columnId, { hidden });
  };

  const Columns = columns?.map((column, index) => {
    if (column.code === DefaultColumnCodes.Title) {
      return (
        <div key={column.id}>
          <FieldComponent updateVisibility={onUpdateVisibility} column={column} />
        </div>
      );
    } else {
      return (
        <Draggable
          draggableId={`draggableId-${column.id}`}
          key={`draggableId-${column.id}`}
          index={index}
          isDragDisabled={column.code === DefaultColumnCodes.Title}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <FieldComponent updateVisibility={onUpdateVisibility} column={column} />
            </div>
          )}
        </Draggable>
      );
    }
  });

  const isAnyColumnHidden = columns.some(column => column.hidden);

  return (
    <>
      <ButtonControls
        icon={<HideSvg />}
        text="Hide fields"
        onClick={showDrawer}
        className={isAnyColumnHidden ? activeHiddenFieldsClass : ""}
      />
      <Drawer
        title={<DrawerTitle>Fields visibility</DrawerTitle>}
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
        width={350}
        destroyOnClose={false}
      >
        <div style={{ margin: "-12px -24px" }} >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="board-columns-list">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {Columns}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Drawer>
    </>
  );
};

export default memo(FieldsDrawer);
