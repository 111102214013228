import { FC, useCallback, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import useAsyncData from "lib/customHooks/useAsyncData";
import WorkspaceService from "services/WorkspaceService";
import { SectionSubheading } from "styles/common";
import { StyledButton } from "styles/common";
import CustomStyledButton from "../CustomStyledButton";
import { commonModalProps } from "../ModalDialog/lib";

interface AddWorkspaceModalProps {
  visible?: boolean;
  onClose: () => void;
}

const AddWorkspaceModal: FC<AddWorkspaceModalProps> = (props) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const createWorkspaceFn = useCallback(() => {
    return WorkspaceService.add(name, description);
  }, [description, name]);

  const { loadData: fetchCreate, isLoading } = useAsyncData({
    fetchFn: createWorkspaceFn
  });

  const onCreate = async () => {
    if (!isLoading) {
      await fetchCreate().finally();
      props.onClose();
    }
  };

  return (
    <Modal
      {...commonModalProps}
      width={430}
      title="Create new project"
      visible={props.visible}
      onOk={props.onClose}
      onCancel={props.onClose}
      bodyStyle={{ padding: "15px 20px" }}
      footer={[
        <div key="cancel" style={{ display: "inline-block", marginRight: "10px" }}>
          <StyledButton onClick={props.onClose}>
            Cancel
          </StyledButton>
        </div>,
        <CustomStyledButton
          key="ok"
          btnColor="purple"
          disabled={!name.trim() || isLoading}
          isLoading={isLoading}
          onClick={onCreate}
          value="Create"
        />
      ]}
    >
      <SectionSubheading style={{ marginTop: 0 }}>Name</SectionSubheading>
      <Input
        autoFocus
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <SectionSubheading>Description</SectionSubheading>
      <TextArea
        disabled={false}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        rows={3} />
    </Modal>
  );
};

export default AddWorkspaceModal;
