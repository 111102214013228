import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { BoardHeaderViewIcon } from "layout/HeaderCustom/BoardHeader/styled";

interface BoardSettingsProps {
  selected?: boolean;
}

export const BoardSettingsViewsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const BoardSettingsViewCardIcon = styled.div<BoardSettingsProps>`
  display: flex;
  align-items: center;
  height: 20px;
  font-size: 13px;
  font-weight: 500;
  margin-right: 0;
  ${({ selected }) => selected && css`
    path {
      fill: #40a9ff;
    }
  `}
`;

export const BoardSettingsViewCardTitle = styled.div<BoardSettingsProps>`
  font-size: 12px;
  font-weight: 500;
  color: #999;
  ${({ selected }) => selected && css`
    color: #40a9ff;
  `}
`;
