import { TaskInterface } from "../types/tasksTypes";
import { FilterValueType, FilterVersion, TAnyFilters } from "./filters";

export enum FilterType {
  Text = "text",
  SingleSelect = "singleSelect",
  MultipleSelect = "multipleSelect",
  Date = "date",
  "Due date" = "Due date",
  "Created time" = "Created time",
  "Last modified" = "Last modified",
  "Closing date" = "Closing date",
  Boolean = "boolean",
  Number = "number",
  Users = "users",
  User = "user",
  Assignees = "Assignees",
  "Created by" = "Created by",
  "Last modified by" = "Last modified by",
  uID = "uID",
}

const defaultFieldTypes: Record<string, FilterType> = {
  "Created time": FilterType.Date,
  "Last modified": FilterType.Date,
  "Closing date": FilterType.Date,
  "Title": FilterType.Text,
  uID: FilterType.Text,
};

export const getDefaultFilterTypeByTitle = (titleKey: string) => {
  return defaultFieldTypes[titleKey];
};

// Defines how to render filter values UI component
export const getFilterType = (
  currentFilter: TAnyFilters,
  types: { [key: string]: FilterType }
) => {
  let type = getDefaultFilterTypeByTitle(currentFilter.titleKey) || types?.[currentFilter.titleKey];

  // backward support (v1 "Due date" will be rendered as a list)
  if (currentFilter.version && currentFilter.version !== FilterVersion.v1 && currentFilter.titleKey === FilterType["Due date"]) {
    type = FilterType["Due date"];
  }

  // backward support (v1 "Assignees" will be rendered as a Single Select (or default))
  if (currentFilter.version && currentFilter.version !== FilterVersion.v1 && currentFilter.titleKey === FilterType.Assignees) {
    type = FilterType.Assignees;
  }

  return type;
};

export type TFilterFn = {
  [key: string]: TFilterFnValue;
}

export type TFilterFnValue = {
  filterValues: (...args: any[]) => FilterValueType[];
  filterFn: (...args: any[]) => TaskInterface[];
  sort?: number;
  color?: string | null;
}

export enum FilterEnv {
  MY_TASK = "MY_TASKS",
  PROJECT_TASKS_LIST = "PROJECT_TASKS_LIST",
  VIEWER_TASKS = "VIEWER_TASKS",
  ANNOTATION_TASKS = "ANNOTATION_TASKS",
}
