import React, { FC, RefObject, useRef, useState } from "react";
import { Input, Dropdown } from "antd";

import useMemberPermission from "lib/customHooks/useMemberPermission";
import { useBoardContext } from "lib/contexts/BoardContext";
import { KeyboardKeys } from "lib/types/keyCodes";
import { MemberRoles } from "lib/types/types";
import ButtonIcon from "components/ui/ButtonIcon";

import { eventObserver } from "../lib";
import { ADD_CARD } from "../types";
import { LaneHeaderType } from "./types";
import LaneHeaderMenu from "./LaneHeaderMenu";
import { LaneHeaderDiv, LaneHeaderControls, LaneHeaderTag, LaneHeaderTitle } from "./styled";

const LaneHeader: FC<LaneHeaderType> = (props) => {
  const { id, title, color, onDelete, updateTitle, isFinal, system: isSystem } = props;

  const { board } = useBoardContext();
  const isSynced = !!board?.externalDataSource;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const allowedToCreate = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const inputRef = useRef(null) as RefObject<any>;
  const [isTitleEditable, setTitleEditable] = useState(false);
  const [isDropdownOpened, setDropdownState] = useState(false);

  const onVisibleChange = (visible: boolean) => setDropdownState(visible);
  const onMenuBtnClick = async (visible: boolean, callback: () => Promise<void>) => {
    setDropdownState(visible);

    if (callback) {
      await callback();
    }
  };

  const onTitleEdit = async () => {
    if (!isSystem && allowedToEdit) {
      setTitleEditable(true);
      inputRef.current.focus();
    }
  };

  const onTitleUpdate = ({ target }: React.KeyboardEvent | React.FocusEvent) => {
    updateTitle((target as HTMLInputElement).value);
    setTitleEditable(false);
  };

  const onTitleChange = (event: React.KeyboardEvent) => {
    if (event.key === KeyboardKeys.Escape || event.key === KeyboardKeys.Tab) {
      setTitleEditable(false);
      event.preventDefault();
    }
  };

  const onAddCard = () => eventObserver.broadcast({ type: ADD_CARD, laneId: id });

  const HeaderMenu = (
    <LaneHeaderMenu
      laneId={id}
      onMenuBtnClick={onMenuBtnClick}
      onMenuVisibleChange={onVisibleChange}
      onTitleEdit={onTitleEdit}
      onDelete={onDelete}
      isFinal={isFinal}
      titleColor={color}
    />
  );

  return (
    <LaneHeaderDiv>
      <LaneHeaderTitle>
        {!isTitleEditable && (
          <LaneHeaderTag
            bg={color}
            onClick={onTitleEdit}
            style={{ maxWidth: 170 }}
          >
            {title}
          </LaneHeaderTag>
        )}
        {isTitleEditable && (
          <Input
            autoFocus
            bordered={false}
            ref={inputRef}
            defaultValue={title}
            placeholder="Status"
            onPressEnter={onTitleUpdate}
            onKeyDown={onTitleChange}
            onBlur={onTitleUpdate}
          />
        )}
      </LaneHeaderTitle>
      <LaneHeaderControls>
        {!isSystem && allowedToEdit &&
          <Dropdown
            overlay={HeaderMenu}
            trigger={["click"]}
            placement="bottomRight"
            open={isDropdownOpened}
            onOpenChange={onVisibleChange}
          >
            <ButtonIcon iconUrl="/icons/more.svg" />
          </Dropdown>
        }
        {!isSynced && allowedToCreate &&
          <ButtonIcon onClick={onAddCard} iconUrl="/icons/add.svg" />
        }
      </LaneHeaderControls>
    </LaneHeaderDiv>
  );
};

export default LaneHeader;
