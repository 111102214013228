import styled from "@emotion/styled/macro";

export const ViewMenuBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 14px;
  height: 14px;
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  color: white;
  background-color: #40a9ff;
  border-radius: 7px;
  padding: 2px 4px;
  margin-left: 4px;
`;

export const ViewMenuDefaultWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`;

export const ViewMenuDefaultTag = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #999;
  margin-left: 5px;
  margin-top: 2px;
`;

export const ViewMenuAddView = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #999;
  margin-left: 7px;
`;
