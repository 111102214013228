import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const HtmlSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.5291 20.5167C64.2512 20.5145 62.067 19.6086 60.4562 17.9978C58.8453 16.387 57.9395 14.203 57.9374 11.925V0H19.5457C16.5727 -2.04167e-07 13.7214 1.18075 11.6188 3.2826C9.51614 5.38446 8.33436 8.23533 8.33325 11.2083V78.8C8.33656 81.7717 9.51933 84.6204 11.6217 86.7204C13.7241 88.8204 16.5741 90 19.5457 90H65.6291C67.102 90.0004 68.5603 89.7108 69.9212 89.1475C71.282 88.5846 72.5187 87.7587 73.5603 86.7175C74.602 85.6762 75.4282 84.44 75.992 83.0792C76.5557 81.7187 76.8457 80.2604 76.8457 78.7875V20.5167H66.5291Z" fill="#00C650"/>
        <path d="M76.8458 20.5167H66.5292C64.2512 20.5145 62.0671 19.6086 60.4562 17.9978C58.8454 16.387 57.9396 14.203 57.9375 11.925V0L76.8458 20.5167Z" fill="#00C650"/>
        <path d="M84.975 71.0833H32.8749C29.1792 71.0833 26.1833 74.0791 26.1833 77.7749V93.3083C26.1833 97.0041 29.1792 99.9999 32.8749 99.9999H84.975C88.6708 99.9999 91.6666 97.0041 91.6666 93.3083V77.7749C91.6666 74.0791 88.6708 71.0833 84.975 71.0833Z" fill="#00C650"/>
        <path d="M32.3537 93.75C32.1199 93.75 31.911 93.6613 31.7268 93.4842C31.5497 93.3 31.4612 93.0913 31.4612 92.8575V80.235C31.4612 80.0013 31.5497 79.7959 31.7268 79.6188C31.911 79.4346 32.1199 79.3425 32.3537 79.3425C32.5874 79.3425 32.7928 79.4346 32.9699 79.6188C33.1541 79.7959 33.2462 80.0013 33.2462 80.235V85.5688H41.9162V80.235C41.9162 80.0013 42.0047 79.7959 42.1818 79.6188C42.3589 79.4346 42.5678 79.3425 42.8087 79.3425C43.0424 79.3425 43.2478 79.4346 43.4249 79.6188C43.6091 79.7959 43.7012 80.0013 43.7012 80.235V92.8575C43.7012 93.0913 43.6091 93.3 43.4249 93.4842C43.2478 93.6613 43.0424 93.75 42.8087 93.75C42.5678 93.75 42.3589 93.6613 42.1818 93.4842C42.0047 93.3 41.9162 93.0913 41.9162 92.8575V87.3538H33.2462V92.8575C33.2462 93.0913 33.1541 93.3 32.9699 93.4842C32.7928 93.6613 32.5874 93.75 32.3537 93.75Z" fill="white"/>
        <path d="M52.4388 93.75C52.1413 93.75 51.918 93.6754 51.7693 93.5267C51.6205 93.3779 51.5463 93.155 51.5463 92.8575V81.1488H47.4238C47.1263 81.1488 46.9031 81.0742 46.7544 80.9254C46.6056 80.7767 46.5312 80.5538 46.5312 80.2563C46.5312 79.9588 46.6056 79.7354 46.7544 79.5867C46.9031 79.4379 47.1263 79.3638 47.4238 79.3638H57.4538C57.7513 79.3638 57.9743 79.4379 58.123 79.5867C58.2718 79.7354 58.3463 79.9588 58.3463 80.2563C58.3463 80.5538 58.2718 80.7767 58.123 80.9254C57.9743 81.0742 57.7513 81.1488 57.4538 81.1488H53.3313V92.8575C53.3313 93.1409 53.2534 93.3604 53.0976 93.5163C52.9488 93.6721 52.7293 93.75 52.4388 93.75Z" fill="white"/>
        <path d="M62.0705 93.75C61.4755 93.75 61.178 93.4525 61.178 92.8575V80.235C61.178 79.64 61.4755 79.3425 62.0705 79.3425C62.4601 79.3425 62.7859 79.5338 63.048 79.9163L68.063 87.46L67.6805 87.5238L72.738 79.9163C72.9788 79.5338 73.3401 79.3425 73.8217 79.3425C74.3034 79.3425 74.5442 79.64 74.5442 80.235V92.8575C74.5442 93.4525 74.2467 93.75 73.6517 93.75C73.0567 93.75 72.7592 93.4525 72.7592 92.8575V82.02L73.3117 82.2113L68.8492 88.7775C68.5871 89.16 68.2259 89.3513 67.7655 89.3513C67.4467 89.3513 67.1563 89.16 66.8942 88.7775L62.4317 82.2113L62.963 82.36V92.8575C62.963 93.4525 62.6655 93.75 62.0705 93.75Z" fill="white"/>
        <path d="M79.8551 93.75C79.2601 93.75 78.9626 93.4525 78.9626 92.8575V80.235C78.9626 79.64 79.2601 79.3425 79.8551 79.3425C80.4501 79.3425 80.7477 79.64 80.7477 80.235V91.965H86.6339C87.2289 91.965 87.5264 92.2625 87.5264 92.8575C87.5264 93.4525 87.2289 93.75 86.6339 93.75H79.8551Z" fill="white"/>
        <path d="M25.6834 47.5793C25.6788 47.3997 25.7346 47.2234 25.8417 47.0793C25.9682 46.9168 26.1202 46.7759 26.2918 46.6626C26.5514 46.5272 26.82 46.4105 27.0959 46.3126L27.9001 45.9793L31.1167 44.6251C31.5639 44.4168 32.3973 44.0697 33.6167 43.5834L35.146 42.9584L35.6959 42.7626L35.7917 42.7209C35.8177 42.7151 35.8449 42.7151 35.8709 42.7209C35.9325 42.6934 35.9993 42.6789 36.0668 42.6793C36.1958 42.6726 36.3248 42.6918 36.4464 42.7351C36.568 42.7789 36.6797 42.8464 36.7751 42.9334C36.8546 43.0101 36.918 43.1022 36.9617 43.2039C37.0053 43.3051 37.0283 43.4147 37.0293 43.5251V44.5834C37.0255 44.7805 36.9631 44.9718 36.85 45.1334C36.7367 45.3155 36.5648 45.4534 36.3626 45.5251L29.7917 48.2126L36.3626 50.9001C36.5577 50.9797 36.7265 51.1126 36.85 51.2834C36.9674 51.438 37.0305 51.6268 37.0293 51.8209V52.8793C37.0267 53.1047 36.9354 53.3205 36.7751 53.4793C36.6814 53.5693 36.5701 53.6393 36.4482 53.6843C36.3264 53.7293 36.1965 53.7489 36.0668 53.7418C35.9997 53.7414 35.9334 53.7289 35.8709 53.7043L35.6959 53.6459L35.146 53.4501L33.6167 52.8209L31.1167 51.7834L27.9001 50.4293L27.0959 50.0959C26.8334 49.9918 26.5668 49.8751 26.2918 49.7418C26.1206 49.6622 25.9739 49.5384 25.8669 49.383C25.7599 49.228 25.6965 49.0468 25.6834 48.8584V47.5793Z" fill="#00C650"/>
        <path d="M38.8418 54.875C38.582 54.8841 38.3281 54.7962 38.1293 54.6291C38.0407 54.5604 37.9689 54.4729 37.9191 54.3725C37.8693 54.2721 37.8429 54.1621 37.8418 54.05C37.8418 53.8916 38.3126 52.8 39.2543 50.7166L40.6293 47.6958L40.9209 47.0666L43.1376 42.1458L43.9209 40.4416L44.0793 40.05C44.14 39.9054 44.2125 39.766 44.296 39.6333C44.4059 39.4528 44.5618 39.3049 44.7478 39.2046C44.9338 39.1043 45.1431 39.0552 45.3543 39.0625H46.3334C46.5944 39.0536 46.8495 39.1411 47.05 39.3083C47.1378 39.3773 47.2089 39.4652 47.258 39.5655C47.307 39.6658 47.3329 39.7758 47.3335 39.8875C46.9999 40.8591 46.6075 41.8096 46.1584 42.7333L45.9418 43.2041L44.2751 46.85L42.0584 51.7708L41.2751 53.4791L41.1167 53.8708C41.0607 54.0275 40.9895 54.1779 40.9042 54.3208C40.7862 54.4983 40.6257 54.6437 40.4372 54.7433C40.2487 54.8429 40.0383 54.8941 39.8251 54.8916L38.8418 54.875Z" fill="#00C650"/>
        <path d="M49.1376 53.75C49.0052 53.7583 48.8725 53.7391 48.7478 53.6941C48.623 53.6491 48.509 53.5787 48.4126 53.4875C48.3321 53.4096 48.2682 53.3162 48.2245 53.2133C48.1808 53.1104 48.1584 52.9996 48.1585 52.8875V51.8291C48.1566 51.6371 48.2148 51.4491 48.3251 51.2916C48.4497 51.1166 48.6239 50.9833 48.8251 50.9083L55.4126 48.2208L48.8251 45.5333C48.6245 45.4446 48.4537 45.3 48.3335 45.1166C48.2228 44.9575 48.1619 44.7687 48.1585 44.575V43.5C48.1586 43.3883 48.182 43.2783 48.2272 43.1762C48.2724 43.0746 48.3384 42.9833 48.421 42.9083C48.6175 42.7333 48.8747 42.6421 49.1376 42.6541C49.1999 42.6525 49.2616 42.6671 49.3168 42.6958C49.3527 42.6896 49.3893 42.6896 49.4252 42.6958L49.5127 42.7375C49.4835 42.7375 49.6627 42.7875 50.0417 42.9333L51.5917 43.5583C51.5113 43.5221 52.3447 43.8696 54.0917 44.6C55.0447 44.9916 56.1363 45.4554 57.3667 45.9916C57.4459 46.0166 57.5501 46.0583 57.6834 46.1083L58.1334 46.2875C58.3976 46.3816 58.6526 46.4987 58.8959 46.6375C59.0717 46.7166 59.2217 46.8429 59.3292 47.0029C59.4363 47.1625 59.4972 47.3491 59.5042 47.5416V48.8333C59.5026 49.0108 59.4442 49.1829 59.3376 49.325C59.2222 49.4875 59.0713 49.6216 58.8959 49.7166C58.6626 49.8333 58.3792 49.9583 58.0626 50.0916L57.2292 50.425L49.9709 53.4625C49.7626 53.5666 49.6376 53.6333 49.6001 53.6583L49.521 53.7166L49.1376 53.75Z" fill="#00C650"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default HtmlSvg;
