import { EntityId } from "lib/types/entity";
import { captureException } from "lib/utils/sentry";
import useTaskPerformRequest, { TaskPerformRequestHookProps } from "./useTaskPerformRequest";
import TasksService from "services/TasksService";
import { ViewpointPayload } from "lib/types/types";
import { ViewpointType } from "lib/types/tasksTypes";
import UploadService from "services/UploadService";

const useTaskSetViewpoints = (props: TaskPerformRequestHookProps) => {
  const { task } = props;
  const performRequest = useTaskPerformRequest(props);

  const addViewpoint = async (data: ViewpointPayload, blob?: Blob) => {
    if (!task) {
      return;
    }

    const viewpoints = Array.from(task.viewpoints);
    viewpoints.push(data as unknown as ViewpointType);

    const requestFn = async () => {

      const viewpointPayload: ViewpointPayload = {
        uid: data.uid,
        viewpoint: data.viewpoint,
        picture: null,
      };

      if (blob) {
        const [picture] = await UploadService.upload(blob as File);
        viewpointPayload.picture = picture.id;
      }

      return TasksService.createViewpoint(task.id, viewpointPayload);
    };

    return performRequest({ viewpoints }, requestFn);
  };

  const updateViewpoint = async (viewpointId: EntityId, data: Partial<ViewpointPayload>, blob?: Blob) => {
    if (!task) {
      return;
    }

    const viewpoints = Array.from(task.viewpoints);
    const viewpointIndex = viewpoints.findIndex(viewpoint => String(viewpoint.id) === String(viewpointId));
    if (viewpointIndex === -1) {
      captureException(`Cannot find viewpoint ${viewpointId}`);
      return;
    }

    viewpoints[viewpointIndex] = {
      ...viewpoints[viewpointIndex],
      viewpoint: JSON.stringify(data.viewpoint),
      picture: null,
      isLoading: data.isLoading,
    };

    const requestFn = async () => {
      const viewpointPayload: Partial<ViewpointPayload> = {
        viewpoint: data.viewpoint,
        picture: null,
      };

      if (blob) {
        const [picture] = await UploadService.upload(blob as File);
        viewpointPayload.picture = picture.id;
      }
      return TasksService.updateViewpoint(task.id, viewpointId, viewpointPayload);
    };

    performRequest({ viewpoints }, requestFn);
  };

  const deleteViewpoint = async (viewpointId: EntityId) => {
    if (!task) {
      return;
    }

    const viewpointIndex = task.viewpoints.findIndex(viewpoint => String(viewpoint.id) === String(viewpointId));
    if (viewpointIndex === -1) {
      captureException(`Cannot find viewpoint ${viewpointId}`);
      return;
    }

    const viewpoints = Array.from(task.viewpoints);
    viewpoints.splice(viewpointIndex, 1);

    const requestFn = () => TasksService.deleteViewpoint(task.id, viewpointId);

    performRequest({ viewpoints }, requestFn);
  };

  return { addViewpoint, updateViewpoint, deleteViewpoint };
};

export default useTaskSetViewpoints;
