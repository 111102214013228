import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { Input, Tabs } from "antd";
import AutomationsCssCard from "../AutomationsDrawer/AutomationItem/styled";

const { TabPane } = Tabs;

export const AutomationsBuilderContent = styled.div`
  border-top: 1px solid #e4e4e4;
  padding: 14px 16px;
`;

export const AutomationsBuilderStatus = styled.div`
  font-size: 12px;
  color: #999;
`;

export const AutomationsTabsCard = styled.div<{ disabled?: boolean }>`
  ${AutomationsCssCard};
  width: 100%;
  font-size: 13px;
  margin-bottom: 10px;
  background-color: ${({ disabled }) => disabled ? "#f9f9f8" : "white"};
  color: ${({ disabled }) => disabled ? "#999999" : "black"};
  cursor: ${({ disabled }) => disabled ? "text" : "pointer"};
`;

export const AutomationsClearWrapper = styled.div`
  visibility: hidden;
  transition: opacity 300ms ease;
  opacity: 0;
`;

export const AutomationsBuilderCard = styled.div`
  ${AutomationsCssCard};
  padding: 0;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  &:hover {
    border: solid 1px #cdcdcd;
    ${AutomationsClearWrapper} {
      visibility: visible;
      opacity: 100%;
    }
  }
`;

export const AutomationsModalLayout = styled.div`
  display: flex;
  width: 100%;
  height: 430px;
  background-color: #f9f9f8;
  padding: 0;
`;

export const AutomationModalBuilder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px;
  flex-grow: 1;
`;

export const AutomationsModalControls = styled.div`
  border-left: 1px solid #ededed;
  min-height: 100%;
  width: 300px;
  background-color: white;
  .ant-tabs-nav-wrap {
    margin: 0 20px;
  }
  .ant-tabs-tabpane {
    padding: 0 20px 8px 20px;
  }
`;

export const AutomationsTabPane = styled(TabPane)`
  padding: 5px 20px 20px 20px;
`;

export const AutomationsTabsHeading = styled.div`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const AutomationsInput = styled(Input)`
  width: 300px;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
  background-color: transparent;
  border-radius: 7px;
`;

export const ActionCard = styled(AutomationsBuilderCard) <{ activeCard?: boolean }>`
  justify-content: center;
  min-height: 54px;
  color: #999999;
  ${({ activeCard }) => activeCard && css`
    color: #40a9ff;
    border-color: #40a9ff;
    border-style: dashed;
    border-width: 2px;
    background-color: rgba(64, 169, 255, 0.05);
    &:hover {
      border-color: #40a9ff;
      border-style: dashed;
      border-width: 2px;
    }
  `}
`;
