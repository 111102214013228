import styled from "@emotion/styled/macro";

export const CardMenuWrapper = styled.div`
  padding: 7px 0;
  position: relative;
  min-width: 160px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0 3px 6px -4px, rgba(0, 0, 0, 0.08) 0px 6px 16px, rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  .ant-upload {
    width: 100%;
  }
`;

export const CardMenuItem = styled.div`
  display: block;
  padding: 6px 12px;
  cursor: pointer;
  transition: 0.2s;
  &:first-of-type {
    margin-top: 0;
  }
  &:hover {
    background-color: #f5f5f5;
  }
`;
