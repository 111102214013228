import { useCallback } from "react";
import { WsEvents } from "lib/types/wsTypes";
import { FileContainerInterface } from "lib/types/fileContainer";
import { useAppDispatch } from "app/store";
import { addFileContainerToCollection } from "app/slices/fileContainerSlice";
import useWSSubscription from "../useWSSubscription";

const useWSCreateFileContainer = () => {
  const dispatch = useAppDispatch();

  const callback = useCallback((data: FileContainerInterface) => {
    dispatch(addFileContainerToCollection(data));
  }, [dispatch]);

  useWSSubscription<FileContainerInterface>({
    eventName: WsEvents.FileContainer.Create,
    callback,
    fnKey: "useWSCreateFileContainer"
  });

};

export default useWSCreateFileContainer;
