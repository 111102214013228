import { FC, useState } from "react";
import { Modal } from "antd";

import TasksService from "services/TasksService";
import { copyLink, getTaskDetailModelUri, getTaskDetailUri } from "lib/helpers/navigationUtils";
import { DisplayView } from "lib/types/applicationTypes";
import { useBoardContext } from "lib/contexts/BoardContext";
import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { cancelButtonProps, okButtonProps } from "styles/common-vars";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import { useViewerContext } from "lib/contexts/ViewerContext";
import DuplicateTaskModal from "components/blocks/DuplicateTaskModal";
import ExportTaskModal from "components/blocks/ExportTaskModal";
import ImportViewpointsModal from "components/blocks/ImportViewpointsModal";
import { CardMenuItem, CardMenuWrapper } from "pages/Board/BoardKanban/Card/CardMenu/styled";
import ExportSvg from "components/icons/export";
import UrlSvg from "components/icons/resizable/url";
import TrashSvg from "components/icons/resizable/trash";
import ImportSvg from "components/icons/import";
import OpenSvg from "components/icons/resizable/open";
import MenuItemIcon from "components/ui/MenuItemIcon";
import ModelSvg from "components/icons/model";
import DuplicateSvg from "components/icons/resizable/duplicate";
import useWorkspaceById from "lib/customHooks/useWorkspaceById";
import useSavingState from "lib/customHooks/useSavingState";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

interface TaskDrawerMenuProps {
  handleVisibility: (visible: boolean) => void;
  hideOpenNewTab?: boolean;
  displayView?: DisplayView;
}

const TaskDrawerMenu: FC<TaskDrawerMenuProps> = (props) => {
  const { handleVisibility } = props;

  const { setSavingStart, setSavingFinish } = useSavingState();
  const [isShowExportModal, setIsShowExportModal] = useState(false);
  const [isShowImportModal, setIsShowImportModal] = useState(false);
  const [isShowDuplicateModal, setIsShowDuplicateModal] = useState(false);

  const allowedToCreate = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const allowedToDelete = useMemberPermission([MemberRoles.Owner]);

  const { viewer } = useViewerContext();
  const { board } = useBoardContext();
  const { task } = useTaskContext() as TaskContextInterface;
  const { id } = task;
  const workspace = useWorkspaceById(task?.board.workspace || "");
  const { isModelsEnabled, terminology } = useBoardFeatures(board || task.board);

  const unitName = terminology.single.toLowerCase();
  const isSynced = !!board?.externalDataSource || !!task?.board?.externalDataSource;
  const isModelView = (props.displayView === DisplayView.Model);

  const handleTaskDelete = async () => {
    setSavingStart();
    await TasksService.deleteTask(id);
    setSavingFinish();
  };

  const handleTaskDuplicate = async () => {
    setSavingStart();
    await TasksService.duplicateTask(id);
    setSavingFinish();
  };

  const path = getTaskDetailUri(task?.uid || id);
  const url = window.location.origin + path;

  const handleOnDelete = (item: any) => {
    handleVisibility(false);
    Modal.confirm({
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      title: `Permanently delete the ${unitName}?`,
      content: `This will permanently delete the ${unitName}. This item will no longer be accessible to you or anyone else. This action is irreversible.`,
      okText: "Delete",
      okButtonProps: okButtonProps,
      cancelText: "Cancel",
      cancelButtonProps: cancelButtonProps,
      async onOk() {
        await item.onClick();
      },
      zIndex: 1040,
    });
  };

  const handleOnDuplicate = () => {
    handleVisibility(false);
    setIsShowDuplicateModal(true);
  };

  const menuItems = [
    {
      title: "Open in new tab",
      icon: <OpenSvg size={12} />,
      key: "open-in-new-tab",
      hidden: props.hideOpenNewTab,
      onClick: () => {
        handleVisibility(false);
        window.open(url, "_blank");
      },
    },
    {
      title: "Open in viewer",
      icon: <ModelSvg />,
      key: "open-in-viewer",
      hidden: isModelView || !isModelsEnabled,
      onClick: () => {
        handleVisibility(false);
        const url = getTaskDetailModelUri(String(workspace?.uid), String(task.board.uid), (task?.uid || id));
        window.open(url, "_blank");
      },
    },
    {
      title: `Duplicate ${unitName}`,
      icon: <DuplicateSvg size={12} />,
      key: "duplicate",
      hidden: !allowedToCreate,
      async onClick() {
        handleVisibility(false);
        await handleTaskDuplicate().finally();
      },
      render: (item: any, index: number) => (
        <CardMenuItem key={index} onClick={handleOnDuplicate}>
          <MenuItemIcon text={item.title} icon={item.icon} />
        </CardMenuItem>
      ),
    },
    {
      title: `Copy ${unitName} link`,
      icon: <UrlSvg size={12} />,
      key: "copy-link",
      onClick: () => {
        handleVisibility(false);
        copyLink(path);
      },
    },
    {
      title: "Export data",
      icon: <ExportSvg />,
      key: "export-data",
      onClick: () => {
        handleVisibility(false);
        setIsShowExportModal(true);
      },
    },
    {
      title: "Import viewpoints",
      icon: <ImportSvg />,
      key: "import-viewpoints",
      hidden: !isModelView || !allowedToEdit || !isModelsEnabled,
      onClick: () => {
        handleVisibility(false);
        setIsShowImportModal(true);
      },
    },
    {
      title: "Delete",
      icon: <TrashSvg size={12} />,
      key: "delete",
      hidden: isSynced || !allowedToDelete,
      async onClick() {
        handleVisibility(false);
        await handleTaskDelete().finally();
      },
      render: (item: any, index: number) => (
        <CardMenuItem key={index} onClick={() => handleOnDelete(item)}>
          <MenuItemIcon text={item.title} icon={item.icon} />
        </CardMenuItem>
      ),
    },
  ];

  return (
    <>
      <CardMenuWrapper>
        {menuItems.map((item, index) => {
          if (item.hidden) {
            return null;
          }
          if (item.render) {
            return item.render(item, index);
          }
          return (
            <CardMenuItem key={index} onClick={item.onClick}>
              <MenuItemIcon text={item.title} icon={item.icon} />
            </CardMenuItem>
          );
        })}
      </CardMenuWrapper>
      {isShowExportModal &&
        <ExportTaskModal
          taskId={id}
          visible={isShowExportModal}
          onClose={() => setIsShowExportModal(false)}
        />
      }
      {isShowDuplicateModal &&
        <DuplicateTaskModal
          taskId={id}
          visible={isShowDuplicateModal}
          onClose={() => setIsShowDuplicateModal(false)}
        />
      }
      {(isShowImportModal && viewer) &&
        <ImportViewpointsModal
          taskId={id}
          viewer={viewer}
          method="task"
          visible={isShowImportModal}
          onClose={() => setIsShowImportModal(false)}
        />
      }
    </>
  );
};

export default TaskDrawerMenu;
