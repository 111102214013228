import moment from "moment";
import { DateFormats, TimeFormats } from "lib/theme/lib";
import { TaskPropertyDateTimeFormat, TaskPropertyTimeFormat } from "lib/types/kanbanTypes";

export const DateMasksMap: Record<TaskPropertyDateTimeFormat, string> = {
  [TaskPropertyDateTimeFormat.Europe]: DateFormats.Europe,
  [TaskPropertyDateTimeFormat.Iso]: DateFormats.ISO,
  [TaskPropertyDateTimeFormat.Usa]: DateFormats.US,
  [TaskPropertyDateTimeFormat.Friendly]: DateFormats.Friendly,
  [TaskPropertyDateTimeFormat.Default]: DateFormats.Friendly,
};

export const TimeMasksMap: Record<TaskPropertyTimeFormat, string> = {
  [TaskPropertyTimeFormat.Europe]: TimeFormats.Europe,
  [TaskPropertyTimeFormat.Usa]: TimeFormats.US,
  [TaskPropertyTimeFormat.None]: ""
};

export function formatDateTimeString(value: moment.MomentInput, dateFormat?: TaskPropertyDateTimeFormat, timeFormat?: TaskPropertyTimeFormat) {
  if (!value) {
    return "";
  }

  if (!dateFormat && !timeFormat) {
    return moment(value).format(DateFormats.HumanFull);
  }

  const dateMask = (dateFormat) ? DateMasksMap[dateFormat] : DateFormats.Friendly;
  const timeMask = (timeFormat) ? TimeMasksMap[timeFormat] : TimeFormats.US;

  const mask = dateMask + (timeMask ? " " + timeMask : "");

  const dateTimeString = moment(value).format(mask).trim();
  return truncateTimeSuffix(dateTimeString);
}

export function compareMomentDates(a?: moment.Moment | null, b?: moment.Moment | null) {
  return (a || moment())?.isSame(b);
}

export function truncateTimeSuffix(timeString: string) {
  return timeString.replace(/(00:00|12:00am)$/, "");
}
