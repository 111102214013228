import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const UrlSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
        <path d="M9.89924 90.1009C-3.29975 76.9023 -3.29975 55.18 9.89924 41.981L15.2455 36.6346C18.1783 33.7019 23.0051 33.7019 25.9382 36.6346C28.871 39.5675 28.871 44.3945 25.9382 47.3273L20.5918 52.6736C13.2585 60.007 13.2585 72.0756 20.5918 79.409C27.9252 86.7425 39.9938 86.7425 47.3272 79.409L52.6735 74.0626C55.6273 71.1088 60.4123 71.1088 63.3662 74.0626C66.32 77.0145 66.32 81.8035 63.3662 84.755L58.0198 90.1017C44.8205 103.3 23.0982 103.3 9.89924 90.1009Z"/>
        <path d="M74.0621 63.3662C71.1292 60.4333 71.1292 55.6063 74.0621 52.6735L79.4084 47.3272C86.7417 39.9938 86.7417 27.9252 79.4084 20.5918C72.0751 13.2585 60.0064 13.2585 52.6731 20.5918L47.3267 25.9382C44.3729 28.892 39.5879 28.892 36.6341 25.9382C33.6802 22.9863 33.6802 18.1973 36.6341 15.2455L41.9804 9.89924C55.1794 -3.29975 76.9017 -3.29975 90.1008 9.89924C103.3 23.0982 103.3 44.8205 90.1008 58.0195L84.7542 63.3658C81.8219 66.2989 76.9952 66.2989 74.0621 63.3662Z"/>
        <path d="M33.9612 66.0434C31.0273 63.1106 31.027 58.2826 33.9612 55.3491L55.3464 33.9601C58.2793 31.0267 63.106 31.0263 66.0391 33.9581C68.9729 36.8909 68.9732 41.719 66.0391 44.6524L44.6538 66.0414C41.721 68.9749 36.8942 68.9756 33.9612 66.0434Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default UrlSvg;
