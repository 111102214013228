import { FC, useState } from "react";
import { Dropdown } from "antd";
import DropdownSvg from "components/icons/resizable/dropdown";
import { MoreButton, MoreButtonContainer } from "components/blocks/ViewpointLikeCard/styled";
import AnnotationCardMenuItems from "components/blocks/AnnotationCard/AnnotationCardMenu/AnnotationCardMenuItems";
import { AnnotationInterface } from "lib/types/fileContainer";
import { AnnotationContextMenuItems } from "./AnnotationCardMenuItems/lib";

interface AnnotationCardMenuProps {
  annotation: AnnotationInterface | null;
  menuItems: Array<AnnotationContextMenuItems>;
}

const AnnotationCardMenu: FC<AnnotationCardMenuProps> = (props) => {
  const { annotation, menuItems } = props;
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleVisibility = () => setIsDropdownVisible(!isDropdownVisible);

  const handleOnClick: React.MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();
    toggleVisibility();
  };

  if (!annotation) {
    return (null);
  }

  return (
    <Dropdown
      overlay={<AnnotationCardMenuItems
        annotation={annotation}
        toggleVisibility={toggleVisibility}
        menuItems={menuItems}
      />}
      trigger={["click", "contextMenu"]}
      onOpenChange={toggleVisibility}
      open={isDropdownVisible}
    >
      <MoreButtonContainer>
        <MoreButton
          onClick={handleOnClick}
          active={isDropdownVisible}
        >
          <DropdownSvg size={11} />
        </MoreButton>
      </MoreButtonContainer>
    </Dropdown>
  );
};

export default AnnotationCardMenu;
