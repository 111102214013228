import styled from "@emotion/styled/macro";

export const DottedPlaceholderWrapper = styled.div`
  opacity: 0;
  transition: opacity 200ms ease;
  &:hover {
    opacity: 1;
  }
`;

export const DottedCircle = styled.div`
  margin: 2px;
  
  width: 28px;
  height: 28px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  border: dashed 1px #999;
  border-radius: 50%;
  opacity: 0.5;
  transition: opacity 200ms ease, background-color 200ms ease;
  cursor: pointer;

  &:hover {
    opacity: 1;
    background-color: #f6f6f6;
  }
`;

export const Image = styled.img`
  margin: 0 5px;
`;
