import loadable, { LoadableComponent } from "@loadable/component";
import FormPublic from "pages/FormPublic";
import ProcoreIntegration from "pages/ProcoreIntegration";
import { Redirect, RedirectProps, Route, RouteProps, Switch } from "react-router-dom";
import ProtectedRoute from "./components/blocks/ProtectedRoute/ProtectedRoute";
import AppLayout from "./layout";
import ChangePassword from "./pages/Auth/AuthPages/ChangePassword";
import ForgotPassword from "./pages/Auth/AuthPages/ForgotPassword";
import Onboarding from "./pages/Auth/AuthPages/Onboarding";
import SignIn from "./pages/Auth/AuthPages/SignIn";
import SignUp from "./pages/Auth/AuthPages/SignUp";
import AuthRedirect from "./pages/Auth/AuthRedirect";
import BoardPage from "./pages/Board/BoardPage";

interface RoutesItem {
  path: string;
  component: any;
  ignoreSplit?: boolean;
  LoadableComponent?: LoadableComponent<unknown>;
  props?: RouteProps;
}

const protectedRoutes: RoutesItem[] = [
  {
    path: "/home",
    component: () => import("pages/Home"),
    props: {
      exact: true,
    },
  },
  {
    path: "/work",
    component: () => import("pages/MyTasks"),
  },
  {
    path: "/reporting/:projectId?/:boardId?",
    component: () => import("pages/Reporting"),
  },
  {
    path: "/settings",
    component: () => import("pages/Settings"),
    props: {
      exact: true,
    },
  },
  {
    path: "/admin/:uid?",
    component: () => import("pages/AdminConsole"),
  },
  {
    path: "/project/:id/r",
    component: () => import("pages/Project/ProjectTasks"),
  },
  {
    path: "/project/:id/f",
    component: () => import("pages/Project/ProjectFiles"),
    props: {
      exact: true,
    }
  },
  {
    path: "/project/:id/settings",
    component: () => import("pages/Project/ProjectSettings"),
    props: {
      exact: true,
    },
  },
  {
    path: "/project/:id/docs/:uid",
    component: () => import("pages/Doc"),
    props: {
      exact: true,
    }
  },
  {
    path: "/project/:id/f/:uid",
    component: () => import("pages/FileContainer"),
    props: {
      exact: true,
    }
  },
  {
    path: "/project/:id/plans",
    component: () => import("pages/Pricing"),
    props: {
      exact: true,
    },
  },
  {
    path: "/project/:id",
    component: () => import("pages/Project/ProjectOverview"),
  },
  {
    path: "/viewer/:id/",
    component: () => import("pages/Viewer")
  },
  {
    path: "/board/:id/:view",
    component: BoardPage,
    ignoreSplit: true,
    props: {},
  },
  {
    path: "/r/:id",
    component: () => import("pages/Task/TaskPage"),
    props: {
      exact: true,
    }
  },
  {
    path: "/f/:uid",
    component: () => import("pages/FileContainer"),
  },
  {
    path: "/extensions/markup-transfer",
    component: () => import("pages/Extensions/MarkupTransfer"),
    props: {
      exact: true,
    }
  },
];

const redirects: Array<RedirectProps> = [
  {
    from: "/project/:id",
    to: "/project/:id",
  },
  {
    from: "/board/:id",
    to: "/board/:id/list"
  },
  {
    from: "/tasks",
    to: "/work"
  },
  {
    from: "/task/:id",
    to: "/r/:id"
  },
  {
    to: "/home",
  },
];

const splitProtectedRoutes = protectedRoutes.map((item) => {
  if (item.ignoreSplit) {
    return item;
  }

  item.LoadableComponent = loadable(item.component);

  return item;
});

const publicRouter: RoutesItem[] = [
  {
    path: "/auth",
    component: SignUp,
    props: {
      exact: true,
    },
  },
  {
    path: "/auth/login",
    component: SignIn,
    props: {
      exact: true,
    },
  },
  {
    path: "/auth/forgot-password",
    component: ForgotPassword,
    props: {
      exact: true,
    },
  },
  {
    path: "/auth/reset-password",
    component: ChangePassword,
    props: {
      exact: true,
    },
  },
  {
    path: "/auth/onboarding",
    component: Onboarding,
    props: {
      exact: true,
    },
  },
  {
    path: "/auth/connect/:providerName/redirect",
    component: AuthRedirect,
    props: {
      exact: true,
    },
  },
  {
    path: "/integrations/procore",
    component: ProcoreIntegration,
    props: {
      exact: true,
    },
  },
  {
    path: "/form/:id",
    component: FormPublic,
    props: {
      exact: true,
    }
  }
];

const publicRedirects = [
  {
    to: "/auth",
  },
];

const Routes = () => (
  <Switch>
    {publicRouter.map(publicRoute => {
      const { path, props, component: Component } = publicRoute;

      return (
        <Route
          {...props}
          key={path}
          path={path}
          render={(routeProps) => {
            return <Component {...routeProps} />;
          }}
        />
      );
    })}

    <ProtectedRoute>
      <AppLayout>
        <Switch>
          {splitProtectedRoutes.map((route) => {
            const { path, props, component, LoadableComponent } = route;
            const Component = route.ignoreSplit ? component : LoadableComponent;

            return (
              <Route
                {...props}
                key={path}
                path={path}
                render={(routeProps) => {
                  return <Component {...routeProps} />;
                }}
              />
            );
          })}
          {redirects.map((props, i) => <Redirect key={i} {...props} />)}
        </Switch>
      </AppLayout>
    </ProtectedRoute>

    {publicRedirects.map((props, i) => <Redirect key={i} {...props} />)}
  </Switch>
);

export default Routes;
