import { useAppSelector } from "app/store";
import { TaskCreateFlow, TaskCreateFlowTab } from "components/blocks/TaskModals/TaskModalCreateAnnotation/lib";
import UserService from "services/UserService";

const useUserPreferences = () => {
  const user = useAppSelector(state => state.currentUserReducer.user);

  const initialFlow: TaskCreateFlow = user?.preferences?.fileContainer?.createFlow?.flow || "create";
  const initialTab: TaskCreateFlowTab = user?.preferences?.fileContainer?.createFlow?.tab || "from-scratch";

  const savePreferences = async (preferences: Record<string, any>) => {
    return await UserService.updatePreferences(preferences);
  };

  return {
    savePreferences,
    createFlow: {
      flow: initialFlow,
      tab: initialTab,
    }
  };
};

export default useUserPreferences;
