import { FC, memo, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { sortBy } from "lodash";
import { useAppSelector } from "app/store";
import AddWorkspaceModal from "components/blocks/AddWorkspaceModal";
import ButtonIcon from "components/ui/ButtonIcon";
import SkeletonAside from "components/ui/Skeletons/Aside";
import { MemberPermissionProvider } from "lib/contexts/MemberPermissionContext";
import { ProjectProvider } from "lib/contexts/ProjectContext";
import useFetchCurrentUser from "lib/customHooks/useFetchCurrentUser";
import useGetOrganizations from "lib/customHooks/useGetOrganizations";
import useGetWorkspaces from "lib/customHooks/useGetWorkspaces";
import useWSCreateWorkspace from "lib/customHooks/websocketWorkspace/useWSCreateWorkspace";
import useWSDeleteWorkspace from "lib/customHooks/websocketWorkspace/useWSDeleteWorkspace";
import useWSUpdateWorkspace from "lib/customHooks/websocketWorkspace/useWSUpdateWorkspace";
import SidebarProject from "layout/SidebarCustom/SidebarMenus/SidebarProject";
import AsideMenuItems from "./SidebarMenus/SidebarTopMenu";
import { SidebarAddProjectSection, SidebarDivider, SidebarTopMenuSection, SidebarProjectsSection } from "./styled";
import useWSUpdateSelf from "lib/customHooks/websocketSelf/useWSUpdateSelf";

const SidebarCustom: FC = () => {
  useGetWorkspaces();
  useFetchCurrentUser();
  useWSUpdateSelf();
  useGetOrganizations();
  useWSUpdateWorkspace();
  useWSCreateWorkspace();
  useWSDeleteWorkspace();

  const [isShowModal, setIsShowModal] = useState(false);
  const workspaces = useAppSelector(state => state.workspaces.workspaces);
  const isLoading = useAppSelector(state => state.workspaces.loading);

  const workspaceList = sortBy(workspaces, workspace => workspace.id);

  const menuItems = [
    { name: "Home", path: "/home", icon: "home.svg" },
    { name: "My Work", path: "/work", icon: "my-tasks.svg", hidden: !workspaces.length },
    { name: "Reporting", path: "/reporting", icon: "reporting.svg", hidden: !workspaces.length },
    { name: "Settings", path: "/settings", icon: "settings.svg" }
  ];

  return (
    <>
      <SidebarTopMenuSection>
        {menuItems.map((item) => {
          if (item.hidden) {
            return (null);
          }
          return (
            <AsideMenuItems key={item.name} name={item.name} path={item.path} icon={item.icon} />
          );
        })}
      </SidebarTopMenuSection>
      <SidebarDivider />
      <Scrollbars autoHide>
        <SidebarProjectsSection>
          {isLoading && <SkeletonAside />}
          {!isLoading && workspaceList?.map((workspace) => (
            <ProjectProvider workspace={workspace} key={workspace.id}>
              <MemberPermissionProvider workspaceId={String(workspace.id)}>
                <SidebarProject />
              </MemberPermissionProvider>
            </ProjectProvider>
          ))}
        </SidebarProjectsSection>
      </Scrollbars>
      <SidebarDivider />
      <SidebarAddProjectSection>
        <ButtonIcon
          onClick={() => setIsShowModal(true)}
          text="Add a project"
        />
      </SidebarAddProjectSection>
      {isShowModal &&
        <AddWorkspaceModal
          visible={isShowModal}
          onClose={() => setIsShowModal(false)}
        />
      }
    </>
  );
};

export default memo(SidebarCustom);
