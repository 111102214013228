import { FC, Fragment, useEffect, useState } from "react";
import { Dropdown, Menu, Tooltip } from "antd";
import {
  createNewFilter,
  selectFilterTypes,
  setFilterFunction,
  setFilterTitle,
  setFilterValue
} from "app/slices/tasksFilterAndSortSlice";
import { useAppDispatch, useAppSelector } from "app/store";
import ButtonControls from "components/ui/ButtonControls";
import AddSvg from "components/ui/ButtonControls/icons/add";
import FilterSvg from "components/ui/ButtonControls/icons/filter";
import { HeaderAvatarUser, HeaderAvatarUserEmail } from "layout/HeaderCustom/HeaderAvatar/styled";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import useBoardViewSave from "lib/customHooks/views/useBoardViewSave";
import { FilterFunction, getMappedFilterFunction } from "lib/filters/filterFunctions";
import { FilterValueNone, FilterValueType, FilterVersion, noneFilterValue } from "lib/filters/filters";
import { FilterType, TFilterFn } from "lib/filters/filterTypes";
import { getUserFullName } from "lib/helpers/userUtils";
import { MemberRoles } from "lib/types/types";
import usePrevious from "../../../lib/customHooks/usePrevious";
import MultipleFilterComponent from "./MultipleFilter";
import SingleFilter from "./SingleFilter";
import { activeButtonClassFilter, FilterTooltip, FilterWrapper } from "./styled";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import { useBoardContext } from "lib/contexts/BoardContext";

interface FilterProps {
  filters: TFilterFn;
  onChangeTitle?: (title: string) => void;
  onChangeValue?: (value: FilterValueType) => void;
  hideJustMyTasks?: boolean;
  isCustomView?: boolean;
  text?: string;
  tooltipText?: string;
}

const Filter: FC<FilterProps> = (props) => {
  const { isCustomView } = props;

  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [filterTitles, setFilterTitles] = useState<string[]>([]);
  const [isNoneFilter, setIsNoneFilter] = useState(true);
  const [displayGeneralMenu, setDisplayGeneralMenu] = useState(isNoneFilter);

  const previousVisible = usePrevious(visible);
  const currentFilter = useAppSelector(state => state.boardFilterAndSort.filter);
  const user = useAppSelector(state => state.currentUserReducer.user);
  const currentBoard = useAppSelector(state => state.currentBoardReducer.board);
  const types = useAppSelector(selectFilterTypes);
  const filtersVersion = useAppSelector(state => state.boardFilterAndSort.filter.version);

  const { board: boardCtx } = useBoardContext();
  const { saveViewFilters } = useBoardViewSave();
  const { isAssigneesEnabled, isDueDatesEnabled } = useBoardFeatures(currentBoard || boardCtx);

  const allowedToEditCustomViews = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const isSortCustomViewsDisabled = isCustomView && !allowedToEditCustomViews;

  useEffect(() => {
    setIsNoneFilter(
      currentFilter.titleKey === FilterValueNone &&
      currentFilter.value.text === FilterValueNone &&
      currentFilter.multiple?.length === 0
    );
  }, [currentFilter.titleKey, currentFilter.value.text, currentFilter.multiple?.length]);

  useEffect(() => {
    setDisplayGeneralMenu(isNoneFilter);
  }, [isNoneFilter]);

  useEffect(() => {
    return () => {
      dispatch(setFilterTitle(FilterValueNone));
      dispatch(setFilterValue(noneFilterValue));
    };
  }, [dispatch]);

  useEffect(() => {
    const filters = props.filters;

    setFilterTitles(
      Object
        .keys(filters)
        .sort((prev, next) => {
          return ((filters[prev]?.sort || 0) - (filters[next]?.sort || 0));
        })
    );
  }, [currentBoard, currentFilter.titleKey, dispatch, props.filters]);

  useEffect(() => {
    if (!visible && previousVisible) {
      // save exactly when user closes modal true -> false state
      // if we try to saveViewFilters without this check sometimes it leads to loop
      // also it is hard to call saveViewFilters right way in handleVisibilityChange because of redux queue
      saveViewFilters();
    }
  }, [visible, previousVisible, saveViewFilters]);

  const handleVisibilityChange = (visible: boolean, generalMenuVisible?: boolean) => {
    setVisible(visible);
    if (generalMenuVisible !== undefined) {
      setDisplayGeneralMenu(generalMenuVisible);
    }
    if (!visible && isNoneFilter) {
      setDisplayGeneralMenu(true);
    }
  };

  const getFilterFunction = (title: string) => {
    const filterKeys = getMappedFilterFunction({ ...currentFilter, titleKey: title }, types);
    return filterKeys?.[0] || "";
  };

  const onSelectTitle = (title: string) => {
    const filterFunction = getFilterFunction(title);

    dispatch(setFilterTitle(title));
    dispatch(setFilterFunction(filterFunction));

    if (currentFilter?.types[title] === FilterType.Boolean) {
      const booleanDefaultValue = { text: "False", valueKey: false };
      dispatch(setFilterValue(booleanDefaultValue));
    } else {
      dispatch(setFilterValue(noneFilterValue));
    }
  };

  const onChangeValue = (value: FilterValueType) => {
    dispatch(setFilterValue(value));
  };

  const handleCustomFilterOnClick = () => {
    setDisplayGeneralMenu(false);
  };

  const handleJustMyTasksOnClick = () => {
    const titleKey = "Assignees";

    onSelectTitle(titleKey);
    handleVisibilityChange(false, false);

    if (user) {
      const value = {
        text: getUserFullName(user) || "",
        valueKey: String(user.id)
      };

      onChangeValue(value);
    }
  };

  const handleDueDatePresetOnClick = () => {
    handleVisibilityChange(false, false);
    dispatch(setFilterFunction(FilterFunction["This week"]));
    dispatch(setFilterTitle("Due date"));
  };

  const addNewFilter = () => {
    dispatch(createNewFilter());
  };

  const GeneralMenuItems = (
    <Menu>
      {(isAssigneesEnabled || isDueDatesEnabled) &&
        <>
          <HeaderAvatarUser key="gm-quick-filter">
            <HeaderAvatarUserEmail>Shortcuts</HeaderAvatarUserEmail>
          </HeaderAvatarUser>
          {(!props.hideJustMyTasks && isAssigneesEnabled) &&
            <Menu.Item
              key="gm-just-my-tasks"
              disabled={isSortCustomViewsDisabled}
              onClick={handleJustMyTasksOnClick}
              icon={<img src={process.env.PUBLIC_URL + "/icons/assignees.svg"} alt="menu" />}
            >
              Assigned to me
            </Menu.Item>
          }
          {isDueDatesEnabled &&
            <Menu.Item
              key="gm-due-date-this-week"
              disabled={isSortCustomViewsDisabled}
              icon={<img src={process.env.PUBLIC_URL + "/icons/calendar.svg"} alt="menu" />}
              onClick={handleDueDatePresetOnClick}
            >
              Due this week
            </Menu.Item>
          }
          <Menu.Divider />
        </>
      }
      <Menu.Item
        key="gm-custom-filter"
        disabled={isSortCustomViewsDisabled}
        icon={<img src={process.env.PUBLIC_URL + "/icons/filter.svg"} alt="menu" />}
        onClick={handleCustomFilterOnClick}
      >
        All filters
      </Menu.Item>
    </Menu>
  );

  const Filters = (
    <FilterWrapper>
      <FilterTooltip>
        Only show records...
      </FilterTooltip>
      {currentFilter.multiple && currentFilter.multiple.length
        ? currentFilter.multiple.map((filter, index) => (
          <MultipleFilterComponent
            isSortCustomViewsDisabled={isSortCustomViewsDisabled}
            key={filter.id}
            currentFilter={filter}
            filterTitles={filterTitles}
            index={index}
            {...props} />
        ))
        : <SingleFilter
          isSortCustomViewsDisabled={isSortCustomViewsDisabled}
          setIsNoneFilter={setIsNoneFilter}
          filterTitles={filterTitles}
          {...props}
        />
      }

      {filtersVersion && (filtersVersion !== FilterVersion.v1) &&
        <div style={{ marginBlock: 3 }}>
          <ButtonControls onClick={addNewFilter} icon={<AddSvg />} text="Add condition" />
        </div>
      }
    </FilterWrapper>
  );

  const overlay: React.ReactElement = (visible)
    ? (displayGeneralMenu ? GeneralMenuItems : Filters)
    : <Fragment />;

  return (
    <Dropdown
      open={visible}
      overlay={overlay}
      placement="bottomRight"
      onOpenChange={handleVisibilityChange}
      destroyPopupOnHide
      trigger={["click"]}
      overlayStyle={{ animationDuration: "0s" }}
    >
      <Tooltip title={props.tooltipText ?? ""}>
        <>
          <ButtonControls
            icon={<FilterSvg />}
            text={props.text ?? "Filter"}
            className={isNoneFilter ? "" : activeButtonClassFilter}
          />
        </>
      </Tooltip>
    </Dropdown>
  );
};

export default Filter;
