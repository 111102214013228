import { MemberPermissionProvider } from "lib/contexts/MemberPermissionContext";
import { TaskProvider } from "lib/contexts/TaskContext";
import { DisplayView } from "lib/types/applicationTypes";
import { TableLiteCellProps } from "lib/types/table";

export const wrapTaskProviderFn = (children: JSX.Element, cell: TableLiteCellProps) => {
  return (
    <TaskProvider
      key={cell.row.original.id}
      task={cell.row.original}
      displayView={DisplayView.List}
    >
      {children}
    </TaskProvider>
  );
};

export const wrapMemberProviderFn = (children: JSX.Element, cell: TableLiteCellProps) => (
  <MemberPermissionProvider
    workspaceId={cell.row.original.board.workspace}
  >
    {children}
  </MemberPermissionProvider>
);
