import { ForgeTokenType } from "lib/types/forgeTokenType";
import { JSendResponse } from "lib/types/jsend";
import HttpService from "./HttpService";

class ForgeService extends HttpService {
  constructor() {
    super("forge");
  }

  async getToken(): Promise<ForgeTokenType | null> {
    const data: JSendResponse<ForgeTokenType> = await this._get({
      additionalUrl: "/token"
    });

    return data.data || null;
  }
}

export default new ForgeService();
