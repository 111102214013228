import styled from "@emotion/styled/macro";

export const ProjectSettingsSwitch = styled.div`
  display: flex;
  align-items: center;
  margin: 6px 0;
`;

export const ProjectSettingsIntegration = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProjectSettingsIntegrationHeading = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const ProjectSettingsIntegrationDescription = styled.div`
  color: #999;
  font-size: 12px;
`;
