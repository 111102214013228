import { FC, ReactNode, useState } from "react";
import { Dropdown } from "antd";
import { sortBy } from "lodash";

import TaskDetailedTitle from "components/blocks/TaskDetailed/TaskDetailedTitle";
import TaskDetailedDescriptionRTE from "components/blocks/TaskDetailed/TaskDetailedDescriptionRTE";
import TaskSubtasks from "components/blocks/TaskDetailed/TaskSubtasks";
import ButtonIcon from "components/ui/ButtonIcon";
import TaskDrawerMenu from "components/blocks/TaskDrawer/TaskDrawerMenu";
import { useDocumentTitle } from "lib/customHooks/useDocumentTitle";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import { TaskPropertyValue } from "lib/types/kanbanTypes";
import { useBoardViewContext } from "lib/contexts/BoardViewContext";
import { DisplayView } from "lib/types/applicationTypes";
import { DefaultColumnCodes } from "lib/types/boardTypes";
import { PropertyValueType, SystemPropertyCode } from "lib/types/dataTypes";
import { CommentInterface, MemberRoles } from "lib/types/types";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import { ContentWrapper, TaskSection } from "pages/Board/BoardKanban/Card/CardDetails/styled";

import { TaskCommentBoxProps } from "../TaskCommentBox";
import { TaskDetailedBarWrapper } from "./styled";
import TaskCheckmark from "../TaskCheckmark";
import TaskDetailedProperties from "./TaskDetailedProperties";
import TaskDetailedChangelog from "./TaskDetailedChangelog";

interface TaskDetailedProps {
  hideHeader?: boolean;
  hideCommentBox?: boolean;
  displayView: DisplayView;
  onEditComment?: (comment: CommentInterface) => void;
  customization?: Partial<Record<PropertyValueType, ReactNode>>
}

const TaskDetailed: FC<TaskDetailedProps> = (props) => {
  const { hideHeader, hideCommentBox } = props;
  const { task, setValue } = useTaskContext() as TaskContextInterface;
  const { viewData } = useBoardViewContext();
  const { title, completed, propertyValues } = task;
  const { isCompletionEnabled } = useBoardFeatures(task.board);

  useDocumentTitle(title);
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);

  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const handleOnVisibleChange = (visible: boolean) => setIsMenuVisible(visible);

  // Comment section and editor
  const [commentBoxOptions, setCommentBoxOptions] = useState<Omit<TaskCommentBoxProps, "taskId" | "onDiscard" | "workspaceId">>({ isEditor: false });
  const onEditComment = (comment: CommentInterface) => {
    setCommentBoxOptions({
      isEditor: true,
      commentId: comment.id,
      initialText: comment.text,
      initialFileAttachments: comment.attachments,
    });
  };

  const onDiscardEditComment = () => {
    if (commentBoxOptions.isEditor) {
      setCommentBoxOptions({
        isEditor: false,
        initialText: "",
        initialFileAttachments: [],
      });
    }
  };

  // Task property values
  let taskPropertyValues = propertyValues.filter(property => {
    const excludedColumns: Array<string> = [
      DefaultColumnCodes.Completion,
      DefaultColumnCodes.Description,
      DefaultColumnCodes.DescriptionRTE,
      DefaultColumnCodes.Title,
      SystemPropertyCode.Progress,
    ];
    return (property.active && !excludedColumns.includes(property.code));
  });

  // Apply fields sort & visibility (from Board data) 
  taskPropertyValues = sortBy(taskPropertyValues, property => property.sort);

  let visibleTaskProperties = taskPropertyValues.filter(property => !property.hidden);
  let hiddenTaskProperties = taskPropertyValues.filter(property => property.hidden);

  // Apply fields sort & visibility (from Board view data, if available) 
  if (viewData?.appliedColumns) {
    const getCurrentColumn = (property: TaskPropertyValue) => {
      return viewData.appliedColumns.find(column => String(column.id) === String(property.columnId));
    };

    taskPropertyValues = sortBy(taskPropertyValues, property => {
      const appliedColumn = getCurrentColumn(property);
      return appliedColumn?.sort || property.sort;
    });

    visibleTaskProperties = taskPropertyValues.filter(property => {
      const appliedColumn = getCurrentColumn(property);
      return !appliedColumn?.hidden;
    });

    hiddenTaskProperties = taskPropertyValues.filter(property => {
      const appliedColumn = getCurrentColumn(property);
      return appliedColumn?.hidden;
    });
  }

  return (
    <ContentWrapper>
      {/* Task Info & Properties */}
      <TaskSection>
        {/* Task Title & Checkmark: ONLY FOR DETAILED PAGE */}
        {!hideHeader &&
          <TaskDetailedBarWrapper>
            <div style={{ marginLeft: "-10px" }}>
              {isCompletionEnabled &&
                <TaskCheckmark
                  id={task.id}
                  isCompleted={completed}
                  setValue={setValue}
                  clickable={allowedToEdit}
                  button
                />
              }
            </div>
            <Dropdown
              overlay={<TaskDrawerMenu handleVisibility={handleOnVisibleChange} hideOpenNewTab />}
              trigger={["click"]}
              placement="bottomRight"
              open={isMenuVisible}
              onOpenChange={handleOnVisibleChange}
            >
              <ButtonIcon iconUrl="/icons/more.svg" />
            </Dropdown>
          </TaskDetailedBarWrapper>
        }

        {/* New title */}
        <TaskDetailedTitle />

        {/* Description */}
        <TaskDetailedDescriptionRTE id={task.id} value={task.descriptionRich} />

        {/* Subtasks */}
        <TaskSubtasks />

        {/* Task Properties */}
        <TaskDetailedProperties
          visibleTaskProperties={visibleTaskProperties}
          hiddenTaskProperties={hiddenTaskProperties}
          displayView={props.displayView}
          customization={props.customization}
        />
      </TaskSection>

      {/* Task Comment Section */}
      <TaskDetailedChangelog
        onEditComment={props.onEditComment || onEditComment}
        onDiscardEditComment={onDiscardEditComment}
        hideCommentBox={hideCommentBox}
        commentBoxOptions={commentBoxOptions}
      />

    </ContentWrapper>
  );
};

export default TaskDetailed;
