import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { captureException } from "lib/utils/sentry";
import UserService from "services/UserService";

const useGetUserEmailByTokenCode = (code: string): string => {
  const [email, setEmail] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (!code) {
      return;
    }

    const getData = async () => {
      try {
        const data = await UserService.getUserByTokenCode(code);
        // error if data is empty {}
        if (data && Object.keys(data).length !== 0) {
          setEmail(data.email);
        } else {
          history.push("/auth/login");
        }
      } catch (error) {
        captureException(error);
        history.push("/auth/login");
      }
    };

    getData();
  }, [code]);

  return email;
};

export default useGetUserEmailByTokenCode;
