import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { KanbanCardSettings } from "pages/Board/BoardKanban/Card/styled";

export const AutomationsMoreWrapper = styled(KanbanCardSettings)`
  position: absolute;
  top: auto;
  bottom: auto;
  margin: 0 8px 0 0;
`;

const AutomationsCssCard = css`
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 16px;
  border: solid 1px #e4e4e4;
  border-radius: 6px;
  transition: border-color 200ms ease;
  background-color: white;
  user-select: none;
  &:hover {
    border: solid 1px #cdcdcd;
    ${AutomationsMoreWrapper} {
      opacity: 100%;
    }
  }
`;

export const AutomationsItemCard = styled.div`
  ${AutomationsCssCard};
  padding: 8px 8px 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  transition: all 200ms ease;
`;

export const AutomationsItemContent = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 20px)
`;

export const AutomationsItemInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AutomationsItemTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AutomationsItemDate = styled.div`
  font-size: 12px;
  color: #999;
`;

export default AutomationsCssCard;
