import { FC } from "react";
import IconSvgWrapper from "components/icons/IconSvgWrapper";
import { IconProps } from "components/icons/lib";

const AutomationsSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M10.9654 4.8176C10.8903 4.62618 10.7056 4.50024 10.5 4.5002H7.12338L7.98811 0.608669C8.04812 0.33912 7.87829 0.0719651 7.60874 0.0119588C7.43482 -0.0267536 7.25345 0.0299868 7.13265 0.16093L1.13276 6.66082C0.945335 6.86361 0.957833 7.17993 1.16063 7.36735C1.25307 7.45279 1.37435 7.50021 1.5002 7.50012H4.30656L3.02583 11.3418C2.93848 11.6038 3.08005 11.8869 3.34202 11.9743C3.51546 12.0321 3.70659 11.9907 3.84049 11.8662L10.8404 5.36633C10.991 5.22663 11.0406 5.00881 10.9654 4.8176V4.8176Z" />
    </IconSvgWrapper>
  );
};

export default AutomationsSvg;
