import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "antd";
import { AuthLink, AuthReactLink } from "pages/Auth/styled";

import AuthContainer from "pages/Auth/index";
import AuthEmail from "pages/Auth/AuthComponents/Email";
import AuthSignInButton from "pages/Auth/AuthComponents/SignInButton";
import AuthFirstLastName from "pages/Auth/AuthComponents/FirstLastName";
import AuthResetPassword from "pages/Auth/AuthComponents/ResetPassword";
import { setToken } from "app/slices/authSlice";
import UserService from "services/UserService";
import useGetQueryValue from "lib/customHooks/routeHooks/useGetQueryValue";
import WebSocketService from "lib/API/socket";
import useAsyncData from "lib/customHooks/useAsyncData";
import { UserInterface } from "lib/types/types";
import useGetUserEmailByTokenCode from "lib/customHooks/useGetUserEmailByTokenCode";
import useAuthorizedRedirect from "lib/customHooks/routeHooks/useAuthorizedRedirect";
import { useAppDispatch } from "app/store";
import configuration from "lib/configs/configuration";

const Onboarding: FC = () => {
  useAuthorizedRedirect();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const code = useGetQueryValue("code");
  if (code === null) {
    history.push("/auth");
  }
  const onboardingUserEmail = useGetUserEmailByTokenCode(code || "");

  useEffect(() => {
    form.setFieldsValue({ email: email });
  }, [email, form]);

  useEffect(() => {
    setEmail(onboardingUserEmail);
  }, [onboardingUserEmail]);

  type valueChanged = {
    firstName?: string
    lastName?: string
    email?: string
    password?: string
  };

  const onValuesChange = (valueChanged: valueChanged) => {
    if (valueChanged.firstName) {
      setFirstName(valueChanged.firstName);
    } else if (valueChanged.lastName) {
      setLastName(valueChanged.lastName);
    } else if (valueChanged.password) {
      setPassword(valueChanged.password);
    }
  };

  const fetchCallback = useCallback(() => {
    if (code) {
      return UserService.onboarding(code, firstName, lastName, password);
    } else {
      return null;
    }
  }, [code, firstName, lastName, password]);

  const { loadData } = useAsyncData<UserInterface & { jwt: string } | null>({ fetchFn: fetchCallback });

  const onFinish = async () => {
    const data = await loadData();
    if (data && data.jwt) {
      await dispatch(setToken(data.jwt));
      WebSocketService.connect();
    }
  };

  return (
    <Form
      form={form}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      style={{ width: "100%" }}
      requiredMark={false}
      layout="vertical"
    >
      <AuthContainer
        header="Create an account"
        emailComponent={<AuthEmail value={email} disabled />}
        firstLastNameComponent={<AuthFirstLastName autoFocus />}
        resetPasswordComponent={<AuthResetPassword text="Create a password" />}
        termsOfPolicy={
          <>
            By signing up, I agree to the {configuration.app.title} <AuthLink href="https://airtasks.com/legal/privacy-policy" target="_blank">Privacy Policy</AuthLink> and <AuthLink href="https://airtasks.com/legal/terms-of-service" target="_blank">Terms of Service</AuthLink>.
          </>
        }
        haveAccountText="Already have an account?"
        linkComponent={<AuthReactLink to="/auth/login">Sign In</AuthReactLink>}
        signInButton={<AuthSignInButton text="Sign up" />}
      />
    </Form>
  );
};

export default Onboarding;
