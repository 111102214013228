import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const GifSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.3113 20.4315C64.0428 20.4293 61.8677 19.5272 60.2636 17.9231C58.6594 16.319 57.7573 14.144 57.7553 11.8755V7.71041e-07H19.5271C18.0604 -0.000544262 16.6079 0.287871 15.2527 0.848768C13.8976 1.40967 12.6661 2.23207 11.6288 3.26898C10.5915 4.30589 9.76867 5.53701 9.20727 6.89199C8.64587 8.24697 8.35693 9.6993 8.35693 11.166V78.4772C8.36133 81.4365 9.54025 84.273 11.6347 86.3635C13.7291 88.454 16.5678 89.6278 19.5271 89.6266H65.4192C68.3806 89.6266 71.2208 88.4502 73.3146 86.356C75.4088 84.2622 76.5852 81.422 76.5852 78.4606V20.4315H66.3113Z" fill="#FF9908"/>
        <path d="M76.585 20.4315H66.3111C64.0427 20.4293 61.8676 19.5272 60.2634 17.9231C58.6593 16.319 57.7572 14.144 57.7551 11.8755V0L76.585 20.4315Z" fill="#FF9908"/>
        <path d="M84.6847 70.9543H32.8009C29.1205 70.9543 26.137 73.9377 26.137 77.6182V93.0871C26.137 96.7676 29.1205 99.751 32.8009 99.751H84.6847C88.3652 99.751 91.3486 96.7676 91.3486 93.0871V77.6182C91.3486 73.9377 88.3652 70.9543 84.6847 70.9543Z" fill="#FF9908"/>
        <path d="M48.7898 93.361C47.1745 93.361 45.9788 92.9768 45.2029 92.2079C44.434 91.432 44.0496 90.2361 44.0496 88.6208V83.7536C44.0496 82.1241 44.434 80.9249 45.2029 80.1561C45.9718 79.3801 47.1603 78.9992 48.7686 79.0133H51.3294C52.6555 79.0133 53.6676 79.2884 54.3659 79.8386C55.0643 80.3818 55.4701 81.2424 55.5829 82.4204C55.6252 82.7166 55.5688 82.9424 55.4136 83.0976C55.2584 83.2527 55.0327 83.3303 54.7365 83.3303C54.2004 83.3303 53.89 83.0341 53.8053 82.4415C53.7348 81.7926 53.5091 81.3552 53.1282 81.1295C52.7543 80.9038 52.1547 80.7909 51.3294 80.7909H48.7686C48.0209 80.7839 47.4319 80.8685 47.0016 81.0449C46.5784 81.2212 46.2751 81.5283 46.0917 81.9656C45.9153 82.3959 45.8272 82.9917 45.8272 83.7536V88.6208C45.8272 89.3756 45.9153 89.9681 46.0917 90.3984C46.2751 90.8287 46.5819 91.1357 47.0122 91.3191C47.4425 91.4955 48.035 91.5834 48.7898 91.5834H51.3294C51.9854 91.5834 52.4966 91.5129 52.8634 91.3718C53.2373 91.2237 53.4983 90.9664 53.6464 90.5996C53.7945 90.2328 53.8688 89.7141 53.8688 89.044V88.0494L52.2182 88.0283C51.6257 88.0283 51.3294 87.732 51.3294 87.1395C51.3294 86.5469 51.6257 86.2507 52.2182 86.2507L54.7576 86.2718C55.3501 86.2718 55.6464 86.5681 55.6464 87.1606V89.044C55.6464 90.5465 55.297 91.6436 54.5987 92.3349C53.9004 93.0191 52.8107 93.361 51.3294 93.361H48.7898Z" fill="white"/>
        <path d="M60.2223 93.361C59.919 93.361 59.6932 93.2904 59.5451 93.1494C59.404 93.0012 59.3335 92.7755 59.3335 92.4722V79.9232C59.3335 79.6128 59.404 79.3871 59.5451 79.246C59.6932 79.105 59.919 79.0344 60.2223 79.0344C60.5327 79.0344 60.7584 79.105 60.8995 79.246C61.0405 79.3871 61.1111 79.6128 61.1111 79.9232V92.4722C61.1111 92.7755 61.0405 93.0012 60.8995 93.1494C60.7584 93.2904 60.5327 93.361 60.2223 93.361Z" fill="white"/>
        <path d="M66.4017 93.3609C65.8092 93.3609 65.5129 93.0646 65.5129 92.4721V79.902C65.5129 79.3094 65.8092 79.0132 66.4017 79.0132H74.1893C74.4644 79.0132 74.6793 79.0945 74.8345 79.2568C74.9968 79.4119 75.0781 79.6269 75.0781 79.902C75.0781 80.4945 74.7818 80.7908 74.1893 80.7908H67.2905V85.2348H71.5441C72.1366 85.2348 72.4329 85.531 72.4329 86.1236C72.4329 86.7161 72.1366 87.0124 71.5441 87.0124H67.2905V92.4721C67.2905 93.0646 66.9943 93.3609 66.4017 93.3609Z" fill="white"/>
        <path d="M40.5727 64.0788C36.0459 64.0734 31.7061 62.2726 28.5051 59.0718C25.3042 55.8709 23.5035 51.5311 23.498 47.0041C23.5035 42.4772 25.3042 38.1375 28.5051 34.9365C31.7061 31.7356 36.0459 29.9349 40.5727 29.9294C45.0995 29.9349 49.4393 31.7356 52.6404 34.9365C55.8412 38.1375 57.642 42.4772 57.6474 47.0041C57.642 51.5311 55.8412 55.8709 52.6404 59.0718C49.4393 62.2726 45.0995 64.0734 40.5727 64.0788ZM40.5727 32.9211C36.8397 32.9277 33.2615 34.4136 30.6218 37.0532C27.9822 39.6929 26.4963 43.2709 26.4897 47.0041C26.4963 50.7373 27.9822 54.3153 30.6218 56.9552C33.2615 59.5946 36.8397 61.0805 40.5727 61.0871C44.3058 61.0805 47.884 59.5946 50.5238 56.9552C53.1632 54.3153 54.6491 50.7373 54.6557 47.0041C54.6491 43.2709 53.1632 39.6929 50.5238 37.0532C47.884 34.4136 44.3058 32.9277 40.5727 32.9211Z" fill="#FF9908"/>
        <path d="M40.5728 56.5726C38.6217 56.5755 36.7001 56.0962 34.9787 55.1776C33.6992 54.4946 32.5597 53.585 31.6139 52.4962C31.0716 51.8722 31.2636 50.9348 31.9516 50.4763C32.6386 50.0182 33.5587 50.2145 34.1262 50.8145C34.7796 51.505 35.5422 52.0884 36.3865 52.5394C37.6719 53.2265 39.107 53.5855 40.5645 53.585C42.0225 53.5859 43.4581 53.2265 44.7437 52.5386C45.5879 52.0871 46.3502 51.5033 47.0032 50.812C47.5704 50.2116 48.4911 50.0145 49.1785 50.473C49.8662 50.9311 50.0583 51.8676 49.5167 52.4917C48.5729 53.5796 47.4357 54.4892 46.1586 55.1722C44.4402 56.0917 42.5216 56.5726 40.5728 56.5726Z" fill="#FF9908"/>
        <path d="M35.8384 44.386C36.9361 44.386 37.8259 43.496 37.8259 42.3984C37.8259 41.3008 36.9361 40.4109 35.8384 40.4109C34.7407 40.4109 33.8508 41.3008 33.8508 42.3984C33.8508 43.496 34.7407 44.386 35.8384 44.386Z" fill="#FF9908"/>
        <path d="M45.3071 44.386C46.4048 44.386 47.2947 43.496 47.2947 42.3984C47.2947 41.3008 46.4048 40.4109 45.3071 40.4109C44.2095 40.4109 43.3196 41.3008 43.3196 42.3984C43.3196 43.496 44.2095 44.386 45.3071 44.386Z" fill="#FF9908"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default GifSvg;
