import styled from "@emotion/styled/macro";

import { ColorOptionProps } from "./types";

export const ColorPaletteWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 208px;
`;

export const ColorOption = styled.div<ColorOptionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size ? `${size}px` : "20px"};
  height: ${({ size }) => size ? `${size}px` : "20px"};
  flex: 0 0 auto;
  border: 1px solid rgba(166, 164, 162, 0.25);
  border-radius: 3px;
  background-color: ${({ colors }) => colors?.normal};
  margin: 3px 3px;
  cursor: ${({ disabled }) => disabled ? "auto" : "pointer"};
  transition: background 0.2s;
  &:hover {
    background-color: ${({ colors }) => colors?.hover};
  }
`;
