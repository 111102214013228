import { FC } from "react";
import { withNProgress } from "@tanem/react-nprogress";

import Bar from "./Bar";
import Container from "./Container";

const Progress: FC<{
  animationDuration: number
  isFinished: boolean
  progress: number
}> = ({ isFinished, progress, animationDuration }) => (
  <Container animationDuration={animationDuration} isFinished={isFinished}>
    <Bar animationDuration={animationDuration} progress={progress} />
  </Container>
);

export default withNProgress(Progress);
