import { BoardViewInterface } from "lib/types/boardTypes";
import { EntityId } from "lib/types/entity";
import { captureException } from "lib/utils/sentry";
import { pick } from "lodash";
import { useCallback } from "react";
import BoardService from "services/BoardService";
import useBoardPerformRequest, { BoardPerformRequestHookProps } from "./useBoardPerformRequest";

const useBoardSetViews = (props: BoardPerformRequestHookProps) => {
  const { board } = props;
  const performRequest = useBoardPerformRequest(props);

  const updateBoardView = useCallback((viewId: EntityId, payload: Partial<BoardViewInterface>) => {
    if (!board) {
      return;
    }

    const viewIndex = board.views.findIndex(view => String(view.id) === String(viewId));
    if (viewIndex === -1) {
      captureException(`Cannot find view ${viewId}`);
      return;
    }

    const views = Array.from(board.views);
    const view = views[viewIndex];
    views[viewIndex] = { ...view, ...payload };

    const requestFn = () => BoardService.viewsUpdate(board.id, viewId, payload);

    return performRequest({ views }, requestFn);
  }, [board, performRequest]);

  const deleteBoardView = (viewId: EntityId) => {
    if (!board) {
      return;
    }

    const viewIndex = board.views.findIndex(views => String(views.id) === String(viewId));
    if (viewIndex === -1) {
      captureException(`Cannot find view ${viewId}`);
      return;
    }

    const views = Array.from(board.views);
    views.splice(viewIndex, 1);

    const requestFn = () => BoardService.viewsDelete(board.id, viewId);

    return performRequest({ views }, requestFn);
  };

  const reorderBoardViews = (data: Array<BoardViewInterface>) => {
    if (!board) {
      return;
    }

    const payload = data.map(item => pick(item, ["id", "sort"]));
    const requestFn = () => BoardService.viewsReorder(board.id, payload);

    return performRequest({ views: data }, requestFn);
  };

  return { updateBoardView, deleteBoardView, reorderBoardViews };
};

export default useBoardSetViews;
