import { Form, Tooltip } from "antd";
import SyncedFieldTooltip from "components/blocks/SyncedFieldTooltip";
import { DefaultIconColor } from "components/icons/lib";
import { useBoardContext } from "lib/contexts/BoardContext";
import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import useMemberPermission from "lib/customHooks/useMemberPermission";

import { KeyboardKeys } from "lib/types/keyCodes";
import { TaskInterface, TaskSetValueHandler } from "lib/types/tasksTypes";
import { MemberRoles } from "lib/types/types";
import TaskCheckmark from "pages/Task/TaskCheckmark";
import { ExpandWrapper, TaskExpandButton } from "pages/Viewer/ViewerTasks/TaskItem/styled";
import React, { FC, memo, RefObject, useEffect, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useHistory } from "react-router-dom";
import { DummyRowId } from "../../utils/listReducer";

import {
  TaskTitleDragDrop,
  TaskTitleInputWrapper,
  TaskTitleWrapper,
  TextAreaCommentButton,
  TextAreaCommentWrapper,
  TitleInput,
} from "./styled";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

interface TaskTitleFieldProps {
  value: string;
  setValue: TaskSetValueHandler;
  dragHandleProps: Record<string, any>;
  afterCreate?: () => void;
  onExpand?: (recordId: string) => void;
}

const TaskTitle: FC<TaskTitleFieldProps> = (props) => {
  const { value, setValue, dragHandleProps, onExpand } = props;

  const history = useHistory();
  const { task } = useTaskContext() as TaskContextInterface;
  const { board } = useBoardContext();
  const [form] = Form.useForm();

  const { isCompletionEnabled } = useBoardFeatures(board || task.board);

  const titleRef = useRef(null) as RefObject<any>;
  const fieldName = `title_${task?.id}`;
  const isTaskExist = task && Object.keys(task).length > 1;

  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);
  const allowedToComplete = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const isSyncedBoard = !!board?.externalDataSource || !!(task?.board?.externalDataSource as unknown as TaskInterface);

  const textColor = (isCompletionEnabled && task?.completed) ? DefaultIconColor : "black";

  useEffect(() => {
    form.setFieldsValue({ [fieldName]: value });
  }, [fieldName, form, value]);

  const onExpandTask = (hash = "") => {
    if (!task) {
      return;
    }

    if (onExpand) {
      return onExpand(task.uid);
    }

    if (history.location.pathname.slice(-1) === "/") {
      history.push(`${history.location.pathname}${task?.uid}${hash}`);
    } else {
      history.push(`${history.location.pathname}/${task?.uid}${hash}`);
    }
  };

  const handleOnBlur = async () => {
    try {
      const values = await form.validateFields();
      const updateValue = values[fieldName].trim();

      if (updateValue === value) {
        return;
      }

      if (!updateValue) {
        form.setFieldsValue({ [fieldName]: value });
        return;
      }

      await setValue({ cellName: "title", cellValue: values[fieldName] });

      if (props.afterCreate && task?.id === DummyRowId) {
        props.afterCreate();
      }
    } catch (error) {
      form.setFieldsValue({ [fieldName]: value });
      return;
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyboardKeys.Escape) {
      event.preventDefault();
      titleRef.current.blur();
      form.setFieldsValue({ [fieldName]: value });
    }

    if (event.key === KeyboardKeys.Tab || event.key === KeyboardKeys.Enter) {
      event.preventDefault();
      titleRef.current.blur();
    }
  };

  return (
    <TaskTitleWrapper isCompletionDisabled={!isCompletionEnabled}>
      {allowedToEdit && (Object.values(task || {}).length > 1) && (
        <TaskTitleDragDrop dragHandleProps={dragHandleProps} />
      )}
      {isCompletionEnabled &&
        <TaskCheckmark
          id={task?.id}
          isCompleted={!!task?.completed}
          clickable={allowedToComplete}
          setValue={setValue}
        />
      }
      <TaskTitleInputWrapper>
        <OutsideClickHandler
          onOutsideClick={() => titleRef.current.blur()}
        >
          <Tooltip title={task.title} mouseLeaveDelay={0}>
            <Form form={form} initialValues={{ [fieldName]: value }}>
              <Form.Item
                name={fieldName}
                rules={
                  [{ required: false, whitespace: true }]
                }
              >
                <TitleInput
                  autoFocus={!isTaskExist}
                  ref={titleRef}
                  size="small"
                  autoComplete="off"
                  onKeyDown={handleOnKeyDown}
                  onBlur={handleOnBlur}
                  style={{ color: textColor }}
                  disabled={isSyncedBoard || !allowedToEdit}
                  suffix={isSyncedBoard && <SyncedFieldTooltip />}
                />
              </Form.Item>
            </Form>
          </Tooltip>
        </OutsideClickHandler>
      </TaskTitleInputWrapper>
      {isTaskExist && (task.comments?.length !== 0) && (
        <TextAreaCommentWrapper onClick={() => onExpandTask("#comments")}>
          <TextAreaCommentButton>
            {task.comments?.length}
            <div style={{ marginTop: "-1px", marginLeft: "2px" }}>
              <img src={process.env.PUBLIC_URL + "/icons/chat-bubble.svg"} alt="expand task" />
            </div>
          </TextAreaCommentButton>
        </TextAreaCommentWrapper>
      )}
      {isTaskExist && (
        <ExpandWrapper onClick={() => onExpandTask()}>
          <TaskExpandButton>
            <img src={process.env.PUBLIC_URL + "/icons/expand.svg"} alt="view task" />
          </TaskExpandButton>
        </ExpandWrapper>
      )}
    </TaskTitleWrapper>
  );
};

export default memo(TaskTitle);
