import { FC, useState } from "react";
import { Dropdown } from "antd";
import ViewpointCardMenuItems from "components/blocks/ViewpointCard/ViewpointCardMenu/ViewpointCardMenuItems";
import DropdownSvg from "components/icons/resizable/dropdown";
import { ViewpointType } from "lib/types/tasksTypes";
import { MoreButton, MoreButtonContainer } from "components/blocks/ViewpointLikeCard/styled";
import { ViewpointContextMenuItems } from "./ViewpointCardMenuItems/lib";

interface ViewpointCardMenuProps {
  viewpoint: ViewpointType | null;
  menuItems: Array<ViewpointContextMenuItems>;
}

const ViewpointCardMenu: FC<ViewpointCardMenuProps> = (props) => {
  const { viewpoint, menuItems } = props;
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleVisibility = () => setIsDropdownVisible(!isDropdownVisible);

  const handleOnClick: React.MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();
    toggleVisibility();
  };

  if (!viewpoint) {
    return (null);
  }

  return (
    <Dropdown
      overlay={<ViewpointCardMenuItems viewpoint={viewpoint} toggleVisibility={toggleVisibility} menuItems={menuItems} />}
      trigger={["click", "contextMenu"]}
      onOpenChange={toggleVisibility}
      open={isDropdownVisible}
    >
      <MoreButtonContainer>
        <MoreButton
          onClick={handleOnClick}
          active={isDropdownVisible}
        >
          <DropdownSvg size={11} />
        </MoreButton>
      </MoreButtonContainer>
    </Dropdown>
  );
};

export default ViewpointCardMenu;
