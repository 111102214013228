import styled from "@emotion/styled/macro";
import AutomationsCssCard from "components/blocks/Automations/AutomationsDrawer/AutomationItem/styled";
import { Input } from "antd";
import { DisplayedFieldClearWrapper } from "pages/Board/BoardForm/BoardFormDisplayedField/styled";

const { TextArea } = Input;

export const BoardFormPageContainer = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr;
  height: 100%;
  .ant-select-selection-item {
    border-radius: 12px !important;
  }
`;

export const BoardFormFieldsContainer = styled.div`
  border-right: 1px solid #f2f2f2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const BoardFormFieldsInnerContainer = styled.div`
  padding: 20px 20px 70px 20px;
`;

export const BoardFormFieldsSpan = styled.div`
  margin-bottom: 10px;
`;

export const BoardFormFieldPlusContainer = styled.div`
  transition: opacity 200ms ease;
  opacity: 0;
`;

export const BoardFormFieldContainer = styled.div<{ allowedToEdit?: boolean }>`
  ${AutomationsCssCard};
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: ${({ allowedToEdit }) => allowedToEdit ? "pointer" : "default"};
  color: #000000d3;
  &:hover {
    ${BoardFormFieldPlusContainer} {
      opacity: 1;
    }
    color: black;
  }
`;

export const BoardFormFieldAdded = styled.div<{ allowedToEdit?: boolean }>`
  ${AutomationsCssCard};
  align-items: start;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: ${({ allowedToEdit }) => allowedToEdit ? "pointer" : "default"};
  &:hover {
    ${DisplayedFieldClearWrapper} {
      display: block;
      opacity: 1;
    }
  }
`;

export const BoardFormBodyContainer = styled.div`
  background-color: #f9f9f8;
  //overflow-y: scroll;
  display: flex;
  flex-direction: column;
  //padding: 40px 40px 100px 40px;
  overflow: hidden;
  min-height: 100%;
`;

export const BoardFormBodyTitle = styled(TextArea)`
  font-size: 20px;
  font-weight: 600;
  background-color: transparent;
  border: 1px solid transparent;
  margin-bottom: 6px;
`;

export const BoardFormBodyDescription = styled(TextArea)`
  color: #4d4d4d !important;
  background-color: transparent;
  border: 1px solid transparent;
`;

export const BoardFormBodyEmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #e4e4e4;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #bfbfbf;
`;

export const BoardFormBody = styled.div`
  width: 600px;
  margin-inline: auto;
  background-color: #fafafa;
  border-radius: 10px;
  padding: 40px 0 100px 0;
`;

export const BoardFormBodyHeader = styled.div`
  border: 1px solid #f2f2f2;
  background-color: white;
  padding: 30px 40px;
  border-radius: 10px 10px 0 0;
`;

export const BoardFormBodyFields = styled.div`
  border: 1px solid #f2f2f2;
  background-color: white;
  padding: 40px;
  margin-top: -1px;
  border-radius: 0 0 10px 10px;
`;
