import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const TiffSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.5291 20.5167C64.2512 20.5145 62.067 19.6086 60.4562 17.9978C58.8453 16.387 57.9395 14.203 57.9374 11.925V0H19.5457C18.0729 -1e-07 16.6145 0.29014 15.2539 0.853883C13.8933 1.41763 12.657 2.2439 11.6158 3.28552C10.5746 4.32713 9.74874 5.56371 9.18551 6.92454C8.62227 8.28538 8.33271 9.74388 8.33325 11.2167V78.8042C8.33271 80.2771 8.62227 81.7354 9.18551 83.0963C9.74874 84.4571 10.5746 85.6938 11.6158 86.7354C12.657 87.7771 13.8933 88.6033 15.2539 89.1671C16.6145 89.7308 18.0729 90.0208 19.5457 90.0208H65.6291C68.6041 90.0208 71.457 88.8392 73.5603 86.7354C75.6641 84.6321 76.8457 81.7792 76.8457 78.8042V20.5208L66.5291 20.5167Z" fill="#A140FF"/>
        <path d="M76.8458 20.5167H66.5292C64.2512 20.5145 62.0671 19.6086 60.4562 17.9978C58.8454 16.387 57.9396 14.203 57.9375 11.925V0L76.8458 20.5167Z" fill="#A140FF"/>
        <path d="M84.975 71.0833H32.8749C29.1792 71.0833 26.1833 74.0791 26.1833 77.775V93.3083C26.1833 97.0041 29.1792 100 32.8749 100H84.975C88.6708 100 91.6666 97.0041 91.6666 93.3083V77.775C91.6666 74.0791 88.6708 71.0833 84.975 71.0833Z" fill="#A140FF"/>
        <path d="M44.0454 93.75C43.7479 93.75 43.5248 93.6758 43.3761 93.5271C43.2273 93.3783 43.1529 93.155 43.1529 92.8575V81.1488H39.0304C38.7329 81.1488 38.5098 81.0742 38.3611 80.9254C38.2123 80.7767 38.1379 80.5538 38.1379 80.2563C38.1379 79.9588 38.2123 79.7354 38.3611 79.5867C38.5098 79.4379 38.7329 79.3638 39.0304 79.3638H49.0604C49.3579 79.3638 49.5811 79.4379 49.7298 79.5867C49.8786 79.7354 49.9529 79.9588 49.9529 80.2563C49.9529 80.5538 49.8786 80.7767 49.7298 80.9254C49.5811 81.0742 49.3579 81.1488 49.0604 81.1488H44.9379V92.8575C44.9379 93.1409 44.86 93.3604 44.7042 93.5163C44.5554 93.6721 44.3359 93.75 44.0454 93.75Z" fill="white"/>
        <path d="M53.6772 93.75C53.3726 93.75 53.1459 93.6792 52.9972 93.5375C52.8555 93.3888 52.7847 93.1621 52.7847 92.8575V80.2563C52.7847 79.9446 52.8555 79.7179 52.9972 79.5763C53.1459 79.4346 53.3726 79.3638 53.6772 79.3638C53.9888 79.3638 54.2155 79.4346 54.3572 79.5763C54.4988 79.7179 54.5697 79.9446 54.5697 80.2563V92.8575C54.5697 93.1621 54.4988 93.3888 54.3572 93.5375C54.2155 93.6792 53.9888 93.75 53.6772 93.75Z" fill="white"/>
        <path d="M59.8822 93.75C59.2872 93.75 58.9897 93.4525 58.9897 92.8575V80.235C58.9897 79.64 59.2872 79.3425 59.8822 79.3425H67.7022C67.9785 79.3425 68.1943 79.4238 68.3502 79.5867C68.5131 79.7425 68.5947 79.9588 68.5947 80.235C68.5947 80.83 68.2972 81.1275 67.7022 81.1275H60.7747V85.59H65.046C65.641 85.59 65.9385 85.8875 65.9385 86.4825C65.9385 87.0775 65.641 87.375 65.046 87.375H60.7747V92.8575C60.7747 93.4525 60.4772 93.75 59.8822 93.75Z" fill="white"/>
        <path d="M71.6896 93.75C71.0946 93.75 70.7971 93.4525 70.7971 92.8575V80.235C70.7971 79.64 71.0946 79.3425 71.6896 79.3425H79.5096C79.7859 79.3425 80.0021 79.4238 80.158 79.5867C80.3209 79.7425 80.4021 79.9588 80.4021 80.235C80.4021 80.83 80.1046 81.1275 79.5096 81.1275H72.5821V85.59H76.8534C77.4484 85.59 77.7459 85.8875 77.7459 86.4825C77.7459 87.0775 77.4484 87.375 76.8534 87.375H72.5821V92.8575C72.5821 93.4525 72.2846 93.75 71.6896 93.75Z" fill="white"/>
        <path d="M51.7959 61.0041H33.3875C31.5858 61.0021 29.8587 60.2854 28.5847 59.0112C27.3107 57.7375 26.594 56.01 26.5918 54.2083V35.8042C26.594 34.0025 27.3107 32.2752 28.5847 31.0012C29.8587 29.7272 31.5858 29.0105 33.3875 29.0083H51.7959C53.5963 29.0116 55.3222 29.7289 56.5947 31.0029C57.8672 32.2769 58.5822 34.0035 58.5834 35.8042V54.2083C58.5822 56.0087 57.8672 57.7354 56.5947 59.0096C55.3222 60.2833 53.5963 61.0008 51.7959 61.0041ZM33.3875 32.0125C32.3823 32.0136 31.4185 32.4134 30.7077 33.1243C29.9968 33.8351 29.597 34.7989 29.5959 35.8042V54.2083C29.597 55.2137 29.9968 56.1775 30.7077 56.8883C31.4185 57.5991 32.3823 57.9987 33.3875 58H51.7959C52.8005 57.9979 53.7634 57.5975 54.473 56.8866C55.183 56.1758 55.5822 55.2129 55.5834 54.2083V35.8042C55.5822 34.7996 55.183 33.8365 54.473 33.1257C53.7634 32.415 52.8005 32.0146 51.7959 32.0125H33.3875Z" fill="#A140FF"/>
        <path d="M57.0832 50.7083V54.2083C57.0832 55.6108 56.5262 56.9562 55.5349 57.9487C54.5432 58.9408 53.1987 59.4987 51.7957 59.5H33.3874C31.9839 59.5 30.638 58.9425 29.6456 57.95C28.6532 56.9575 28.0957 55.6116 28.0957 54.2083V48.3333C31.0415 47.7 35.5291 47.5 40.9791 49.675L44.3499 46.4583L46.6499 52.2916C46.6499 52.2916 47.2666 50.1458 49.2582 50.45C51.2499 50.7541 54.4749 51.8333 55.8541 50.9125C56.2257 50.6979 56.6624 50.6254 57.0832 50.7083Z" fill="#A140FF"/>
        <path d="M50.6416 39.5625C51.7716 39.5625 52.6874 38.6466 52.6874 37.5167C52.6874 36.3868 51.7716 35.4708 50.6416 35.4708C49.5117 35.4708 48.5957 36.3868 48.5957 37.5167C48.5957 38.6466 49.5117 39.5625 50.6416 39.5625Z" fill="#A140FF"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default TiffSvg;
