import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const DocxSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.3112 20.4357C64.0427 20.4335 61.8676 19.5313 60.2635 17.9272C58.6593 16.3231 57.7573 14.1481 57.7552 11.8796V7.71176e-07H19.527C18.0603 -0.000544262 16.6078 0.28782 15.2527 0.848718C13.8975 1.40962 12.6661 2.23204 11.6287 3.26895C10.5914 4.30585 9.7686 5.53697 9.2072 6.89195C8.64579 8.24693 8.35686 9.69925 8.35686 11.1659V78.4772C8.35686 79.944 8.64579 81.3963 9.2072 82.751C9.7686 84.1062 10.5914 85.3374 11.6287 86.3743C12.6661 87.4112 13.8975 88.2336 15.2527 88.7942C16.6078 89.3552 18.0603 89.6436 19.527 89.6432H65.4191C68.3805 89.6432 71.2208 88.4668 73.3145 86.3726C75.4087 84.2788 76.5851 81.4386 76.5851 78.4772V20.4357H66.3112Z" fill="#0072FF"/>
        <path d="M76.5851 20.4357H66.3112C64.0427 20.4335 61.8676 19.5313 60.2635 17.9272C58.6593 16.3231 57.7573 14.1481 57.7552 11.8796V0L76.5851 20.4357Z" fill="#0072FF"/>
        <path d="M62.5477 34.7427H19.7095C19.298 34.7427 18.9033 34.5791 18.6122 34.2881C18.3212 33.997 18.1577 33.6024 18.1577 33.1908C18.1571 32.9866 18.1969 32.7844 18.2746 32.5956C18.3524 32.4069 18.4666 32.2353 18.6107 32.0907C18.7549 31.9462 18.9261 31.8315 19.1147 31.7533C19.3032 31.6751 19.5054 31.6348 19.7095 31.6348H62.5477C62.9606 31.6348 63.356 31.7987 63.6481 32.0905C63.9398 32.3823 64.1037 32.7781 64.1037 33.1908C64.1025 33.6027 63.9382 33.9975 63.6465 34.2884C63.3548 34.5793 62.9598 34.7427 62.5477 34.7427Z" fill="#0072FF"/>
        <path d="M62.5477 44.0248H19.7095C19.298 44.0248 18.9033 43.8613 18.6122 43.5705C18.3212 43.2792 18.1577 42.8846 18.1577 42.473C18.1571 42.2688 18.1969 42.0667 18.2746 41.8779C18.3524 41.6892 18.4666 41.5174 18.6107 41.373C18.7549 41.2284 18.9261 41.1138 19.1147 41.0355C19.3032 40.9573 19.5054 40.917 19.7095 40.917H62.5477C62.9606 40.917 63.356 41.0809 63.6481 41.3727C63.9398 41.6647 64.1037 42.0605 64.1037 42.473C64.1025 42.885 63.9382 43.2796 63.6465 43.5705C63.3548 43.8613 62.9598 44.0248 62.5477 44.0248Z" fill="#0072FF"/>
        <path d="M62.5477 53.3071H19.7095C19.5054 53.3071 19.3032 53.2669 19.1147 53.1884C18.9261 53.1104 18.7549 52.9955 18.6107 52.8511C18.4666 52.7067 18.3524 52.5349 18.2746 52.3461C18.1969 52.1573 18.1571 51.9552 18.1577 51.7511C18.1577 51.3395 18.3212 50.9449 18.6122 50.6536C18.9033 50.3627 19.298 50.1992 19.7095 50.1992H62.5477C62.9598 50.1992 63.3548 50.3627 63.6465 50.6536C63.9382 50.9444 64.1025 51.3391 64.1037 51.7511C64.1037 52.1639 63.9398 52.5594 63.6481 52.8515C63.356 53.1432 62.9606 53.3071 62.5477 53.3071Z" fill="#0072FF"/>
        <path d="M45.6432 62.5893H19.7095C19.5054 62.5893 19.3032 62.5491 19.1147 62.4707C18.9261 62.3926 18.7549 62.2777 18.6107 62.1333C18.4666 61.9889 18.3524 61.8171 18.2746 61.6283C18.1969 61.4399 18.1571 61.2375 18.1577 61.0333C18.1577 60.6217 18.3212 60.2271 18.6122 59.9358C18.9033 59.6449 19.298 59.4814 19.7095 59.4814H45.6432C46.0547 59.4814 46.4495 59.6449 46.7405 59.9358C47.0315 60.2271 47.195 60.6217 47.195 61.0333C47.1956 61.2375 47.1558 61.4399 47.0781 61.6283C47.0003 61.8171 46.8861 61.9889 46.742 62.1333C46.5978 62.2777 46.4266 62.3926 46.238 62.4707C46.0495 62.5491 45.8473 62.5893 45.6432 62.5893Z" fill="#0072FF"/>
        <path d="M84.6846 70.7925H32.8008C29.1205 70.7925 26.1369 73.7759 26.1369 77.4564V92.9253C26.1369 96.6058 29.1205 99.5892 32.8008 99.5892H84.6846C88.3651 99.5892 91.3485 96.6058 91.3485 92.9253V77.4564C91.3485 73.7759 88.3651 70.7925 84.6846 70.7925Z" fill="#0072FF"/>
        <path d="M32.2814 93.3609C31.6888 93.3609 31.3926 93.0646 31.3926 92.4721V79.902C31.3926 79.3094 31.6888 79.0132 32.2814 79.0132H37.3814C39.2225 79.0132 40.5768 79.4505 41.4444 80.3252C42.3191 81.1929 42.7565 82.5472 42.7565 84.3883V87.9858C42.7565 89.8269 42.3227 91.185 41.455 92.0597C40.5944 92.9273 39.2366 93.3609 37.3814 93.3609H32.2814ZM33.1702 91.5833H37.3814C38.2843 91.5833 38.9967 91.4742 39.5187 91.2555C40.0478 91.0298 40.4216 90.6559 40.6403 90.1339C40.866 89.6049 40.9789 88.8887 40.9789 87.9858V84.3883C40.9789 83.4924 40.866 82.7837 40.6403 82.2617C40.4216 81.7327 40.0478 81.3551 39.5187 81.1294C38.9967 80.9036 38.2843 80.7908 37.3814 80.7908H33.1702V91.5833Z" fill="white"/>
        <path d="M50.8764 93.361C49.261 93.361 48.0653 92.9768 47.2894 92.2079C46.5205 91.432 46.1361 90.2361 46.1361 88.6208V83.7324C46.1361 82.1029 46.524 80.9075 47.3 80.1457C48.0759 79.3768 49.268 78.9992 50.8764 79.0133H53.4158C55.0382 79.0133 56.2336 79.398 57.0025 80.1664C57.7714 80.9357 58.156 82.1312 58.156 83.7536V88.6208C58.156 90.2361 57.7714 91.432 57.0025 92.2079C56.2336 92.9768 55.0382 93.361 53.4158 93.361H50.8764ZM50.8764 91.5834H53.4158C54.1705 91.5834 54.7631 91.4955 55.1934 91.3191C55.6237 91.1357 55.927 90.8287 56.1033 90.3984C56.2867 89.9681 56.3784 89.3756 56.3784 88.6208V83.7536C56.3784 83.0059 56.2867 82.4171 56.1033 81.9868C55.927 81.5565 55.6237 81.2494 55.1934 81.066C54.7631 80.8826 54.1705 80.7909 53.4158 80.7909H50.8764C50.1286 80.7839 49.5396 80.8685 49.1093 81.0449C48.679 81.2212 48.3722 81.5245 48.1888 81.9548C48.0054 82.3851 47.9137 82.9776 47.9137 83.7324V88.6208C47.9137 89.3756 48.0018 89.9681 48.1782 90.3984C48.3616 90.8287 48.6684 91.1357 49.0987 91.3191C49.529 91.4955 50.1216 91.5834 50.8764 91.5834Z" fill="white"/>
        <path d="M66.2722 93.361C64.6568 93.361 63.4614 92.9768 62.6855 92.2079C61.9166 91.432 61.532 90.2361 61.532 88.6208V83.7536C61.532 82.1241 61.9166 80.9249 62.6855 80.1561C63.4544 79.3801 64.6427 78.9992 66.251 79.0133H68.8116C70.1378 79.0133 71.1502 79.2884 71.8485 79.8386C72.5469 80.3818 72.9523 81.2424 73.0651 82.4204C73.1075 82.7166 73.051 82.9424 72.8958 83.0976C72.7407 83.2527 72.5149 83.3303 72.2187 83.3303C71.6826 83.3303 71.3722 83.0341 71.2875 82.4415C71.217 81.7926 70.9913 81.3552 70.6104 81.1295C70.2365 80.9038 69.6369 80.7909 68.8116 80.7909H66.251C65.5033 80.7839 64.9145 80.8685 64.4842 81.0449C64.061 81.2212 63.7577 81.5283 63.5743 81.9656C63.3979 82.3959 63.3095 82.9917 63.3095 83.7536V88.6208C63.3095 89.3756 63.3979 89.9681 63.5743 90.3984C63.7577 90.8287 64.0643 91.1357 64.4946 91.3191C64.9249 91.4955 65.5174 91.5834 66.2722 91.5834H68.8116C69.6369 91.5834 70.2365 91.4706 70.6104 91.2449C70.9913 91.0121 71.217 90.5747 71.2875 89.9328C71.3722 89.3403 71.6826 89.044 72.2187 89.044C72.5149 89.044 72.7407 89.1216 72.8958 89.2768C73.051 89.432 73.1075 89.6577 73.0651 89.954C72.9523 91.139 72.5469 92.0034 71.8485 92.5465C71.1502 93.0897 70.1378 93.361 68.8116 93.361H66.2722Z" fill="white"/>
        <path d="M76.3938 93.1705C75.9212 92.846 75.8506 92.4298 76.1822 91.9219L80.0548 86.2083L75.9705 80.4522C75.6531 79.9867 75.7307 79.5705 76.2033 79.2037C76.69 78.8722 77.0992 78.9498 77.4307 79.4365L81.2187 84.7481L85.0278 79.4365C85.3946 78.9639 85.8037 78.9004 86.2552 79.246C86.7349 79.5987 86.8054 80.0078 86.4668 80.4734L82.2979 86.2717L86.2975 91.9008C86.6291 92.3875 86.5515 92.8107 86.0647 93.1705C85.578 93.502 85.1689 93.4244 84.8373 92.9377L81.1552 87.7531L77.6423 92.9589C77.3178 93.4174 76.9017 93.4879 76.3938 93.1705Z" fill="white"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default DocxSvg;
