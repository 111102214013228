import { FC, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as procoreIframeHelpers from "@procore/procore-iframe-helpers";

import AuthService from "services/AuthService";
import { getErrorMessage } from "lib/helpers/getErrorMessage";
import { captureException } from "lib/utils/sentry";
import { setToken } from "app/slices/authSlice";
import { useAppDispatch } from "app/store";

const AuthRedirect: FC = () => {
  const location = useLocation();
  const { providerName } = useParams<{ providerName: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const iframeHelperContext = procoreIframeHelpers.initialize();

  useEffect(() => {
    AuthService.providerConnect(providerName, location.search)
      .then((res) => {
        dispatch(setToken(res.jwt));

        iframeHelperContext.authentication.notifySuccess(res);

        history.push("/home");
      })
      .catch(err => {
        const errorMessage = getErrorMessage(err);
        iframeHelperContext.authentication.notifyFailure(err);
        captureException(err, true, errorMessage);

        history.push("/auth");
      });
  }, [dispatch, history, location.search, providerName]);

  return (null);
};

export default AuthRedirect;
