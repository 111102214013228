import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const AviSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.4583 20.5167C64.18 20.5145 61.9954 19.6087 60.3842 17.998C58.7725 16.3874 57.8658 14.2034 57.8625 11.925V0H19.4583C16.4846 0 13.6327 1.18132 11.5299 3.28407C9.42715 5.38683 8.24585 8.23875 8.24585 11.2125V78.8042C8.24916 81.7754 9.43206 84.6237 11.5346 86.7229C13.6371 88.8225 16.4871 90.0012 19.4583 90H65.5417C68.5154 90 71.3675 88.8187 73.47 86.7158C75.5729 84.6133 76.7542 81.7612 76.7542 78.7875V20.5167H66.4583Z" fill="#FF9908"/>
        <path d="M76.7542 20.5167H66.4583C64.18 20.5145 61.9954 19.6087 60.3842 17.998C58.7725 16.3874 57.8658 14.2034 57.8625 11.925V0L76.7542 20.5167Z" fill="#FF9908"/>
        <path d="M84.8875 71.0833H32.7874C29.0917 71.0833 26.0958 74.0791 26.0958 77.7749V93.3083C26.0958 97.0041 29.0917 99.9999 32.7874 99.9999H84.8875C88.5833 99.9999 91.5792 97.0041 91.5792 93.3083V77.7749C91.5792 74.0791 88.5833 71.0833 84.8875 71.0833Z" fill="#FF9908"/>
        <path d="M43.5954 93.665C43.0288 93.4455 42.8588 93.0559 43.0854 92.4963L48.1429 80.0438C48.3554 79.5763 48.6883 79.3425 49.1417 79.3425H49.1842C49.6446 79.3638 49.9563 79.5975 50.1192 80.0438L55.2617 92.4963C55.4883 93.0559 55.3254 93.4455 54.7729 93.665C54.2133 93.8846 53.8238 93.7217 53.6042 93.1763L52.4567 90.3925H45.8373L44.7429 93.1763C44.5233 93.7288 44.1408 93.8917 43.5954 93.665ZM46.5385 88.6075H51.7238L49.0779 82.1688L46.5385 88.6075Z" fill="white"/>
        <path d="M62.3112 93.75C61.9429 93.75 61.6525 93.5162 61.44 93.0487L56.1912 80.6175C56.085 80.3695 56.0567 80.15 56.1062 79.9587C56.1558 79.7604 56.3187 79.5975 56.595 79.47C57.1758 79.1795 57.5867 79.3283 57.8275 79.9162L62.7892 91.6675H62.1729L67.0075 79.9162C67.2412 79.3354 67.6521 79.1866 68.24 79.47C68.4879 79.5975 68.64 79.7604 68.6967 79.9587C68.7604 80.15 68.7429 80.3695 68.6437 80.6175L63.3525 93.0487C63.1329 93.5162 62.8142 93.75 62.3962 93.75H62.3112Z" fill="white"/>
        <path d="M72.3321 93.75C72.0275 93.75 71.8008 93.6792 71.6521 93.5375C71.5104 93.3888 71.4396 93.1621 71.4396 92.8575V80.2563C71.4396 79.9446 71.5104 79.7179 71.6521 79.5763C71.8008 79.4346 72.0275 79.3638 72.3321 79.3638C72.6437 79.3638 72.8704 79.4346 73.0121 79.5763C73.1537 79.7179 73.2246 79.9446 73.2246 80.2563V92.8575C73.2246 93.1621 73.1537 93.3888 73.0121 93.5375C72.8704 93.6792 72.6437 93.75 72.3321 93.75Z" fill="white"/>
        <path d="M42.4334 62.4291C37.8877 62.4237 33.5298 60.6154 30.3155 57.4012C27.1012 54.187 25.293 49.8291 25.2875 45.2833C25.293 40.7376 27.1012 36.3797 30.3155 33.1655C33.5298 29.9512 37.8877 28.143 42.4334 28.1375C46.979 28.143 51.3371 29.9512 54.5513 33.1655C57.7654 36.3797 59.5738 40.7376 59.5792 45.2833C59.5738 49.8291 57.7654 54.187 54.5513 57.4012C51.3371 60.6154 46.979 62.4237 42.4334 62.4291ZM42.4334 31.1416C38.6848 31.1482 35.0916 32.6403 32.441 35.2909C29.7903 37.9416 28.2983 41.5347 28.2917 45.2833C28.2917 49.0362 29.7808 52.6358 32.4322 55.2916C35.0835 57.9479 38.6804 59.4433 42.4334 59.4499C46.1862 59.4433 49.7832 57.9479 52.4346 55.2916C55.0859 52.6358 56.575 49.0362 56.575 45.2833C56.5684 41.5347 55.0763 37.9416 52.4259 35.2909C49.7751 32.6403 46.1819 31.1482 42.4334 31.1416Z" fill="#FF9908"/>
        <path d="M47.35 44.1084L43.3625 41.8084C43.1561 41.6893 42.922 41.6265 42.6837 41.6265C42.4454 41.6264 42.2112 41.6888 42.0048 41.808C41.7983 41.9272 41.6268 42.0984 41.5075 42.3047C41.3882 42.5109 41.3253 42.7451 41.3251 42.9834V47.5876C41.3253 47.8259 41.3882 48.0601 41.5075 48.2663C41.6268 48.4726 41.7983 48.6438 42.0048 48.763C42.2112 48.8822 42.4454 48.9447 42.6837 48.9447C42.922 48.9447 43.1561 48.8818 43.3625 48.7626L47.35 46.4584C47.5559 46.3393 47.7267 46.1676 47.8455 45.9613C47.9643 45.7551 48.0269 45.5213 48.0269 45.2834C48.0269 45.0455 47.9643 44.8118 47.8455 44.6055C47.7267 44.3993 47.5559 44.228 47.35 44.1084Z" fill="#FF9908"/>
        <path d="M39.2792 41.45H37.8209C37.2778 41.45 36.8375 41.8904 36.8375 42.4333V48.1375C36.8375 48.6804 37.2778 49.1208 37.8209 49.1208H39.2792C39.8223 49.1208 40.2625 48.6804 40.2625 48.1375V42.4333C40.2625 41.8904 39.8223 41.45 39.2792 41.45Z" fill="#FF9908"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default AviSvg;
