import styled from "@emotion/styled/macro";
import { selectTagCss } from "styles/common-css";
import { dataInput } from "components/blocks/TaskTable/styled";
import { fieldDetailedHover, fieldDetailedWrapper } from "../../styled";
import {
  customSelectsWrapper,
  customSelectsGroup,
  customSelectsSearch
} from "../../../../../ui/CustomSelects/styled";

export const MultiSelectFieldWrapper = styled.div`
  ${customSelectsWrapper};
`;

export const MultiSelectTag = styled.div<{ bg?: string }>`
  ${selectTagCss};
  background-color: ${({ bg }) => bg};
  margin: 3px 4px 3px 0;
`;

export const MultiSelectTagSelect = styled.div<{ bg?: string }>`
  ${selectTagCss};
  background-color: ${({ bg }) => bg};
`;

export const MultiSelectListWrapper = styled.div`
  ${dataInput};
  cursor: pointer;
`;

export const MultiSelectDetailedWrapper = styled.div`
  ${fieldDetailedWrapper};
  ${fieldDetailedHover};
  width: 100%;
`;

export const MultiSelectOptionsWrapper = styled.div<{ isDetailed?: boolean }>`
  display: flex;
  align-content: flex-start;
  flex-wrap: ${({ isDetailed }) => isDetailed ? "wrap" : "no-wrap"};
`;

export const MultiSelectFieldGroup = styled.div`
  ${customSelectsGroup};
`;

export const MultiSelectFieldSearch = styled.div`
  ${customSelectsSearch};
`;
