import { TaskInterface } from "../../types/tasksTypes";
import { FilterFunction } from "../filterFunctions";
import { FilterValueNone, FilterVersion, TAnyFilters } from "../filters";

const getCustomPropertyValue = (task: TaskInterface, currentFilter: TAnyFilters) => {
  const customProperty = task.propertyValues.find(value => value.title === currentFilter.titleKey);
  return (customProperty?.value || false);
};

const filterV1 = (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
  return tasks.filter(task => {
    return getCustomPropertyValue(task, currentFilter) === currentFilter.value.valueKey;
  });
};

const filterV2 = (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
  switch (currentFilter.function) {
    case FilterFunction.Is: {
      return tasks.filter(task => {
        return getCustomPropertyValue(task, currentFilter) === currentFilter.value.valueKey;
      });
    }

    case FilterFunction["Is not"]: {
      return tasks.filter(task => {
        return !getCustomPropertyValue(task, currentFilter) === currentFilter.value.valueKey;
      });
    }
  }

  return tasks;
};

export const booleanFilter = {
  filterValues: () => {
    return [
      {
        text: "True",
        valueKey: true
      },
      {
        text: "False",
        valueKey: false
      }
    ];
  },
  filterFn: (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
    const filterText = currentFilter.value.text;

    if (!filterText || filterText === FilterValueNone) {
      return tasks;
    }

    if (currentFilter.version && currentFilter.version !== FilterVersion.v1) {
      return filterV2(tasks, currentFilter);
    }

    return filterV1(tasks, currentFilter);
  }
};
