import { useAppSelector } from "app/store";
import { BoardProvider } from "lib/contexts/BoardContext";
import { useDocumentTitle } from "lib/customHooks/useDocumentTitle";
import { FC } from "react";
import Scrollbars from "react-custom-scrollbars-2";

import { PageContainer, PageContent } from "styles/common";
import BoardFields from "../BoardFields";
import DeleteBoard from "../DeleteBoard";
import BoardSettingsSync from "./BoardSettingsSync";

const BoardSettings: FC = () => {
  const board = useAppSelector(state => state.currentBoardReducer.board);

  const pageTitle = board?.name ? `${board?.name} - Settings` : undefined;
  useDocumentTitle(pageTitle);

  return (
    <BoardProvider board={board} >
      <Scrollbars autoHide>
        <PageContainer>
          <PageContent>
            <BoardFields />
            <BoardSettingsSync />
            <DeleteBoard />
          </PageContent>
        </PageContainer>
      </Scrollbars>
    </BoardProvider>
  );
};

export default BoardSettings;
