import { FC } from "react";
import { UnifiedToolbarColorBody, UnifiedToolbarColorContainer } from "components/blocks/UnifiedToolbar/styled";

export interface ForgeMarkupToolbarColorsProps {
  color?: string;
  active?: boolean;
}

const ForgeMarkupToolbarColors: FC<ForgeMarkupToolbarColorsProps> = (props) => {

  return (
    <UnifiedToolbarColorContainer active={props.active}>
      <UnifiedToolbarColorBody color={props.color} />
    </UnifiedToolbarColorContainer>
  );
};

export default ForgeMarkupToolbarColors;
