import { FC, useEffect, useState } from "react";
import { Form } from "antd";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { FormComponentProps } from "../lib";
import { TagButton } from "styles/common";
import SubtaskInput from "components/blocks/TaskDetailed/TaskSubtasks/SubtaskInput";
import { TaskSubtaskInterface } from "lib/types/tasksTypes";
import SubtaskItem from "components/blocks/TaskDetailed/TaskSubtasks/SubtaskItem";
import KeyCodes from "lib/types/keyCodes";

interface SubtaskFieldProps extends FormComponentProps {
  disabled?: boolean;
}

const SubtasksField: FC<SubtaskFieldProps> = (props) => {
  const { fieldName } = props;
  const form = Form.useFormInstance();
  const initialValues = (form.getFieldValue(fieldName) ?? []);

  const [subtasks, setSubtasks] = useState<Array<TaskSubtaskInterface>>(initialValues);
  const [isAddActive, setIsAddActive] = useState(false);

  useEffect(() => {
    form.setFieldValue(fieldName, subtasks);
  }, [fieldName, form, subtasks]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if ((!destination) || (result.destination?.index === result.source.index)) {
      return;
    }

    const newData = Array.from(subtasks);
    const [movedRow] = newData.splice(source.index, 1);
    newData.splice(destination.index, 0, movedRow);
    const nextState = newData.map((item, index) => ({ ...item, sort: index }));

    setSubtasks(nextState);
  };

  const handleAddInputOnKeydown = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if ((event.keyCode === KeyCodes.Enter && !event.shiftKey) || event.keyCode === KeyCodes.Tab) {
      // @ts-expect-error
      event.target.blur();
    }
    if (event.keyCode === KeyCodes.Escape) {
      event.stopPropagation();
      setIsAddActive(false);
    }
  };

  const handleAddInputOnBlur = async (event: React.FocusEvent<HTMLTextAreaElement>) => {
    const title = event.target.value.trim();
    if (title) {
      handleOnAdd(title);
    }
    setIsAddActive(false);
  };

  const handleOnAdd = (title: string) => {
    // @ts-expect-error
    const item: TaskSubtaskInterface = {
      _id: Date.now(),
      completed: false,
      title: title,
      sort: subtasks.length + 1,
    };

    const nextState = [...subtasks, item];
    setSubtasks(nextState);
  };

  const handleOnEdit = (item: TaskSubtaskInterface, data: Partial<TaskSubtaskInterface>) => {
    const subtaskIndex = subtasks.findIndex(s => String(s._id) === String(item._id));
    if (subtaskIndex === -1) {
      return;
    }

    const nextState = Array.from(subtasks);
    const subtask = subtasks[subtaskIndex];
    nextState[subtaskIndex] = {
      ...subtask,
      ...data,
    };
    setSubtasks(nextState);
  };

  const handleOnDelete = (item: TaskSubtaskInterface) => {
    const nextState = subtasks.filter(s => String(s._id) !== String(item._id));
    setSubtasks(nextState);
  };

  const handleAddInputOnPaste = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();

    const pasted = event.clipboardData.getData("text").trim();
    const subtaskTitles = pasted.split(/\r?\n/);

    // @ts-expect-error
    const payload: Array<TaskSubtaskInterface> = subtaskTitles.map(taskTitle => ({
      _id: Date.now(),
      completed: false,
      title: taskTitle,
      sort: subtasks.length + 1,
    }));

    const nextState = [...subtasks, ...payload];
    setSubtasks(nextState);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="task-subtasks-list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {subtasks?.map((subtask, index) => (
                <Draggable
                  draggableId={`draggableId-${subtask._id}`}
                  key={`draggableId-${subtask._id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <SubtaskItem
                        subtask={subtask}
                        onUpdate={handleOnEdit}
                        onDelete={handleOnDelete}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {!isAddActive &&
        <TagButton
          onClick={() => setIsAddActive(true)}
          style={{ margin: "7px 0 0 0" }}
        >
          Add checklist item
        </TagButton >
      }
      {!!isAddActive &&
        <SubtaskInput
          onKeyDown={handleAddInputOnKeydown}
          onBlur={handleAddInputOnBlur}
          onPaste={handleAddInputOnPaste}
        />
      }
    </>
  );
};

export default SubtasksField;
