import { FC, MouseEventHandler, useState } from "react";
import { Dropdown } from "antd";
import { getProjectModelTasksUri, getProjectUri } from "lib/helpers/navigationUtils";
import { ProjectContextInterface, useProjectContext } from "lib/contexts/ProjectContext";
import { BoardProvider } from "lib/contexts/BoardContext";
import { WorkspaceInterface } from "lib/types/types";
import { BoardInterfaceExtended } from "lib/types/boardTypes";
import ProjectContextMenu from "components/blocks/ProjectContextMenu";
import {
  SidebarLinkContainer, SidebarItemContentGrid, SidebarItemTitle,
  SidebarSettingsButton,
  SidebarNavigationLink,
} from "layout/SidebarCustom/styled";
import {
  SidebarToggleContainer,
  SidebarViewerButton,
  SidebarToggleIcon
} from "layout/SidebarCustom/SidebarMenus/SidebarProject/styled";
import SidebarBoard from "layout/SidebarCustom/SidebarMenus/SidebarProject/SidebarBoard";
import { useWorkspaceFeatures } from "lib/customHooks/useWorkspaceFeatures";

const OpenedContent: FC<{ workspace: WorkspaceInterface, isExpanded: boolean }> = (props) => {
  const { workspace, isExpanded } = props;
  const { isModelsEnabled } = useWorkspaceFeatures(workspace);

  if (isExpanded) {
    return (
      <>
        {workspace.boards?.map((board) => (
          <BoardProvider
            key={board.id}
            board={board as unknown as BoardInterfaceExtended}
          >
            <SidebarBoard />
          </BoardProvider>
        ))}
        {isModelsEnabled &&
          <SidebarViewerButton to={getProjectModelTasksUri(workspace.uid)}>
            Open viewer
          </SidebarViewerButton>
        }
      </>
    );
  } else {
    return (null);
  }
};

const SidebarProject: FC = () => {
  const { workspace } = useProjectContext() as ProjectContextInterface;

  const [isExpanded, setIsExpanded] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleOpen: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setIsExpanded(!isExpanded);
  };

  const toggleVisibility = () => setIsDropdownVisible(!isDropdownVisible);

  return (
    <>
      <SidebarLinkContainer isSelected={isDropdownVisible}>
        <SidebarNavigationLink to={getProjectUri(workspace.uid)} isSelected={isDropdownVisible}>
          <SidebarItemContentGrid>
            <SidebarToggleContainer onClick={toggleOpen}>
              <SidebarToggleIcon isOpen={isExpanded} src="/icons/dropdown.svg" alt="dropdown" />
            </SidebarToggleContainer>
            <SidebarItemTitle>{workspace.name}</SidebarItemTitle>
          </SidebarItemContentGrid>
        </SidebarNavigationLink>
        <Dropdown
          // @ts-ignore
          overlay={ProjectContextMenu({ workspace, toggleVisibility })}
          trigger={["click"]}
          onOpenChange={toggleVisibility}
          open={isDropdownVisible}
        >
          <SidebarSettingsButton>
            <img src="/icons/more.svg" alt="more" />
          </SidebarSettingsButton>
        </Dropdown>
      </SidebarLinkContainer>
      <OpenedContent
        workspace={workspace}
        isExpanded={isExpanded}
      />
    </>
  );
};

export default SidebarProject;
