import { groupBy } from "lodash";

import { NotificationEvent, NotificationInterface } from "lib/types/notificationTypes";
import { ContentType, getContentTypeDisplayName } from "lib/types/contentTypes";

export const NotificationGroupingSeparator = "-";

export const groupNotifications = (notifications: Array<NotificationInterface>) => {
  return groupBy(notifications, notification => `${notification.contentType}${NotificationGroupingSeparator}${notification.itemId}`);
};

export const RenderNotificationMessage = (eventName: NotificationEvent, contentType: ContentType | string) => {
  const renderedContentType = getContentTypeDisplayName(contentType);

  switch (eventName) {
    case NotificationEvent.Assign:
      return (
        "assigned to you"
      );
    case NotificationEvent.Comment:
      return (
        `commented on this ${renderedContentType}`
      );
    case NotificationEvent.Mention:
      return (
        `mentioned you on this ${renderedContentType}`
      );
    case NotificationEvent.Join:
      return (
        `joined the ${renderedContentType}`
      );
    case NotificationEvent.Complete:
      return (
        `marked this ${renderedContentType} complete`
      );
    default:
      return null;
  }
};
