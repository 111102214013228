import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const TransparentSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M49.9999 91.6666C73.0118 91.6666 91.6666 73.0118 91.6666 49.9999C91.6666 26.9881 73.0118 8.33325 49.9999 8.33325C26.9881 8.33325 8.33325 26.9881 8.33325 49.9999C8.33325 73.0118 26.9881 91.6666 49.9999 91.6666ZM49.9999 83.3332C68.4094 83.3332 83.3332 68.4094 83.3332 49.9999C83.3332 31.5904 68.4094 16.6666 49.9999 16.6666C31.5904 16.6666 16.6666 31.5904 16.6666 49.9999C16.6666 68.4094 31.5904 83.3332 49.9999 83.3332Z"/>
        <path d="M26.4911 26.3684C20.4229 32.4054 16.6666 40.764 16.6666 49.9999C16.6666 68.4094 31.5904 83.3332 49.9999 83.3332C59.2359 83.3332 67.5945 79.577 73.6314 73.5087L26.4911 26.3684Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default TransparentSvg;
