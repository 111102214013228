import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { Form } from "antd";

export const FormItem = styled(Form.Item)`
  margin: 0!important;
  
  ${({ hidden }) => hidden && css`
    visibility: hidden;
  `}

  .ant-form-item-label {
    padding-bottom: 5px;

    label {
      color: #999;
      font-size: 12px;
      font-weight: 600;
    }
  }
`;
