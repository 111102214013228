import styled from "@emotion/styled/macro";

export const ProcoreIntegrationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProcoreIntegrationLogoWrapper = styled.div`
  width: 70px;
  height: 70px;
  padding: 12px;
  border-radius: 12px;
  background-color: white;
  margin: 0 10px;
`;

export const ProcoreIntegrationLogo = styled.img`
  height: 100%;
  width: 100%;
`;
