import { xhr } from "lib/API/xhr";
import HttpService from "./HttpService";
import { ForgeSettingsInterface, ForgeSignedUrlInterface, MemberRoles, WorkspaceConnectionInterface, WorkspaceInterface } from "lib/types/types";
import { JSendResponse } from "lib/types/jsend";
import { EntityId } from "lib/types/entity";

class WorkspaceService extends HttpService {
  constructor() {
    super("workspaces");
  }

  async getAll() {
    const data: Array<WorkspaceInterface> = await this._get({});

    return data || [];
  }

  async add(name: string, description?: string) {
    const data: JSendResponse = await this._post({
      body: { name, description }
    });

    return data;
  }

  async addSampleProject() {
    const data: JSendResponse = await this._post({
      additionalUrl: "/sample"
    });

    return data;
  }

  async duplicate(id: EntityId, payload: Partial<WorkspaceInterface>) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/duplicate`,
      body: payload,
    });

    return data;
  }

  async updateWorkspace(id: EntityId, workspaceData: Partial<WorkspaceInterface>) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}`,
      body: workspaceData,
    });

    return data;
  }

  /** @deprecated */
  async addCollaborators(id: EntityId, collaborators: string[]) {
    const data: JSendResponse = await this._put({
      body: { collaborators },
      additionalUrl: `/${id}/collaborators`
    });

    return data;
  }

  /** @deprecated */
  async resendInvitation(id: EntityId, userId: EntityId) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/collaborators/${userId}/resend`
    });

    return data;
  }

  /** @deprecated */
  async deleteCollaborator(id: EntityId, userId: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}/collaborators/${userId}`
    });

    return data;
  }

  async membersAdd(id: EntityId, members: Array<EntityId>, role = MemberRoles.Editor) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/members`,
      body: {
        emails: members,
        role: role,
      }
    });

    return data;
  }

  async membersSetRole(id: EntityId, userId: EntityId, role: MemberRoles) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}/members/${userId}`,
      body: { role }
    });

    return data;
  }

  async membersDelete(id: EntityId, userId: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}/members/${userId}`,
    });

    return data;
  }

  async membersResendInvite(id: EntityId, userId: EntityId) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/members/${userId}/resend`,
    });

    return data;
  }

  async delete(id: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}`
    });

    return data;
  }

  async uploadModel(
    workspaceId: EntityId,
    file: any,
    fileId: EntityId,
    onProgress: (fileId: EntityId, event: ProgressEvent<EventTarget>) => void,
    onLoaded: (fileId: EntityId) => void) {

    const formData = new FormData();
    formData.append("file", file);

    const onProgressLocal = (event: ProgressEvent<EventTarget>) => {
      onProgress(fileId, event);
    };

    const onLoadedLocal = () => {
      onLoaded(fileId);
    };

    const response: any = await xhr(
      "POST",
      `${this.baseUrl}${this.url}/${workspaceId}/models`,
      formData,
      onProgressLocal || null,
      onLoadedLocal
    );

    const data: WorkspaceInterface | undefined = await JSON.parse(response);

    if (data) {
      return data;
    } else {
      return null;
    }
  }

  async replaceModel(workspaceId: EntityId, modelId: EntityId, file: File, signal?: AbortSignal) {
    const formData = new FormData();
    formData.append("file", file);

    const data: JSendResponse = await this._postFormData({
      method: "PUT",
      body: formData,
      additionalUrl: `/${workspaceId}/models/${modelId}`,
      signal,
    });

    return data;
  }

  async deleteModel(workspaceId: EntityId, modelId: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${workspaceId}/models/${modelId}`
    });

    return data;
  }

  async getModelLink(workspaceId: EntityId, modelId: EntityId) {
    const data: JSendResponse<ForgeSignedUrlInterface> = await this._get({
      additionalUrl: `/${workspaceId}/models/${modelId}/link`
    });

    return data;
  }

  async requestPlanInfo(workspaceId: EntityId, plan: EntityId) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${workspaceId}/request/plan`,
      body: { plan }
    });

    return data;
  }

  async connectionsUpdate(workspaceId: EntityId, connectionId: EntityId, connectionData: Partial<WorkspaceConnectionInterface>) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${workspaceId}/connections/${connectionId}`,
      body: connectionData
    });

    return data;
  }

  async connectionsDelete(workspaceId: EntityId, connectionId: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${workspaceId}/connections/${connectionId}`,
    });

    return data;
  }

  async connectionsAuthorize(workspaceId: EntityId, provider: string) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${workspaceId}/connections/authorize`,
      body: { provider }
    });

    return data;
  }

  async connectionsTest(workspaceId: EntityId, connectionId: EntityId) {
    const data: JSendResponse = await this._get({
      additionalUrl: `/${workspaceId}/connections/${connectionId}`,
    });

    return data;
  }

  async connectionsGetConfigData(workspaceId: EntityId, connectionId: EntityId) {
    const data: JSendResponse = await this._get({
      additionalUrl: `/${workspaceId}/connections/${connectionId}/configuration`,
    });

    return data;
  }

  async forgeSettingsUpdate(workspaceId: EntityId, payload: Partial<ForgeSettingsInterface>) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${workspaceId}/viewer/settings`,
      body: payload,
    });

    return data;
  }

  async forgeSetHomeView(workspaceId: EntityId, viewpoint: any, picture: Blob, signal?: AbortSignal) {
    const formData = new FormData();
    formData.append("viewpoint", JSON.stringify(viewpoint));
    formData.append("picture", picture);

    const data: JSendResponse = await this._postFormData({
      additionalUrl: `/${workspaceId}/viewer/viewpoint`,
      body: formData,
      signal,
    });

    return data;
  }
}

export default new WorkspaceService();
