import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const DuplicateSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M50 33.3333C40.7952 33.3333 33.3333 40.7952 33.3333 49.9999V83.3333C33.3333 92.5382 40.7952 99.9999 50 99.9999H83.3333C92.5383 99.9999 100 92.5382 100 83.3333V49.9999C100 40.7952 92.5383 33.3333 83.3333 33.3333H50ZM52.5 48.3333C50.1988 48.3333 48.3333 50.1988 48.3333 52.4999V80.8332C48.3333 83.1344 50.1988 84.9999 52.5 84.9999H80.8333C83.1345 84.9999 85 83.1344 85 80.8332V52.4999C85 50.1988 83.1345 48.3333 80.8333 48.3333H52.5Z"/>
        <path d="M16.6667 0C7.46193 0 0 7.46193 0 16.6667V50C0 59.2047 7.46193 66.6667 16.6667 66.6667H21.6667C25.8088 66.6667 29.1667 63.3088 29.1667 59.1667C29.1667 55.0245 25.8088 51.6667 21.6667 51.6667H19.1667C16.8655 51.6667 15 49.8012 15 47.5V19.1667C15 16.8655 16.8655 15 19.1667 15H47.5C49.8012 15 51.6667 16.8655 51.6667 19.1667V21.6667C51.6667 25.8088 55.0245 29.1667 59.1667 29.1667C63.3088 29.1667 66.6667 25.8088 66.6667 21.6667V16.6667C66.6667 7.46193 59.2047 0 50 0H16.6667Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default DuplicateSvg;
