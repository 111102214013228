import { FC, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Badge, Calendar } from "antd";
import { HeaderRender } from "antd/lib/calendar/generateCalendar";
import moment from "moment";

import { useBoardContext } from "lib/contexts/BoardContext";
import { useTasksContext } from "lib/contexts/TasksContext";
import { getTaskDetailBoardView } from "lib/helpers/navigationUtils";
import { TaskInterface } from "lib/types/tasksTypes";
import { TaskCalendarMaxCell } from "./lib";
import TaskCalendarCellModal from "./TaskCalendarCellModal";
import ButtonControls from "components/ui/ButtonControls";
import MenuLeftSvg from "components/icons/menu-left";
import MenuRightSvg from "components/icons/menu-right";
import {
  TaskCalendarHeader,
  TaskCalendarHeaderControls,
  TaskCalendarHeaderTitle,
} from "components/blocks/TaskCalendar/styled";
import "./style.css";
import { DateFormats } from "lib/theme/lib";
import TaskCalendarCell from "./TaskCalendarCell";

const TaskCalendar: FC = () => {
  const { tasks } = useTasksContext();
  const { board } = useBoardContext();
  const history = useHistory();
  const params = useParams<{ id: string, view: string }>();

  const [date, setDate] = useState(moment());

  const handleTaskOnClick = (taskUid: string) => {
    history.push(getTaskDetailBoardView(params.id, params.view, taskUid));
  };

  const getListData = (value: moment.Moment) => {
    const dueDate = value.format(DateFormats.ISO);
    return tasks
      .filter(task => (task?.dueDate as string)?.substring(0, 10) === dueDate)
      .map(task => {
        const statusColor = board?.statuses.find(status => String(status.id) === String(task?.status))?.color || "gray";

        return {
          ...task,
          badgeColor: statusColor
        };
      }) || [];
  };

  const dateCellRender = (value: moment.Moment) => {
    const listData = getListData(value);
    const isAllRendered = (listData.length <= TaskCalendarMaxCell);

    return (
      <ul className="events">
        {!isAllRendered &&
          <>
            {listData.slice(0, TaskCalendarMaxCell).map(item => (
              <TaskCalendarCell
                key={item?.id}
                task={item}
                color={item.badgeColor}
                onItemClick={() => handleTaskOnClick(item.uid)}
              />
            ))}
            <TaskCalendarCellModal
              cellTasks={listData as Array<TaskInterface>}
              date={value}
            >
              {listData.map(item => (
                <TaskCalendarCell
                  key={item?.id}
                  task={item}
                  color={item.badgeColor}
                  onItemClick={() => handleTaskOnClick(item.uid)}
                  additionalStyles={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                />
              ))}
            </TaskCalendarCellModal>
          </>
        }
        {isAllRendered &&
          <>
            {listData.map(item => (
              <TaskCalendarCell
                key={item?.id}
                task={item}
                color={item.badgeColor}
                onItemClick={() => handleTaskOnClick(item.uid)}
              />
            ))}
          </>
        }
      </ul>
    );
  };

  const headerRender: HeaderRender<moment.Moment> = (headerRenderProps) => {
    const newValue = date.clone();

    return (
      <TaskCalendarHeader>
        <TaskCalendarHeaderTitle>{date.format("MMMM YYYY")}</TaskCalendarHeaderTitle>
        <TaskCalendarHeaderControls>
          <ButtonControls onClick={() => setDate(newValue.add(-1, "months"))}
            icon={<MenuLeftSvg />}
          />
          <ButtonControls onClick={() => setDate(moment())}
            text="Today"
          />
          <ButtonControls onClick={() => setDate(newValue.add(1, "months"))}
            icon={<MenuRightSvg />}
          />
        </TaskCalendarHeaderControls>
      </TaskCalendarHeader>
    );
  };

  const handleCalendarOnChange = (dateValue: moment.Moment) => {
    setDate(dateValue);
  };

  return (
    <Calendar
      mode="month"
      dateCellRender={dateCellRender}
      headerRender={headerRender}
      onChange={handleCalendarOnChange}
      value={date}
    />
  );
};

export default TaskCalendar;
