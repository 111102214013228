import { FC, useState } from "react";
import { Dropdown } from "antd";
import BoardCardMenuItems from "components/blocks/BoardCardMenuItems";
import { getBoardUri } from "lib/helpers/navigationUtils";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import { SidebarItemContentGrid, SidebarNavigationLink, SidebarLinkContainer, SidebarSettingsButton } from "layout/SidebarCustom/styled";
import {
  SidebarBoardIcon,
  SidebarBoardTitle
} from "layout/SidebarCustom/SidebarMenus/SidebarProject/SidebarBoard/styled";

const SidebarBoard: FC = () => {
  const { board } = useBoardContext() as BoardContextInterface;
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleVisibility = () => setIsDropdownVisible(!isDropdownVisible);

  return (
    <SidebarLinkContainer isSelected={isDropdownVisible}>
      <SidebarNavigationLink to={getBoardUri(board.uid)} isSelected={isDropdownVisible}>
        <SidebarItemContentGrid>
          <SidebarBoardIcon src={board.icon ? `/images/${board.icon}.png` : "/images/brick.png"} alt="" />
          <SidebarBoardTitle>{board.name}</SidebarBoardTitle>
        </SidebarItemContentGrid>
      </SidebarNavigationLink>
      <Dropdown
        // @ts-ignore
        overlay={BoardCardMenuItems({ board, toggleVisibility })}
        trigger={["click"]}
        open={isDropdownVisible}
        onOpenChange={toggleVisibility}
      >
        <SidebarSettingsButton>
          <img src="/icons/more.svg" alt="more" />
        </SidebarSettingsButton>
      </Dropdown>
    </SidebarLinkContainer>
  );
};

export default SidebarBoard;
