import { FC } from "react";
import IconSvgWrapper from "./IconSvgWrapper";
import { IconProps } from "./lib";

const ListSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 2C0 1.44772 0.447715 1 1 1H11C11.5523 1 12 1.44772 12 2C12 2.55228 11.5523 3 11 3H1C0.447715 3 0 2.55228 0 2ZM0 10C0 9.44772 0.447715 9 1 9H11C11.5523 9 12 9.44772 12 10C12 10.5523 11.5523 11 11 11H1C0.447715 11 0 10.5523 0 10ZM1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H1Z" />
    </IconSvgWrapper>
  );
};

export default ListSvg;
