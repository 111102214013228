import { css } from "@emotion/css";
import styled from "@emotion/styled/macro";
import { customSelectsGroup, customSelectsWrapper } from "components/ui/CustomSelects/styled";

export const SecondaryBarContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 55px;
  padding: 0 75px;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #f2f2f2;
  margin: -1px;
  box-shadow: 0 2px 2px -2px rgba(109, 110, 111, 0.08);
  z-index: 1;
`;

export const ButtonWrapper = styled.div`
  margin: 0 10px;
  display: flex;
  color: #999;
  font-size: 13px;
  font-weight: 600;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  .ant-select-selection-overflow {
    flex-wrap: nowrap;
  }
  .ant-select-selector {
    overflow: hidden;
  }
  .ant-select-clear {
    background-color: transparent;
  }
`;

export const SortWrapper = styled(FilterWrapper)`
  flex-direction: row;
`;

export const activeButtonClassSort = css`
  background-color: #efebfd !important;
  color: #8966f6 !important;
  path {
    fill: #8966f6; !important;
  }
`;

export const activeButtonClassFilter = css`
  background-color: #fee8de !important;
  color: #fe6f2c !important;
  path {
    fill: #fe6f2c; !important;
  }
`;

export const activeHiddenTasksClass = css`
  background-color: #f6f6f6 !important;
`;

export const activeHiddenFieldsClass = css`
  background-color: #e9f5fc !important;
  color: #49a3e7 !important;
  path {
    fill: #49a3e7; !important;
  }
`;

export const singleFilterWrapper = css`
  display: flex;
  margin-bottom: 7px;
`;

export const SortSaveWrapper = styled.div`
  ${customSelectsWrapper};
`;

export const SortSaveGroup = styled.div`
  ${customSelectsGroup};
  .ant-select-item-option-content {
    font-weight: 400;
  }
`;

export const FilterOperatorText = styled.div`
  display: flex;
  align-items: center;
  width: 70px;
  margin-right: 7px;
  padding-left: 12px;
`;

export const FilterTooltip = styled.div`
  font-size: 13px;
  color: #999999;
  margin-bottom: 10px;
`;
