import { TaskPropertyOption } from "lib/types/kanbanTypes";
import { TaskInterface } from "lib/types/tasksTypes";
import { TableColumn } from "../types";

export enum ListReducerActionKind {
  setList = "set_list",
  addOptionToColumn = "add_option_to_column",
  addRow = "add_row",
  removeRow = "remove_row",
  setRows = "set_rows",
  updateColumnType = "update_column_type",
  updateColumnHeader = "update_column_header",
  updateCell = "update_cell",
  addColumnToLeft = "add_column_to_left",
  addColumnToRight = "add_column_to_right",
  deleteColumn = "delete_column",
  enableReset = "enable_reset",
}

export const DummyRowId = "dummyRow";

export interface ListReducerState {
  skipReset?: boolean;
  columns: Array<TableColumn>;
  data: Array<TaskInterface>;
}

export type ListReducerAction = ListReducerState & {
  type: ListReducerActionKind;
  columnId?: string;
  option?: string;
  backgroundColor?: string;
  newData?: Array<TaskInterface>;
  title?: string;
  rowIndex?: number;
  value?: any;
  payload?: any;
}

const reducer = (state: ListReducerState, action: ListReducerAction): ListReducerState => {
  const { type } = action;

  switch (type) {
    case ListReducerActionKind.setList:
      return {
        ...state,
        columns: action.columns,
        data: action.data,
      };

    case ListReducerActionKind.addOptionToColumn: {
      const optionIndex = state.columns.findIndex((column: any) => column.id === action.columnId);
      const newOption = { title: action.option, backgroundColor: action.backgroundColor } as unknown as TaskPropertyOption;

      return {
        ...state,
        skipReset: true,
        columns: [
          ...state.columns.slice(0, optionIndex),
          {
            ...state.columns[optionIndex],
            options: [
              ...state.columns[optionIndex].options,
              newOption,
            ]
          },
          ...state.columns.slice(optionIndex + 1, state.columns.length)
        ]
      };
    }

    case ListReducerActionKind.addRow: {
      const newRow = { id: DummyRowId } as TaskInterface;
      const findRow = state.data.find(item => item.id === DummyRowId);

      return {
        ...state,
        skipReset: true,
        data: [...state.data, ...(!findRow ? [newRow] : [])]
      };
    }

    case ListReducerActionKind.removeRow: {
      const nextState = state.data.filter(row => row.id !== DummyRowId);

      return {
        ...state,
        skipReset: true,
        data: nextState,
      };
    }

    case ListReducerActionKind.setRows: {
      return {
        ...state,
        skipReset: true,
        data: action.newData || [],
      };
    }

    // #region <ListReducerActionKind.updateColumnType>
    // case ListReducerActionKind.updateColumnType:
    //   const typeIndex = state.columns.findIndex((column: any) => column.id === action.payload.columnId);
    //   switch (action.payload.type) {
    //     case TableElementType.Number:
    //       if (state.columns[typeIndex].type === TableElementType.Number) {
    //         return state;
    //       } else {
    //         return {
    //           ...state,
    //           columns: [
    //             ...state.columns.slice(0, typeIndex),
    //             { ...state.columns[typeIndex], type: action.payload.type },
    //             ...state.columns.slice(typeIndex + 1, state.columns.length)
    //           ],
    //           data: state.data.map((row: any) => ({
    //             ...row,
    //             [action.payload.columnId]: isNaN(row[action.payload.columnId]) ? "" : Number.parseInt(row[action.payload.columnId])
    //           }))
    //         };
    //       }
    //     case TableElementType.SingleSelect:
    //       if (state.columns[typeIndex].type === TableElementType.SingleSelect) {
    //         return {
    //           ...state,
    //           columns: [
    //             ...state.columns.slice(0, typeIndex),
    //             { ...state.columns[typeIndex], type: action.payload.type },
    //             ...state.columns.slice(typeIndex + 1, state.columns.length)
    //           ],
    //           skipReset: true
    //         };
    //       } else {
    //         const options: any = [];
    //         state.data.forEach((row: any) => {
    //           if (row[action.payload.columnId]) {
    //             options.push({ title: row[action.payload.columnId], backgroundColor: randomColor() });
    //           }
    //         });
    //         return {
    //           ...state,
    //           columns: [
    //             ...state.columns.slice(0, typeIndex),
    //             {
    //               ...state.columns[typeIndex],
    //               type: action.payload.type,
    //               options: [...state.columns[typeIndex].options, ...options]
    //             },
    //             ...state.columns.slice(typeIndex + 1, state.columns.length)
    //           ],
    //           skipReset: true
    //         };
    //       }
    //     case TableElementType.SingleLineText:
    //       if (state.columns[typeIndex].type === TableElementType.SingleLineText) {
    //         return state;
    //       } else if (state.columns[typeIndex].type === TableElementType.SingleSelect) {
    //         return {
    //           ...state,
    //           skipReset: true,
    //           columns: [
    //             ...state.columns.slice(0, typeIndex),
    //             { ...state.columns[typeIndex], type: action.payload.type },
    //             ...state.columns.slice(typeIndex + 1, state.columns.length)
    //           ]
    //         };
    //       } else {
    //         return {
    //           ...state,
    //           skipReset: true,
    //           columns: [
    //             ...state.columns.slice(0, typeIndex),
    //             { ...state.columns[typeIndex], type: action.payload.type },
    //             ...state.columns.slice(typeIndex + 1, state.columns.length)
    //           ],
    //           data: state.data.map((row: any) => ({ ...row, [action.payload.columnId]: row[action.payload.columnId] + "" }))
    //         };
    //       }
    //     default:
    //       return state;
    //   }
    // #endregion

    case ListReducerActionKind.updateColumnHeader: {
      const index = state.columns.findIndex((column: any) => column.id === action.columnId);
      const updateColumn = { ...state.columns[index], title: action.title } as TableColumn;

      return {
        ...state,
        skipReset: true,
        columns: [
          ...state.columns.slice(0, index),
          updateColumn,
          ...state.columns.slice(index + 1, state.columns.length)
        ]
      };
    }

    case ListReducerActionKind.updateCell: {
      return {
        ...state,
        skipReset: true,
        data: state.data.map((row: any, index: any) => {
          if (action.rowIndex && action.columnId && (index === action.rowIndex)) {
            return {
              ...state.data[action.rowIndex],
              [action.columnId]: action.value
            };
          }
          return row;
        })
      };
    }

    // #region <ListReducerActionKind.addColumnToLeft> 
    // case ListReducerActionKind.addColumnToLeft:
    //   const leftIndex = state.columns.findIndex((column: any) => column.id === action.columnId);
    //   const leftId = shortId();
    //   return {
    //     ...state,
    //     skipReset: true,
    //     columns: [
    //       ...state.columns.slice(0, leftIndex),
    //       {
    //         id: leftId,
    //         title: "Column",
    //         accessor: leftId,
    //         type: TableElementType.SingleLineText,
    //         created: action.focus && true,
    //         options: []
    //       },
    //       ...state.columns.slice(leftIndex, state.columns.length)
    //     ]
    //   };
    // case ListReducerActionKind.addColumnToRight:
    //   const rightIndex = state.columns.findIndex((column: any) => column.id === action.columnId);
    //   const rightId = shortId();
    //   return {
    //     ...state,
    //     skipReset: true,
    //     columns: [
    //       ...state.columns.slice(0, rightIndex + 1),
    //       {
    //         id: rightId,
    //         title: "Column",
    //         accessor: rightId,
    //         type: TableElementType.SingleLineText,
    //         created: action.focus && true,
    //         options: []
    //       },
    //       ...state.columns.slice(rightIndex + 1, state.columns.length)
    //     ]
    //   };
    //  #endregion

    case ListReducerActionKind.deleteColumn: {
      const deleteIndex = state.columns.findIndex((column: any) => column.id === action.columnId);

      return {
        ...state,
        skipReset: true,
        columns: [...state.columns.slice(0, deleteIndex), ...state.columns.slice(deleteIndex + 1, state.columns.length)]
      };
    }

    case ListReducerActionKind.enableReset: {
      return {
        ...state,
        skipReset: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
