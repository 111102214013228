import { groupBy } from "lodash";
import { DefaultOptionType } from "antd/lib/select";
import { BoardUnit } from "lib/types/boardTypes";

interface BoardUnitsItem {
  code: BoardUnit;
  single: string;
  plural: string;
  tag: "Popular" | "Other";
}

export const DefaultBoardUnit: BoardUnitsItem = { code: BoardUnit.Record, single: "Record", plural: "Records", tag: "Popular" };

export const BoardUnits: BoardUnitsItem[] = [
  { code: BoardUnit.Asset, single: "Asset", plural: "Assets", tag: "Popular" },
  { code: BoardUnit.Delivery, single: "Delivery", plural: "Deliveries", tag: "Other" },
  { code: BoardUnit.Document, single: "Document", plural: "Documents", tag: "Other" },
  { code: BoardUnit.Drawing, single: "Drawing", plural: "Drawings", tag: "Other" },
  { code: BoardUnit.Entry, single: "Entry", plural: "Entries", tag: "Other" },
  { code: BoardUnit.Equipment, single: "Equipment", plural: "Equipments", tag: "Other" },
  { code: BoardUnit.File, single: "File", plural: "Files", tag: "Other" },
  { code: BoardUnit.Issue, single: "Issue", plural: "Issues", tag: "Popular" },
  { code: BoardUnit.Item, single: "Item", plural: "Items", tag: "Other" },
  { code: BoardUnit.Order, single: "Order", plural: "Orders", tag: "Other" },
  { code: BoardUnit.Part, single: "Part", plural: "Parts", tag: "Other" },
  { code: BoardUnit.Plan, single: "Plan", plural: "Plans", tag: "Other" },
  DefaultBoardUnit,
  { code: BoardUnit.Request, single: "Request", plural: "Requests", tag: "Other" },
  { code: BoardUnit.Submission, single: "Submission", plural: "Submissions", tag: "Other" },
  { code: BoardUnit.Task, single: "Task", plural: "Tasks", tag: "Popular" },
  { code: BoardUnit.Teammate, single: "Teammate", plural: "Teammates", tag: "Other" },
  { code: BoardUnit.Ticket, single: "Ticket", plural: "Tickets", tag: "Other" },
  { code: BoardUnit.Vendor, single: "Vendor", plural: "Vendors", tag: "Other" },
  { code: BoardUnit.Zone, single: "Zone", plural: "Zones", tag: "Other" },
];

const groupedItems = groupBy(BoardUnits, "tag");

export const BoardUnitsSelectOptions: DefaultOptionType[] = Object.keys(groupedItems)
  .map(key => ({
    label: key,
    options: groupedItems[key].map(option => ({
      label: option.plural,
      value: option.code,
    }))
  }));
