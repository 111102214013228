import styled from "@emotion/styled/macro";
import { squareWrapperCss } from "styles/common-css";
import { dataInput } from "components/blocks/TaskTable/styled";
import { fieldDetailedInput } from "../../styled";

export const CurrencyPostfix = styled.div`
  ${squareWrapperCss};
  min-width: 24px;
  width: auto;
  margin-left: 5px;
  opacity: 0;
  background-color: white;
  border: solid 1px #e4e4e4;
  cursor: auto;
  padding: 0 4px;
  &:hover {
    background-color: white;
  }
`;

export const CurrencyListWrapper = styled.div`
  ${dataInput};
  &:hover {
    ${CurrencyPostfix} {
      opacity: 1;
    }
  }
`;

export const CurrencyDetailedWrapper = styled.div`
  ${fieldDetailedInput};
  display: flex;
  align-items: center;
  &:hover {
    ${CurrencyPostfix} {
      opacity: 1;
    }
  }
`;
