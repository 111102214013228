import { FC, useEffect, useRef, useState } from "react";
import { Tooltip, Image, Spin } from "antd";
import UploadService from "services/UploadService";
import { FileInterface, MemberRoles } from "lib/types/types";
import { PictureMimeTypes } from "lib/helpers/uploadHelper";
import { AddViewpoint } from "pages/Viewer/ViewerTasks/TaskItem/styled";
import { FieldEmptyState } from "../../styled";
import ConditionalWrapper from "components/blocks/ConditionalWrapper";
import { TaskFieldSetterGetter } from "lib/types/applicationTypes";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import {
  FilesGenericRelativeContainer,
  FilesGenericViewpointWrapper
} from "components/blocks/TaskFields/Components/Files/Display/styled";
import CircleDeleteButton from "components/blocks/CircleDeleteButton";
import { captureException } from "lib/utils/sentry";
import { ViewpointLikeCardContentContainer } from "components/blocks/ViewpointLikeCard/styled";

interface FilesGenericProps extends TaskFieldSetterGetter {
  value: Array<FileInterface> | null;
}

const FilesGeneric: FC<FilesGenericProps> = (props) => {
  const { code, value, setValue } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [currentValue, setCurrentValue] = useState<Array<FileInterface>>(value || []);

  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);
  const fileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCurrentValue(value || []);
  }, [value]);

  const renderFile = (file: FileInterface) => {
    if (PictureMimeTypes.includes(file.mime)) {
      return (<Image src={file.url} />);
    }

    return (
      <Tooltip title={file.name}>
        <a href={file.url} target="_blank" rel="noopener noreferrer">
          <ViewpointLikeCardContentContainer>
            <img width="14px" alt="" src={process.env.PUBLIC_URL + "/icons/attachment.svg"} />
          </ViewpointLikeCardContentContainer>
        </a>
      </Tooltip>
    );
  };

  const handleOnFileRemove = async (file: FileInterface) => {
    setIsLoading(true);
    const nextState = currentValue?.filter(f => f.id !== file.id);
    try {
      setCurrentValue(nextState);
      await setValue({ cellName: code, cellValue: nextState });
    } catch (error) {
      captureException(error);
    }
    setIsLoading(false);
  };

  const handleFileInputOnChange = async (draggedFiles: FileList | null) => {
    setIsLoading(true);
    if (draggedFiles) {
      const payload = Array.from(draggedFiles);
      try {
        const addFiles = await UploadService.upload(payload);
        const nextState = [...(currentValue || []), ...addFiles];
        setCurrentValue(nextState);
        await setValue({ cellName: code, cellValue: nextState });
      } catch (error) {
        captureException(error);
      }
    }
    setIsLoading(false);
  };

  const handleOnFileInputClick = () => {
    fileInput?.current?.click();
  };

  const wrapperFn = (children: JSX.Element) => (
    <FieldEmptyState>
      {children}
    </FieldEmptyState>
  );

  return (
    <>
      <Spin spinning={isLoading}>
        <Image.PreviewGroup>
          {currentValue?.map(value => (
            <FilesGenericRelativeContainer key={value.id}>
              <FilesGenericViewpointWrapper>
                {renderFile(value)}
                {allowedToEdit &&
                  <CircleDeleteButton onClick={() => handleOnFileRemove(value)} />
                }
              </FilesGenericViewpointWrapper>
            </FilesGenericRelativeContainer>
          ))}
        </Image.PreviewGroup>
        <ConditionalWrapper condition={!value?.length} wrapper={wrapperFn}>
          <>
            {allowedToEdit &&
              <Tooltip title="Add attachment">
                <AddViewpoint cardSize={36} onClick={handleOnFileInputClick}>
                  <img src={process.env.PUBLIC_URL + "/icons/add.svg"} alt="" />
                  <input
                    multiple
                    type="file"
                    ref={fileInput}
                    style={{ display: "none" }}
                    onChange={e => handleFileInputOnChange(e?.target?.files)}
                  />
                </AddViewpoint>
              </Tooltip>
            }
          </>
        </ConditionalWrapper>
      </Spin>
    </>
  );
};

export default FilesGeneric;
