import { FC } from "react";

import { DefaultColumnCodes } from "lib/types/boardTypes";
import { DisplayView, TaskSourceComponent } from "lib/types/applicationTypes";
import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import UsersField from "components/blocks/TaskFields/Components/Users";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";

interface AssigneesProps {
  kanbanContext: Record<string, any>;
}

const Assignees: FC<AssigneesProps> = (props) => {
  const { task, setValue } = useTaskContext() as TaskContextInterface;

  return (
    <UsersField
      value={task.assignees}
      setValue={setValue}
      code={DefaultColumnCodes.Assignees}
      displayView={DisplayView.Kanban}
      component={TaskSourceComponent.Card}
      propertyType={TaskPropertyClass.Default}
    />
  );
};

export default Assignees;
