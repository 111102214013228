import socket from "lib/API/socket";
import configuration from "lib/configs/configuration";
import { getTokenFromStorage, removeTokenFromStorage } from "lib/helpers/helpers";

interface PostFormDataProps {
  body: FormData;
  additionalUrl?: string;
  isUseToken?: boolean;
  signal?: AbortSignal;
  method?: "POST" | "PUT" | "PATCH";
}

interface PostDataProps {
  body?: Record<any, any>;
  additionalUrl?: string;
  isUseToken?: boolean;
  signal?: AbortSignal;
}

export default class HttpService {
  baseUrl: string;
  url: string;
  token: string;
  socketId: string | null;

  constructor(url: string) {
    this.baseUrl = configuration.backend.url || "";
    this.url = url;
    this.token = getTokenFromStorage();
    this.socketId = socket.getSocketId();
  }

  private getTokenFromStorage() {
    if (!this.token) {
      this.token = getTokenFromStorage();
    }
  }

  private getSocketId() {
    this.socketId = socket.getSocketId();
  }

  private handleError = (json: any) => {
    if (json.error === "Unauthorized") {
      removeTokenFromStorage();
      document.location.reload();
    }
    throw json;
  };

  private getCredentials = () => {
    this.getTokenFromStorage();
    this.getSocketId();
  };

  private setHeaders = (isUseToken: boolean, emptyHeaders?: boolean) => {
    const headers: Record<string, any> = {};
    if (!emptyHeaders) {
      headers["Content-type"] = "application/json";
      headers["Connection"] = "keep-alive";
    }

    if (this.token && isUseToken) {
      headers.Authorization = `Bearer ${this.token}`;
      headers["X-Socket-Id"] = this.socketId;
    }

    return headers;
  };

  protected async _get({ isUseToken = true, additionalUrl = "" }): Promise<any> {
    this.getCredentials();

    const headers = this.setHeaders(isUseToken);

    try {
      const response = await fetch(this.baseUrl + this.url + additionalUrl, {
        method: "GET",
        headers,
        redirect: "follow",
      });

      const data = await response.json();

      if (response.ok) {
        return data;
      } else {
        this.handleError(data);
      }

    } catch (e) {
      console.warn(e);
      throw e;
    }

    return {};
  }

  protected async _post({ body = {}, additionalUrl = "", isUseToken = true, signal }: PostDataProps): Promise<any> {
    this.getCredentials();

    const headers = this.setHeaders(isUseToken);

    try {
      const response = await fetch(this.baseUrl + this.url + additionalUrl, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
        signal
      });

      const data = await response.json();

      if (response.ok) {
        return data;
      } else {
        this.handleError(data);
      }
    } catch (e) {
      console.warn(e);

      // описать тип ошибки TODO
      throw e;
    }

    return {};
  }

  protected async _postFormData({ body, additionalUrl = "", isUseToken = true, signal, method = "POST" }: PostFormDataProps): Promise<any> {
    this.getCredentials();

    const headers = this.setHeaders(isUseToken, true);

    try {
      const response = await fetch(this.baseUrl + this.url + additionalUrl, {
        method: method,
        headers,
        body: body,
        signal
      });

      const data = await response.json();

      if (response.ok) {
        return data;
      } else {
        this.handleError(data);
      }
    } catch (e) {
      console.warn(e);
      throw e;
    }

    return {};
  }

  protected async _put({ body = {}, additionalUrl = "", isUseToken = true }): Promise<any> {
    this.getCredentials();

    const headers = this.setHeaders(isUseToken);

    try {
      const response = await fetch(this.baseUrl + this.url + additionalUrl, {
        method: "PUT",
        headers,
        body: JSON.stringify(body)
      });

      const data = await response.json();

      if (response.ok) {
        return data;
      } else {
        this.handleError(data);
      }
    } catch (e) {
      console.warn(e);
      throw e;
    }

    return {};
  }

  protected async _patch({ body = {}, additionalUrl = "", isUseToken = true }): Promise<any> {
    this.getCredentials();

    const headers = this.setHeaders(isUseToken);

    try {
      const response = await fetch(this.baseUrl + this.url + additionalUrl, {
        method: "PATCH",
        headers,
        body: JSON.stringify(body)
      });

      const data = await response.json();

      if (response.ok) {
        return data;
      } else {
        this.handleError(data);
      }
    } catch (e) {
      console.warn(e);
      throw e;
    }

    return {};
  }

  protected async _delete({ isUseToken = true, additionalUrl = "" }): Promise<any> {
    this.getCredentials();

    const headers = this.setHeaders(isUseToken, true);

    try {
      const response = await fetch(this.baseUrl + this.url + additionalUrl, {
        method: "DELETE",
        headers,
        redirect: "follow",
      });

      const data = await response.json();

      if (response.ok) {
        return data;
      } else {
        this.handleError(data);
      }

    } catch (e) {
      console.warn(e);
      throw e;
    }

    return {};
  }
}
