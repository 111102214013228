import styled from "@emotion/styled/macro";

export const BoardLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BoardLabelIcon = styled.img`
  width: 16px; 
  height: 16px;
  margin-right: 7px;
`;

export const BoardLabelTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
