import { FC, memo, ReactNode } from "react";
import { Collapse } from "antd";
import { isEqual } from "lodash";
import TaskDetailedProperty from "components/blocks/TaskDetailed/TaskDetailedProperty";
import { DisplayView, TaskSourceComponent } from "lib/types/applicationTypes";
import { PropertyValueType } from "lib/types/dataTypes";
import { TaskPropertyValue } from "lib/types/kanbanTypes";
import { CollapseWrapper } from "pages/Board/BoardKanban/Card/CardDetails/styled";
import { TaskDetailedHeading } from "../styled";

interface TaskDetailedPropertiesProps {
  visibleTaskProperties: Array<TaskPropertyValue>;
  hiddenTaskProperties: Array<TaskPropertyValue>;
  displayView: DisplayView;
  customization?: Partial<Record<PropertyValueType, ReactNode>>
}

const TaskDetailedProperties: FC<TaskDetailedPropertiesProps> = (props) => {
  const { visibleTaskProperties, hiddenTaskProperties } = props;

  return (
    <>
      {/* Visible Properties */}
      <TaskDetailedHeading>
        Fields
      </TaskDetailedHeading>
      {visibleTaskProperties.map(propertyValue => (
        <TaskDetailedProperty
          key={propertyValue.id}
          propertyValue={propertyValue}
          displayView={props.displayView}
          component={TaskSourceComponent.Detailed}
          customization={props.customization}
        />
      ))}

      {/* Hidden Properties */}
      {(hiddenTaskProperties.length > 0) &&
        <CollapseWrapper>
          <Collapse ghost>
            <Collapse.Panel header="Hidden fields" key="task-detailed-hidden-fields">
              {hiddenTaskProperties.map(propertyValue => (
                <TaskDetailedProperty
                  key={propertyValue.id}
                  propertyValue={propertyValue}
                  displayView={props.displayView}
                  component={TaskSourceComponent.Detailed}
                  customization={props.customization}
                />
              ))}
            </Collapse.Panel>
          </Collapse>
        </CollapseWrapper>
      }

      {/* Add field button */}
      {/* <ButtonIcon text="Add a field" /> */}
    </>
  );
};

const customEqual = (prevProps: TaskDetailedPropertiesProps, nextProps: TaskDetailedPropertiesProps) => {
  return isEqual(prevProps.hiddenTaskProperties, nextProps.hiddenTaskProperties)
    && isEqual(prevProps.visibleTaskProperties, nextProps.visibleTaskProperties);
};

export default memo(TaskDetailedProperties, customEqual);
