import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const PdfSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.4896 20.4357C64.2212 20.4335 62.0461 19.5313 60.4419 17.9272C58.8378 16.3231 57.9357 14.1481 57.9336 11.8796V0H19.7013C16.7398 0 13.8998 1.17644 11.8057 3.27047C9.7117 5.36448 8.53528 8.20452 8.53528 11.1659V78.4772C8.53968 81.4357 9.71803 84.2714 11.8116 86.3618C13.9051 88.4523 16.7427 89.6266 19.7013 89.6266H65.5975C68.556 89.6266 71.3938 88.4523 73.4871 86.3618C75.5809 84.2714 76.7589 81.4357 76.7635 78.4772V20.4357H66.4896Z" fill="#FF3E4C"/>
        <path d="M76.7635 20.4357H66.4896C64.2211 20.4335 62.046 19.5313 60.4419 17.9272C58.8377 16.3231 57.9357 14.1481 57.9336 11.8796V0L76.7635 20.4357Z" fill="#FF3E4C"/>
        <path d="M62.7261 62.5892H19.8879C19.4752 62.5892 19.0795 62.4253 18.7876 62.1336C18.4959 61.8415 18.3319 61.446 18.3319 61.0332C18.333 60.6212 18.4974 60.2266 18.7891 59.9357C19.0808 59.6448 19.476 59.4813 19.8879 59.4813H62.7261C63.1377 59.4813 63.5323 59.6448 63.8236 59.9357C64.1145 60.227 64.278 60.6216 64.278 61.0332C64.2784 61.2373 64.239 61.4398 64.161 61.6282C64.0834 61.817 63.9693 61.9888 63.8249 62.1332C63.6809 62.2776 63.5095 62.3925 63.3211 62.4705C63.1323 62.549 62.9302 62.5892 62.7261 62.5892Z" fill="#FF3E4C"/>
        <path d="M62.7261 45.0622H19.8879C19.476 45.0622 19.0808 44.8987 18.7891 44.6079C18.4974 44.317 18.333 43.9224 18.3319 43.5104C18.3319 43.0979 18.4959 42.7021 18.7876 42.4099C19.0795 42.1182 19.4752 41.9543 19.8879 41.9543H62.7261C62.9302 41.9543 63.1323 41.9946 63.3211 42.073C63.5095 42.151 63.6809 42.266 63.8249 42.4104C63.9693 42.5548 64.0834 42.7265 64.161 42.9153C64.239 43.1041 64.2784 43.3062 64.278 43.5104C64.278 43.922 64.1145 44.3166 63.8236 44.6079C63.5323 44.8987 63.1377 45.0622 62.7261 45.0622Z" fill="#FF3E4C"/>
        <path d="M62.7261 53.8258H19.8879C19.4752 53.8258 19.0795 53.6619 18.7876 53.3702C18.4959 53.0781 18.3319 52.6826 18.3319 52.2698C18.333 51.8577 18.4974 51.4631 18.7891 51.1722C19.0808 50.8814 19.476 50.7179 19.8879 50.7179H62.7261C63.1377 50.7179 63.5323 50.8814 63.8236 51.1722C64.1145 51.4635 64.278 51.8581 64.278 52.2698C64.2784 52.4739 64.239 52.676 64.161 52.8648C64.0834 53.0536 63.9693 53.2254 63.8249 53.3698C63.6809 53.5142 63.5095 53.6291 63.3211 53.7071C63.1323 53.7855 62.9302 53.8258 62.7261 53.8258Z" fill="#FF3E4C"/>
        <path d="M45.8298 36.2988H19.8879C19.476 36.2988 19.0808 36.1355 18.7891 35.8446C18.4974 35.5537 18.333 35.1589 18.3319 34.7469C18.3319 34.3342 18.4959 33.9385 18.7876 33.6467C19.0795 33.3549 19.4752 33.1909 19.8879 33.1909H45.8298C46.2418 33.192 46.6365 33.3564 46.9274 33.6481C47.2183 33.9398 47.3817 34.335 47.3817 34.7469C47.3806 35.1582 47.2168 35.5522 46.926 35.843C46.6352 36.1338 46.2411 36.2977 45.8298 36.2988Z" fill="#FF3E4C"/>
        <path d="M45.8298 27.5353H19.8879C19.476 27.5353 19.0808 27.3718 18.7891 27.0809C18.4974 26.79 18.333 26.3953 18.3319 25.9834C18.3319 25.5707 18.4959 25.1748 18.7876 24.8831C19.0795 24.5912 19.4752 24.4274 19.8879 24.4274H45.8298C46.2418 24.4285 46.6365 24.5928 46.9274 24.8845C47.2183 25.1762 47.3817 25.5714 47.3817 25.9834C47.3806 26.3946 47.2168 26.7887 46.926 27.0795C46.6352 27.3703 46.2411 27.5342 45.8298 27.5353Z" fill="#FF3E4C"/>
        <path d="M84.6888 70.9543H32.805C29.1246 70.9543 26.1411 73.9377 26.1411 77.6182V93.0871C26.1411 96.7676 29.1246 99.751 32.805 99.751H84.6888C88.3693 99.751 91.3527 96.7676 91.3527 93.0871V77.6182C91.3527 73.9377 88.3693 70.9543 84.6888 70.9543Z" fill="#FF3E4C"/>
        <path d="M41.8091 93.361C41.2166 93.361 40.9203 93.0648 40.9203 92.4722V79.9021C40.9203 79.3096 41.2166 79.0133 41.8091 79.0133H46.761C48.0518 79.0133 49.0394 79.359 49.7236 80.0502C50.4149 80.7345 50.7605 81.722 50.7605 83.0129V83.6477C50.7605 84.9386 50.4149 85.9295 49.7236 86.6208C49.0394 87.305 48.0518 87.6473 46.761 87.6473H42.6979V92.4722C42.6979 93.0648 42.4016 93.361 41.8091 93.361ZM42.6979 85.8697H46.761C47.5651 85.8697 48.1365 85.7004 48.4751 85.3619C48.8136 85.0233 48.9829 84.4519 48.9829 83.6477V83.0129C48.9829 82.2158 48.8136 81.6477 48.4751 81.3092C48.1365 80.9635 47.5651 80.7909 46.761 80.7909H42.6979V85.8697Z" fill="white"/>
        <path d="M54.7871 93.361C54.1946 93.361 53.8983 93.0648 53.8983 92.4722V79.9021C53.8983 79.3096 54.1946 79.0133 54.7871 79.0133H59.8871C61.7282 79.0133 63.0825 79.4507 63.9502 80.3253C64.8249 81.193 65.2622 82.5473 65.2622 84.3884V87.9859C65.2622 89.827 64.8286 91.1847 63.961 92.0594C63.1004 92.927 61.7423 93.361 59.8871 93.361H54.7871ZM55.6759 91.5834H59.8871C60.79 91.5834 61.5025 91.4739 62.0245 91.2552C62.5535 91.0295 62.9274 90.6556 63.146 90.1336C63.3718 89.6046 63.4846 88.8888 63.4846 87.9859V84.3884C63.4846 83.4926 63.3718 82.7834 63.146 82.2614C62.9274 81.7324 62.5535 81.3552 62.0245 81.1295C61.5025 80.9038 60.79 80.7909 59.8871 80.7909H55.6759V91.5834Z" fill="white"/>
        <path d="M70.0386 93.361C69.446 93.361 69.1498 93.0648 69.1498 92.4722V79.9021C69.1498 79.3096 69.446 79.0133 70.0386 79.0133H77.8261C78.1012 79.0133 78.3166 79.0942 78.4718 79.2565C78.634 79.4116 78.7149 79.627 78.7149 79.9021C78.7149 80.4946 78.4187 80.7909 77.8261 80.7909H70.9274V85.2349H75.1809C75.7734 85.2349 76.0697 85.5311 76.0697 86.1237C76.0697 86.7162 75.7734 87.0125 75.1809 87.0125H70.9274V92.4722C70.9274 93.0648 70.6311 93.361 70.0386 93.361Z" fill="white"/>
        <path d="M61.9668 24.4274H54.6307C53.3518 24.4274 52.3153 25.464 52.3153 26.7427V34.0788C52.3153 35.3575 53.3518 36.3942 54.6307 36.3942H61.9668C63.2456 36.3942 64.2821 35.3575 64.2821 34.0788V26.7427C64.2821 25.464 63.2456 24.4274 61.9668 24.4274Z" fill="#FF3E4C"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default PdfSvg;
