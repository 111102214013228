import styled from "@emotion/styled/macro";
import { COLOR_DEFAULT_GREY } from "lib/configs/colors";

export const SelectFieldStatusOption = styled.div<{ color?: string }>`
  width: 12px;
  height: 12px;
  margin-right: 7px;
  background: ${({ color }) => color || COLOR_DEFAULT_GREY};
  border-radius: 50%
`;
