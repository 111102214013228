import { cloneDeep, isEmpty } from "lodash";
import { BoardDisplayView, BoardFeaturesToggled, DefaultColumnCodes } from "lib/types/boardTypes";
import { EntityId } from "lib/types/entity";
import { BoardDefaultDisplayView } from "pages/Board/BoardSettings/BoardSettingsDisplayView/lib";
import BoardService from "services/BoardService";
import useBoardPerformRequest, { BoardPerformRequestHookProps } from "./useBoardPerformRequest";
import { BoardFeatureCodes, DisplayView } from "lib/types/applicationTypes";

const useBoardSetDisplayViews = (props: BoardPerformRequestHookProps) => {
  const { board } = props;
  const performRequest = useBoardPerformRequest(props);

  const setBoardDisplayView = (id: EntityId, payload: Partial<BoardDisplayView>) => {
    if (!board) {
      return;
    }

    const { columns, features } = cloneDeep(board);
    const displayView = (!isEmpty(board?.displayView)) ? cloneDeep(board.displayView) : BoardDefaultDisplayView;
    const displayViewCodes = Object.keys(payload);

    function setColumnState(columnCode: string, columnState: boolean) {
      const findIndex = columns.findIndex(column => column.code === columnCode);
      if (findIndex !== -1) {
        columns[findIndex].active = columnState;
      }
    }

    function setFeatureState(featureCode: keyof BoardFeaturesToggled, featureState: boolean) {
      features![featureCode] = featureState;
    }

    displayViewCodes.forEach(displayViewCode => {
      const state = !!(payload[displayViewCode as never]);

      switch (displayViewCode) {
        case DisplayView.Kanban: {
          displayView.kanban = state;
          if (state === true) {
            setFeatureState(BoardFeatureCodes.Statuses, state);
            setColumnState(DefaultColumnCodes.Status, state);
          }
          break;
        }

        case DisplayView.Calendar: {
          displayView.calendar = state;
          if (state === true) {
            setFeatureState(BoardFeatureCodes.DueDates, state);
            setColumnState(DefaultColumnCodes.DueDate, state);
          }
          break;
        }

        case DisplayView.Model: {
          displayView.model = state;
          if (state === true) {
            setFeatureState(BoardFeatureCodes.Models, state);
            setColumnState(DefaultColumnCodes.Viewpoints, state);
          }
          break;
        }

        case DisplayView.Form: {
          displayView.form = state;
          break;
        }

        case DisplayView.Files: {
          displayView.files = state;
          break;
        }

        case DisplayView.List:
        default:
          break;
      }
    });

    const requestFn = () => BoardService.displayViewUpdate(id, payload);
    return performRequest({ columns, features, displayView }, requestFn);
  };

  return { setBoardDisplayView };
};

export default useBoardSetDisplayViews;
