import { InputNumberStyled } from "components/blocks/TaskTable/components/TaskTitle/styled";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { TaskFieldSetterGetter } from "lib/types/applicationTypes";
import { MemberRoles } from "lib/types/types";
import { FC, useEffect, useState } from "react";
import { CurrencyPostfix } from "./styled";
import { getColumnPrecision } from "lib/helpers/boardUtils";

interface CurrencyGenericProps extends TaskFieldSetterGetter {
  currency: string;
  precision: number;
}

const CurrencyGeneric: FC<CurrencyGenericProps> = (props) => {
  const { code, precision, value, setValue, currency } = props;

  const [displayValue, setDisplayValue] = useState(value);
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  const handleInputOnChange = (value: string | number) => {
    setDisplayValue(value);
  };

  const handleInputNumberUpdate = (e: any) => {
    const value = e.target?.value || null;
    setValue({ cellName: code, cellValue: value });
  };

  return (
    <>
      <InputNumberStyled
        type="number"
        value={displayValue}
        precision={getColumnPrecision(precision)}
        disabled={!allowedToEdit}
        onChange={value => handleInputOnChange(value as number)}
        onBlur={handleInputNumberUpdate}
        onPressEnter={handleInputNumberUpdate}
        style={{ width: "100%" }}
        size="small"
        placeholder="Empty"
      />
      <CurrencyPostfix>
        {currency}
      </CurrencyPostfix>
    </>
  );
};

export default CurrencyGeneric;
