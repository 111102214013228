import React, { FC, RefObject, useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";
import { Empty, Input, Select } from "antd";
import OutsideClickHandler from "react-outside-click-handler";

import { DisplayView, TaskFieldSetterGetter, TaskSourceComponent } from "lib/types/applicationTypes";
import { TaskPropertyOption } from "lib/types/kanbanTypes";
import { COLOR_DEFAULT_GREY } from "lib/configs/colors";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";

import { FieldEmptyState } from "../../styled";
import {
  MultiSelectFieldGroup,
  MultiSelectFieldSearch,
  MultiSelectFieldWrapper,
  MultiSelectOptionsWrapper,
  MultiSelectTag,
  MultiSelectTagSelect
} from "./styled";

interface MultiSelectListProps extends TaskFieldSetterGetter {
  selectedOptions?: Array<TaskPropertyOption>;
  options?: Array<TaskPropertyOption>;
  displayView: DisplayView;
  component: TaskSourceComponent;
}

const { Option, OptGroup } = Select;

const MultiSelectGeneric: FC<MultiSelectListProps> = (props) => {
  const { code, component, displayView, setValue } = props;

  const wrapperRef = useRef(null) as RefObject<any>;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);
  const selectDropdownParent = wrapperRef && wrapperRef.current ? wrapperRef.current.offsetParent : wrapperRef.current;

  const [options, setOptions] = useState(props.options);
  const [selectedOptions, setSelectedOptions] = useState(props.selectedOptions);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const isDetailed = (displayView === DisplayView.Detailed) || (component === TaskSourceComponent.Detailed);
  const defaultSelectValue = (selectedOptions ?? [])?.map(option => option.id);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    setSelectedOptions(props.selectedOptions);
  }, [props.selectedOptions]);

  const handleSelectOnChange = (optionIds: Array<string>) => {
    const nextState = options?.filter(option => optionIds.includes(option.id));
    if (nextState) {
      setSelectedOptions(nextState);
    }
  };

  const handleSelectOnVisibleChange = () => {
    const propsValues = props.selectedOptions?.map(option => option.id);
    const stateValues = selectedOptions?.map(option => option.id);

    if (!isEqual(stateValues, propsValues)) {
      setValue({ cellName: code, cellValue: stateValues });
    }
  };

  const handleSelectClick = (e: React.MouseEvent) => {
    const dropdownElement = (e.target as HTMLElement).closest(".ant-select-dropdown");
    if (!dropdownElement) {
      setIsSelectOpen(!isSelectOpen);
    }
  };

  const handleOnOutsideClick = (e: MouseEvent) => {
    if (component === TaskSourceComponent.Detailed) {
      const dropdownElement = (e.target as HTMLElement).closest(".ant-select-dropdown");
      if (!dropdownElement) {
        setIsSelectOpen(false);
      }
    }

    if (selectDropdownParent && !selectDropdownParent.contains(e.target)) {
      setIsSelectOpen(false);
    }
  };

  const handleSearchOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value?.toLowerCase().trim();
    if (value?.length) {
      const nextState = options?.filter(option => option.title.toLowerCase().includes(value));
      setOptions(nextState);
    } else {
      setOptions(options);
    }
  };

  return (
    <MultiSelectFieldWrapper onClick={handleSelectClick} ref={wrapperRef}>
      <OutsideClickHandler onOutsideClick={handleOnOutsideClick}>
        {(!selectedOptions?.length) &&
          <FieldEmptyState>Empty</FieldEmptyState>
        }
        {(!!selectedOptions?.length) && (
          <MultiSelectOptionsWrapper isDetailed={isDetailed}>
            {selectedOptions?.map(option => (
              <MultiSelectTag
                key={option.id}
                bg={option.color || COLOR_DEFAULT_GREY}
                style={{ flexShrink: 0, maxWidth: 375 }}
              >
                {option?.title}
              </MultiSelectTag>
            ))}
          </MultiSelectOptionsWrapper>
        )}
        <Select
          mode="multiple"
          open={isSelectOpen}
          disabled={!allowedToEdit}
          value={defaultSelectValue}
          onChange={handleSelectOnChange}
          onDropdownVisibleChange={handleSelectOnVisibleChange}
          getPopupContainer={() => selectDropdownParent}
          dropdownStyle={{ minWidth: 180, maxWidth: 240 }}
          dropdownRender={menu => (
            <>
              <MultiSelectFieldSearch>
                <Input
                  allowClear
                  placeholder="Search option…"
                  onChange={handleSearchOnChange}
                  bordered={false}
                  onKeyDown={e => e.stopPropagation()}
                />
              </MultiSelectFieldSearch>
              <MultiSelectFieldGroup>
                {menu}
                {!options?.length &&
                  <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
              </MultiSelectFieldGroup>
            </>
          )}
        >
          <OptGroup label="Select options">
            {options?.map(option => (
              <Option value={option.id} key={option.id}>
                <div style={{ display: "flex", paddingRight: 10 }}>
                  <MultiSelectTagSelect bg={option.color || COLOR_DEFAULT_GREY}>
                    {option?.title}
                  </MultiSelectTagSelect>
                </div>
              </Option>
            ))}
          </OptGroup>
        </Select>
      </OutsideClickHandler>
    </MultiSelectFieldWrapper>
  );
};

export default MultiSelectGeneric;
