import React, { FC, useEffect, useState } from "react";
import { Dropdown, Menu, Tooltip } from "antd";
import { DropdownIcon, DropdownWrapper } from "styles/common";
import useBoardView from "lib/customHooks/views/useBoardView";
import { BoardViewInterface } from "lib/types/boardTypes";
import { copyLink, getFormPublicUri } from "lib/helpers/navigationUtils";
import { useBoardContext, BoardContextInterface } from "lib/contexts/BoardContext";
import ButtonControls from "components/ui/ButtonControls";
import OpenSvg from "components/icons/resizable/open";
import DotSvg from "components/icons/dot";
import { activeButtonClassShare } from "./styled";
import UrlSvg from "components/icons/resizable/url";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import {
  FormPublicDropdownDescription,
  FormPublicDropdownIconContainer,
  FormPublicDropdownInput,
  FormPublicDropdownInputContainer,
  FormPublicDropdownItem,
  FormPublicDropdownTitle
} from "pages/FormPublic/FormPublicDropdown/styled";
import useBoardSetViews from "lib/customHooks/dataLayer/board/useBoardSetViews";

const FormPublicDropdown: FC = () => {
  const view = useBoardView() as BoardViewInterface;
  const { board } = useBoardContext() as BoardContextInterface;
  const { updateBoardView } = useBoardSetViews({ board, updateDetailed: true });

  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);

  const [isShared, setIsShared] = useState(view.shared);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  useEffect(() => {
    setIsShared(view.shared);
  }, [view]);

  const formPublicUri = getFormPublicUri(view?.uid || 0);
  const formPublicLink = window.location.origin + formPublicUri;

  const handleFormOnSharedChange = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, checked: boolean) => {
    if (checked) {
      event.stopPropagation();
    } else {
      setIsDropdownOpened(false);
    }

    setIsShared(checked);
    updateBoardView(view.id, { shared: checked });
  };

  const handleOnFormCopy = () => {
    copyLink(formPublicUri);
  };

  const handleOnFormOpen = () => {
    window.open(formPublicLink, "_blank", "noopener,noreferrer");
  };

  const privateMenu = (
    <Menu style={{ maxWidth: 300, position: "relative" }} onClick={({ domEvent }) => domEvent.stopPropagation()}>
      {allowedToEdit &&
        <Menu.Item key="createFormLink" onClick={({ domEvent }) => handleFormOnSharedChange(domEvent, true)}>
          <FormPublicDropdownTitle>Create a shareable form link</FormPublicDropdownTitle>
          <FormPublicDropdownDescription>You can send the link to anyone to have them fill the form out.</FormPublicDropdownDescription>
        </Menu.Item>
      }
      {!allowedToEdit &&
        <Menu.Item style={{ cursor: "default" }} disabled>
          <FormPublicDropdownTitle>This form is not shared</FormPublicDropdownTitle>
          <FormPublicDropdownDescription>Reach out to the project owner to active the public link.</FormPublicDropdownDescription>
        </Menu.Item>
      }
    </Menu>
  );

  const sharedMenu = (
    <Menu style={{ maxWidth: 340, position: "relative" }} onClick={({ domEvent }) => domEvent.stopPropagation()}>
      <FormPublicDropdownItem key="createFormLink">
        <FormPublicDropdownTitle>This form is shared via a private link</FormPublicDropdownTitle>
        <FormPublicDropdownDescription>People with the private link can only see the empty form. The title of this form is the view name.</FormPublicDropdownDescription>
      </FormPublicDropdownItem>
      <FormPublicDropdownItem key="copy-form-link">
        <FormPublicDropdownInputContainer>
          <FormPublicDropdownInput value={formPublicLink} disabled />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Copy form link">
              <FormPublicDropdownIconContainer onClick={handleOnFormCopy}>
                <UrlSvg size={12} />
              </FormPublicDropdownIconContainer>
            </Tooltip>
            <Tooltip title="Preview form">
              <FormPublicDropdownIconContainer onClick={handleOnFormOpen}>
                <OpenSvg size={12} />
              </FormPublicDropdownIconContainer>
            </Tooltip>
          </div>
        </FormPublicDropdownInputContainer>
      </FormPublicDropdownItem>
      {allowedToEdit &&
        <Menu.Item key="disableFormLink" onClick={({ domEvent }) => handleFormOnSharedChange(domEvent, false)}>
          <DropdownWrapper>
            <DropdownIcon src="/icons/x.svg" alt="disable-form-link" />
            Disable shared view link
          </DropdownWrapper>
        </Menu.Item>
      }
    </Menu>
  );

  return (
    <Dropdown
      overlay={isShared ? sharedMenu : privateMenu}
      trigger={["click"]}
      open={isDropdownOpened}
      onOpenChange={setIsDropdownOpened}
    >
      <ButtonControls
        icon={isShared ? <DotSvg /> : <OpenSvg size={12} />}
        text={isShared ? "Shared" : "Share form"}
        className={isShared ? activeButtonClassShare : ""}
      />
    </Dropdown>
  );
};

export default FormPublicDropdown;
