import { FC } from "react";
import { Form, Input } from "antd";

interface AuthEmailProps {
  disabled?: boolean;
  value?: string;
  autoFocus?: boolean;
}

const AuthEmail: FC<AuthEmailProps> = (props) => {
  return (
    <Form.Item label="Work email" name={["email"]} rules={
      [{
        type: "email",
        message: "",
      },
      {
        required: true,
        message: "",
      }]
    }>
      <Input
        id="email"
        name="email"
        autoFocus={props.autoFocus}
        autoComplete="email"
        value={props.value}
        disabled={props.disabled}
      />
    </Form.Item>
  );
};

export default AuthEmail;
