import React, { FC } from "react";
import { StyledButton } from "styles/common";
import { ButtonIconContent, ButtonIconIcon, ButtonIconText } from "./styled";
import CrossSvg from "components/icons/resizable/arrow";

interface ButtonIconProps {
  text?: string;
  iconUrl?: string;
  icon?: React.ReactNode;
  iconOutlined?: boolean;
  color?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  buttonStyle?: any;
  redOutlined?: boolean;
  disabled?: boolean;
  noBorder?: boolean;
}

const ButtonIcon: FC<ButtonIconProps> = (props) => {
  const icon = props?.icon || <CrossSvg size={12} />;
  const iconUrl = props?.iconUrl || "/icons/add.svg";
  const color = props?.color || "";
  const redOutlined = props?.redOutlined || false;
  const iconOutlined = props?.iconOutlined || false;

  return (
    <StyledButton
      icon
      redOutlined={redOutlined}
      iconOutlined={iconOutlined}
      onClick={props.onClick}
      disabled={props.disabled}
      noBorder={props.noBorder}
    >
      <ButtonIconContent>
        <ButtonIconIcon>
          {!!props.icon && (
            <>
              {icon}
            </>
          )}
          {!props.icon && (
            <img src={iconUrl} alt="" style={{ pointerEvents: "none" }} />
          )}
        </ButtonIconIcon>
        {!!props.text && !!props.text.length && (
          <ButtonIconText>
            <div style={{ color: `${color}` }}>{props.text}</div>
          </ButtonIconText>
        )}
      </ButtonIconContent>
    </StyledButton>
  );
};

export default ButtonIcon;
