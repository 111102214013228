import { ChangeEventHandler, FC, useRef, useState } from "react";
import { Button, Form } from "antd";

import LoadingMessage from "components/blocks/LoadingMessage";
import { FileInterface } from "lib/types/types";
import UploadService from "services/UploadService";
import { FormComponentProps } from "../lib";
import AttachmentContainer from "components/blocks/AttachmentContainer";
import CircleDeleteButton from "components/blocks/CircleDeleteButton";
import { UploadMultipleRelativeContainer } from "components/blocks/FormComponents/UploadMultiple/styled";
import { captureException } from "lib/utils/sentry";

interface UploadSingleProps extends FormComponentProps {
  disabled?: boolean;
}

const UploadSingle: FC<UploadSingleProps> = (props) => {
  const { fieldName, disabled } = props;

  const [file, setFile] = useState<FileInterface | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const form = Form.useFormInstance();

  const fileInput = useRef<HTMLInputElement>(null);

  const handleOnFileInputClick = () => {
    if (!disabled) {
      fileInput?.current?.click();
    }
  };

  const handleInputOnChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
    setIsLoading(true);

    const droppedFiles = Array.from(event.target.files || []);

    try {
      const [data] = await UploadService.upload(droppedFiles);
      setFile(data);

      const nextFormState = data.id;
      form.setFields([{ name: fieldName, value: nextFormState }]);

      LoadingMessage.complete("File has been uploaded");
    } catch (error) {
      captureException(error, true, "Error uploading file");
    }

    setIsLoading(false);
  };

  const handleFileOnDelete = () => {
    setFile(null);
    form.setFields([{ name: fieldName, value: null }]);
  };

  return (
    <>
      <Button
        onClick={handleOnFileInputClick}
        loading={isLoading}
        size="small"
        htmlType="button"
      >
        {!file ? "Attach" : "Replace"} file
        <input
          type="file"
          ref={fileInput}
          style={{ display: "none" }}
          onChange={handleInputOnChange}
        />
      </Button>
      {file &&
        <UploadMultipleRelativeContainer>
          <AttachmentContainer fileId={file.id} fileUrl={file.url} fileName={file.name} />
          <CircleDeleteButton onClick={handleFileOnDelete} offsetValue={5} />
        </UploadMultipleRelativeContainer>
      }
    </>
  );
};

export default UploadSingle;
