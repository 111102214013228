import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const TemplatesSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path d="M8.33333 0C3.73096 0 0 3.73096 0 8.33333V30.5556C0 35.1579 3.73096 38.8889 8.33333 38.8889C12.9357 38.8889 16.6667 35.1579 16.6667 30.5556V16.6667H30.5556C35.1579 16.6667 38.8889 12.9357 38.8889 8.33333C38.8889 3.73096 35.1579 0 30.5556 0H8.33333Z"/>
        <path d="M0 91.6667C0 96.269 3.73096 100 8.33333 100H30.5556C35.1579 100 38.8889 96.269 38.8889 91.6667C38.8889 87.0643 35.1579 83.3333 30.5556 83.3333H16.6667V69.4444C16.6667 64.8421 12.9357 61.1111 8.33333 61.1111C3.73096 61.1111 0 64.8421 0 69.4444V91.6667Z"/>
        <path d="M91.6667 0C96.269 0 100 3.73096 100 8.33333V30.5556C100 35.1579 96.269 38.8889 91.6667 38.8889C87.0643 38.8889 83.3333 35.1579 83.3333 30.5556V16.6667H69.4444C64.8421 16.6667 61.1111 12.9357 61.1111 8.33333C61.1111 3.73096 64.8421 0 69.4444 0H91.6667Z"/>
        <path d="M91.6667 100C96.269 100 100 96.269 100 91.6667V69.4444C100 64.8421 96.269 61.1111 91.6667 61.1111C87.0643 61.1111 83.3333 64.8421 83.3333 69.4444V83.3333H69.4444C64.8421 83.3333 61.1111 87.0643 61.1111 91.6667C61.1111 96.269 64.8421 100 69.4444 100H91.6667Z"/>
        <path d="M42.2219 57.7778H28.3331C23.7307 57.7778 20 54.0468 20 49.4445C20 44.8421 23.731 41.1111 28.3334 41.1111H42.2222V27.2223C42.2222 22.6199 45.9532 18.8889 50.5555 18.8889C55.1579 18.8889 58.8889 22.6199 58.8889 27.2223V41.1111H72.7777C77.3801 41.1111 81.1111 44.8421 81.1111 49.4445C81.1111 54.0468 77.3801 57.7778 72.7777 57.7778H58.8886V71.6667C58.8886 76.269 55.1576 80 50.5553 80C45.9529 80 42.2219 76.269 42.2219 71.6667V57.7778Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default TemplatesSvg;
