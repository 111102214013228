import { ColorObject } from "components/blocks/ColorPalette/types";
import { sample } from "lodash";

const colors: Array<ColorObject> = [
  { normal: "#d6f0fb", hover: "#d2e7f1" },
  { normal: "#ccf5e9", hover: "#cceade" },
  { normal: "#d8f6c8", hover: "#d5ecc5" },
  { normal: "#fcebbc", hover: "#f0e3ba" },
  { normal: "#f9e3d7", hover: "#ecdad0" },
  { normal: "#f9dde4", hover: "#ebd5db" },
  { normal: "#fadbf5", hover: "#ebd2e8" },
  { normal: "#ebe2fc", hover: "#e0d9f0" },
  { normal: "#8dceef", hover: "#95c4e2" },
  { normal: "#8ddac5", hover: "#98d1bf" },
  { normal: "#a4dd91", hover: "#a9d293" },
  { normal: "#f8d77e", hover: "#e8d085" },
  { normal: "#f3ad87", hover: "#e1a987" },
  { normal: "#f1a2b7", hover: "#dba0af" },
  { normal: "#eda2dd", hover: "#d99fd1" },
  { normal: "#c8b1fa", hover: "#bdaaec" },
];

export default colors;

export const COLOR_DEFAULT_GREY = "#ccc";

export const randomColor = () => {
  return sample(colors) as ColorObject;
};
