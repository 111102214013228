import { io, Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";
import { message, notification } from "antd";

import { getTokenFromStorage } from "lib/helpers/helpers";
import configuration from "lib/configs/configuration";
import { WsEvents } from "lib/types/wsTypes";
import ReloaderText from "components/blocks/ReloaderText";
import BaseClassLoggable from "lib/utils/BaseClassLoggable";


class WebSocketService extends BaseClassLoggable {
  socket: null | Socket<DefaultEventsMap, DefaultEventsMap> = null;
  jwt = "";
  path = "";
  socketId = "";
  initialized = false;
  // subscriptions: Record<string, any[]> = {};

  connect() {
    this.jwt = getTokenFromStorage();
    this.path = configuration.backend.ws;
    if (this.jwt) {
      this._establishConnection();
    }
  }

  private _establishConnection = () => {
    this.socket = io(this.path, {
      transports: ["websocket"],
      query: {
        "jwt": this.jwt
      },
      withCredentials: true,
    });

    this.socket.on(WsEvents.Service.Connect, this._onopen);
    this.socket.on(WsEvents.Service.Disconnect, this._onclose);
  };

  /**
   * @param {string} message
   */
  private _onmessage = (message: any) => {
    if (typeof message === "string") {
      try {
        return JSON.parse(message);
      } catch (e) {
        return e;
      }
    }
    return message;
  };

  private _onopen = () => {
    this.logger.debug("WebSocket connected");

    if (configuration.app.isDevelopment && this.initialized) {
      notification.success({
        message: "Connection restored",
        placement: "bottomRight",
      });
    }

    this.initialized = true;
  };

  private _onclose = (reason: string) => {
    this.logger.debug("WebSocket disconnected. Reason:", reason);

    if (configuration.app.isDevelopment && reason === "transport close") {
      notification.error({
        message: "Server disconnected",
        placement: "bottomRight",
      });
    }
  };

  private _onfail = () => {
    message.warning({
      content: ReloaderText({ text: "Server has been disconnected. " }),
      duration: 10000,
    });
  };

  disconnect = () => {
    if (this.socket) {
      this.socket.close();
    }
  };

  sendMessage = (eventName: string, payload: any = {}) => {
    if (this.socket) {
      this.socket.emit(eventName, payload);
    }
  };

  subscribe = <T>(eventName: string, callback: (data: T) => void) => {

    if (!this.socket) {
      return;
    }

    this.socket.on(eventName, (resp) => {
      const message = this._onmessage(resp);

      if (callback) {
        callback(message);
      }
    });
  };

  unsubscribe = (eventName: string) => {
    if (!this.socket) {
      return;
    }

    this.socket.off(eventName);
  };

  setSocketId = (id: string) => {
    if (configuration.app.isDevelopment) {
      this.logger.debug("Setting socket id to:", id);
    }
    this.socketId = id;
  };

  getSocketId = () => {
    return this.socketId;
  };
}

export default new WebSocketService();
