import { FC } from "react";
import { DatePicker } from "antd";
import { Moment } from "moment";

import { FormItem } from "../styled";
import { DateFormats } from "lib/theme/lib";

interface DateFieldProps {
  label?: string;
  name: string | string[];
  placeholder: string;
  initialValue?: string;
  bordered?: boolean | true;
  disabled?: boolean;
  onChange?: (value: Moment | null, dateString: string) => void;
  rules?: Array<any>;
  fieldKey?: Array<any>;
  value?: Moment;
}

const DateField: FC<DateFieldProps> = (props) => {
  const {
    label,
    name,
    disabled,
    onChange,
    rules,
    initialValue,
    bordered,
    value,
  } = props;

  return (
    <FormItem
      label={label}
      name={name}
      rules={rules}
      initialValue={initialValue}
    >
      <DatePicker
        style={{ width: "100%" }}
        onChange={onChange}
        value={value}
        bordered={bordered}
        disabled={disabled}
        format={DateFormats.Friendly}
      />
    </FormItem>
  );
};

export default DateField;
