import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const CdrSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M68.4166 20.5C66.125 20.5 63.9583 19.5833 62.3333 18C60.7083 16.375 59.8333 14.2083 59.8333 11.9167V0H21.4583C20 0 18.5416 0.291667 17.1666 0.833333C15.7916 1.41667 14.5833 2.20833 13.5416 3.25C12.5 4.29167 11.6666 5.54167 11.125 6.875C10.5416 8.25 10.2916 9.70833 10.2916 11.1667V78.75C10.2916 81.7083 11.5 84.5833 13.5833 86.6667C15.6666 88.75 18.5416 89.9583 21.5 89.9583H67.5833C70.5416 89.9583 73.4166 88.7917 75.5 86.6667C77.5833 84.5833 78.7916 81.7083 78.7916 78.75V20.5H68.4166Z" fill="#3CBA32"/>
        <path d="M78.7083 20.5H68.375C66.0833 20.5 63.9166 19.5833 62.2916 18C60.6666 16.375 59.7916 14.2083 59.7916 11.9167V0L78.7083 20.5Z" fill="#3CBA32"/>
        <path d="M83.125 71.0833H31.0417C27.3334 71.0833 24.3334 74.0832 24.3334 77.7916V93.3333C24.3334 97.0416 27.3334 100.042 31.0417 100.042H83.125C86.8334 100.042 89.8334 97.0416 89.8334 93.3333V77.7916C89.8334 74.0832 86.8334 71.0833 83.125 71.0833Z" fill="#3CBA32"/>
        <path d="M42.0416 93.7499C40.4166 93.7499 39.2083 93.3749 38.4583 92.5833C37.6666 91.7916 37.2916 90.5833 37.2916 88.9999V84.1249C37.2916 82.4999 37.6666 81.2916 38.4583 80.4999C39.25 79.7083 40.4166 79.3333 42.0416 79.3333H44.625C45.9583 79.3333 46.9583 79.6249 47.6666 80.1666C48.375 80.7083 48.7916 81.5833 48.875 82.7499C48.9166 83.0416 48.875 83.2916 48.7083 83.4166C48.5416 83.5833 48.3333 83.6666 48.0416 83.6666C47.5 83.6666 47.2083 83.3749 47.125 82.7916C47.0416 82.1249 46.8333 81.7083 46.4583 81.4583C46.0833 81.2499 45.5 81.1249 44.6666 81.1249H42.0833C41.3333 81.1249 40.75 81.2083 40.2916 81.3749C39.875 81.5416 39.5416 81.8749 39.375 82.2916C39.2083 82.7083 39.125 83.3333 39.125 84.0833V88.9583C39.125 89.7083 39.2083 90.2916 39.375 90.7499C39.5416 91.1666 39.875 91.4999 40.2916 91.6666C40.7083 91.8333 41.3333 91.9166 42.0833 91.9166H44.625C45.4583 91.9166 46.0416 91.7916 46.4166 91.5833C46.7916 91.3333 47.0416 90.9166 47.0833 90.2499C47.1666 89.6666 47.5 89.3749 48 89.3749C48.2916 89.3749 48.5416 89.4583 48.6666 89.6249C48.8333 89.7916 48.875 89.9999 48.8333 90.2916C48.7083 91.4999 48.3333 92.3333 47.625 92.8749C46.9166 93.4166 45.9166 93.7083 44.5833 93.7083H42.0416V93.7499Z" fill="white"/>
        <path d="M53.5 93.75C52.9167 93.75 52.625 93.4583 52.625 92.875V80.25C52.625 79.6667 52.9167 79.375 53.5 79.375H58.625C60.4583 79.375 61.8333 79.8333 62.7083 80.7083C63.5833 81.5833 64.0417 82.9583 64.0417 84.7917V88.4167C64.0417 90.25 63.625 91.625 62.75 92.5C61.875 93.375 60.5417 93.7917 58.6667 93.7917H53.5V93.75ZM54.375 91.9583H58.5833C59.5 91.9583 60.2083 91.8333 60.75 91.625C61.2917 91.4167 61.6667 91.0417 61.875 90.5C62.0833 89.9583 62.2083 89.25 62.2083 88.3333V84.7083C62.2083 83.7917 62.0833 83.0833 61.875 82.5833C61.6667 82.0417 61.2917 81.6667 60.75 81.4583C60.2083 81.25 59.5 81.125 58.5833 81.125H54.375V91.9583Z" fill="white"/>
        <path d="M77.1667 93.7916C76.9167 93.9583 76.7084 93.9999 76.5 93.9166C76.2917 93.8749 76.125 93.7083 75.9584 93.4999L72.6667 87.9999H69.7084V92.8333C69.7084 93.4166 69.4167 93.7083 68.8334 93.7083C68.25 93.7083 67.9584 93.4166 67.9584 92.8333V80.2083C67.9584 79.6249 68.25 79.3333 68.8334 79.3333H73.7917C75.0834 79.3333 76.0834 79.6666 76.75 80.3749C77.4584 81.0416 77.7917 82.0416 77.7917 83.3333V83.9583C77.7917 85.0833 77.5417 85.9999 77 86.6666C76.4584 87.3333 75.7084 87.7499 74.7084 87.9166L77.5 92.5416C77.7917 93.0833 77.6667 93.4999 77.1667 93.7916ZM69.7084 86.2083H73.7917C74.5834 86.2083 75.1667 86.0416 75.5 85.7083C75.8334 85.3749 76 84.7916 76 83.9999V83.3749C76 82.5833 75.8334 81.9999 75.5 81.6666C75.1667 81.3333 74.5834 81.1666 73.7917 81.1666H69.7084V86.2083Z" fill="white"/>
        <path d="M39.7917 26.3749C40.3334 25.9999 41 25.9166 41.5417 26.2916C43.7084 27.7499 48.5834 33.7916 41.5417 57.9583C41.4167 58.3333 41.0834 58.5833 40.75 58.5833C40.375 58.5833 40.0417 58.3749 39.9584 57.9999C38.7917 54.0416 32.6667 31.6666 39.7917 26.3749Z" fill="#3CBA32"/>
        <path d="M45.7084 27.2083C45.2917 26.9999 44.9167 27.5416 45.25 27.8749C48.125 30.7499 51.6667 37.9166 44.2917 53.9999C44.0834 54.4583 44.7084 54.8333 45 54.4166C49.7917 47.2916 57.5417 32.7083 45.7084 27.2083Z" fill="#3CBA32"/>
        <path d="M51.0834 29.5833C50.8334 29.375 50.5001 29.7083 50.7918 29.9583C53.5001 33.2083 56.2501 41 44.4168 56.5417C44.3751 56.5833 44.3751 56.6667 44.4584 56.625C47.5418 53.0417 60.5001 37.0833 51.0834 29.5833Z" fill="#3CBA32"/>
        <path d="M35.7501 27.2083C36.1668 26.9999 36.5418 27.5416 36.2084 27.8749C33.3334 30.7499 29.7918 37.9166 37.1668 53.9999C37.3751 54.4583 36.7501 54.8333 36.4584 54.4166C31.7084 47.2916 23.9168 32.7083 35.7501 27.2083Z" fill="#3CBA32"/>
        <path d="M30.3751 29.5834C30.7084 29.3334 30.9584 29.75 30.6667 29.9584C27.9167 33.2917 25.2084 41.1667 37.6667 57.1667C37.7917 57.2917 37.6667 57.3334 37.5834 57.25C36.3334 55.875 20.1667 37.7084 30.3751 29.5834Z" fill="#3CBA32"/>
        <path d="M42.7084 63.7499C42.625 63.9999 42.375 64.2083 42.125 64.2083H39.5C39.2084 64.2083 39 64.0416 38.9167 63.7499L38.25 61.6249C38.125 61.2082 38.4167 60.8333 38.8334 60.8333H42.7917C43.2084 60.8333 43.5 61.2499 43.375 61.6249L42.7084 63.7499Z" fill="#3CBA32"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default CdrSvg;
