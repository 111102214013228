import { FC } from "react";
import styled from "@emotion/styled/macro";
import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import { dataInput } from "components/blocks/TaskTable/styled";
import { InputNumberStyled } from "components/blocks/TaskTable/components/TaskTitle/styled";
import NumberDisplay from "./Display/generic";
import { fieldDetailedInput } from "../styled";

const NumberListWrapper = styled.div`
  ${dataInput};
  cursor: auto;
  z-index: 5;
  &:hover {
    ${InputNumberStyled}
  }
`;

const NumberDetailedWrapper = styled.div`
  ${fieldDetailedInput};
`;

interface NumberFieldProps extends TaskFieldPropsGeneric {
  precision: number;
}

const NumberField: FC<NumberFieldProps> = (props) => {
  const { component } = props;

  switch (component) {
    case TaskSourceComponent.Card:
      return null;
    case TaskSourceComponent.Cell:
      return (
        <NumberListWrapper>
          <NumberDisplay {...props} />
        </NumberListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <NumberDetailedWrapper>
          <NumberDisplay {...props} />
        </NumberDetailedWrapper>
      );
    default:
      return null;
  }
};

export default NumberField;
