import { ChangeEventHandler, FC, useRef, useState } from "react";
import { Button, Form } from "antd";
import { FileInterface } from "lib/types/types";
import { FormComponentProps } from "../lib";
import LoadingMessage from "components/blocks/LoadingMessage";
import UploadService from "services/UploadService";
import { EntityId } from "lib/types/entity";
import AttachmentContainer from "components/blocks/AttachmentContainer";
import CircleDeleteButton from "components/blocks/CircleDeleteButton";
import { UploadMultipleRelativeContainer } from "components/blocks/FormComponents/UploadMultiple/styled";
import { captureException } from "lib/utils/sentry";

interface UploadMultipleProps extends FormComponentProps {
  disabled?: boolean;
}

const UploadMultiple: FC<UploadMultipleProps> = (props) => {
  const { fieldName, disabled } = props;

  const form = Form.useFormInstance();
  const fileInput = useRef<HTMLInputElement>(null);
  const initialValue = form.getFieldValue(fieldName) ?? [];

  const [files, setFiles] = useState<FileInterface[]>(initialValue);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnFileInputClick = () => {
    if (!disabled) {
      fileInput?.current?.click();
    }
  };

  const handleInputOnChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
    setIsLoading(true);

    const droppedFiles = Array.from(event.target.files || []);

    try {
      const uploadedFiles = await UploadService.upload(droppedFiles);
      const nextState = [...files, ...uploadedFiles];

      setFiles(nextState);
      form.setFields([{ name: fieldName, value: nextState }]);

      LoadingMessage.complete("Files have been uploaded");
    } catch (error) {
      captureException(error, true, "Error uploading files");
    }

    setIsLoading(false);
  };

  const handleFileOnDelete = (id: EntityId) => {
    const nextState = files.filter(file => String(file.id) !== String(id));

    setFiles(nextState);
    form.setFields([{ name: fieldName, value: nextState }]);
  };

  return (
    <>
      <Button
        onClick={handleOnFileInputClick}
        loading={isLoading}
        size="small"
        htmlType="button"
      >
        Attach files
        <input
          multiple
          type="file"
          ref={fileInput}
          style={{ display: "none" }}
          onChange={handleInputOnChange}
        />
      </Button>
      {files?.map(file => (
        <UploadMultipleRelativeContainer>
          <AttachmentContainer fileId={file.id} fileUrl={file.url} fileName={file.name} />
          <CircleDeleteButton onClick={() => handleFileOnDelete(file.id)} offsetValue={5} />
        </UploadMultipleRelativeContainer>
      ))}
    </>
  );
};

export default UploadMultiple;
