import { isEqual } from "lodash";
import React, { FC, RefObject, useEffect, useRef, useState } from "react";
import { Empty, Input, Select } from "antd";
import Avatar from "antd/lib/avatar";
import OutsideClickHandler from "react-outside-click-handler";

import { useAppSelector } from "app/store";
import { getUserFullName } from "lib/helpers/userUtils";
import { MemberRoles, UserInterface } from "lib/types/types";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import { DisplayView, TaskFieldSetterGetter, TaskSourceComponent } from "lib/types/applicationTypes";
import DottedPlaceholder from "components/blocks/TaskTable/components/placeholders/DottedPlaceholder";
import AvatarCustom from "components/ui/AvatarCustom";
import UserCustom from "components/ui/UserCustom";
import { DottedCircle } from "components/blocks/TaskTable/components/placeholders/DottedPlaceholder/styled";
import { PlaceholderImage } from "pages/Board/BoardKanban/Card/Calendar/styled";
import { UsersFieldWrapper, UsersFieldSearch, UsersFieldGroup } from "./styled";
import { FieldEmptyState } from "../../styled";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import { DefaultColumnCodes } from "lib/types/boardTypes";
import HiddenCell from "../../HiddenCell";

interface UsersCellProps extends TaskFieldSetterGetter {
  displayView?: DisplayView;
  component: TaskSourceComponent;
}

const { Option, OptGroup } = Select;

const UsersGeneric: FC<UsersCellProps> = (props) => {
  const { code, displayView, component, value, setValue } = props;
  const { task } = useTaskContext() as TaskContextInterface;

  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);
  const workspaceId = task.board.workspace;

  const workspaces = useAppSelector(state => state.workspaces.workspaces);
  const sourceWorkspace = workspaces.find(workspace => String(workspace.id) === String(workspaceId));
  const collaborators = sourceWorkspace?.collaborators || [];
  const initialSuggestions = collaborators.map(user => ({ display: getUserFullName(user), id: user.id, user: user }));

  const { isAssigneesEnabled } = useBoardFeatures(task?.board);
  const isHidden = (code === DefaultColumnCodes.Assignees) && !isAssigneesEnabled;

  const [selectedUsers, setSelectedUsers] = useState(value);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(initialSuggestions);

  const wrapperRef = useRef(null) as RefObject<any>;
  const selectDropdownParent = wrapperRef && wrapperRef.current ? wrapperRef.current.offsetParent : wrapperRef.current;

  useEffect(() => {
    setSelectedUsers(value);
  }, [value]);

  const handleSelectOnChange = (userIds: Array<any>) => {
    const newAssignees = collaborators?.filter(u => userIds.includes(u.id));
    if (newAssignees) {
      setSelectedUsers(newAssignees);
    }
  };

  const handleSelectOnVisibleChange = () => {
    const stateUsers = selectedUsers?.map((u: UserInterface) => (u.id));
    const propsUsers = value?.map((u: UserInterface) => (u.id));

    if (!isEqual(stateUsers, propsUsers)) {
      setValue({ cellName: code, cellValue: selectedUsers });
    }
  };

  const handleSelectClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const dropdownElement = (e.target as HTMLElement).closest(".ant-select-dropdown");
    if (!dropdownElement) {
      setIsSelectOpen(!isSelectOpen);
    }
  };

  const handleOnOutsideClick = (e: MouseEvent) => {
    if (component === TaskSourceComponent.Detailed) {
      const dropdownElement = (e.target as HTMLElement).closest(".ant-select-dropdown");
      if (!dropdownElement) {
        setIsSelectOpen(false);
      }
    }

    if (selectDropdownParent && !selectDropdownParent.contains(e.target)) {
      setIsSelectOpen(false);
    }
  };

  const handleSearchOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value?.toLowerCase().trim();
    if (searchValue?.length) {
      const nextState = initialSuggestions.filter(suggestion => suggestion.display?.toLowerCase().includes(searchValue));
      setSuggestions(nextState);
    } else {
      setSuggestions(initialSuggestions);
    }
  };

  const renderEmptyState = () => {
    switch (component) {
      case TaskSourceComponent.Card:
        return (
          <DottedCircle>
            <PlaceholderImage src={process.env.PUBLIC_URL + "/icons/assignees.svg"} alt="menu" />
          </DottedCircle>
        );
      case TaskSourceComponent.Detailed:
        return (
          <FieldEmptyState>Empty</FieldEmptyState>
        );
      case TaskSourceComponent.Cell:
        return (
          <DottedPlaceholder icon="/icons/assignees.svg" />
        );
      default:
        return (
          <FieldEmptyState>Empty</FieldEmptyState>
        );
    }
  };

  return (
    <UsersFieldWrapper onClick={handleSelectClick} ref={wrapperRef}>
      {isHidden &&
        <HiddenCell />
      }
      {!isHidden &&
        <OutsideClickHandler onOutsideClick={handleOnOutsideClick}>
          {(!selectedUsers?.length && displayView) &&
            renderEmptyState()
          }
          {(!!selectedUsers?.length) &&
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar.Group maxCount={2}>
                {selectedUsers.map((assignee: UserInterface) => (
                  <AvatarCustom key={assignee.id} user={assignee} tooltip />
                ))}
              </Avatar.Group>
            </div>
          }
          <Select
            mode="multiple"
            // @ts-ignore
            value={selectedUsers?.map(u => u.id) || []}
            onChange={handleSelectOnChange}
            onDropdownVisibleChange={handleSelectOnVisibleChange}
            open={isSelectOpen}
            getPopupContainer={() => selectDropdownParent}
            dropdownStyle={{ width: "100%", minWidth: 210, maxWidth: 210 }}
            disabled={!allowedToEdit}
            dropdownRender={menu => (
              <>
                <UsersFieldSearch>
                  <Input
                    allowClear
                    placeholder="Search person…"
                    onChange={handleSearchOnChange}
                    bordered={false}
                    onKeyDown={e => e.stopPropagation()}
                  />
                </UsersFieldSearch>
                <UsersFieldGroup>
                  {menu}
                  {!suggestions?.length &&
                    <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  }
                </UsersFieldGroup>
              </>
            )}
          >
            <OptGroup label="Select a person">
              {suggestions?.map(user => (
                <Option value={user.id} key={user.id}>
                  <UserCustom user={user.user} name={user.display} />
                </Option>
              ))}
            </OptGroup>
          </Select>
        </OutsideClickHandler>
      }
    </UsersFieldWrapper>
  );
};

export default UsersGeneric;
