import { FC } from "react";
import styled from "@emotion/styled/macro";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import SyncedFieldTooltip from "components/blocks/SyncedFieldTooltip";
import { TaskFieldSetterGetter } from "lib/types/applicationTypes";
import { useBoardContext } from "lib/contexts/BoardContext";
import { useTaskContext } from "lib/contexts/TaskContext";

interface IdGenericProps extends TaskFieldSetterGetter {
  value: Record<string, any>;
  propertyType: TaskPropertyClass;
}

const IdInnerWrapper = styled.div`
  white-space: nowrap;
  color: #999;
`;

const IdGeneric: FC<IdGenericProps> = (props) => {
  const { value } = props;

  const { board } = useBoardContext();
  const { task } = useTaskContext();

  const isSyncedBoard = !!board?.externalDataSource || !!task?.board?.externalDataSource;
  const isEditDisabled = isSyncedBoard && (props.propertyType === TaskPropertyClass.Synced);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IdInnerWrapper>
        {String(task?.uid || task?.id || value)}
      </IdInnerWrapper>
      {isEditDisabled &&
        <SyncedFieldTooltip marginLeft />
      }
    </div>
  );
};

export default IdGeneric;
