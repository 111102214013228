import { useCallback } from "react";
import { WorkspaceInterface } from "lib/types/types";
import { deleteWorkspace } from "app/slices/workspaceSlice";
import useWSSubscription from "../useWSSubscription";
import useGetWorkspaceIdFromPath from "../routeHooks/useGetWorkspaceIdFromPath";
import { setBoard } from "app/slices/currentBoardSlice";
import { useAppDispatch } from "app/store";
import { WsEvents } from "lib/types/wsTypes";
import LoadingMessage from "components/blocks/LoadingMessage";

const useWSDeleteWorkspace = () => {
  const dispatch = useAppDispatch();
  const openedWorkspaceId = useGetWorkspaceIdFromPath();

  const callback = useCallback((data: WorkspaceInterface) => {
    dispatch(deleteWorkspace({ id: data.id }));
    if (data.id === openedWorkspaceId) {
      dispatch(setBoard(null));
      LoadingMessage.complete(`The project ${data.name} has been deleted`);
    }
  }, [dispatch, openedWorkspaceId]);

  useWSSubscription<WorkspaceInterface>({
    eventName: WsEvents.Workspace.Delete,
    callback,
    fnKey: "useWSDeleteWorkspace"
  });
};

export default useWSDeleteWorkspace;
