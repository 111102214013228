import { useAppSelector } from "app/store";
import { EntityId } from "lib/types/entity";
import { Column } from "lib/types/tasksTypes";
import { useCallback } from "react";
import useBoardSetViews from "../dataLayer/board/useBoardSetViews";
import useBoardView from "./useBoardView";

const useBoardViewColumns = (boardId: number | string, columnsData: Column[]) => {
  const view = useBoardView();
  const board = useAppSelector(state => state.currentBoardReducer.board);
  const { updateBoardView } = useBoardSetViews({ board, updateDetailed: true });

  const appliedColumns = view?.appliedColumns || [];
  let newColumns: Column[] = [];

  if (appliedColumns) {
    newColumns = columnsData?.map(column => {
      const appliedColumn = appliedColumns.find((item) => String(item.id) === String(column.id));
      return appliedColumn
        ? {
          ...column,
          hidden: appliedColumn.hidden,
          sort: appliedColumn.sort
        }
        : column;
    });
  }

  const saveViewColumns = useCallback((data: Column[]) => {
    if (!view) {
      return;
    }

    const payload = data.map((item, index) => ({
      id: item.id,
      sort: index,
      hidden: item.hidden || false
    }));

    updateBoardView(view.id, { appliedColumns: payload });

  }, [updateBoardView, view]);

  const toggleHidden = useCallback(async (columnId: EntityId, hidden: boolean) => {
    const columnsSource = appliedColumns.length ? appliedColumns : columnsData;

    const columns = columnsSource?.map((column: any) => {
      if (column.id === columnId) {
        return { ...column, hidden };
      }
      return column;
    });
    await saveViewColumns(columns);
  }, [appliedColumns, columnsData, saveViewColumns]);

  return {
    appliedColumns: newColumns || columnsData,
    saveViewColumns,
    toggleHidden
  };
};

export default useBoardViewColumns;
