import { cloneDeep } from "lodash";
import { TaskInterface, TaskOptimisticUpdatePayload, TaskSubtaskInterface } from "lib/types/tasksTypes";
import { EntityId } from "lib/types/entity";
import { UserInterface } from "lib/types/types";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import { DefaultColumnCodes } from "lib/types/boardTypes";
import { captureException } from "lib/utils/sentry";

export const getTaskLastSortList = (tasks: Array<TaskInterface> = []) => {
  const mathMax = 1 + Math.max(...tasks.map(task => Number(task.sort__list)), 0);
  const listLength = 1 + tasks.length;

  return Math.max(mathMax, listLength, 0);
};

export const getTaskLastSortKanban = (tasks: Array<TaskInterface> = [], laneId: EntityId) => {
  const tasksLane = tasks?.filter(task => task.status?.toString() === laneId);

  const mathMax = 1 + Math.max(...tasksLane.map(task => Number(task.sort__kanban), 0));
  const laneLength = 1 + tasksLane.length;

  return Math.max(mathMax, laneLength, 0);
};

export const calculateTaskProgress = (subtasks: Array<TaskSubtaskInterface> = []) => {
  const completedSubtasks = subtasks?.filter(subtask => subtask.completed).length;
  const allSubtasks = subtasks?.length;
  const progress = Math.round(completedSubtasks / allSubtasks * 100);

  return !isNaN(progress) ? progress : -1;
};

export const updateTaskProperties = (task: TaskInterface, updatedProperties: TaskOptimisticUpdatePayload, editor: UserInterface) => {
  const { columns } = task.board;
  const nextState: Partial<TaskInterface> = {
    propertyValues: cloneDeep(task.propertyValues),
    customProperties: cloneDeep(task.customProperties),
  };

  for (const key in updatedProperties) {
    const code = key as keyof TaskInterface;
    const nextValue = updatedProperties[code] as never;

    // Related components (without a corresponding column) – break early
    if ([
      DefaultColumnCodes.Collaborators,
      DefaultColumnCodes.Comments,
      "subtasks",
      "changelog",
    ].includes(code)) {
      nextState[code] = nextValue;
      continue;
    }

    // Default properties
    if ([
      DefaultColumnCodes.Completion,
      DefaultColumnCodes.Title,
      DefaultColumnCodes.Description,
      DefaultColumnCodes.DescriptionRTE,
      DefaultColumnCodes.Status,
      DefaultColumnCodes.LastModifiedBy,
      DefaultColumnCodes.Viewpoints,
      DefaultColumnCodes.Annotations,
    ].includes(code as DefaultColumnCodes)) {
      nextState[code] = nextValue;

      // Status property — check if task should be marked as `completed`
      if (code === DefaultColumnCodes.Status) {
        const isFinalStatus = task.board.statuses.find(status => String(status.id) === String(nextValue))?.isFinal;
        if (isFinalStatus) {
          nextState.completed = true;
          nextState.closingDate = new Date();
        }
      }
    }

    const columnIndex = columns.findIndex(column => column.code === code);
    const findPropertyIndex = task.propertyValues.findIndex(pv => pv.code === code);
    if (columnIndex === -1) {
      const errorText = "[taskUtils.updateTaskProperties] Cannot find column: " + code;
      captureException(errorText);
      continue;
    }

    const propertyColumn = columns[columnIndex];
    nextState.propertyValues![findPropertyIndex].value = nextValue;

    switch (propertyColumn.class) {
      case TaskPropertyClass.System:
      case TaskPropertyClass.Default: {
        nextState[code] = nextValue;
        continue;
      }
      case TaskPropertyClass.Custom: {
        if (findPropertyIndex !== -1) {
          nextState.customProperties[code] = nextValue;
          nextState[code] = nextValue;
        }
        continue;
      }
      case TaskPropertyClass.Synced:
      default:
        continue;
    }
  }

  nextState.updated_at = new Date();
  nextState.lastEditor = editor;

  return nextState;
};
