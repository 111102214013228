import styled from "@emotion/styled/macro";

export const TaskCommentMainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
  position: relative;
`;

export const TaskCommentContent = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  flex-grow: 1;
`;

export const TaskCommentEditControl = styled.div`
  margin: 5px 0 0 5px;
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 200ms ease;
  cursor: pointer;
  &:hover {
    background-color: #e5e5e5;
  }
`;

export const TaskCommentAvatarContainer = styled.div`
  width: 35px;
  height: 35px;
  margin-right: 15px;
`;

export const TaskCommentContentContainer = styled.div`
  overflow: hidden;
`;

export const TaskCommentAuthorContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TaskCommentName = styled.div`
  font-weight: 600;
  margin-right: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TaskCommentDateCreated = styled.div`
  display: flex;
  flex-shrink: 0;
  font-size: 12px;
  color: #6f7782;
`;

export const TaskCommentEditedTag = styled.div`
  margin-left: 4px;
  font-style: italic;
  font-size: 12px;
  color: #6f7782;
`;

export const TaskCommentTextContainer = styled.div`
  width: 100%;
  margin-bottom: 5px;
  white-space: pre-line;
`;

export const MentionLink = styled.span`
  color: #14aaf5;
`;
