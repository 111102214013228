import BaseClassLoggable from "lib/utils/BaseClassLoggable";

export abstract class ForgeViewerExtension extends BaseClassLoggable {
  public readonly extensionName = "";
  protected viewer: Autodesk.Viewing.Viewer3D | null;
  protected extOptions?: Autodesk.Viewing.ExtensionOptions;

  constructor(
    viewer: Autodesk.Viewing.Viewer3D | null,
    options?: Autodesk.Viewing.ExtensionOptions
  ) {
    super();
    this.viewer = viewer;
    this.extOptions = options || ({} as Autodesk.Viewing.ExtensionOptions);
  }

  public abstract load(): void;
  public abstract unload(): void;
  public abstract activate(): void;
  public abstract deactivate(): void;
}
