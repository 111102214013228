import { FC } from "react";
import styled from "@emotion/styled/macro";
import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import { dataInput } from "components/blocks/TaskTable/styled";
import DateTimeGeneric from "./Display/generic";
import { fieldDetailedWrapper } from "../styled";

const DateTimeListWrapper = styled.div`
  ${dataInput};
`;

const DateTimeDetailedWrapper = styled.div`
  ${fieldDetailedWrapper};
`;

const DateTimeField: FC<TaskFieldPropsGeneric> = (props) => {
  const { component } = props;

  switch (component) {
    case TaskSourceComponent.Card:
      return null;
    case TaskSourceComponent.Cell:
      return (
        <DateTimeListWrapper>
          <DateTimeGeneric {...props} />
        </DateTimeListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <DateTimeDetailedWrapper>
          <DateTimeGeneric {...props} />
        </DateTimeDetailedWrapper>
      );
    default:
      return null;
  }
};

export default DateTimeField;
