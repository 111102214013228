import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { MenuItemIconProps } from "components/ui/MenuItemIcon/index";

export const MenuItemIconComponent = styled.div<MenuItemIconProps>`
  display: flex;
  align-items: center;
  padding-right: 5px;
  ${({ disabled }) => disabled && css`
    opacity: 0.7;
  `}
  ${({ red }) => red && css`
    path {
      fill: #ed4758; !important;
    }
  `}
`;

export const MenuItemIconContainer = styled.div<MenuItemIconProps>`
  height: 12px;
  width: 12px;
  display: flex;
  align-items: center;
  align-content: center;
`;

export const MenuItemIconText = styled.div<MenuItemIconProps>`
  margin-left: 7px;
  color: #333;
  white-space: nowrap;
  ${({ red }) => red && css`
    color: #ed4758;
  `}
`;
