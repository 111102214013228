import { FC } from "react";
import { Form, Select } from "antd";
import ReusableSelectOption from "components/blocks/ReusableFields/ReusableMultiSelectOption";
import { getUserFullName } from "lib/helpers/userUtils";
import { InputPlaceholders } from "lib/theme/lib";
import { UserInterface } from "lib/types/types";
import { FormComponentProps } from "../lib";
import { EntityId } from "lib/types/entity";

interface UserSingleProps extends FormComponentProps {
  members?: Array<UserInterface>;
}

const UserSingle: FC<UserSingleProps> = (props) => {
  const { fieldName, members } = props;
  const form = Form.useFormInstance();
  const initialValues = form.getFieldValue(fieldName)?.id;

  const handleSelectOnChange = (value: EntityId) => {
    const nextState = members?.find(member => String(member.id) === String(value));

    form.setFields([{ name: fieldName, value: nextState }]);
  };

  return (
    <Select
      placeholder={InputPlaceholders.Empty}
      allowClear
      showSearch
      defaultValue={initialValues}
      onChange={handleSelectOnChange}
      filterOption={(input, option) =>
        String(option?.label)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {members?.map(user => (
        <Select.Option label={getUserFullName(user)} value={user.id} key={user.id} >
          <ReusableSelectOption selectTitle={getUserFullName(user)} selectColor={user.color || undefined} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default UserSingle;
