import { FC } from "react";

import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import { TaskPropertyDateTimeFormat } from "lib/types/kanbanTypes";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";

import { DateListWrapper, DateDetailedWrapper } from "./styled";
import DateGeneric from "./Display/generic";
import { useBoardContext } from "lib/contexts/BoardContext";
import { useTaskContext } from "lib/contexts/TaskContext";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";

interface DateFieldProps extends TaskFieldPropsGeneric {
  propertyType: TaskPropertyClass;
  isFinal?: boolean;
  dateFormat?: TaskPropertyDateTimeFormat;
}

const DateField: FC<DateFieldProps> = (props) => {
  const { component } = props;
  const { board } = useBoardContext();
  const { task } = useTaskContext();

  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);
  const isSyncedBoard = !!board?.externalDataSource || !!task?.board?.externalDataSource;

  const editDisabled = (
    !allowedToEdit ||
    ((props.propertyType === TaskPropertyClass.Default) && isSyncedBoard)
  );

  switch (component) {
    case TaskSourceComponent.Card:
      return <DateGeneric {...props} />;
    case TaskSourceComponent.Cell:
      return (
        <DateListWrapper clickable={!editDisabled}>
          <DateGeneric {...props} />
        </DateListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <DateDetailedWrapper clickable={!editDisabled}>
          <DateGeneric {...props} />
        </DateDetailedWrapper>
      );
    default:
      return null;
  }
};

export default DateField;
