import { FC, useState } from "react";
import TaskModalCreate from "../TaskModals/TaskModalCreate";
import ButtonIcon from "components/ui/ButtonIcon";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import useWorkspaceById from "lib/customHooks/useWorkspaceById";
import { useBoardContext } from "lib/contexts/BoardContext";
import { CreateFlowFormSource } from "../TaskModals/CreateFlowForm/lib";
import { useProjectContext } from "lib/contexts/ProjectContext";
import AddTaskModal from "../AddTaskModal";

interface AddRecordBlockProps {
  type: CreateFlowFormSource;
}

const AddRecordBlock: FC<AddRecordBlockProps> = (props) => {
  const { type } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { board } = useBoardContext();
  const { terminology } = useBoardFeatures(board);
  const { workspace: fallbackWorkspace } = useProjectContext();
  const workspace = useWorkspaceById(board?.workspace?.id || null) ?? fallbackWorkspace;

  return (
    <>
      <ButtonIcon
        text={`Add ${terminology.single.toLowerCase()}`}
        onClick={() => setIsModalVisible(!isModalVisible)}
      />

      {isModalVisible &&
        <>
          {type === "mytasks"
            ? <AddTaskModal
              visible={isModalVisible}
              onClose={() => setIsModalVisible(false)}
            />
            : <TaskModalCreate
              currentBoard={board?.id ?? null}
              visible={isModalVisible}
              onClose={() => setIsModalVisible(false)}
              workspace={workspace}
              type={type}
            />
          }
        </>
      }
    </>
  );
};

export default AddRecordBlock;
