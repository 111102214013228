import styled from "@emotion/styled/macro";

export const CollabWrapper = styled.div`
  margin: 0 10px 10px 10px;
`;

export const CollabBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between
`;

export const CollabText = styled.div`
  color: #999;
  font-size: 12px;
  font-weight: 600;
  margin-right: 7px;
`;