import { useBoardContext } from "lib/contexts/BoardContext";
import { BoardViewProvider } from "lib/contexts/BoardViewContext";
import useBoardView from "lib/customHooks/views/useBoardView";
import { getBoardViewUri } from "lib/helpers/navigationUtils";
import { DisplayView } from "lib/types/applicationTypes";
import BoardForm from "pages/Board/BoardForm";
import { FC, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import BoardCalendar from "../BoardCalendar";
import BoardFormFallback from "../BoardForm/BoardFormFallback";
import BoardKanban from "../BoardKanban";
import BoardList from "../BoardList";
import { BoardDefaultDisplayView } from "../BoardSettings/BoardSettingsDisplayView/lib";
import BoardTasksRenderer from "../BoardTasksRenderer";
import BoardViewNotFound from "./BoardViewNotFound";
import BoardFiles from "../BoardFiles";

const BoardView: FC = () => {
  const params = useParams<{ id: string, view: string }>();
  const { loading, board } = useBoardContext();
  const history = useHistory();
  const view = useBoardView();

  const isCustomView = !Object.values(DisplayView).includes(params.view as DisplayView);
  const displayView = isCustomView ? (view?.view || DisplayView.List) : (params.view as DisplayView);
  const displayViews = board?.displayView || BoardDefaultDisplayView;

  // @ts-ignore
  const isViewDisabled = !displayViews[displayView];

  const handleRedirectToList = () => {
    const target = getBoardViewUri(params.id, DisplayView.List);
    history.push(target);
  };

  useEffect(() => {
    if (isViewDisabled) {
      handleRedirectToList();
    }
  }, [isViewDisabled]);

  const renderBoardView = useCallback(() => {
    switch (params.view) {
      case DisplayView.List:
        return (<BoardList />);
      case DisplayView.Kanban:
        return (<BoardKanban />);
      case DisplayView.Calendar:
        return (<BoardCalendar />);
      case DisplayView.Form:
        return (<BoardFormFallback />);
      case DisplayView.Files:
        return (<BoardFiles />);

      default: {
        switch (view?.view) {
          case DisplayView.List:
            return (<BoardList key={view.uid} />);
          case DisplayView.Kanban:
            return (<BoardKanban key={view.uid} />);
          case DisplayView.Calendar:
            return (<BoardCalendar key={view.uid} />);
          case DisplayView.Form:
            return (<BoardForm key={view.uid} />);
          case DisplayView.Files:
            return (<BoardFiles key={view.uid} />);
          default:
            return (<BoardViewNotFound />);
        }
      }
    }
  }, [params.view, view?.uid, view?.view]);

  // TODO: remove render function, replace with a lookup component: <BoardComponent key={renderKey} />
  const renderKey = (view?.view || params.view);
  const renderLookup = {
    [DisplayView.List]: BoardList,
    [DisplayView.Kanban]: BoardKanban,
    [DisplayView.Calendar]: BoardCalendar,
    [DisplayView.Form]: (view?.uid) ? BoardForm : BoardFormFallback,
  };

  const BoardComponent = renderLookup[renderKey as never] || BoardViewNotFound;

  return (
    <BoardViewProvider
      view={params.view}
      displayView={displayView}
      isCustomView={isCustomView}
      viewData={view}
    >
      <BoardTasksRenderer
        renderBoardFn={renderBoardView}
        loading={loading}
        displayView={displayView}
        useTasksProvider
      />
    </BoardViewProvider>
  );
};

export default BoardView;
