import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const PngSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.5584 20.5167C64.2805 20.5145 62.0963 19.6086 60.4855 17.9978C58.8746 16.387 57.9688 14.203 57.9667 11.925V0H19.575C16.6013 0 13.7494 1.18132 11.6466 3.28407C9.54387 5.38683 8.36255 8.23875 8.36255 11.2125V78.8042C8.36697 81.775 9.55023 84.6225 11.6525 86.7217C13.7548 88.8208 16.6042 90 19.575 90H65.6625C68.6342 90 71.4842 88.8204 73.5867 86.7204C75.6888 84.6204 76.8717 81.7717 76.875 78.8V20.5167H66.5584Z" fill="#005FAD"/>
        <path d="M76.875 20.5167H66.5583C64.2804 20.5145 62.0963 19.6086 60.4854 17.9978C58.8746 16.387 57.9688 14.203 57.9667 11.925V0L76.875 20.5167Z" fill="#005FAD"/>
        <path d="M85.0042 71.0834H32.9042C29.2085 71.0834 26.2125 74.0792 26.2125 77.775V93.3084C26.2125 97.0042 29.2085 100 32.9042 100H85.0042C88.7 100 91.6959 97.0042 91.6959 93.3084V77.775C91.6959 74.0792 88.7 71.0834 85.0042 71.0834Z" fill="#005FAD"/>
        <path d="M40.0845 93.75C39.4895 93.75 39.192 93.4525 39.192 92.8575V80.235C39.192 79.64 39.4895 79.3425 40.0845 79.3425H45.057C46.3533 79.3425 47.3449 79.6896 48.032 80.3838C48.7262 81.0709 49.0733 82.0625 49.0733 83.3588V83.9963C49.0733 85.2925 48.7262 86.2879 48.032 86.9821C47.3449 87.6692 46.3533 88.0125 45.057 88.0125H40.977V92.8575C40.977 93.4525 40.6795 93.75 40.0845 93.75ZM40.977 86.2275H45.057C45.8645 86.2275 46.4383 86.0575 46.7783 85.7175C47.1183 85.3775 47.2883 84.8038 47.2883 83.9963V83.3588C47.2883 82.5584 47.1183 81.9884 46.7783 81.6484C46.4383 81.3013 45.8645 81.1275 45.057 81.1275H40.977V86.2275Z" fill="white"/>
        <path d="M53.1166 93.75C52.5216 93.75 52.2241 93.4525 52.2241 92.8575V80.235C52.2241 79.64 52.5216 79.3425 53.1166 79.3425C53.4212 79.3425 53.7187 79.5196 54.0091 79.8738L62.5516 90.8813H62.1054V80.235C62.1054 79.64 62.4029 79.3425 62.9979 79.3425C63.5929 79.3425 63.8904 79.64 63.8904 80.235V92.8575C63.8904 93.4525 63.5929 93.75 62.9979 93.75C62.6791 93.75 62.3816 93.573 62.1054 93.2188L53.5629 82.2113H54.0091V92.8575C54.0091 93.4525 53.7116 93.75 53.1166 93.75Z" fill="white"/>
        <path d="M72.5571 93.75C70.935 93.75 69.7342 93.3642 68.955 92.5921C68.183 91.813 67.7971 90.6121 67.7971 88.99V84.1025C67.7971 82.4663 68.183 81.2621 68.955 80.49C69.7271 79.7109 70.9209 79.3284 72.5359 79.3425H75.1071C76.4388 79.3425 77.455 79.6188 78.1563 80.1713C78.8575 80.7167 79.265 81.5809 79.3784 82.7638C79.4209 83.0613 79.3642 83.288 79.2084 83.4438C79.0525 83.5996 78.8259 83.6775 78.5284 83.6775C77.99 83.6775 77.6784 83.38 77.5934 82.785C77.5225 82.1334 77.2959 81.6942 76.9134 81.4675C76.538 81.2409 75.9359 81.1275 75.1071 81.1275H72.5359C71.785 81.1205 71.1934 81.2055 70.7613 81.3825C70.3363 81.5596 70.0317 81.868 69.8475 82.3071C69.6705 82.7392 69.5821 83.3375 69.5821 84.1025V88.99C69.5821 89.748 69.6705 90.343 69.8475 90.775C70.0317 91.2071 70.34 91.5155 70.7721 91.6996C71.2042 91.8767 71.7992 91.965 72.5571 91.965H75.1071C75.7659 91.965 76.2792 91.8942 76.6475 91.7525C77.023 91.6038 77.285 91.3455 77.4338 90.9771C77.5825 90.6088 77.6571 90.088 77.6571 89.415V88.4163L75.9996 88.395C75.4046 88.395 75.1071 88.0975 75.1071 87.5025C75.1071 86.9075 75.4046 86.61 75.9996 86.61L78.5496 86.6313C79.1446 86.6313 79.4421 86.9288 79.4421 87.5238V89.415C79.4421 90.9238 79.0913 92.0255 78.39 92.7196C77.6888 93.4067 76.5946 93.75 75.1071 93.75H72.5571Z" fill="white"/>
        <path d="M30.3209 27.8H26.2584C25.7981 27.8 25.425 28.1731 25.425 28.6334V32.6959C25.425 33.1561 25.7981 33.5292 26.2584 33.5292H30.3209C30.7811 33.5292 31.1542 33.1561 31.1542 32.6959V28.6334C31.1542 28.1731 30.7811 27.8 30.3209 27.8Z" fill="#005FAD"/>
        <path d="M36.0542 33.5292H31.9917C31.5315 33.5292 31.1583 33.9023 31.1583 34.3625V38.425C31.1583 38.8852 31.5315 39.2583 31.9917 39.2583H36.0542C36.5144 39.2583 36.8875 38.8852 36.8875 38.425V34.3625C36.8875 33.9023 36.5144 33.5292 36.0542 33.5292Z" fill="#005FAD"/>
        <path d="M41.7834 27.8H37.7209C37.2607 27.8 36.8876 28.1731 36.8876 28.6334V32.6959C36.8876 33.1561 37.2607 33.5292 37.7209 33.5292H41.7834C42.2437 33.5292 42.6167 33.1561 42.6167 32.6959V28.6334C42.6167 28.1731 42.2437 27.8 41.7834 27.8Z" fill="#005FAD"/>
        <path d="M47.5126 33.5292H43.45C42.9898 33.5292 42.6167 33.9023 42.6167 34.3625V38.425C42.6167 38.8852 42.9898 39.2583 43.45 39.2583H47.5126C47.9728 39.2583 48.3459 38.8852 48.3459 38.425V34.3625C48.3459 33.9023 47.9728 33.5292 47.5126 33.5292Z" fill="#005FAD"/>
        <path d="M53.2501 27.8H49.1876C48.7273 27.8 48.3542 28.1731 48.3542 28.6334V32.6959C48.3542 33.1561 48.7273 33.5292 49.1876 33.5292H53.2501C53.7105 33.5292 54.0834 33.1561 54.0834 32.6959V28.6334C54.0834 28.1731 53.7105 27.8 53.2501 27.8Z" fill="#005FAD"/>
        <path d="M58.9792 33.5292H54.9167C54.4563 33.5292 54.0834 33.9023 54.0834 34.3625V38.425C54.0834 38.8852 54.4563 39.2583 54.9167 39.2583H58.9792C59.4396 39.2583 59.8125 38.8852 59.8125 38.425V34.3625C59.8125 33.9023 59.4396 33.5292 58.9792 33.5292Z" fill="#005FAD"/>
        <path d="M30.3209 39.25H26.2584C25.7981 39.25 25.425 39.6231 25.425 40.0833V44.1458C25.425 44.6063 25.7981 44.9792 26.2584 44.9792H30.3209C30.7811 44.9792 31.1542 44.6063 31.1542 44.1458V40.0833C31.1542 39.6231 30.7811 39.25 30.3209 39.25Z" fill="#005FAD"/>
        <path d="M36.0542 44.9792H31.9917C31.5315 44.9792 31.1583 45.3522 31.1583 45.8126V49.8751C31.1583 50.3355 31.5315 50.7084 31.9917 50.7084H36.0542C36.5144 50.7084 36.8875 50.3355 36.8875 49.8751V45.8126C36.8875 45.3522 36.5144 44.9792 36.0542 44.9792Z" fill="#005FAD"/>
        <path d="M41.7834 39.25H37.7209C37.2607 39.25 36.8876 39.6231 36.8876 40.0833V44.1458C36.8876 44.6063 37.2607 44.9792 37.7209 44.9792H41.7834C42.2437 44.9792 42.6167 44.6063 42.6167 44.1458V40.0833C42.6167 39.6231 42.2437 39.25 41.7834 39.25Z" fill="#005FAD"/>
        <path d="M47.5126 44.9792H43.45C42.9898 44.9792 42.6167 45.3522 42.6167 45.8126V49.8751C42.6167 50.3355 42.9898 50.7084 43.45 50.7084H47.5126C47.9728 50.7084 48.3459 50.3355 48.3459 49.8751V45.8126C48.3459 45.3522 47.9728 44.9792 47.5126 44.9792Z" fill="#005FAD"/>
        <path d="M53.2501 39.25H49.1876C48.7273 39.25 48.3542 39.6231 48.3542 40.0833V44.1458C48.3542 44.6063 48.7273 44.9792 49.1876 44.9792H53.2501C53.7105 44.9792 54.0834 44.6063 54.0834 44.1458V40.0833C54.0834 39.6231 53.7105 39.25 53.2501 39.25Z" fill="#005FAD"/>
        <path d="M58.9792 44.9792H54.9167C54.4563 44.9792 54.0834 45.3522 54.0834 45.8126V49.8751C54.0834 50.3355 54.4563 50.7084 54.9167 50.7084H58.9792C59.4396 50.7084 59.8125 50.3355 59.8125 49.8751V45.8126C59.8125 45.3522 59.4396 44.9792 58.9792 44.9792Z" fill="#005FAD"/>
        <path d="M30.3209 50.7542H26.2584C25.7981 50.7542 25.425 51.1271 25.425 51.5875V55.65C25.425 56.1104 25.7981 56.4833 26.2584 56.4833H30.3209C30.7811 56.4833 31.1542 56.1104 31.1542 55.65V51.5875C31.1542 51.1271 30.7811 50.7542 30.3209 50.7542Z" fill="#005FAD"/>
        <path d="M36.0542 56.4834H31.9917C31.5315 56.4834 31.1583 56.8563 31.1583 57.3167V61.3792C31.1583 61.8396 31.5315 62.2126 31.9917 62.2126H36.0542C36.5144 62.2126 36.8875 61.8396 36.8875 61.3792V57.3167C36.8875 56.8563 36.5144 56.4834 36.0542 56.4834Z" fill="#005FAD"/>
        <path d="M41.7834 50.7542H37.7209C37.2607 50.7542 36.8876 51.1271 36.8876 51.5875V55.65C36.8876 56.1104 37.2607 56.4833 37.7209 56.4833H41.7834C42.2437 56.4833 42.6167 56.1104 42.6167 55.65V51.5875C42.6167 51.1271 42.2437 50.7542 41.7834 50.7542Z" fill="#005FAD"/>
        <path d="M47.5126 56.4834H43.45C42.9898 56.4834 42.6167 56.8563 42.6167 57.3167V61.3792C42.6167 61.8396 42.9898 62.2126 43.45 62.2126H47.5126C47.9728 62.2126 48.3459 61.8396 48.3459 61.3792V57.3167C48.3459 56.8563 47.9728 56.4834 47.5126 56.4834Z" fill="#005FAD"/>
        <path d="M53.2501 50.7542H49.1876C48.7273 50.7542 48.3542 51.1271 48.3542 51.5875V55.65C48.3542 56.1104 48.7273 56.4833 49.1876 56.4833H53.2501C53.7105 56.4833 54.0834 56.1104 54.0834 55.65V51.5875C54.0834 51.1271 53.7105 50.7542 53.2501 50.7542Z" fill="#005FAD"/>
        <path d="M58.9792 56.4834H54.9167C54.4563 56.4834 54.0834 56.8563 54.0834 57.3167V61.3792C54.0834 61.8396 54.4563 62.2126 54.9167 62.2126H58.9792C59.4396 62.2126 59.8125 61.8396 59.8125 61.3792V57.3167C59.8125 56.8563 59.4396 56.4834 58.9792 56.4834Z" fill="#005FAD"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default PngSvg;
