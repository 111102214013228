import styled from "@emotion/styled/macro";

export const FormPublicFieldContainer = styled.div`
  margin-bottom: 20px;
`;

export const FormPublicFieldTitleContainer = styled.div`
  font-size: 14px;
  margin-bottom: 6px;
`;

export const FormPublicFieldTitle = styled.span`
  margin-right: 4px;
`;

export const FormPublicFieldTitleAsterisk = styled.span`
  font-size: 12px;
  color: #ff4d4f;
`;
