import { FC } from "react";
import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import { TaskPropertyOption } from "lib/types/kanbanTypes";

import { MultiSelectListWrapper, MultiSelectDetailedWrapper } from "./Display/styled";
import MultiSelectGeneric from "./Display/generic";

interface MultiSelectFieldProps extends TaskFieldPropsGeneric {
  options?: Array<TaskPropertyOption>;
}

const MultiSelectField: FC<MultiSelectFieldProps> = (props) => {
  const { value, component, options } = props;
  const currentOptions = (options ?? [])?.filter(option => value?.includes(option.id));

  switch (component) {
    case TaskSourceComponent.Card:
      return null;
    case TaskSourceComponent.Cell:
      return (
        <MultiSelectListWrapper>
          <MultiSelectGeneric {...props} selectedOptions={currentOptions} />
        </MultiSelectListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <MultiSelectDetailedWrapper>
          <MultiSelectGeneric {...props} selectedOptions={currentOptions} />
        </MultiSelectDetailedWrapper>
      );
    default:
      return null;
  }
};

export default MultiSelectField;
