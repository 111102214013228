import { TAnyFilters } from "../../filters/filters";
import { TasksSortType } from "../../sort/tasksSort";
import useBoardViewFilter from "./useBoardViewFilter";
import useBoardViewGeneralFilter from "./useBoardViewGeneralFilter";
import useBoardViewSort from "./useBoardViewSort";

type TUseBoardViewSortAndFilters = () => {
  currentGeneralFilter: string
  currentFilter: TAnyFilters
  currentSort: TasksSortType
};

const useBoardViewSortAndFilters: TUseBoardViewSortAndFilters = () => {
  const currentGeneralFilter = useBoardViewGeneralFilter();
  const currentFilter = useBoardViewFilter();
  const currentSort = useBoardViewSort();

  return {
    currentGeneralFilter,
    currentFilter,
    currentSort
  };
};

export default useBoardViewSortAndFilters;
