import { useAppSelector } from "app/store";
import TaskTable from "components/blocks/TaskTable";
import Skeleton from "components/ui/Skeletons/Table";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import { useTasksContext } from "lib/contexts/TasksContext";
import useCustomColumnsFilters from "lib/customHooks/filters/useCustomColumnsFilters";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import useBoardViewColumns from "lib/customHooks/views/useBoardViewColumns";
import useBoardViewSortAndFilters from "lib/customHooks/views/useBoardViewSortAndFilters";
import { listFilters } from "lib/filters/filters";
import { getSortFields } from "lib/sort/tasksSort";
import { Column } from "lib/types/tasksTypes";
import { MemberRoles } from "lib/types/types";
import SecondaryBar from "pages/Board/SecondaryBar";
import { FC } from "react";

import { PageContainer } from "styles/common";
import { BoardTableScrollable, BoardTableWrapper } from "./styled";

const BoardList: FC = () => {
  const { board } = useBoardContext() as BoardContextInterface;
  const { tasks } = useTasksContext();

  const isLoading = useAppSelector(state => state.tasks.isLoading);
  const defaultColumns = board.columns || [];

  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const allowedToCreate = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const { currentFilter, currentSort } = useBoardViewSortAndFilters();
  const { appliedColumns } = useBoardViewColumns(board.id || "", defaultColumns);
  const { allFilters } = useCustomColumnsFilters(listFilters);

  const columns: Column[] = [...appliedColumns];

  return (
    <>
      <SecondaryBar
        filters={allFilters}
        sortFields={getSortFields(board)}
        hideAutomations={!allowedToEdit}
        hideAddRecord={!allowedToCreate}
        showHideFields={allowedToEdit}
        type="board"
      />
      <BoardTableScrollable>
        <PageContainer>
          <BoardTableWrapper>
            {isLoading && <Skeleton />}
            {!isLoading &&
              <TaskTable
                addRow
                isResize={allowedToEdit}
                columns={columns}
                data={tasks}
                boardId={board.id}
                isAddColumn
                currentSort={currentSort}
                currentFilter={currentFilter}
                wrapTaskProvider
              />
            }
          </BoardTableWrapper>
        </PageContainer>
      </BoardTableScrollable>
    </>
  );
};

export default BoardList;
