import styled from "@emotion/styled/macro";
import { Input } from "antd";
import { squareWrapperCss, styledInputCss } from "styles/common-css";
import { dataInput } from "components/blocks/TaskTable/styled";
import { fieldDetailedInput } from "../../styled";

export const MultiLineOpenButton = styled.div`
  ${squareWrapperCss};
  margin-left: 5px;
  opacity: 0;
`;

export const MultiLineListWrapper = styled.div`
  ${dataInput};
  white-space: nowrap;
  &:hover {
    ${MultiLineOpenButton} {
      opacity: 1;
    }
  }
`;

export const MultiLineDetailedWrapper = styled.div`
  ${fieldDetailedInput};
  &:hover {
    ${MultiLineOpenButton} {
      opacity: 1;
    }
  }
`;

export const MultiLineListInput = styled(Input)`
  ${styledInputCss}
`;
