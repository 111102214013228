import { FC } from "react";
import IconSvgWrapper from "components/icons/IconSvgWrapper";
import { IconProps } from "components/icons/lib";

const DotSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M8.5 6C8.5 7.38071 7.38071 8.5 6 8.5C4.61929 8.5 3.5 7.38071 3.5 6C3.5 4.61929 4.61929 3.5 6 3.5C7.38071 3.5 8.5 4.61929 8.5 6Z"/>
    </IconSvgWrapper>
  );
};

export default DotSvg;
