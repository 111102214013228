import styled from "@emotion/styled/macro";

export const TaskDetailedRow = styled.div<{ alignment?: boolean }>`
  display: flex;
  height: ${({ alignment }) => alignment ? "start" : "center"};
  margin: 7px 0;
`;

export const TaskDetailedHeader = styled.div`
  width: 120px;
  margin-right: 15px;
  overflow: hidden;
  flex-shrink: 0;
`;

export const TaskDetailedContent = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;
  width: 100%;
`;
