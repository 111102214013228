import { FC, useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd";
import Scrollbars from "react-custom-scrollbars-2";
import { Form } from "antd";
import { isEqual, orderBy } from "lodash";

import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import useBoardView from "lib/customHooks/views/useBoardView";
import { Column } from "lib/types/tasksTypes";
import { BoardViewAppliedColumn, BoardViewInterface, DefaultColumnCodes } from "lib/types/boardTypes";
import { PropertyValueType } from "lib/types/dataTypes";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import { SecondaryBarContainer } from "pages/Board/SecondaryBar/styled";
import BoardFormHiddenField from "pages/Board/BoardForm/BoardFormHiddenField";
import BoardFormDisplayedField from "pages/Board/BoardForm/BoardFormDisplayedField";
import FormPublicDropdown from "pages/FormPublic/FormPublicDropdown";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import CustomStyledButton from "components/blocks/CustomStyledButton";

import {
  BoardFormBody,
  BoardFormBodyContainer,
  BoardFormBodyDescription,
  BoardFormBodyEmptyState,
  BoardFormBodyFields,
  BoardFormBodyHeader,
  BoardFormBodyTitle,
  BoardFormFieldsContainer, BoardFormFieldsInnerContainer,
  BoardFormFieldsSpan,
  BoardFormPageContainer
} from "pages/Board/BoardForm/styled";
import useBoardSetViews from "lib/customHooks/dataLayer/board/useBoardSetViews";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

const BoardForm: FC = () => {
  const { board } = useBoardContext() as BoardContextInterface;
  const { updateBoardView } = useBoardSetViews({ board, updateDetailed: true });
  const { excludedColumns, insertColumns } = useBoardFeatures(board);

  const view = useBoardView() as BoardViewInterface;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);

  const appliedIds = view?.appliedColumns?.map(col => col.id) || [];
  const excludedTypes = [
    PropertyValueType.Viewpoint,
    PropertyValueType.Viewpoints,
    PropertyValueType.Annotations,
  ];

  const initialStateLeft = (board?.columns || [])
    .concat(insertColumns)
    .filter(column => (column.class !== TaskPropertyClass.System))
    .filter(column => !appliedIds.includes(column.id))
    .filter(column => !excludedTypes.includes(column.type as PropertyValueType))
    .filter(column => !excludedColumns.includes(column.code as DefaultColumnCodes));

  const initialStateRight = (view?.appliedColumns || []);

  const [leftPaneFields, setLeftPaneFields] = useState(initialStateLeft);
  const [rightPaneFields, setRightPaneFields] = useState(initialStateRight);
  const [form] = Form.useForm();

  const options = orderBy(leftPaneFields, field => field.sort);

  const setModified = useCallback(() => {
    const nextValues: Partial<BoardViewInterface> = {
      title: form.getFieldValue("formTitle"),
      description: form.getFieldValue("formDescription"),
      appliedColumns: rightPaneFields,
    };

    const prevValues: Partial<BoardViewInterface> = {
      title: view.title,
      description: view.description,
      appliedColumns: view.appliedColumns,
    };

    const isModified = !isEqual(nextValues, prevValues);
    if (isModified) {
      const payload: Partial<BoardViewInterface> = {
        title: form.getFieldValue("formTitle")?.trim(),
        description: form.getFieldValue("formDescription")?.trim(),
        appliedColumns: rightPaneFields,
      };

      updateBoardView(view.id, payload);
    }
  }, [form, rightPaneFields, view.appliedColumns, view.description, view.id, view.title]);

  useEffect(() => {
    setModified();
  }, [rightPaneFields, setModified]);

  useEffect(() => {
    if (view.title !== form.getFieldValue("formTitle")) {
      form.setFieldsValue({ name: "title", value: view.title });
    }

    if (view.description !== form.getFieldValue("formDescription")) {
      form.setFieldsValue({ name: "description", value: view.description });
    }
  }, [view.title, view.description]);

  const handleLeftPaneFieldOnClick = (column: Column) => {
    if (!allowedToEdit) {
      return;
    }
    const nextStateLeft = leftPaneFields.filter(col => col.id !== column.id);

    const newFieldRight: BoardViewAppliedColumn = {
      id: column.id,
      title: column.title,
      sort: rightPaneFields.length + 1,
      hidden: false,
      required: false,
    };
    const nextStateRight = [...rightPaneFields, newFieldRight];

    setLeftPaneFields(nextStateLeft);
    setRightPaneFields(nextStateRight);
  };

  const handleRightPaneFieldOnClick = (column: BoardViewAppliedColumn) => {
    const nextStateRight = rightPaneFields.filter(col => col.id !== column.id);

    const newFieldLeft = board?.columns.find(col => col.id === column.id);
    const nextStateLeft = [...leftPaneFields, ...(newFieldLeft ? [newFieldLeft] : []),];

    setLeftPaneFields(nextStateLeft);
    setRightPaneFields(nextStateRight);
  };

  const handleRightPaneFieldOnChange = (params: Partial<BoardViewAppliedColumn>) => {
    const findIndex = rightPaneFields.findIndex(col => col.id === params.id);
    if (findIndex !== -1) {
      const nextStateRight = [...rightPaneFields];
      nextStateRight[findIndex] = { ...nextStateRight[findIndex], ...params };
      setRightPaneFields(nextStateRight);
    }
  };

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { source, destination } = result;

    if ((!destination) || (result.destination?.index === result.source.index)) {
      return;
    }

    let nextStateRight = [...rightPaneFields];
    const [movedRow] = nextStateRight.splice(source.index, 1);
    nextStateRight.splice(destination.index, 0, movedRow);
    nextStateRight = nextStateRight.map((col, index) => ({ ...col, sort: index }));
    setRightPaneFields(nextStateRight);
  };

  return (
    <Form
      form={form}
      initialValues={{ formTitle: view.title, formDescription: view.description }}
      onFieldsChange={setModified}
      style={{ minHeight: "100%" }}
    >
      <SecondaryBarContainer>
        <div style={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
          <FormPublicDropdown />
        </div>
      </SecondaryBarContainer>
      <BoardFormPageContainer>
        <BoardFormFieldsContainer>
          <Scrollbars autoHide>
            <BoardFormFieldsInnerContainer>
              <BoardFormFieldsSpan>Fields</BoardFormFieldsSpan>
              {options.map(column => (
                <BoardFormHiddenField
                  key={column.id}
                  column={column}
                  onClick={() => handleLeftPaneFieldOnClick(column)}
                />
              ))}
              {!options.length &&
                <span style={{ color: "#bfbfbf" }}>All fields are already in use</span>
              }
            </BoardFormFieldsInnerContainer>
          </Scrollbars>
        </BoardFormFieldsContainer>
        <BoardFormBodyContainer>
          <Scrollbars autoHide>
            <BoardFormBody>
              <BoardFormBodyHeader>
                <Form.Item name="formTitle" rules={[{ required: true }]}>
                  <BoardFormBodyTitle
                    placeholder="Form name"
                    size="large"
                    autoSize={{ minRows: 1, maxRows: 4 }}
                    readOnly={!allowedToEdit}
                  />
                </Form.Item>
                <Form.Item name="formDescription">
                  <BoardFormBodyDescription
                    placeholder="Add a description for this form"
                    autoSize={{ minRows: 1, maxRows: 4 }}
                    readOnly={!allowedToEdit}
                  />
                </Form.Item>
              </BoardFormBodyHeader>
              <BoardFormBodyFields>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="form-items-list">
                    {provided => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {rightPaneFields.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={String(item.id)}
                            index={index}
                            isDragDisabled={!allowedToEdit}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <BoardFormDisplayedField
                                  key={item.id}
                                  column={item}
                                  onDelete={() => handleRightPaneFieldOnClick(item)}
                                  onChange={handleRightPaneFieldOnChange}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {!rightPaneFields.length &&
                          <BoardFormBodyEmptyState>
                            Add fields to this form
                          </BoardFormBodyEmptyState>
                        }
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <div style={{ marginTop: 20 }}>
                  <CustomStyledButton btnColor="purple" value="Submit" disabled />
                </div>
              </BoardFormBodyFields>
            </BoardFormBody>
          </Scrollbars>
        </BoardFormBodyContainer>
      </BoardFormPageContainer>
    </Form>
  );
};

export default BoardForm;
