import { ForgeMarkup2dDefaultLayerId, ForgeMarkup2dEditorExtension } from "components/blocks/ForgeViewer/extensions/Airtasks/Markup2dEditor";
import { ForgeMarkupCoreExtension } from "components/blocks/ForgeViewer/extensions/Autodesk/MarkupCore";
import { ForgeViewerSectionExtension, ForgeViewerSectionMode } from "components/blocks/ForgeViewer/extensions/Autodesk/Section";
import { ForgeViewerEvent, ForgeViewerState } from "lib/types/ForgeViewer";
import { PictureInterface } from "lib/types/types";
import { captureException } from "lib/utils/sentry";
import { compressBlob, imageToBlob } from "pages/Viewer/ViewerTasks/TaskItem/imageconversion";
import ForgeService from "services/ForgeService";

export const asyncGetScreenShot = async (viewer: Autodesk.Viewing.Viewer3D, maxSize = 2_000): Promise<any> => {
  const { markupCore } = getMarkupExtensions(viewer);
  const { clientWidth, clientHeight } = viewer.container;

  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const image = new Image();

    canvas.width = clientWidth;
    canvas.height = clientHeight;

    const context = canvas.getContext("2d");

    const renderCanvasToBlob = async () => {
      const blob = await imageToBlob(canvas.toDataURL("image/png"));
      if (blob) {
        const compressedBlob = compressBlob(blob);
        resolve(compressedBlob);
      } else {
        reject(new Error("Can not create blob"));
      }
    };

    image.onload = () => {
      if (!context) {
        return;
      }

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      if (markupCore?.duringEditMode) {
        markupCore.renderToCanvas(context, renderCanvasToBlob);
      } else {
        renderCanvasToBlob();
      }
    };

    viewer.getScreenShot(clientWidth, clientHeight, (blobUrl: string) => image.src = blobUrl);
  });
};

export const getViewpointPicture = (picture: PictureInterface | null | undefined) => {
  const src = picture?.previewUrl
    || picture?.formats?.thumbnail.url
    || picture?.formats?.small?.url
    || picture?.url
    || null;

  return src;
};

export const saveViewerState = (viewer: Autodesk.Viewing.Viewer3D | null) => {
  const viewpoint: ForgeViewerState = viewer?.getState();
  const sectionExt = viewer?.getExtension("Autodesk.Section") as ForgeViewerSectionExtension;
  const { markupCore } = getMarkupExtensions(viewer);

  try {
    if (sectionExt?.activeStatus) {
      viewpoint.sectionMode = sectionExt?.getSectionStyle();
    }

    if (markupCore?.duringEditMode) {
      const data = markupCore?.generateData();
      viewpoint.markup2d = { data };
    }
  } catch (error) {
    captureException(error);
  }

  return viewpoint;
};

export const restoreSectionBox = (viewer: Autodesk.Viewing.Viewer3D | null, cutplanes: Array<THREE.Vector4>) => {
  const box = new THREE.Box3();
  for (const cutplane of cutplanes) {
    const normal = new THREE.Vector3(cutplane.x, cutplane.y, cutplane.z);
    const offset = cutplane.w;
    const pointOnPlane = normal.negate().multiplyScalar(offset);
    box.expandByPoint(pointOnPlane);
  }
  const sectionExt = viewer?.getExtension("Autodesk.Section") as ForgeViewerSectionExtension;
  sectionExt?.setSectionBox(box);
};

export const restoreViewerState = async (viewer: Autodesk.Viewing.Viewer3D | null, viewpoint: ForgeViewerState | string, filter?: any, waitUntilTransitionEnded = false) => {
  const sectionExt = viewer?.getExtension("Autodesk.Section") as ForgeViewerSectionExtension;
  const { markupCore, markupGui } = getMarkupExtensions(viewer);

  // Disable Section if any applied
  sectionExt?.deactivate();

  // Disable Markups if any applied
  markupCore?.hide();
  markupGui?.deactivate();

  if (typeof viewpoint === "string") {
    viewpoint = JSON.parse(viewpoint) as ForgeViewerState;
  }

  const state: ForgeViewerState = {
    seedURN: viewpoint.seedURN,
    objectSet: viewpoint.objectSet,
    viewport: viewpoint.viewport,
    renderOptions: viewpoint.renderOptions,
  };

  if (viewer && waitUntilTransitionEnded) {
    await restoreViewerPromise(viewer, state);
  }

  if (viewer && !waitUntilTransitionEnded) {
    viewer.restoreState(state);
  }

  if (viewpoint.cutplanes) {
    const cutplanes = viewpoint.cutplanes.map(cutplane => new THREE.Vector4().fromArray(cutplane));
    const sectionMode = viewpoint.sectionMode?.toLowerCase() as ForgeViewerSectionMode;

    viewer?.setCutPlanes(cutplanes);

    if (sectionExt?.buttons[sectionMode]) {
      sectionExt?.buttons[sectionMode].setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
      sectionExt?.buttons[sectionMode].setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
    }
  }

  if (!viewpoint.objectSet?.length) {
    viewer?.isolate();
  }

  if (markupCore) {
    if (viewpoint.markup2d?.data) {
      markupCore.unloadMarkupsAllLayers();
      markupCore.show();
      markupCore.loadMarkups(viewpoint.markup2d.data, ForgeMarkup2dDefaultLayerId);
    }
  }

};

const restoreViewerPromise = async (viewer: Autodesk.Viewing.Viewer3D, viewerState: ForgeViewerState) => {
  return new Promise<void>((resolve) => {
    const listener = (event: ForgeViewerEvent) => {
      if (event.value.finalFrame) {
        viewer.removeEventListener(
          Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
          listener
        );
        resolve();
      }
    };

    // Wait for last render caused by camera changes
    viewer.addEventListener(
      Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
      listener
    );

    viewer.restoreState(viewerState);
  });
};

export const restoreViewerNodePromise = async (viewer: Autodesk.Viewing.Viewer3D, node: Autodesk.Viewing.BubbleNode) => {
  return new Promise<void>((resolve) => {
    const listener = (event: ForgeViewerEvent) => {
      if (event.value.finalFrame) {
        viewer.removeEventListener(
          Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
          listener
        );
        resolve();
      }
    };

    // Wait for last render caused by camera changes
    viewer.addEventListener(
      Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
      listener
    );

    try {
      const resCode = viewer?.setView(node, {});
    } catch (error) {
      captureException(error);
    }
  });
};

export const getMarkupExtensions = (viewer: Autodesk.Viewing.Viewer3D | null) => {
  const markupCore = viewer?.getExtension("Autodesk.Viewing.MarkupsCore") as ForgeMarkupCoreExtension | null;
  const markupGui = viewer?.getExtension("Airtasks.Markup2dEditor") as ForgeMarkup2dEditorExtension | null;

  return { markupCore, markupGui };
};

/**
 * Generates access token for viewing models in the Model Derivative service.
 */
export function getForgeViewerAccessToken(callback?: (accessToken: string, expires: number) => void) {
  ForgeService.getToken()
    .then(response => {
      if (response && callback) {
        callback(response.access_token, response.expires_in);
      }
    })
    .catch(err => {
      captureException(err);
    });
}

/**
 * Initializes the runtime for communicating with the Model Derivative service, and creates a new instance of viewer.
 */
export async function initForgeViewer(container: HTMLElement, options: Autodesk.Viewing.InitializerOptions, config: Autodesk.Viewing.Viewer3DConfig | undefined) {
  return new Promise(function (resolve, reject) {
    Autodesk.Viewing.Initializer(options, function () {
      const viewer = new Autodesk.Viewing.GuiViewer3D(container, config);
      viewer.start();
      resolve(viewer);
    });
  });
}

/**
 * Loads specific model into the viewer.
 */
export async function loadForgeViewerModel(
  viewer: Autodesk.Viewing.Viewer3D,
  urn: string,
  xForm: THREE.Matrix4 | null,
  offset: THREE.Vector3 | null,
  loadOptions?: Record<string, any>,
) {
  return new Promise(function (resolve, reject) {
    function onDocumentLoadFailure(code: any, message: string, errors: any[]) {
      captureException({ code, message, errors });
    }

    function onDocumentLoadSuccess(doc: Autodesk.Viewing.Document) {
      const viewable = doc.getRoot().getDefaultGeometry();

      const options: Record<string, any> = {
        preserveView: true,
        keepCurrentModels: true,
        globalOffset: { x: 0, y: 0, z: 0 },
        ...loadOptions,
      };

      if (xForm) {
        options.placementTransform = xForm;
      }

      if (offset) {
        options.globalOffset = offset;
      }

      viewer?.loadDocumentNode(doc, viewable, options)
        .then(resolve)
        .catch(reject);
    }

    Autodesk.Viewing.Document.load(
      "urn:" + urn,
      onDocumentLoadSuccess,
      onDocumentLoadFailure
    );
  });
}

export async function loadForgeViewerManifest(documentId: string): Promise<Autodesk.Viewing.Document> {
  return new Promise((resolve, reject) => {
    const onDocumentLoadSuccess = (doc: Autodesk.Viewing.Document) => {
      doc.downloadAecModelData(() => resolve(doc));
    };
    Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, reject);
  });
}
