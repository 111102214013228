import React, { FC, useState } from "react";
import { EntityId } from "lib/types/entity";
import { Image } from "antd";

import {
  AttachmentContainerLink,
  AttachmentContainerContainer,
  AttachmentContainerIcon,
  AttachmentContainerName,
} from "components/blocks/AttachmentContainer/styled";

interface AttachmentContainerProps {
  fileId?: EntityId;
  fileUrl?: string;
  fileName?: string;
  fileMime?: string
}

const AttachmentContainer: FC<AttachmentContainerProps> = (props) => {
  const { fileId, fileUrl, fileName, fileMime } = props;
  const [visible, setVisible] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (fileMime?.includes("image")) {
      e.preventDefault();
      setVisible(true);
    }
  };

  return (
    <>
      <AttachmentContainerLink href={fileUrl} onClick={handleClick} target="_blank" rel="noopener noreferrer" style={{ width: "100%" }}>
        <AttachmentContainerContainer key={"uploadFile" + fileId}>
          <AttachmentContainerIcon>
            <img alt="" src={process.env.PUBLIC_URL + "/icons/files.svg"} />
          </AttachmentContainerIcon>
          <AttachmentContainerName>
            {fileName}
          </AttachmentContainerName>
        </AttachmentContainerContainer>
      </AttachmentContainerLink>
      {visible &&
        <div style={{ display: "none" }}>
          <Image
            preview={{
              visible,
              src: fileUrl,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
        </div>
      }
    </>
  );
};

export default AttachmentContainer;
