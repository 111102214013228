import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import ForgeViewerLink from "components/blocks/ForgeViewerLink";

export const SidebarToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 20px;
  cursor: pointer;
`;

export const SidebarToggleIcon = styled.img<{ isOpen: boolean }>`
  width: 10px;
  height: 10px;
  opacity: 0.25;
  transition: transform 200ms ease;
  transform: rotate(-90deg);
  ${({ isOpen }) => isOpen && css`
    transform: rotate(0deg);
    opacity: 1;
  `}
`;

export const SidebarViewerButton = styled(ForgeViewerLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin: 7px 25px;
  border: solid 1px #e4e4e4;
  border-radius: 4px;
  background-color: white;
  color: #999;
  font-size: 12px;
  font-weight: 600;
  outline: none;
  text-decoration: none;
  transition: background-color 200ms ease;
  &:hover {
    background-color: #f6f6f6;
  }
  &:focus, :active, :hover {
    color: #999;
    outline: none;
    text-decoration: none;
  }
`;
