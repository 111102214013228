import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import styled from "@emotion/styled/macro";

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
`;

const LoadingPanel = () => {
  return (
    <Wrapper>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </Wrapper>
  );
};

export default LoadingPanel;
