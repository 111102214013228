import React from "react";
import { DragDropContainer } from "./styled";

interface DragDropProps {
  className?: string;
  dragHandleProps: Record<string, any>;
}

const DragDrop = ({ dragHandleProps, className } : DragDropProps) => (
  <DragDropContainer
    {...dragHandleProps}
    className={className}
    aria-label="move"
    role="img"
  >
    <img src={process.env.PUBLIC_URL + "/icons/drag.svg"} alt="drag" />
  </DragDropContainer>
);

export default DragDrop;
