import { useCallback } from "react";
import { WorkspaceInterface } from "../../types/types";
import { addWorkspace } from "app/slices/workspaceSlice";
import useWSSubscription from "../useWSSubscription";
import { useAppDispatch } from "app/store";
import { WsEvents } from "lib/types/wsTypes";

const useWSCreateWorkspace = () => {
  const dispatch = useAppDispatch();

  const callback = useCallback((data: WorkspaceInterface) => {
    dispatch(addWorkspace(data));
  }, [dispatch]);

  useWSSubscription<WorkspaceInterface>({
    eventName: WsEvents.Workspace.Create,
    callback,
    fnKey: "useWSCreateWorkspace"
  });
};

export default useWSCreateWorkspace;
