let backendUrl: string;
let websocketUrl: string;

const host: string = process.env.REACT_APP_BACKEND_HOST || "";

// Local debug support
if (host.includes("localhost")) {
  backendUrl = `http://${host}/`;
  websocketUrl = `ws://${host}`;
} else {
  backendUrl = `https://${host}/`;
  websocketUrl = `wss://${host}`;
}

const configuration = {
  app: {
    debugEnv: process.env.REACT_APP_DEBUG_ENV,
    isDevelopment: process.env.NODE_ENV !== "production",
    isProduction: process.env.NODE_ENV === "production",
    supportEmail: "help@airtasks.com",
    title: process.env.REACT_APP_TITLE || "Airtasks",
    nameLong: process.env.REACT_APP_NAME || "Airtasks for Web",
    version: process.env.REACT_APP_VERSION || new Date().getFullYear().toString(),
    hash: process.env.REACT_APP_HASH,
  },
  links: {
    website: "https://airtasks.com",
    privacyPolicy: "https://airtasks.com/legal/privacy-policy",
    termsOfService: "https://airtasks.com/legal/terms-of-service",
    canny: "http://feedback.airtasks.com",
    documentation: "https://airtasks.notion.site",
    supportEmail: "help@airtasks.com",
  },
  backend: {
    url: backendUrl,
    ws: websocketUrl
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
  },
  logRocket: {
    appId: process.env.REACT_APP_LOGROCKET_APP_ID || "",
  },
  canny: {
    appId: process.env.REACT_APP_CANNY_APP_ID || "",
    privateKey: process.env.REACT_APP_CANNY_PRIVATE_KEY || "",
  },
  algolia: {
    appId: process.env.REACT_APP_ALGOLIA_APP_ID || "",
    apiKey: process.env.REACT_APP_ALGOLIA_API_KEY || "",
  },
  intercom: {
    appId: process.env.REACT_APP_INTERCOM_APP_ID || "",
  },
  apryse: {
    licenseKey: process.env.REACT_APP_APRYSE_KEY || "",
  },
};

export default configuration;
