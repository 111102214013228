import { FC, memo } from "react";
import { SidebarTopMenuTitle } from "layout/SidebarCustom/SidebarMenus/SidebarTopMenu/styled";
import { SidebarTopMenuIcon } from "layout/SidebarCustom/SidebarMenus/SidebarTopMenu/styled";
import { SidebarItemContentGrid, SidebarLinkContainer, SidebarNavigationLink } from "layout/SidebarCustom/styled";

interface SidebarTopMenuProps {
  name: string;
  icon?: string;
  path: string;
}

const SidebarTopMenu: FC<SidebarTopMenuProps> = (props) => {
  const { name, icon, path } = props;

  return (
    <SidebarLinkContainer>
      <SidebarNavigationLink to={path}>
        <SidebarItemContentGrid>
          <SidebarTopMenuIcon alt="icon" src={`/icons/${icon}`} />
          <SidebarTopMenuTitle>{name}</SidebarTopMenuTitle>
        </SidebarItemContentGrid>
      </SidebarNavigationLink>
    </SidebarLinkContainer>
  );
};

export default memo(SidebarTopMenu);
