import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const JsSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.4583 20.5167C64.18 20.5145 61.9954 19.6087 60.3842 17.998C58.7725 16.3874 57.8658 14.2034 57.8625 11.925V0H19.4583C16.4846 0 13.6327 1.18131 11.5299 3.28406C9.42715 5.38679 8.24585 8.23875 8.24585 11.2125V78.8042C8.25027 81.775 9.43353 84.6225 11.5358 86.7217C13.6381 88.8208 16.4875 90 19.4583 90H65.5417C68.5154 90 71.3675 88.8187 73.47 86.7158C75.5729 84.6133 76.7542 81.7612 76.7542 78.7875V20.5167H66.4583Z" fill="#FF3E4C"/>
        <path d="M76.7542 20.5167H66.4583C64.18 20.5145 61.9954 19.6087 60.3842 17.998C58.7725 16.3874 57.8658 14.2034 57.8625 11.925V0L76.7542 20.5167Z" fill="#FF3E4C"/>
        <path d="M84.8875 71.0834H32.7874C29.0917 71.0834 26.0958 74.0792 26.0958 77.775V93.3084C26.0958 97.0042 29.0917 100 32.7874 100H84.8875C88.5833 100 91.5792 97.0042 91.5792 93.3084V77.775C91.5792 74.0792 88.5833 71.0834 84.8875 71.0834Z" fill="#FF3E4C"/>
        <path d="M36.6103 93.75C35.2857 93.75 34.2692 93.4775 33.5609 92.9321C32.8596 92.3796 32.4453 91.5188 32.3178 90.35C32.2894 90.0525 32.3532 89.8225 32.509 89.6596C32.6648 89.4967 32.8915 89.415 33.189 89.415C33.7344 89.415 34.039 89.7125 34.1028 90.3075C34.1878 90.9521 34.418 91.3913 34.7934 91.625C35.1688 91.8517 35.7744 91.965 36.6103 91.965H38.374C39.0328 91.965 39.5463 91.8909 39.9146 91.7421C40.29 91.5934 40.5521 91.3346 40.7009 90.9663C40.8496 90.5979 40.924 90.0809 40.924 89.415V80.235C40.924 79.64 41.2215 79.3425 41.8165 79.3425C42.4115 79.3425 42.709 79.64 42.709 80.235V89.415C42.709 90.9096 42.3584 92.0075 41.6571 92.7088C40.9559 93.403 39.8615 93.75 38.374 93.75H36.6103Z" fill="white"/>
        <path d="M46.3443 93.665C45.7776 93.4455 45.6076 93.0559 45.8343 92.4963L50.8917 80.0438C51.1042 79.5763 51.4371 79.3425 51.8904 79.3425H51.9329C52.3933 79.3638 52.705 79.5975 52.8679 80.0438L58.0104 92.4963C58.2371 93.0559 58.0742 93.4455 57.5217 93.665C56.9621 93.8846 56.5725 93.7217 56.3529 93.1763L55.2054 90.3925H48.5861L47.4918 93.1763C47.2722 93.7288 46.8897 93.8917 46.3443 93.665ZM49.2874 88.6075H54.4725L51.8267 82.1688L49.2874 88.6075Z" fill="white"/>
        <path d="M65.06 93.75C64.6917 93.75 64.4013 93.5162 64.1888 93.0487L58.94 80.6175C58.8338 80.3695 58.8054 80.15 58.855 79.9587C58.9046 79.7604 59.0675 79.5975 59.3438 79.47C59.9246 79.1795 60.3354 79.3283 60.5763 79.9162L65.5384 91.6675H64.9221L69.7563 79.9162C69.99 79.3354 70.4009 79.1866 70.9888 79.47C71.2367 79.5975 71.3892 79.7604 71.4459 79.9587C71.5096 80.15 71.4917 80.3695 71.3925 80.6175L66.1013 93.0487C65.8817 93.5162 65.5629 93.75 65.145 93.75H65.06Z" fill="white"/>
        <path d="M72.8654 93.665C72.2988 93.4455 72.1288 93.0559 72.3554 92.4963L77.4129 80.0438C77.6254 79.5763 77.9584 79.3425 78.4117 79.3425H78.4542C78.9146 79.3638 79.2263 79.5975 79.3892 80.0438L84.5317 92.4963C84.7584 93.0559 84.5954 93.4455 84.0429 93.665C83.4834 93.8846 83.0938 93.7217 82.8742 93.1763L81.7267 90.3925H75.1071L74.0129 93.1763C73.7934 93.7288 73.4109 93.8917 72.8654 93.665ZM75.8084 88.6075H80.9934L78.3479 82.1688L75.8084 88.6075Z" fill="white"/>
        <path d="M36.2083 63.3042C36.2083 63.8125 35.7961 64.225 35.2875 64.225C33.1625 64.225 31.4695 63.6013 30.2083 62.3542C28.9472 61.1071 28.3181 59.4221 28.3208 57.3V50.5C28.3208 48.658 27.8014 47.4684 26.7626 46.9313C25.9797 46.5267 25.2167 45.8855 25.2167 45.0042C25.2167 44.123 25.9797 43.4817 26.7626 43.0771C27.8014 42.54 28.3208 41.3505 28.3208 39.5083V32.7083C28.3208 30.5833 28.95 28.8972 30.2083 27.65C31.4667 26.4028 33.1597 25.7805 35.2875 25.7833C35.7961 25.7833 36.2083 26.1956 36.2083 26.7042V28.3458C36.2083 28.6933 35.9267 28.975 35.5792 28.975C33.0792 28.975 31.8292 30.3875 31.8292 33.2125V40.1792C31.799 42.6446 30.8529 44.2163 28.9908 44.893C28.9443 44.91 28.9125 44.9542 28.9125 45.0034C28.9125 45.0538 28.9451 45.098 28.9925 45.1146C30.8152 45.7484 31.7607 47.2784 31.8292 49.7042V56.7875C31.8292 59.6125 33.0792 61.025 35.5792 61.025C35.9267 61.025 36.2083 61.3067 36.2083 61.6542V63.3042Z" fill="#FF3E4C"/>
        <path d="M49.7126 64.2333C49.2003 64.2288 48.7875 63.8121 48.7875 63.3V61.6625C48.7875 61.315 49.0692 61.0333 49.4167 61.0333C51.9167 61.0333 53.1667 59.6208 53.1667 56.7958V49.7125C53.2213 47.2838 54.1667 45.7538 56.003 45.1225C56.0505 45.1063 56.0834 45.0617 56.0834 45.0113C56.0834 44.9621 56.0517 44.9183 56.0055 44.9008C54.1513 44.2133 53.205 42.6421 53.1667 40.1875V33.2208C53.1667 30.3958 51.9167 28.9833 49.4167 28.9833C49.0692 28.9833 48.7875 28.7016 48.7875 28.3541V26.7167C48.7875 26.2058 49.2017 25.7916 49.7126 25.7916C51.8375 25.7916 53.5292 26.4138 54.7875 27.6583C56.0459 28.9028 56.6763 30.5888 56.6792 32.7166V39.5166C56.6792 41.3588 57.1988 42.5483 58.2375 43.0854C59.0205 43.49 59.7834 44.1313 59.7834 45.0125C59.7834 45.8938 59.0205 46.535 58.2375 46.9396C57.1988 47.4767 56.6792 48.6663 56.6792 50.5083V57.3083C56.6792 59.4333 56.0488 61.1179 54.7875 62.3625C53.5263 63.6071 51.8346 64.2304 49.7126 64.2333Z" fill="#FF3E4C"/>
        <path d="M42.1542 35.9042C43.6799 35.9042 44.9167 34.6674 44.9167 33.1417C44.9167 31.616 43.6799 30.3792 42.1542 30.3792C40.6285 30.3792 39.3917 31.616 39.3917 33.1417C39.3917 34.6674 40.6285 35.9042 42.1542 35.9042Z" fill="#FF3E4C"/>
        <path d="M42.1542 47.7667C43.6799 47.7667 44.9167 46.53 44.9167 45.0042C44.9167 43.4784 43.6799 42.2417 42.1542 42.2417C40.6285 42.2417 39.3917 43.4784 39.3917 45.0042C39.3917 46.53 40.6285 47.7667 42.1542 47.7667Z" fill="#FF3E4C"/>
        <path d="M42.1542 59.6292C43.6799 59.6292 44.9167 58.3926 44.9167 56.8667C44.9167 55.3409 43.6799 54.1042 42.1542 54.1042C40.6285 54.1042 39.3917 55.3409 39.3917 56.8667C39.3917 58.3926 40.6285 59.6292 42.1542 59.6292Z" fill="#FF3E4C"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default JsSvg;
