import React, { FC, useState } from "react";
import { Select, Modal, Switch } from "antd";

import { TaskExportFormats, TaskRequestExportOptions } from "lib/types/tasksTypes";
import configuration from "lib/configs/configuration";
import { fetchDownload } from "lib/helpers/fetchDownload";
import CustomStyledButton from "components/blocks/CustomStyledButton";

import { StyledButton } from "styles/common";
import { ExportModalHeading, ExportModalOptionWrapper } from "../ExportBoardModal/styled";
import { EntityId } from "lib/types/entity";
import { ExportTaskIncludeOptions, ExportTaskOptionChangeHandler } from "./lib";
import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import { ModalComponentProps } from "lib/types/components";
import { getDownloadFilename } from "lib/helpers/stringUtils";
import { captureException } from "lib/utils/sentry";
import { commonModalProps } from "../ModalDialog/lib";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

interface ExportTaskModalProps extends ModalComponentProps {
  taskId: EntityId;
}

const ExportTaskModal: FC<ExportTaskModalProps> = (props) => {
  const { task } = useTaskContext() as TaskContextInterface;
  const { terminology } = useBoardFeatures(task.board);
  const [selectedFormat, setSelectedFormat] = useState<string | TaskExportFormats>(TaskExportFormats.Pdf);
  const [selectedOptions, setSelectedOptions] = useState<TaskRequestExportOptions>({});
  const [isLoading, setIsLoading] = useState(false);

  const additionalOptionsAvailable = [TaskExportFormats.Pdf].includes(selectedFormat as TaskExportFormats);

  const handleIncludeOptionOnChange: ExportTaskOptionChangeHandler = (checked, property) => {
    const nextState: TaskRequestExportOptions = {
      ...selectedOptions,
      include: {
        ...selectedOptions.include,
        [property]: checked,
      },
    };
    setSelectedOptions(nextState);
  };

  const handleSelectOnChange = (value: string) => {
    setSelectedFormat(value);
  };

  const exportTask = async () => {
    const path = `${configuration.backend.url}tasks/${task.id}/export/${selectedFormat}`;
    const fileName = getDownloadFilename(task.title, selectedFormat);

    await fetchDownload(path, fileName, selectedOptions);
  };

  const handleExportOnClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await exportTask();
    } catch (error) {
      captureException(error, true);
    }
    setIsLoading(false);
    props.onClose();
  };

  return (
    <Modal
      {...commonModalProps}
      destroyOnClose
      title={`Export ${terminology.single.toLowerCase()}`}
      visible={props.visible}
      onOk={props.onClose}
      onCancel={props.onClose}
      width={325}
      footer={[
        <div key="cancel" style={{ display: "inline-block", marginRight: "10px" }}>
          <StyledButton onClick={props.onClose}>
            Cancel
          </StyledButton>
        </div>,
        <CustomStyledButton
          key="ok"
          value="Export"
          btnColor="purple"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={handleExportOnClick}
        />
      ]}
    >
      <ExportModalOptionWrapper>
        <ExportModalHeading>Export format</ExportModalHeading>
        <Select
          size="small"
          placement="bottomRight"
          bordered={false}
          dropdownStyle={{ minWidth: 150 }}
          value={selectedFormat}
          onChange={handleSelectOnChange}
          optionLabelProp="label"
        >
          <Select.Option value={TaskExportFormats.Pdf} label="PDF">PDF</Select.Option>
          <Select.Option value={TaskExportFormats.Csv} label="CSV">CSV</Select.Option>
          <Select.Option value={TaskExportFormats.Xlsx} label="XLSX">XLSX</Select.Option>
          <Select.Option value={TaskExportFormats.Viewpoints} label="XML">XML (Viewpoints)</Select.Option>
        </Select>
      </ExportModalOptionWrapper>
      {additionalOptionsAvailable &&
        <>
          {ExportTaskIncludeOptions.map(option => (
            <ExportModalOptionWrapper key={option.code}>
              <ExportModalHeading htmlFor={option.code}>
                {option.text}
              </ExportModalHeading>
              <Switch
                id={option.code}
                style={{ marginRight: 10 }}
                size="small"
                onChange={checked => handleIncludeOptionOnChange(checked, option.code)}
              />
            </ExportModalOptionWrapper>
          ))}
        </>
      }
    </Modal>
  );
};

export default ExportTaskModal;
