import { CSSProperties, FC, ReactNode, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Drawer, Skeleton } from "antd";

import { CommentInterface } from "lib/types/types";
import { PropertyValueType } from "lib/types/dataTypes";
import { useTaskContext } from "lib/contexts/TaskContext";
import TaskCollaborators from "pages/Task/TaskCollaborators";
import TaskCommentBox, { TaskCommentBoxProps } from "pages/Task/TaskCommentBox";
import TaskDetailed from "pages/Task/TaskDetailed";
import TaskDrawerTitle from "./TaskDrawerTitle";
import { ModalComponentProps } from "lib/types/components";

interface TaskDrawerProps extends ModalComponentProps {
  showMask?: boolean;
  drawerWidth?: number;
  isRenderInCurrentDom?: boolean;
  afterVisibleChange?: (visible: boolean) => void;
  customization?: Partial<Record<PropertyValueType, ReactNode>>;
}

const TaskDrawer: FC<TaskDrawerProps> = (props) => {
  const { visible, onClose, showMask = true, drawerWidth = 570, isRenderInCurrentDom } = props;

  const { task, displayView } = useTaskContext();

  const [commentBoxOptions, setCommentBoxOptions] = useState<Omit<TaskCommentBoxProps, "taskId" | "onDiscard" | "workspaceId">>({
    isEditor: false,
  });

  let getContainer: false | undefined;
  let style: CSSProperties = {};

  if (isRenderInCurrentDom) {
    getContainer = false;
    style = { position: "absolute" };
  }

  const onEditComment = (comment: CommentInterface) => {
    setCommentBoxOptions({
      isEditor: true,
      commentId: comment.id,
      initialText: comment.text,
      initialFileAttachments: comment.attachments,
    });
  };

  const onDiscardEditComment = () => {
    if (commentBoxOptions.isEditor) {
      setCommentBoxOptions({
        isEditor: false,
        initialText: "",
        initialFileAttachments: [],
      });
    }
  };

  return (
    <Drawer
      placement="right"
      destroyOnClose
      closable={false}
      keyboard
      getContainer={getContainer}
      style={style}
      onClose={onClose}
      open={visible}
      width={drawerWidth}
      mask={showMask}
      afterOpenChange={props.afterVisibleChange}
      bodyStyle={{ padding: 0 }}
      title={
        task
          ? <TaskDrawerTitle displayView={displayView} onClose={onClose} />
          : <Skeleton.Input style={{ width: 200 }} active />
      }
      footer={
        task
          ? (<>
            <TaskCommentBox onDiscard={onDiscardEditComment}  {...commentBoxOptions} />
            <TaskCollaborators />
          </>)
          : <Skeleton active />
      }
      footerStyle={{ backgroundColor: "#fbfbfb" }}
    >
      <Scrollbars autoHide>
        {task
          ? <TaskDetailed
            hideHeader
            hideCommentBox
            displayView={displayView}
            onEditComment={onEditComment}
            customization={props.customization}
          />
          : <Skeleton active style={{ padding: 24 }} />
        }
      </Scrollbars>
    </Drawer>
  );
};

export default TaskDrawer;
