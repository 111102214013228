import { useEffect } from "react";
import { setOrganizations, setOrganizationsLoading } from "app/slices/organizationSlice";
import { useAppDispatch } from "app/store";
import OrganizationService from "services/OrganizationService";

const useGetOrganizations = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setOrganizationsLoading(true));
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getData = async () => {
      const data = await OrganizationService.getOrganizations();
      if (!signal.aborted) {
        dispatch(setOrganizations(data));
        dispatch(setOrganizationsLoading(false));
      }
    };
    getData();

    return () => {
      abortController.abort();
      dispatch(setOrganizationsLoading(false));
    };

  }, [dispatch]);
};

export default useGetOrganizations;
