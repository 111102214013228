import { extractFilterFromCtxParams } from "lib/helpers/navigationUtils";
import { BoardInterface } from "../../types/boardTypes";
import { TaskInterface } from "../../types/tasksTypes";
import { WorkspaceInterface } from "../../types/types";
import { FilterValueNone, TAnyFilters } from "../filters";

export const noneFilter = {
  filterValues: (...args: any[]) => {
    return [{
      text: FilterValueNone,
      valueKey: FilterValueNone
    }];
  },
  filterFn: (tasks: TaskInterface[]) => {
    return tasks;
  },
};

export const boardFilter = {
  filterValues: (boards: BoardInterface[] = []) => {
    return boards.map(board => {
      return {
        text: board.name,
        valueKey: board.uid,
        id: String(board.id),
      };
    }) || [];
  },
  filterFn: (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
    const boardId = currentFilter.value.id;
    const filter = extractFilterFromCtxParams(boardId);

    if (!boardId || boardId === FilterValueNone) {
      return tasks;
    }

    return tasks.filter(task => {
      return String(task.board[filter.code]) === String(filter.value);
    });
  },
};

export const projectFilter = {
  filterValues: (workspaces: WorkspaceInterface[] = []) => {
    return workspaces.map(workspace => {
      return {
        text: workspace.name,
        valueKey: String(workspace.id)
      };
    });
  },
  filterFn: (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
    const workspaceId = currentFilter.value.valueKey;
    if (!workspaceId || workspaceId === FilterValueNone) {
      return tasks;
    }

    return tasks.filter(task => {
      return String(task.board.workspace) === workspaceId;
    });
  },
};

export const viewpointsFilter = {
  filterValues: (...args: any[]) => {
    return ["Not empty", "Empty"].map(value => {
      return {
        text: value,
        valueKey: value
      };
    });
  },
  filterFn: (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
    const filterValue = currentFilter.value.valueKey;

    switch (filterValue) {
      case "Not empty": {
        return tasks.filter(task => {
          return task.viewpoints?.length;
        });
      }

      case "Empty": {
        return tasks.filter(task => {
          return !task.viewpoints?.length;
        });
      }

      default:
        return tasks;
    }
  },
};
