import LoadingMessage from "components/blocks/LoadingMessage";
import { captureClassMethodException } from "./sentry";

// Define the ILogger interface
export interface ILogger {
  info: (msg: any, ...rest: any[]) => void;
  debug: (msg: any, ...rest: any[]) => void;
  warn: (msg: any, ...rest: any[]) => void;
  error: (msg: any, ...rest: any[]) => void;
}

export default abstract class BaseClassLoggable {
  public readonly LogPrefix: string;
  readonly logger: ILogger;

  constructor() {
    this.LogPrefix = `[${this.constructor.name}]`;
    this.logger = createLogger({ prefix: this.LogPrefix });
  }

  protected getMethodName(method: string) {
    return this.constructor.name + "." + method;
  }

  protected handleError(error: any, methodName: string, showUiMessage?: boolean, uiMessageText?: string) {
    this.logger.error(methodName, error);
    captureClassMethodException(error, this.constructor.name, methodName);

    if (showUiMessage) {
      LoadingMessage.error(uiMessageText);
    }
  }
}

export function createLogger(options: { prefix: string; }): ILogger {
  return {
    info: (msg: any, ...rest: any) => console.info(`${options.prefix} `, msg, ...rest),
    debug: (msg: any, ...rest: any) => console.debug(`${options.prefix} `, msg, ...rest),
    warn: (msg: any, ...rest: any) => console.warn(`${options.prefix} `, msg, ...rest),
    error: (msg: any, ...rest: any) => console.error(`${options.prefix} `, msg, ...rest),
  };
}

