import { JSendResponse, JSendStatus } from "lib/types/jsend";
import configuration from "lib/configs/configuration";
import { captureException } from "lib/utils/sentry";

interface ApiRequestWithStoreHandler {
  (
    requestFn: () => Promise<JSendResponse<any> | void>,
    onError: () => void,
    onSettled: () => void,
  ): Promise<void>
}

const LogPrefix = "[API] performApiRequestWithStore:";

export const performApiRequestWithStore: ApiRequestWithStoreHandler = async (requestFn, onError, onSettled) => {
  try {
    const response = await requestFn();
    if (configuration.app.isDevelopment) {
      console.debug(LogPrefix, "performing `requestFn` action");
    }
    if (response?.status === JSendStatus.Error) {
      throw new Error(response.message);
    }
  } catch (error) {
    onError();
    captureException(error, true);

    if (configuration.app.isDevelopment) {
      console.debug(LogPrefix, "caught an error during API request", error);
      console.debug(LogPrefix, "performing `onError` action");
    }
  } finally {
    onSettled();

    if (configuration.app.isDevelopment) {
      console.debug(LogPrefix, "successfully resolved API request");
      console.debug(LogPrefix, "performing `onSettled` action");
    }
  }
};
