import React, { FC } from "react";
import {
  ReusableSelectOptionContainer,
  ReusableSelectOptionCircle, ReusableSelectOptionText,
} from "components/blocks/ReusableFields/ReusableMultiSelectOption/styled";
import { COLOR_DEFAULT_GREY } from "lib/configs/colors";

interface ReusableMultiSelectOptionProps {
  selectTitle?: string;
  selectColor?: string | null;
  selectIcon?: React.ReactNode;
}

const ReusableSelectOption: FC<ReusableMultiSelectOptionProps> = (props) => {
  const { selectTitle, selectColor, selectIcon } = props;

  return (
    <ReusableSelectOptionContainer>
      {selectIcon &&
        <span style={{ marginRight: 6 }}>{selectIcon}</span>
      }
      {!selectIcon &&
        <ReusableSelectOptionCircle color={selectColor || COLOR_DEFAULT_GREY} />
      }
      <ReusableSelectOptionText>
        {selectTitle}
      </ReusableSelectOptionText>
    </ReusableSelectOptionContainer>
  );
};

export default ReusableSelectOption;
