import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { basicButton } from "styles/common";

export const PickerWrapper = styled.div`
  .emoji-mart-category-label {
    font-size: 14px;
    margin-top: 6px;
    color: #999999;
  }
`;

export const ReactionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ReactionsButton = styled.button<{ active?: boolean }>`
  ${basicButton};
  display: flex;
  align-items: center;
  padding: 3px 7px 2px 7px;
  border-radius: 13px;
  height: 26px;
  font-size: 13px;
  ${({ active }) => active && css`
    background-color: #e9f5fc;
    border-color: #49a3e7;
    color: #49a3e7;
    &:hover {
      background-color: #e9f5fc;
    }
  `}
`;

export const EmojiWrapper = styled.span`
  font-size: 16px;
  margin-right: 2px;
`;
