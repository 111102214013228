import { FC, useEffect } from "react";
import ReactDOM from "react-dom";

const element = document.createElement("div");
const HeaderPortal: FC = (props) => {
  useEffect(() => {
    const portalRoot = document.getElementById("portal-root");
    if (portalRoot) {
      portalRoot.appendChild(element);
    }

    return () => {
      if (portalRoot) {
        portalRoot.removeChild(element);
      }
    };
  }, []);

  return (
    ReactDOM.createPortal(props.children, element)
  );
};

export default HeaderPortal;
