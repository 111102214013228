import { useEffect, useState } from "react";

const useGetQueryValue = (queryName: string) => {
  const [queryValue, setQueryValue] = useState<null | string | undefined>();
  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    setQueryValue(params.get(queryName));
  }, [queryName]);

  return queryValue;
};

export default useGetQueryValue;