import { FC, useRef, useState } from "react";
import { Form } from "antd";
import moment from "moment";

import TasksService from "services/TasksService";
import { DateFormats } from "lib/theme/lib";
import { DefaultColumnCodes } from "lib/types/boardTypes";
import TasksForm from "./TasksForm";
import { EditorJsCore } from "../EditorJS/lib";
import { ModalComponentProps } from "lib/types/components";

const AddTaskModal: FC<ModalComponentProps> = (props) => {
  const { onClose, visible } = props;
  const editorCore = useRef<EditorJsCore | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  const resetFields = () => {
    form.resetFields();
    editorCore.current?.clear();
  };

  const handleCancel = () => {
    onClose();
    resetFields();
  };

  const handleOk = async () => {
    const editorJsValue = await editorCore?.current?.save();
    form.validateFields()
      .then(values => {
        setIsLoading(true);

        const body = { ...values };
        body.board = body.board[body.board.length - 1];
        body[DefaultColumnCodes.DescriptionRTE] = editorJsValue;
        if (body.dueDate) {
          body.dueDate = moment(body.dueDate).format(DateFormats.ISO);
        }

        return TasksService.addNewTask(body);
      })
      .then(() => {
        handleCancel();
        resetFields();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <TasksForm
      form={form}
      isLoading={isLoading}
      isVisible={visible}
      onSubmit={handleOk}
      onCancel={handleCancel}
      editorRef={editorCore}
    />
  );
};

export default AddTaskModal;
