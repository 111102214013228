import { FC, RefObject, useEffect, useMemo, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import Board from "react-trello";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import { ConfigProvider } from "antd";

import { setReorderedTasks } from "app/slices/tasksSlice";
import { useAppDispatch, useAppSelector } from "app/store";
import Skeleton from "components/ui/Skeletons/Kanban";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import { useBoardViewContext } from "lib/contexts/BoardViewContext";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import useBoardViewSortAndFilters from "lib/customHooks/views/useBoardViewSortAndFilters";
import useBoardViewStatuses from "lib/customHooks/views/useBoardViewStatuses";
import useSavingState from "lib/customHooks/useSavingState";
import useBoardSetStatuses from "lib/customHooks/dataLayer/board/useBoardSetStatuses";
import useCustomColumnsFilters from "lib/customHooks/filters/useCustomColumnsFilters";
import { kanbanFilters } from "lib/filters/filters";
import { filterTasks } from "lib/filters/generalFilter";
import { getSortFields, sortTasks } from "lib/sort/tasksSort";
import { EntityId } from "lib/types/entity";
import { KanbanStructureInterface } from "lib/types/kanbanTypes";
import { TaskInterface } from "lib/types/tasksTypes";
import { MemberRoles } from "lib/types/types";
import TasksService from "services/TasksService";

import SecondaryBar from "../SecondaryBar";
import AddCardLink from "./AddCardLink";
import Card from "./Card";
import LaneHeader from "./LaneHeader";
import NewCardForm from "./NewCardForm";
import NewLaneForm from "./NewLaneForm";
import NewLaneSection from "./NewLaneSection";
import { BoardKanbanContainer, boardStyles, laneStyles, Overlay } from "./styled";
import { getBoardState, tasksSort, } from "./utils";
import { KanbanContext } from "./lib";
import usePrevious from "lib/customHooks/usePrevious";

declare global {
  interface Array<T> {
    move(from: number, to: number): T[];
  }
}

// TODO: remove this spike
// eslint-disable-next-line
Array.prototype.move = function (from: number, to: number) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};

const BoardKanban: FC = () => {
  const dispatch = useAppDispatch();
  const { board } = useBoardContext() as BoardContextInterface;
  const { isCustomView } = useBoardViewContext();

  const tasks = useAppSelector(state => state.tasks.tasks);
  const isLoading = useAppSelector(state => state.tasks.isLoading);
  const { setSavingStart, setSavingFinish } = useSavingState();
  const reorderRef = useRef<boolean>(false);
  const kanbanWrapperRef = useRef(null) as RefObject<any>;
  const [boardData, setBoardData] = useState<KanbanStructureInterface>({ lanes: [] });
  const [isOverlay, setOverlay] = useState(false);
  const prevBoardData = usePrevious<KanbanStructureInterface>(boardData);

  const { updateBoardStatus, deleteBoardStatus, reorderBoardStatuses } = useBoardSetStatuses({ board: board, updateDetailed: true });
  const { currentGeneralFilter, currentFilter, currentSort } = useBoardViewSortAndFilters();
  const { filterFn, allFilters } = useCustomColumnsFilters(kanbanFilters);
  const { appliedStatuses: statuses, saveViewStatuses } = useBoardViewStatuses();

  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const allowedToDrag = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const context = useMemo(() => ({ setOverlay, kanbanWrapperRef }), []);
  const isSynced = !!board?.externalDataSource;

  /** This effect constructs `data` object for react-trello <Board /> component */
  useEffect(() => {
    const statusesIds = (statuses || []).map((status) => String(status.id));

    const boardLanes = cloneDeep(statuses).map(status => {
      const isNoStatus = status.system;

      const lane = {
        ...status,
        id: String(status.id),
        cards: [] as TaskInterface[],
      };

      lane.cards = cloneDeep(tasks).filter((task: TaskInterface) => {
        if (isNoStatus && !statusesIds.includes(String(task.status))) {
          return task;
        }

        return String(task.status) === String(status.id);
      });

      lane.cards = filterFn(lane.cards);
      lane.cards = filterTasks(lane.cards, currentGeneralFilter);
      lane.cards = sortTasks(lane.cards, currentSort);
      lane.cards.sort((a, b) => Number(a.sort__kanban) - Number(b.sort__kanban));
      lane.id = status.id.toString();

      return lane;
    });

    setBoardData({ lanes: boardLanes });
  }, [currentFilter, currentGeneralFilter, currentSort, filterFn, statuses, tasks]);

  /**
   * This effect saves kanban cards after DnD reorders to the backend
   * (checks `reorderRef` that is being set in `onCardMoveAcrossLanes`)
   */
  useEffect(() => {
    if (reorderRef.current === true) {
      const prevState = getBoardState(prevBoardData);
      const nextState = getBoardState(boardData);

      if (!isEmpty(prevState) && !isEmpty(nextState) && !isEqual(prevState, nextState)) {
        const payload = nextState.filter((item, index) => !isEqual(item.tasks, prevState[index].tasks));
        setSavingStart();
        TasksService.reorderKanban(board.id, payload).finally(() => setSavingFinish());
      }

      reorderRef.current = false;
    }
  }, [board.id, boardData]);

  const onLaneUpdate = (laneId: EntityId, data: Record<string, any>) => {
    updateBoardStatus(laneId, { title: data.title });
  };

  const onLaneDelete = (laneId: EntityId) => {
    deleteBoardStatus(laneId);
  };

  const onCardMoveAcrossLanes = (fromLaneId: string, toLaneId: string, cardId: number, index: number) => {
    const sortedLane = tasksSort({ lanes: boardData.lanes, fromLaneId, toLaneId, cardId, index });

    dispatch(setReorderedTasks({
      status: sortedLane.status,
      tasks: sortedLane.tasks,
      target: "kanban"
    }));

    reorderRef.current = true;
  };

  const handleLaneDragEnd = (removedIndex: number, addedIndex: number) => {
    const newData = Array.from(statuses);
    const [movedRow] = newData.splice(removedIndex, 1);
    newData.splice(addedIndex, 0, movedRow);
    const nextState = newData.map((item, index) => ({ ...item, sort: index }));

    if (isCustomView) {
      saveViewStatuses(nextState);
    } else {
      reorderBoardStatuses(nextState);
    }
  };

  const getPopupContainer = (trigger?: HTMLElement): HTMLElement => {
    if (trigger) {
      return trigger.parentElement as HTMLElement;
    }

    return kanbanWrapperRef.current;
  };

  return (
    <>
      <SecondaryBar
        filters={allFilters}
        sortFields={getSortFields(board)}
        hideAutomations={!allowedToEdit}
        hideAddRecord={!allowedToDrag}
        showHideFields={allowedToEdit}
        type="board"
      />
      <Scrollbars autoHide>
        <BoardKanbanContainer ref={kanbanWrapperRef}>
          {isLoading && <Skeleton />}
          {isOverlay && <Overlay />}
          {!isLoading && (
            <ConfigProvider getPopupContainer={getPopupContainer}>
              <KanbanContext.Provider value={context}>
                <Board
                  components={{
                    Card,
                    LaneHeader,
                    AddCardLink,
                    NewLaneSection,
                    NewLaneForm,
                    NewCardForm,
                  }}
                  data={boardData}
                  style={boardStyles}
                  laneStyle={laneStyles}
                  draggable={allowedToDrag}
                  laneDraggable={allowedToEdit}
                  cardDraggable={allowedToDrag}
                  editable={!isSynced && allowedToDrag}
                  canAddLanes={allowedToEdit}
                  cardDragClass="draggingCard"
                  laneDragClass="draggingLane"
                  onLaneDelete={onLaneDelete}
                  onLaneUpdate={onLaneUpdate}
                  handleLaneDragEnd={handleLaneDragEnd}
                  onCardMoveAcrossLanes={onCardMoveAcrossLanes}
                />
              </KanbanContext.Provider>
            </ConfigProvider>
          )}
        </BoardKanbanContainer>
      </Scrollbars>
    </>
  );
};

export default BoardKanban;

