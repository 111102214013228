import styled from "@emotion/styled/macro";
import { SidebarItemTitle } from "layout/SidebarCustom/styled";

export const SidebarBoardIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const SidebarBoardTitle = styled(SidebarItemTitle)`
  font-size: 12px;
  font-weight: 600;
`;
