import { useEffect } from "react";

import { setNotifications } from "app/slices/notificationsSlice";
import { setLoading } from "app/slices/progressSlice";
import { useAppDispatch } from "app/store";
import NotificationService from "services/NotificationService";

const useGetNotifications = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLoading(true));
    const abortController = new AbortController();
    const signal = abortController.signal;
    const getData = async () => {
      const notifications = await NotificationService.fetchAll();
      if (!signal.aborted) {
        dispatch(setNotifications(notifications));
        dispatch(setLoading(false));
      }
    };
    getData();
    return () => {
      abortController.abort();
      dispatch(setLoading(false));
    };
  }, [dispatch]);
};

export default useGetNotifications;
