enum ProjectionType {
  Perspective = "persp",
  Orthographic = "ortho",
}

enum ClipPlaneModeType {
  Planes = "planes",
  Box = "box"
}

enum ClipPlaneStateType {
  Default = "default",
  Disabled = "disabled",
  Enabled = "enabled"
}

enum ModelUnitsType {
  Meter = "m",
  Centimeter = "cm",
  Millimeter = "mm",
  Foot = "ft",
  Inch = "in",
  Yard = "yrd",
  Kilometer = "km",
  Mile = "mi",
  Micrometer = "um",
  Mil = "mils",
  Microinch = "uin",
}

const NavisworksSchema = {
  projectionType: ProjectionType,
  modelUnitsType: ModelUnitsType,
  clipPlaneModeType: ClipPlaneModeType,
  clipPlaneStateType: ClipPlaneStateType,
};

export default NavisworksSchema;
