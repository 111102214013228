import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const WavSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.6168 20.5167C64.3388 20.5145 62.1547 19.6086 60.5438 17.9978C58.933 16.387 58.0272 14.203 58.0251 11.925V7.74254e-07H19.6376C18.1648 -0.00054653 16.7063 0.28907 15.3455 0.852304C13.9846 1.41554 12.7481 2.24137 11.7065 3.2826C10.6649 4.32383 9.83857 5.56008 9.27483 6.92071C8.71109 8.28133 8.4209 9.73971 8.4209 11.2125V78.8042C8.42532 81.7758 9.60915 84.6242 11.7123 86.7233C13.8155 88.8225 16.666 90.0012 19.6376 90H65.7209C67.1934 90 68.6513 89.71 70.0118 89.1467C71.3722 88.5829 72.608 87.7571 73.6492 86.7158C74.6905 85.6746 75.5163 84.4387 76.0801 83.0783C76.6434 81.7179 76.9334 80.26 76.9334 78.7875V20.5167H66.6168Z" fill="#00C650"/>
        <path d="M76.9335 20.5167H66.6168C64.3389 20.5145 62.1547 19.6086 60.5439 17.9978C58.9331 16.387 58.0272 14.203 58.0251 11.925V0L76.9335 20.5167Z" fill="#00C650"/>
        <path d="M85.0668 71.0833H32.9668C29.2711 71.0833 26.2751 74.0791 26.2751 77.775V93.3083C26.2751 97.0041 29.2711 100 32.9668 100H85.0668C88.7626 100 91.7584 97.0041 91.7584 93.3083V77.775C91.7584 74.0791 88.7626 71.0833 85.0668 71.0833Z" fill="#00C650"/>
        <path d="M41.4849 93.75C41.0953 93.75 40.819 93.495 40.6561 92.985L36.7249 80.5325C36.6399 80.2633 36.6788 79.9941 36.8417 79.725C37.0117 79.4558 37.2561 79.3212 37.5749 79.3212C37.7449 79.3212 37.9078 79.3779 38.0636 79.4912C38.2194 79.5975 38.3328 79.7675 38.4036 80.0012L41.8674 91.03H41.3999L44.6936 80.1075C44.7715 79.8525 44.9026 79.6612 45.0867 79.5337C45.278 79.4062 45.4728 79.3425 45.6711 79.3425C45.8836 79.3425 46.089 79.4062 46.2874 79.5337C46.4857 79.6612 46.6203 79.8525 46.6911 80.1075L49.9849 91.03H49.5174L52.9812 80.0012C53.0591 79.7675 53.1687 79.6012 53.3104 79.5021C53.4591 79.3958 53.6187 79.3425 53.7887 79.3425C54.1004 79.3425 54.3483 79.4629 54.5324 79.7037C54.7166 79.9446 54.7591 80.2208 54.6599 80.5325L50.7287 92.985C50.5587 93.495 50.247 93.75 49.7937 93.75H49.7511C49.2765 93.75 48.9649 93.495 48.8161 92.985L45.3949 81.9137H45.8624L42.4199 92.985C42.349 93.24 42.2428 93.4312 42.1011 93.5587C41.9665 93.6862 41.7611 93.75 41.4849 93.75Z" fill="white"/>
        <path d="M56.0991 93.665C55.5324 93.4455 55.3624 93.0559 55.5891 92.4963L60.6466 80.0438C60.8591 79.5763 61.192 79.3425 61.6453 79.3425H61.6878C62.1483 79.3638 62.4599 79.5975 62.6228 80.0438L67.7653 92.4963C67.992 93.0559 67.8291 93.4455 67.2766 93.665C66.717 93.8846 66.3274 93.7217 66.1078 93.1763L64.9603 90.3925H58.3412L57.2466 93.1763C57.027 93.7288 56.6445 93.8917 56.0991 93.665ZM59.0424 88.6075H64.2274L61.5816 82.1688L59.0424 88.6075Z" fill="white"/>
        <path d="M74.8151 93.75C74.4468 93.75 74.1563 93.5162 73.9438 93.0487L68.6951 80.6175C68.5888 80.3695 68.5605 80.15 68.6101 79.9587C68.6597 79.7604 68.8226 79.5975 69.0988 79.47C69.6797 79.1795 70.0905 79.3283 70.3313 79.9162L75.2934 91.6675H74.6772L79.5113 79.9162C79.7451 79.3354 80.1559 79.1866 80.7438 79.47C80.9918 79.5975 81.1443 79.7604 81.2009 79.9587C81.2647 80.15 81.2468 80.3695 81.1476 80.6175L75.8563 93.0487C75.6368 93.5162 75.318 93.75 74.9001 93.75H74.8151Z" fill="white"/>
        <path d="M58.5167 54.9667H53.9334V36.5251C53.9334 35.9073 53.6879 35.3149 53.2513 34.8781C52.8142 34.4413 52.2221 34.1959 51.6042 34.1959H39.9375C39.3198 34.1959 38.7273 34.4413 38.2905 34.8781C37.8537 35.3149 37.6084 35.9073 37.6084 36.5251V54.9667H33.0459V34.9667C33.0459 33.55 33.6086 32.1914 34.6104 31.1896C35.6122 30.1879 36.9708 29.6251 38.3875 29.6251H53.1417C54.5584 29.6251 55.9171 30.1879 56.9188 31.1896C57.9204 32.1914 58.4834 33.55 58.4834 34.9667L58.5167 54.9667Z" fill="#00C650"/>
        <path d="M53.1083 60.375C56.0954 60.375 58.5166 57.9538 58.5166 54.9667C58.5166 51.9796 56.0954 49.5583 53.1083 49.5583C50.1212 49.5583 47.7 51.9796 47.7 54.9667C47.7 57.9538 50.1212 60.375 53.1083 60.375Z" fill="#00C650"/>
        <path d="M32.2499 60.375C35.2368 60.375 37.6582 57.9538 37.6582 54.9667C37.6582 51.9796 35.2368 49.5583 32.2499 49.5583C29.263 49.5583 26.8416 51.9796 26.8416 54.9667C26.8416 57.9538 29.263 60.375 32.2499 60.375Z" fill="#00C650"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default WavSvg;
