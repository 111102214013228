import styled from "@emotion/styled/macro";
import { Radio } from "antd";


export const TaskDetailedBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const TaskDetailedHeading = styled.div`
  color: #999;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const TaskDetailedTabs = styled(Radio.Group)`
  margin-bottom: 10px;
  .ant-radio-button-wrapper {
    border-radius: 5px;
    margin-right: 5px;
    border: 1px solid transparent;
    color: #999;
    font-weight: 500;
    font-size: 13px;
    transition: background-color 200ms ease;
    &:hover {
      background-color: #f6f6f6;
    }
  }
  .ant-radio-button-wrapper-checked {
    background-color: #f6f6f6;
    border-color: transparent !important;
    &:hover, :focus, :focus-within {
      box-shadow: none;
      color: #999;
    }
  }
  .ant-radio-button-wrapper::before {
    display: none;
  }
  .ant-radio-button-wrapper-checked {
    z-index: 0;
  }
`;

export const TaskDetailedTabPane = styled(Radio.Button)`
  color: #49a3e7;
`;
