import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TFilterFunctions } from "lib/filters/filterFunctions";
import { FilterOperatorEnum } from "lib/filters/filterOperators";
import { FilterValueNone, FilterVersion, MultipleFilter, noneFilterValue } from "lib/filters/filters";
import { FilterEnv, FilterType } from "lib/filters/filterTypes";
import { TaskSortDirection, TasksSortType } from "lib/sort/tasksSort";
import { EntityId } from "lib/types/entity";
import { nanoid } from "nanoid";
import { StoreType } from "../store";
import { generalFilters } from "lib/filters/generalFilter";

interface InitialStateType {
  filter: {
    titleKey: string,
    value: {
      text: any,
      valueKey: string | number | boolean | string[]
      id?: EntityId
    },
    types: Record<string, FilterType>,
    function: TFilterFunctions,
    version: FilterVersion
    multiple?: MultipleFilter[]
    filterEnv?: FilterEnv
    operator?: FilterOperatorEnum
  }
  sort: {
    field: {
      title: string,
      code: string
    },
    order: TaskSortDirection
  },
  generalFilter: generalFilters
}

const initialState: InitialStateType = {
  filter: {
    titleKey: FilterValueNone,
    value: {
      text: FilterValueNone,
      valueKey: FilterValueNone
    },
    types: {},
    function: "",
    version: FilterVersion.v3,
    multiple: [],
    filterEnv: undefined,
    operator: FilterOperatorEnum.and
  },
  sort: {
    field: {
      title: FilterValueNone,
      code: FilterValueNone,
    },
    order: TaskSortDirection.Ascending
  },
  generalFilter: generalFilters.IncompleteTasks
};

export const tasksFilterAndSortSlice = createSlice({
  name: "tasksFilterAndSort",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<InitialStateType["filter"]>) => {
      state.filter.titleKey = action.payload.titleKey;
      state.filter.value = { ...action.payload.value };
      state.filter.function = action.payload.function;
      state.filter.version = action.payload.version;
      state.filter.multiple = action.payload.multiple?.length ? [...action.payload.multiple] : [];
      state.filter.operator = action.payload.operator || FilterOperatorEnum.and;
    },
    setFilterTitle: (state, action: PayloadAction<string>) => {
      state.filter.titleKey = action.payload;
    },
    setFilterTitleMultiple: (state, action: PayloadAction<{ titleKey: string, id: string }>) => {
      if (state.filter.multiple) {
        const index = state.filter.multiple.findIndex((filter => filter.id === action.payload.id));
        state.filter.multiple[index].titleKey = action.payload.titleKey;
      }
    },
    setFilterValue: (state, action: PayloadAction<{ text: any, valueKey: string | number | boolean | string[] }>) => {
      state.filter.value = action.payload;
    },
    setFilterValueMultiple: (state, action: PayloadAction<{ value: { text: any, valueKey: string | number | boolean }, id: string }>) => {
      if (state.filter.multiple) {
        const index = state.filter.multiple.findIndex((filter => filter.id === action.payload.id));
        state.filter.multiple[index].value = action.payload.value;
      }
    },
    removeFilterValueMultiple: (state, action: PayloadAction<{ id: string }>) => {
      if (state.filter.multiple) {
        const index = state.filter.multiple.findIndex((filter => filter.id === action.payload.id));
        state.filter.multiple.splice(index, 1);
      }
    },
    setFilterV3: (state) => {
      state.filter.version = FilterVersion.v3;
    },
    setSortField: (state, action: PayloadAction<{ title: string, code: string }>) => {
      state.sort.field = action.payload;
    },
    setSortOrder: (state, action: PayloadAction<TaskSortDirection>) => {
      state.sort.order = action.payload;
    },
    setGeneralFilter: (state, action: PayloadAction<generalFilters>) => {
      state.generalFilter = action.payload;
    },
    setSort: (state, action: PayloadAction<TasksSortType>) => {
      state.sort = action.payload;
    },
    setFilterTypes: (state, action: PayloadAction<Record<string, FilterType>>) => {
      state.filter.types = { ...state.filter.types, ...action.payload };
    },
    setFilterFunction: (state, action: PayloadAction<TFilterFunctions>) => {
      state.filter.function = action.payload;
    },
    setFilterFunctionMultiple: (state, action: PayloadAction<{ function: TFilterFunctions, id: string }>) => {
      if (state.filter.multiple) {
        const index = state.filter.multiple.findIndex((filter => filter.id === action.payload.id));
        state.filter.multiple[index].function = action.payload.function;
      }
    },
    setFilterEnv: (state, action: PayloadAction<FilterEnv | undefined>) => {
      state.filter.filterEnv = action.payload;
    },
    setFilterOperator: (state, action: PayloadAction<FilterOperatorEnum>) => {
      state.filter.operator = action.payload;
    },
    resetAllFilersAndSort: () => initialState,
    resetOnlyFilters: (state) => {
      state.filter.titleKey = initialState.filter.titleKey;
      state.filter.value = initialState.filter.value;
      state.filter.function = initialState.filter.function;
      state.filter.multiple = initialState.filter.multiple;
      state.filter.operator = initialState.filter.operator;
    },

    /* Multiple Filters */
    createNewFilter: (state) => {
      const newEmptyFilter: MultipleFilter = {
        id: nanoid(),
        titleKey: FilterValueNone,
        value: {
          text: FilterValueNone,
          valueKey: FilterValueNone
        },
        function: "",
        version: FilterVersion.v3,
        multiple: [],
        operator: FilterOperatorEnum.and
      };

      if (state.filter.multiple?.length === 0 || !state.filter.multiple) {
        const oldFilter = {
          id: nanoid(),
          titleKey: state.filter.titleKey,
          value: { ...state.filter.value },
          function: state.filter.function,
          version: FilterVersion.v3,
          operator: state.filter.operator || FilterOperatorEnum.and
        };
        state.filter.multiple = [oldFilter, newEmptyFilter];
        state.filter.titleKey = FilterValueNone;
        state.filter.value = noneFilterValue;

      } else {
        state.filter.multiple =
          state.filter.multiple
            ? [...state.filter.multiple, newEmptyFilter]
            : [newEmptyFilter];
      }
    }
  },
});

export const selectGeneralFilter = (state: StoreType) => state.boardFilterAndSort.generalFilter;
export const selectCurrentFilter = (state: StoreType) => state.boardFilterAndSort.filter;
export const selectCurrentSort = (state: StoreType) => state.boardFilterAndSort.sort;
export const selectFilterTypes = (state: StoreType) => state.boardFilterAndSort.filter.types;
export const selectFilterEnv = (state: StoreType) => state.boardFilterAndSort.filter.filterEnv;
export const selectFilterOperator = (state: StoreType) => state.boardFilterAndSort.filter.operator;

export const {
  setFilter,
  setFilterTitle,
  setFilterValue,
  setFilterTitleMultiple,
  setFilterValueMultiple,
  setFilterV3,
  setSortField,
  setSortOrder,
  setSort,
  setGeneralFilter,
  resetAllFilersAndSort,
  setFilterTypes,
  setFilterFunction,
  setFilterFunctionMultiple,
  removeFilterValueMultiple,
  createNewFilter,
  setFilterEnv,
  setFilterOperator,
  resetOnlyFilters
} = tasksFilterAndSortSlice.actions;

export default tasksFilterAndSortSlice.reducer;
