import { FC } from "react";

import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import { TaskSubtaskInterface } from "lib/types/tasksTypes";

import ProgressList from "./Display/generic";
import { ProgressListWrapper } from "./Display/styled";

interface ProgressFieldProps extends TaskFieldPropsGeneric {
  subtasks: Array<TaskSubtaskInterface>
}

const ProgressField: FC<ProgressFieldProps> = (props) => {
  const { component } = props;

  switch (component) {
    case TaskSourceComponent.Cell:
      return (
        <ProgressListWrapper>
          <ProgressList {...props} />
        </ProgressListWrapper>
      );
    default:
      return null;
  }
};

export default ProgressField;
