import { Form } from "antd";
import { FC, useState } from "react";

import AuthService from "services/AuthService";
import { ErrorMessages } from "lib/helpers/ErrorMessages";
import { captureException } from "lib/utils/sentry";
import { JSendError, JSendStatus } from "lib/types/jsend";
import AuthEmail from "pages/Auth/AuthComponents/Email";
import AuthSignInButton from "pages/Auth/AuthComponents/SignInButton";
import AuthContainer from "pages/Auth/index";
import { AuthReactLink } from "pages/Auth/styled";

const ForgotPassword: FC = () => {
  const [form] = Form.useForm();
  const [email, setEmail] = useState("");
  const [showSuccessText, setShowSuccessText] = useState(false);

  const onValuesChange = (valueChanged: { email?: string }) => {
    if (valueChanged.email) {
      setEmail(valueChanged.email);
    }
  };

  const onFinish = async () => {
    try {
      const data = await AuthService.forgotPassword(email);
      if (data?.status === JSendStatus.Success) {
        setShowSuccessText(true);
      }

      if (data?.status === JSendStatus.Error) {
        throw data;
      }

    } catch (e: any) {
      let errorMessage = "";
      if (e?.status && e?.message) {
        const jsendError: JSendError = e;
        errorMessage = ErrorMessages[jsendError.message];
      }

      captureException(e, true, errorMessage);
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      style={{ width: "100%" }}
      requiredMark={false}
      layout="vertical"
    >
      <AuthContainer
        header="Password reset"
        emailComponent={<AuthEmail autoFocus />}
        linkComponent={<AuthReactLink to="/auth/login">Back to Sign In</AuthReactLink>}
        signButtonHelpText={
          showSuccessText &&
          <div style={{ fontSize: "13px", color: "#646f79" }}>
            A password reset link was sent
          </div>
        }
        signInButton={<AuthSignInButton text="Send password reset link" />}
      />
    </Form>
  );
};

export default ForgotPassword;
