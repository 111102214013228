import { FC, RefObject, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Form, Tooltip } from "antd";

import {
  TaskTitleInputWrapper,
  TaskTitleWrapper,
  TitleInput
} from "components/blocks/TaskTable/components/TaskTitle/styled";
import { useBoardContext } from "lib/contexts/BoardContext";
import { KeyboardKeys } from "lib/types/keyCodes";
import { TaskInterface, TaskSetValueHandler } from "lib/types/tasksTypes";
import SyncedFieldTooltip from "components/blocks/SyncedFieldTooltip";
import TaskCheckmark from "pages/Task/TaskCheckmark";
import { ExpandWrapper, TaskExpandButton } from "pages/Viewer/ViewerTasks/TaskItem/styled";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import { useTaskContext } from "lib/contexts/TaskContext";
import { DefaultIconColor } from "components/icons/lib";
import { DummyRowId } from "../../utils/listReducer";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import CheckPlaceholder from "pages/Task/TaskCheckmark/CheckPlaceholder";

interface TaskTitleLiteProps {
  value: string;
  afterCreate?: () => void;
  onExpand?: (recordId: string) => void;
  setValue: TaskSetValueHandler;
}

const TaskTitleLite: FC<TaskTitleLiteProps> = (props) => {
  const { value, setValue, onExpand } = props;
  const history = useHistory();

  const { task } = useTaskContext();
  const { board } = useBoardContext();

  const { isCompletionEnabled } = useBoardFeatures(board || task?.board);

  const [form] = Form.useForm();
  const titleRef = useRef(null) as RefObject<any>;
  const fieldName = `title_${task?.id}`;

  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);
  const allowedToComplete = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const isSyncedBoard = !!board?.externalDataSource || !!(task?.board?.externalDataSource as unknown as TaskInterface);

  const textColor = (isCompletionEnabled && task?.completed) ? DefaultIconColor : "black";

  useEffect(() => {
    form.setFieldsValue({ [fieldName]: value });
    // eslint-disable-next-line
  }, [value]);

  const onExpandTask = () => {
    if (!task) {
      return;
    }

    if (onExpand) {
      return onExpand(task?.uid);
    }

    if (history.location.pathname.slice(-1) === "/") {
      history.push(`${history.location.pathname}${task?.uid}`);
    } else {
      history.push(`${history.location.pathname}/${task?.uid}`);
    }
  };

  const handleOnBlur = async () => {
    try {
      const values = await form.validateFields();
      const updateValue = values[fieldName].trim();

      if (updateValue === value) {
        return;
      }

      if (!updateValue) {
        form.setFieldsValue({ [fieldName]: value });
        return;
      }

      await setValue({ cellName: "title", cellValue: values[fieldName] });

      if (props.afterCreate && task?.id === DummyRowId) {
        props.afterCreate();
      }
    } catch (error) {
      form.setFieldsValue({ [fieldName]: value });
      return;
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyboardKeys.Escape) {
      event.preventDefault();
      titleRef.current.blur();
      form.setFieldsValue({ [fieldName]: value });
    }

    if (event.key === KeyboardKeys.Tab || event.key === KeyboardKeys.Enter) {
      event.preventDefault();
      titleRef.current.blur();
    }
  };

  return (
    <TaskTitleWrapper>
      {isCompletionEnabled &&
        <TaskCheckmark isCompleted={!!task?.completed} clickable={allowedToComplete} id={task?.id} />
      }
      {!isCompletionEnabled &&
        <CheckPlaceholder />
      }
      <TaskTitleInputWrapper>
        <Tooltip title={task?.title} mouseLeaveDelay={0}>
          <Form form={form} initialValues={{ [fieldName]: value }}>
            <Form.Item
              name={fieldName}
              rules={
                [{ required: true, message: "required", whitespace: true }]
              }
            >
              <TitleInput
                ref={titleRef}
                size="small"
                autoComplete="off"
                onKeyDown={handleOnKeyDown}
                onBlur={handleOnBlur}
                style={{ color: textColor }}
                disabled={isSyncedBoard || !allowedToEdit}
                suffix={isSyncedBoard && <SyncedFieldTooltip />}
              />
            </Form.Item>
          </Form>
        </Tooltip>
      </TaskTitleInputWrapper>
      {task?.title && (
        <ExpandWrapper onClick={onExpandTask}>
          <TaskExpandButton>
            <img src={process.env.PUBLIC_URL + "/icons/expand.svg"} alt="expand task" />
          </TaskExpandButton>
        </ExpandWrapper>
      )}
    </TaskTitleWrapper>
  );
};

export default TaskTitleLite;
