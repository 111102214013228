import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const PolylineSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path d="M95.375 29.4165L92.7083 26.7498C92.0835 26.1265 91.2368 25.7764 90.3542 25.7764C89.4715 25.7764 88.6249 26.1265 88 26.7498L59.7917 54.9582L42.625 37.7915C41.9565 37.1178 41.1566 36.5888 40.2749 36.2376C39.3932 35.8863 38.4486 35.7203 37.5 35.7498C36.5732 35.7444 35.6545 35.9219 34.7965 36.2722C33.9385 36.6226 33.1581 37.1389 32.5 37.7915L4.625 65.8332C4.01339 66.473 3.67041 67.323 3.66667 68.2082C3.65961 68.648 3.74078 69.0848 3.90538 69.4927C4.06998 69.9006 4.31468 70.2714 4.625 70.5832L7.29167 73.2498C7.92179 73.8632 8.76645 74.2065 9.64584 74.2065C10.5252 74.2065 11.3699 73.8632 12 73.2498L37.5 47.5832L54.6667 64.7498C55.9872 66.0704 57.7783 66.8122 59.6458 66.8122C61.5134 66.8122 63.3044 66.0704 64.625 64.7498L95.375 34.1665C95.9866 33.5266 96.3296 32.6766 96.3333 31.7915C96.3404 31.3517 96.2592 30.9149 96.0946 30.507C95.93 30.099 95.6853 29.7282 95.375 29.4165Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default PolylineSvg;
