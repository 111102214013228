import { FC } from "react";
import { useParams } from "react-router-dom";

import { getBoardViewUri } from "lib/helpers/navigationUtils";
import { DisplayView } from "lib/types/applicationTypes";
import NotFoundSection from "components/blocks/NotFoundSection";

const BoardViewNotFound: FC = () => {
  const { id } = useParams<{ id: string }>();
  const target = getBoardViewUri(id, DisplayView.List);

  return (
    <NotFoundSection
      text="No view found"
      backLink={target}
      backLinkText="Back to board"
    />
  );
};

export default BoardViewNotFound;
