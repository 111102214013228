import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";

export const NotificationsHeader = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 32px;
`;

// Board styles
export const NotificationsBoardWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  white-space: nowrap;
`;

export const NotificationsBoardIcon = styled.img`
  height: 13px;
  margin-right: 9px;
`;

export const NotificationsBoardName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #999999;
`;


// Task styles
export const NotificationsTaskWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const NotificationsCheckmarkWrapper = styled.div`
  margin-left: -2px;
  margin-right: 7px;
`;

export const NotificationsTaskTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333;
  font-weight: 600;
  font-size: 16px;
  width: auto;
`;


// Controls
export const NotificationsUnreadCircle = styled.div`
  position: absolute;
  margin: 17px 17px 0 0;
  left: auto;
  top: 0;
  right: 0;
  bottom: auto;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: #01a5ed;
`;

export const NotificationsButtonsWrapper = styled.div`
  position: absolute;
  display: flex;
  opacity: 0;
  margin: 6px 6px 0 0;
  padding: 4px;
  left: auto;
  top: 0;
  right: 0;
  bottom: auto;
  transition: opacity 200ms ease;
  background-color: white;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
`;

export const notificationsWrapper = css`
  padding: 12px 15px 0 15px;
  position: relative;
  width: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  overflow: hidden;
`;


// Notification event
export const NotificationsEventWrapper = styled.div`
  position: relative;
  background-color: #fdfdfd;
  margin: 0 -15px;
  border-top: 1px solid #f2f2f2;
  padding: 7px 80px 7px 10px;
  &:hover {
    ${NotificationsButtonsWrapper} {
      opacity: 100%;
    }
  }
`;

export const NotificationsUserAvatarWrapper = styled.div`
  padding-right: 5px;
`;

export const NotificationsUserName = styled.div`
  display: inline;
  font-size: 13px;
  font-weight: 600;
`;

export const NotificationsEventName = styled.div`
  display: inline;
  font-size: 13px;
  margin-left: 4px;
`;

export const NotificationsCreatedWrapper = styled.div`
  display: flex;
`;

export const NotificationsCreated = styled.div`
  display: inline-block;
  font-size: 12px;
  color: #6f7782;
`;

// Other
export const NotificationsVirtuosoContainer = styled.div`
  margin-inline: 15px;
  padding-top: 15px;
`;
