import { TFilterFn } from "../filterTypes";
import { assigneeFilter } from "./assigneeFilter";
import { booleanFilter } from "./booleanFilter";
import { dateFilter } from "./dateFilter";
import { dueDateFilter } from "./dueDateFilter";
import { boardFilter, noneFilter, projectFilter, viewpointsFilter } from "./filterPresets";
import { multiSelectFilter } from "./multiSelectFilter";
import { numberFilter } from "./numberFilters";
import { selectFilter } from "./selectFilter";
import { statusFilter } from "./statusFilter";
import { textFilter } from "./textFilter";

export const allCustomPresets = {
  textFilter,
  selectFilter,
  booleanFilter,
  multiSelectFilter,
  numberFilter,
  dateFilter,
  dueDateFilter,
  assigneeFilter,
  statusFilter,
  noneFilter,
  boardFilter,
  projectFilter,
  viewpointsFilter,
};

export const allFixedPresets: TFilterFn = {
  None: noneFilter,
  Assignees: assigneeFilter,
  "Due date": dueDateFilter,
  Status: statusFilter,
  Title: textFilter,
  Viewpoints: viewpointsFilter,
  Board: boardFilter,
  "Created by": assigneeFilter,
  "Last modified by": assigneeFilter,
  uID: textFilter,
};

export {
  textFilter,
  selectFilter,
  booleanFilter,
  multiSelectFilter,
  numberFilter,
  dateFilter,
  dueDateFilter,
  assigneeFilter,
  statusFilter
};
