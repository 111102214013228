import { Badge } from "antd";
import { FC } from "react";
import { TaskCalendarItem } from "../styled";
import { TaskInterface } from "lib/types/tasksTypes";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

interface TaskCalendarCellProps {
  task: TaskInterface;
  onItemClick?: () => void;
  color: string;
  additionalStyles?: React.CSSProperties;
}

const TaskCalendarCell: FC<TaskCalendarCellProps> = (props) => {
  const { task, onItemClick, color, additionalStyles } = props;
  const { isStatusEnabled } = useBoardFeatures(task.board);

  return (
    <TaskCalendarItem
      onClick={onItemClick}
      completed={task.completed}
    >
      {isStatusEnabled &&
        <Badge
          text={task?.title}
          color={color}
          style={additionalStyles}
        />
      }
      {!isStatusEnabled &&
        <text style={additionalStyles}>
          {task.title}
        </text>
      }
    </TaskCalendarItem>
  );
};

export default TaskCalendarCell;
