import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const BmpSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.4708 20.5167C64.1921 20.5155 62.0071 19.6102 60.3954 17.9993C58.7837 16.3884 57.8771 14.2037 57.875 11.925V0H19.4875C18.0147 -1e-07 16.5564 0.29014 15.1957 0.853883C13.8351 1.41763 12.5988 2.2439 11.5576 3.28552C10.5164 4.32713 9.69052 5.56371 9.12728 6.92454C8.56404 8.28538 8.27448 9.74388 8.27503 11.2167V78.8042C8.27448 80.2771 8.56404 81.7354 9.12728 83.0963C9.69052 84.4571 10.5164 85.6938 11.5576 86.7354C12.5988 87.7771 13.8351 88.6033 15.1957 89.1671C16.5564 89.7308 18.0147 90.0208 19.4875 90.0208H65.5708C67.0437 90.0208 68.5021 89.7308 69.8625 89.1671C71.2233 88.6033 72.4596 87.7771 73.5008 86.7354C74.5421 85.6938 75.3679 84.4571 75.9308 83.0963C76.4941 81.7354 76.7837 80.2771 76.7833 78.8042V20.5208L66.4708 20.5167Z" fill="#A140FF"/>
        <path d="M76.7833 20.5167H66.4708C64.1921 20.5155 62.0071 19.6102 60.3954 17.9993C58.7837 16.3884 57.8771 14.2037 57.875 11.925V0L76.7833 20.5167Z" fill="#A140FF"/>
        <path d="M85.0417 71.0833H32.9417C29.246 71.0833 26.25 74.0791 26.25 77.7749V93.3083C26.25 97.0041 29.246 99.9999 32.9417 99.9999H85.0417C88.7375 99.9999 91.7333 97.0041 91.7333 93.3083V77.7749C91.7333 74.0791 88.7375 71.0833 85.0417 71.0833Z" fill="#A140FF"/>
        <path d="M39.5035 93.75C38.9085 93.75 38.611 93.4525 38.611 92.8575V80.235C38.611 79.64 38.9085 79.3425 39.5035 79.3425H45.0497C46.176 79.3425 47.0401 79.64 47.6422 80.235C48.2514 80.83 48.556 81.68 48.556 82.785V83.3588C48.556 84.315 48.2549 85.055 47.6528 85.5792C48.7791 86.2096 49.3422 87.3609 49.3422 89.0325V89.755C49.3422 91.0584 48.9951 92.05 48.301 92.73C47.6139 93.41 46.6222 93.75 45.326 93.75H39.5035ZM40.396 91.965H45.326C46.1264 91.965 46.6966 91.795 47.0366 91.455C47.3837 91.1079 47.5572 90.5413 47.5572 89.755V89.0325C47.5572 88.2463 47.3908 87.6867 47.0578 87.3538C46.7249 87.0138 46.1476 86.8438 45.326 86.8438H40.396V91.965ZM40.396 85.0588H45.4322C45.9493 85.0588 46.2999 84.8992 46.4841 84.5804C46.6753 84.2617 46.771 83.8546 46.771 83.3588V82.785C46.771 82.1688 46.6435 81.74 46.3885 81.4992C46.1335 81.2513 45.6872 81.1275 45.0497 81.1275H40.396V85.0588Z" fill="white"/>
        <path d="M53.8017 93.75C53.2067 93.75 52.9092 93.4525 52.9092 92.8575V80.235C52.9092 79.64 53.2067 79.3425 53.8017 79.3425C54.1913 79.3425 54.5171 79.5338 54.7792 79.9163L59.7942 87.46L59.4117 87.5238L64.4692 79.9163C64.71 79.5338 65.0713 79.3425 65.5529 79.3425C66.0346 79.3425 66.2754 79.64 66.2754 80.235V92.8575C66.2754 93.4525 65.9779 93.75 65.3829 93.75C64.7879 93.75 64.4904 93.4525 64.4904 92.8575V82.02L65.0429 82.2113L60.5804 88.7775C60.3183 89.16 59.9571 89.3513 59.4967 89.3513C59.1779 89.3513 58.8875 89.16 58.6254 88.7775L54.1629 82.2113L54.6942 82.36V92.8575C54.6942 93.4525 54.3967 93.75 53.8017 93.75Z" fill="white"/>
        <path d="M71.5859 93.75C70.9909 93.75 70.6934 93.4525 70.6934 92.8575V80.235C70.6934 79.64 70.9909 79.3425 71.5859 79.3425H76.5584C77.8546 79.3425 78.8463 79.6896 79.5334 80.3838C80.2275 81.0709 80.5746 82.0625 80.5746 83.3588V83.9963C80.5746 85.2925 80.2275 86.2875 79.5334 86.9817C78.8463 87.6688 77.8546 88.0125 76.5584 88.0125H72.4784V92.8575C72.4784 93.4525 72.1809 93.75 71.5859 93.75ZM72.4784 86.2275H76.5584C77.3659 86.2275 77.9396 86.0575 78.2796 85.7175C78.6196 85.3775 78.7896 84.8038 78.7896 83.9963V83.3588C78.7896 82.5584 78.6196 81.9879 78.2796 81.6479C77.9396 81.3009 77.3659 81.1275 76.5584 81.1275H72.4784V86.2275Z" fill="white"/>
        <path d="M51.7334 61.0041H33.3334C31.5316 61.0021 29.8044 60.2854 28.5304 59.0112C27.2564 57.7375 26.5397 56.01 26.5375 54.2083V35.8042C26.5397 34.0025 27.2564 32.2752 28.5304 31.0012C29.8044 29.7272 31.5316 29.0105 33.3334 29.0083H51.7375C53.5388 29.0105 55.2659 29.7273 56.5392 31.0015C57.8125 32.2756 58.5279 34.0028 58.5292 35.8042V54.2083C58.5279 56.0104 57.8117 57.7383 56.5375 59.0125C55.2634 60.2866 53.5354 61.0029 51.7334 61.0041ZM33.3334 32.0125C32.3281 32.0136 31.3644 32.4134 30.6535 33.1243C29.9427 33.8351 29.5427 34.7989 29.5416 35.8042V54.2083C29.5427 55.2137 29.9427 56.1775 30.6535 56.8883C31.3644 57.5991 32.3281 57.9987 33.3334 58H51.7375C52.7421 57.9979 53.7046 57.5975 54.4146 56.8866C55.1246 56.1758 55.5238 55.2129 55.525 54.2083V35.8042C55.5238 34.7996 55.1246 33.8365 54.4146 33.1257C53.7046 32.415 52.7421 32.0146 51.7375 32.0125H33.3334Z" fill="#A140FF"/>
        <path d="M57.025 50.7084V54.2084C57.0237 55.6114 56.4662 56.9568 55.4737 57.9488C54.4816 58.9409 53.1362 59.4989 51.7333 59.5001H33.3333C31.9295 59.5001 30.5831 58.9426 29.59 57.9505C28.597 56.958 28.0386 55.6122 28.0375 54.2084V48.3334C30.8969 47.7197 35.2009 47.5126 40.422 49.4813C40.727 49.5963 41.0721 49.5309 41.308 49.3059L43.3997 47.3126C43.8279 46.9043 44.533 47.0597 44.7499 47.6101L45.6967 50.0114C45.9886 50.7518 47.4137 50.7768 48.1685 50.5247C48.4628 50.4264 48.806 50.3893 49.2042 50.4501C51.1958 50.7543 54.4208 51.8334 55.8 50.9126C56.1704 50.6993 56.6054 50.6268 57.025 50.7084Z" fill="#A140FF"/>
        <path d="M50.5792 39.5626C51.7092 39.5626 52.625 38.6467 52.625 37.5168C52.625 36.3869 51.7092 35.4709 50.5792 35.4709C49.4492 35.4709 48.5333 36.3869 48.5333 37.5168C48.5333 38.6467 49.4492 39.5626 50.5792 39.5626Z" fill="#A140FF"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default BmpSvg;
