import { Dropdown, Menu, Tooltip } from "antd";
import { setGeneralFilter } from "app/slices/tasksFilterAndSortSlice";
import { useAppDispatch, useAppSelector } from "app/store";
import ButtonControls from "components/ui/ButtonControls";
import TasksSvg from "components/ui/ButtonControls/icons/tasks";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import useBoardViewSave from "lib/customHooks/views/useBoardViewSave";
import { generalFilters } from "lib/filters/generalFilter";
import { MemberRoles } from "lib/types/types";
import { FC } from "react";
import { activeHiddenTasksClass } from "./styled";

interface GeneralFilterProps {
  isCustomView?: boolean;
  hideLabel?: boolean;
}

const GeneralFilter: FC<GeneralFilterProps> = (props) => {
  const { isCustomView, hideLabel } = props;
  const dispatch = useAppDispatch();
  const currentFilter = useAppSelector(state => state.boardFilterAndSort.generalFilter);
  const isActive = currentFilter !== generalFilters.AllTasks;
  const { saveView } = useBoardViewSave();

  const allowedToEditCustomViews = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const isSortCustomViewsDisabled = isCustomView && !allowedToEditCustomViews;

  const onClickMenu = (item: generalFilters) => {
    dispatch(setGeneralFilter(item));
    saveView({ generalFilter: item });
  };

  const tasks = (
    <Menu>
      {Object.values(generalFilters).map((item) => {
        return (
          <Menu.Item key={item} onClick={() => onClickMenu(item)}>
            {item}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown
      overlay={tasks}
      placement="bottom"
      trigger={["click"]}
      destroyPopupOnHide
      disabled={isSortCustomViewsDisabled}
      overlayStyle={{ animationDuration: "0s" }}
    >
      <Tooltip title={hideLabel ? currentFilter : ""}>
        <>
          <ButtonControls
            icon={<TasksSvg />}
            text={hideLabel ? "" : currentFilter}
            className={isActive ? activeHiddenTasksClass : ""}
          />
        </>
      </Tooltip>
    </Dropdown>
  );
};

export default GeneralFilter;
