import { autocomplete, GetSources } from "@algolia/autocomplete-js";
import { createElement, FC, Fragment, useEffect, useRef } from "react";
import { render } from "react-dom";

interface SearchAutocompleteProps {
  openOnFocus?: boolean;
  getSources: GetSources<any>;
}

const SearchAutocomplete: FC<SearchAutocompleteProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      placeholder: "Search",
      renderer: { createElement, Fragment },
      render({ children }, root) {
        render(children, root);
      },
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [props]);

  return (<div ref={containerRef} />);
};

export default SearchAutocomplete;
