import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const AnnotationSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path fillRule="evenodd" clipRule="evenodd" d="M94.6462 6.91828C90.6087 1.98074 83.3028 1.70774 78.9005 6.11015L57.1214 27.89C53.6975 31.314 51.7771 35.9553 51.7771 40.7941V44.3753C51.7771 46.5148 53.5124 48.2503 55.652 48.2503H59.2331C64.0721 48.2503 68.7169 46.3258 72.1369 42.9058L93.5672 21.4747C97.3748 17.667 98.2407 11.3158 94.6462 6.91828Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M87.5416 36.6299C91.612 35.8623 95.534 38.5396 96.3017 42.61C100.275 63.6782 86.6761 78.3026 71.0817 81.4925C63.3401 83.0761 54.8574 81.9296 47.6759 77.2672C40.39 72.537 35.0948 64.6147 33.0629 53.897C32.2913 49.8274 34.965 45.9028 39.0346 45.1313C43.1043 44.3597 47.0288 47.0333 47.8004 51.103C49.1707 58.3307 52.4209 62.4638 55.8439 64.6861C59.3713 66.9762 63.7611 67.6793 68.0757 66.7968C76.5937 65.0544 83.8424 57.4834 81.5616 45.39C80.7939 41.3196 83.4713 37.3976 87.5416 36.6299Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.5539 93.0208C39.6095 96.6782 35.0683 98.0668 31.4109 96.1224C8.58331 83.9861 -0.329353 60.1924 4.10044 39.6677C6.32122 29.378 11.9176 19.8172 20.8448 13.0651C29.8127 6.28217 41.6916 2.67047 55.8764 3.51314C60.0112 3.75878 63.164 7.30986 62.9184 11.4447C62.6728 15.5796 59.1217 18.7324 54.9868 18.4867C43.9216 17.8294 35.6755 20.6552 29.8934 25.0285C24.0707 29.4326 20.292 35.7469 18.7628 42.8322C15.6926 57.0575 21.78 74.0139 38.4524 82.8778C42.1098 84.8223 43.4984 89.3635 41.5539 93.0208Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default AnnotationSvg;
