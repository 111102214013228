import { DefaultColumnCodes } from "lib/types/boardTypes";

interface ExportBoardOption {
  code: string;
  text: string;
}

export interface ExportBoardOptionChangeHandler {
  (checked: boolean, property: string): void;
}

export const ExportBoardFilterOptions: Array<ExportBoardOption> = [
  {
    code: "completed",
    text: "Completed records",
  }
];

export const ExportBoardIncludeOptions: Array<ExportBoardOption> = [
  {
    code: "summary",
    text: "Summary page",
  },
  {
    code: "comments",
    text: "Comments",
  },
];

export const ExportBoardDefaultColumnSet = [
  { title: "Completion", code: DefaultColumnCodes.Completion },
  { title: "Status", code: DefaultColumnCodes.Status },
];
