import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Form, Modal, Switch } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { BoardAutomationInterface } from "lib/types/boardTypes";
import { StyledButton } from "styles/common";
import CustomStyledButton from "components/blocks/CustomStyledButton";
import { checkIfSaveEnabled, prepareAutomationData, prepareUpdateValue } from "../helpers";
import { Action, TActiveTab, TFormType, Trigger } from "../types";
import { AutomationActiveTabContext, AutomationFormContext, RemovePropertyContext } from "./automationContext";
import AutomationsBuilder from "./AutomationsBuilder";
import AutomationsTabs from "./AutomationsTabs";
import { AutomationsModalLayout } from "./styled";
import { ModalSubheading } from "styles/common";
import useBoardSetAutomations from "lib/customHooks/dataLayer/board/useBoardSetAutomations";
import { cancelButtonProps, okButtonProps } from "styles/common-vars";
import useAsyncData from "lib/customHooks/useAsyncData";
import BoardService from "services/BoardService";
import useSavingState from "lib/customHooks/useSavingState";
import { commonModalProps } from "components/blocks/ModalDialog/lib";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import { ModalComponentProps } from "lib/types/components";
import LoadingMessage from "components/blocks/LoadingMessage";

interface AddWorkspaceModalProps extends ModalComponentProps {
  automation?: BoardAutomationInterface;
}

const AutomationsModal: FC<AddWorkspaceModalProps> = (props) => {
  const { automation, onClose, visible } = props;

  const { setSavingStart, setSavingFinish } = useSavingState();
  const { board } = useBoardContext() as BoardContextInterface;
  const { updateBoardAutomation, deleteBoardAutomation } = useBoardSetAutomations({ board, updateDetailed: true });

  const createFn = useCallback(async (data) => {
    return BoardService.addAutomation(board.id, data);
  }, [board.id]);

  const { loadData: createAutomation, isLoading } = useAsyncData<any, Partial<BoardAutomationInterface>>({
    fetchFn: createFn
  });

  const [form] = Form.useForm();
  const [triggerProperty, setTriggerProperty] = useState(Trigger.default);
  const [actionProperty, setActionProperty] = useState(Action.default);
  const [activeKey, setActiveKey] = useState<TActiveTab>(TActiveTab.Triggers);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const formRef = useRef<any>(null);

  useEffect(() => {
    if (automation) {
      // @ts-ignore
      setTriggerProperty(Trigger[automation.triggerProperty]);
      // @ts-ignore
      setActionProperty(Action[automation.updateProperty]);

      form.setFieldsValue({
        ...form?.getFieldsValue(),
        triggerValue: automation.triggerValue,
        updateValue: prepareUpdateValue(automation)
      });
    }
  }, [automation]);

  useEffect(() => {
    setIsSaveEnabled(
      checkIfSaveEnabled(
        triggerProperty,
        actionProperty,
        form?.getFieldValue("triggerValue"),
        form?.getFieldValue("updateValue"),
      )
    );
  }, [triggerProperty, actionProperty, form]);

  const onDelete = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      title: "Permanently delete the automation?",
      content: "This will permanently delete the automation. This item will no longer be accessible to you or anyone else. This action is irreversible.",
      okText: "Delete",
      okButtonProps: okButtonProps,
      cancelText: "Cancel",
      cancelButtonProps: cancelButtonProps,
      onOk() {
        if (automation?.id) {
          deleteBoardAutomation(automation.id);
        }
        onClose();
      },
      zIndex: 1040,
    });
  };

  const handleOnOk = async () => {
    try {
      setSavingStart();
      const values = await form.validateFields();

      if (automation?.id) {
        updateBoardAutomation(automation.id, prepareAutomationData(values, triggerProperty, actionProperty, automation.id));
      } else {
        await createAutomation(prepareAutomationData(values, triggerProperty, actionProperty));
      }
      setSavingFinish();
      onClose();
    } catch (error) {
      console.log("form.validateFields: error", error);
      LoadingMessage.error("Looks like you missed some required fields");
    }
  };

  const onClickTrigger = (trigger: Trigger) => {
    setTriggerProperty(trigger);
    form.setFieldsValue({ ...form?.getFieldsValue(), triggerValue: undefined });
  };

  const onClickAction = (action: Action) => {
    setActionProperty(action);
    form.setFieldsValue({ ...form?.getFieldsValue(), updateValue: undefined });
  };

  const onValueChange = (changedValue: any, allValues: TFormType) => {
    setIsSaveEnabled(
      checkIfSaveEnabled(triggerProperty, actionProperty, allValues.triggerValue, allValues.updateValue)
    );
  };

  const onRemoveTrigger = () => {
    setTriggerProperty(Trigger.default);
    setIsSaveEnabled(false);
  };

  const onRemoveAction = () => {
    setActionProperty(Action.default);
    setIsSaveEnabled(false);
  };

  return (

    <AutomationFormContext.Provider value={form}>
      <AutomationActiveTabContext.Provider value={activeKey}>
        <RemovePropertyContext.Provider value={{
          removeTrigger: onRemoveTrigger,
          removeAction: onRemoveAction,
          onClickTriggerHeader: () => setActiveKey(TActiveTab.Triggers),
          onClickActionHeader: () => setActiveKey(TActiveTab.Actions)
        }}>
          <Modal
            {...commonModalProps}
            style={{ minWidth: 660 }}
            width={860}
            title={automation ? "Edit automation" : "Create automation"}
            open={visible}
            onOk={onClose}
            onCancel={onClose}
            bodyStyle={{ padding: 0 }}
            centered
            footer={[
              <div key="footer" style={{ display: "flex", justifyContent: "space-between" }}>
                {automation
                  ? <CustomStyledButton
                    key="ok"
                    btnColor="redOutlined"
                    value="Delete"
                    onClick={onDelete}
                  />
                  : <div />
                }
                <div>
                  <div key="cancel" style={{ display: "inline-block", marginRight: "10px" }}>
                    <StyledButton onClick={onClose}>
                      Cancel
                    </StyledButton>
                  </div>
                  <CustomStyledButton
                    onClick={handleOnOk}
                    isLoading={isLoading}
                    disabled={!isSaveEnabled}
                    key="ok"
                    btnColor="purple"
                    value="Save"
                  />
                </div>
              </div>
            ]}
          >
            <Form.Provider>
              <Form
                form={form}
                name="automation-form"
                ref={formRef}
                onValuesChange={onValueChange}
                initialValues={{
                  title: automation?.title,
                  triggerValue: automation?.triggerValue,
                  updateValue: automation?.updateValue
                }}
              >
                <ModalSubheading>
                  <Form.Item
                    name="enabled"
                    noStyle
                    valuePropName="checked"
                    initialValue={automation ? automation.enabled : true}
                  >
                    <Switch
                      defaultChecked={automation ? automation.enabled : true}
                      size="small"
                    />
                  </Form.Item>
                  <div style={{ marginLeft: "7px" }}>Active</div>
                </ModalSubheading>
                <AutomationsModalLayout>
                  <AutomationsBuilder
                    onClickTrigger={() => setActiveKey(TActiveTab.Triggers)}
                    onClickAction={() => setActiveKey(TActiveTab.Actions)}
                    triggerComponent={triggerProperty}
                    actionComponent={actionProperty}
                  />
                  <AutomationsTabs
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    onClickTrigger={onClickTrigger}
                    onClickAction={onClickAction}
                  />
                </AutomationsModalLayout>
              </Form>
            </Form.Provider>
          </Modal>
        </RemovePropertyContext.Provider>
      </AutomationActiveTabContext.Provider>
    </AutomationFormContext.Provider>

  );
};

export default AutomationsModal;
