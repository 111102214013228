import { FC } from "react";

import SelectField from "components/blocks/FormComponents/SelectField";
import { TwoColumns, Column } from "styles/common";
import { Precisions } from "./config";

const NumberSettings: FC = () => (
  <TwoColumns>
    <Column key="1">
      <SelectField
        label="Precision"
        name="precision"
        placeholder="Select precision"
        data={Precisions}
      />
    </Column>
    <Column key="2" />
  </TwoColumns>
);

export default NumberSettings;
