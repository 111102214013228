import { setBoard } from "app/slices/currentBoardSlice";
import { BoardInterfaceExtended } from "lib/types/boardTypes";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "app/store";
import useWSSubscription from "../useWSSubscription";
import { WsEvents } from "lib/types/wsTypes";
import { isAllowedBoardUpdateMethod, isCurrentInitiator } from "lib/helpers/websocketUtils";

const useWSUpdateCurrentBoard = (forceUpdate = false) => {
  const dispatch = useAppDispatch();
  const currentBoard = useAppSelector(state => state.currentBoardReducer.board);

  const callback = useCallback((data: BoardInterfaceExtended) => {
    if (currentBoard?.id === data?.id) {
      const updateAvailable = !isCurrentInitiator(data.transactionInitiator) || isAllowedBoardUpdateMethod(data.transactionInitiator);

      if (forceUpdate || updateAvailable) {
        dispatch(setBoard(data));
      }
    }
  }, [currentBoard?.id, dispatch, forceUpdate]);

  useWSSubscription<BoardInterfaceExtended>({
    eventName: WsEvents.Board.Update,
    callback,
    fnKey: "useWSUpdateCurrentBoard"
  });
};

export default useWSUpdateCurrentBoard;
