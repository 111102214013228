import styled from "@emotion/styled/macro";

export const TaskCommentBoxMainContainer = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  //padding: 14px 8px;
  padding: 14px 0;
  .ant-upload-list-item-name {
    max-width: 440px;
    color: #333333;
  }
  .ant-upload-list-item-info {
    border-radius: 5px;
    padding-left: 2px;
  }
  .ant-upload-text-icon {
    display: none;
  }
`;

export const TaskCommentBoxAvatar = styled.div`
  margin-right: 10px
`;

export const TaskCommentBoxInputContainer = styled.div`
  padding: 2px;
  margin: -2px;
`;

export const TaskCommentBoxButtonsContainer = styled.div`
  position: relative;
  margin-top: 10px;
`;

export const TaskCommentBoxButtons = styled.div`
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  bottom: auto;
`;
