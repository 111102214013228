import { FC } from "react";
import styled from "@emotion/styled/macro";
import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import LinkGeneric from "./Display/generic";
import { dataInput } from "components/blocks/TaskTable/styled";

const LinkListWrapper = styled.div`
  ${dataInput};
`;

const LinkField: FC<TaskFieldPropsGeneric> = (props) => {
  const { component } = props;

  switch (component) {
    case TaskSourceComponent.Card:
      return null;
    case TaskSourceComponent.Cell:
      return (
        <LinkListWrapper>
          <LinkGeneric {...props} />
        </LinkListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <LinkGeneric {...props} />
      );
    default:
      return null;
  }
};

export default LinkField;
