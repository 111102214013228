import styled from "@emotion/styled/macro";
import { COLOR_DEFAULT_GREY } from "lib/configs/colors";

export const ReusableSelectOptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ReusableSelectOptionCircle = styled.div<{ color?: string | null }>`
  display: flex;
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
  background: ${({ color }) => color || COLOR_DEFAULT_GREY};
`;

export const ReusableSelectOptionText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;
