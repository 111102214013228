import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { Input } from "antd";

export const ViewMenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ViewMenuItemTitle = styled.div`
  max-width: 100px;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  //padding-right: 14px;
`;

export const ViewMenuItemInput = styled(Input)`
  padding: 0;
  flex-shrink: 1;
`;

export const ViewMenuItemControls = styled.div<{ hidden?: boolean }>`
  display: flex;
  align-items: center;
  ${({ hidden }) => hidden && css`
    display: none;
  `}
`;
