import { omit } from "lodash";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
import LinkTool from "@editorjs/link";
import ImageTool from "@editorjs/image";
import Header from "@editorjs/header";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@editorjs/simple-image";
import AttachesTool from "@editorjs/attaches";
// import Alert from "editorjs-alert";
// import Warning from "@editorjs/warning";

import configuration from "lib/configs/configuration";
import { EditorJsSaveFormat } from "./lib";

export const EditorJsTools = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      placeholder: "Start typing or press Tab for commands…"
    }
  },
  list: {
    class: List,
    inlineToolbar: true,
  },
  header: {
    class: Header,
    inlineToolbar: true,
    config: {
      placeholder: "Enter a header",
      levels: [1, 2, 3],
      defaultLevel: 2,
    }
  },
  table: {
    class: Table,
    inlineToolbar: true,
    config: {
      rows: 2,
      cols: 3,
    },
  },
  linkTool: {
    class: LinkTool,
    config: {
      endpoint: configuration.backend.url + "documents/plugins/link/fetchUrl",
    }
  },
  checklist: {
    class: CheckList,
    inlineToolbar: true,
  },
  image: {
    class: ImageTool,
    config: {
      endpoints: {
        byFile: configuration.backend.url + "documents/plugins/image/uploadByFile",
        byUrl: configuration.backend.url + "documents/plugins/image/uploadByUrl",
      },
    }
  },
  attaches: {
    class: AttachesTool,
    config: {
      endpoint: configuration.backend.url + "documents/plugins/attaches/uploadFile",
    }
  },
  // FIXME: icon in dropdown menu is screwed
  // alert: {
  //   class: Alert,
  //   inlineToolbar: true,
  //   config: {
  //     defaultType: "primary",
  //     messagePlaceholder: "Enter something",
  //   },
  // },
  delimiter: Delimiter,
  embed: {
    class: Embed,
    inlineToolbar: true
  },
  marker: Marker,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
};

export const EditorJsDocumentConfig = omit(EditorJsTools, [
  "linkTool",
  "delimiter",
]);

export const EditorJsTaskDescriptionConfig = omit(EditorJsTools, [
  "checklist",
  "linkTool",
  "delimiter",
]);

export const EditorJsFileContainerDescriptionConfig = omit(EditorJsTools, [
  "checklist",
  "linkTool",
  "delimiter",
  "attaches",
  "image",
]);

export const EditorJSInitialState: EditorJsSaveFormat = {
  blocks: [],
  time: Date.now(),
};
