import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const ThickSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M80 50C80 66.5685 66.5685 80 50 80C33.4315 80 20 66.5685 20 50C20 33.4315 33.4315 20 50 20C66.5685 20 80 33.4315 80 50Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default ThickSvg;
