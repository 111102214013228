import React, { FC, useEffect, useRef, useState } from "react";

import useMemberPermission from "lib/customHooks/useMemberPermission";
import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import { MemberRoles } from "lib/types/types";
import { DefaultIconColor } from "components/icons/lib";

import { TaskDetailedTitleWrapper, TaskDetailedTitleInput } from "./styled";
import KeyCodes from "lib/types/keyCodes";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

const TaskDetailedTitle: FC = () => {
  const { task, setValue } = useTaskContext() as TaskContextInterface;

  const ref = useRef<HTMLTextAreaElement>(null);
  const [displayTitle, setDisplayTitle] = useState(task.title);
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);
  const { isCompletionEnabled } = useBoardFeatures(task.board);

  const titleColor = (isCompletionEnabled && task?.completed) ? DefaultIconColor : "black";
  const isEditDisabled = !!task?.board?.externalDataSource || !allowedToEdit;

  useEffect(() => {
    setDisplayTitle(task.title);
  }, [task.title]);

  const handleTitleUpdate = (e: React.KeyboardEvent<HTMLTextAreaElement> | React.FocusEvent<HTMLTextAreaElement>) => {
    const titleValue = displayTitle.trim();
    if (titleValue === task.title) {
      return;
    }

    if (!titleValue) {
      setDisplayTitle(task.title);
      return;
    }

    setValue({ cellName: "title", cellValue: titleValue });
    e.currentTarget.blur();
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const sanitizedInput = e.target.value.replace(/\n/g, " ");
    setDisplayTitle(sanitizedInput);
  };

  const handleTextAreaOnKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if ([KeyCodes.Escape, KeyCodes.Enter].includes(event.keyCode)) {
      event.stopPropagation();
      ref.current?.blur();
    }
  };

  return (
    <TaskDetailedTitleWrapper>
      <TaskDetailedTitleInput
        ref={ref}
        value={displayTitle}
        style={{ fontSize: 20, fontWeight: 600, color: `${titleColor}` }}
        autoSize={{ minRows: 1, maxRows: 6 }}
        onChange={handleInputOnChange}
        onBlur={handleTitleUpdate}
        onPressEnter={handleTextAreaOnKeyDown}
        onKeyDown={handleTextAreaOnKeyDown}
        disabled={isEditDisabled}
      />
    </TaskDetailedTitleWrapper>
  );
};

export default TaskDetailedTitle;
