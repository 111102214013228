import { FC, useState } from "react";
import { Empty, Tooltip } from "antd";
import { orderBy } from "lodash";

import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import { ChangeInterface, ChangelogItemType, CommentInterface } from "lib/types/types";
import TaskChange from "pages/Task/TaskChange";
import TaskComment from "pages/Task/TaskComment";
import { TaskCommentSectionViews } from "pages/Task/TaskDetailed/lib";
import {
  TaskChangelogRendererButton,
  TaskChangelogRendererOrder
} from "components/blocks/TaskDetailed/TaskChangelogRenderer/styled";
import OrderSvg from "components/icons/order";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

export interface TaskChangelogRendererProps {
  view: TaskCommentSectionViews;
  onEditComment: (comment: CommentInterface) => void;
  onDiscardEditComment: () => void;
}

const TaskChangelogRenderer: FC<TaskChangelogRendererProps> = (props) => {
  const { view, onEditComment, onDiscardEditComment } = props;
  const { task } = useTaskContext() as TaskContextInterface;
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const { terminology } = useBoardFeatures(task.board);

  const dummyCreatedItem: CommentInterface & { customOverride: boolean } = {
    id: "",
    text: `created this ${terminology.single.toLowerCase()}.`,
    author: task?.owner,
    createdAt: task.created_at,
    modifiedAt: null,
    edited: false,
    attachments: [],
    item: ChangelogItemType.Comment,
    customOverride: true,
  };

  const handleOnOrderClick = () => {
    const nextState = (order === "desc") ? "asc" : "desc";
    setOrder(nextState);
  };

  const renderItems = () => {
    let items = orderBy(task.changelog, item => item.createdAt, [order]);

    if (order === "desc") {
      items.push(dummyCreatedItem);
    } else {
      items.unshift(dummyCreatedItem);
    }

    switch (view) {
      case TaskCommentSectionViews.Changes:
        items = (items as ChangeInterface[]).filter(item => item.item === ChangelogItemType.Change);
        break;
      case TaskCommentSectionViews.Comments:
        items = (items as CommentInterface[]).filter(item => item.item === ChangelogItemType.Comment);
        break;
      default:
        break;
    }

    if (!items.length && (view === TaskCommentSectionViews.Changes)) {
      return (
        <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      );
    } else {
      return (
        items.map(item => renderChangelogItem(item))
      );
    }
  };

  const renderChangelogItem = (item: any) => {
    const itemKey = `${item.item}-${item.id}`;

    switch (item.item as ChangelogItemType) {
      case ChangelogItemType.Change:
        return (<TaskChange key={itemKey} change={item} />);
      case ChangelogItemType.Comment:
        return (<TaskComment key={itemKey} comment={item} onEdit={onEditComment} onDelete={onDiscardEditComment} />);
      default:
        return (null);
    }
  };

  return (
    <>
      <Tooltip title={`${(order === "desc") ? "Newest" : "Oldest"} first`}>
        <TaskChangelogRendererButton onClick={handleOnOrderClick}>
          <TaskChangelogRendererOrder>
            <OrderSvg />
          </TaskChangelogRendererOrder>
        </TaskChangelogRendererButton>
      </Tooltip>
      {renderItems()}
    </>
  );
};

export default TaskChangelogRenderer;
