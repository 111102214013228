import styled from "@emotion/styled/macro";
import { Input as AntdInput } from "antd";

export const Input = styled(AntdInput)`
  min-height: 40px;
  padding: 4px 8px;

  &:focus {
    outline: none;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  min-width: 160px;
  margin: 0 auto;
  padding-top: 6px;

  ${Input} {
    border-radius: 4px;
    background-color: #fff;
  }
`;

export const Loader = styled.div`
  position: absolute;
  margin-top: 5px;
  left: 0;
  top: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
