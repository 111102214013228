import { BoardAutomationEvents, DefaultColumnCodes } from "lib/types/boardTypes";
import { ContentType } from "lib/types/contentTypes";
import { UserInterface } from "lib/types/types";

export const Automation = {
  ContentTypes: {
    Task: ContentType.Task,
  },
  Events: {
    Create: BoardAutomationEvents.Create,
    Update: BoardAutomationEvents.Update,
    Delete: BoardAutomationEvents.Delete,
  },
  Triggers: {
    Properties: [
      DefaultColumnCodes.Completion,
      DefaultColumnCodes.Assignees,
      DefaultColumnCodes.DueDate,
      DefaultColumnCodes.Status,
      DefaultColumnCodes.Comments,
    ],
  },
  Actions: {
    Properties: [
      DefaultColumnCodes.Completion,
      DefaultColumnCodes.Assignees,
      DefaultColumnCodes.DueDate,
      DefaultColumnCodes.Status,
      DefaultColumnCodes.Collaborators,
      DefaultColumnCodes.Comments,
    ],
  },
};

export const AutomationUser: UserInterface = {
  id: 0,
  username: "Automation",
  email: "",
  provider: "local",
  confirmed: true,
  blocked: null,
  firstName: "Automation",
  lastName: "",
  phone: "",
  job: "",
  role: 1,
  color: "#488eff",
  description: "System User",
  department: "",
  api_token: "",
  created_at: new Date(),
  updated_at: new Date(),
  // @ts-expect-error
  picture: {
    url: process.env.PUBLIC_URL + "/icons/users/automations.svg"
  },
  organization: null,
  emailNotifications: null,
};

export const AnonymousUser: UserInterface = {
  id: 0,
  username: "Anonymous",
  email: "",
  provider: "local",
  confirmed: true,
  blocked: null,
  firstName: "Anonymous",
  lastName: "",
  phone: "",
  job: "",
  role: 1,
  color: "#488eff",
  description: "System User",
  department: "",
  api_token: "",
  created_at: new Date(),
  updated_at: new Date(),
  // @ts-expect-error
  picture: {
    url: process.env.PUBLIC_URL + "/icons/users/anonymous.svg"
  },
  organization: null,
  emailNotifications: null,
};

export const getDummyUser = (props: Partial<UserInterface>): UserInterface => ({
  ...AnonymousUser,
  ...props,
  id: -1,
});

export const getUserById = (userId: string | null, users: Array<UserInterface>) => {
  if (!userId) {
    return AnonymousUser;
  } else {
    const user = users.find(user => String(user.id) === String(userId));
    if (user) {
      return user;
    } else {
      return getDummyUser({ firstName: userId });
    }
  }
};
