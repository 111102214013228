import React, { FC, useContext } from "react";
import { WorkspaceInterface } from "lib/types/types";

interface ProjectContextProps {
  workspace: WorkspaceInterface | null | undefined;
}

export interface ProjectContextInterface {
  workspace: WorkspaceInterface;
}

const ProjectContext = React.createContext<ProjectContextProps>({
  workspace: undefined,
});

export const useProjectContext = () => useContext(ProjectContext);

export const ProjectProvider: FC<ProjectContextProps> = (props) => {
  return (
    <ProjectContext.Provider value={{ ...props }}>
      {props.children}
    </ProjectContext.Provider>
  );
};
