import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const FallbackSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.6101 20.4357C64.3412 20.4335 62.1657 19.5315 60.5611 17.9275C58.9561 16.3236 58.0532 14.1485 58.0499 11.8796V0H19.8217C16.8603 0 14.0202 1.17644 11.9261 3.27047C9.83211 5.36448 8.65576 8.20452 8.65576 11.1659V78.4772C8.65576 81.4386 9.83211 84.2788 11.9261 86.3726C14.0202 88.4668 16.8603 89.6432 19.8217 89.6432H65.7138C68.6752 89.6432 71.5155 88.4668 73.6092 86.3726C75.7034 84.2788 76.8798 81.4386 76.8798 78.4772V20.4357H66.6101Z" fill="#B0B0B0"/>
        <path d="M76.8797 20.4357H66.61C64.3411 20.4335 62.1656 19.5315 60.561 17.9275C58.956 16.3236 58.0531 14.1485 58.0498 11.8796V0L76.8797 20.4357Z" fill="#B0B0B0"/>
        <path d="M62.8466 34.7427H20.0043C19.593 34.7416 19.1989 34.5778 18.9082 34.287C18.6173 33.9962 18.4535 33.6021 18.4524 33.1909C18.4524 32.7789 18.6157 32.3837 18.9066 32.092C19.1975 31.8003 19.5923 31.6359 20.0043 31.6349H62.8466C63.2586 31.6359 63.6532 31.8003 63.9441 32.092C64.235 32.3837 64.3985 32.7789 64.3985 33.1909C64.3972 33.6021 64.2333 33.9962 63.9429 34.287C63.652 34.5778 63.2578 34.7416 62.8466 34.7427Z" fill="#B0B0B0"/>
        <path d="M62.8466 44.0249H20.0043C19.593 44.0237 19.1989 43.8602 18.9082 43.5693C18.6173 43.2784 18.4535 42.8842 18.4524 42.473C18.4524 42.061 18.6157 41.666 18.9066 41.3742C19.1975 41.0825 19.5923 40.9182 20.0043 40.9171H62.8466C63.2586 40.9182 63.6532 41.0825 63.9441 41.3742C64.235 41.666 64.3985 42.061 64.3985 42.473C64.3972 42.8842 64.2333 43.2784 63.9429 43.5693C63.652 43.8602 63.2578 44.0237 62.8466 44.0249Z" fill="#B0B0B0"/>
        <path d="M62.8466 53.3071H20.0043C19.5923 53.3058 19.1975 53.1415 18.9066 52.8498C18.6157 52.5581 18.4524 52.1631 18.4524 51.751C18.4535 51.3398 18.6173 50.9456 18.9082 50.6548C19.1989 50.3643 19.593 50.2004 20.0043 50.1992H62.8466C63.2578 50.2004 63.652 50.3643 63.9429 50.6548C64.2333 50.9456 64.3972 51.3398 64.3985 51.751C64.3985 52.1631 64.235 52.5581 63.9441 52.8498C63.6532 53.1415 63.2586 53.3058 62.8466 53.3071Z" fill="#B0B0B0"/>
        <path d="M62.8466 62.5851H20.0043C19.5927 62.5851 19.198 62.4216 18.9069 62.1307C18.6159 61.8394 18.4524 61.4448 18.4524 61.0332C18.4524 60.6212 18.6157 60.2261 18.9066 59.9344C19.1975 59.6427 19.5923 59.4784 20.0043 59.4772H62.8466C63.2586 59.4784 63.6532 59.6427 63.9441 59.9344C64.235 60.2261 64.3985 60.6212 64.3985 61.0332C64.3985 61.4448 64.235 61.8394 63.9437 62.1307C63.6528 62.4216 63.2582 62.5851 62.8466 62.5851Z" fill="#B0B0B0"/>
        <path d="M84.9794 70.7925H33.0955C29.4152 70.7925 26.4316 73.7759 26.4316 77.4564V92.9253C26.4316 96.6058 29.4152 99.5892 33.0955 99.5892H84.9794C88.6599 99.5892 91.6433 96.6058 91.6433 92.9253V77.4564C91.6433 73.7759 88.6599 70.7925 84.9794 70.7925Z" fill="#B0B0B0"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default FallbackSvg;
