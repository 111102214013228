import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const XmlSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.4999 20.5167C64.222 20.5145 62.0379 19.6086 60.427 17.9978C58.8166 16.387 57.9104 14.203 57.9083 11.925V0H19.5166C16.5429 0 13.6909 1.18131 11.5882 3.28406C9.48542 5.38679 8.30408 8.23875 8.30408 11.2125V78.8042C8.30849 81.775 9.4918 84.6225 11.5941 86.7217C13.6964 88.8208 16.5458 90 19.5166 90H65.5999C68.5737 90 71.4258 88.8187 73.5283 86.7158C75.6312 84.6133 76.8124 81.7612 76.8124 78.7875V20.5167H66.4999Z" fill="#A140FF"/>
        <path d="M76.8125 20.5167H66.5C64.2221 20.5145 62.0379 19.6086 60.4271 17.9978C58.8167 16.387 57.9104 14.203 57.9083 11.925V0L76.8125 20.5167Z" fill="#A140FF"/>
        <path d="M84.9457 71.0833H32.8458C29.1501 71.0833 26.1541 74.0792 26.1541 77.775V93.3083C26.1541 97.0042 29.1501 100 32.8458 100H84.9457C88.6416 100 91.6374 97.0042 91.6374 93.3083V77.775C91.6374 74.0792 88.6416 71.0833 84.9457 71.0833Z" fill="#A140FF"/>
        <path d="M39.7027 93.5588C39.2281 93.2329 39.1573 92.815 39.4902 92.305L43.3789 86.5675L39.2777 80.7875C38.9589 80.32 39.0368 79.9021 39.5114 79.5338C40.0002 79.2008 40.411 79.2788 40.7439 79.7675L44.5477 85.1013L48.3727 79.7675C48.741 79.2929 49.1518 79.2292 49.6052 79.5763C50.0866 79.9304 50.1575 80.3413 49.8175 80.8088L45.6314 86.6313L49.6477 92.2838C49.9804 92.7725 49.9025 93.1975 49.4139 93.5588C48.9252 93.8917 48.5143 93.8138 48.1814 93.325L44.4839 88.1188L40.9564 93.3463C40.6306 93.8067 40.2127 93.8775 39.7027 93.5588Z" fill="white"/>
        <path d="M54.2796 93.75C53.6846 93.75 53.3871 93.4525 53.3871 92.8575V80.235C53.3871 79.64 53.6846 79.3425 54.2796 79.3425C54.6692 79.3425 54.995 79.5337 55.2571 79.9162L60.2721 87.46L59.8896 87.5238L64.9471 79.9162C65.1879 79.5337 65.5492 79.3425 66.0308 79.3425C66.5125 79.3425 66.7533 79.64 66.7533 80.235V92.8575C66.7533 93.4525 66.4558 93.75 65.8608 93.75C65.2658 93.75 64.9683 93.4525 64.9683 92.8575V82.02L65.5208 82.2113L61.0583 88.7775C60.7963 89.16 60.435 89.3512 59.9746 89.3512C59.6558 89.3512 59.3654 89.16 59.1033 88.7775L54.6408 82.2113L55.1721 82.36V92.8575C55.1721 93.4525 54.8746 93.75 54.2796 93.75Z" fill="white"/>
        <path d="M72.0641 93.75C71.4691 93.75 71.1716 93.4525 71.1716 92.8575V80.235C71.1716 79.64 71.4691 79.3425 72.0641 79.3425C72.6591 79.3425 72.9566 79.64 72.9566 80.235V91.965H78.8429C79.4379 91.965 79.7354 92.2625 79.7354 92.8575C79.7354 93.4525 79.4379 93.75 78.8429 93.75H72.0641Z" fill="white"/>
        <path d="M25.1833 47.5792C25.1775 47.4 25.2318 47.2242 25.3375 47.0792C25.4639 46.9167 25.6158 46.7762 25.7874 46.6625C26.0473 46.5279 26.3161 46.4112 26.5917 46.3125L27.3958 45.9792L30.6124 44.625C31.0597 44.4167 31.893 44.0696 33.1124 43.5833L34.6416 42.9583L35.1916 42.7625L35.2874 42.7208C35.3135 42.7154 35.3405 42.7154 35.3666 42.7208C35.4283 42.6937 35.495 42.6796 35.5625 42.6792C35.6916 42.6721 35.8207 42.6908 35.9423 42.7346C36.064 42.7783 36.1757 42.8458 36.2708 42.9333C36.3514 43.0092 36.4156 43.1008 36.4593 43.2029C36.503 43.3046 36.5254 43.4142 36.5249 43.525V44.5833C36.5209 44.7796 36.4602 44.9708 36.3499 45.1333C36.234 45.315 36.0611 45.4525 35.8583 45.525L29.2875 48.2125L35.8583 50.9C36.0539 50.9808 36.2239 51.1133 36.3499 51.2833C36.4645 51.4392 36.5258 51.6275 36.5249 51.8208V52.8792C36.525 52.9912 36.5026 53.1021 36.4589 53.205C36.4152 53.3083 36.3513 53.4012 36.2708 53.4792C36.1773 53.5696 36.066 53.6396 35.9441 53.6846C35.8222 53.7296 35.6923 53.7492 35.5625 53.7417C35.4955 53.7408 35.4291 53.7283 35.3666 53.7042L35.1916 53.6458L34.6416 53.45L33.1249 52.8333L30.6249 51.7958L27.4083 50.4417L26.6042 50.1083C26.3458 50.0042 26.0749 49.8875 25.7999 49.7542C25.6293 49.6746 25.4832 49.5508 25.3769 49.3954C25.2706 49.24 25.208 49.0587 25.1958 48.8708L25.1833 47.5792Z" fill="#A140FF"/>
        <path d="M44.8125 54.875C44.604 54.88 44.3978 54.83 44.2149 54.7296C44.0319 54.6296 43.8787 54.4829 43.7708 54.3042C43.6813 54.1758 43.6098 54.0354 43.5583 53.8875L43.4208 53.5167L40.4208 46.8875L40.2042 46.3958C39.9542 45.8625 39.4708 44.8042 38.7542 43.2208C37.8236 41.207 37.3583 40.1028 37.3583 39.9084C37.3584 39.7912 37.3852 39.6756 37.4364 39.5702C37.4877 39.4648 37.5621 39.3725 37.6542 39.3C37.8621 39.1243 38.1279 39.0322 38.3999 39.0417H39.375C39.5804 39.0382 39.7831 39.0891 39.9624 39.1894C40.1418 39.2897 40.2913 39.4357 40.3958 39.6125L40.6125 40.0292L40.75 40.4L41.5333 42.125L43.75 47.0292L44.0417 47.6542L45.4167 50.675C45.9438 51.7587 46.4084 52.8717 46.8084 54.0083C46.8076 54.125 46.7807 54.2396 46.7294 54.3442C46.6782 54.4487 46.604 54.5404 46.5124 54.6125C46.3047 54.7887 46.0388 54.8808 45.7667 54.8708L44.8125 54.875Z" fill="#A140FF"/>
        <path d="M48.4416 53.75C48.3085 53.7588 48.1751 53.74 48.0496 53.6946C47.9242 53.6496 47.8094 53.5792 47.7124 53.4875C47.5521 53.3288 47.4608 53.1133 47.4582 52.8875V51.8292C47.4563 51.6371 47.5147 51.4492 47.6249 51.2917C47.7496 51.1167 47.9237 50.9833 48.1249 50.9083L54.7166 48.2208L48.1249 45.5375C47.9248 45.45 47.7551 45.305 47.6374 45.1208C47.5239 44.9629 47.4613 44.7738 47.4582 44.5792V43.5042C47.4568 43.3942 47.4783 43.2854 47.5214 43.1842C47.5644 43.0829 47.6281 42.9921 47.7082 42.9167C47.9041 42.7404 48.1618 42.6492 48.4249 42.6625C48.4858 42.6617 48.5459 42.6758 48.5999 42.7042C48.6357 42.6979 48.6724 42.6979 48.7082 42.7042L48.7958 42.7458C48.7708 42.7458 48.9457 42.7958 49.3249 42.9417L50.8749 43.5667C50.7945 43.5304 51.6279 43.8779 53.3749 44.6083C54.3279 45 55.4195 45.4638 56.6499 46L56.9624 46.1167L57.4124 46.2958C57.6774 46.39 57.9341 46.5071 58.1791 46.6458C58.3541 46.7258 58.5037 46.8525 58.6108 47.0121C58.7183 47.1717 58.7795 47.3579 58.7874 47.55V48.8417C58.7837 49.0188 58.7254 49.1904 58.6208 49.3333C58.5045 49.495 58.3537 49.6288 58.1791 49.725C57.9416 49.8417 57.6624 49.9667 57.3458 50.1L56.5124 50.4333L49.2541 53.4708C49.0457 53.575 48.9208 53.6417 48.8833 53.6667L48.804 53.725L48.4416 53.75Z" fill="#A140FF"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default XmlSvg;
