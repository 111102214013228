import { FC } from "react";
import { InputNumber } from "antd";

import { FormItem } from "../styled";

interface NumberFieldProps {
  className?: string;
  label?: string;
  name: string | string[];
  placeholder?: string;
  initialValue?: string;
  bordered?: boolean | true;
  disabled?: boolean;
  onChange?: (value: number | null) => void;
  rules?: Array<any>
  fieldKey?: Array<any>
}

const NumberField: FC<NumberFieldProps> = (props) => {
  const {
    className,
    label,
    name,
    placeholder,
    disabled,
    onChange,
    rules,
    initialValue,
    bordered,
    fieldKey,
  } = props;

  return (
    <FormItem
      className={className}
      label={label}
      name={name}
      rules={rules}
      initialValue={initialValue}
      fieldKey={fieldKey}
    >
      <InputNumber
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        bordered={bordered}
      />
    </FormItem>
  );
};

export default NumberField;
