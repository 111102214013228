import { ModalProps } from "antd";

export const commonModalProps: Partial<ModalProps> = {
  forceRender: true,
  modalRender: (node => (
    <div onClick={e => e.stopPropagation()}>
      {node}
    </div>
  )),
  maskClosable: false,
  keyboard: false,
};

export const infoModalProps: Partial<ModalProps> = {
  okButtonProps: { style: { display: "none" } },
  closable: true,
};
