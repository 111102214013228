import { FC } from "react";
import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import CurrencyGeneric from "./Display/generic";

import { CurrencyListWrapper, CurrencyDetailedWrapper } from "./Display/styled";

interface CurrencyFieldProps extends TaskFieldPropsGeneric {
  currency: string;
  precision: number;
}

const CurrencyField: FC<CurrencyFieldProps> = (props) => {
  const { component } = props;

  switch (component) {
    case TaskSourceComponent.Card:
      return null;
    case TaskSourceComponent.Cell:
      return (
        <CurrencyListWrapper>
          <CurrencyGeneric {...props} />
        </CurrencyListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <CurrencyDetailedWrapper>
          <CurrencyGeneric {...props} />
        </CurrencyDetailedWrapper>
      );
    default:
      return null;
  }
};

export default CurrencyField;
