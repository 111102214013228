import { FC } from "react";
import IconSvgWrapper from "components/icons/IconSvgWrapper";
import { IconProps } from "components/icons/lib";

const FilterSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M10.2709 1H1.72914C1.32707 1 1 1.32707 1 1.72914V2.69373C1 2.89705 1.08583 3.0929 1.23628 3.23167L4.58086 6.29541C4.68874 6.3933 4.75 6.53337 4.75 6.67917V10.6875C4.75 10.8025 4.81332 10.9088 4.91502 10.9629C4.96126 10.9879 5.01207 11 5.0625 11C5.12331 11 5.18373 10.9825 5.23584 10.9475L6.92545 9.82126C7.12877 9.68584 7.25 9.45879 7.25 9.21457V6.67917C7.25 6.53337 7.31126 6.3933 7.41914 6.29503L10.7637 3.23083C10.9142 3.0929 11 2.89705 11 2.69373V1.72914C11 1.32707 10.6729 1 10.2709 1V1Z" />
    </IconSvgWrapper>
  );
};

export default FilterSvg;
