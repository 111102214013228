import { MemberRoles } from "lib/types/types";

export const MemberRights = [
  {
    value: "Owner",
    description: "Can fully configure boards and fields, manage models, and edit project settings and billing",
    code: MemberRoles.Owner,
  },
  {
    value: "Manager",
    description: "Can fully configure boards and fields, manage models",
    code: MemberRoles.Admin,
  },
  {
    value: "Collaborator",
    description: "Can edit tasks and views, but cannot configure boards or fields",
    code: MemberRoles.Editor,
  },
  {
    value: "Commenter",
    description: "Can comment on tasks and create personal views",
    code: MemberRoles.Commenter,
  },
  {
    value: "Read only",
    description: "Cannot edit or comment",
    code: MemberRoles.Viewer,
  },
];
