import styled from "@emotion/styled/macro";

export const TaskChangelogRendererButton = styled.button`
  border: 0;
  background-color: transparent;
  padding-block: 4px;
  cursor: pointer;
`;

export const TaskChangelogRendererOrder = styled.div`
  display: flex;
  align-items: center;
`;
