import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { promptWindowUnsavedWarning } from "lib/helpers/windowUtils";

interface InitialStateType {
  isLoading: boolean;
  transactionQueue: number
}

const initialState: InitialStateType = {
  isLoading: false,
  transactionQueue: 0,
};

export const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    enqueueTransaction: (state) => {
      state.transactionQueue += 1;
      if (state.transactionQueue > 0) {
        promptWindowUnsavedWarning(true);
      }
    },

    dequeueTransaction: (state) => {
      state.transactionQueue -= 1;
      if (state.transactionQueue <= 1) {
        promptWindowUnsavedWarning(false);
      }
    },
  }
});

export const { setLoading, enqueueTransaction, dequeueTransaction } = progressSlice.actions;

export default progressSlice.reducer;
