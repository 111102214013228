import { FC } from "react";
import { Select } from "antd";

import { useAppSelector } from "app/store";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import SelectField from "components/blocks/FormComponents/SelectField";

import { customFields, syncedBim360Fields, systemFields, TaskFieldInterface } from "../../FieldsConfig";
import { OptionWrapper, Image } from "./styled";
import { BoardDataSources } from "lib/types/boardTypes";

const { Option, OptGroup } = Select;

interface TypeSelectProps {
  allowClear?: boolean;
  showSearch?: boolean;
  label: string;
  property: string;
  placeholder: string;
  disabled: boolean;
  rules?: Array<any>;
  onChange?: (value: string) => void;
  filterOption?: boolean | any;
}

const TypeSelect: FC<TypeSelectProps> = (props) => {
  const { property } = props;
  const board = useAppSelector(state => state.currentBoardReducer.board);
  const columns = board?.columns || [];
  const isOptionDisabled = (field: TaskFieldInterface) => {
    if ([TaskPropertyClass.System, TaskPropertyClass.Synced].includes(field.class)) {
      const column = columns.find(item => item.code === field.code);
      return column?.active;
    }
    return false;
  };

  const renderFieldTypeList = (fields: Array<TaskFieldInterface>) => fields.map((field) => (
    <Option value={field.code} key={field.code} disabled={isOptionDisabled(field)}>
      <OptionWrapper disabled={isOptionDisabled(field)}>
        <Image src={process.env.PUBLIC_URL + field.icon} alt="" disabled={isOptionDisabled(field)} />
        {field.name}
      </OptionWrapper>
    </Option>
  ));

  return (
    <SelectField
      {...props}
      name={property}
    >
      <OptGroup label="Custom fields">{renderFieldTypeList(customFields)}</OptGroup>;
      <OptGroup label="System fields">{renderFieldTypeList(systemFields)}</OptGroup>;
      {(board?.externalDataSource?.source === BoardDataSources.Bim360) &&
        <OptGroup label="Synced fields">{renderFieldTypeList(syncedBim360Fields)}</OptGroup>
      }
    </SelectField>
  );
};

export default TypeSelect;
