import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const AiSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.4606 20.4357C64.1917 20.4324 62.0166 19.5293 60.4129 17.9246C58.8087 16.3198 57.9066 14.1444 57.9046 11.8755V0H19.6722C16.7107 0 13.8707 1.17639 11.7767 3.27042C9.68267 5.36444 8.50623 8.20461 8.50623 11.166V78.4772C8.50623 81.4386 9.68267 84.2788 11.7767 86.3726C13.8707 88.4668 16.7107 89.6432 19.6722 89.6432H65.5643C67.0311 89.6436 68.4834 89.3552 69.8386 88.7946C71.1938 88.2336 72.4253 87.4112 73.4627 86.3743C74.5 85.3374 75.3228 84.1062 75.8842 82.751C76.4456 81.3963 76.7344 79.944 76.7344 78.4772V20.4357H66.4606Z" fill="#FF9908"/>
        <path d="M76.7344 20.4357H66.4606C64.1917 20.4324 62.0166 19.5293 60.4129 17.9246C58.8087 16.3198 57.9066 14.1444 57.9046 11.8755V0L76.7344 20.4357Z" fill="#FF9908"/>
        <path d="M84.8299 70.7925H32.946C29.2656 70.7925 26.2822 73.7759 26.2822 77.4564V92.9253C26.2822 96.6058 29.2656 99.5892 32.946 99.5892H84.8299C88.5104 99.5892 91.4938 96.6058 91.4938 92.9253V77.4564C91.4938 73.7759 88.5104 70.7925 84.8299 70.7925Z" fill="#FF9908"/>
        <path d="M50.3083 93.2763C49.7439 93.0576 49.5746 92.6696 49.8003 92.1124L54.8369 79.7115C55.0485 79.246 55.3801 79.0132 55.8315 79.0132H55.8738C56.3324 79.0343 56.6427 79.2671 56.805 79.7115L61.9261 92.1124C62.1519 92.6696 61.9896 93.0576 61.4394 93.2763C60.8821 93.4949 60.4942 93.3327 60.2755 92.7895L59.1328 90.0173H52.5407L51.451 92.7895C51.2324 93.3397 50.8514 93.502 50.3083 93.2763ZM53.239 88.2397H58.4025L55.768 81.8277L53.239 88.2397Z" fill="white"/>
        <path d="M65.9684 93.361C65.6651 93.361 65.4394 93.2904 65.2913 93.1494C65.1502 93.0012 65.0797 92.7755 65.0797 92.4722V79.9232C65.0797 79.6128 65.1502 79.3871 65.2913 79.246C65.4394 79.105 65.6651 79.0344 65.9684 79.0344C66.2788 79.0344 66.5045 79.105 66.6456 79.246C66.7867 79.3871 66.8572 79.6128 66.8572 79.9232V92.4722C66.8572 92.7755 66.7867 93.0012 66.6456 93.1494C66.5045 93.2904 66.2788 93.361 65.9684 93.361Z" fill="white"/>
        <path d="M39.686 47.7203C39.9287 47.4776 40.0151 47.1245 40.1276 46.8004C40.1873 46.6282 40.2855 46.4722 40.4149 46.3444C40.5282 46.2311 40.6628 46.1411 40.8109 46.0797C40.959 46.0182 41.1177 45.9867 41.278 45.9867C41.4383 45.9867 41.597 46.0182 41.7451 46.0797C41.8932 46.1411 42.0278 46.2311 42.1411 46.3444C42.2545 46.4577 42.3444 46.5921 42.4058 46.7402C42.4672 46.8884 42.4988 47.0473 42.4988 47.2075C42.4988 47.3676 42.4672 47.5265 42.4058 47.6747C42.3444 47.8228 42.2545 47.9572 42.1411 48.0705C42.0131 48.2 41.8571 48.2983 41.6852 48.3581C41.3611 48.4705 41.0075 48.5568 40.7649 48.7992L32.2674 57.2971C31.2728 58.2913 32.4326 59.9141 33.6955 59.295L47.6292 52.4647C47.9518 52.3066 48.1894 52.0203 48.2887 51.6747C48.7878 49.939 50.4597 44.4556 52.427 41.6211C52.8187 41.0568 52.8178 40.266 52.3319 39.7802L48.697 36.1451C48.2111 35.6592 47.4203 35.6585 46.8558 36.0503C44.0214 38.0176 38.5383 39.6893 36.8022 40.1885C36.457 40.2877 36.1706 40.5253 36.0125 40.8479L29.1763 54.7938C28.5574 56.0564 30.1789 57.2162 31.1737 56.2224L39.686 47.7203Z" fill="#FF9908"/>
        <path d="M58.4635 36.6327L51.8531 30.0223C51.3668 29.5362 50.5784 29.5362 50.0925 30.0223L47.7482 32.3666C47.2621 32.8527 47.2621 33.6409 47.7482 34.1271L54.3585 40.7375C54.8448 41.2236 55.6328 41.2236 56.1191 40.7375L58.4635 38.3932C58.9494 37.9071 58.9494 37.1189 58.4635 36.6327Z" fill="#FF9908"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default AiSvg;
