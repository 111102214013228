import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import KanbanSvg from "components/icons/kanban";
import ListSvg from "components/icons/list";
import ModelSvg from "components/icons/model";
import { DisplayView } from "lib/types/applicationTypes";
import FormSvg from "components/icons/form";
import DueDatesSvg from "components/icons/due-date";
import FilesSvg from "components/icons/resizable/files";

export const BoardDefaultDisplayView = {
  list: true,
  kanban: true,
  calendar: true,
  form: true,
  files: true,
  model: false,
};

export type IBoardDisplayViewItem = keyof typeof BoardDefaultDisplayView;

export interface IBoardDisplayView {
  code: IBoardDisplayViewItem;
  title: string;
  description: string;
  icon: EmotionJSX.Element;
}

export interface BoardViewItemClickHandler {
  (item: IBoardDisplayViewItem, checked: boolean): void;
}

export const BoardDisplayViews: Array<IBoardDisplayView> = [
  {
    code: DisplayView.List,
    title: "List",
    description: "",
    icon: <ListSvg />,
  },
  {
    code: DisplayView.Kanban,
    title: "Kanban",
    description: "",
    icon: <KanbanSvg />,
  },
  {
    code: DisplayView.Calendar,
    title: "Calendar",
    description: "",
    icon: <DueDatesSvg />,
  },
  {
    code: DisplayView.Form,
    title: "Form",
    description: "",
    icon: <FormSvg />,
  },
  {
    code: DisplayView.Files,
    title: "Files",
    description: "",
    icon: <FilesSvg size={12} />,
  },
  {
    code: DisplayView.Model,
    title: "Model",
    description: "",
    icon: <ModelSvg />,
  },
];
