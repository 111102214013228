import { FC } from "react";

import { useBoardContext } from "lib/contexts/BoardContext";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import { SectionSubheading } from "styles/common";
import { BoardDefaultFeatures, BoardFeaturesItems } from "./lib";
import BoardSettingsDisplayViewItem from "./BoardSettingsFeatureItem";
import { BoardSettingsFeaturesGrid } from "pages/Board/BoardSettings/BoardSettingsFeatures/styled";

const BoardSettingsFeatures: FC = () => {
  const { board } = useBoardContext();
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);

  const initialValues = board?.features || BoardDefaultFeatures;

  return (
    <>
      {allowedToEdit &&
        <>
          <SectionSubheading>Blocks</SectionSubheading>
          <BoardSettingsFeaturesGrid>
            {BoardFeaturesItems.map((displayBlock) => (
              <BoardSettingsDisplayViewItem
                key={displayBlock.code}
                boardDisplayBlock={displayBlock}
                initialValues={initialValues}
                editable={allowedToEdit}
              />
            ))}
          </BoardSettingsFeaturesGrid>
        </>
      }
    </>
  );
};

export default BoardSettingsFeatures;
