import styled from "@emotion/styled/macro";

export const SidebarTopMenuIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const SidebarTopMenuTitle = styled.div`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
`;
