import { TaskInterface } from "lib/types/tasksTypes";

export enum generalFilters {
  IncompleteTasks = "Incomplete",
  CompletedTasks = "Completed",
  AllTasks = "All tasks"
}

export const filterTasks = (tasks: TaskInterface[], currentFilter: string) => {
  if (currentFilter === generalFilters.CompletedTasks) {
    return tasks?.filter(task => task.completed);
  } else if (currentFilter === generalFilters.IncompleteTasks) {
    return tasks?.filter(task => !task.completed);
  }
  return tasks;
};
