import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrganizationInterface } from "lib/types/organization";

interface InitialStateType {
  organizations: Array<OrganizationInterface>;
  loading: boolean | null;
}

const initialState: InitialStateType = {
  organizations: [],
  loading: null,
};

export const organizationSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setOrganizationsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setOrganizations: (state, action: PayloadAction<OrganizationInterface[]>) => {
      state.organizations = action.payload;
    },
    addOrganization: (state, action: PayloadAction<OrganizationInterface>) => {
      state.organizations.push(action.payload);
    },
    updateOrganization: (state, action: PayloadAction<OrganizationInterface>) => {
      const organizationIndex = state.organizations.findIndex(o => o.id === action.payload.id);
      if (organizationIndex !== -1) {
        state.organizations[organizationIndex] = action.payload;
      }
    },
    deleteOrganization: (state, action: PayloadAction<OrganizationInterface>) => {
      const organizationIndex = state.organizations.findIndex(o => o.id === action.payload.id);
      if (organizationIndex !== -1) {
        state.organizations.splice(organizationIndex, 1);
      }
    }
  }
});

export const {
  setOrganizationsLoading,
  setOrganizations,
  addOrganization,
  updateOrganization,
  deleteOrganization,
} = organizationSlice.actions;

export default organizationSlice.reducer;
