import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { DisplayView } from "lib/types/applicationTypes";
import { BoardViewInterface } from "lib/types/boardTypes";

export const getCustomViewTitle = (displayView: DisplayView, view?: BoardViewInterface) => {
  return (displayView === view?.view) ? view.title : false;
};

export interface BoardViewTabInterface {
  title: string;
  displayView: DisplayView;
  path: string;
  icon: EmotionJSX.Element;
  onClickOverride?: React.MouseEventHandler;
}
