import { KanbanStructureInterface, StatusInterface } from "lib/types/kanbanTypes";

export const tasksSortUtil = (list: StatusInterface[]) => list.reduce((acc: any, item) => {
  let cards = [];
  if (item.cards && item.cards.length) {
    cards = item.cards.reduce((result: any, card) => [...result, { id: card.id, sort: card.sort }], []);
  }

  return [...acc, { status: item.id, tasks: [...cards] }];
}, []);

interface TaskSortProps {
  lanes: StatusInterface[];
  fromLaneId: string;
  toLaneId: string;
  cardId: number;
  index: number;
}

export const tasksSort = (props: TaskSortProps) => {
  const { lanes, fromLaneId, toLaneId, cardId, index } = props;

  const processedData = tasksSortUtil(lanes);
  const newLane = processedData.find((item: any) => String(item.status) === String(toLaneId));

  if (fromLaneId !== toLaneId) {
    const taskLane = processedData.find((item: Record<string, any>) => String(item.status) === String(fromLaneId));
    const draggedTask = taskLane.tasks.find((item: Record<string, any>) => item.id === cardId);
    newLane.tasks.splice(index, 0, draggedTask);
    newLane.tasks = newLane.tasks.map((item: Record<string, any>, key: number) => ({ ...item, sort: key }));

    return newLane;
  }

  const indexCard = newLane.tasks.findIndex((item: Record<string, any>) => item.id === cardId);
  newLane.tasks = newLane.tasks
    .move(indexCard, index)
    .map((item: Record<string, any>, key: number) => ({ ...item, sort: key }));

  return newLane;
};

export const getBoardState = (data: KanbanStructureInterface) => {
  return (data?.lanes || [])?.map(lane => ({
    status: lane.id,
    title: lane.title,
    tasks: lane.cards?.map(item => ({ id: item.id, sort: Number(item.sort__kanban) })) || [],
  }));
};
