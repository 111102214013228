import { FC } from "react";
import { useHistory } from "react-router-dom";
import * as procoreIframeHelpers from "@procore/procore-iframe-helpers";
import { Layout, Result } from "antd";
import { Content } from "antd/lib/layout/layout";
import { getOAuthLink } from "lib/helpers/navigationUtils";
import { OAuthProviders, UserResponse } from "lib/types/types";
import { useAppDispatch } from "app/store";
import { setToken } from "app/slices/authSlice";
import { setUser } from "app/slices/currentUserSlice";
import { StyledButton } from "styles/common";
import {
  ProcoreIntegrationLogo,
  ProcoreIntegrationLogoWrapper,
  ProcoreIntegrationWrapper
} from "pages/ProcoreIntegration/styled";
import useAuthorizedRedirect from "lib/customHooks/routeHooks/useAuthorizedRedirect";
import configuration from "lib/configs/configuration";

const ProcoreIntegration: FC = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const iframeHelperContext = procoreIframeHelpers.initialize();
  useAuthorizedRedirect();

  const handleProcoreOnClick = () => {
    iframeHelperContext.authentication.authenticate({
      url: getOAuthLink(OAuthProviders.Procore),
      onSuccess(payload: UserResponse) {
        dispatch(setToken(payload.jwt));
        dispatch(setUser(payload.user));
        history.replace("/home");
      },
      onFailure(error: Error) {
        console.log("authentication failed!", error);
      },
    });
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Content style={{ width: "67%", alignSelf: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Result
          style={{ marginBottom: "40px" }}
          icon={
            <ProcoreIntegrationWrapper>
              <ProcoreIntegrationLogoWrapper>
                <ProcoreIntegrationLogo alt="airtasks" src={process.env.PUBLIC_URL + "/images/logos/airtasks.svg"} />
              </ProcoreIntegrationLogoWrapper>
              <img alt="airtasks" src={process.env.PUBLIC_URL + "/icons/connect.svg"} />
              <ProcoreIntegrationLogoWrapper>
                <ProcoreIntegrationLogo alt="procore" src={process.env.PUBLIC_URL + "/images/logos/procore.svg"} />
              </ProcoreIntegrationLogoWrapper>
            </ProcoreIntegrationWrapper>
          }
          title={
            <div style={{ lineHeight: 1.3, marginBottom: 10 }}>
              Connect {configuration.app.title} to your Procore account
            </div>
          }
          subTitle={`${configuration.app.title} is an official partner with Procore`}
          extra={[
            <StyledButton purple key="procore" onClick={handleProcoreOnClick}>Continue</StyledButton>
          ]}
        >
        </Result>
      </Content>
    </Layout>
  );
};

export default ProcoreIntegration;
