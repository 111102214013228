import { Select } from "antd";
import {
  selectFilterTypes,
  setFilterFunction,
  setFilterTitle,
  setFilterV3,
  setFilterValue
} from "app/slices/tasksFilterAndSortSlice";
import { useAppDispatch, useAppSelector } from "app/store";
import ButtonIcon from "components/ui/ButtonIcon";
import { getMappedFilterFunction } from "lib/filters/filterFunctions";
import { FilterValueNone, FilterValueType, FilterVersion, noneFilterValue } from "lib/filters/filters";
import { FilterType } from "lib/filters/filterTypes";
import { TaskInterface } from "lib/types/tasksTypes";
import React, { FC, useEffect } from "react";
import FilterFunctions from "../FilterFunctions";
import FilterOperator from "../FilterOperator";
import FilterTypes from "../FilterTypes";
import { singleFilterWrapper } from "../styled";

type SingleFilterProps = {
  setIsNoneFilter: (state: boolean) => void
  isSortCustomViewsDisabled?: boolean
  filters: {
    [key: string]: {
      filterValues: (...args: any[]) => FilterValueType[]
      filterFn: (...args: any[]) => TaskInterface[]
      sort?: number
    }
  };
  onChangeTitle?: (title: string) => void;
  onChangeValue?: (value: FilterValueType) => void;
  hideJustMyTasks?: boolean;
  isCustomView?: boolean;
  filterTitles: string[]
}

const SingleFilter: FC<SingleFilterProps> = (props) => {
  const { setIsNoneFilter, isSortCustomViewsDisabled, filterTitles } = props;

  const dispatch = useAppDispatch();
  const currentFilter = useAppSelector(state => state.boardFilterAndSort.filter);
  const filtersVersion = useAppSelector(state => state.boardFilterAndSort.filter.version);
  const filtersTypes = useAppSelector(state => state.boardFilterAndSort.filter.types);
  const types = useAppSelector(selectFilterTypes);

  useEffect(() => {
    setIsNoneFilter(currentFilter.titleKey === FilterValueNone && currentFilter.value.text === FilterValueNone);
  }, [currentFilter.titleKey, currentFilter.value.text, setIsNoneFilter]);

  const getFilterFunction = (title: string) => {
    const filterKeys = getMappedFilterFunction({ ...currentFilter, titleKey: title }, types);
    return filterKeys?.[0] || "";
  };

  const onSelectTitle = (title: string) => {
    const filterFunction = getFilterFunction(title);

    dispatch(setFilterTitle(title));
    dispatch(setFilterFunction(filterFunction));

    if (filtersTypes[title] === FilterType.Boolean) {
      const booleanDefaultValue = { text: "False", valueKey: false };
      dispatch(setFilterValue(booleanDefaultValue));
    } else {
      dispatch(setFilterValue(noneFilterValue));
    }
  };

  const onClose = () => {
    dispatch(setFilterTitle(FilterValueNone));
    dispatch(setFilterValue(noneFilterValue));
    dispatch(setFilterFunction(""));
    dispatch(setFilterV3());
  };

  return (
    <div className={singleFilterWrapper}>
      {filtersVersion && (filtersVersion !== FilterVersion.v1) &&
        <FilterOperator index={0} />
      }
      <Select
        style={{ minWidth: 125, marginRight: "7px" }}
        filterOption={(input, option) =>
          String(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) => {
          const sortA = props.filters[String(optionA.children)]?.sort;
          const sortB = props.filters[String(optionB.children)]?.sort;
          if (sortA && sortB) {
            return sortA - sortB;
          }
          return String(optionA.children).toLowerCase().localeCompare(String(optionB.children).toLowerCase());
        }}
        defaultValue={currentFilter.titleKey === FilterValueNone ? "Select field" : currentFilter.titleKey}
        onSelect={(item: string) => onSelectTitle(item)}
        dropdownStyle={{ minWidth: 175 }}
        disabled={isSortCustomViewsDisabled}
      >
        {filterTitles.filter(item => item !== FilterValueNone).map(item =>
          <Select.Option value={item} key={item}>
            {item}
          </Select.Option>
        )}
      </Select>
      {filtersVersion && (filtersVersion !== FilterVersion.v1) &&
        <FilterFunctions currentFilter={currentFilter} />
      }
      <FilterTypes
        currentFilter={currentFilter}
        disabled={isSortCustomViewsDisabled}
      />
      {!isSortCustomViewsDisabled &&
        <ButtonIcon onClick={onClose} iconUrl="/icons/x.svg" />
      }
    </div>
  );
};

export default SingleFilter;
