import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { Image } from "antd";

export interface ViewpointLikeCardContainerProps {
  current?: boolean;
  nonClickable?: boolean;
  cardSize?: number;
  bgImageUrl?: string;
  geometry?: any;
  grayedOut?: boolean;
}

export const DeleteButtonContainer = styled.div`
  position: absolute;
  opacity: 0;
  left: auto;
  top: 0;
  right: 0;
  bottom: auto;
  margin-top: -6px;
  margin-right: -6px;
  cursor: pointer;
`;

export const DeleteButton = styled.div`
  display: flex;
  width: 12px;
  height: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f2f2f2;
  transition: opacity 200ms ease;
`;

export const MoreButtonContainer = styled.div`
  position: absolute;
  opacity: 0;
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  margin-bottom: 2px;
  margin-right: 2px;
  cursor: pointer;
  transition: opacity 200ms ease;
`;

export const MoreButton = styled.div<{ active?: boolean }>`
  display: flex;
  width: 11px;
  height: 11px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  transition: background-color, opacity 200ms ease;
  ${({ active }) => active && css`
    opacity: 1;
    background-color: white;
  `}
`;

export const ViewpointLikeCardOuterContainer = styled.div<ViewpointLikeCardContainerProps>`
  flex: 0 0 auto;
  position: relative;
  display: inline-block;
  width: ${({ cardSize }) => cardSize ? `${cardSize}px` : "36px"};
  height: ${({ cardSize }) => cardSize ? `${cardSize}px` : "36px"};
  border-radius: 7px;
  border: 2px solid #f2f2f2;
  background-image: ${({ bgImageUrl }) => bgImageUrl ? `url(${bgImageUrl})` : ""};
  background-size: cover;
  background-position: 50% 50%;
  transition: border-color 200ms ease;
  cursor: pointer;
  &:hover {
    border-color: #e5e5e5;
    ${DeleteButtonContainer} {
      opacity: 1;
    }
    ${MoreButtonContainer} {
      opacity: 1;
    }
  }
  ${({ geometry }) => geometry && css`
      background-color: #f6f6f6;
    `};
  ${({ current }) => current && css`
      border-color: #1890ff;
      &:hover {
        border-color: #1890ff;
      }
    `};
  ${({ nonClickable }) => nonClickable && css`
      cursor: auto;
    `};
  ${({ grayedOut }) => grayedOut && css`
      opacity: 0.2;
      cursor: auto;
    `};
  .ant-image {
    border-radius: 5px;
    overflow: hidden;
  }
`;

export const ViewpointLikeCardContentContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ViewpointLikeCardImage = styled(Image) <ViewpointLikeCardContainerProps>`
  object-fit: cover;
  width: ${({ cardSize }) => cardSize ? `${cardSize - 4}px` : "32px"};
  height: ${({ cardSize }) => cardSize ? `${cardSize - 4}px` : "32px"};
  ${({ current }) => current && css`
      border-color: #1890ff;
      &:hover {
        border-color: #1890ff;
      }
    `};
`;
