import React, { FC } from "react";
import moment from "moment";

import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import { TaskPropertyDateTimeFormat, TaskPropertyTimeFormat } from "lib/types/kanbanTypes";
import { DateGenericWrapper, InnerText, DateGenericInnerWrapper } from "../styled";
import { DateFormats, TimeFormats } from "lib/theme/lib";
import { DefaultIconColor } from "components/icons/lib";
import { DateMasksMap, TimeMasksMap, truncateTimeSuffix } from "lib/helpers/dateUtils";
import { DeleteButton } from "components/blocks/ViewpointLikeCard/styled";

interface DateValueProps {
  dateValue: moment.Moment;
  dateFormat?: TaskPropertyDateTimeFormat;
  timeFormat?: TaskPropertyTimeFormat;
  isCompleted?: boolean;
  propertyType?: TaskPropertyClass;
  setDateNull: (e: React.MouseEvent) => void;
  disableClear?: boolean;
  disableColors?: boolean;
}

const DateValue: FC<DateValueProps> = (props) => {
  const { dateValue, dateFormat, timeFormat, isCompleted, disableColors, propertyType, setDateNull } = props;

  const dateMask = (dateFormat) ? DateMasksMap[dateFormat] : DateFormats.Friendly;
  const timeMask = (timeFormat) ? TimeMasksMap[timeFormat] : TimeFormats.US;

  const timeString = (timeFormat === TaskPropertyTimeFormat.None) ? "" : dateValue.format(timeMask);
  const renderedDateString = getDateString() + " " + truncateTimeSuffix(timeString);

  function getDateString(): string {
    const now = moment();

    if (dateFormat === TaskPropertyDateTimeFormat.Default) {
      const diff = Math.ceil(dateValue.diff(now, "days", true));
      switch (diff) {
        case -1:
          return "Yesterday";
        case 0:
          return "Today";
        case 1:
          return "Tomorrow";
        default:
          if (diff > 1 && diff < 7) {
            return dateValue.format("dddd");
          } else {
            return dateValue.format(dateMask);
          }
      }
    } else {
      return dateValue.format(dateMask);
    }
  }

  function getDateColor() {
    const now = moment();
    const diff = Math.ceil(dateValue.diff(now, "days", true));

    if (isCompleted) {
      return DefaultIconColor;
    }

    if (disableColors) {
      return "#333";
    }

    switch (propertyType) {
      case TaskPropertyClass.Default:
        if (diff < 0) {
          return "#c92f54";
        } else if (diff === 0) {
          return "#58a182";
        } else {
          return "#333";
        }
      default:
        return "#333";
    }
  }

  return (
    <DateGenericWrapper color={getDateColor()}>
      <InnerText>
        <DateGenericInnerWrapper>
          {renderedDateString}
          {!props.disableClear &&
            <div style={{ marginLeft: "5px" }}>
              <DeleteButton onClick={setDateNull} data-btn_type="close_btn">
                <img alt="" src={process.env.PUBLIC_URL + "/icons/close_6px.svg"} />
              </DeleteButton>
            </div>
          }
        </DateGenericInnerWrapper>
      </InnerText>
    </DateGenericWrapper>
  );
};

export default DateValue;
