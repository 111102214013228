import { Form } from "antd";
import { setToken } from "app/slices/authSlice";

import WebSocketService from "lib/API/socket";
import useAuthorizedRedirect from "lib/customHooks/routeHooks/useAuthorizedRedirect";
import { FC, useCallback, useEffect, useState } from "react";
import AuthService from "services/AuthService";
import useAsyncData from "lib/customHooks/useAsyncData";
import AuthEmail from "pages/Auth/AuthComponents/Email";
import AuthResetPassword from "pages/Auth/AuthComponents/ResetPassword";
import AuthSignInButton from "pages/Auth/AuthComponents/SignInButton";
import AuthContainer from "pages/Auth/index";

import { AuthReactLink } from "pages/Auth/styled";
import { useAppDispatch } from "app/store";

const SignIn: FC = () => {
  useAuthorizedRedirect();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const fetch = useCallback(() => {
    return AuthService.login({ email, password, identifier: email });
  }, [email, password]);

  const { data, loadData } = useAsyncData<any>({
    fetchFn: fetch
  });

  useEffect(() => {
    if (data && data.jwt) {
      dispatch(setToken(data.jwt));
      WebSocketService.connect();
    }
  }, [data]);

  type valueChanged = {
    email?: string
    password?: string
  };

  const onValuesChange = (valueChanged: valueChanged) => {
    if (valueChanged.email) {
      setEmail(valueChanged.email);
    } else if (valueChanged.password) {
      setPassword(valueChanged.password);
    }
  };

  return (
    <Form
      name="auth"
      form={form}
      onFinish={loadData}
      onValuesChange={onValuesChange}
      style={{ width: "100%" }}
      requiredMark={false}
      layout="vertical"
    >
      <AuthContainer
        header="Sign in"
        emailComponent={<AuthEmail autoFocus />}
        resetPasswordComponent={<AuthResetPassword text="Password" />}
        forgotPassword={<AuthReactLink to="/auth/forgot-password">Forgot password?</AuthReactLink>}
        signInButton={<AuthSignInButton text="Sign in" />}
        haveAccountText="Don't have an account?"
        linkComponent={<AuthReactLink to="/auth/register">Sign Up</AuthReactLink>}
      />
    </Form>
  );
};

export default SignIn;
