import { useCallback } from "react";

import { UserInterface } from "lib/types/types";
import { useAppDispatch, useAppSelector } from "app/store";
import { setUser } from "app/slices/currentUserSlice";

import useWSSubscription from "../useWSSubscription";
import { WsEvents } from "lib/types/wsTypes";

const useWSUpdateSelf = () => {
  const currentUserId = useAppSelector(state => state.currentUserReducer.user?.id);
  const dispatch = useAppDispatch();

  const callback = useCallback((user: UserInterface) => {
    if (String(currentUserId) === String(user.id)) {
      dispatch(setUser(user));
    }
  }, [currentUserId, dispatch]);

  useWSSubscription({
    eventName: WsEvents.User.Update,
    callback,
    fnKey: "useWSUpdateStatuses"
  });
};

export default useWSUpdateSelf;
