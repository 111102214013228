import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const PptSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.4605 20.4357C64.192 20.4335 62.0169 19.5313 60.4127 17.9272C58.8086 16.3231 57.9065 14.1481 57.9044 11.8796V0H19.672C16.7106 0 13.8706 1.17644 11.7766 3.27047C9.68255 5.36448 8.5061 8.20452 8.5061 11.1659V78.4772C8.5061 81.4386 9.68255 84.2788 11.7766 86.3726C13.8706 88.4668 16.7106 89.6432 19.672 89.6432H65.5642C67.031 89.6436 68.4833 89.3552 69.8385 88.7942C71.1937 88.2336 72.4252 87.4112 73.4625 86.3743C74.4999 85.3374 75.3227 84.1062 75.8841 82.751C76.4455 81.3963 76.7343 79.944 76.7343 78.4772V20.4357H66.4605Z" fill="#FF3E4C"/>
        <path d="M76.7344 20.4357H66.4606C64.1921 20.4335 62.017 19.5313 60.4128 17.9272C58.8087 16.3231 57.9066 14.1481 57.9045 11.8796V0L76.7344 20.4357Z" fill="#FF3E4C"/>
        <path d="M84.8299 70.7925H32.946C29.2657 70.7925 26.2822 73.7759 26.2822 77.4564V92.9253C26.2822 96.6058 29.2657 99.5892 32.946 99.5892H84.8299C88.5104 99.5892 91.4938 96.6058 91.4938 92.9253V77.4564C91.4938 73.7759 88.5104 70.7925 84.8299 70.7925Z" fill="#FF3E4C"/>
        <path d="M42.3258 93.361C41.7333 93.361 41.437 93.0648 41.437 92.4722V79.9021C41.437 79.3096 41.7333 79.0133 42.3258 79.0133H47.2777C48.5685 79.0133 49.5561 79.359 50.2403 80.0502C50.9316 80.7345 51.2773 81.722 51.2773 83.0129V83.6477C51.2773 84.9386 50.9316 85.9295 50.2403 86.6208C49.5561 87.305 48.5685 87.6473 47.2777 87.6473H43.2146V92.4722C43.2146 93.0648 42.9183 93.361 42.3258 93.361ZM43.2146 85.8697H47.2777C48.0818 85.8697 48.6532 85.7004 48.9918 85.3619C49.3304 85.0233 49.4997 84.4519 49.4997 83.6477V83.0129C49.4997 82.2158 49.3304 81.6477 48.9918 81.3092C48.6532 80.9635 48.0818 80.7909 47.2777 80.7909H43.2146V85.8697Z" fill="white"/>
        <path d="M55.3038 93.361C54.7113 93.361 54.415 93.0648 54.415 92.4722V79.9021C54.415 79.3096 54.7113 79.0133 55.3038 79.0133H60.2557C61.5466 79.0133 62.5341 79.359 63.2184 80.0502C63.9096 80.7345 64.2553 81.722 64.2553 83.0129V83.6477C64.2553 84.9386 63.9096 85.9295 63.2184 86.6208C62.5341 87.305 61.5466 87.6473 60.2557 87.6473H56.1926V92.4722C56.1926 93.0648 55.8964 93.361 55.3038 93.361ZM56.1926 85.8697H60.2557C61.0599 85.8697 61.6312 85.7004 61.9698 85.3619C62.3084 85.0233 62.4777 84.4519 62.4777 83.6477V83.0129C62.4777 82.2158 62.3084 81.6477 61.9698 81.3092C61.6312 80.9635 61.0599 80.7909 60.2557 80.7909H56.1926V85.8697Z" fill="white"/>
        <path d="M71.6891 93.361C71.3929 93.361 71.1705 93.2871 71.0223 93.139C70.8742 92.9909 70.8003 92.7684 70.8003 92.4722V80.812H66.6949C66.3987 80.812 66.1763 80.7377 66.0281 80.5896C65.88 80.4415 65.8062 80.2195 65.8062 79.9232C65.8062 79.627 65.88 79.4045 66.0281 79.2564C66.1763 79.1083 66.3987 79.0344 66.6949 79.0344H76.6833C76.9796 79.0344 77.2016 79.1083 77.3497 79.2564C77.4979 79.4045 77.5721 79.627 77.5721 79.9232C77.5721 80.2195 77.4979 80.4415 77.3497 80.5896C77.2016 80.7377 76.9796 80.812 76.6833 80.812H72.5779V92.4722C72.5779 92.7543 72.5003 92.973 72.3452 93.1282C72.197 93.2834 71.9783 93.361 71.6891 93.361Z" fill="white"/>
        <path d="M39.4978 34.7428H21.3692C20.9573 34.7428 20.5621 34.5795 20.2704 34.2885C19.9787 33.9976 19.8143 33.6029 19.8132 33.1909C19.8132 32.7782 19.9772 32.3824 20.269 32.0907C20.5608 31.7988 20.9566 31.6349 21.3692 31.6349H39.4812C39.8939 31.6349 40.2898 31.7988 40.5816 32.0907C40.8734 32.3824 41.0373 32.7782 41.0373 33.1909C41.0362 33.6 40.8741 33.9922 40.586 34.2826C40.2978 34.5731 39.9069 34.7384 39.4978 34.7428Z" fill="#FF3E4C"/>
        <path d="M39.4978 44.025H21.3692C20.9573 44.025 20.5621 43.8615 20.2704 43.5706C19.9787 43.2797 19.8143 42.8851 19.8132 42.4731C19.8132 42.0606 19.9772 41.6648 20.269 41.3728C20.5608 41.081 20.9566 40.9171 21.3692 40.9171H39.4812C39.8939 40.9171 40.2898 41.081 40.5816 41.3728C40.8734 41.6648 41.0373 42.0606 41.0373 42.4731C41.0362 42.8822 40.8741 43.2743 40.586 43.5648C40.2978 43.8552 39.9069 44.0208 39.4978 44.025Z" fill="#FF3E4C"/>
        <path d="M39.4978 53.3071H21.3692C20.9566 53.3071 20.5608 53.1432 20.269 52.8515C19.9772 52.5594 19.8132 52.164 19.8132 51.7511C19.8165 51.3407 19.9819 50.9478 20.2734 50.659C20.5648 50.3698 20.9587 50.2075 21.3692 50.2075H39.4812C39.8932 50.2075 40.2884 50.371 40.5801 50.6619C40.8718 50.9527 41.0362 51.3474 41.0373 51.7594C41.0351 52.1677 40.8725 52.559 40.5845 52.8486C40.2966 53.1382 39.9062 53.3025 39.4978 53.3071Z" fill="#FF3E4C"/>
        <path d="M39.4978 62.5892H21.3692C20.9566 62.5892 20.5608 62.4253 20.269 62.1336C19.9772 61.8415 19.8132 61.446 19.8132 61.0332C19.8143 60.6212 19.9787 60.2266 20.2704 59.9357C20.5621 59.6448 20.9573 59.4813 21.3692 59.4813H39.4812C39.8932 59.4813 40.2884 59.6448 40.5801 59.9357C40.8718 60.2266 41.0362 60.6212 41.0373 61.0332C41.0373 61.4431 40.8757 61.8361 40.5875 62.1278C40.2993 62.4191 39.9077 62.5851 39.4978 62.5892Z" fill="#FF3E4C"/>
        <path d="M58.4008 48.9644C57.714 48.9635 57.1576 48.4063 57.1576 47.7195V36.4824C57.1576 35.7692 56.5576 35.1962 55.8526 35.3046C52.9854 35.7456 50.331 37.126 48.3174 39.2482C46.0014 41.6893 44.7101 44.9258 44.7095 48.2905C44.7089 50.0158 45.0483 51.7245 45.7083 53.3183C46.3683 54.9125 47.336 56.361 48.5559 57.581C49.7759 58.8009 51.2244 59.7685 52.8186 60.4287C54.4124 61.0884 56.1211 61.4278 57.8464 61.4274C61.2111 61.4278 64.4476 60.1366 66.8883 57.8204C69.0107 55.8059 70.3904 53.1503 70.8294 50.2818C70.9369 49.5776 70.3651 48.9785 69.6526 48.9776L58.4008 48.9644Z" fill="#FF3E4C"/>
        <path d="M60.975 34.2524C60.2709 34.1453 59.6721 34.7181 59.6721 35.4306V45.3237C59.6721 46.0113 60.2294 46.5685 60.9169 46.5685H70.8103C71.5227 46.5685 72.0954 45.9698 71.9883 45.2656C71.5688 42.5067 70.2792 39.9402 68.2895 37.951C66.3003 35.9617 63.7339 34.6719 60.975 34.2524Z" fill="#FF3E4C"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default PptSvg;
