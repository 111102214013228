import { FC, useMemo, useRef } from "react";
import ConditionalWrapper from "components/blocks/ConditionalWrapper";
import { Column, useBlockLayout, useTable } from "react-table";
import { Virtuoso } from "react-virtuoso";
import { TableContent, TableHeader, TaskTableWrapper, Td, Tr } from "../styled";
import { DefaultTableProps } from "../types";
import Cell from "./Cell";
import Header from "./Header";
import { wrapMemberProviderFn, wrapTaskProviderFn } from "./lib";
import { tableScroll } from "components/blocks/TaskTable/Table/styled";
import CustomScrollbar from "components/blocks/CustomScrollbar";
import TaskTableEmptyState from "../components/TaskTableEmptyState";

const TableLite: FC<DefaultTableProps> = (props) => {
  const { columns, data, columnsProps, customization, onItemExpand, currentSort, currentFilter, wrapMemberProvider, wrapTaskProvider } = props;
  const isLiteVersion = true;
  const tableHeaderRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  const defaultColumn: Partial<Column<object>> = useMemo(
    () => ({
      minWidth: columnsProps?.minWidth || 150,
      width: columnsProps?.width || 200,
      maxWidth: columnsProps?.maxWidth || 400,
      Cell: Cell,
      Header: Header as never,
      sortType: "alphanumericFalsyLast"
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
  } = useTable({
    columns,
    data,
    defaultColumn,
  }, useBlockLayout);

  const virtualItem = (i: number) => {
    const row = rows[i];
    prepareRow(row);

    return (
      <Tr {...row.getRowProps()}>
        {row.cells.map((cell: any, index: number) => {
          return (
            <ConditionalWrapper key={index} condition={!!wrapTaskProvider} wrapper={children => wrapTaskProviderFn(children, cell)}>
              <ConditionalWrapper condition={!!wrapMemberProvider} wrapper={children => wrapMemberProviderFn(children, cell)}>
                <Td
                  {...cell.getCellProps()}
                  isSorted={currentSort?.field?.code === cell.column.code}
                  isFiltered={currentFilter?.titleKey === cell.column.title}
                >
                  {cell.render("Cell", {
                    lite: isLiteVersion,
                    customization: customization?.cells,
                    task: row.original,
                    onItemExpand: onItemExpand,
                  })}
                </Td>
              </ConditionalWrapper>
            </ConditionalWrapper>
          );
        })}
      </Tr>
    );
  };

  const virtualListHeight = Number(tableRef?.current?.clientHeight) - Number(tableHeaderRef?.current?.clientHeight);
  const virtualListWidth = (Number(tableRef?.current?.clientWidth) > totalColumnsWidth)
    ? Number(tableRef?.current?.clientWidth)
    : totalColumnsWidth;

  return (
    <TaskTableWrapper {...getTableProps()} ref={tableRef}>
      <TableHeader width={totalColumnsWidth} ref={tableHeaderRef}>
        {headerGroups.map(headerGroup => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => column.render("Header", { key: column.id, lite: isLiteVersion }))}
          </Tr>
        ))}
      </TableHeader>
      <TableContent {...getTableBodyProps()}>
        <Virtuoso
          className={tableScroll}
          style={{
            width: virtualListWidth,
            height: virtualListHeight,
            overflowX: "hidden"
          }}
          totalCount={rows.length}
          itemContent={virtualItem}
          components={{
            Scroller: CustomScrollbar,
            EmptyPlaceholder: TaskTableEmptyState,
          }}
        />
      </TableContent>
    </TaskTableWrapper>
  );
};

export default TableLite;
