import { RecordTemplateInterface } from "lib/types/boardTypes";
import { EntityId } from "lib/types/entity";
import { captureException } from "lib/utils/sentry";
import BoardService from "services/BoardService";
import useBoardPerformRequest, { BoardPerformRequestHookProps } from "./useBoardPerformRequest";

const useBoardSetRecordTemplates = (props: BoardPerformRequestHookProps) => {
  const { board } = props;
  const performRequest = useBoardPerformRequest(props);

  const updateBoardRecordTemplate = (recordTemplateId: EntityId, payload: Partial<RecordTemplateInterface>) => {
    if (!board) {
      return;
    }

    const recordTemplateIndex = board.recordTemplates.findIndex(recordTemplate => String(recordTemplate.id) === String(recordTemplateId));
    if (recordTemplateIndex === -1) {
      captureException(`Cannot find recordTemplate ${recordTemplateId}`);
      return;
    }

    const recordTemplates = Array.from(board.recordTemplates);
    const recordTemplate = recordTemplates[recordTemplateIndex];
    recordTemplates[recordTemplateIndex] = { ...recordTemplate, ...payload };

    const requestFn = () => BoardService.recordTemplatesUpdate(board.id, recordTemplateId, payload);

    return performRequest({ recordTemplates }, requestFn);
  };

  const deleteBoardRecordTemplate = (recordTemplateId: EntityId) => {
    if (!board) {
      return;
    }

    const recordTemplateIndex = board.recordTemplates.findIndex(recordTemplates => String(recordTemplates.id) === String(recordTemplateId));
    if (recordTemplateIndex === -1) {
      captureException(`Cannot find recordTemplate ${recordTemplateId}`);
      return;
    }

    const recordTemplates = Array.from(board.recordTemplates);
    recordTemplates.splice(recordTemplateIndex, 1);

    const requestFn = () => BoardService.recordTemplatesDelete(board.id, recordTemplateId);

    return performRequest({ recordTemplates }, requestFn);
  };

  return { updateBoardRecordTemplate, deleteBoardRecordTemplate };
};

export default useBoardSetRecordTemplates;
