import { updateTask, updateTaskChangelog } from "app/slices/currentTaskSlice";
import { useAppDispatch } from "app/store";
import { isCurrentInitiator, isAllowedTaskUpdateMethod } from "lib/helpers/websocketUtils";
import { TaskInterface } from "lib/types/tasksTypes";
import { WsEvents } from "lib/types/wsTypes";
import { useCallback } from "react";
import useWSSubscription from "../useWSSubscription";

const useWSUpdateTaskDetailed = (forceUpdate = false) => {
  const dispatch = useAppDispatch();

  const callback = useCallback((data: TaskInterface) => {
    const updateAvailable = !isCurrentInitiator(data.transactionInitiator) || isAllowedTaskUpdateMethod(data.transactionInitiator);

    if (forceUpdate || updateAvailable) {
      dispatch(updateTask(data));
    }

    dispatch(updateTaskChangelog(data));
  }, [dispatch, forceUpdate]);

  useWSSubscription<TaskInterface>({
    eventName: WsEvents.Task.Update,
    callback,
    fnKey: "useWSUpdateTaskDetailed"
  });
};

export default useWSUpdateTaskDetailed;
