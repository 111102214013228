import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const DownloadSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
        <path d="M0 68.3333C0 64.1912 3.35787 60.8333 7.5 60.8333C11.6422 60.8333 15 64.1912 15 68.3333V84.9996H85V68.3333C85 64.1912 88.3575 60.8333 92.5 60.8333C96.6417 60.8333 100 64.1912 100 68.3333V92.4206C100 92.4469 100 92.4732 100 92.4996C100 94.4414 99.2617 96.2109 98.0508 97.5428C97.8825 97.7287 97.7042 97.9061 97.5175 98.0743C96.1883 99.2714 94.4292 100 92.5 100C92.4733 100 92.4467 99.9999 92.4208 99.9996H7.57894C7.55267 99.9999 7.52635 100 7.5 100C3.35787 100 0 96.6421 0 92.5L0 68.3333Z"/>
        <path d="M74.4553 53.1002C77.3843 50.1712 77.3843 45.4226 74.4553 42.4936C71.5264 39.5646 66.7777 39.5646 63.8488 42.4936L57.4998 48.8426L57.4998 8.33423C57.4998 4.19173 54.1419 0.834224 49.9998 0.834224C45.8576 0.834224 42.4998 4.19173 42.4998 8.33423V48.2497L36.7433 42.4932C33.8144 39.5643 29.0657 39.5643 26.1368 42.4932C23.2078 45.4222 23.2078 50.1709 26.1368 53.0999L44.9331 71.8962C44.9528 71.9164 44.9726 71.9364 44.9926 71.9564C47.9215 74.8853 52.6703 74.8853 55.5992 71.9564L74.4553 53.1002Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default DownloadSvg;
