import { FC } from "react";
import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import UrlGeneric from "./Display/generic";
import { UrlListWrapper, UrlDetailedWrapper } from "./Display/styled";

const UrlField: FC<TaskFieldPropsGeneric> = (props) => {
  const { component } = props;

  switch (component) {
    case TaskSourceComponent.Card:
      return null;
    case TaskSourceComponent.Cell:
      return (
        <UrlListWrapper>
          <UrlGeneric {...props} />
        </UrlListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <UrlDetailedWrapper>
          <UrlGeneric {...props} />
        </UrlDetailedWrapper>
      );
    default:
      return null;
  }
};

export default UrlField;
