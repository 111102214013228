import { css } from "@emotion/react/macro";
import styled from "@emotion/styled/macro";

interface TagButtonProps {
  blue?: boolean;
  orange?: boolean;
  plan?: boolean
}

interface PageContainerProps {
  direction?: string;
}

export const PageScrollable = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  overflow-y: auto;
  display: block;
`;

export const PageContainer = styled.div<PageContainerProps>`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : "column")};
  align-items: center;
  height: 100%;
`;

export const PageContent = styled.div<{ width?: number }>`
  width: 685px;
  padding: 40px 0;
`;

export const SectionContent = styled.div`
  margin-bottom: 30px;
`;

export const SectionHeading = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  color: black;
  margin-bottom: 0;
`;

export const SectionSubheading = styled.div`
  color: #999;
  font-size: 12px;
  font-weight: 600;
  margin-block: 10px 5px;
`;

export const TwoColumnGrid = styled.div`
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const TwoColumns = styled.div`
  display: flex;
  margin: 0 -5px;
`;

export const Column = styled.div`
  width: 50%;
  padding: 0 5px;
  flex-shrink: 0;
  flex-grow: 0;
`;

export const PageTitleWrapper = styled.header`
  flex-grow: 1;
  margin-right: 15px;
`;

export const PageTitle = styled.header<{ maxWidth?: number }>`
  max-width: ${({ maxWidth }) => maxWidth ? `${maxWidth}px` : "170px"};
  font-size: 16px;
  font-weight: 500;
  color: black;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
`;

export const basicButton = css`
  //color: #999;
  color: #8f9090;
  font-weight: 500;
  padding: 6px 14px;
  border: solid 1px #e5e5e5;
  border-radius: 5px;
  background-color: transparent;
  transition: background-color 200ms ease, border-color 200ms ease;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  &:hover {
    background-color: #f6f6f6;
  }
  &:active {
    background-color: #f2f2f2;
  }
  &:focus-visible {
    outline: none;
  }
`;

const disabledStyles = css`
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
`;

export interface StyledButtonProps {
  purple?: boolean | string;
  red?: boolean;
  redOutlined?: boolean;
  icon?: boolean;
  iconOutlined?: boolean;
  noBorder?: boolean;
}

export const StyledButton = styled.button <StyledButtonProps>`
  ${basicButton};
  ${({ purple }) => purple && css`
      background-color: #8f64ff;
      color: white;
      border: solid 1px #8f64ff;
      &:hover {
        background-color: #5a27e7;
        border-color: #5a27e7;
      }
    `};
  ${({ red }) => red && css`
      color: white;
      background-color: #ff5263;
      border: solid 1px #ff5263;
      &:hover {
        background-color: #eb4b5b;
      }
    `};
  ${({ redOutlined }) => redOutlined && css`
      color: #ff5263;
      border: 1px solid #f2f2f2;
      transition: border-color 200ms ease;
      &:hover {
        border: 1px solid #ed4758;
        background-color: white;
      }
    `};
  ${({ icon }) => icon && css`
      border: 1px solid transparent;
      padding: 0;
    `};
  ${({ iconOutlined }) => iconOutlined && css`
      border: solid 1px #e5e5e5;
      padding: 0;
    `};
  ${({ noBorder }) => noBorder && css`
      border: none;
    `};
  ${({ disabled }) => disabled && css`
      ${disabledStyles};
      &:hover {
        ${disabledStyles};
        cursor: not-allowed;
      }
    `};
`;

export const TagButton = styled.button<TagButtonProps>`
  ${basicButton};
  margin: 0 4px;
  padding: 3px 9px 2px 9px;
  font-size: 12px;
  border: solid 1px #e4e4e4;
  border-radius: 20px;
  ${({ blue }) => blue && css`
      background-color: #01a5ed;
      border: 1px solid #01a5ed;
      color: white;
      &:hover {
        background-color: #01a5ed;
      }
    `};
  ${({ orange }) => orange && css`
      background-color: #fe6f2c;
      border: 1px solid #fe6f2c;
      color: white;
      &:hover {
        background-color: #fe6f2c;
      }
    `};
  ${({ plan }) => plan && css`
      border-radius: 3px;
      padding: 3px 6px 2px 6px;
      background-color: #f6f6f6;
      border: none;
      color: #999;
      &:hover {
        background-color: #f2f2f2;
      }
    `};
`;

export const StyledModelMainText = styled.p`
  font-size: 24px;
  line-height: 1.4;
  text-align: center;
  color: #a5a4a2;
  margin-top: 30px;
`;

export const StyledEllipsisDiv = styled.div<{ textWidth?: string }>`
  width: ${({ textWidth }) => (textWidth ? textWidth : "103px")};
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5px;
`;

export const DropdownIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 7px;
`;

export const cardShadow = css`
  border: solid 1px #e4e4e4;
  border-radius: 8px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition: border-color 200ms ease, box-shadow 200ms ease;
  &:hover {
    border: solid 1px #cdcdcd;
    box-shadow: 0 4px 6px 0 rgba(21, 7, 38, 0.06);
  }
`;

export const ModalSubheading = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 20px;
  border-bottom: 1px solid #eff0f0;
`;

export const scrollBar = css`
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: hsl(0, 0%, 95%);
  }
  &::-webkit-scrollbar-thumb {
    background: hsl(0, 0%, 85%);
    border-radius: 4px;
    &:hover {
      background: hsl(0, 0%, 75%);
    }
  }
  @supports (scrollbar-color: hsl(0, 0%, 85%) white) {
    scrollbar-color: hsl(0, 0%, 85%) white;
    scrollbar-width: thin;
  }
`;
