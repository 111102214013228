import styled from "@emotion/styled/macro";
import { Input } from "antd";
const { TextArea } = Input;

export const TaskDetailedTitleInput = styled(TextArea)`
  border: 1px solid transparent;
  border-radius: 7px;
  &:hover {
    border: 1px solid #40a9ff;
  }
  &:disabled {
    background-color: white;
  }
`;

export const TaskDetailedTitleWrapper = styled.div`
  margin: -8px -11px 20px -11px;
`;
