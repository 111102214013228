import { FC, Fragment } from "react";
import { Dropdown, Menu, Typography, Tooltip } from "antd";
import moment from "moment";
import regexifyString from "regexify-string";
import { isEmpty } from "lodash";

import { useAppSelector } from "app/store";
import { getUserFullName } from "lib/helpers/userUtils";
import { commentMentionRegExp, commentUserNameRegExp } from "lib/helpers/commentUtils";
import { CommentInterface, MemberRoles, TaskChangeSources } from "lib/types/types";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { DateFormats } from "lib/theme/lib";
import useTaskSetComments from "lib/customHooks/dataLayer/task/useTaskSetComments";
import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import AvatarCustom from "components/ui/AvatarCustom";
import LinkifySecure from "components/blocks/LinkifySecure";
import { AnonymousUser, AutomationUser } from "components/blocks/Automations/lib";
import AttachmentContainer from "components/blocks/AttachmentContainer";
import TaskCommentReactions from "./TaskCommentReactions";
import {
  TaskCommentMainContainer,
  TaskCommentContent,
  TaskCommentEditControl,
  TaskCommentAvatarContainer,
  TaskCommentContentContainer,
  TaskCommentAuthorContainer,
  TaskCommentName,
  TaskCommentDateCreated,
  TaskCommentEditedTag,
  TaskCommentTextContainer,
  MentionLink
} from "./styled";

const { Text } = Typography;

interface TaskCommentProps {
  comment: CommentInterface & { customOverride?: boolean };
  onEdit: (comment: CommentInterface) => void;
  onDelete: () => void;
}

const TaskComment: FC<TaskCommentProps> = (props) => {
  const { comment, onEdit, onDelete } = props;
  const { id, text, source, author, createdAt, edited, attachments, customOverride } = comment;

  const { task } = useTaskContext() as TaskContextInterface;
  const { deleteComment } = useTaskSetComments({ task, updateDetailed: true });
  const user = useAppSelector(state => state.currentUserReducer.user);
  const allowedToEdit = useMemberPermission([MemberRoles.Admin, MemberRoles.Owner]);

  const fallbackUser = !isEmpty(author) ? author : AnonymousUser;
  const displayUser = (source === TaskChangeSources.Automation) ? AutomationUser : fallbackUser;

  const handleDelete = async () => {
    deleteComment(id);
    onDelete();
  };

  const handleEdit = () => {
    const nextState: CommentInterface = {
      ...comment
    };
    onEdit(nextState);
  };

  const renderCommentText = (text: string) => {
    return regexifyString({
      pattern: commentMentionRegExp,
      decorator: (match, index) => {
        const tokens = commentUserNameRegExp.exec(match);
        return (tokens?.length)
          ? (<MentionLink key={index}>{tokens[1]}</MentionLink>)
          : (<Fragment key={index} />);
      },
      input: text
    });
  };

  const checkModifyAvailability = () => {
    let actionEnabled = true;

    const now = moment();
    const commentDate = moment(createdAt);

    const duration = moment.duration(now.diff(commentDate));
    const hours = duration.asHours();

    actionEnabled = hours <= 48;

    if (!actionEnabled) {
      actionEnabled = allowedToEdit;
    }

    return actionEnabled;
  };

  const canModifyComment = checkModifyAvailability();

  const commentActions = (
    <Menu style={{ minWidth: "100px" }}>
      <Menu.Item key="commentActionsEdit" onClick={handleEdit}>
        <Text>Edit</Text>
      </Menu.Item>
      <Menu.Item key="commentActionsDelete" onClick={handleDelete}>
        <Text type="danger">Delete</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <TaskCommentMainContainer>
      <TaskCommentContent>
        <TaskCommentAvatarContainer>
          <AvatarCustom user={displayUser} size="large" />
        </TaskCommentAvatarContainer>
        <TaskCommentContentContainer>
          <TaskCommentAuthorContainer>
            <TaskCommentName>{getUserFullName(displayUser)}</TaskCommentName>
            <Tooltip title={moment(createdAt).format(DateFormats.HumanFull)} placement="top">
              <TaskCommentDateCreated>{moment(createdAt).format(DateFormats.Default)}</TaskCommentDateCreated>
            </Tooltip>
            {edited &&
              <TaskCommentEditedTag>(edited)</TaskCommentEditedTag>
            }
          </TaskCommentAuthorContainer>
          <TaskCommentTextContainer>
            <LinkifySecure>
              {renderCommentText(text)}
            </LinkifySecure>
          </TaskCommentTextContainer>
          {(attachments || [])
            .filter(file => file?.url)
            .map(file => (
              <AttachmentContainer key={file.id} fileId={file.id} fileUrl={file.url} fileName={file.name} fileMime={file.mime} />
            ))}
          {(!customOverride) &&
            <TaskCommentReactions comment={comment} />
          }
        </TaskCommentContentContainer>
      </TaskCommentContent>
      {((user?.id === author?.id) && !customOverride) && canModifyComment &&
        <Dropdown overlay={commentActions} trigger={["click"]}>
          <TaskCommentEditControl>
            <img alt="" src={process.env.PUBLIC_URL + "/icons/down_6px.svg"} />
          </TaskCommentEditControl>
        </Dropdown>
      }
    </TaskCommentMainContainer>
  );
};

export default TaskComment;
