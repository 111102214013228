import React from "react";
import { Skeleton } from "antd";

const PROJECTS_NUMBER = Math.floor(Math.random() * (7 - 3) ) + 3;

const renderSkeletonData = (n: number) => {
  const projects = [];
  for (let i = 0; i < n; i++) {
    const title = Math.floor(Math.random() * (200 - 100) ) + 100;
    projects.push(
      <div style={ { margin: "12px 30px" } } key={i}>
        <Skeleton.Button active size="small" shape="round" style={ { width: `${title}px`, height: "22px" } }/>
      </div>
    );
  }

  return projects;
};

const SkeletonAside = () => (
  <>
    {renderSkeletonData(PROJECTS_NUMBER)}
  </>
);

export default SkeletonAside;
