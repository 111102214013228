import { FC } from "react";
import { DefaultIconColor, DefaultIconSize, IconProps } from "../lib";
import { IconSvgContainer } from "components/icons/IconSvgWrapperNew/styled";

const IconSvgWrapperNew: FC<IconProps> = (props) => {
  const { size = DefaultIconSize, color = DefaultIconColor } = props;

  return (
    <IconSvgContainer size={size} color={color} onClick={props.onClick}>
      {props.children}
    </IconSvgContainer>
  );
};

export default IconSvgWrapperNew;
