import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import { getBoardLastSortColumns } from "lib/helpers/boardUtils";
import { EntityId } from "lib/types/entity";
import { Column } from "lib/types/tasksTypes";
import { captureException } from "lib/utils/sentry";
import { pick } from "lodash";
import BoardService from "services/BoardService";
import useBoardPerformRequest, { BoardPerformRequestHookProps } from "./useBoardPerformRequest";

const useBoardSetColumns = (props: BoardPerformRequestHookProps) => {
  const { board } = props;
  const performRequest = useBoardPerformRequest(props);

  const updateBoardColumn = (columnId: EntityId, payload: Partial<Column>) => {
    if (!board) {
      return;
    }

    const columnIndex = board.columns.findIndex(columns => String(columns.id) === String(columnId));
    if (columnIndex === -1) {
      captureException(`Cannot find column ${columnId}`);
      return;
    }

    const columns = Array.from(board.columns);
    const column = columns[columnIndex];
    columns[columnIndex] = { ...column, ...payload };

    const requestFn = () => BoardService.updateBoardColumn(board.id, columnId, payload);

    return performRequest({ columns }, requestFn);
  };

  const deleteBoardColumn = (columnId: EntityId) => {
    if (!board) {
      return;
    }

    const columnIndex = board.columns.findIndex(columns => String(columns.id) === String(columnId));
    if (columnIndex === -1) {
      captureException(`Cannot find column ${columnId}`);
      return;
    }

    const columns = Array.from(board.columns);
    columns.splice(columnIndex, 1);

    const requestFn = () => BoardService.deleteBoardColumn(board.id, columnId);

    return performRequest({ columns }, requestFn);
  };

  const reorderBoardColumns = (data: Array<Column>) => {
    if (!board) {
      return;
    }

    const payload = data.map(item => pick(item, ["id", "sort"]));
    const requestFn = () => BoardService.reorderBoardColumns(board.id, payload);

    return performRequest({ columns: data }, requestFn);
  };

  const activateBoardColumn = (columnCode: string) => {
    if (!board) {
      return;
    }

    const columnIndex = board.columns.findIndex(columns => String(columns.code) === String(columnCode));
    if (columnIndex === -1) {
      captureException(`Cannot find column ${columnCode}`);
      return;
    }

    const columns = Array.from(board.columns);
    const column = columns[columnIndex];

    if ([TaskPropertyClass.System, TaskPropertyClass.Synced].includes(column.class as TaskPropertyClass)) {
      columns[columnIndex] = {
        ...column,
        active: true,
        hidden: false,
        sort: getBoardLastSortColumns(columns)
      };
    } else {
      captureException(`Cannot activate non-system column: ${column.code}`);
      return;
    }

    const requestFn = () => BoardService.activateBoardColumn(board.id, columnCode);

    return performRequest({ columns }, requestFn);
  };

  const deactivateBoardColumn = (columnCode: string) => {
    if (!board) {
      return;
    }

    const columnIndex = board.columns.findIndex(columns => String(columns.code) === String(columnCode));
    if (columnIndex === -1) {
      captureException(`Cannot find column ${columnCode}`);
      return;
    }

    const columns = Array.from(board.columns);
    const column = columns[columnIndex];

    if ([TaskPropertyClass.System, TaskPropertyClass.Synced].includes(column.class as TaskPropertyClass)) {
      columns[columnIndex] = {
        ...column,
        active: false,
      };
    } else {
      captureException(`Cannot deactivate non-system column: ${column.code}`);
      return;
    }

    const requestFn = () => BoardService.deactivateBoardColumn(board.id, columnCode);

    return performRequest({ columns }, requestFn);
  };

  return {
    updateBoardColumn, deleteBoardColumn, reorderBoardColumns,
    activateBoardColumn, deactivateBoardColumn,
  };
};

export default useBoardSetColumns;
