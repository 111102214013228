import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RegisterInterface, UserPlatformVersion } from "lib/types/types";

const writeToStorage = (token: string) => {
  localStorage.setItem("jwt", JSON.stringify(token));
};

interface AuthStateType extends RegisterInterface {
  token: string;
}

let tokenFromStorage = "";
if (localStorage.getItem("jwt")) {
  tokenFromStorage = JSON.parse(localStorage.getItem("jwt") as string);
}

const initialState: AuthStateType = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  token: tokenFromStorage,
  username: "",
  version: UserPlatformVersion.Amvp,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      writeToStorage(action.payload);
      state.token = action.payload;
    }
  },
});

export const { setEmail, setFirstName, setLastName, setToken } = authSlice.actions;

export default authSlice.reducer;
