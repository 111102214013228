import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useGetWorkspaceIdFromPath = () => {
  const [id, setId] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("project/")) {
      let path = location.pathname;
      const first = path.indexOf("project/") + "project/".length;
      path = path.slice(first);

      const lastIndex = path.lastIndexOf("/");
      if (lastIndex !== -1) {
        setId(Number(path.slice(0, lastIndex)));
      } else {
        setId(Number(path));
      }
    }

    return () => {
      setId(0);
    };
  }, [location.pathname]);

  return id;
};

export default useGetWorkspaceIdFromPath;
