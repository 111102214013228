import HttpService from "./HttpService";
import { StatusInterface } from "lib/types/kanbanTypes";
import { JSendResponse } from "lib/types/jsend";
import { EntityId } from "lib/types/entity";

class StatusesService extends HttpService {
  constructor() {
    super("boards");
  }

  // FIXME: move to BoardService
  async addNewStatus(id: EntityId, body: Record<string, any>) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/statuses`,
      body,
    });

    return data;
  }

  // FIXME: move to BoardService
  async modifyStatus(id: EntityId, statusId: EntityId, body: Record<string, any>) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}/statuses/${statusId}`,
      body
    });

    return data;
  }

  // FIXME: move to BoardService
  async deleteStatus(id: EntityId, statusId: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}/statuses/${statusId}`
    });

    return data;
  }

  // FIXME: move to BoardService
  async reorderStatuses(id: EntityId, body: Partial<StatusInterface>[]) {
    const data: JSendResponse = await this._patch({
      additionalUrl: `/${id}/statuses/order`,
      body
    });

    return data;
  }
}

export default new StatusesService();
