import { FC } from "react";
import { Switch, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import ColorPalette from "components/blocks/ColorPalette";
import {
  LaneMenu,
  LaneMenuItem,
  LaneMenuHeader,
  LaneMenuDivider,
  LaneMenuRow,
  LaneMenuRowHeader,
  LaneMenuRowBody,
} from "./styled";
import { DropdownIcon, DropdownWrapper } from "styles/common";
import { cancelButtonProps, okButtonProps } from "styles/common-vars";
import useBoardSetStatuses from "lib/customHooks/dataLayer/board/useBoardSetStatuses";
import CustomTooltip from "components/ui/CustomTooltip";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

interface LaneHeaderMenuProps {
  laneId: string;
  isFinal: boolean;
  onMenuBtnClick: (visible: boolean, callback: any) => Promise<void>;
  onMenuVisibleChange: (visible: boolean) => void;
  onTitleEdit: () => void;
  onDelete: () => void;
  titleColor: string;
}

const LaneHeaderMenu: FC<LaneHeaderMenuProps> = (props) => {
  const { laneId, onMenuBtnClick, onTitleEdit, isFinal, titleColor, onMenuVisibleChange } = props;
  const { board } = useBoardContext() as BoardContextInterface;
  const { updateBoardStatus, deleteBoardStatus } = useBoardSetStatuses({ board, updateDetailed: true });
  const { isCompletionEnabled, terminology } = useBoardFeatures(board);

  const changeColor = (color: string) => {
    updateBoardStatus(laneId, { color });
  };

  const handleChangeStatusComplete = (isFinal: boolean) => {
    updateBoardStatus(laneId, { isFinal });
  };

  const onConfirm = () => {
    onMenuVisibleChange(false);

    Modal.confirm({
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      title: "Are you sure you want to delete this status?",
      content: "This will permanently delete the status. This action is irreversible. Tasks won't be deleted.",
      okText: "Delete",
      okButtonProps: okButtonProps,
      cancelText: "Cancel",
      cancelButtonProps: cancelButtonProps,
      onOk() {
        onMenuBtnClick(false, deleteBoardStatus(laneId));
      },
      zIndex: 1040,
    });
  };

  return (
    <LaneMenu>
      <LaneMenuItem key="0" onClick={() => onMenuBtnClick(false, onTitleEdit)}>
        <DropdownWrapper>
          <DropdownIcon src="/icons/edit.svg" alt="invite" />
          Rename
        </DropdownWrapper>
      </LaneMenuItem>
      <LaneMenuItem key="1" onClick={onConfirm}>
        <DropdownWrapper>
          <DropdownIcon src="/icons/x.svg" alt="invite" />
          Delete
        </DropdownWrapper>
      </LaneMenuItem>
      <LaneMenuDivider />
      {isCompletionEnabled &&
        <>
          <LaneMenuRow>
            <LaneMenuRowHeader>
              <LaneMenuHeader>Complete status</LaneMenuHeader>
              <CustomTooltip placement="top" content={`${terminology.plural} with complete status will be considered completed.`} />
            </LaneMenuRowHeader>
            <Switch size="small" defaultChecked={isFinal} onChange={handleChangeStatusComplete} />
          </LaneMenuRow>
          <LaneMenuDivider />
        </>
      }
      <LaneMenuRow>
        <LaneMenuHeader>Colors</LaneMenuHeader>
        <LaneMenuRowBody>
          <ColorPalette changeColor={changeColor} titleColor={titleColor} />
        </LaneMenuRowBody>
      </LaneMenuRow>
    </LaneMenu>
  );
};

export default LaneHeaderMenu;
