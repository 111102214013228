import { FC, useEffect, useState } from "react";
import { Input, TimePicker } from "antd";
import moment from "moment";

import { TaskPropertyDateTimeFormat, TaskPropertyTimeFormat } from "lib/types/kanbanTypes";
import { DateMasksMap, TimeMasksMap } from "lib/helpers/dateUtils";
import { DateFormats, TimeFormats } from "lib/theme/lib";
import { TimePickerStylePanel } from "./styled";
import { TimePickerCustomContainer } from "../styled";

interface TimePickerCustomProps {
  value: moment.Moment | null | undefined;
  saveTime: (momentObject: moment.Moment | null) => void;
  dateFormat: TaskPropertyDateTimeFormat;
  timeFormat: TaskPropertyTimeFormat;
}

const TimePickerCustom: FC<TimePickerCustomProps> = (props) => {
  const { value, saveTime, dateFormat, timeFormat } = props;

  const [timePickerValue, setTimePickerValue] = useState(value);

  useEffect(() => {
    setTimePickerValue(value);
  }, [value]);

  const dateMask = (dateFormat) ? DateMasksMap[dateFormat] : DateFormats.Friendly;
  const timeMask = (timeFormat) ? TimeMasksMap[timeFormat] : TimeFormats.US;

  const updateTimePickerValue = (newValue: moment.Moment | null) => {
    setTimePickerValue(newValue);
    saveTime(newValue);
  };

  return (
    <TimePickerCustomContainer>
      <Input
        value={value?.format(dateMask)}
        bordered={false}
        style={{ width: "50%", cursor: "default" }}
      />
      <TimePicker
        allowClear={false}
        bordered={false}
        format={timeMask}
        hourStep={1}
        minuteStep={1}
        showNow
        use12Hours={timeFormat === TaskPropertyTimeFormat.Usa}
        defaultValue={moment({ h: 0, m: 0, s: 0 })}
        value={timePickerValue}
        onKeyDown={e => e.stopPropagation()}
        onChange={updateTimePickerValue}
        onSelect={updateTimePickerValue}
        panelRender={(panelNode => <TimePickerStylePanel>{panelNode}</TimePickerStylePanel>)}
      />
    </TimePickerCustomContainer>);
};

export default TimePickerCustom;
