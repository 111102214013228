import { FC } from "react";

import TextField from "components/blocks/FormComponents/TextField";
import SelectField from "components/blocks/FormComponents/SelectField";
import { TwoColumns, Column } from "styles/common";
import { Precisions } from "../Number/config";

const CurrencySettings: FC = () => (
  <TwoColumns>
    <Column>
      <TextField
        label="Currency"
        name="currency"
        placeholder="$"
      />
    </Column>
    <Column>
      <SelectField
        label="Precision"
        name="precision"
        placeholder="Select precision"
        data={Precisions}
      />
    </Column>
  </TwoColumns>
);

export default CurrencySettings;
