import { useCallback } from "react";
import { BoardInterfaceExtended } from "lib/types/boardTypes";
import { useAppDispatch, useAppSelector } from "app/store";
import { setBoard } from "app/slices/currentBoardSlice";
import useWSSubscription from "../useWSSubscription";
import { WsEvents } from "lib/types/wsTypes";
import LoadingMessage from "components/blocks/LoadingMessage";

const useWSDeleteBoard = () => {
  const dispatch = useAppDispatch();
  const currentBoardId = useAppSelector(state => state.currentBoardReducer.board?.id);

  const callback = useCallback((data: BoardInterfaceExtended) => {
    if (data.id === currentBoardId) {
      dispatch(setBoard(null));
      LoadingMessage.complete(`The board ${data.name} has been deleted`);
    }
  }, [currentBoardId, dispatch]);

  useWSSubscription({
    eventName: WsEvents.Board.Delete,
    callback,
    fnKey: "useWSDeleteBoard"
  });
};

export default useWSDeleteBoard;
