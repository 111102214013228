import { FC } from "react";
import IconSvgWrapper from "components/icons/IconSvgWrapper";
import { IconProps } from "components/icons/lib";

const AddSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M11.343 5.06254H11.3437H6.93746V0.664585C6.93746 0.302634 6.64439 0 6.28244 0H5.71863C5.35678 0 5.06244 0.302634 5.06244 0.664585V5.06254H0.656293C0.294537 5.06254 0 5.35522 0 5.71727V6.28429C0 6.64605 0.294439 6.93746 0.656293 6.93746H5.06254V11.3513C5.06254 11.7131 5.35678 11.9999 5.71873 11.9999H6.28254C6.64449 11.9999 6.93756 11.713 6.93756 11.3513V6.93746H11.343C11.705 6.93746 12 6.64595 12 6.28429V5.71727C12 5.35522 11.705 5.06254 11.343 5.06254Z" />
    </IconSvgWrapper>
  );
};

export default AddSvg;
