/**
 * Reference: Mantine theme configuration
 * https://mantine.dev/theming/theme-object/
 */

import { CSSProperties } from "react";

export interface ApplicationThemeInterface {
  // Defines color scheme for all components, defaults to "light"
  colorScheme: "light" | "dark";

  // Determines whether elements that do not have pointer cursor by default
  // (checkboxes, radio, native select) should have it
  cursorType: "default" | "pointer";

  // Default border-radius used for most elements
  // defaultRadius: "xs" | "sm" | "md" | "lg" | "xl" | number;

  // // White and black colors, defaults to '#fff' and '#000'
  // white: string;
  // black: string;

  // // Object of arrays with 10 colors
  colors?: Record<string, Array<number>>;

  // // Key of theme.colors
  primaryColor: string;

  // // Index of color from theme.colors that is considered primary, Shade type is 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
  // primaryShade: Shade | { light: Shade; dark: Shade };

  // // font-family used in most components
  fontFamily: string;

  // // Monospace font-family, used in Code, Kbd and Prism components
  fontFamilyMonospace: string;

  // // Sizes for corresponding properties
  fontSizes: Record<"xs" | "sm" | "md" | "lg" | "xl", number>;
  radius: Record<"xs" | "sm" | "md" | "lg" | "xl", number>;
  spacing: Record<"xs" | "sm" | "md" | "lg" | "xl", number>;

  // // Values used for box-shadow
  // shadows: Record<"xs" | "sm" | "md" | "lg" | "xl", string>;

  // // Breakpoints used in some components to add responsive styles
  // breakpoints: Record<"xs" | "sm" | "md" | "lg" | "xl", number>;

  // // Styles added to buttons with `:active` pseudo-class
  // activeStyles: CSSObject;

  // // Default date format used in DatePicker and DateRangePicker components
  dateFormat: string;

  // // Default dates formatting locale used in every @mantine/dates component
  datesLocale: string;

  // // defaultProps, styles and classNames for components
  // components: ComponentsOverride;

  // // Add your own custom properties on Application theme
  other?: Record<string, any>;
}

interface Heading {
  fontSize: CSSProperties["fontSize"];
  fontWeight: CSSProperties["fontWeight"];
  lineHeight: CSSProperties["lineHeight"];
}

export const DateFormats = {
  Default: "MMM DD, YYYY",
  Friendly: "MMM DD, YYYY",
  US: "MM/DD/YYYY",
  Europe: "DD/MM/YYYY",
  ISO: "YYYY-MM-DD",
  HumanFull: "MMM DD, YYYY h:mma",
  HumanShort: "ll"
};

export const TimeFormats = {
  None: "",
  US: "h:mma",
  Europe: "HH:mm",
};

export const InputPlaceholders = {
  Empty: "Empty"
};

export const DefaultAppTheme: ApplicationThemeInterface = {
  colorScheme: "light",
  cursorType: "default",
  primaryColor: "",
  fontFamily: "Open Sans, sans-serif",
  fontFamilyMonospace: "Monaco, Courier, monospace",
  fontSizes: {
    xs: 10,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 20,
  },
  radius: {
    xs: 10,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 20,
  },
  spacing: {
    xs: 15,
    sm: 20,
    md: 25,
    lg: 30,
    xl: 40
  },
  datesLocale: DateFormats.HumanFull,
  dateFormat: DateFormats.Default,
};
