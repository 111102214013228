import { FC } from "react";
import { Switch } from "antd";

import ButtonIcon from "components/ui/ButtonIcon";
import TaskFieldIcon from "components/blocks/TaskFields/FieldIcons";
import { PropertyValueType } from "lib/types/dataTypes";
import { BoardViewAppliedColumn } from "lib/types/boardTypes";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import FormFieldItem from "pages/FormPublic/FormPublicField/FormFieldItem";
import { BoardFormFieldAdded } from "pages/Board/BoardForm/styled";

import {
  DisplayedFieldClearWrapper,
  DisplayedFieldInputContainer,
  DisplayedFieldRequiredContainer,
  DisplayedFieldRequiredText, DisplayedFieldHeaderContainer, DisplayedFieldTitleContainer
} from "pages/Board/BoardForm/BoardFormDisplayedField/styled";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

interface BoardFormDisplayedFieldProps {
  onChange: (params: Partial<BoardViewAppliedColumn>) => void;
  onDelete: () => void;
  column: BoardViewAppliedColumn;
}

const BoardFormDisplayedField: FC<BoardFormDisplayedFieldProps> = (props) => {
  const { column, onDelete, onChange } = props;
  const { board } = useBoardContext() as BoardContextInterface;
  const { insertColumns } = useBoardFeatures();
  const boardColumn = (board.columns ?? [])
    .concat(insertColumns)
    .find(col => String(col.id) === String(column.id));
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);

  return (
    <BoardFormFieldAdded allowedToEdit={allowedToEdit}>
      <DisplayedFieldHeaderContainer>
        <DisplayedFieldTitleContainer>
          <TaskFieldIcon propertyValueType={boardColumn?.type as PropertyValueType} />
          <div style={{ marginLeft: 5 }}>{column.title}</div>
        </DisplayedFieldTitleContainer>
        {allowedToEdit &&
          <DisplayedFieldClearWrapper>
            <ButtonIcon onClick={onDelete} iconUrl="/icons/x.svg" iconOutlined />
          </DisplayedFieldClearWrapper>
        }
      </DisplayedFieldHeaderContainer>
      <DisplayedFieldInputContainer>
        {boardColumn &&
          <FormFieldItem
            column={boardColumn}
            members={board.collaborators}
            fieldName={boardColumn.code}
            disabled
          />
        }
      </DisplayedFieldInputContainer>
      <DisplayedFieldRequiredContainer>
        <Switch
          checked={column.required}
          size="small"
          onChange={(checked) => onChange({ id: column.id, required: checked })}
          disabled={!allowedToEdit}
        />
        <DisplayedFieldRequiredText>
          Required
          {(boardColumn?.type === PropertyValueType.Boolean) && " to be checked"}
        </DisplayedFieldRequiredText>
      </DisplayedFieldRequiredContainer>
    </BoardFormFieldAdded>
  );
};

export default BoardFormDisplayedField;
