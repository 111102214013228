import { Input, Select } from "antd";
import { FC, useContext } from "react";
import ButtonIcon from "components/ui/ButtonIcon";
import { RemovePropertyContext } from "../../automationContext";
import {
  AutomationsBuilderCard,
  AutomationsBuilderContent,
  AutomationsBuilderStatus,
  AutomationsClearWrapper
} from "../../styled";
import {
  AutomationsCardHeader,
  AutomationsCardMain,
  AutomationsCardWrapper,
  AutomationsFormItem,
  AutomationsSubheading,
} from "./styled";

const { Option } = Select;

export type TCard = {
  type: "triggerValue" | "updateValue";
  cardType?: "textarea" | "multiselect" | "custom";
  options?: Array<TCardOption>;
  header: string;
  defaultValue?: string; // if no select or other input
  subHeading?: string;
  helpText?: string;
}

export interface TCardOption {
  value: string | number;
  text: string;
  renderFn?: () => void;
}

const Card: FC<TCard> = ({
  type,
  cardType = "select",
  options,
  defaultValue,
  header,
  subHeading,
  helpText,
  children
}) => {

  const { removeTrigger, removeAction, onClickTriggerHeader, onClickActionHeader } = useContext(RemovePropertyContext);

  const onClickRemove = () => {
    type === "triggerValue"
      ? removeTrigger()
      : removeAction();
  };

  const onClickHeader = () => {
    type === "triggerValue"
      ? onClickTriggerHeader()
      : onClickActionHeader();
  };

  const getCardType = () => {

    if (defaultValue) {
      return (
        <AutomationsFormItem name={type} initialValue={defaultValue} noStyle />
      );
    }

    if (options) {
      return (
        <AutomationsBuilderContent>
          <AutomationsSubheading>{helpText}</AutomationsSubheading>
          <AutomationsFormItem name={type}>
            <Select
              style={{ width: "100%" }}
              dropdownStyle={{ minWidth: 120 }}
              filterOption={
                cardType === "multiselect"
                  ? (input, option) =>
                    String(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  : undefined
              }
              mode={cardType === "multiselect" ? "multiple" : undefined}>
              {options.map(option => {
                return (option.renderFn)
                  ? option.renderFn()
                  : <Option key={option.value} value={option.value}>{option.text}</Option>;
              })}
            </Select>
          </AutomationsFormItem>
        </AutomationsBuilderContent>
      );
    }

    if (cardType === "textarea") {
      return (
        <AutomationsBuilderContent>
          <AutomationsSubheading>{helpText}</AutomationsSubheading>
          <AutomationsFormItem name={type}>
            <Input.TextArea />
          </AutomationsFormItem>
        </AutomationsBuilderContent>
      );
    }

    if (cardType === "custom") {
      return (
        children
      );
    }

    return <></>;
  };

  return (
    <AutomationsBuilderCard onClick={onClickHeader}>
      <AutomationsCardWrapper>
        <AutomationsCardMain>
          <AutomationsCardHeader>{header}</AutomationsCardHeader>
          <AutomationsClearWrapper>
            <ButtonIcon onClick={onClickRemove} iconUrl="/icons/x.svg" iconOutlined />
          </AutomationsClearWrapper>
        </AutomationsCardMain>
        <>
          {subHeading && <AutomationsBuilderStatus>{subHeading}</AutomationsBuilderStatus>}
          {getCardType()}
        </>
      </AutomationsCardWrapper>
    </AutomationsBuilderCard>
  );
};

export default Card;
