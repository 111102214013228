import * as Sentry from "@sentry/react";
import { getUserFullName } from "lib/helpers/userUtils";
import LoadingMessage from "components/blocks/LoadingMessage";
import configuration from "lib/configs/configuration";
import { UserInterface } from "lib/types/types";
import { Primitive } from "@sentry/types";

export function initSentry() {
  Sentry.init({
    dsn: configuration.sentry.dsn,
    debug: configuration.app.isDevelopment,
    release: configuration.app.version,
    environment: configuration.app.debugEnv,
    // TODO: set up  a proxy
    // tunnel: configuration.sentry.proxy
    integrations: [
      Sentry.captureConsoleIntegration(),
      Sentry.extraErrorDataIntegration(),
    ],
  });
}

export const captureException = (e: any, showUiMessage?: boolean, uiMessageText?: string) => {
  console.warn(e);
  Sentry.captureException(e);

  if (showUiMessage) {
    LoadingMessage.error(uiMessageText);
  }
};

export const captureClassMethodException = (error: any, className: string, methodName: string) => {
  const scope = new Sentry.Scope();
  scope.setTag("class", className);
  scope.setTag("method", methodName);
  Sentry.captureException(error, scope);
};

export function setSentryScopeTag(key: string, value: Primitive) {
  Sentry.getCurrentScope().setTag(key, value);
}

export function setSentryUser(user: UserInterface | null) {
  if (user) {
    Sentry.setUser({
      id: String(user.id),
      username: getUserFullName(user),
      email: user.email,
      locale: user.locale,
      version: user.version,
    });
  }
}

export const SentryErrorBoundary = Sentry.ErrorBoundary;
