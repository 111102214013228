import styled from "@emotion/styled/macro";

export const DisplayedFieldTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DisplayedFieldHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DisplayedFieldInputContainer = styled.div`
  width: 100%;
  margin-block: 8px 12px;
`;

export const DisplayedFieldRequiredContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DisplayedFieldRequiredText = styled.div`
  margin-left: 7px;
  font-size: 13px;
  color: #999;
`;

export const DisplayedFieldClearWrapper = styled.div`
  transition: opacity 300ms ease;
  opacity: 0;
  margin-right: -6px;
`;
