import { ForgeViewerMode } from "lib/types/ForgeViewer";
import React, { FC, useContext, useState } from "react";

export interface ViewerContextProps {
  viewer: Autodesk.Viewing.Viewer3D | null;
  mode: ForgeViewerMode;
  setMode: (mode: ForgeViewerMode) => void;
  isModelsReady: boolean;
}

export interface ViewerProviderProps {
  viewer: Autodesk.Viewing.Viewer3D | null;
  isModelsReady: boolean;
}

const ViewerContext = React.createContext<ViewerContextProps>({
  viewer: null,
  mode: ForgeViewerMode.Navigation,
  setMode: () => false,
  isModelsReady: false,
});

export const useViewerContext = () => useContext(ViewerContext);

export const ViewerProvider: FC<ViewerProviderProps> = (props) => {
  const [mode, setMode] = useState(ForgeViewerMode.Navigation);

  return (
    <ViewerContext.Provider value={{
      viewer: props.viewer,
      mode: mode,
      setMode: setMode,
      isModelsReady: props.isModelsReady,
    }}>
      {props.children}
    </ViewerContext.Provider >
  );
};
