import { generateUid } from "lib/helpers/MultiRelationalEntity";
import { asyncGetScreenShot, restoreViewerNodePromise, restoreViewerState, saveViewerState } from "lib/helpers/viewerUtils";
import { EntityId } from "lib/types/entity";
import { ForgeViewerState } from "lib/types/ForgeViewer";
import { JSendStatus } from "lib/types/jsend";
import { TaskViewpointsPayload } from "lib/types/tasksTypes";
import { ViewpointPayload } from "lib/types/types";
import TasksService from "services/TasksService";
import UploadService from "services/UploadService";

export const importViewpointsIntoTaskNoImages = async (
  taskId: EntityId,
  viewpoints: ForgeViewerState[],
  signal?: AbortSignal
) => {
  const payload = viewpoints.map(viewpoint => ({
    uid: generateUid(),
    viewpoint: viewpoint,
    picture: null,
  }));

  await TasksService.addViewpointsBatch(taskId, payload);
};

export const importViewpointsIntoTaskWithImages = async (
  taskId: EntityId,
  viewer: Autodesk.Viewing.Viewer3D,
  viewpoints: ForgeViewerState[],
  signal?: AbortSignal
) => {
  for (const viewpointData of viewpoints) {
    if (signal?.aborted) {
      break;
    }
    await restoreViewerState(viewer, viewpointData, {}, true);

    const blob = await asyncGetScreenShot(viewer);
    if (blob) {
      const [picture] = await UploadService.upload(blob);
      const payload: ViewpointPayload = {
        uid: generateUid(),
        viewpoint: viewpointData,
        picture: picture.id,
      };
      await TasksService.createViewpoint(taskId, payload, signal);
    }
  }
};

export const importViewpointsFromBubbleNodes = async (
  taskId: EntityId,
  viewer: Autodesk.Viewing.Viewer3D,
  nodes: Array<Autodesk.Viewing.BubbleNode>,
  signal?: AbortSignal
) => {
  for (const node of nodes) {
    if (signal?.aborted) {
      break;
    }

    await restoreViewerNodePromise(viewer, node);

    const rawViewpoint = saveViewerState(viewer);
    const blob = await asyncGetScreenShot(viewer);
    if (blob) {
      const [picture] = await UploadService.upload(blob);
      const payload: ViewpointPayload = {
        uid: generateUid(),
        viewpoint: rawViewpoint,
        picture: picture.id,
      };
      await TasksService.createViewpoint(taskId, payload, signal);
    }
  }
};

export const importViewpointsIntoBoardNoImages = async (
  boardId: EntityId,
  data: TaskViewpointsPayload[],
  signal?: AbortSignal
) => {
  for (const taskData of data) {
    if (signal?.aborted) {
      break;
    }
    const payload = {
      ...taskData,
      board: boardId
    };

    await TasksService.addNewTask(payload);
  }
};

export const importViewpointsIntoBoardWithImages = async (
  boardId: EntityId,
  viewer: Autodesk.Viewing.Viewer3D,
  data: TaskViewpointsPayload[],
  signal?: AbortSignal
) => {
  for (const taskData of data) {
    if (signal?.aborted) {
      break;
    }
    const payload = {
      title: taskData.title,
      board: boardId
    };

    const response = await TasksService.addNewTask(payload);

    if (response.status === JSendStatus.Success) {
      const { id } = response.data;

      for (const viewpointData of taskData.viewpoints) {
        await restoreViewerState(viewer, viewpointData.viewpoint, {}, true);

        const blob = await asyncGetScreenShot(viewer);
        if (blob) {
          const [picture] = await UploadService.upload(blob);
          const payload: ViewpointPayload = {
            uid: generateUid(),
            viewpoint: viewpointData.viewpoint,
            picture: picture.id,
          };
          await TasksService.createViewpoint(id, payload, signal);
        }
      }
    }
  }
};
