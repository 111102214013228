import { FC, useState } from "react";
import { Dropdown } from "antd";
import { useHistory } from "react-router-dom";

import ButtonIcon from "components/ui/ButtonIcon";
import { DisplayView } from "lib/types/applicationTypes";
import { TitleControls } from "pages/Board/BoardKanban/Card/CardDetails/styled";
import TaskCheckmark from "pages/Task/TaskCheckmark";
import { useTasksContext } from "lib/contexts/TasksContext";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import useBoardView from "lib/customHooks/views/useBoardView";
import { getTaskDetailBoardView } from "lib/helpers/navigationUtils";
import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import useWorkspaceById from "lib/customHooks/useWorkspaceById";

import TaskDrawerMenu from "../TaskDrawerMenu";
import { TaskDrawerTitleWrapper, TaskDrawerArrow, TaskDrawerArrowsWrapper } from "../styled";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import AntCloseSvg from "components/icons/resizable/ant-close";

interface TaskDrawerTitleProps {
  displayView?: DisplayView;
  onClose: () => void;
}

const TaskDrawerTitle: FC<TaskDrawerTitleProps> = (props) => {
  const { task, setValue } = useTaskContext() as TaskContextInterface;
  const workspace = useWorkspaceById(task.board.workspace);
  const { isCompletionEnabled } = useBoardFeatures(task.board);

  const history = useHistory();
  const view = useBoardView();

  const { tasks, isViewerBoardView, source } = useTasksContext();
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);

  const handleOnVisibleChange = (visible: boolean) => setIsMenuVisible(visible);

  const currentTaskIndex = tasks.findIndex(t => String(t?.id) === String(task.id));
  const prevTask = tasks[currentTaskIndex - 1];
  const nextTask = tasks[currentTaskIndex + 1];

  const handleArrowOnClick = (direction: "prev" | "next") => {
    const targetTask = (direction === "next") ? nextTask?.uid : prevTask?.uid;
    if (!targetTask) {
      return;
    }

    let path = "";
    switch (source) {
      case DisplayView.Model:
        if (isViewerBoardView) {
          path = `/viewer/${workspace?.uid}/board/${task.board.uid}/${targetTask}`;
        } else {
          path = `/viewer/${workspace?.uid}/r/${targetTask}`;
        }
        break;
      case DisplayView.MyTasks:
        path = `/work/${targetTask}`;
        break;
      case DisplayView.Project:
        path = `/project/${workspace?.uid}/r/${targetTask}`;
        break;
      case DisplayView.ProjectPreview:
        path = `/project/${workspace?.uid}/${targetTask}`;
        break;
      case DisplayView.Calendar:
      case DisplayView.Kanban:
      case DisplayView.List: {
        const viewUid = view?.uid || source;
        path = getTaskDetailBoardView(task.board.uid, viewUid, targetTask);
        break;
      }
      default:
        break;
    }

    if (path.length) {
      history.replace(path);
    }
  };

  return (
    <TaskDrawerTitleWrapper>
      <TaskDrawerArrowsWrapper>
        {isCompletionEnabled &&
          <div>
            <TaskCheckmark
              id={String(task.id)}
              setValue={setValue}
              isCompleted={task.completed}
              clickable={allowedToEdit}
              button
            />
          </div>
        }
        <div style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
          <TaskDrawerArrow disabled={!prevTask} src="/icons/prev-task.svg" onClick={() => handleArrowOnClick("prev")} alt="prev" />
          <TaskDrawerArrow disabled={!nextTask} src="/icons/next-task.svg" onClick={() => handleArrowOnClick("next")} alt="next" />
        </div>
      </TaskDrawerArrowsWrapper>
      <TitleControls>
        <div>
          <Dropdown
            overlay={
              <TaskDrawerMenu
                handleVisibility={handleOnVisibleChange}
                displayView={props.displayView}
              />
            }
            trigger={["click"]}
            placement="bottomRight"
            open={isMenuVisible}
            onOpenChange={handleOnVisibleChange}
          >
            <ButtonIcon iconUrl="/icons/more.svg" />
          </Dropdown>
        </div>
        <div>
          <ButtonIcon
            icon={<AntCloseSvg size={12} />}
            onClick={props.onClose}
          />
        </div>
      </TitleControls>
    </TaskDrawerTitleWrapper>
  );
};

export default TaskDrawerTitle;
