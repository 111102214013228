import { getTokenFromStorage } from "../../helpers/helpers";
import socket from "lib/API/socket";

type xhrType = (
  method: string,
  url: string,
  data: FormData,
  onProgress: ((this: XMLHttpRequest, ev: ProgressEvent<EventTarget>) => any) | null,
  onLoaded?: () => void
) => Promise<unknown>

export const xhr: xhrType = (
  method,
  url,
  data,
  onProgress,
  onLoaded) =>
  new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();

    xhr.open(method, url);
    xhr.setRequestHeader("Authorization", `Bearer ${getTokenFromStorage()}`);
    xhr.setRequestHeader("X-Socket-Id", socket.getSocketId());
    //xhr.withCredentials = true;
    xhr.upload.onprogress = onProgress;
    xhr.upload.onloadend = onProgress;
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        if (onLoaded) {
          onLoaded();
        }
        return resolve(xhr.response);
      }

      if (this.status === 413) {
        return reject({
          status: this.status,
          statusText: xhr.statusText,
          errorMessage: "Toooo big",
        });
      }

      const response = JSON.parse(xhr.response);
      const { error_message } = response;

      return reject({
        status: this.status,
        statusText: xhr.statusText,
        errorMessage: error_message,
      });
    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
    xhr.send(data);
  });

// Пример использования:
//
// const onProgress = (event) => {
//   const percentComplete = (event.loaded / event.total) * 100;
//   dispatch(setUploadProgress(percentComplete));
// };
//
// xhr('POST', url, formData, onProgress);
