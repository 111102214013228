import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const RefreshSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
        <path d="M98.3902 60.2066C97.3536 59.1643 95.9821 58.5936 94.5219 58.5936H67.178C64.1642 58.5936 61.7187 61.04 61.7187 64.0546V68.6879C61.7187 70.1448 62.2845 71.5465 63.3211 72.5822C64.3561 73.6164 65.7236 74.2188 67.178 74.2188H74.4106C67.9992 80.4692 59.1813 84.3977 49.9415 84.3977C35.3415 84.3977 22.2935 75.1302 17.4732 61.3603C16.3772 58.2294 13.4138 56.1196 10.0992 56.1196C9.22194 56.1196 8.35283 56.2635 7.51787 56.5554C5.54795 57.2456 3.96503 58.6595 3.06015 60.5383C2.15446 62.418 2.03576 64.5391 2.72519 66.5074C9.73657 86.5359 28.6788 99.9912 49.9089 99.9912C62.9439 99.9912 75 95.1237 84.3748 86.24V91.366C84.3748 94.3814 86.8919 96.8757 89.9081 96.8757H94.5406C97.5569 96.8757 100 94.3814 100 91.366V64.0733C100 62.6131 99.4309 61.2408 98.3902 60.2066Z"/>
        <path d="M97.2821 33.492C90.2707 13.4643 71.3244 0.0090332 50.0943 0.0090332C37.0585 0.0090332 25 4.87733 15.6252 13.7611V8.63505C15.6252 5.62042 13.1163 3.12448 10.1 3.12448H5.46667C2.45122 3.12448 0 5.62042 0 8.63505V35.9269C0 37.3863 0.573984 38.7456 1.61463 39.7814C2.65041 40.8237 4.0252 41.3936 5.48537 41.3936L5.46911 41.4058H32.8276C35.8439 41.4058 38.2813 38.9611 38.2813 35.9464V31.3123C38.2813 28.2968 35.8439 25.7806 32.8276 25.7806H25.5967C32.0081 19.5302 40.8268 15.6025 50.0667 15.6025C64.6667 15.6025 77.7138 24.87 82.5341 38.6407C83.6301 41.7716 86.5927 43.8806 89.9081 43.8806C90.7862 43.8806 91.6537 43.7375 92.4894 43.4448C94.4602 42.7546 96.0423 41.3416 96.9472 39.4619C97.852 37.5814 97.9715 35.4619 97.2821 33.492Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default RefreshSvg;
