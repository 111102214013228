import Search from "components/blocks/Search";
import NotificationsDrawer from "pages/Notifications/NotificationsDrawer";
import { FC } from "react";
import HeaderAvatar from "./HeaderAvatar";
import { HeaderCustomRoot, HeaderCustomWrapper, HeaderCustomSearchContainer } from "./styled";

const HeaderCustom: FC = () => {
  return (
    <HeaderCustomWrapper>
      <HeaderCustomRoot id="portal-root" />
      <HeaderCustomSearchContainer>
        <Search />
      </HeaderCustomSearchContainer>
      <NotificationsDrawer />
      <HeaderAvatar />
    </HeaderCustomWrapper>
  );
};

export default HeaderCustom;
