import { FC } from "react";
import { Tooltip } from "antd";
import { BoardDataSourceInterface } from "lib/types/boardTypes";
import ButtonIcon from "components/ui/ButtonIcon";
import BoardService from "services/BoardService";
import { JSendError, JSendStatus } from "lib/types/jsend";
import { useAppDispatch } from "app/store";
import { EntityId } from "lib/types/entity";
import LoadingMessage from "components/blocks/LoadingMessage";
import { captureException } from "lib/utils/sentry";
import { setTasksLoading } from "app/slices/tasksSlice";
import useSavingState from "lib/customHooks/useSavingState";

interface SyncBoardButtonProps {
  boardId: EntityId;
  externalDataSource: BoardDataSourceInterface;
}

const SyncBoardButton: FC<SyncBoardButtonProps> = (props) => {
  const { externalDataSource, boardId } = props;
  const dispatch = useAppDispatch();
  const { setSavingStart, setSavingFinish } = useSavingState();

  const handleSyncOnClick = async () => {
    setSavingStart();
    dispatch(setTasksLoading(true));
    const data = await BoardService.externalSyncProcess(boardId);
    if (data.status === JSendStatus.Success) {
      LoadingMessage.complete("Sync complete");
    } else {
      captureException(data, true, String((data as JSendError).message));
    }
    dispatch(setTasksLoading(false));
    setSavingFinish();
  };

  const displayDate = externalDataSource.lastSuccessTime ? new Date(externalDataSource.lastSuccessTime).toLocaleString() : "";

  return (
    <>
      <Tooltip title={`Last synced: ${displayDate}`} placement="right">
        <div>
          <ButtonIcon iconUrl="/icons/sync.svg" text="Sync data" onClick={handleSyncOnClick} />
        </div>
      </Tooltip>
    </>
  );
};

export default SyncBoardButton;
