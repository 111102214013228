import { FC, useEffect, useState } from "react";
import { Image } from "antd";
import { TaskFieldPropsGeneric } from "lib/types/applicationTypes";
import { FieldEmptyState } from "../../styled";
import { AnnotationInterface } from "lib/types/fileContainer";
import AnnotationSvg from "components/icons/resizable/annotation";
import {
  ViewpointLikeCardContentContainer,
  ViewpointLikeCardOuterContainer
} from "components/blocks/ViewpointLikeCard/styled";
import { ViewpointLikeCardImage } from "components/blocks/ViewpointLikeCard/styled";
import AnnotationCardMenu from "components/blocks/AnnotationCard/AnnotationCardMenu";
import { AnnotationContextMenuItems } from "components/blocks/AnnotationCard/AnnotationCardMenu/AnnotationCardMenuItems/lib";

interface AnnotationsGenericProps extends TaskFieldPropsGeneric {
  cardSize: number;
}

const AnnotationsGeneric: FC<AnnotationsGenericProps> = (props) => {
  const { value, cardSize } = props;

  const [displayValue, setDisplayValue] = useState(value);
  useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  return (
    <>
      {!displayValue?.length &&
        <FieldEmptyState>Empty</FieldEmptyState>
      }
      <Image.PreviewGroup>
        {displayValue?.map((annotation: AnnotationInterface, index: number) => (
          <ViewpointLikeCardOuterContainer
            cardSize={cardSize}
            key={index}
            nonClickable={!(annotation.picture?.url)}
            geometry
          >
            {annotation.picture?.url && (
              <ViewpointLikeCardImage
                cardSize={cardSize}
                preview={{ src: annotation.picture?.url }}
                src={annotation.picture?.formats?.thumbnail?.url || annotation.picture?.url}
              />
            )}
            {!annotation.picture?.url && (
              <ViewpointLikeCardContentContainer>
                <AnnotationSvg size={13} />
              </ViewpointLikeCardContentContainer>
            )}
            <AnnotationCardMenu
              annotation={annotation}
              menuItems={[
                AnnotationContextMenuItems.OpenInViewer,
                AnnotationContextMenuItems.CopyLink,
                AnnotationContextMenuItems.Delete,
              ]}
            />
          </ViewpointLikeCardOuterContainer>
        ))}
      </Image.PreviewGroup>
    </>
  );
};

export default AnnotationsGeneric;
