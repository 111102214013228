import { MouseEventHandler } from "react";

export interface IconProps {
  size?: number;
  color?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const DefaultIconSize = 12;
export const DefaultIconColor = "#b0b0b0";
