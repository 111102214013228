import { Column } from "lib/types/tasksTypes";
import React, { FC, memo, useEffect, useState } from "react";
import { ColumnInstance } from "react-table";
import { SvgAddWrapper } from "../TaskTable/Table/Header/styled";

import ModalComponent from "./Modal";
import { Wrapper } from "./styled";

interface ModalProps {
  columnData?: ColumnInstance<Column>;
  isVisible?: boolean;
  setVisibility?: (value: boolean) => void;
  noChildren?: boolean;
}

const TaskFieldsModal: FC<ModalProps> = (props) => {
  const { columnData, children, noChildren, isVisible, setVisibility } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setIsModalVisible(isVisible || false);
  }, [isVisible]);

  const handleModalVisibility = (value: boolean) => {
    if (setVisibility) {
      setVisibility(value);
    }

    setIsModalVisible(value);
  };
  const showModal = () => handleModalVisibility(true);
  const handleCancelModal = () => handleModalVisibility(false);

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onClick: showModal });
    }

    return child;
  });

  const renderChildren = () => {
    if (noChildren) {
      return null;
    }

    if (children) {
      return childrenWithProps;
    }

    return (
      <SvgAddWrapper onClick={showModal}>
        <img src={process.env.PUBLIC_URL + "/icons/add.svg"} alt="plus" />
      </SvgAddWrapper>
    );
  };

  return (
    <Wrapper noChildren={noChildren}>
      {renderChildren()}
      {isModalVisible &&
        <ModalComponent
          columnData={columnData}
          onCancel={handleCancelModal}
          isModalVisible={isModalVisible}
        />
      }
    </Wrapper>
  );
};

export default memo(TaskFieldsModal);
