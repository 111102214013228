import { FC } from "react";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";

import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import { dataInput } from "components/blocks/TaskTable/styled";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import { DottedPlaceholderWrapper } from "components/blocks/TaskTable/components/placeholders/DottedPlaceholder/styled";

import UserGeneric from "./Display/generic";
import { fieldDetailedWrapper, fieldDetailedHover } from "../styled";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";

type UsersFieldProps = TaskFieldPropsGeneric

const UserListWrapper = styled.div<{ propertyType: TaskPropertyClass, clickable?: boolean }>`
  ${dataInput};
  cursor: ${({ propertyType }) => (propertyType === TaskPropertyClass.Custom) ? "pointer" : "auto"};
  &:hover {
    ${DottedPlaceholderWrapper} {
      opacity: 1;
    }
  }
  ${({ clickable }) => !clickable && css`
    cursor: default;
    ${DottedPlaceholderWrapper} {
      display: none;
    }
  `}
`;

const UserDetailedWrapper = styled.div<{ propertyType: TaskPropertyClass, clickable?: boolean }>`
  ${({ propertyType }) => (propertyType === TaskPropertyClass.Custom) && css`
    ${fieldDetailedHover};
  `};
  ${({ propertyType }) => !(propertyType === TaskPropertyClass.Custom) && css`
    ${fieldDetailedWrapper};
  `};
  width: 100%;
  ${({ clickable }) => !clickable && css`
    cursor: default;
  `}
`;

const UserField: FC<UsersFieldProps> = (props) => {
  const { component, propertyType } = props;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  switch (component) {
    case TaskSourceComponent.Card:
      return null;
    case TaskSourceComponent.Cell:
      return (
        <UserListWrapper propertyType={propertyType} clickable={allowedToEdit}>
          <UserGeneric {...props} />
        </UserListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <UserDetailedWrapper propertyType={propertyType} clickable={allowedToEdit}>
          <UserGeneric {...props} />
        </UserDetailedWrapper>
      );
    default:
      return null;
  }
};

export default UserField;
