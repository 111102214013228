import configuration from "lib/configs/configuration";
import { useEffect } from "react";

const defaultTitle = configuration.app.title;
const suffix = " - " + defaultTitle;

export const useDocumentTitle = (title = defaultTitle, isShowSuffix = true) => {
  useEffect(() => {
    document.title = `${title}${isShowSuffix ? suffix : ""}`;
    return () => {
      document.title = defaultTitle;
    };

  }, [title, document.title]);
};
