import { FC, useState } from "react";
import moment from "moment";
import ButtonIcon from "components/ui/ButtonIcon";
import { DateFormats } from "lib/theme/lib";
import { BoardAutomationInterface } from "lib/types/boardTypes";
import AutomationsModal from "../../AutomationsModal";
import {
  AutomationsItemCard,
  AutomationsItemContent,
  AutomationsItemDate, AutomationsItemInnerContent,
  AutomationsItemTitle,
  AutomationsMoreWrapper
} from "./styled";

interface AutomationItemProps {
  item: BoardAutomationInterface;
}

const AutomationItem: FC<AutomationItemProps> = (props) => {
  const { item } = props;
  const [isShowModal, setIsShowModal] = useState(false);
  const displayDate = moment(item.createdAt).format(DateFormats.Friendly);

  return (
    <>
      <AutomationsItemCard>
        <AutomationsItemContent>
          <div style={{ marginRight: "14px" }}>
            <div style={{ height: "6px", width: "6px", borderRadius: "3px", backgroundColor: (item.enabled) ? "#a4dd91" : "red" }} />
          </div>
          <AutomationsItemInnerContent>
            <AutomationsItemTitle>
              {(item.title ? item.title : "Unnamed automation")}
            </AutomationsItemTitle>
            <AutomationsItemDate>{`Created on ${displayDate}`}</AutomationsItemDate>
          </AutomationsItemInnerContent>
        </AutomationsItemContent>
        <AutomationsMoreWrapper onClick={() => setIsShowModal(true)}>
          <ButtonIcon iconUrl="/icons/edit.svg" />
        </AutomationsMoreWrapper>
      </AutomationsItemCard>
      {isShowModal &&
        <AutomationsModal
          automation={item}
          visible={isShowModal}
          onClose={() => setIsShowModal(false)}
        />
      }
    </>
  );
};

export default AutomationItem;
