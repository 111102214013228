import { FC, useState } from "react";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";

import { setLoading } from "app/slices/progressSlice";
import WorkspaceService from "services/WorkspaceService";
import { StyledButton } from "styles/common";
import CustomStyledButton from "../CustomStyledButton";
import { useAppDispatch } from "app/store";
import { ModalComponentProps } from "lib/types/components";
import { ProjectContextInterface, useProjectContext } from "lib/contexts/ProjectContext";
import { captureException } from "lib/utils/sentry";
import { commonModalProps } from "../ModalDialog/lib";

const DeleteProjectModal: FC<ModalComponentProps> = (props) => {
  const { visible, onClose } = props;
  const { workspace } = useProjectContext() as ProjectContextInterface;

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleDeleteOnClick = async () => {
    let deletedWorkspace;

    dispatch(setLoading(true));
    setIsLoading(true);

    try {
      deletedWorkspace = await WorkspaceService.delete(workspace.id);
    } catch (error) {
      captureException(error);
      onClose();
    }

    dispatch(setLoading(false));
    setIsLoading(false);

    if (deletedWorkspace) {
      onClose();
      history.push("/home");
    }
  };

  const handleOnClose: React.MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <Modal
      {...commonModalProps}
      title={`Delete the "${workspace.name}" project?`}
      visible={visible}
      onCancel={handleOnClose}
      footer={[
        <div key="cancel" style={{ display: "inline-block", marginRight: "10px" }}>
          <StyledButton onClick={handleOnClose}>
            Cancel
          </StyledButton>
        </div>,
        <CustomStyledButton
          btnColor="redOutlined"
          key="delete"
          onClick={handleDeleteOnClick}
          value="Delete"
          isLoading={isLoading}
        />
      ]}
    >
      <div>
        <p>This will delete the project, along with the following:</p>
        <ul style={{ marginBottom: 0 }}>
          <li>any board and their records;</li>
          <li>any 3D BIM models.</li>
        </ul>
      </div>
    </Modal>
  );
};

export default DeleteProjectModal;
