import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EntityId } from "lib/types/entity";
import { TaskInterface } from "lib/types/tasksTypes";

interface InitialStateType {
  task: TaskInterface | null;
}

const initialState: InitialStateType = {
  task: null
};

export const currentTaskSlice = createSlice({
  name: "currentTask",
  initialState,
  reducers: {
    setTask: (state, action: PayloadAction<TaskInterface | null>) => {
      state.task = action.payload;
    },
    updateTask: (state, action: PayloadAction<TaskInterface | null>) => {
      if (state.task?.id === action.payload?.id) {
        state.task = action.payload;
      }
    },
    updateTaskChangelog: (state, action: PayloadAction<Pick<TaskInterface, "id" | "changelog" | "comments"> | null>) => {
      if (state.task && (state.task?.id === action.payload?.id)) {
        state.task.changelog = action.payload.changelog;
        state.task.comments = action.payload.comments;
      }
    },
    performTaskOptimisticUpdate: (state, action: PayloadAction<{ id: EntityId, data: Partial<TaskInterface> }>) => {
      const { id, data } = action.payload;

      if (state.task && String(state.task?.id) === String(id)) {
        for (const [code, value] of Object.entries(data)) {
          state.task[code as keyof TaskInterface] = value as never;
        }
      }
    },
    unsetTask: (state, action: PayloadAction<TaskInterface | null>) => {
      if (state.task?.id === action.payload?.id) {
        state.task = null;
      }
    }
  },
});

export const { setTask, performTaskOptimisticUpdate, updateTaskChangelog, unsetTask, updateTask } = currentTaskSlice.actions;

export default currentTaskSlice.reducer;
