import { FC } from "react";
import { AutocompleteComponents } from "@algolia/autocomplete-js";
import { SearchTaskItem } from "lib/types/searchTypes";
import TaskCheckmark from "pages/Task/TaskCheckmark";
import {
  SearchItemBoardTitle,
  SearchItemCheckmarkWrapper,
  SearchItemTaskTitle,
  SearchItemWrapper
} from "components/blocks/Search/styled";

interface SearchItemProps {
  item: SearchTaskItem;
  components: AutocompleteComponents;
  onClickLink: (item: SearchTaskItem) => void;
}

const SearchItem: FC<SearchItemProps> = (props) => {
  const { item, components, onClickLink } = props;

  const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    onClickLink(item);
  };

  return (
    <div onClick={onClick}>
      <SearchItemWrapper>
        <SearchItemCheckmarkWrapper>
          <TaskCheckmark isCompleted={item.completed} />
        </SearchItemCheckmarkWrapper>
        <div>
          <SearchItemTaskTitle>
            <components.Highlight hit={item} attribute="title" />
          </SearchItemTaskTitle>
          {item.board &&
            <SearchItemBoardTitle>
              {item.board.name}
            </SearchItemBoardTitle>
          }
        </div>
      </SearchItemWrapper>
    </div>
  );
};

export default SearchItem;
