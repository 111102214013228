import { FC } from "react";
import { Input, Select, Checkbox, DatePicker, Form, InputNumber } from "antd";

import ReusableSelectOption from "components/blocks/ReusableFields/ReusableMultiSelectOption";
import { PropertyValueType } from "lib/types/dataTypes";
import { Column } from "lib/types/tasksTypes";
import { UserInterface } from "lib/types/types";
import UploadSingle from "components/blocks/FormComponents/UploadSingle";
import UploadMultiple from "components/blocks/FormComponents/UploadMultiple";
import { BoardViewAppliedColumn, DefaultColumnCodes } from "lib/types/boardTypes";
import { DateFormats, InputPlaceholders } from "lib/theme/lib";
import { DateFormatsMap } from "lib/types/date";
import UserMultiple from "components/blocks/FormComponents/UserMultiple";
import UserSingle from "components/blocks/FormComponents/UserSingle";
import ConditionalWrapper from "components/blocks/ConditionalWrapper";
import RichTextEditorField from "components/blocks/FormComponents/RichTextEditorField";
import DateTimePickerField from "components/blocks/FormComponents/DateTimePickerField";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import SubtasksField from "components/blocks/FormComponents/SubtasksField";
import { getColumnPrecision } from "lib/helpers/boardUtils";

interface FormFieldItemProps {
  fieldName: string | string[];
  column: Column & BoardViewAppliedColumn;
  members?: Array<UserInterface>;
  disabled?: boolean;
}

const FormFieldItem: FC<FormFieldItemProps> = (props) => {
  const { column, members, fieldName, disabled } = props;

  const config = {
    ...(column.type === PropertyValueType.Boolean ? { valuePropName: "checked" } : {}),
  };

  const rules = [
    ...(column.required ? [{ required: true }] : []),
    ...([PropertyValueType.SingleLineText, PropertyValueType.MultiLineText].includes(column.type as PropertyValueType) ? [{ whitespace: true }] : []),
  ];

  const ignoreFormItemWrapperCodes: Array<string> = [DefaultColumnCodes.DescriptionRTE];
  const inputCustomWidth = { width: "50%", minWidth: 100 };
  const dateFormat = DateFormatsMap[(column.dateFormat)] || DateFormats.Friendly;
  const precision = getColumnPrecision(column.precision);

  const wrapCondition = !ignoreFormItemWrapperCodes.includes(column.code);

  const wrapFormItemFn = (children: JSX.Element) => (
    <Form.Item
      name={fieldName}
      rules={rules}
      {...config}
    >
      {children}
    </Form.Item>
  );

  const fieldsMap: Record<PropertyValueType | string, EmotionJSX.Element> = {
    [PropertyValueType.SingleLineText]: <Input placeholder={InputPlaceholders.Empty} />,
    [PropertyValueType.MultiLineText]: (column.code === DefaultColumnCodes.DescriptionRTE)
      ? <RichTextEditorField fieldName={column.code} />
      : <Input.TextArea placeholder={InputPlaceholders.Empty} rows={3} />,
    [PropertyValueType.Number]: <InputNumber precision={precision} placeholder={InputPlaceholders.Empty} style={inputCustomWidth} />,
    [PropertyValueType.Percent]: <InputNumber precision={precision} placeholder={InputPlaceholders.Empty} style={inputCustomWidth} />,
    [PropertyValueType.SingleSelect]: (
      <Select
        placeholder={InputPlaceholders.Empty}
        style={inputCustomWidth}
        allowClear
        showSearch
        filterOption={(input, option) => String(option?.label)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {column.options?.map(selectOption => (
          <Select.Option label={selectOption.title} value={selectOption.id} key={selectOption.id}>
            <ReusableSelectOption selectTitle={selectOption.title} selectColor={selectOption.color} />
          </Select.Option>
        ))}
      </Select>
    ),
    [PropertyValueType.MultiSelect]: (
      <Select
        mode="multiple"
        placeholder={InputPlaceholders.Empty}
        allowClear
        showArrow
        showSearch
        filterOption={(input, option) => String(option?.label)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {column.options?.map(selectOption => (
          <Select.Option label={selectOption.title} value={selectOption.id} key={selectOption.id}>
            <ReusableSelectOption selectTitle={selectOption.title} selectColor={selectOption.color} />
          </Select.Option>
        ))}
      </Select>
    ),
    [PropertyValueType.Boolean]: <Checkbox />,
    [PropertyValueType.User]: <UserSingle members={members} fieldName={fieldName} />,
    [PropertyValueType.Users]: <UserMultiple members={members} fieldName={fieldName} />,
    [PropertyValueType.Date]: (
      <DateTimePickerField
        fieldName={fieldName}
        dateFormat={column.dateFormat}
        timeFormat={column.timeFormat}
        style={inputCustomWidth}
      />
    ),
    [PropertyValueType.DateTime]: <DatePicker format={dateFormat} placeholder={InputPlaceholders.Empty} style={inputCustomWidth} />,
    [PropertyValueType.File]: <UploadSingle fieldName={fieldName} disabled={disabled} />,
    [PropertyValueType.Files]: <UploadMultiple fieldName={fieldName} disabled={disabled} />,
    [PropertyValueType.Link]: <Input placeholder={InputPlaceholders.Empty} />,
    [PropertyValueType.Url]: <Input placeholder={InputPlaceholders.Empty} />,
    [PropertyValueType.Currency]: (
      <InputNumber
        precision={precision}
        placeholder={InputPlaceholders.Empty}
        style={inputCustomWidth}
        prefix={column.currency}
      />
    ),
    [PropertyValueType.Subtasks]: <SubtasksField fieldName={fieldName} />
  };

  const FieldComponent = fieldsMap[column.type] || <></>;

  return (
    <ConditionalWrapper
      condition={wrapCondition}
      wrapper={wrapFormItemFn}
    >
      {FieldComponent}
    </ConditionalWrapper>
  );
};

export default FormFieldItem;
