import React, { FC } from "react";
import FilterSvg from "components/ui/ButtonControls/icons/filter";
import { MenuItemIconComponent, MenuItemIconContainer, MenuItemIconText } from "components/ui/MenuItemIcon/styled";

export interface MenuItemIconProps {
  icon?: React.ReactNode;
  text?: React.ReactNode;
  disabled?: boolean;
  red?: boolean;
}

const MenuItemIcon: FC<MenuItemIconProps> = (props) => {
  const icon = props?.icon || <FilterSvg />;
  const text = props?.text || "Undefined";
  const disabled = props?.disabled || false;
  const red = props?.red || false;

  return (
    <MenuItemIconComponent disabled={disabled} red={red}>
      <MenuItemIconContainer>
        {icon}
      </MenuItemIconContainer>
      <MenuItemIconText red={red}>
        {text}
      </MenuItemIconText>
    </MenuItemIconComponent>
  );
};

export default MenuItemIcon;
