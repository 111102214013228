import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const CrossSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.1253 16.6738C9.62491 15.1738 9.62491 12.6774 11.1253 11.125C12.6781 9.62501 15.1227 9.62501 16.6755 11.125L49.9803 44.4774L83.3411 11.125C84.8415 9.62501 87.3385 9.62501 88.8354 11.125C90.3882 12.6774 90.3882 15.1773 88.8354 16.6738L55.5306 49.9738L88.8354 83.3262C90.3882 84.8262 90.3882 87.3226 88.8354 88.875C87.3351 90.375 84.838 90.375 83.3411 88.875L49.9803 55.5226L16.6755 88.875C15.1227 90.375 12.6781 90.375 11.1253 88.875C9.62491 87.3226 9.62491 84.8227 11.1253 83.3262L44.4301 49.9738L11.1253 16.6738Z" />
      </svg>
    </IconSvgWrapperNew>
  );
};

export default CrossSvg;
