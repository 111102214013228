import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const ExeSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.39 20.4357C64.1203 20.4335 61.9444 19.5309 60.3394 17.9261C58.7344 16.3212 57.8319 14.1451 57.8298 11.8755V0H19.585C16.6236 0 13.7835 1.17639 11.6895 3.27042C9.59546 5.36444 8.41907 8.20461 8.41907 11.166V78.4772C8.42346 81.4357 9.60181 84.2714 11.6954 86.3618C13.7889 88.4523 16.6265 89.6266 19.585 89.6266H65.4771C68.4386 89.6266 71.2788 88.4502 73.3726 86.356C75.4668 84.2622 76.6431 81.422 76.6431 78.4606V20.4191L66.39 20.4357Z" fill="#0072FF"/>
        <path d="M76.6431 20.4357H66.39C64.1203 20.4335 61.9444 19.5309 60.3394 17.9261C58.7344 16.3212 57.8319 14.1451 57.8298 11.8755V0L76.6431 20.4357Z" fill="#0072FF"/>
        <path d="M84.7428 70.7925H32.8589C29.1786 70.7925 26.1951 73.7759 26.1951 77.4564V92.9253C26.1951 96.6058 29.1786 99.5892 32.8589 99.5892H84.7428C88.4233 99.5892 91.4067 96.6058 91.4067 92.9253V77.4564C91.4067 73.7759 88.4233 70.7925 84.7428 70.7925Z" fill="#0072FF"/>
        <path d="M42.2216 93.3609C41.629 93.3609 41.3328 93.0646 41.3328 92.4721V79.902C41.3328 79.3094 41.629 79.0132 42.2216 79.0132H49.8186C50.4111 79.0132 50.7074 79.3094 50.7074 79.902C50.7074 80.4945 50.4111 80.7908 49.8186 80.7908H43.1104V85.2348H47.3216C47.9141 85.2348 48.2104 85.531 48.2104 86.1236C48.2104 86.7161 47.9141 87.0124 47.3216 87.0124H43.1104V91.5833H49.8186C50.4111 91.5833 50.7074 91.8796 50.7074 92.4721C50.7074 93.0646 50.4111 93.3609 49.8186 93.3609H42.2216Z" fill="white"/>
        <path d="M53.7029 93.1705C53.2303 92.846 53.1598 92.4298 53.4913 91.9219L57.3639 86.2083L53.2797 80.4522C52.9623 79.9867 53.0399 79.5705 53.5125 79.2037C53.9992 78.8722 54.4083 78.9498 54.7399 79.4365L58.5278 84.7481L62.3369 79.4365C62.7038 78.9639 63.1129 78.9004 63.5643 79.246C64.044 79.5987 64.1145 80.0078 63.776 80.4734L59.6071 86.2717L63.6067 91.9008C63.9382 92.3875 63.8606 92.8107 63.3739 93.1705C62.8872 93.502 62.478 93.4244 62.1465 92.9377L58.4643 87.7531L54.9515 92.9589C54.627 93.4174 54.2108 93.4879 53.7029 93.1705Z" fill="white"/>
        <path d="M68.2191 93.3609C67.6266 93.3609 67.3303 93.0646 67.3303 92.4721V79.902C67.3303 79.3094 67.6266 79.0132 68.2191 79.0132H75.8162C76.4087 79.0132 76.705 79.3094 76.705 79.902C76.705 80.4945 76.4087 80.7908 75.8162 80.7908H69.1079V85.2348H73.3191C73.9117 85.2348 74.2079 85.531 74.2079 86.1236C74.2079 86.7161 73.9117 87.0124 73.3191 87.0124H69.1079V91.5833H75.8162C76.4087 91.5833 76.705 91.8796 76.705 92.4721C76.705 93.0646 76.4087 93.3609 75.8162 93.3609H68.2191Z" fill="white"/>
        <path d="M42.5312 61.2905C42.058 61.2905 41.6042 61.1025 41.2696 60.7681C40.935 60.4332 40.7469 59.9793 40.7469 59.5062V56.7635C40.7469 56.5295 40.7931 56.2975 40.8828 56.0813C40.9725 55.8651 41.1041 55.6685 41.2698 55.5033C41.4355 55.3378 41.6323 55.2071 41.8487 55.1178C42.0652 55.0286 42.2971 54.983 42.5312 54.9834C43.0033 54.9834 43.456 55.171 43.7899 55.5046C44.1237 55.8386 44.3113 56.2913 44.3113 56.7635V59.5062C44.3113 59.9788 44.1238 60.432 43.7901 60.7664C43.4564 61.1008 43.0037 61.2892 42.5312 61.2905Z" fill="#0072FF"/>
        <path d="M42.5312 39.1785C42.058 39.1785 41.6042 38.9905 41.2696 38.6559C40.935 38.3213 40.7469 37.8675 40.7469 37.3943V34.2035C40.7469 33.7302 40.935 33.2764 41.2696 32.9418C41.6042 32.6072 42.058 32.4192 42.5312 32.4192C43.0037 32.4203 43.4564 32.6087 43.7901 32.9432C44.1238 33.2777 44.3113 33.731 44.3113 34.2035V37.3943C44.3113 37.8668 44.1238 38.32 43.7901 38.6545C43.4564 38.9889 43.0037 39.1774 42.5312 39.1785Z" fill="#0072FF"/>
        <path d="M48.859 59.5975C48.5453 59.598 48.2371 59.5158 47.9654 59.359C47.6937 59.2025 47.4681 58.9772 47.3113 58.7054L45.9834 56.3984C45.7474 55.9897 45.6835 55.5038 45.8056 55.0478C45.9278 54.5917 46.2261 54.2029 46.6349 53.9668C46.8375 53.8494 47.0614 53.7731 47.2935 53.7419C47.5257 53.7112 47.7617 53.7266 47.988 53.7872C48.2142 53.8473 48.4263 53.9523 48.6121 54.0946C48.7979 54.2374 48.9537 54.4154 49.0706 54.6183L50.3984 56.9212C50.6345 57.3307 50.6988 57.817 50.5764 58.2739C50.4544 58.7303 50.1561 59.12 49.747 59.3569C49.477 59.5133 49.1709 59.5963 48.859 59.5975Z" fill="#0072FF"/>
        <path d="M37.8423 40.5104C37.5287 40.5109 37.2205 40.4286 36.9488 40.272C36.677 40.1154 36.4514 39.8899 36.2946 39.6183L34.6597 36.7926C34.4239 36.3826 34.3604 35.8958 34.4833 35.4391C34.6063 34.9823 34.9055 34.5931 35.3153 34.3569C35.7248 34.1207 36.2112 34.0566 36.6679 34.1787C37.1246 34.3009 37.5141 34.5993 37.751 35.0084L39.3817 37.8382C39.618 38.2477 39.682 38.7342 39.5599 39.1908C39.4377 39.6475 39.1393 40.0371 38.7302 40.274C38.4601 40.4291 38.1539 40.5107 37.8423 40.5104Z" fill="#0072FF"/>
        <path d="M53.4855 54.9629C53.1738 54.9633 52.8676 54.8815 52.5975 54.7263L50.2904 53.3944C49.8813 53.1575 49.583 52.7678 49.4609 52.3114C49.3387 51.8546 49.4028 51.3683 49.639 50.9587C49.8751 50.5488 50.2643 50.2496 50.7211 50.1268C51.178 50.0039 51.6647 50.067 52.0747 50.3031L54.3817 51.6351C54.7909 51.872 55.0892 52.2616 55.2112 52.7181C55.3336 53.1749 55.2693 53.6612 55.0332 54.0707C54.8763 54.3425 54.6506 54.5678 54.3792 54.7243C54.1075 54.8811 53.7992 54.9633 53.4855 54.9629Z" fill="#0072FF"/>
        <path d="M34.3985 43.9461C34.0865 43.9452 33.7802 43.8623 33.5105 43.7054L30.6806 42.0747C30.2715 41.8378 29.9731 41.4483 29.851 40.9916C29.7289 40.535 29.7929 40.0485 30.0291 39.6391C30.146 39.4361 30.3019 39.2581 30.4876 39.1155C30.6734 38.9729 30.8855 38.8683 31.1118 38.8078C31.338 38.7472 31.574 38.732 31.8062 38.7628C32.0384 38.7937 32.2622 38.8701 32.4648 38.9876L35.2947 40.6183C35.703 40.8559 36.0006 41.2455 36.1227 41.7017C36.2447 42.1581 36.1813 42.6444 35.9461 43.054C35.7893 43.3257 35.5637 43.5511 35.292 43.7075C35.0203 43.8643 34.7121 43.9465 34.3985 43.9461Z" fill="#0072FF"/>
        <path d="M55.1868 48.639H52.4399C51.9668 48.639 51.5129 48.4511 51.178 48.1166C50.8436 47.7818 50.6556 47.3278 50.6556 46.8548C50.6569 46.3822 50.8453 45.9295 51.1797 45.5959C51.5141 45.2623 51.9673 45.0747 52.4399 45.0747H55.1868C55.6594 45.0747 56.1125 45.2623 56.4469 45.5959C56.7814 45.9295 56.9697 46.3822 56.971 46.8548C56.971 47.3278 56.783 47.7818 56.4486 48.1166C56.1137 48.4511 55.6598 48.639 55.1868 48.639Z" fill="#0072FF"/>
        <path d="M33.0623 48.639H29.8755C29.4023 48.639 28.9485 48.4511 28.6139 48.1166C28.2793 47.7818 28.0913 47.3278 28.0913 46.8548C28.0924 46.3822 28.2809 45.9295 28.6154 45.5959C28.9499 45.2623 29.4031 45.0747 29.8755 45.0747H33.0581C33.2923 45.0743 33.5242 45.1199 33.7406 45.2092C33.9571 45.2984 34.1538 45.4291 34.3195 45.5946C34.4852 45.7598 34.6167 45.9565 34.7065 46.1726C34.7962 46.3888 34.8423 46.6208 34.8423 46.8548C34.8429 47.0888 34.7973 47.3208 34.7081 47.5374C34.6189 47.7536 34.4878 47.9506 34.3224 48.1162C34.1571 48.2818 33.9606 48.4133 33.7444 48.5029C33.5282 48.593 33.2964 48.639 33.0623 48.639Z" fill="#0072FF"/>
        <path d="M50.9876 43.7554C50.6743 43.7558 50.3669 43.6741 50.0951 43.5181C49.8233 43.3624 49.5975 43.138 49.4399 42.8674C49.204 42.4575 49.1405 41.9707 49.2635 41.5139C49.3864 41.0572 49.6857 40.6679 50.0955 40.4317L52.5851 38.9878C52.9947 38.7515 53.481 38.6875 53.9378 38.8096C54.3943 38.9317 54.7839 39.2301 55.0208 39.6393C55.2569 40.0487 55.3212 40.5352 55.1988 40.9918C55.0768 41.4485 54.7785 41.838 54.3694 42.0749L51.8797 43.5189C51.6084 43.6753 51.3005 43.7566 50.9876 43.7554Z" fill="#0072FF"/>
        <path d="M31.5727 54.9627C31.2596 54.9631 30.9519 54.8809 30.6809 54.7245C30.4098 54.5677 30.1849 54.3424 30.0291 54.0706C29.7929 53.661 29.7289 53.1747 29.851 52.7179C29.9731 52.2614 30.2715 51.8718 30.6806 51.6349L33.3196 50.1121C33.7293 49.8768 34.2154 49.8133 34.6718 49.9357C35.1281 50.0577 35.5177 50.3552 35.7553 50.7635C35.9912 51.1735 36.0546 51.6602 35.9317 52.117C35.8088 52.5739 35.5095 52.9631 35.0997 53.1992L32.4648 54.722C32.1944 54.881 31.8863 54.9639 31.5727 54.9627Z" fill="#0072FF"/>
        <path d="M42.7387 58.552H42.4524C40.413 58.5043 38.4239 57.9097 36.6931 56.83C34.9825 55.7695 33.5803 54.2786 32.6266 52.5064C31.7352 50.8375 31.2678 48.9753 31.2656 47.0831C31.2656 46.9877 31.2656 46.8923 31.2656 46.7968C31.3147 44.847 31.8603 42.9417 32.8507 41.2616C33.8399 39.5835 35.239 38.1843 36.9171 37.1952C38.6044 36.204 40.5167 35.6598 42.4731 35.6143H42.7345C44.6288 35.6122 46.4939 36.0814 48.1619 36.9794C49.9328 37.9298 51.4225 39.3294 52.4814 41.0375C53.5623 42.7765 54.1557 44.7744 54.1992 46.8217V47.0831C54.1997 49.0641 53.6864 51.0114 52.7096 52.7346C51.6884 54.5433 50.1938 56.0396 48.386 57.0624C46.6637 58.0383 44.7181 58.5512 42.7387 58.552ZM42.693 39.1744H42.581C41.2254 39.2039 39.8998 39.5796 38.7304 40.2658C37.574 40.9477 36.609 41.9114 35.9254 43.0665C35.2485 44.2284 34.88 45.5437 34.8548 46.8881V47.0707C34.854 48.374 35.1762 49.657 35.7926 50.8051C36.4492 52.03 37.4166 53.0603 38.5976 53.7927C39.7957 54.5358 41.1713 54.9446 42.581 54.9753H42.7636C44.1292 54.9769 45.4717 54.6238 46.6598 53.9504C47.9074 53.2441 48.9389 52.2114 49.6432 50.9628C50.3158 49.7761 50.6689 48.435 50.6681 47.0707V46.913C50.6374 45.4952 50.2275 44.1109 49.4814 42.9047C48.7514 41.7267 47.7237 40.7623 46.5021 40.108C45.3345 39.4845 44.0292 39.1635 42.7055 39.1744H42.693Z" fill="#0072FF"/>
        <path d="M47.3278 40.3238C47.0161 40.325 46.7096 40.2434 46.4398 40.0873C46.2369 39.9704 46.0589 39.8146 45.9163 39.6288C45.7737 39.443 45.6691 39.2309 45.6086 39.0046C45.548 38.7784 45.5327 38.5424 45.5636 38.3102C45.5945 38.078 45.6708 37.8542 45.7883 37.6516L47.3112 35.0084C47.4281 34.8055 47.5839 34.6275 47.7696 34.4849C47.9554 34.3422 48.1676 34.2377 48.3938 34.1771C48.6201 34.1166 48.8561 34.1014 49.0883 34.1322C49.3204 34.1631 49.5443 34.2395 49.7469 34.357C49.9498 34.4739 50.1278 34.6297 50.2705 34.8154C50.4132 35.0012 50.5174 35.2134 50.578 35.4396C50.6386 35.6659 50.6539 35.9019 50.6232 36.1341C50.5921 36.3663 50.5157 36.5901 50.3983 36.7927L48.8755 39.4317C48.7187 39.7033 48.4931 39.9288 48.2213 40.0854C47.9496 40.242 47.6414 40.3243 47.3278 40.3238Z" fill="#0072FF"/>
        <path d="M36.2033 59.5977C35.8912 59.5972 35.5848 59.5143 35.3153 59.357C34.9055 59.1209 34.6063 58.7317 34.4833 58.2748C34.3604 57.818 34.4239 57.3313 34.6597 56.9213L36.1037 54.4317C36.3406 54.0226 36.7301 53.7242 37.1868 53.6022C37.6435 53.4798 38.1299 53.5441 38.5394 53.7802C38.9485 54.0172 39.2469 54.4068 39.369 54.8632C39.4912 55.3201 39.4271 55.8064 39.1908 56.2159L37.751 58.7055C37.5942 58.9773 37.3685 59.2026 37.0968 59.3591C36.8251 59.5159 36.5169 59.5981 36.2033 59.5977Z" fill="#0072FF"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default ExeSvg;
