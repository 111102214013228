import { Dropdown, Select, Tooltip } from "antd";

import { setSortField, setSortOrder } from "app/slices/tasksFilterAndSortSlice";
import { useAppDispatch, useAppSelector } from "app/store";
import LoadingMessage from "components/blocks/LoadingMessage";
import ButtonControls from "components/ui/ButtonControls";
import CrossSvg from "components/ui/ButtonControls/icons/cross";
import SaveSvg from "components/ui/ButtonControls/icons/save";
import SortSvg from "components/ui/ButtonControls/icons/sort";
import ButtonIcon from "components/ui/ButtonIcon";
import { useTasksContext } from "lib/contexts/TasksContext";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import useSavingState from "lib/customHooks/useSavingState";
import useBoardViewSave from "lib/customHooks/views/useBoardViewSave";
import { FilterValueNone } from "lib/filters/filters";
import { DEFAULT_SORT_FIELD, TaskSortDirection } from "lib/sort/tasksSort";
import { DisplayView } from "lib/types/applicationTypes";
import { SortFieldType } from "lib/types/sortAndFilterTypes";
import { MemberRoles } from "lib/types/types";
import { FC, useEffect, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import TasksService from "services/TasksService";

import { DropdownWrapper } from "styles/common";
import { activeButtonClassSort, SortWrapper, SortSaveGroup, SortSaveWrapper } from "./styled";

const { Option, OptGroup } = Select;

interface SortProps {
  fields: Array<SortFieldType>;
  hideSortActions?: boolean;
  isCustomView?: boolean;
  text?: string;
  tooltipText?: string;
}

const Sort: FC<SortProps> = (props) => {
  const { isCustomView, fields } = props;

  const dispatch = useAppDispatch();
  const { saveView } = useBoardViewSave();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const { setSavingStart, setSavingFinish } = useSavingState();

  const { source, sortedUnfilteredTasks } = useTasksContext();
  const currentSort = useAppSelector(state => state.boardFilterAndSort.sort);
  const board = useAppSelector(state => state.currentBoardReducer.board);
  const directions: Array<TaskSortDirection> = [TaskSortDirection.Ascending, TaskSortDirection.Descending];

  const allowedToSort = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);
  const allowedToEditCustomViews = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const isSortActionsEnabled = [DisplayView.List].includes(source) && allowedToSort;
  const isSortCustomViewsDisabled = isCustomView && !allowedToEditCustomViews;

  enum SortActions {
    Save = "save",
    Revert = "revert",
  }

  const onClickField = (fieldTitle: string) => {
    const field = fields.find(field => field.title === fieldTitle) || DEFAULT_SORT_FIELD;
    dispatch(setSortField(field));
    saveView({ sort: { ...currentSort, field } });
  };

  const onSelectOrder = (direction: TaskSortDirection) => {
    dispatch(setSortOrder(direction));
    saveView({ sort: { ...currentSort, order: direction } });
  };

  useEffect(() => {
    return () => {
      dispatch(setSortField(DEFAULT_SORT_FIELD));
    };
  }, [dispatch]);

  const onClose = () => {
    setVisible(false);
    dispatch(setSortField(DEFAULT_SORT_FIELD));
    saveView({ sort: { ...currentSort, field: DEFAULT_SORT_FIELD } });
  };

  const isNoneTitle = currentSort.field?.title === FilterValueNone;
  const defaultSortFieldValue = currentSort.field?.title === FilterValueNone ? "Select field" : currentSort.field?.title;

  let overlay = (
    <SortWrapper>
      <Select
        showSearch
        style={{ minWidth: 125, marginRight: "7px" }}
        filterOption={(input, option) =>
          String(option?.children)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) => {
          const sortA = fields.find(field => field.title === String(optionA.children))?.sort;
          const sortB = fields.find(field => field.title === String(optionB.children))?.sort;
          if (sortA && sortB) {
            return sortA - sortB;
          }
          return String(optionA.children)?.toLowerCase().localeCompare(String(optionB.children)?.toLowerCase());
        }}
        defaultValue={defaultSortFieldValue}
        onSelect={(item: string) => onClickField(item)}
        dropdownStyle={{ minWidth: 150 }}
        disabled={isSortCustomViewsDisabled}
      >
        {fields.filter(item => item.title !== FilterValueNone).map(item =>
          <Option value={item.title} key={item.code}>
            {item.title}
          </Option>
        )}
      </Select>
      <Select
        style={{ minWidth: 125, marginRight: "7px" }}
        defaultValue={currentSort.order}
        disabled={isNoneTitle || isSortCustomViewsDisabled}
        onSelect={(item: any) => onSelectOrder(item)}
      >
        {directions.map((item) =>
          <Option value={item} key={item}>
            {item}
          </Option>
        )}
      </Select>
      {!isSortCustomViewsDisabled &&
        <ButtonIcon onClick={onClose} iconUrl="/icons/x.svg" />
      }
    </SortWrapper>
  );

  if (!visible) {
    overlay = (<></>);
  }

  const handleSortActionOnChange = async (value: SortActions) => {
    switch (value) {
      case SortActions.Save: {
        if (source === DisplayView.List && sortedUnfilteredTasks?.length && board) {
          setSavingStart();
          LoadingMessage.loading("Saving sort order…");
          const payload = sortedUnfilteredTasks.map((item, index) => ({
            id: item.id, sort: index
          }));
          await TasksService.reorderList(String(board.id), payload);
          LoadingMessage.complete("You're good to go!");
          dispatch(setSortField(DEFAULT_SORT_FIELD));
          setSavingFinish();
        }

        break;
      }
      case SortActions.Revert: {
        dispatch(setSortField(DEFAULT_SORT_FIELD));
        break;
      }

      default:
        break;
    }
  };

  const handleOnOutsideClick = (e: MouseEvent) => {
    const dropdownElement = (e.target as HTMLElement).closest(".ant-select-dropdown");
    if (!dropdownElement) {
      setIsSelectOpen(false);
    }
  };

  return (
    <>
      <Dropdown
        overlay={overlay}
        open={visible}
        onOpenChange={setVisible}
        placement="bottomRight"
        trigger={["click"]}
        destroyPopupOnHide
        overlayStyle={{ animationDuration: "0s" }}
      >
        <Tooltip title={props.tooltipText ?? ""}>
          <>
            <ButtonControls
              icon={<SortSvg />}
              text={props.text ?? "Sort"}
              className={isNoneTitle ? "" : activeButtonClassSort}
            />
          </>
        </Tooltip>
      </Dropdown>
      {(!isNoneTitle && isSortActionsEnabled && !isCustomView) &&
        <SortSaveWrapper onClick={() => setIsSelectOpen(!isSelectOpen)} ref={wrapperRef}>
          <OutsideClickHandler onOutsideClick={handleOnOutsideClick}>
            <ButtonControls
              icon={<SaveSvg />}
              className={isNoneTitle ? "" : activeButtonClassSort}
            />
            <Select
              open={isSelectOpen}
              onChange={handleSortActionOnChange}
              placement="bottomRight"
              dropdownStyle={{ minWidth: 180, maxWidth: 240 }}
              dropdownRender={menu => (
                <SortSaveGroup>
                  {menu}
                </SortSaveGroup>
              )}
            >
              <OptGroup label="Layout options" key="general">
                <Option value={SortActions.Save} key={SortActions.Save}>
                  <DropdownWrapper>
                    <SaveSvg />
                    <span style={{ marginLeft: 7 }}>Save sort order</span>
                  </DropdownWrapper>
                </Option>
                <Option value={SortActions.Revert} key={SortActions.Revert}>
                  <DropdownWrapper>
                    <CrossSvg />
                    <span style={{ marginLeft: 7 }}>Revert changes</span>
                  </DropdownWrapper>
                </Option>
              </OptGroup>
            </Select>
          </OutsideClickHandler>
        </SortSaveWrapper>
      }
    </>
  );
};

export default Sort;
