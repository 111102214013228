import { orderBy } from "lodash";
import { getUserFullName } from "../../helpers/userUtils";
import { BoardInterface, BoardInterfaceExtended } from "../../types/boardTypes";
import { TaskInterface } from "../../types/tasksTypes";
import { WorkspaceInterface } from "../../types/types";
import { FilterFunction } from "../filterFunctions";
import { FilterValueNone, FilterVersion, TAnyFilters } from "../filters";
import { FilterType } from "../filterTypes";

const filterV1 = (tasks: TaskInterface[], currentFilter: TAnyFilters, collaboratorId: string) => {
  // custom users, custom user TODO multiple change logic after V3?
  if ([FilterType.Users, FilterType.User].includes(currentFilter?.types?.[currentFilter?.titleKey] as FilterType)) {
    const filterValueKey = currentFilter.value.valueKey;
    const filteredArray = Array.isArray(filterValueKey)
      ? filterValueKey.map((item: string) => Number(item))
      : [Number(filterValueKey)];

    return tasks.filter(task => {
      const customProperty = task.propertyValues.find(value => value.title === currentFilter.titleKey);
      const taskValue = customProperty?.value;

      const taskValues = Array.isArray(taskValue)
        ? taskValue?.map((user: any) => user?.id) || []
        : [taskValue?.id];

      return filteredArray.some(item => taskValues?.includes(item));
    });
  }

  // assignees
  return tasks.filter(task => {
    const collaboratorIds = task.assignees.map(collaborator => String(collaborator.id));
    return collaboratorIds.includes(collaboratorId);
  });
};

const getFilteredArray = (currentFilter: TAnyFilters) => {
  const filterValueKey = currentFilter.value.valueKey;
  return Array.isArray(filterValueKey)
    ? filterValueKey.map((item: string) => Number(item))
    : [Number(filterValueKey)];
};

const getCustomValue = (task: TaskInterface, currentFilter: TAnyFilters) => {
  const customProperty = task.propertyValues.find(value => value.title === currentFilter.titleKey);
  const taskValue = customProperty?.value;

  if (Array.isArray(taskValue)) {
    return taskValue?.map((user: any) => user?.id) || [];
  }

  return taskValue?.id ? [taskValue?.id] : [];
};

const filterV2 = (tasks: TaskInterface[], currentFilter: TAnyFilters, collaboratorsId: string) => {
  // custom users, custom user, Created by, Last modified by
  if (
    currentFilter.titleKey === FilterType["Created by"] ||
    currentFilter.titleKey === FilterType["Last modified by"] ||
    [FilterType.Users, FilterType.User].includes(currentFilter?.types?.[currentFilter?.titleKey] as FilterType)
  ) {
    const filteredArray = getFilteredArray(currentFilter);

    switch (currentFilter.function) {
      case FilterFunction.Is:
      case FilterFunction.Contains: {
        return tasks.filter(task => {
          const customValue = getCustomValue(task, currentFilter);
          return filteredArray.some(item => customValue?.includes(item));
        });
      }

      case FilterFunction["Is not"]:
      case FilterFunction["Does not contain"]: {
        return tasks.filter(task => {
          const customValue = getCustomValue(task, currentFilter);
          return filteredArray.every(item => !customValue?.includes(item));
        });
      }

      case FilterFunction["Is empty"]: {
        return tasks.filter(task => {
          const customValue = getCustomValue(task, currentFilter);
          return customValue.length === 0;
        });
      }

      case FilterFunction["Is not empty"]: {
        return tasks.filter(task => {
          const customValue = getCustomValue(task, currentFilter);
          return customValue.length !== 0;
        });
      }
    }

    return tasks;
  }

  // assignees
  switch (currentFilter.function) {
    case FilterFunction.Contains: {
      return tasks.filter(task => {
        const collaboratorIds = task.assignees.map(collaborator => String(collaborator.id));
        return collaboratorIds.some(item => collaboratorsId?.includes(item));
      });
    }

    case FilterFunction["Does not contain"]: {
      return tasks.filter(task => {
        const collaboratorIds = task.assignees.map(collaborator => String(collaborator.id));
        return collaboratorIds.every(item => !collaboratorsId?.includes(item));
      });
    }

    case FilterFunction["Is empty"]: {
      return tasks.filter(task => {
        const collaboratorIds = task.assignees.map(collaborator => String(collaborator.id));
        return collaboratorIds.length === 0;
      });
    }

    case FilterFunction["Is not empty"]: {
      return tasks.filter(task => {
        const collaboratorIds = task.assignees.map(collaborator => String(collaborator.id));
        return collaboratorIds.length !== 0;
      });
    }
  }

  return tasks;
};

export const assigneeFilter = {
  filterValues: (item: BoardInterfaceExtended | BoardInterface | WorkspaceInterface | null | undefined, ...args: any[]) => {
    let values = item?.collaborators.map(collaborator => {
      return {
        text: getUserFullName(collaborator) || "",
        valueKey: String(collaborator.id),
        color: collaborator.color,
      };
    }) || [];

    values = orderBy(values, [value => value.text.toLowerCase()]);
    return values;
  },
  filterFn: (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
    const collaboratorId = currentFilter.value.valueKey as string;

    if (currentFilter.version && currentFilter.version !== FilterVersion.v1) {
      return filterV2(tasks, currentFilter, collaboratorId);
    }

    if (
      !collaboratorId ||
      collaboratorId === FilterValueNone ||
      !currentFilter.value.valueKey
    ) {
      return tasks;
    }

    return filterV1(tasks, currentFilter, collaboratorId);
  },
};
