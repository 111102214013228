import { FC, useContext } from "react";
import { Avatar, Tooltip } from "antd";
import SizeContext from "antd/es/avatar/SizeContext";
import { AvatarSize } from "antd/lib/avatar/SizeContext";
import { getUserInitials, getUserFullName } from "lib/helpers/userUtils";
import { UserInterface } from "lib/types/types";
import { AvatarCustomContainer } from "components/ui/AvatarCustom/styled";

interface AvatarCustomProps {
  user: UserInterface | null;
  className?: string;
  size?: AvatarSize;
  onClick?: () => void;
  tooltip?: boolean;
}

const AvatarCustom: FC<AvatarCustomProps> = (props) => {
  const sizeFromContext = useContext(SizeContext);
  const avatarLetters = getUserInitials(props.user);

  return (
    <Tooltip title={props.tooltip ? getUserFullName(props.user) : ""} placement="top">
      <AvatarCustomContainer>
        <Avatar
          // @ts-ignore
          onClick={props.onClick}
          size={props.size || sizeFromContext}
          src={props.user?.picture?.url || false}
          className={props.className ? props.className : ""}
          style={{ backgroundColor: props.user?.color || "#ccc", flexShrink: 0 }}
        >
          {avatarLetters}
        </Avatar>
      </AvatarCustomContainer>
    </Tooltip>
  );
};

export default AvatarCustom;
