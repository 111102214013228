import { UI } from "@pdftron/webviewer";
import { AnnotationInterface, FileContainerInterface, FileContainerPayloadInterface } from "lib/types/fileContainer";
import { JSendResponse } from "lib/types/jsend";
import { EntityQueryPayload } from "lib/types/backend";
import { EntityId } from "lib/types/entity";
import { CommentInterface, FileInterface } from "lib/types/types";
import { ApryseAnnotationManagerAction } from "components/blocks/ApryseViewer/lib";
import HttpService from "./HttpService";

interface RevisionManagerPayload<DataType> {
  annotationId: string;
  revisionId: EntityId;
  fileContainerId?: string;
  recordId?: string;
  action: ApryseAnnotationManagerAction;
  data?: DataType;
  picture?: FileInterface | EntityId;
}

class FileContainerService extends HttpService {
  constructor() {
    super("file-containers");
  }

  query = async (filter: EntityQueryPayload<FileContainerInterface>) => {
    const data: Array<FileContainerInterface> = await this._post({
      additionalUrl: "/query",
      body: filter,
    });

    return data;
  };

  fetch = async (id: EntityId) => {
    const data: FileContainerInterface = await this._get({
      additionalUrl: `/${id}`
    });

    return data;
  };

  create = async (payload: Partial<FileContainerPayloadInterface>) => {
    const data: JSendResponse = await this._post({
      body: payload,
    });

    return data;
  };

  update = async (id: EntityId, payload: Partial<FileContainerInterface>) => {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}`,
      body: payload,
    });

    return data;
  };

  delete = async (id: EntityId) => {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}`
    });

    return data;
  };

  revisionsAdd = async (id: EntityId, file: FileInterface) => {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/revisions`,
      body: {
        file: file,
      }
    });

    return data;
  };

  revisionsDelete = async (id: EntityId, revisionId: EntityId) => {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}/revisions/${revisionId}`,
    });

    return data;
  };

  annotationManager = async (id: EntityId, payload: RevisionManagerPayload<string>) => {
    const data: JSendResponse<AnnotationInterface> = await this._put({
      additionalUrl: `/${id}/annotation-manager`,
      body: payload,
    });

    return data;
  };

  annotationTransfer = async (id: EntityId, sourceRevisionId: string, targetRevisionId: string, options = { patch: false }) => {
    const data: JSendResponse<AnnotationInterface> = await this._post({
      additionalUrl: `/${id}/annotation-transfer`,
      body: {
        source: sourceRevisionId,
        target: targetRevisionId,
        options: options,
      },
    });

    return data;
  };

  mentionManager = async (id: EntityId, payload: RevisionManagerPayload<UI.MentionsManager.Mention>) => {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}/mention-manager`,
      body: payload,
    });

    return data;
  };


  commentsAdd = async (id: EntityId, commentPayload: Partial<CommentInterface>) => {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/comments`,
      body: commentPayload,
    });

    return data;
  };

  commentsEdit = async (id: EntityId, commentId: EntityId, commentPayload: Partial<CommentInterface>) => {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}/comments/${commentId}`,
      body: commentPayload,
    });

    return data;
  };

  commentsDelete = async (id: EntityId, commentId: EntityId) => {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}/comments/${commentId}`,
    });

    return data;
  };

  commentsReact = async (id: EntityId, commentId: EntityId, reaction: string) => {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/comments/${commentId}/reaction`,
      body: { reaction },
    });

    return data;
  };

  commentsUnreact = async (id: EntityId, commentId: EntityId) => {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}/comments/${commentId}/reaction`,
    });

    return data;
  };
}

export default new FileContainerService();
