import { FC } from "react";
import IconSvgWrapper from "./IconSvgWrapper";
import { IconProps } from "./lib";

const MenuRightSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M6.95999 6.00005L2.79999 10.08L3.91999 11.2L9.19999 6.00005L3.91999 0.800049L2.79999 1.92005L6.95999 6.00005Z" />
    </IconSvgWrapper>
  );
};

export default MenuRightSvg;
