import { pick } from "lodash";
import { EntityQueryPayload, MultiRelationalEntityCommonFields, MultiRelationalEntityFilter, MultiRelationalEntityKeys } from "lib/types/backend";
import { ContentType, getContentTypeDisplayName } from "lib/types/contentTypes";
import { extractFilterFromCtxParams, getBoardViewUri, getProjectFilesUri, getTaskDetailUri } from "./navigationUtils";
import { TaskInterface } from "lib/types/tasksTypes";
import { WorkspaceInterface } from "lib/types/types";
import { BoardInterface } from "lib/types/boardTypes";
import useWorkspaceById from "lib/customHooks/useWorkspaceById";
import { customAlphabet } from "nanoid";

export const generateUid = () => {
  const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const generator = customAlphabet(alphabet, 12);
  return generator();
};

export function doesMatchFilter(filter: MultiRelationalEntityFilter | null, entity: MultiRelationalEntityCommonFields) {
  const params = extractFilterFromCtxParams(filter?.id);
  const field = filter?.contentType as unknown as keyof MultiRelationalEntityCommonFields;
  return entity[field]?.[params.code] === params.value;
}

export const extractEntityRelation = (entity: MultiRelationalEntityCommonFields) => {
  let contentTypeCode, contentTypeValue, contentTypeLink, contentTypeTitle;

  for (const [key, value] of Object.entries(pick(entity, MultiRelationalEntityKeys))) {
    if (value !== null && value !== undefined) {
      contentTypeCode = key;
      contentTypeValue = value;
      break;
    }
  }

  if (!contentTypeValue?.uid || !contentTypeCode) {
    return { contentTypeCode, contentTypeValue, contentTypeLink, contentTypeDisplayName: null };
  }

  const contentTypeDisplayName = getContentTypeDisplayName(contentTypeCode, true);

  switch (contentTypeCode) {
    case ContentType.Workspace:
      contentTypeLink = getProjectFilesUri(contentTypeValue?.uid);
      contentTypeTitle = (contentTypeValue as WorkspaceInterface).name;
      break;
    case ContentType.Board:
      contentTypeLink = getBoardViewUri(contentTypeValue?.uid, "files");
      contentTypeTitle = (contentTypeValue as BoardInterface).name;
      break;
    case ContentType.Task:
      contentTypeLink = getTaskDetailUri(contentTypeValue?.uid);
      contentTypeTitle = (contentTypeValue as TaskInterface).title;
      break;

    default:
      break;
  }

  return { contentTypeCode, contentTypeValue, contentTypeLink, contentTypeDisplayName, contentTypeTitle };
};

export const useEntityWorkspace = (entity: MultiRelationalEntityCommonFields) => {
  const workspaceId = entity?.workspace?.id ||
    entity?.board?.workspace ||
    entity?.task?.board?.workspace ||
    null;

  const workspace = useWorkspaceById(workspaceId || "");

  return { workspace, workspaceId };
};

export const extractFilterRelation = (filter: EntityQueryPayload) => {
  let contentTypeCode, contentTypeId;

  for (const [key, value] of Object.entries(pick(filter, MultiRelationalEntityKeys))) {
    if (value !== null && value !== undefined) {
      contentTypeCode = key;
      contentTypeId = value;
      break;
    }
  }

  const contentTypeFilter = (!!contentTypeCode && !!contentTypeId) ? { contentType: contentTypeCode, id: contentTypeId as string } : null;

  return { contentTypeCode, contentTypeId, contentTypeFilter };
};
