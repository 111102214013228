import styled from "@emotion/styled/macro";

export const AttachmentContainerLink = styled.a`
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
`;

export const AttachmentContainerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 14px;
  margin-top: 6px;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  transition: border 200ms ease;
  cursor: pointer;
  width: 100%;
  margin-bottom: 8px;
  &:hover {
    border: 1px solid #cdcdcd;
  }
`;

export const AttachmentContainerIcon = styled.div`
  margin-right: 12px;
`;

export const AttachmentContainerName = styled.div`
  display: flex;
  flex-direction: column;
  color: #333333;
`;
