import { EntityId } from "lib/types/entity";
import { captureException } from "lib/utils/sentry";
import useTaskPerformRequest, { TaskPerformRequestHookProps } from "./useTaskPerformRequest";
import TasksService from "services/TasksService";

const useTaskSetAnnotations = (props: TaskPerformRequestHookProps) => {
  const { task } = props;
  const performRequest = useTaskPerformRequest(props);

  const deleteAnnotation = (annotationId: EntityId) => {
    if (!task) {
      return;
    }

    const annotationIndex = task.annotations.findIndex(annotation => String(annotation.id) === String(annotationId));
    if (annotationIndex === -1) {
      captureException(`Cannot find annotation ${annotationId}`);
      return;
    }

    const annotations = Array.from(task.annotations);
    annotations.splice(annotationIndex, 1);

    const requestFn = () => TasksService.deleteAnnotation(task.id, annotationId);

    performRequest({ annotations }, requestFn);
  };

  return { deleteAnnotation };
};

export default useTaskSetAnnotations;
