import { FC } from "react";

import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import { TaskPropertyOption } from "lib/types/kanbanTypes";

import SingleSelectGeneric from "./Display/generic";

import { SingleSelectListWrapper, SingleSelectDetailedWrapper } from "./styled";
import { DefaultColumnCodes } from "lib/types/boardTypes";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import { useBoardContext } from "lib/contexts/BoardContext";
import { useTaskContext } from "lib/contexts/TaskContext";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";

interface SingleSelectFieldProps extends TaskFieldPropsGeneric {
  options?: Array<TaskPropertyOption>;
  boardStatuses?: Array<any>;
  propertyType: TaskPropertyClass;
}

const SingleSelectField: FC<SingleSelectFieldProps> = (props) => {
  const { code, value, setValue, component, options, boardStatuses, propertyType } = props;

  const { board } = useBoardContext();
  const { task } = useTaskContext();

  const isStatusField = (code === DefaultColumnCodes.Status);
  const isSyncedBoard = !!board?.externalDataSource || !!task?.board?.externalDataSource;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const editDisabled = (
    !allowedToEdit ||
    ((props.propertyType === TaskPropertyClass.Synced) && isSyncedBoard)
  );

  const fieldOptions = boardStatuses || options;
  const currentOption = fieldOptions?.find((option: any) => String(option.id) === String(value)) || undefined;

  const selectOptions = {
    value,
    code,
    component,
    setValue,
    options: fieldOptions,
    selectedOption: currentOption,
    propertyType
  };

  switch (component) {
    case TaskSourceComponent.Card:
      return null;
    case TaskSourceComponent.Cell:
      return (
        <SingleSelectListWrapper clickable={!editDisabled}>
          <SingleSelectGeneric {...selectOptions} isStatusField={isStatusField} />
        </SingleSelectListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <SingleSelectDetailedWrapper clickable={!editDisabled}>
          <SingleSelectGeneric {...selectOptions} isStatusField={isStatusField} />
        </SingleSelectDetailedWrapper>
      );
    default:
      return null;
  }
};

export default SingleSelectField;
