import { FC } from "react";
import { UserInterface } from "lib/types/types";
import AvatarCustom from "../AvatarCustom";
import {
  UserCustomWrapper,
  UserCustomAvatar,
  UserCustomName
} from "./styled";

interface UserCustomProps {
  user: UserInterface | null;
  name: string | undefined;
}

const UserCustom: FC<UserCustomProps> = (props) => {
  return (
    <>
      <UserCustomWrapper>
        <UserCustomAvatar>
          <AvatarCustom user={props.user} size="small" />
        </UserCustomAvatar>
        <UserCustomName>
          {props.name}
        </UserCustomName>
      </UserCustomWrapper>
    </>
  );
};

export default UserCustom;
