import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { IconProps } from "components/icons/lib";

export const IconSvgContainer = styled.div<IconProps>`
  ${({ size }) => size && css`
    width: ${size}px;
    height: ${size}px;
    svg {
      display: block;
      width: ${size}px;
      height: ${size}px;
    }
  `}
  ${({ color }) => color && css`
    path {
      fill: ${color};
    }
  `}
`;
