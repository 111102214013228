import { EntityId } from "lib/types/entity";
import { ChangelogItemType, CommentInterface } from "lib/types/types";
import { captureException } from "lib/utils/sentry";
import TasksService from "services/TasksService";
import useTaskPerformRequest, { TaskPerformRequestHookProps } from "./useTaskPerformRequest";

const useTaskSetComments = (props: TaskPerformRequestHookProps) => {
  const { task } = props;
  const performRequest = useTaskPerformRequest(props);

  const updateComment = (commentId: EntityId, data: Partial<CommentInterface>) => {
    if (!task) {
      return;
    }

    const commentIndex = task.changelog?.findIndex(s => (String(s.id) === String(commentId)) && (s.item === ChangelogItemType.Comment));
    if (commentIndex === -1) {
      captureException(`Cannot find comment ${commentId}`);
      return;
    }

    const changelog = Array.from(task.changelog);
    const comment = changelog[commentIndex];
    changelog[commentIndex] = { ...comment, ...data };

    const requestFn = () => TasksService.editComment(task.id, commentId, data);

    return performRequest({ changelog }, requestFn);
  };

  const deleteComment = (commentId: EntityId) => {
    if (!task) {
      return;
    }

    const commentIndex = task.changelog?.findIndex(s => (String(s.id) === String(commentId)) && (s.item === ChangelogItemType.Comment));
    if (commentIndex === -1) {
      captureException(`Cannot find comment ${commentId}`);
      return;
    }

    const changelog = Array.from(task.changelog);
    changelog.splice(commentIndex, 1);

    const requestFn = () => TasksService.deleteComment(task.id, commentId);

    return performRequest({ changelog }, requestFn);
  };

  return { updateComment, deleteComment };
};

export default useTaskSetComments;
