import { FC } from "react";
import { CheckPlaceholderContainer, CheckPlaceholderDot } from "pages/Task/TaskCheckmark/styled";
import { Tooltip } from "antd";

const CheckPlaceholder: FC = (props) => {
  return (
    <Tooltip title="This field is disabled in the board settings.">
      <CheckPlaceholderContainer>
        <CheckPlaceholderDot />
      </CheckPlaceholderContainer>
    </Tooltip>
  );
};

export default CheckPlaceholder;
