import { useAppSelector } from "app/store";
import { extractFilterFromCtxParams } from "lib/helpers/navigationUtils";
import { EntityId } from "lib/types/entity";
import { WorkspaceInterface } from "../types/types";

const useWorkspaceById = (id: EntityId | null): WorkspaceInterface | undefined => {
  const filter = extractFilterFromCtxParams(id);

  return useAppSelector(state =>
    state.workspaces.workspaces.find(item => String(item[filter.code]) === String(id))
  );
};

export default useWorkspaceById;
