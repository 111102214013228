import styled from "@emotion/styled/macro";
import { css } from "@emotion/css";

export const CheckmarkButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => disabled ? "default" : "pointer"};
  margin-right: 6px;
  transition: opacity 200ms ease;
  &:hover {
    opacity: ${({ disabled }) => disabled ? "100%" : "65%"};
  }
`;

export const CheckmarkControlsWrapper = styled.div`
  font-size: 13px;
  margin-top: 1px;
`;

export const completedButtonClass = css`
  background-color: white !important;
  color: #00BF9C !important;
  transition: border 200ms ease !important;
  path {
    fill: #00BF9C; !important;
  }
  &:hover {
    border: 1px solid rgb(111, 228, 201);
  }
`;

export const CheckPlaceholderContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  margin-right: 6px;
`;

export const CheckPlaceholderDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #bfbfbf;
  opacity: 0.5;
`;
