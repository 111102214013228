import { Modal } from "antd";
import { commonModalProps } from "components/blocks/ModalDialog/lib";
import { ModalComponentProps } from "lib/types/components";
import { WorkspaceInterface } from "lib/types/types";
import { FC, useEffect, useState } from "react";
import { TaskModalCreateContainer } from "../TaskModalCreateAnnotation/styled";
import CreateFlowForm from "../CreateFlowForm";
import { useForm } from "antd/lib/form/Form";
import { EntityId } from "lib/types/entity";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import TasksService from "services/TasksService";
import LoadingMessage from "components/blocks/LoadingMessage";
import { BoardInterface } from "lib/types/boardTypes";

interface TaskModalCreateBoardProps extends ModalComponentProps {
  workspace: WorkspaceInterface | null | undefined;
  currentBoard: EntityId | null;
  type: "workspace" | "board" | "mytasks";
}

const TaskModalCreate: FC<TaskModalCreateBoardProps> = (props) => {
  const { visible, onClose, workspace, currentBoard, type } = props;

  const [form] = useForm();
  const [selectedBoard, setSelectedBoard] = useState(currentBoard);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setSelectedBoard(currentBoard);
  }, [currentBoard]);

  const boards = workspace?.boards || [];
  const selected = boards.find(board => board.id === selectedBoard);

  const { terminology } = useBoardFeatures(selected as BoardInterface);
  const modalTitle = "Create new " + terminology?.single.toLowerCase();

  const handleOnOk = async () => {
    form.validateFields()
      .then(async (values) => {
        if (!selectedBoard || !values.title) {
          throw new Error();
        }

        setIsSubmitting(true);

        const task = await TasksService.addNewTask({
          title: values.title,
          board: selectedBoard,
          ...values,
        });

        setIsSubmitting(false);
        setSelectedBoard(currentBoard);

        form.resetFields();
        onClose();
      })
      .catch(error => {
        console.log("form.validateFields: error", error);
        LoadingMessage.error("Looks like you missed some required fields");
      });
  };

  return (
    <Modal
      {...commonModalProps}
      title={modalTitle}
      open={visible}
      onOk={handleOnOk}
      okText="Create"
      okButtonProps={{ disabled: !selectedBoard }}
      cancelButtonProps={{ disabled: isSubmitting }}
      onCancel={onClose}
      confirmLoading={isSubmitting}
      bodyStyle={{ maxHeight: "60vh", overflowY: "scroll", padding: "6px 24px" }}
      centered
      destroyOnClose
    >
      <TaskModalCreateContainer>
        <CreateFlowForm
          form={form}
          workspace={workspace}
          initialBoard={currentBoard}
          selectedBoard={selectedBoard}
          onChangeBoard={setSelectedBoard}
          type={type}
        />
      </TaskModalCreateContainer>
    </Modal>
  );
};

export default TaskModalCreate;
