import { pick } from "lodash";
import { EntityId } from "lib/types/entity";
import { TaskSubtaskInterface } from "lib/types/tasksTypes";
import TasksService from "services/TasksService";
import useTaskPerformRequest, { TaskPerformRequestHookProps } from "./useTaskPerformRequest";

const useTaskSetSubtasks = (props: TaskPerformRequestHookProps) => {
  const { task } = props;
  const performRequest = useTaskPerformRequest(props);

  const createSubtask = (data: Partial<TaskSubtaskInterface>) => {
    if (!task) {
      return;
    }
    const subtasks = Array.from(task.subtasks);
    subtasks.push(data as unknown as TaskSubtaskInterface);

    const requestFn = () => TasksService.createSubtask(task.id, data);

    return performRequest({ subtasks }, requestFn);
  };

  const createSubtasks = (data: Partial<TaskSubtaskInterface>[]) => {
    if (!task) {
      return;
    }
    const subtasks = [...task.subtasks, ...(data as unknown as TaskSubtaskInterface[])];

    const requestFn = () => TasksService.createSubtasksBatch(task.id, data);

    return performRequest({ subtasks }, requestFn);
  };

  const updateSubtask = (subtaskId: EntityId, data: Partial<TaskSubtaskInterface>) => {
    if (!task) {
      return;
    }

    const subtaskIndex = task.subtasks.findIndex(s => String(s.id) === String(subtaskId));
    if (subtaskIndex === -1) {
      return;
    }

    const subtasks = Array.from(task.subtasks);
    const subtask = subtasks[subtaskIndex];
    subtasks[subtaskIndex] = { ...subtask, ...data };

    const requestFn = () => TasksService.updateSubtask(task.id, subtaskId, data);

    return performRequest({ subtasks }, requestFn);
  };

  const deleteSubtask = (subtaskId: EntityId) => {
    if (!task) {
      return;
    }

    const subtaskIndex = task.subtasks.findIndex(s => String(s.id) === String(subtaskId));
    if (subtaskIndex === -1) {
      return;
    }

    const subtasks = Array.from(task.subtasks);
    subtasks.splice(subtaskIndex, 1);

    const requestFn = () => TasksService.deleteSubtask(task.id, subtaskId);

    return performRequest({ subtasks }, requestFn);
  };

  const reorderSubtasks = (data: Array<TaskSubtaskInterface>) => {
    if (!task) {
      return;
    }

    const payload = data.map(item => pick(item, ["id", "sort"]));
    const requestFn = () => TasksService.reorderSubtasks(task.id, payload);

    return performRequest({ subtasks: data }, requestFn);
  };

  return { createSubtask, createSubtasks, updateSubtask, deleteSubtask, reorderSubtasks };
};

export default useTaskSetSubtasks;
