import { useCallback } from "react";
import { useAppDispatch } from "app/store";
import { addTask } from "app/slices/tasksSlice";
import { TaskInterface } from "lib/types/tasksTypes";
import { EntityId } from "lib/types/entity";
import { WsEvents } from "lib/types/wsTypes";

import useWSSubscription from "../useWSSubscription";

const useWSCreateTask = (currentBoardId?: EntityId) => {
  const dispatch = useAppDispatch();

  const callback = useCallback((data: TaskInterface) => {
    if (!currentBoardId) {
      dispatch(addTask(data));
    } else if (String(data.board.id) === String(currentBoardId)) {
      dispatch(addTask(data));
    }
  }, [currentBoardId, dispatch]);

  useWSSubscription<TaskInterface>({
    eventName: WsEvents.Task.Create,
    callback,
    fnKey: "useWSCreateTask"
  });
};

export default useWSCreateTask;
