import { useCallback } from "react";
import { useAppDispatch } from "app/store";
import { deleteTask } from "app/slices/tasksSlice";
import { TaskInterface } from "lib/types/tasksTypes";
import { WsEvents } from "lib/types/wsTypes";
import useWSSubscription from "../useWSSubscription";

const useWSDeleteTask = () => {
  const dispatch = useAppDispatch();

  const callback = useCallback((data: TaskInterface) => {
    dispatch(deleteTask(data));
  }, [dispatch]);

  useWSSubscription<TaskInterface>({
    eventName: WsEvents.Task.Delete,
    callback,
    fnKey: "useWSDeleteTask"
  });
};

export default useWSDeleteTask;
