import BooleanField from "components/blocks/TaskFields/Components/Boolean";
import CurrencyField from "components/blocks/TaskFields/Components/Currency";
import DateField from "components/blocks/TaskFields/Components/Date";
import FilesField from "components/blocks/TaskFields/Components/Files";
import IdField from "components/blocks/TaskFields/Components/Id";
import LinkField from "components/blocks/TaskFields/Components/Link";
import MultiLineTextField from "components/blocks/TaskFields/Components/MultiLineText";
import MultiSelectField from "components/blocks/TaskFields/Components/MultiSelect";
import NumberField from "components/blocks/TaskFields/Components/Number";
import PercentField from "components/blocks/TaskFields/Components/Percent";
import ProgressField from "components/blocks/TaskFields/Components/Progress";
import SingleLineTextField from "components/blocks/TaskFields/Components/SingleLineText";
import SingleSelectField from "components/blocks/TaskFields/Components/SingleSelect";
import UrlField from "components/blocks/TaskFields/Components/Url";
import UserField from "components/blocks/TaskFields/Components/User";
import UsersField from "components/blocks/TaskFields/Components/Users";
import ViewpointsField from "components/blocks/TaskFields/Components/Viewpoints";
import TaskTitle from "components/blocks/TaskTable/components/TaskTitle";
import TaskTitleLite from "components/blocks/TaskTable/components/TaskTitleLite";
import BarcodeField from "components/blocks/TaskFields/Components/Barcode";
import AnnotationsField from "components/blocks/TaskFields/Components/Annotations";
import { TableElementType, TaskPropertyClass } from "components/blocks/TaskTable/constants";
import useTaskSetValue from "lib/customHooks/dataLayer/task/useTaskSetValue";
import useSavingState from "lib/customHooks/useSavingState";
import { DisplayView, TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import { BoardInterface, DefaultColumnCodes } from "lib/types/boardTypes";
import { EntityId } from "lib/types/entity";
import { TaskInterface, TaskSetValueHandler } from "lib/types/tasksTypes";
import { isEqual } from "lodash";
import { FC, memo, useCallback } from "react";
import { CellProps } from "react-table";

import TasksService from "services/TasksService";
import { DummyRowId } from "../../utils/listReducer";
import DateTimeField from "components/blocks/TaskFields/Components/DateTime";

interface TableCellProps extends CellProps<TaskInterface & { boardData?: BoardInterface }> {
  lite?: boolean;
  customization?: () => void;
  boardId: EntityId;
  afterCreate?: () => void;
  task: TaskInterface;
  onItemExpand?: (itemId: string) => void;
}

const Cell: FC<TableCellProps> = (props) => {
  const {
    value,
    row: { original },
    column: { type, code, options, class: propertyType, precision, currency, dateFormat, timeFormat },
    dragHandleProps,
    lite,
    customization,
    boardId,
    afterCreate,
    onItemExpand,
  } = props;

  const { setTaskValue } = useTaskSetValue({ task: original });
  const { setSavingStart, setSavingFinish } = useSavingState();

  const setValue: TaskSetValueHandler = useCallback(({ cellName, cellValue }) => {
    const isTaskExist = (original?.id !== DummyRowId);

    if (isTaskExist) {
      setTaskValue({ cellName, cellValue });
    } else {
      setSavingStart();
      const payload = {
        board: boardId,
        [cellName]: cellValue,
      };

      return TasksService.addNewTask(payload)
        .finally(() => {
          setSavingFinish();
          if (afterCreate) {
            afterCreate();
          }
        });
    }
  }, [afterCreate, boardId, original.id, setSavingFinish, setSavingStart, setTaskValue]);

  const displayView = DisplayView.List;
  const defaultCellProps: TaskFieldPropsGeneric = {
    component: TaskSourceComponent.Cell,
    value,
    setValue,
    displayView,
    code,
    taskId: original.id,
    propertyType: propertyType as TaskPropertyClass,
  };

  if (code === DefaultColumnCodes.Title) {
    return (lite)
      ? <TaskTitleLite {...defaultCellProps} afterCreate={afterCreate} onExpand={onItemExpand} />
      : <TaskTitle {...defaultCellProps} afterCreate={afterCreate} onExpand={onItemExpand} dragHandleProps={dragHandleProps} />;
  }

  if (Object.values(original).length < 2) {
    return null;
  }

  if (code === DefaultColumnCodes.Status) {
    defaultCellProps.boardStatuses = original.boardData?.statuses;
  }

  const CellsProps: Record<string, any> = {
    [TableElementType.Currency]: { currency, precision },
    [TableElementType.Date]: { isFinal: original.completed, dateFormat, timeFormat },
    [TableElementType.DateTime]: { isFinal: original.completed, dateFormat, timeFormat },
    [TableElementType.MultiSelect]: { options },
    [TableElementType.Number]: { precision },
    [TableElementType.Percent]: { precision },
    [TableElementType.SingleSelect]: { options },
    [TableElementType.User]: { workspaceId: original.board?.workspace },
    [TableElementType.Users]: { workspaceId: original.board?.workspace },
    [TableElementType.Progress]: { subtasks: original.subtasks }
  };

  let CellsTypes: Record<string, any> = {
    [TableElementType.addBtn]: null,
    [TableElementType.Boolean]: BooleanField,
    [TableElementType.Currency]: CurrencyField,
    [TableElementType.Date]: DateField,
    [TableElementType.DateTime]: DateTimeField,
    [TableElementType.Element]: null,
    [TableElementType.Elements]: null,
    [TableElementType.File]: null,
    [TableElementType.Files]: FilesField,
    [TableElementType.Formula]: null,
    [TableElementType.Guid]: null,
    [TableElementType.Id]: IdField,
    [TableElementType.MultiLineText]: MultiLineTextField,
    [TableElementType.MultiSelect]: MultiSelectField,
    [TableElementType.Number]: NumberField,
    [TableElementType.Percent]: PercentField,
    [TableElementType.SingleLineText]: SingleLineTextField,
    [TableElementType.SingleSelect]: SingleSelectField,
    [TableElementType.User]: UserField,
    [TableElementType.Users]: UsersField,
    [TableElementType.Viewpoint]: null,
    [TableElementType.Viewpoints]: ViewpointsField,
    [TableElementType.Link]: LinkField,
    [TableElementType.Url]: UrlField,
    [TableElementType.Progress]: ProgressField,
    [TableElementType.Barcode]: BarcodeField,
    [TableElementType.Annotations]: AnnotationsField,
  };

  if (customization) {
    CellsTypes = { ...CellsTypes, ...customization };
  }

  const Component = CellsTypes[type];
  if (Component) {
    return <Component {...defaultCellProps} {...CellsProps[type]} />;
  }

  return null;
};

const customEqual = (prevProps: TableCellProps, nextProps: TableCellProps) => {
  return (
    prevProps.task.updated_at === nextProps.task.updated_at
    && prevProps.task.id === nextProps.task.id
    && isEqual(prevProps.column, nextProps.column)
  );
};

export default memo(Cell, customEqual);
