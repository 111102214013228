import * as H from "history";

export const constructQueryString = (queryParams: Record<string, any>) => {
  const query = new URLSearchParams();

  for (const key in queryParams) {
    if (queryParams[key]) {
      query.append(key, queryParams[key]);
    }
  }

  return query.toString();
};

export const appendSearchParams = (params?: Record<string, string>) => {
  if (!params) {
    return "";
  } else {
    const queryString = constructQueryString(params);
    return `?${queryString}`;
  }
};

export const extractQueryParams = <DataShape extends Record<string, string>>(prefix = "") => {
  const queryValues: Partial<DataShape> = {};
  const queryParams = new URLSearchParams(window.location.search);

  queryParams.forEach((value, key) => {
    if (key.startsWith(prefix)) {
      const formKey = key.replace(prefix, "");
      queryValues[formKey as keyof DataShape] = value as DataShape[keyof DataShape];
    }
  });

  return queryValues;
};

export const setQueryParams = (history: H.History, params: Record<string, string>) => {
  const search = constructQueryString(params);
  history.replace({ pathname: window.location.pathname, search: search });
};

export const unsetQueryParams = (history: H.History) => {
  history.replace({ pathname: window.location.pathname });
};

export const HttpsRegExp = /^https?:\/\//i;
