import styled from "@emotion/styled/macro";
import { fieldDetailedHover, fieldDetailedWrapper } from "components/blocks/TaskFields/Components/styled";

export const DateTimePickerFieldOuterContainer = styled.div`
  ${fieldDetailedWrapper};
  ${fieldDetailedHover};
  display: inline-block;
`;

export const DateTimePickerFieldInnerContainer = styled.div`
  display: flex;
  align-items: center;
`;
