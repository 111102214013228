import React from "react";

import CannySDK from "./CannySDK";
import configuration from "lib/configs/configuration";
import { getUserFullName } from "lib/helpers/userUtils";
import { UserInterface } from "lib/types/types";

interface CannyIdentifyProps {
  user: UserInterface | null;
}

export default class CannyIdentify extends React.Component<CannyIdentifyProps> {

  componentDidMount() {
    CannySDK.init();
    const user = this.props.user;

    if (user) {
      // @ts-ignore
      Canny("identify", {
        appID: configuration.canny.appId,
        user: {
          email: user?.email,
          name: getUserFullName(user),
          id: user?.id,
          avatarURL: user?.picture?.url || false,
          created: new Date(user.created_at).toISOString()
        },
      });
    }
  }

  render() {
    return this.props.children;
  }
}
