import { useAppSelector } from "app/store";
import TaskCalendar from "components/blocks/TaskCalendar";
import useCustomColumnsFilters from "lib/customHooks/filters/useCustomColumnsFilters";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { listFilters } from "lib/filters/filters";
import { getSortFields } from "lib/sort/tasksSort";
import { MemberRoles } from "lib/types/types";
import { BoardCalendarContainer } from "pages/Board/BoardCalendar/styled";
import { FC } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { PageContainer } from "styles/common";
import PageLoader from "components/blocks/PageLoader";
import SecondaryBar from "../SecondaryBar";

const BoardCalendar: FC = () => {
  const isLoading = useAppSelector(state => state.tasks.isLoading);
  const board = useAppSelector(state => state.currentBoardReducer.board);
  const { allFilters } = useCustomColumnsFilters(listFilters);
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const allowedToCreate = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  return (
    <>
      <SecondaryBar
        filters={allFilters}
        sortFields={getSortFields(board)}
        hideAutomations={!allowedToEdit}
        hideAddRecord={!allowedToCreate}
        showHideFields={allowedToEdit}
        type="board"
      />
      <Scrollbars autoHide>
        <PageContainer>
          {isLoading && <PageLoader />}
          {!isLoading &&
            <BoardCalendarContainer>
              <TaskCalendar />
            </BoardCalendarContainer>
          }
        </PageContainer>
      </Scrollbars>
    </>
  );
};

export default BoardCalendar;
