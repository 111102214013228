import moment from "moment";

import { TaskPropertyDateTimeFormat, TaskPropertyTimeFormat } from "lib/types/kanbanTypes";
import { DateFormats, TimeFormats } from "lib/theme/lib";

const today = moment.now();

export const DateFieldFormats = [
  {
    name: "Default (Today)",
    format: DateFormats.Default,
    value: TaskPropertyDateTimeFormat.Default,
    id: TaskPropertyDateTimeFormat.Default,
  },
  {
    name: `Friendly (${moment(today).format(DateFormats.Friendly)})`,
    format: DateFormats.Friendly,
    value: TaskPropertyDateTimeFormat.Friendly,
    id: TaskPropertyDateTimeFormat.Friendly,
  },
  {
    name: `US (${moment(today).format(DateFormats.US)})`,
    format: DateFormats.US,
    value: TaskPropertyDateTimeFormat.Usa,
    id: TaskPropertyDateTimeFormat.Usa,
  },
  {
    name: `Europe (${moment(today).format(DateFormats.Europe)})`,
    format: DateFormats.Europe,
    value: TaskPropertyDateTimeFormat.Europe,
    id: TaskPropertyDateTimeFormat.Europe,
  },
  {
    name: `ISO (${moment(today).format(DateFormats.ISO)})`,
    format: DateFormats.ISO,
    value: TaskPropertyDateTimeFormat.Iso,
    id: TaskPropertyDateTimeFormat.Iso,
  },
];

export const TimeFieldFormats = [
  {
    name: "Disabled",
    format: TimeFormats.None,
    value: TaskPropertyTimeFormat.None,
    id: TaskPropertyTimeFormat.None,
  },
  {
    name: `12 hour (${moment(today).format(TimeFormats.US)})`,
    format: TimeFormats.US,
    value: TaskPropertyTimeFormat.Usa,
    id: TaskPropertyTimeFormat.Usa,
  },
  {
    name: `24 hour (${moment(today).format(TimeFormats.Europe)})`,
    format: TimeFormats.Europe,
    value: TaskPropertyTimeFormat.Europe,
    id: TaskPropertyTimeFormat.Europe,
  },
];
