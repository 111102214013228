import React, { FC, useState } from "react";
import { Select, Modal, Switch } from "antd";

import configuration from "lib/configs/configuration";
import { Column, TaskExportFormats, TaskRequestExportOptions } from "lib/types/tasksTypes";
import { PropertyValueType } from "lib/types/dataTypes";
import { fetchDownload } from "lib/helpers/fetchDownload";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";

import CustomStyledButton from "components/blocks/CustomStyledButton";
import { StyledButton } from "styles/common";
import { ExportModalOptionWrapper, ExportModalHeading } from "./styled";
import { ExportBoardDefaultColumnSet, ExportBoardFilterOptions, ExportBoardIncludeOptions, ExportBoardOptionChangeHandler } from "./lib";
import { EntityId } from "lib/types/entity";
import { ModalComponentProps } from "lib/types/components";
import { DisplayView } from "lib/types/applicationTypes";
import { getDownloadFilename } from "lib/helpers/stringUtils";
import { captureException } from "lib/utils/sentry";
import { commonModalProps } from "../ModalDialog/lib";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

interface ExportBoardModalProps extends ModalComponentProps {
  boardId: EntityId;
}

const ExportBoardModal: FC<ExportBoardModalProps> = (props) => {
  const { board } = useBoardContext() as BoardContextInterface;
  const { isCompletionEnabled, excludedColumns } = useBoardFeatures(board);

  const initialState: TaskRequestExportOptions = {
    groupBy: undefined,
    include: {},
    filterBy: {
      completed: isCompletionEnabled,
    },
    view: null,
  };

  const [selectedFormat, setSelectedFormat] = useState<TaskExportFormats>(TaskExportFormats.Pdf);
  const [selectedOptions, setSelectedOptions] = useState<TaskRequestExportOptions>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const additionalOptionsAvailable = [TaskExportFormats.Pdf, TaskExportFormats.Viewpoints].includes(selectedFormat);

  const exportColumns: Array<Partial<Column>> = (board?.columns || [])
    .filter(column => [PropertyValueType.Boolean, PropertyValueType.SingleSelect].includes(column.type as PropertyValueType))
    .filter(column => !excludedColumns.includes(column.code))
    || ExportBoardDefaultColumnSet;

  const handleSelectFormatOnChange = (value: string) => {
    setSelectedFormat(value as TaskExportFormats);
  };

  const handleSelectOptionOnChange = (value: string) => {
    const nextState: TaskRequestExportOptions = {
      ...selectedOptions,
      groupBy: value,
    };
    setSelectedOptions(nextState);
  };

  const handleSelectViewOnChange = (value: string) => {
    const nextState: TaskRequestExportOptions = {
      ...selectedOptions,
      view: value,
    };
    setSelectedOptions(nextState);
  };

  const handleIncludeOptionOnChange: ExportBoardOptionChangeHandler = (checked, property) => {
    const nextState: TaskRequestExportOptions = {
      ...selectedOptions,
      include: {
        ...selectedOptions.include,
        [property]: checked,
      },
    };
    setSelectedOptions(nextState);
  };

  const handleFilterOptionOnChange: ExportBoardOptionChangeHandler = (checked, property) => {
    const nextState: TaskRequestExportOptions = {
      ...selectedOptions,
      filterBy: {
        ...selectedOptions.filterBy,
        [property]: checked
      },
    };
    setSelectedOptions(nextState);
  };

  const exportBoard = async () => {
    const path = `${configuration.backend.url}tasks/${board?.id}/board/export/${selectedFormat}`;
    const fileName = getDownloadFilename(board?.name, selectedFormat);

    await fetchDownload(path, fileName, selectedOptions);
  };

  const handleExportOnClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await exportBoard();
    } catch (error) {
      captureException(error, true);
    }
    setIsLoading(false);
    props.onClose();
  };

  return (
    <Modal
      {...commonModalProps}
      destroyOnClose
      title="Export board"
      visible={props.visible}
      onOk={props.onClose}
      onCancel={props.onClose}
      width={325}
      footer={[
        <div key="cancel" style={{ display: "inline-block", marginRight: "10px" }}>
          <StyledButton onClick={props.onClose}>
            Cancel
          </StyledButton>
        </div>,
        <CustomStyledButton
          key="ok"
          value="Export"
          btnColor="purple"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={handleExportOnClick}
        />
      ]}
    >
      <ExportModalOptionWrapper>
        <ExportModalHeading htmlFor="exportFormat">
          Export format
        </ExportModalHeading>
        <Select
          id="exportFormat"
          size="small"
          placement="bottomRight"
          bordered={false}
          dropdownStyle={{ minWidth: 150 }}
          value={selectedFormat}
          onChange={handleSelectFormatOnChange}
          optionLabelProp="label"
        >
          <Select.Option key={TaskExportFormats.Pdf} value={TaskExportFormats.Pdf} label="PDF">PDF</Select.Option>
          <Select.Option key={TaskExportFormats.Csv} value={TaskExportFormats.Csv} label="CSV">CSV</Select.Option>
          <Select.Option key={TaskExportFormats.Xlsx} value={TaskExportFormats.Xlsx} label="XLSX">XLSX</Select.Option>
          <Select.Option key={TaskExportFormats.Viewpoints} value={TaskExportFormats.Viewpoints} label="XML">XML (Viewpoints)</Select.Option>
        </Select>
      </ExportModalOptionWrapper>
      {additionalOptionsAvailable &&
        <>
          {!!board?.views?.length &&
            <ExportModalOptionWrapper key="filterView">
              <ExportModalHeading htmlFor="filterView">
                Select view
              </ExportModalHeading>
              <Select
                id="filterView"
                allowClear
                size="small"
                placement="bottomRight"
                bordered={false}
                placeholder="Select view"
                style={{ maxWidth: 150 }}
                dropdownStyle={{ minWidth: 150 }}
                onChange={handleSelectViewOnChange}
              >
                {board?.views
                  .filter(view => view.view !== DisplayView.Form)
                  .map(view => (
                    <Select.Option key={view.uid} value={view.uid}>{view.title}</Select.Option>
                  ))}
              </Select>
            </ExportModalOptionWrapper>
          }
          <ExportModalOptionWrapper key="groupBy">
            <ExportModalHeading htmlFor="groupBy">
              Group by
            </ExportModalHeading>
            <Select
              id="groupBy"
              allowClear
              size="small"
              placement="bottomRight"
              bordered={false}
              placeholder="Select field"
              dropdownStyle={{ minWidth: 150 }}
              onChange={handleSelectOptionOnChange}
            >
              {exportColumns?.map(column => (
                <Select.Option key={column.id} value={column.code}>{column.title}</Select.Option>
              ))}
            </Select>
          </ExportModalOptionWrapper>
          {[TaskExportFormats.Pdf].includes(selectedFormat) &&
            <>
              {ExportBoardFilterOptions.map(option => {
                const disabledRule = (option.code === "completed") && (selectedOptions.view || !isCompletionEnabled);
                if (disabledRule) {
                  return null;
                }

                return (
                  <ExportModalOptionWrapper key={option.code}>
                    <ExportModalHeading htmlFor={option.code}>
                      {option.text}
                    </ExportModalHeading>
                    <Switch
                      id={option.code}
                      style={{ marginRight: 10 }}
                      size="small"
                      onChange={checked => handleFilterOptionOnChange(!checked, option.code)}
                    />
                  </ExportModalOptionWrapper>
                );
              })}
              {ExportBoardIncludeOptions.map(option => (
                <ExportModalOptionWrapper key={option.code}>
                  <ExportModalHeading htmlFor={option.code}>
                    {option.text}
                  </ExportModalHeading>
                  <Switch
                    id={option.code}
                    style={{ marginRight: 10 }}
                    size="small"
                    onChange={checked => handleIncludeOptionOnChange(checked, option.code)}
                  />
                </ExportModalOptionWrapper>
              ))}
            </>
          }
        </>
      }
    </Modal>
  );
};

export default ExportBoardModal;
