import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const PubSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.3901 20.4357C64.1212 20.4335 61.9457 19.5315 60.3412 17.9275C58.7362 16.3236 57.8333 14.1485 57.83 11.8796V0H19.5851C16.6237 0 13.7837 1.17644 11.6896 3.27047C9.59558 5.36448 8.41919 8.20452 8.41919 11.1659V78.4772C8.42359 81.4357 9.60194 84.2714 11.6955 86.3618C13.789 88.4523 16.6266 89.6266 19.5851 89.6266H65.4773C68.4387 89.6266 71.2789 88.4502 73.3727 86.356C75.4669 84.2622 76.6432 81.422 76.6432 78.4606V20.4191L66.3901 20.4357Z" fill="#FF3E4C"/>
        <path d="M76.6431 20.4357H66.39C64.1211 20.4335 61.9456 19.5315 60.341 17.9275C58.7361 16.3236 57.8332 14.1485 57.8298 11.8796V0L76.6431 20.4357Z" fill="#FF3E4C"/>
        <path d="M84.7428 70.7925H32.8589C29.1786 70.7925 26.1951 73.7759 26.1951 77.4564V92.9253C26.1951 96.6058 29.1786 99.5892 32.8589 99.5892H84.7428C88.4233 99.5892 91.4067 96.6058 91.4067 92.9253V77.4564C91.4067 73.7759 88.4233 70.7925 84.7428 70.7925Z" fill="#FF3E4C"/>
        <path d="M40.0939 93.361C39.5013 93.361 39.2051 93.0648 39.2051 92.4722V79.9021C39.2051 79.3096 39.5013 79.0133 40.0939 79.0133H45.0457C46.3366 79.0133 47.3242 79.359 48.0084 80.0502C48.6997 80.7345 49.0453 81.722 49.0453 83.0129V83.6477C49.0453 84.9386 48.6997 85.9295 48.0084 86.6208C47.3242 87.305 46.3366 87.6473 45.0457 87.6473H40.9827V92.4722C40.9827 93.0648 40.6864 93.361 40.0939 93.361ZM40.9827 85.8697H45.0457C45.8499 85.8697 46.4213 85.7004 46.7599 85.3619C47.0984 85.0233 47.2677 84.4519 47.2677 83.6477V83.0129C47.2677 82.2158 47.0984 81.6477 46.7599 81.3092C46.4213 80.9635 45.8499 80.7909 45.0457 80.7909H40.9827V85.8697Z" fill="white"/>
        <path d="M56.8174 93.361C55.202 93.361 54.0066 92.9764 53.2307 92.2075C52.4618 91.4316 52.0771 90.2361 52.0771 88.6208V79.9021C52.0771 79.6058 52.1514 79.3834 52.2996 79.2353C52.4477 79.0872 52.6697 79.0133 52.9659 79.0133C53.2622 79.0133 53.4846 79.0872 53.6328 79.2353C53.7809 79.3834 53.8547 79.6058 53.8547 79.9021V88.6208C53.8547 89.3756 53.9431 89.9681 54.1195 90.3984C54.3029 90.8287 54.6095 91.1353 55.0398 91.3187C55.4701 91.4951 56.0626 91.5834 56.8174 91.5834H59.3568C60.1116 91.5834 60.7041 91.4951 61.1344 91.3187C61.5647 91.1353 61.868 90.8287 62.0444 90.3984C62.2278 89.9681 62.3195 89.3756 62.3195 88.6208V79.9021C62.3195 79.6058 62.3937 79.3834 62.5419 79.2353C62.69 79.0872 62.912 79.0133 63.2083 79.0133C63.5045 79.0133 63.7269 79.0872 63.8751 79.2353C64.0232 79.3834 64.0971 79.6058 64.0971 79.9021V88.6208C64.0971 90.2361 63.7128 91.4316 62.944 92.2075C62.1751 92.9764 60.9792 93.361 59.3568 93.361H56.8174Z" fill="white"/>
        <path d="M69.2738 93.361C68.6813 93.361 68.385 93.0648 68.385 92.4722V79.9021C68.385 79.3096 68.6813 79.0133 69.2738 79.0133H74.797C75.9186 79.0133 76.7792 79.3096 77.3788 79.9021C77.9854 80.4946 78.2887 81.3411 78.2887 82.4415V83.0129C78.2887 83.9652 77.9892 84.7021 77.3896 85.2241C78.5112 85.8519 79.0717 86.9984 79.0717 88.6631V89.3826C79.0717 90.6805 78.7261 91.6681 78.0348 92.3453C77.3506 93.0224 76.363 93.361 75.0721 93.361H69.2738ZM70.1626 91.5834H75.0721C75.8692 91.5834 76.4373 91.4141 76.7759 91.0756C77.1215 90.7299 77.2941 90.1656 77.2941 89.3826V88.6631C77.2941 87.8801 77.1286 87.3229 76.797 86.9913C76.4655 86.6527 75.8904 86.4834 75.0721 86.4834H70.1626V91.5834ZM70.1626 84.7058H75.178C75.6929 84.7058 76.0423 84.5469 76.2257 84.2295C76.4161 83.9121 76.5112 83.5067 76.5112 83.0129V82.4415C76.5112 81.8278 76.3842 81.4009 76.1302 81.161C75.8763 80.9141 75.4319 80.7909 74.797 80.7909H70.1626V84.7058Z" fill="white"/>
        <path d="M37.8839 34.7428H19.7677C19.3561 34.7428 18.9614 34.5792 18.6703 34.2882C18.3793 33.9972 18.2158 33.6025 18.2158 33.1909C18.2153 32.9868 18.255 32.7845 18.3328 32.5958C18.4105 32.407 18.5247 32.2354 18.6689 32.0909C18.813 31.9463 18.9843 31.8317 19.1728 31.7534C19.3614 31.6752 19.5635 31.6349 19.7677 31.6349H37.8839C38.2937 31.6404 38.6847 31.8073 38.9722 32.0994C39.2596 32.3915 39.4203 32.7852 39.4191 33.1951C39.417 33.6027 39.255 33.9933 38.9679 34.2827C38.6807 34.5722 38.2916 34.7373 37.8839 34.7428Z" fill="#FF3E4C"/>
        <path d="M37.8839 44.025H19.7677C19.3561 44.025 18.9614 43.8615 18.6703 43.5706C18.3793 43.2793 18.2158 42.8847 18.2158 42.4731C18.2153 42.2689 18.255 42.0669 18.3328 41.8781C18.4105 41.6893 18.5247 41.5175 18.6689 41.3731C18.813 41.2285 18.9843 41.1139 19.1728 41.0357C19.3614 40.9574 19.5635 40.9171 19.7677 40.9171H37.8839C38.2959 40.9182 38.6906 41.0826 38.9815 41.3743C39.2724 41.666 39.4358 42.0611 39.4358 42.4731C39.4347 42.8843 39.2708 43.2785 38.98 43.5694C38.6892 43.8602 38.2952 44.0237 37.8839 44.025Z" fill="#FF3E4C"/>
        <path d="M37.8839 53.3071H19.7677C19.5635 53.3071 19.3614 53.2669 19.1728 53.1884C18.9843 53.1104 18.813 52.9955 18.6689 52.8511C18.5247 52.7067 18.4105 52.5349 18.3328 52.3461C18.255 52.1573 18.2153 51.9552 18.2158 51.7511C18.2158 51.3395 18.3793 50.9449 18.6703 50.6536C18.9614 50.3627 19.3561 50.1992 19.7677 50.1992H37.8839C38.2952 50.2005 38.6892 50.3644 38.98 50.6548C39.2708 50.9457 39.4347 51.3399 39.4358 51.7511C39.4358 52.1631 39.2724 52.5581 38.9815 52.8498C38.6906 53.1415 38.2959 53.3059 37.8839 53.3071Z" fill="#FF3E4C"/>
        <path d="M37.8839 62.5892H19.7677C19.5635 62.5892 19.3614 62.549 19.1728 62.4705C18.9843 62.3925 18.813 62.2776 18.6689 62.1332C18.5247 61.9888 18.4105 61.817 18.3328 61.6282C18.255 61.4398 18.2153 61.2373 18.2158 61.0332C18.2158 60.6216 18.3793 60.227 18.6703 59.9357C18.9614 59.6448 19.3561 59.4813 19.7677 59.4813H37.8839C38.2952 59.4826 38.6892 59.6461 38.98 59.9369C39.2708 60.2278 39.4347 60.622 39.4358 61.0332C39.4358 61.4452 39.2724 61.8402 38.9815 62.1319C38.6906 62.4236 38.2959 62.588 37.8839 62.5892Z" fill="#FF3E4C"/>
        <path d="M62.6059 34.7428H44.9462C44.5345 34.7428 44.1399 34.5792 43.8488 34.2882C43.5578 33.9972 43.3943 33.6025 43.3943 33.1909C43.3938 32.9868 43.4335 32.7845 43.5112 32.5958C43.589 32.407 43.7032 32.2354 43.8474 32.0909C43.9915 31.9463 44.1628 31.8317 44.3513 31.7534C44.5399 31.6752 44.742 31.6349 44.9462 31.6349H62.6059C63.0188 31.6349 63.4142 31.7988 63.7063 32.0907C63.998 32.3824 64.1619 32.7782 64.1619 33.1909C64.1607 33.6029 63.9964 33.9976 63.7047 34.2885C63.413 34.5795 63.0179 34.7428 62.6059 34.7428Z" fill="#FF3E4C"/>
        <path d="M62.6059 44.025H44.9462C44.5345 44.025 44.1399 43.8615 43.8488 43.5706C43.5578 43.2793 43.3943 42.8847 43.3943 42.4731C43.3938 42.2689 43.4335 42.0669 43.5112 41.8781C43.589 41.6893 43.7032 41.5175 43.8474 41.3731C43.9915 41.2285 44.1628 41.1139 44.3513 41.0357C44.5399 40.9574 44.742 40.9171 44.9462 40.9171H62.6059C63.0188 40.9171 63.4142 41.081 63.7063 41.3728C63.998 41.6648 64.1619 42.0606 64.1619 42.4731C64.1607 42.8851 63.9964 43.2797 63.7047 43.5706C63.413 43.8615 63.0179 44.025 62.6059 44.025Z" fill="#FF3E4C"/>
        <path d="M62.6059 53.3071H44.9462C44.742 53.3071 44.5399 53.2669 44.3513 53.1884C44.1628 53.1104 43.9915 52.9955 43.8474 52.8511C43.7032 52.7067 43.589 52.5349 43.5112 52.3461C43.4335 52.1573 43.3938 51.9552 43.3943 51.7511C43.3943 51.3395 43.5578 50.9449 43.8488 50.6536C44.1399 50.3627 44.5345 50.1992 44.9462 50.1992H62.6059C63.0179 50.1992 63.413 50.3627 63.7047 50.6536C63.9964 50.9444 64.1607 51.3391 64.1619 51.7511C64.1619 52.1639 63.998 52.5594 63.7063 52.8515C63.4142 53.1432 63.0188 53.3071 62.6059 53.3071Z" fill="#FF3E4C"/>
        <path d="M62.6059 62.5892H44.9462C44.742 62.5892 44.5399 62.549 44.3513 62.4705C44.1628 62.3925 43.9915 62.2776 43.8474 62.1332C43.7032 61.9888 43.589 61.817 43.5112 61.6282C43.4335 61.4398 43.3938 61.2373 43.3943 61.0332C43.3943 60.6216 43.5578 60.227 43.8488 59.9357C44.1399 59.6448 44.5345 59.4813 44.9462 59.4813H62.6059C63.0179 59.4813 63.413 59.6448 63.7047 59.9357C63.9964 60.2266 64.1607 60.6212 64.1619 61.0332C64.1619 61.446 63.998 61.8415 63.7063 62.1336C63.4142 62.4253 63.0188 62.5892 62.6059 62.5892Z" fill="#FF3E4C"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default PubSvg;
