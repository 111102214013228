import { isEqual, uniqBy } from "lodash";
import { BoardInterface, BoardInterfaceExtended } from "../types/boardTypes";
import { TaskInterface } from "../types/tasksTypes";
import { WorkspaceInterface } from "../types/types";
// @ts-ignore
import { allCustomPresets, allFixedPresets } from "./filterPresets";
import { FilterValueNone, FilterValueType, MultipleFilter, myTasksFilters, TAnyFilters } from "./filters";
import { FilterType, TFilterFn } from "./filterTypes";

// only for My Tasks, because there are no currentBoard, only workspaces
const getMyTasksFilterValuesByTitleKey = (titleKey: string, tasks: TaskInterface[], workspaces: WorkspaceInterface[]) => {
  switch (titleKey) {
    case FilterValueNone:
    case "Due date": {
      return myTasksFilters["Due date"].filterValues();
    }

    case "Board": {
      const allBoards = tasks.map(task => task.board);
      const boards = uniqBy(allBoards, "id");
      return myTasksFilters["Board"].filterValues(boards);
    }

    case "Project": {
      return myTasksFilters["Project"].filterValues(workspaces);
    }

    default:
      return [];
  }
};
export const getMyTasksFilterValues = (currentFilter: TAnyFilters, tasks: TaskInterface[], workspaces: WorkspaceInterface[]) => {
  const filterValues: Record<string, FilterValueType[]> = {};

  if (currentFilter.multiple?.length) {
    currentFilter.multiple.forEach(filter => {
      filterValues[filter.titleKey] = getMyTasksFilterValuesByTitleKey(filter.titleKey, tasks, workspaces);
    });
  } else {
    // old implementation with single filters
    filterValues[currentFilter.titleKey] = getMyTasksFilterValuesByTitleKey(currentFilter.titleKey, tasks, workspaces);
  }

  return filterValues;
};

// only for ViewerTasks, because there are different currentBoard and can be workspaces based filters
export const geViewerTasksFilterValues = (
  currentFilter: TAnyFilters,
  filterPreset: TFilterFn,
  entity: WorkspaceInterface | BoardInterface | null) => {
  const filterValues: Record<string, FilterValueType[]> = {};

  if (currentFilter.multiple?.length) {
    currentFilter.multiple.forEach(filter => {
      filterValues[filter.titleKey] = filterPreset[filter.titleKey]?.filterValues(entity, filter);
    });
  } else {
    // old implementation with single filters
    filterValues[currentFilter.titleKey] = filterPreset[currentFilter.titleKey]?.filterValues(entity, currentFilter);
  }

  return filterValues;
};

const getFilterPreset = (rawTitleKey: string, types: { [key: string]: FilterType }) => {
  // Fixed presets
  const preset = allFixedPresets[rawTitleKey];
  if (preset) {
    return preset;
  }

  // Custom presets (from Custom columns)
  // define original filter type of column
  const type = types[rawTitleKey];

  switch (type) {
    /** Text filters */
    case FilterType.Text:
      return allCustomPresets.textFilter;
    case FilterType.SingleSelect:
      return allCustomPresets.selectFilter;
    case FilterType.MultipleSelect:
      return allCustomPresets.multiSelectFilter;
    case FilterType.Date:
      return allCustomPresets.dateFilter;
    case FilterType["Due date"]:
      return allCustomPresets.dueDateFilter;
    case FilterType.Boolean:
      return allCustomPresets.booleanFilter;
    case FilterType.Number:
      return allCustomPresets.numberFilter;
    case FilterType.User:
    case FilterType.Assignees:
    case FilterType.Users:
      return allCustomPresets.assigneeFilter;
  }

  return allCustomPresets.noneFilter;
};

type GetFilterValuesProps = {
  types: {
    [key: string]: FilterType
  },
  board: BoardInterfaceExtended
  currentFilter: MultipleFilter | TAnyFilters
}

export const getFilterValues = ({ board, types, currentFilter }: GetFilterValuesProps) => {
  const preset = getFilterPreset(currentFilter.titleKey, types);
  return preset?.filterValues(board, currentFilter);
};

export const isEqualViewFiltersAndCurrentFilters = (currentFilter: TAnyFilters, filterFromView?: TAnyFilters) => {
  return !!filterFromView &&
    (
      (filterFromView?.titleKey === currentFilter.titleKey) &&
      isEqual(filterFromView?.value, currentFilter.value) &&
      (filterFromView?.function === currentFilter.function) &&
      (filterFromView?.operator === currentFilter?.operator) &&
      isEqual(filterFromView?.multiple, currentFilter.multiple)
    );
};
