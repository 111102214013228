import { EntityId } from "lib/types/entity";
import { FileInterface } from "lib/types/types";
import HttpService from "./HttpService";
import { PictureMimeTypes } from "lib/helpers/uploadHelper";

class UploadService extends HttpService {
  constructor() {
    super("upload");
  }

  async upload(files: File | File[]) {
    const formData = new FormData();

    if (Array.isArray(files)) {
      files.forEach(file => {
        formData.append("files", file);
      });
    } else {
      if (files instanceof Blob && PictureMimeTypes.includes(files.type)) {
        const fileName = Date.now() + ".png";
        formData.append("files", files, fileName);
      } else {
        formData.append("files", files);
      }
    }

    const data: Array<FileInterface> = await this._postFormData({
      body: formData,
    });

    return data;
  }

  async fetch(id: EntityId) {
    const data: FileInterface = await this._get({
      additionalUrl: `/files/${id}`
    });

    return data || {};
  }

  async fetchAll() {
    const data: Array<FileInterface> = await this._get({
      additionalUrl: "/files"
    });

    return data || [];
  }
}

export default new UploadService();
