import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const CircleSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M50 91.6666C73.0119 91.6666 91.6667 73.0118 91.6667 49.9999C91.6667 26.9881 73.0119 8.33325 50 8.33325C26.9882 8.33325 8.33337 26.9881 8.33337 49.9999C8.33337 73.0118 26.9882 91.6666 50 91.6666ZM50 83.3333C68.4095 83.3333 83.3334 68.4094 83.3334 49.9999C83.3334 31.5904 68.4095 16.6666 50 16.6666C31.5906 16.6666 16.6667 31.5904 16.6667 49.9999C16.6667 68.4094 31.5906 83.3333 50 83.3333Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default CircleSvg;
