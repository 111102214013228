import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";

export const TaskDrawerTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-inline: -10px;
`;

export const TaskDrawerArrowsWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`;

export const TaskDrawerArrow = styled.img<{ disabled?: boolean }>`
  transition: opacity ease 200ms;
  cursor: pointer;
  margin: 0 3px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  };
  ${({ disabled }) => disabled && css`
    cursor: default;
    &:hover {
      opacity: 0.5;
    };
  `}
`;
