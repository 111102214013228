import React, { FC, useState } from "react";
import { Form, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import StatusesService from "services/StatusesService";
import { StatusInterface } from "lib/types/kanbanTypes";
import { KeyboardKeys } from "lib/types/keyCodes";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";

import { Wrapper, Input, Loader } from "./styled";

interface NewLaneFormProps {
  onCancel: () => void;
}

const NewLaneForm: FC<NewLaneFormProps> = (props) => {
  const { onCancel } = props;
  const { board } = useBoardContext() as BoardContextInterface;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const statuses: StatusInterface[] = board?.statuses;

  const onLaneAdd = () => {
    form.validateFields().then(values => {
      const sort = statuses.reduce((acc, curr) => acc.sort > curr.sort ? acc : curr).sort + 1;
      setIsLoading(true);
      StatusesService
        .addNewStatus(board.id, {
          title: values.title,
          sort,
        })
        .then(() => onCancel(), () => setIsLoading(false));
    });
  };

  const onLaneChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyboardKeys.Escape) {
      event.preventDefault();
      onCancel();
    }

    if (event.key === KeyboardKeys.Tab || event.key === KeyboardKeys.Enter) {
      event.preventDefault();
      onLaneAdd();
    }
  };

  return (
    <Wrapper>
      <Form form={form}>
        <Form.Item
          name="title"
          rules={
            [{ required: true, message: "", whitespace: true }]
          }
        >
          <Input
            placeholder="Status name"
            autoComplete="off"
            autoFocus
            onKeyDown={onLaneChange}
            onBlur={onLaneAdd}
            disabled={isLoading}
          />
        </Form.Item>
        {isLoading && (
          <Loader>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
          </Loader>
        )}
      </Form>
    </Wrapper>
  );
};

export default NewLaneForm;
