import { addNotification } from "app/slices/notificationsSlice";
import { useAppDispatch } from "app/store";
import { NotificationInterface } from "lib/types/notificationTypes";
import { WsEvents } from "lib/types/wsTypes";
import { useCallback } from "react";
import useWSSubscription from "../useWSSubscription";

const useWSCreateNotification = () => {
  const dispatch = useAppDispatch();

  const callback = useCallback((data: NotificationInterface) => {
    dispatch(addNotification(data));
  }, [dispatch]);

  useWSSubscription<NotificationInterface>({
    eventName: WsEvents.Notification.Create,
    callback,
    fnKey: "useWSCreateNotification"
  });

};

export default useWSCreateNotification;
