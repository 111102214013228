import { FC } from "react";
import { Empty } from "antd";

const TaskTableEmptyState: FC = () => {
  return (
    <div style={{ width: "100%", alignItems: "start" }}>
      <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
  );
};

export default TaskTableEmptyState;
