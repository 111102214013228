import styled from "@emotion/styled/macro";
import { dataInput } from "components/blocks/TaskTable/styled";
import { DottedPlaceholderWrapper } from "components/blocks/TaskTable/components/placeholders/DottedPlaceholder/styled";
import { fieldDetailedHover, fieldDetailedWrapper } from "../styled";
import { SyncedFieldTooltipIcon } from "components/blocks/SyncedFieldTooltip/styled";
import { DeleteButton } from "components/blocks/ViewpointLikeCard/styled";

export const DateListWrapper = styled.div<{ clickable?: boolean }>`
  ${dataInput};
  margin-left: 7px;
  cursor: ${({ clickable }) => clickable ? "pointer" : "default"};
  &:hover {
    ${DottedPlaceholderWrapper} {
      opacity: 1;
    }
    ${DeleteButton} {
      opacity: 1;
    }
    ${SyncedFieldTooltipIcon} {
      display: block;
    }
  }
`;

export const DateDetailedWrapper = styled.div<{ clickable?: boolean }>`
  ${fieldDetailedWrapper};
  ${fieldDetailedHover};
  cursor: ${({ clickable }) => clickable ? "pointer" : "default"};
  &:hover {
    ${DeleteButton} {
      opacity: 1;
    }
    ${SyncedFieldTooltipIcon} {
      display: block;
    }
  }
`;
