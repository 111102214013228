import React, { FC, useState } from "react";
import { Modal, Input } from "antd";

import CustomStyledButton from "components/blocks/CustomStyledButton";
import { SectionSubheading, StyledButton } from "styles/common";
import { useHistory } from "react-router-dom";
import { getProjectUri } from "lib/helpers/navigationUtils";
import WorkspaceService from "services/WorkspaceService";
import TextArea from "antd/lib/input/TextArea";
import { useProjectContext } from "lib/contexts/ProjectContext";
import { ModalComponentProps } from "lib/types/components";
import { captureException } from "lib/utils/sentry";
import { commonModalProps } from "../ModalDialog/lib";

const DuplicateProjectModal: FC<ModalComponentProps> = (props) => {
  const { visible, onClose } = props;
  const { workspace } = useProjectContext();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(`Copy of ${workspace?.name}`);
  const [description, setDescription] = useState("");

  const workspaceId = workspace?.id || 0;
  const handleOnSubmit = async (event: React.MouseEvent) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const payload = { name, description };
      const data = await WorkspaceService.duplicate(workspaceId, payload);
      if (data.data?.data?.uid) {
        const target = getProjectUri(data.data.data.uid);
        history.push(target);
      }
    } catch (error) {
      captureException(error);
    }

    setIsLoading(false);
    onClose();
  };

  return (
    <Modal
      {...commonModalProps}
      destroyOnClose
      title="Duplicate project"
      visible={visible}
      onOk={onClose}
      onCancel={onClose}
      width={325}
      footer={[
        <div key="cancel" style={{ display: "inline-block", marginRight: "10px" }}>
          <StyledButton onClick={onClose}>
            Cancel
          </StyledButton>
        </div>,
        <CustomStyledButton
          key="ok"
          value="Duplicate"
          btnColor="purple"
          disabled={!name.trim() || isLoading}
          isLoading={isLoading}
          onClick={handleOnSubmit}
        />
      ]}
    >
      <SectionSubheading style={{ marginTop: 0 }}>Name</SectionSubheading>
      <Input
        autoFocus
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <SectionSubheading>Description</SectionSubheading>
      <TextArea
        disabled={false}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        rows={3}
      />
    </Modal>
  );
};

export default DuplicateProjectModal;
