import { TextAreaStyled } from "components/blocks/TaskTable/components/TaskTitle/styled";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { TaskFieldSetterGetter } from "lib/types/applicationTypes";
import KeyCodes from "lib/types/keyCodes";
import { MemberRoles } from "lib/types/types";
import React, { FC, useEffect, useRef, useState } from "react";

const MultiLineTextDetailed: FC<TaskFieldSetterGetter> = (props) => {
  const { value, setValue, code } = props;

  const ref = useRef<HTMLTextAreaElement>(null);
  const [displayText, setDisplayText] = useState(value);
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  useEffect(() => {
    setDisplayText(value);
  }, [value]);

  const handleTextAreaOnBlur = () => {
    if (displayText !== value) {
      setValue({ cellName: code, cellValue: displayText });
    }
  };

  const handleTextAreaOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDisplayText(event.target.value);
  };

  const handleTextAreaOnKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.keyCode === KeyCodes.Escape) {
      event.stopPropagation();
      ref.current?.blur();
    }
  };

  return (
    <TextAreaStyled
      ref={ref}
      value={displayText}
      autoSize={{ minRows: 2, maxRows: 6 }}
      onKeyDown={handleTextAreaOnKeyDown}
      onChange={handleTextAreaOnChange}
      onBlur={handleTextAreaOnBlur}
      disabled={!allowedToEdit}
    />
  );
};

export default MultiLineTextDetailed;
