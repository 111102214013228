import ArrowSvg from "components/icons/resizable/markups/arrow";
import CircleSvg from "components/icons/resizable/markups/circle";
import FillSvg from "components/icons/resizable/markups/fill";
import FreehandSvg from "components/icons/resizable/markups/freehand";
import MediumSvg from "components/icons/resizable/markups/medium";
import NoFillSvg from "components/icons/resizable/markups/nofill";
import NoStrokeSvg from "components/icons/resizable/markups/nostroke";
import PolyCloudSvg from "components/icons/resizable/markups/polycloud";
import PolygonSvg from "components/icons/resizable/markups/polyline";
import RectangleSvg from "components/icons/resizable/markups/rectangle";
import TextSvg from "components/icons/resizable/markups/text";
import TextMediumSvg from "components/icons/resizable/markups/textmedium";
import TextSmallSvg from "components/icons/resizable/markups/textsmall";
import ThickSvg from "components/icons/resizable/markups/thick";
import ThinSvg from "components/icons/resizable/markups/thin";
import TransparentSvg from "components/icons/resizable/markups/transparent";
import { SvgPropertiesHyphen } from "csstype";
import ForgeMarkupToolbarColors from "./ForgeMarkupToolbarColors";

export enum ForgeMarkupTools {
  Arrow = "arrow",
  Text = "label",
  Rectangle = "rectangle",
  Ellipse = "ellipse",
  Cloud = "cloud",
  Freehand = "freehand",
  Polyline = "polyline",
  Polycloud = "polycloud",
  // Select = "select",
  // Highlight = "highlight",
  // Callout = "callout",
  // Dimension = "dimension",
}

export const ForgeMarkupEditModes: { [K in ForgeMarkupTools]: string | null } = {
  [ForgeMarkupTools.Arrow]: "EditModeArrow",
  [ForgeMarkupTools.Rectangle]: "EditModeRectangle",
  [ForgeMarkupTools.Ellipse]: "EditModeCircle",
  [ForgeMarkupTools.Cloud]: "EditModeCloud",
  [ForgeMarkupTools.Text]: "EditModeText",
  [ForgeMarkupTools.Freehand]: "EditModeFreehand",
  [ForgeMarkupTools.Polyline]: "EditModePolyline",
  [ForgeMarkupTools.Polycloud]: "EditModePolycloud",
  // [ForgeMarkupTools.Select]: null,
  // [ForgeMarkupTools.Highlight]: null,
  // [ForgeMarkupTools.Callout]: null,
  // [ForgeMarkupTools.Dimension]: null
};

export const ForgeMarkupToolApplicableStyles: { [K in ForgeMarkupTools]: string | null } = {
  [ForgeMarkupTools.Arrow]: "EditModeArrow",
  [ForgeMarkupTools.Rectangle]: "EditModeRectangle",
  [ForgeMarkupTools.Ellipse]: "EditModeCircle",
  [ForgeMarkupTools.Cloud]: "EditModeCloud",
  [ForgeMarkupTools.Text]: "EditModeText",
  [ForgeMarkupTools.Freehand]: "EditModeFreehand",
  [ForgeMarkupTools.Polyline]: "EditModePolyline",
  [ForgeMarkupTools.Polycloud]: "EditModePolycloud",
  // [ForgeMarkupTools.Select]: null,
  // [ForgeMarkupTools.Highlight]: null,
  // [ForgeMarkupTools.Callout]: null,
  // [ForgeMarkupTools.Dimension]: null
};

export const getMarkupEditorClass = (editMode: ForgeMarkupTools) => {
  if (!Object.prototype.hasOwnProperty.call(ForgeMarkupEditModes, editMode)) {
    throw new Error(`${editMode} is not a valid editor`);
  }

  return ForgeMarkupEditModes[editMode];
};


export type ForgeMarkupStylesObj = Partial<Record<keyof SvgPropertiesHyphen & "stroke-color", any>>;

export enum ForgeMarkupFillPresets {
  Fill = "fill",
  Transparent = "transparent",
  NoFill = "no-fill",
}

export const ForgeMarkupFillPresetStyles = {
  [ForgeMarkupFillPresets.Fill]: {
    "stroke-opacity": 1,
    "fill-opacity": 1,
  },
  [ForgeMarkupFillPresets.NoFill]: {
    "stroke-opacity": 1,
    "fill-opacity": 0,
  },
  [ForgeMarkupFillPresets.Transparent]: {
    "stroke-opacity": 0.5,
    "fill-opacity": 0.5,
  },
};

export enum ForgeMarkupColor {
  Red = "#e44234",
  Orange = "#ff8d00",
  Yellow = "#ffcd45",
  Green = "#00cc63",
  Blue = "#4e7de9",
  Purple = "#c544ce",
}

export enum ForgeMarkupStrokeThickness {
  NoStroke = 0,
  Thin = 1,
  Medium = 5,
  Thick = 10,
}

export enum ForgeMarkupFontSize {
  Small = 12,
  Medium = 24,
  Large = 36,
}

export enum ForgeMarkup { }

export const MarkupEditTools = [
  { code: ForgeMarkupTools.Arrow, icon: <ArrowSvg size={24} />, name: "Arrow" },
  { code: ForgeMarkupTools.Polycloud, icon: <PolyCloudSvg size={24} />, name: "PolyCloud" },
  { code: ForgeMarkupTools.Text, icon: <TextSvg size={24} />, name: "Text" },
  { code: ForgeMarkupTools.Rectangle, icon: <RectangleSvg size={24} />, name: "Rectangle" },
  { code: ForgeMarkupTools.Polyline, icon: <PolygonSvg size={24} />, name: "PolyLine" },
  { code: ForgeMarkupTools.Ellipse, icon: <CircleSvg size={24} />, name: "Circle" },
  { code: ForgeMarkupTools.Freehand, icon: <FreehandSvg size={24} />, name: "Freehand" },
];

export enum ForgeMarkupPalettes {
  Fill = "fill",
  Stroke = "stroke",
  Color = "color",
  FontSize = "font-size",
}

export const ForgeMarkupStyles = {
  [ForgeMarkupPalettes.Fill]: [
    { value: ForgeMarkupFillPresets.NoFill, icon: <NoFillSvg size={20} />, name: "No fill" },
    { value: ForgeMarkupFillPresets.Transparent, icon: <TransparentSvg size={20} />, name: "Transparent" },
    { value: ForgeMarkupFillPresets.Fill, icon: <FillSvg size={20} />, name: "Fill" },
  ],
  [ForgeMarkupPalettes.Stroke]: [
    { value: ForgeMarkupStrokeThickness.NoStroke, icon: <NoStrokeSvg size={20} />, name: "No stroke" },
    { value: ForgeMarkupStrokeThickness.Thick, icon: <ThickSvg size={20} />, name: "Thick" },
    { value: ForgeMarkupStrokeThickness.Medium, icon: <MediumSvg size={20} />, name: "Medium" },
    { value: ForgeMarkupStrokeThickness.Thin, icon: <ThinSvg size={20} />, name: "Thin" },
  ],
  [ForgeMarkupPalettes.Color]: Object.keys(ForgeMarkupColor).map((color, i) => ({
    value: Object.values(ForgeMarkupColor)[i],
    icon: <ForgeMarkupToolbarColors color={Object.values(ForgeMarkupColor)[i]} />,
    name: color,
  })),
  [ForgeMarkupPalettes.FontSize]: [
    { value: ForgeMarkupFontSize.Large, icon: <TextSvg size={24} />, name: "Large" },
    { value: ForgeMarkupFontSize.Medium, icon: <TextMediumSvg size={24} />, name: "Medium" },
    { value: ForgeMarkupFontSize.Small, icon: <TextSmallSvg size={24} />, name: "Small" },
  ],
};

export const ForgeMarkupModePalettes: { [K in ForgeMarkupTools]: Array<ForgeMarkupPalettes> } = {
  [ForgeMarkupTools.Arrow]: [ForgeMarkupPalettes.Stroke, ForgeMarkupPalettes.Color],
  [ForgeMarkupTools.Rectangle]: [ForgeMarkupPalettes.Fill, ForgeMarkupPalettes.Stroke, ForgeMarkupPalettes.Color],
  [ForgeMarkupTools.Ellipse]: [ForgeMarkupPalettes.Fill, ForgeMarkupPalettes.Stroke, ForgeMarkupPalettes.Color],
  [ForgeMarkupTools.Cloud]: [ForgeMarkupPalettes.Fill, ForgeMarkupPalettes.Stroke, ForgeMarkupPalettes.Color],
  [ForgeMarkupTools.Text]: [ForgeMarkupPalettes.Color, ForgeMarkupPalettes.FontSize],
  [ForgeMarkupTools.Freehand]: [ForgeMarkupPalettes.Stroke, ForgeMarkupPalettes.Color],
  [ForgeMarkupTools.Polyline]: [ForgeMarkupPalettes.Stroke, ForgeMarkupPalettes.Color],
  [ForgeMarkupTools.Polycloud]: [ForgeMarkupPalettes.Fill, ForgeMarkupPalettes.Stroke, ForgeMarkupPalettes.Color],
};

export const ForgeMarkupToolbarElementId = "MarkupToolbarContainer";
