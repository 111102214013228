import WebSocketService from "lib/API/socket";
import { removeTokenFromStorage } from "lib/helpers/helpers";
import { JSendResponse } from "lib/types/jsend";
import { LoginInterface, RegisterInterface, UserResponse } from "lib/types/types";

import HttpService from "./HttpService";

class AuthService extends HttpService {
  constructor() {
    super("auth/");
  }

  async register(authData: RegisterInterface) {
    const data: UserResponse | JSendResponse = await this._post({
      additionalUrl: "local/register",
      body: authData,
      isUseToken: false
    });

    return data;
  }

  async login(authData: LoginInterface) {
    const data: UserResponse | JSendResponse = await this._post({
      additionalUrl: "local/",
      body: authData,
      isUseToken: false
    });

    return data;
  }

  async forgotPassword(email: string) {
    const data: JSendResponse = await this._post({
      additionalUrl: "forgot-password",
      body: { email },
      isUseToken: false,
    });

    return data;
  }

  async changePassword(code: string, password: string, passwordConfirmation: string) {
    const data: UserResponse = await this._post({
      additionalUrl: "reset-password",
      body: {
        code,
        password,
        passwordConfirmation
      },
      isUseToken: false,
    });

    return data;
  }

  async providerConnect(providerName: string, query: string) {
    const data: UserResponse = await this._get({
      additionalUrl: `${providerName}/callback${query}`,
      isUseToken: false,
    });

    return data;
  }

  logout() {
    WebSocketService.disconnect();
    removeTokenFromStorage();
    document.location.reload();
  }
}

export default new AuthService();
