import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { doesMatchFilter } from "lib/helpers/MultiRelationalEntity";
import { MultiRelationalEntityFilter } from "lib/types/backend";
import { DocumentInterface } from "lib/types/document";
import { EntityId } from "lib/types/entity";

interface InitialStateType {
  collection: Array<DocumentInterface>;
  current: DocumentInterface | null;
  filter: MultiRelationalEntityFilter | null;
}

const initialState: InitialStateType = {
  collection: [],
  current: null,
  filter: null,
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState: initialState,
  reducers: {
    setDocumentCollection: (state, action: PayloadAction<{ data: Array<DocumentInterface>, filter: MultiRelationalEntityFilter }>) => {
      state.filter = action.payload.filter;
      state.collection = action.payload.data;
    },
    addDocumentToCollection: (state, action: PayloadAction<DocumentInterface>) => {
      if (doesMatchFilter(state.filter, action.payload)) {
        state.collection.push(action.payload);
      }
    },
    updateDocumentInCollection: (state, action: PayloadAction<DocumentInterface>) => {
      const findIndex = state.collection.findIndex(document => document.id === action.payload.id);
      if (findIndex !== -1) {
        state.collection[findIndex] = action.payload;
      }
    },
    deleteDocumentFromCollection: (state, action: PayloadAction<EntityId>) => {
      const findIndex = state.collection.findIndex(document => String(document.id) === String(action.payload));
      if (findIndex !== -1) {
        state.collection.splice(findIndex, 1);
      }
    },
    setDocumentDetailed: (state, action: PayloadAction<DocumentInterface | null>) => {
      state.current = action.payload;
    },
    updateDocumentDetailed: (state, action: PayloadAction<DocumentInterface>) => {
      if (action.payload.id === state.current?.id) {
        state.current = action.payload;
      }
    },
    performDocumentOptimisticUpdate: (state, action: PayloadAction<{ id?: EntityId; update: Partial<DocumentInterface>; updateDetailed?: boolean }>) => {
      const documentIndex = state.collection.findIndex(item => String(item.id) === String(action.payload.id));
      if (documentIndex !== -1) {
        const data = action.payload.update;
        for (const [code, value] of Object.entries(data)) {
          state.collection[documentIndex][code as keyof DocumentInterface] = value as never;
        }
      }

      if (action.payload.updateDetailed && state.current && String(action.payload.id) === String(state.current?.id)) {
        const data = action.payload.update;
        for (const [code, value] of Object.entries(data)) {
          state.current[code as keyof DocumentInterface] = value as never;
        }
      }
    },
  }
});

export const {
  setDocumentCollection,
  addDocumentToCollection,
  updateDocumentInCollection,
  deleteDocumentFromCollection,
  setDocumentDetailed,
  updateDocumentDetailed,
  performDocumentOptimisticUpdate,
} = documentsSlice.actions;

export default documentsSlice.reducer;
