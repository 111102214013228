import { FC } from "react";
import { Modal } from "antd";
import { ColumnInstance } from "react-table";

import ModalForm from "./Form";
import { commonModalProps } from "components/blocks/ModalDialog/lib";
import { Column } from "lib/types/tasksTypes";

interface ModalProps {
  onCancel: () => void;
  isModalVisible: boolean;
  columnData?: ColumnInstance<Column>;
}

const ModalComponent: FC<ModalProps> = (props) => {
  const { onCancel, isModalVisible, columnData } = props;

  const handleCancelModal = () => onCancel();

  return (
    <Modal
      {...commonModalProps}
      title={columnData ? "Edit field" : "Add field"}
      visible={isModalVisible}
      footer={null}
      onCancel={handleCancelModal}
      centered
    >
      <ModalForm
        onCancel={onCancel}
        columnData={columnData}
      />
    </Modal>
  );
};

export default ModalComponent;
