import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const DropdownSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M30.0092 33.3333H70.0092C74.1259 33.3333 76.4758 38.0333 74.0092 41.3333L54.0092 67.9999C52.0092 70.6666 48.0092 70.6666 46.0092 67.9999L26.0092 41.3333C23.5342 38.0333 25.8926 33.3333 30.0092 33.3333Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default DropdownSvg;
