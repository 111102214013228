import { FC, Key } from "react";
import Linkify from "react-linkify";

const LinkifySecure: FC = (props) => (
  <Linkify componentDecorator={(
    decoratedHref: string,
    decoratedText: string,
    key: Key
  ) => (
    <a
      href={decoratedHref}
      target="_blank"
      key={key}
      rel="noreferrer"
      onClick={e => e.stopPropagation()}
      style={{ color: "#14aaf5", textDecoration: "underline" }}
    >
      {decoratedText}
    </a>
  )}>
    {props.children}
  </Linkify>
);

export default LinkifySecure;
