import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";

export const ViewSwitchCardCheckContainer = styled.div`
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  bottom: auto;
  display: none;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin: 4px;
`;

export const ViewSwitchCardTile = styled.div<{ select?: boolean, disabled?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px #e4e4e4;
  border-radius: 5px;
  transition: all 200ms ease;
  min-height: 60px;
  cursor: pointer;
  font-size: 12px;
  color: #9e9e9e;
  font-weight: 500;
  user-select: none;
  &:hover {
    border: solid 1px #40a9ff;
  }
  ${({ select }) => select && css`
    border: solid 1px rgba(64, 169, 255, 0.6);
    color: #40a9ff;
    path {
      fill: #40a9ff !important;
    }
    ${ViewSwitchCardCheckContainer} {
      display: flex;
    }
  `}
  ${({ disabled }) => disabled && css`
    cursor: auto;
    ${ViewSwitchCardCheckContainer} {
      display: none;
    }
  `}
`;

export const ViewSwitchCardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  margin: 2px;
`;
