import { useCallback } from "react";
import { useAppDispatch } from "app/store";
import { WsEvents } from "lib/types/wsTypes";
import useWSSubscription from "../useWSSubscription";
import { FileContainerInterface } from "lib/types/fileContainer";
import { deleteFileContainerFromCollection } from "app/slices/fileContainerSlice";

const useWSDeleteFileContainer = () => {
  const dispatch = useAppDispatch();

  const callback = useCallback((data: FileContainerInterface) => {
    dispatch(deleteFileContainerFromCollection(data.id));
  }, [dispatch]);

  useWSSubscription<FileContainerInterface>({
    eventName: WsEvents.FileContainer.Delete,
    callback,
    fnKey: "useWSDeleteFileContainer"
  });
};

export default useWSDeleteFileContainer;
