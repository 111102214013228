import { selectCurrentSort, setSort } from "app/slices/tasksFilterAndSortSlice";
import { useAppDispatch } from "app/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { TasksSortType } from "../../sort/tasksSort";
import useBoardView from "./useBoardView";

type TUseBoardViewSort = () => TasksSortType;

const useBoardViewSort: TUseBoardViewSort = () => {
  const dispatch = useAppDispatch();
  const view = useBoardView();
  const currentSort = useSelector(selectCurrentSort);

  useEffect(() => {
    const sortFromView = view?.appliedSort as TasksSortType;

    if (sortFromView) {
      dispatch(setSort(sortFromView));
    }
  }, [dispatch, view]);

  return currentSort;
};

export default useBoardViewSort;
