import { FC } from "react";
import { DisplayView } from "lib/types/applicationTypes";
import { BoardViewItemClickHandler, IBoardDisplayView } from "../lib";
import { BoardDisplayView } from "lib/types/boardTypes";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import useBoardSetDisplayViews from "lib/customHooks/dataLayer/board/useBoardSetDisplayViews";
import ViewSwitchCard from "components/ui/ViewSwitchCard";

interface BoardSettingsDisplayViewItemProps {
  boardDisplayView: IBoardDisplayView;
  initialValues: BoardDisplayView;
  editable: boolean;
}

const BoardSettingsDisplayViewItem: FC<BoardSettingsDisplayViewItemProps> = (props) => {
  const { boardDisplayView, editable, initialValues } = props;
  const selected = initialValues[boardDisplayView.code] || false;
  const disabled = !editable || (boardDisplayView.code === DisplayView.List);

  const { board } = useBoardContext() as BoardContextInterface;
  const { setBoardDisplayView } = useBoardSetDisplayViews({ board, updateDetailed: true });

  const handleSwitchOnChange: BoardViewItemClickHandler = async (item, checked) => {
    if (disabled) {
      return;
    }

    const payload = { [item]: checked };
    setBoardDisplayView(board?.id, payload);
  };

  return (
    <ViewSwitchCard
      title={boardDisplayView.title}
      icon={boardDisplayView.icon}
      select={!!selected}
      disabled={disabled}
      onClick={() => handleSwitchOnChange(boardDisplayView.code, !selected)}
    />
  );
};

export default BoardSettingsDisplayViewItem;
