import { FC } from "react";
import styled from "@emotion/styled/macro";
import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import { dataInput } from "components/blocks/TaskTable/styled";
import IdGeneric from "./Display/generic";
import { fieldDetailedWrapper } from "../styled";
import { SyncedFieldTooltipIcon } from "components/blocks/SyncedFieldTooltip/styled";

const IdListWrapper = styled.div`
  ${dataInput};
  &:hover {
    ${SyncedFieldTooltipIcon} {
      display: block;
    }
  }
`;

const IdDetailedWrapper = styled.div`
  ${fieldDetailedWrapper};
  &:hover {
    ${SyncedFieldTooltipIcon} {
      display: block;
    }
  }
`;

const IdField: FC<TaskFieldPropsGeneric> = (props) => {
  const { component } = props;

  switch (component) {
    case TaskSourceComponent.Card:
      return null;
    case TaskSourceComponent.Cell:
      return (
        <IdListWrapper>
          <IdGeneric {...props} />
        </IdListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <IdDetailedWrapper>
          <IdGeneric {...props} />
        </IdDetailedWrapper>
      );
    default:
      return null;
  }
};

export default IdField;
