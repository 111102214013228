import { setUser } from "app/slices/currentUserSlice";
import { useAppDispatch } from "app/store";
import { useCallback, useEffect } from "react";
import UserService from "services/UserService";
import useAsyncData from "./useAsyncData";

const useFetchCurrentUser = () => {
  const dispatch = useAppDispatch();

  const fetch = useCallback(() => {
    return UserService.getSelf();
  }, []);

  const { data: userData } = useAsyncData({
    loadOnMount: true,
    fetchFn: fetch
  });

  useEffect(() => {
    if (userData) {
      dispatch(setUser(userData));
    }
  }, [userData]);

};

export default useFetchCurrentUser;
