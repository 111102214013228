import { css } from "@emotion/css";
import { css as cssMacro } from "@emotion/react/macro";
import styled from "@emotion/styled/macro";

export const ViewerViewpointsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  overflow-x: hidden;
`;

export const ViewerViewpointsGrid = styled.div<{ isRow?: boolean }>`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  ${({ isRow }) => isRow && cssMacro`
    grid-auto-flow: column;
    grid-template-columns: min-content;
  `}
`;

export const ViewerViewpointsGradient = styled.div<{ isRow?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 64px;
  background-image: linear-gradient(to right, transparent, white  65%);
  ${({ isRow }) => !isRow && cssMacro`
    display: none;
  `}
`;

export const ViewerViewpointsModalMask = css`
  pointer-events: none;
`;

export const ViewerViewpointsOldContainer = styled.div<{ isRow?: boolean }>`
  padding: 3px 12px 8px 12px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
  background-color: lightblue;
  ${({ isRow }) => isRow && cssMacro`
    flex-wrap: nowrap;
    padding-right: 50px;
  `}
`;
