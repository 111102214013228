import { BoardInterface, BoardInterfaceExtended } from "../../types/boardTypes";
import { TaskInterface } from "../../types/tasksTypes";
import { FilterFunction } from "../filterFunctions";
import { FilterValueNone, FilterVersion, TAnyFilters } from "../filters";

const getValueKey = (currentFilter: TAnyFilters) => String(currentFilter.value.valueKey);

const getTaskPropertyValue = (task: TaskInterface, currentFilter: TAnyFilters) => {
  const value = task.propertyValues.find(value => value.title === currentFilter.titleKey)?.value;

  if (!value) {
    return "";
  }

  return String(value);
};

const filterV1 = (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
  return tasks.filter(task => {
    return getTaskPropertyValue(task, currentFilter) === getValueKey(currentFilter);
  });
};

const filterV2 = (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
  const valueKey = getValueKey(currentFilter);

  switch (currentFilter.function) {
    case FilterFunction.Is: {
      return tasks.filter(task => {
        return getTaskPropertyValue(task, currentFilter) === valueKey;
      });
    }

    case FilterFunction["Is not"]: {
      return tasks.filter(task => {
        return getTaskPropertyValue(task, currentFilter) !== valueKey;
      });
    }

    case FilterFunction["Is empty"]: {
      return tasks.filter(task => {
        return !getTaskPropertyValue(task, currentFilter);
      });
    }

    case FilterFunction["Is not empty"]: {
      return tasks.filter(task => {
        return !!getTaskPropertyValue(task, currentFilter);
      });
    }
  }

  return tasks;
};

export const selectFilter = {
  filterValues: (item: BoardInterfaceExtended | BoardInterface | null, currentFilter: TAnyFilters) => {

    if (item === null) {
      return [];
    }

    const customColumnName = currentFilter.titleKey;
    const options = item.columns.find(column => column.title === customColumnName)?.options;


    return options?.map(option => {
      return {
        text: option.title,
        valueKey: String(option.id),
        color: option?.color || ""
      };
    }) || [];
  },
  filterFn: (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
    const filterText = currentFilter.value.text;

    if (!filterText || (filterText === FilterValueNone)) {
      return tasks;
    }

    if (currentFilter.version && (currentFilter.version !== FilterVersion.v1)) {
      return filterV2(tasks, currentFilter);
    }

    return filterV1(tasks, currentFilter);
  }
};
