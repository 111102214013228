import styled from "@emotion/styled/macro";
import { NavLink } from "react-router-dom";

export const BoardHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const BoardHeaderIconWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 45px;
  height: 45px;
  margin-right: 15px;
  border: 1px solid #d7d6d5;
  border-radius: 7px;
  background-color: #fbfbfb;
  transition: background-color 200ms ease;
  cursor: ${({ disabled }) => disabled ? "" : "pointer"};
  &:hover {
    background-color: ${({ disabled }) => disabled ? "#fbfbfb" : "#f2f2f2"};
  }
`;

export const BoardHeaderIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const BoardHeaderBoardData = styled.div`
  margin-right: 15px;
`;

export const BoardHeaderBoardTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const BoardHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BoardHeaderViews = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

export const BoardHeaderView = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  margin-right: 10px;

  .active {
    color: #40a9ff;
    &:hover {
      color: #40a9ff;
    }
    path {
      fill: #40a9ff;
    }
  }
  
`;

export const BoardHeaderViewTab = styled(NavLink)`
  display: flex;
  height: 36px;
  padding-left: 10px;
  align-items: center;
  transition: border-color 200ms ease;
  color: #999;
  text-decoration: none;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  &:hover {
    color: #999;
    text-decoration: none;
  }
`;

export const BoardHeaderViewIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
`;

export const BoardHeaderViewTitle = styled.div`
  max-width: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
