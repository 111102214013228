import { pickBy } from "lodash";

export function isEmptyValues(value: string | number | boolean | null | Array<any> | object): boolean {
  return (
    value === undefined ||
    value === null ||
    // eslint-disable-next-line use-isnan
    value === NaN ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
}

export function getObjectDifference(prev: any, next: any) {
  if (!prev || !next) {
    return {};
  }
  return pickBy(next, (value, key) => prev[key] !== value);
}
