import { FC } from "react";
import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import MultiLineTextList from "./Display/list";
import MultiLineTextDetailed from "./Display/detailed";
import { MultiLineListWrapper, MultiLineDetailedWrapper } from "./Display/styled";

const MultiLineTextField: FC<TaskFieldPropsGeneric> = (props) => {
  const { component } = props;

  switch (component) {
    case TaskSourceComponent.Card:
      return null;
    case TaskSourceComponent.Cell:
      return (
        <MultiLineListWrapper>
          <MultiLineTextList {...props} />
        </MultiLineListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <MultiLineDetailedWrapper>
          <MultiLineTextDetailed {...props} />
        </MultiLineDetailedWrapper>
      );
    default:
      return null;
  }
};

export default MultiLineTextField;
