import styled from "@emotion/styled/macro";

export const PaletteWrapper = styled.div`
  position: relative;
  padding: 6px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
`;
