import LoadingMessage from "components/blocks/LoadingMessage";
import { captureException } from "lib/utils/sentry";

export const fetchDownload = async (path: string, filename: string, payload = {}, method = "POST") => {
  const jwt = JSON.parse(localStorage.getItem("jwt") as string);

  await fetch(path, {
    method: method,
    headers: {
      "Authorization": `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then(response => {
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.indexOf("application/octet-stream") !== -1) {
        return response.blob();
      } else {
        throw new Error();
      }
    })
    .then(blob => {
      if (blob.size === 0) {
        throw new Error();
      }

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    })
    .catch(error => {
      captureException(error, true);
    });
};

export const fetchDownloadExternal = async (url: string, saveAs: string, showUiMsg?: boolean) => {
  if (showUiMsg) {
    LoadingMessage.complete("Your download will begin shortly");
  }

  // (A) FETCH FILE
  fetch(url)

    // (B) RETURN AS BLOB
    .then(res => {
      if (res.status !== 200) {
        throw new Error("Bad server response");
      }
      return res.blob();
    })

    // (C) BLOB DATA
    .then(data => {
      // (C1) "FORCE DOWNLOAD"
      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", saveAs);
      link.click();

      // (C2) CLEAN UP
      window.URL.revokeObjectURL(url);
      link.parentNode?.removeChild(link);
    })

    // (D) HANDLE ERRORS - OPTIONAL
    .catch(err => captureException(err, true));
};

export const downloadBlobAsFile = (blobPart: BlobPart, fileName: string, type?: string) => {
  const blob = new Blob([blobPart], { type: type });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  // Clean up
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
