import styled from "@emotion/styled/macro";
import { PageScrollable, scrollBar } from "styles/common";

export const BoardTableWrapper = styled.div`
  width: 100%;
  min-height: 100%;
`;

export const BoardTableScrollable = styled(PageScrollable)`
  ${scrollBar};
  &::-webkit-scrollbar-track {
    margin-right: 4px;
  }
`;
