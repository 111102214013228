import { FC } from "react";
import ButtonIcon from "components/ui/ButtonIcon";

import { NewLaneSectionWrapper } from "./styled";

interface NewLaneSectionProps {
  onClick: any;
}

const NewLaneSection: FC<NewLaneSectionProps> = ({ onClick }) => (
  <NewLaneSectionWrapper>
    <ButtonIcon text="Add status" onClick={onClick} />
  </NewLaneSectionWrapper>
);

export default NewLaneSection;
