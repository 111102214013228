import styled from "@emotion/styled/macro";
import { NavLink } from "react-router-dom";

export const SidebarTopMenuSection = styled.div`
  margin: 24px 0;
`;

export const SidebarProjectsSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 0;
`;

export const SidebarAddProjectSection = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 0 14px 20px;
`;

export const SidebarDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f2f2f2
`;

export const SidebarNavigationLink = styled(NavLink) <{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  font-weight: 500;
  color: black;
  padding: 6px 25px;
  background-color: ${(props) => props.isSelected && "#f2f2f2"};
  transition: background-color 200ms ease;
  &:hover {
    color: black;
  }
  &:active, :focus {
    background-color: #f2f2f2;
    color: black;
    text-decoration: none;
  }
`;

export const SidebarItemContentGrid = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-template-rows: auto;
  grid-column-gap: 7px;
  grid-row-gap: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SidebarSettingsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 25px;
  height: 100%;
  margin-right: 10px;
  opacity: 0;
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  transition: opacity 300ms ease;
  cursor: pointer;
`;

export const SidebarLinkContainer = styled.div<{ isSelected?: boolean }>`
  position: relative;
  transition: background-color 300ms ease;
  ${SidebarSettingsButton} {
      opacity: ${(props) => props.isSelected && 1};
    }
  &:hover {
    background-color: #f2f2f2;
    ${SidebarSettingsButton} {
      opacity: 1;
    }
  }
`;

export const SidebarItemTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 10px;
`;
