import { FC } from "react";
import IconSvgWrapper from "components/icons/IconSvgWrapper";
import { IconProps } from "components/icons/lib";

const HideSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M11.8393 11.0688L9.53092 8.76048C10.9335 7.70466 11.8408 6.39976 11.9035 6.30827C12.0309 6.12245 12.0309 5.87747 11.9035 5.69169C11.7972 5.53645 9.26143 1.89006 5.99918 1.89006C5.05839 1.8901 4.17824 2.19355 3.39923 2.62875L0.93018 0.159777C0.717199 -0.0532407 0.371854 -0.0532771 0.1588 0.159777C-0.0542537 0.372831 -0.0542173 0.718139 0.1588 0.931156L2.46712 3.23944L9.22485 9.99713L11.0679 11.8402C11.1744 11.9467 11.314 12 11.4536 12C11.5932 12 11.7328 11.9467 11.8392 11.8402C12.0523 11.6272 12.0523 11.2819 11.8393 11.0688Z" />
      <path d="M0.0954679 5.69109C-0.0318408 5.87688 -0.0318044 6.12178 0.0954679 6.3076C0.201795 6.46287 2.73724 10.1093 6.00007 10.1093C6.53341 10.1093 7.04719 10.0116 7.53421 9.84777L1.63081 3.94434C0.712448 4.80252 0.144595 5.61935 0.0954679 5.69109Z" />
    </IconSvgWrapper>
  );
};

export default HideSvg;
