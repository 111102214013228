import { DateFormats } from "lib/theme/lib";
import moment from "moment";

export const maybePluralize = (count: number, noun: string, suffix = "s") =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;

export const getDownloadFilename = (title?: string, extension?: string) => {
  const filename = title?.replace(/[/\\?%*:|"<>]/g, "");
  const date = moment().format(DateFormats.ISO);

  return `${date}_${filename}.${extension}`;
};
