import { FC } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Tooltip } from "antd";

import { getBoardUri, getFileDetailUri, getProjectUri, getTaskDetailUri } from "lib/helpers/navigationUtils";
import { getUserFullName } from "lib/helpers/userUtils";
import { ContentType } from "lib/types/contentTypes";
import { NotificationInterface, NotificationStatus } from "lib/types/notificationTypes";
import TaskCheckmark from "pages/Task/TaskCheckmark";
import AvatarCustom from "components/ui/AvatarCustom";
import { NotificationCardContainer } from "./styled";
import { NotificationItemContent, NotificationItemDetails } from "../NotificationItem/styled";
import {
  NotificationsBoardIcon,
  NotificationsBoardName,
  NotificationsBoardWrapper,
  NotificationsButtonsWrapper,
  NotificationsCheckmarkWrapper,
  NotificationsCreated,
  NotificationsCreatedWrapper,
  NotificationsEventName,
  NotificationsEventWrapper,
  NotificationsHeader,
  NotificationsTaskTitle,
  NotificationsTaskWrapper,
  NotificationsUnreadCircle,
  NotificationsUserAvatarWrapper,
  NotificationsUserName, NotificationsVirtuosoContainer
} from "pages/Notifications/styled";
import { RenderNotificationMessage } from "../lib";
import { RenderNotificationButtons } from "../common";
import { DateFormats } from "lib/theme/lib";

interface NotificationCardProps {
  notification: NotificationInterface;
  closeDrawerFn: () => void;
}

const NotificationCard: FC<NotificationCardProps> = (props) => {
  const { closeDrawerFn, notification } = props;
  const { id, itemId, contentType, status, initiator, eventName, data, created_at } = notification;

  const taskLinkColor = data?.completed ? "#8c8c8c" : "#1890ff";
  const taskTitle = data?.title || "Untitled task";

  return (
    <NotificationsVirtuosoContainer>
      <NotificationCardContainer>
        <NotificationsHeader>
          {contentType === ContentType.Task && data &&
            <>
              {data?.board &&
                <NotificationsBoardWrapper>
                  <NotificationsBoardIcon src={`/images/${data.board.icon}.png`} alt={data.board.icon} />
                  <Link to={getBoardUri(data?.board?.uid || data?.board.id)} style={{ textDecoration: "none" }} onClick={closeDrawerFn}>
                    <NotificationsBoardName>{data.board.name}</NotificationsBoardName>
                  </Link>
                </NotificationsBoardWrapper>
              }
              <NotificationsTaskWrapper>
                <NotificationsCheckmarkWrapper>
                  <TaskCheckmark isCompleted={data.completed} />
                </NotificationsCheckmarkWrapper>
                <Link to={getTaskDetailUri(data.uid || data.id)} style={{ color: taskLinkColor, textDecoration: "none", overflow: "hidden" }} onClick={closeDrawerFn}>
                  <NotificationsTaskTitle>{taskTitle}</NotificationsTaskTitle>
                </Link>
              </NotificationsTaskWrapper>
            </>
          }
          {contentType === ContentType.Workspace && data &&
            <Link to={getProjectUri(data.uid || data.id)} onClick={closeDrawerFn}>
              <NotificationsBoardName>
                {data.name}
              </NotificationsBoardName>
            </Link>
          }
          {contentType === ContentType.FileContainer && data &&
            <Link to={getFileDetailUri(data.uid || data.id || itemId)} onClick={closeDrawerFn}>
              <NotificationsBoardName>
                {data.title || "Untitled"}
              </NotificationsBoardName>
            </Link>
          }
        </NotificationsHeader>
        <NotificationsEventWrapper>
          <NotificationItemContent>
            <NotificationsUserAvatarWrapper>
              <AvatarCustom user={initiator} tooltip />
            </NotificationsUserAvatarWrapper>
            <div>
              <NotificationItemDetails>
                <NotificationsUserName>{getUserFullName(initiator)}</NotificationsUserName>
                <NotificationsEventName>{RenderNotificationMessage(eventName, contentType)}</NotificationsEventName>
              </NotificationItemDetails>
              <NotificationsCreatedWrapper>
                <Tooltip title={moment(created_at).format(DateFormats.HumanFull)}>
                  <NotificationsCreated>
                    {moment(created_at).format(DateFormats.Friendly)}
                  </NotificationsCreated>
                </Tooltip>
              </NotificationsCreatedWrapper>
            </div>
          </NotificationItemContent>
        </NotificationsEventWrapper>
        {status === NotificationStatus.New && (
          <NotificationsUnreadCircle />
        )}
        <NotificationsButtonsWrapper>
          {RenderNotificationButtons(id, status)}
        </NotificationsButtonsWrapper>
      </NotificationCardContainer>
    </NotificationsVirtuosoContainer>
  );
};

export default NotificationCard;
