import { InputNumber, Select } from "antd";
import { SelectFieldStatusOption } from "components/blocks/FormComponents/SelectField/styled";
import useCurrentBoardCollaborators from "lib/customHooks/useCurrentBoardCollaborators";
import useCurrentBoardStatuses from "lib/customHooks/useCurrentBoardStatuses";
import { getUserFullName } from "lib/helpers/userUtils";
import { useContext, useEffect, useState } from "react";
import { SectionSubheading } from "styles/common";
import { Action, TActiveTab } from "../../../types";
import { AutomationActiveTabContext, AutomationFormContext, RemovePropertyContext } from "../../automationContext";
import { ActionCard, AutomationsBuilderContent } from "../../styled";
import Card, { TCardOption } from "../Card/Card";
import { AutomationsFormItem } from "../Card/styled";

const { Option } = Select;

const Default = () => {
  const { onClickActionHeader } = useContext(RemovePropertyContext);
  const activeTab = useContext(AutomationActiveTabContext);
  return (
    <ActionCard onClick={onClickActionHeader} activeCard={activeTab === TActiveTab.Actions}>
      Select an action
    </ActionCard>
  );
};

const Completed = () => {
  const options = [
    { value: "true", text: "Complete task" },
    { value: "false", text: "Mark task incomplete" }
  ];
  return (
    <Card
      type="updateValue"
      header="Completion status"
      helpText="Choose status"
      options={options}
    />
  );
};

const Assignees = () => {
  const collaborators = useCurrentBoardCollaborators();
  const collaboratorsOptions = collaborators.map(collaborator => ({
    value: collaborator.id,
    text: getUserFullName(collaborator) || ""
  }));
  const options = [
    { value: "multi", text: "Assign task" },
    { value: "clear", text: "Unassign task" }
  ];
  const [value, setValue] = useState<number[]>([]);
  const [selectValue, setSelectValue] = useState("multi");
  const [isShowDays, setIsShowDays] = useState(true);

  const formContext = useContext(AutomationFormContext);
  const fieldValue = formContext?.getFieldValue("updateValue");

  useEffect(() => {
    if (fieldValue === "clear") {
      setSelectValue("clear");
      setIsShowDays(false);
    } else if (Array.isArray(fieldValue)) {
      setSelectValue("multi");
      setValue(fieldValue);
    }
  }, [fieldValue]);

  const onChangeSelect = (selectValue: string) => {
    setSelectValue(selectValue);
    if (selectValue === "multi") {
      setIsShowDays(true);
      setValue([]);
      formContext?.setFieldsValue({
        updateValue: []
      });
    } else {
      setIsShowDays(false);
      formContext?.setFieldsValue({
        updateValue: "clear"
      });
    }
  };

  const onChange = (value: number[]) => {
    setValue(value);
    formContext?.setFieldsValue({
      updateValue: value
    });
  };

  return (
    <Card
      type="updateValue"
      cardType="custom"
      header="Assignees"
    >
      <AutomationsBuilderContent>
        <Select
          value={selectValue}
          onChange={onChangeSelect}
          dropdownStyle={{ minWidth: 120 }}
          style={{ width: "100%" }}
        >
          {options.map(option => (
            <Option key={option.value} value={option.value}>{option.text}</Option>
          ))}
        </Select>
        <AutomationsFormItem name="updateValue" initialValue={[]} hidden={!isShowDays}>
          <SectionSubheading>Choose assignees</SectionSubheading>
          <Select
            allowClear
            filterOption={(input, option) =>
              String(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={value}
            mode="multiple"
            onChange={onChange}
            dropdownStyle={{ minWidth: 120 }}
            style={{ width: "100%" }}
          >
            {collaboratorsOptions.map(option => (
              <Option key={option.value} value={option.value}>{option.text}</Option>
            ))}
          </Select>
        </AutomationsFormItem>
      </AutomationsBuilderContent>
    </Card>
  );
};

const DueDate = () => {
  const [value, setValue] = useState(0);
  const options = [
    { value: "number", text: "Set due date" },
    { value: "clear", text: "Clear due date" }
  ];
  const [selectValue, setSelectValue] = useState("number");
  const [isShowDays, setIsShowDays] = useState(true);

  const formContext = useContext(AutomationFormContext);
  const fieldValue = formContext?.getFieldValue("updateValue");

  useEffect(() => {
    if (fieldValue === "clear") {
      setSelectValue("clear");
      setIsShowDays(false);
    } else if (!isNaN(Number(fieldValue))) {
      setSelectValue("number");
      setValue(Number(fieldValue));
    }
  }, [fieldValue]);

  const onChangeSelect = (selectValue: string) => {
    setSelectValue(selectValue);
    if (selectValue === "number") {
      setIsShowDays(true);
      setValue(0);
      formContext?.setFieldsValue({
        updateValue: 0
      });
    } else {
      setIsShowDays(false);
      formContext?.setFieldsValue({
        updateValue: "clear"
      });
    }
  };

  const onChange = (value: number) => {
    setValue(value);
    formContext?.setFieldsValue({
      updateValue: value
    });
  };

  return (
    <Card
      type="updateValue"
      cardType="custom"
      header="Due date"
    >
      <AutomationsBuilderContent>
        <Select
          value={selectValue}
          onChange={onChangeSelect}
          dropdownStyle={{ minWidth: 120 }}
          style={{ width: "100%" }}
        >
          {options.map(option => (
            <Option key={option.value} value={option.value}>{option.text}</Option>
          ))}
        </Select>
        <AutomationsFormItem name="updateValue" initialValue={0} hidden={!isShowDays}>
          <SectionSubheading>Choose a number of days in the future</SectionSubheading>
          <InputNumber
            value={value}
            onChange={(value) => onChange(value as number)}
            style={{ width: "100%" }}
          />
        </AutomationsFormItem>
      </AutomationsBuilderContent>
    </Card>
  );
};

const Status = () => {
  const statuses = useCurrentBoardStatuses();
  const options: Array<TCardOption> = statuses.map(status => ({
    value: String(status.id),
    text: status.title || "",
    renderFn: () => (
      <Option value={String(status.id)} key={String(status.id)}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <SelectFieldStatusOption color={status.color} />
          {status?.title}
        </div>
      </Option>
    )
  }));

  return (
    <Card
      type="updateValue"
      header="Set status to"
      helpText="Choose status"
      options={options}
    />
  );
};

const Collaborators = () => {
  const collaborators = useCurrentBoardCollaborators();
  const options = collaborators.map(collaborator => ({
    value: collaborator.id,
    text: getUserFullName(collaborator) || ""
  }));

  return (
    <Card
      type="updateValue"
      header="Add followers"
      helpText="Choose followers"
      cardType="multiselect"
      options={options}
    />
  );
};

const Comments = () => (
  <Card
    cardType="textarea"
    type="updateValue"
    helpText="Write a comment"
    header="Add a comment"
  />
);

const Actions = {
  [Action.default]: <Default />,
  [Action.completed]: <Completed />,
  [Action.assignees]: <Assignees />,
  [Action.dueDate]: <DueDate />,
  [Action.status]: <Status />,
  [Action.collaborators]: <Collaborators />,
  [Action.comments]: <Comments />,
  [Action.createProjectTaskItem]: <Assignees />,
  [Action.createManpowerLog]: <Assignees />,
  [Action.createPunchItem]: <Assignees />,
  [Action.createRFI]: <Assignees />
};

export default Actions;
