import styled from "@emotion/styled/macro";
import { Spin } from "antd";

export const Footer = styled.div`
  margin: 24px -24px -24px;
  padding: 10px 16px;
  text-align: right;
  border-top: 1px solid #f0f0f0;
`;

export const CustomSpin = styled(Spin) <{ disabled?: boolean }>`
  display: ${({ disabled }) => disabled ? "inline" : "none"};
`;
