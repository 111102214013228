import { Select } from "antd";
import { selectFilterTypes, setFilterFunction, setFilterFunctionMultiple } from "app/slices/tasksFilterAndSortSlice";
import { useAppDispatch, useAppSelector } from "app/store";
import { getMappedFilterFunction, TFilterFunctions } from "lib/filters/filterFunctions";
import { TAnyFilters } from "lib/filters/filters";
import React, { FC } from "react";

type FilterFunctionProps = {
  currentFilter: TAnyFilters
  id?: string // if component has an id, that means the component is work with multiple filter
}

const FilterFunctions: FC<FilterFunctionProps> = (props) => {
  const dispatch = useAppDispatch();
  const { currentFilter, id } = props;
  const types = useAppSelector(selectFilterTypes);
  const filterKeys = getMappedFilterFunction(currentFilter, types);

  const onSelectFunction = (filterFunction: TFilterFunctions) => {
    id
      ? dispatch(setFilterFunctionMultiple({ function: filterFunction, id }))
      : dispatch(setFilterFunction(filterFunction));
  };

  if (!filterKeys) {
    return null;
  }

  return (
    <Select
      disabled={filterKeys.length === 0}
      style={{ width: 110, marginRight: 7 }}
      filterOption={(input, option) =>
        String(option?.value)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      value={currentFilter.function}
      onSelect={(item: TFilterFunctions) => onSelectFunction(item)}
      dropdownStyle={{ minWidth: 140 }}
    >
      {filterKeys?.map(item => {
        return (
          <Select.Option value={item} key={item}>
            {item}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default FilterFunctions;
