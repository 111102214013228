import { useHistory } from "react-router-dom";
import { useAppSelector } from "app/store";

const useAuthorizedRedirect = (): void => {
  const history = useHistory();
  const jwt = useAppSelector(state => state.auth.token);

  if (jwt) {
    history.push("/home");
  }
};

export default useAuthorizedRedirect;
