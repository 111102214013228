import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useLocationIncludes = (subLocation: string): boolean => {
  const location = useLocation();
  const [isIncludes, setIsIncludes] = useState(false);

  useEffect(() => {
    if (location.pathname.includes(subLocation)) {
      setIsIncludes(true);
    } else {
      setIsIncludes(false);
    }
  }, [location.pathname, subLocation]);

  return isIncludes;
};

export default useLocationIncludes;