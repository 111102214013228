import { FC, useCallback } from "react";
import { createReactEditorJS } from "react-editor-js";

import { FormItem } from "../styled";
import { EditorJsCore } from "components/blocks/EditorJS/lib";
import { EditorJSInitialState, EditorJsTaskDescriptionConfig } from "components/blocks/EditorJS/tools";

interface TextAreaFieldProps {
  label?: string;
  onChange?: (value: any) => void;
  editorRef: React.MutableRefObject<EditorJsCore | null>;
}

const TextAreaField: FC<TextAreaFieldProps> = (props) => {
  const { editorRef, label } = props;

  const ReactEditorJS = createReactEditorJS();

  const handleInitialize = useCallback((instance: EditorJsCore) => {
    editorRef.current = instance;
  }, [editorRef]);

  return (
    <FormItem label={label}>
      <ReactEditorJS
        defaultValue={EditorJSInitialState}
        tools={EditorJsTaskDescriptionConfig}
        onInitialize={handleInitialize}
      />
    </FormItem>
  );
};

export default TextAreaField;
