import { isEmpty } from "lodash";
import { BoardInterface, BoardInterfaceExtended, DefaultColumnCodes } from "lib/types/boardTypes";
import { BoardUnits, DefaultBoardUnit } from "pages/Board/BoardFields/lib";
import { BoardDefaultFeatures } from "pages/Board/BoardSettings/BoardSettingsFeatures/lib";
import { Column } from "lib/types/tasksTypes";
import { TaskPropertyDateTimeFormat, TaskPropertyTimeFormat } from "lib/types/kanbanTypes";
import { PropertyValueType } from "lib/types/dataTypes";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import { getColumnPrecision } from "lib/helpers/boardUtils";

export const useBoardFeatures = (board?: BoardInterface | BoardInterfaceExtended | null) => {

  const features = (isEmpty(board?.features) || !board) ? BoardDefaultFeatures : board.features;

  const isCompletionEnabled = Boolean(features.completion);
  const isStatusEnabled = Boolean(features.statuses);
  const isAssigneesEnabled = Boolean(features.assignees);
  const isDueDatesEnabled = Boolean(features.dueDates);
  const isModelsEnabled = Boolean(features.models);
  const isAnnotationsEnabled = Boolean(features.annotations);

  const terminology = BoardUnits.find(unit => unit.code === features.unit) || DefaultBoardUnit;

  const excludedColumns: Array<string> = [
    ...(!isCompletionEnabled ? [DefaultColumnCodes.Completion] : []),
    ...(!isAssigneesEnabled ? [DefaultColumnCodes.Assignees] : []),
    ...(!isDueDatesEnabled ? [DefaultColumnCodes.DueDate] : []),
    ...(!isStatusEnabled ? [DefaultColumnCodes.Status] : []),
    ...(!isModelsEnabled ? [DefaultColumnCodes.Viewpoints] : []),
    ...(!isAnnotationsEnabled ? [DefaultColumnCodes.Annotations] : []),
  ];

  const insertColumns: Array<Column> = [
    {
      id: DefaultColumnCodes.Subtasks,
      title: "Checklists",
      code: DefaultColumnCodes.Subtasks,
      sort: -1,
      hidden: false,
      width: 300,
      type: PropertyValueType.Subtasks,
      class: TaskPropertyClass.Default,
      active: true,
      dateFormat: TaskPropertyDateTimeFormat.Default,
      timeFormat: TaskPropertyTimeFormat.None,
      currency: "",
      precision: getColumnPrecision(),
      accessor: PropertyValueType.Subtasks,
      formula: null,
      maxWidth: 0,
      options: [],
    },
  ];

  return {
    isCompletionEnabled,
    isStatusEnabled,
    isAssigneesEnabled,
    isDueDatesEnabled,
    isModelsEnabled,
    terminology,
    excludedColumns,
    insertColumns,
  };
};
