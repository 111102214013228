import React, { FC } from "react";
import { BoardFormFieldContainer, BoardFormFieldPlusContainer } from "pages/Board/BoardForm/styled";
import { Column } from "lib/types/tasksTypes";
import { PropertyValueType } from "lib/types/dataTypes";
import TaskFieldIcon from "components/blocks/TaskFields/FieldIcons";
import PlusSvg from "components/ui/ButtonControls/icons/plus";
import { Tooltip } from "antd";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";

interface BoardFormHiddenFieldProps {
  column: Column;
  onClick?: React.MouseEventHandler;
}

const BoardFormHiddenField: FC<BoardFormHiddenFieldProps> = (props) => {
  const { column } = props;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);

  return (
    <BoardFormFieldContainer {...props} allowedToEdit={allowedToEdit}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <TaskFieldIcon propertyValueType={column.type as PropertyValueType} />
        <div style={{ marginLeft: 7 }}>
          {column.title}
        </div>
      </div>
      {allowedToEdit &&
        <Tooltip title="Add to form">
          <BoardFormFieldPlusContainer>
            <PlusSvg />
          </BoardFormFieldPlusContainer>
        </Tooltip>
      }
    </BoardFormFieldContainer>
  );
};

export default BoardFormHiddenField;
