import React, { FC, useEffect, useState } from "react";
import { Input } from "antd";
import { cloneDeep } from "lodash";

import TextField from "components/blocks/FormComponents/TextField";
import ColorPickerField from "components/blocks/FormComponents/ColorPickerField";
import { TaskCommentEditControl } from "pages/Task/TaskComment/styled";
import { FormItem } from "components/blocks/FormComponents/styled";

import {
  SelectOptionRow,
  SelectOptionHiddenElements,
  NumberField,
  OptionInner,
  Dragger,
} from "./styled";

const SelectOption: FC<any> = (props) => {
  const { index, fieldIndex, form, removeOption, dragHandleProps, onAdd, selectName } = props;
  const [currentOption, setCurrentOption] = useState<any>(null);
  const isDisabledOption = currentOption && currentOption?.system;

  useEffect(() => {
    const fields = form.getFieldsValue(true);
    const options = cloneDeep(fields.options);

    options[fieldIndex].index = index;
    setCurrentOption(options[fieldIndex]);

    form.setFieldsValue({ [selectName]: options });
  }, [index]);

  const onOptionRemove = () => removeOption(index);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // @ts-ignore
      event.target.blur();
      onAdd();
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value) {
      onAdd();
    }
  };

  return (
    <SelectOptionRow>
      <OptionInner>
        <Dragger {...dragHandleProps} >
          <SelectOptionHiddenElements>
            <img src={process.env.PUBLIC_URL + "/icons/drag.svg"} alt="drag" />
          </SelectOptionHiddenElements>
        </Dragger>
        <FormItem
          name={[selectName, fieldIndex, "index"]}
          initialValue={index}
          hidden
        >
          <Input size="small" />
        </FormItem>
        <NumberField name={[selectName, fieldIndex, "id"]} />
        <ColorPickerField
          name={[selectName, fieldIndex, "color"]}
          form={form}
          disabled={isDisabledOption}
        />
        <div style={{ flexGrow: 1 }}>
          <TextField
            name={[selectName, fieldIndex, "title"]}
            placeholder="Type an option name"
            bordered={false}
            disabled={isDisabledOption}
            rules={[{ required: true, whitespace: true }]}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            autoFocus
          />
        </div>
      </OptionInner>
      {!isDisabledOption && (
        <SelectOptionHiddenElements>
          <TaskCommentEditControl
            onClick={onOptionRemove}
            style={{ margin: 0 }}
          >
            <img alt="" src={process.env.PUBLIC_URL + "/icons/close_6px.svg"} />
          </TaskCommentEditControl>
        </SelectOptionHiddenElements>
      )}
    </SelectOptionRow>
  );
};

export default SelectOption;
