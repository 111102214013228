import styled from "@emotion/styled/macro";

export const UserCustomWrapper = styled.div`
  display: flex;
  align-items: center
`;

export const UserCustomAvatar = styled.div`
  margin-right: 5px;
  height: 24px;
  .ant-avatar-sm {
    font-size: 12px !important;
  }
`;

export const UserCustomName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;
