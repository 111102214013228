import { TaskInterface } from "../../types/tasksTypes";
import { FilterFunction } from "../filterFunctions";
import { FilterValueNone, FilterVersion, TAnyFilters } from "../filters";
import { FilterType } from "../filterTypes";

const getTargetFilterProperty = (task: TaskInterface, currentFilterTitleKey?: string, isTitleField?: boolean) => {
  if (isTitleField) {return task.title;}

  if (currentFilterTitleKey === FilterType.uID) {
    return task.uid;
  }

  return task.propertyValues.find(value => value.title === currentFilterTitleKey)?.value;
};

const filterV1 = (tasks: TaskInterface[], currentFilter: TAnyFilters, filterText: string, isTitleField?: boolean) => {
  return tasks.filter(task => {
    const customProperty = getTargetFilterProperty(task, currentFilter.titleKey, isTitleField);
    return String(customProperty).toLowerCase().trim().includes(filterText.toLowerCase().trim());
  });
};

const filterV2 = (tasks: TaskInterface[], currentFilter: TAnyFilters, filterText: string, isTitleField?: boolean) => {
  switch (currentFilter.function) {
    case FilterFunction.Contains: {
      return tasks.filter(task => {
        const customProperty = getTargetFilterProperty(task, currentFilter.titleKey, isTitleField);
        return String(customProperty).toLowerCase().trim().includes(filterText.toLowerCase().trim());
      });
    }

    case FilterFunction["Does not contain"]: {
      return tasks.filter(task => {
        const customProperty = getTargetFilterProperty(task, currentFilter.titleKey, isTitleField);
        return !String(customProperty).toLowerCase().trim().includes(filterText.toLowerCase().trim());
      });
    }

    case FilterFunction.Is: {
      return tasks.filter(task => {
        const customProperty = getTargetFilterProperty(task, currentFilter.titleKey, isTitleField);
        return String(customProperty).toLowerCase().trim() === filterText.toLowerCase().trim();
      });
    }

    case FilterFunction["Is not"]: {
      return tasks.filter(task => {
        const customProperty = getTargetFilterProperty(task, currentFilter.titleKey, isTitleField);
        return String(customProperty).toLowerCase().trim() !== filterText.toLowerCase().trim();
      });
    }

    case FilterFunction["Starts with"]: {
      return tasks.filter(task => {
        const customProperty = getTargetFilterProperty(task, currentFilter.titleKey, isTitleField);
        return String(customProperty).toLowerCase().trim().startsWith(filterText.toLowerCase().trim());
      });
    }

    case FilterFunction["Ends with"]: {
      return tasks.filter(task => {
        const customProperty = getTargetFilterProperty(task, currentFilter.titleKey, isTitleField);
        return String(customProperty).toLowerCase().trim().endsWith(filterText.toLowerCase().trim());
      });
    }

    case FilterFunction["Is empty"]: {
      return tasks.filter(task => {
        const customProperty = getTargetFilterProperty(task, currentFilter.titleKey, isTitleField);
        return !customProperty;
      });
    }

    case FilterFunction["Is not empty"]: {
      return tasks.filter(task => {
        const customProperty = getTargetFilterProperty(task, currentFilter.titleKey, isTitleField);
        return !!String(customProperty);
      });
    }

    default: {
      return tasks;
    }
  }
};

export const textFilter = {
  filterValues: (...args: any[]) => [],
  filterFn: (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
    const filterText = currentFilter.value.text;

    if (!filterText || filterText === FilterValueNone) {
      if (currentFilter.function === "Is empty" || currentFilter.function === "Is not empty") {
        return filterV2(tasks, currentFilter, filterText, currentFilter.titleKey === "Title");
      }
      return tasks;
    }

    if (currentFilter.version && currentFilter.version !== FilterVersion.v1) {
      return filterV2(tasks, currentFilter, filterText, currentFilter.titleKey === "Title");
    }

    return filterV1(tasks, currentFilter, filterText, currentFilter.titleKey === "Title");
  }
};
