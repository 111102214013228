import { FC } from "react";
import { Form, Select } from "antd";
import ReusableSelectOption from "components/blocks/ReusableFields/ReusableMultiSelectOption";
import { getUserFullName } from "lib/helpers/userUtils";
import { InputPlaceholders } from "lib/theme/lib";
import { UserInterface } from "lib/types/types";
import { FormComponentProps } from "../lib";
import { EntityId } from "lib/types/entity";

interface UserMultipleProps extends FormComponentProps {
  members?: Array<UserInterface>;
}

const UserMultiple: FC<UserMultipleProps> = (props) => {
  const { fieldName, members } = props;
  const form = Form.useFormInstance();
  const initialValues = (form.getFieldValue(fieldName) ?? []).map((value: UserInterface) => value.id);

  const handleSelectOnChange = (value: Array<EntityId>) => {
    const userIds = (value || [])?.map(item => String(item));
    const nextState = members?.filter(member => userIds.includes(String(member.id)));

    form.setFields([{ name: fieldName, value: nextState }]);
  };

  return (
    <Select
      mode="multiple"
      placeholder={InputPlaceholders.Empty}
      allowClear
      showArrow
      showSearch
      defaultValue={initialValues}
      onChange={handleSelectOnChange}
      filterOption={(input, option) =>
        String(option?.label)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {members?.map(user => (
        <Select.Option label={getUserFullName(user)} value={user.id} key={user.id} >
          <ReusableSelectOption selectTitle={getUserFullName(user)} selectColor={user.color || undefined} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default UserMultiple;
