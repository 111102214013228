import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { Tag } from "antd";

export const HomeInviteModalDesc = styled.div`
  display: inline;
  font-size: 13px;
  color: #999;
  a {
    color: #999;
    &:hover {
      color: #ccc;
    }
  }
`;

export const HomeInviteModalGrid = styled.div<{ tagColor?: string }>`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 125px) auto;
  grid-column-gap: 10px;
  margin-block: 10px;
  border-radius: 7px;
  .ant-tag {
    border-radius: 12px;
  }
`;

export const HomeInviteModalTag = styled(Tag)<{ crossColor?: string }>`
  ${({ crossColor }) => crossColor === "red" && css`
    path {
      fill: #cf1322; !important;
    }
  `}
`;
