import styled from "@emotion/styled/macro";
import { CircleDeleteButtonContainer } from "components/blocks/CircleDeleteButton/styled";
import { ViewpointLikeCardOuterContainer } from "components/blocks/ViewpointLikeCard/styled";

export const FilesGenericViewpointWrapper = styled(ViewpointLikeCardOuterContainer)`
  .ant-image {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
`;

export const FilesGenericRelativeContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  &:hover {
    ${CircleDeleteButtonContainer} {
      opacity: 1;
    }
  }
`;
