import React from "react";
import { Skeleton } from "antd";

const COLUMNS_NUMBER = Math.floor(Math.random() * (5 - 3) ) + 3;

const renderSkeletonData = (n: number) => {
  const elements = [];
  for (let i = 0; i < n; i++) {
    const col = [];
    const cards = Math.floor(Math.random() * (7 - 2) ) + 2;
    for (let j = 0; j < cards; j++) {
      col.push(
        <div key={`${j}-avatar`} style={ { margin: "15px 0" } }>
          <Skeleton.Avatar active style={ { width: "240px", height: "86px", borderRadius: "7px" } }/>
        </div>
      );
    }
    const status = Math.floor(Math.random() * (110 - 70) ) + 70;
    elements.push(
      <div key={`${i}-button`} style={ { margin: "15px" } }>
        <Skeleton.Button active size="small" shape="round" style={ { width: `${status}px` } }/>
        {col}
      </div>
    );
  }
  return elements;
};

const SkeletonKanban = () => (
  <div style={ { display: "flex", margin: "0 25px" } }>
    {renderSkeletonData(COLUMNS_NUMBER)}
  </div>
);

export default SkeletonKanban;
