import styled from "@emotion/styled/macro";
import { dataInput } from "components/blocks/TaskTable/styled";
import { fieldDetailedHover, fieldDetailedWrapper } from "../styled";
import { SyncedFieldTooltipIcon } from "components/blocks/SyncedFieldTooltip/styled";

export const SingleSelectListWrapper = styled.div<{ clickable?: boolean }>`
  ${dataInput};
  cursor: ${({ clickable }) => clickable ? "pointer" : "default"};
  &:hover {
    ${SyncedFieldTooltipIcon} {
      display: block;
    }
  }
`;

export const SingleSelectDetailedWrapper = styled.div<{ clickable?: boolean }>`
  ${fieldDetailedWrapper};
  ${fieldDetailedHover};
  width: 100%;
  cursor: ${({ clickable }) => clickable ? "pointer" : "default"};
  &:hover {
    ${SyncedFieldTooltipIcon} {
      display: block;
    }
  }
`;
