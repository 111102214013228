import styled from "@emotion/styled/macro";
import { Input, Menu } from "antd";
import { css } from "@emotion/css";

export const FormPublicDropdownItem = styled(Menu.Item)`
  background-color: white !important;
  &:hover {
    background-color: transparent;
    cursor: text;
  }
  .ant-dropdown-menu-item-active {
    background-color: transparent !important;
  }
`;

export const FormPublicDropdownTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #4d4d4d;
  margin-bottom: 5px;
`;

export const FormPublicDropdownDescription = styled.div`
  font-size: 12px;
  line-height: 1.4;
  color: #999;
`;

export const FormPublicDropdownInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  overflow: hidden;
  background-color: #f5f5f5;
  padding-right: 7px;
`;

export const FormPublicDropdownInput = styled(Input)`
  border: none;
  font-size: 13px;
  color: #444444 !important;
  cursor: text !important;
  background-color: transparent !important;
`;

export const FormPublicDropdownIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding-inline: 6px;
  cursor: pointer;
`;

export const FormPublicLoaderContainer = styled.div`
  width: 300px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const activeButtonClassShare = css`
  background-color: #e6f8f1 !important;
  color: #0d7f56 !important;
  path {
    fill: #0d7f56 !important;
  }
`;

export const activeButtonClassSave = css`
  background-color: #efebfd !important;
  color: #8966f6 !important;
  path {
    fill: #8966f6; !important;
  }
`;
