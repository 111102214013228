import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const MediumSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M70 50C70 61.0457 61.0457 70 50 70C38.9543 70 30 61.0457 30 50C30 38.9543 38.9543 30 50 30C61.0457 30 70 38.9543 70 50Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default MediumSvg;
