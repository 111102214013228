import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import { EntityId } from "./entity";
import { TaskSetValueHandler } from "./tasksTypes";

export enum DisplayView {
  Project = "project",
  ProjectPreview = "projectpreview",
  List = "list",
  Form = "form",
  Kanban = "kanban",
  Calendar = "calendar",
  Model = "model",
  Files = "files",
  Detailed = "detailed",
  MyTasks = "mytasks",
}

export enum DisplayMode {
  Grid = "grid",
  List = "list",
}

export enum BoardFeatureCodes {
  Completion = "completion",
  Statuses = "statuses",
  Assignees = "assignees",
  DueDates = "dueDates",
  Models = "models",
  Annotations = "annotations",
  Unit = "unit",
}

export enum TaskSourceComponent {
  Card = "card",
  Cell = "cell",
  Detailed = "detailed",
}

export interface TaskFieldAbstract {
  taskId?: EntityId;
  columnId?: EntityId;
  displayView: DisplayView;
  component: TaskSourceComponent;
  propertyType: TaskPropertyClass;
  boardStatuses?: Array<any>;
}

export interface TaskFieldSetterGetter {
  value: any;
  setValue: TaskSetValueHandler;
  code: string;
  taskId?: EntityId;
}

export type DropdownTrigger = "click" | "hover" | "contextMenu";

export type TaskFieldPropsGeneric = TaskFieldAbstract & TaskFieldSetterGetter

export interface ApplicationInstanceInfo {
  id: number;
  name: number;
  total: string;
}

export interface ApplicationVersionResponse {
  message: string;
  version: string;
  name: string;
  timestamp: number;
}

export interface ApplicationHealthResponse {
  message: string;
  uptime: number;
  responseTime: number[];
  timestamp: number;
}

export interface ApplicationAuditLogPayload {
  origin: string;
  action: string;
  label: string;
  object: string;
  description: Record<string, any>;
}
