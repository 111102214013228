import { FC } from "react";

import { IconProps } from "components/icons/lib";
import { FileIconExtensionMap } from "./lib";

interface FileIconWithExtensionProps extends IconProps {
  ext: string;
}

const FileIconWithExtension: FC<FileIconWithExtensionProps> = (props) => {
  const { ext, ...iconProps } = props;

  const parsedExt = ext.replace(/[^a-z0-9]/gi, "");
  const IconComponent = FileIconExtensionMap[parsedExt] ?? FileIconExtensionMap.fallback;

  return (
    <IconComponent {...iconProps} />
  );
};

export default FileIconWithExtension;
