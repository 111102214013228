import { FC } from "react";
import FilterSvg from "components/ui/ButtonControls/icons/filter";
import {
  ButtonControlsWrapper,
  ButtonControlsButton,
  ButtonControlsContent,
  ButtonControlsIcon,
  ButtonControlsText
} from "./styled";

interface ButtonControlsProps {
  text?: string;
  icon?: React.ReactNode;
  color?: string;
  onClick?: any;
  className?: any;
  buttonStyle?: any;
  value?: string;
}

const ButtonControls: FC<ButtonControlsProps> = (props) => {
  const icon = props?.icon || <FilterSvg />;
  const color = props?.color || "";
  const value = props?.value || "";

  return (
    <ButtonControlsWrapper>
      <ButtonControlsButton
        className={props.className || ""}
        onClick={props.onClick}
        style={props.buttonStyle}
        value={value}
      >
        <ButtonControlsContent style={{ color: `${color}` }}>
          {!!props.icon && (
            <ButtonControlsIcon hasText={!!props.text?.length}>
              {icon}
            </ButtonControlsIcon>
          )}
          {!!props.text && !!props.text.length && (
            <ButtonControlsText>
              {props.text}
            </ButtonControlsText>
          )}
        </ButtonControlsContent>
      </ButtonControlsButton>
    </ButtonControlsWrapper>
  );
};

export default ButtonControls;
