import { FC, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import algoliasearch from "algoliasearch";
import { InstantSearch } from "react-instantsearch";

import useLogRocket from "lib/customHooks/useLogRocket";
import useSentry from "lib/customHooks/useSentry";
import useWebsocket from "lib/customHooks/useWebsocket";
import { AppThemeProvider } from "lib/theme/context";
import { DefaultAppTheme } from "lib/theme/lib";
import configuration from "lib/configs/configuration";
import { useAppSelector } from "./app/store";
import Progress from "./components/ui/Progress/Progress";
import Routes from "./Routes";

import "./styles/antd.global.css";
import "./styles/global.css";
import "overlayscrollbars/css/OverlayScrollbars.css";

const App: FC = () => {
  const searchClient = algoliasearch(configuration.algolia.appId, configuration.algolia.apiKey);
  const searchIndexName = `${configuration.app.debugEnv}_task`;
  const isLoading = useAppSelector(state => state.progress.isLoading);

  useWebsocket();
  useSentry();
  useLogRocket();

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <Progress isAnimating={isLoading} />
        <AppThemeProvider theme={DefaultAppTheme}>
          <InstantSearch searchClient={searchClient} indexName={searchIndexName}>
            <Routes />
          </InstantSearch>
        </AppThemeProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
