import {
  BoardAutomationInterface,
  BoardDisplayView,
  BoardFeatures,
  BoardInterface,
  BoardInterfaceExtended,
  BoardViewInterface,
  RecordTemplateInterface
} from "lib/types/boardTypes";
import { EntityId } from "lib/types/entity";
import { JSendResponse } from "lib/types/jsend";
import { Column } from "lib/types/tasksTypes";
import HttpService from "./HttpService";

class BoardService extends HttpService {
  constructor() {
    super("boards");
  }

  async getAll() {
    const data: BoardInterface[] | undefined = await this._get({});

    return data || [];
  }

  async getById(id: EntityId) {
    const data: BoardInterfaceExtended | undefined = await this._get({
      additionalUrl: `/${id}`
    });

    return data || null;
  }

  async create(payload: Partial<BoardInterface> = {}, templateId?: string) {
    const additionalUrl = templateId ? `?templateId=${templateId}` : undefined;

    const data: JSendResponse = await this._post({
      additionalUrl,
      body: payload,
    });

    return data;
  }

  async duplicate(sourceId: EntityId, targetProjectId: EntityId) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${sourceId}/duplicate`,
      body: {
        workspace: targetProjectId,
      }
    });

    return data;
  }

  async updateBoard(id: EntityId, boardData: Partial<BoardInterfaceExtended | BoardInterface>) {
    const data: JSendResponse = await this._put({
      body: boardData,
      additionalUrl: `/${id}`
    });

    return data;
  }

  async deleteBoard(id: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}`
    });

    return data;
  }

  async addBoardColumn(id: EntityId, column: Partial<Column>) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/columns`,
      body: column
    });

    return data;
  }

  async updateBoardColumn(id: EntityId, columnId: EntityId, column: Partial<Column>) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${String(id)}/columns/${String(columnId)}`,
      body: column
    });

    return data;
  }

  async deleteBoardColumn(id: EntityId, columnId: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${String(id)}/columns/${String(columnId)}`,
    });

    return data;
  }

  async reorderBoardColumns(id: EntityId, payload: Array<Partial<Column>>) {
    const data: JSendResponse = await this._patch({
      additionalUrl: `/${id}/columns/order`,
      body: payload
    });

    return data;
  }

  async activateBoardColumn(id: EntityId, columnCode: string) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/columns/${columnCode}/activate`,
    });

    return data;
  }

  async deactivateBoardColumn(id: EntityId, columnCode: string) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/columns/${columnCode}/deactivate`,
    });

    return data;
  }

  async externalSyncEnable(id: EntityId, connectionId: EntityId) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/sync`,
      body: {
        connection: connectionId
      }
    });

    return data;
  }

  async externalSyncDisable(id: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}/sync`,
    });

    return data;
  }

  async externalSyncProcess(id: EntityId) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/sync/now`,
    });

    return data;
  }

  async addAutomation(id: EntityId, automationData: Partial<BoardAutomationInterface>) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/automations`,
      body: { ...automationData, contentType: "task" }
    });

    return data;
  }

  async updateAutomation(id: EntityId, automationId: EntityId, automationData: Partial<BoardAutomationInterface>) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}/automations/${automationId}`,
      body: { ...automationData, contentType: "task" },
    });

    return data;
  }

  async deleteAutomation(id: EntityId, automationId: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}/automations/${automationId}`,
    });

    return data;
  }

  async viewsCreate(id: EntityId, payload: Partial<BoardViewInterface>) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/views`,
      body: payload,
    });

    return data;
  }

  async viewsUpdate(id: EntityId, viewId: EntityId, payload: Partial<BoardViewInterface>) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}/views/${viewId}`,
      body: payload,
    });

    return data;
  }

  async viewsDelete(id: EntityId, viewId: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}/views/${viewId}`,
    });

    return data;
  }

  async viewsReorder(id: EntityId, payload: Array<{ id: EntityId, sort: number }>) {
    const data: JSendResponse = await this._patch({
      additionalUrl: `/${id}/views/order`,
      body: payload,
    });

    return data;
  }

  async displayViewUpdate(id: EntityId, payload: Partial<BoardDisplayView>) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}/displayView`,
      body: payload,
    });

    return data;
  }

  async featuresUpdate(id: EntityId, payload: Partial<BoardFeatures>) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}/features`,
      body: payload,
    });

    return data;
  }

  async recordTemplatesCreate(id: EntityId, payload: Partial<RecordTemplateInterface>) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/record-templates`,
      body: payload,
    });

    return data;
  }

  async recordTemplatesUpdate(id: EntityId, recordTemplateId: EntityId, payload: Partial<RecordTemplateInterface>) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}/record-templates/${recordTemplateId}`,
      body: payload,
    });

    return data;
  }

  async recordTemplatesDelete(id: EntityId, recordTemplateId: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}/record-templates/${recordTemplateId}`,
    });

    return data;
  }
}

export default new BoardService();
