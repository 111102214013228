import { Modal } from "antd";

import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import { DefaultColumnCodes } from "lib/types/boardTypes";
import { SortFieldType } from "lib/types/sortAndFilterTypes";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { cancelButtonProps, okButtonProps } from "styles/common-vars";
import { EntityId } from "lib/types/entity";

interface menuConfigProps {
  boardId: EntityId;
  id: EntityId;
  columnClass?: string;
  code: DefaultColumnCodes | string;
  lite: boolean;
  allowedToEdit: boolean;
  onEditClick: () => void;
  onHideClick: () => void;
  onAscending: (field: SortFieldType) => void;
  onDescending: (field: SortFieldType) => void;
  deleteColumnFn: () => void;
  deactivateColumnFn: () => void;
  moveColumnLeft?: () => void;
  moveColumnRight?: () => void;
}

const menuConfig = (props: menuConfigProps) => {
  const { columnClass, code, lite, allowedToEdit, onEditClick, onHideClick, onAscending, onDescending } = props;

  const sortButtons = [
    {
      onClick: (field: SortFieldType) => onAscending(field),
      label: "Sort ascending",
      icon: "/icons/sort.svg",
      code: "sort-ascending",
      withDivider: false,
      disabled: false,
    },
    {
      onClick: (field: SortFieldType) => onDescending(field),
      label: "Sort descending",
      icon: "/icons/sort-descending.svg",
      code: "sort-descending",
      withDivider: false,
      disabled: false,
    }
  ];

  if (lite) {
    return sortButtons;
  }

  const isSystem = columnClass === TaskPropertyClass.System;
  const isCustom = columnClass === TaskPropertyClass.Custom;
  const isDefault = columnClass === TaskPropertyClass.Default;
  const isSynced = columnClass === TaskPropertyClass.Synced;

  const nonEditableColumnCodes: Array<string> = [
    DefaultColumnCodes.Title,
    DefaultColumnCodes.Assignees,
    DefaultColumnCodes.Viewpoints,
    DefaultColumnCodes.CreatedBy,
    DefaultColumnCodes.LastModifiedBy,
    DefaultColumnCodes.ID,
    DefaultColumnCodes.Progress,
    DefaultColumnCodes.Estimate,
  ];

  const nonHidableColumns: Array<string> = [DefaultColumnCodes.Title];

  const isEditDisabled = nonEditableColumnCodes.includes(code) || isSynced || !allowedToEdit;
  const isHideDisabled = nonHidableColumns.includes(code) || !allowedToEdit;
  const isDeleteDisabled = isDefault || !allowedToEdit;

  const moveColumnsButtons = [];

  if (props.moveColumnLeft) {
    moveColumnsButtons.push({
      label: "Move left",
      onClick: props.moveColumnLeft,
      icon: "/icons/move-left.svg",
      code: "move-left",
      withDivider: false,
      disabled: false,
    });
  }
  if (props.moveColumnRight) {
    moveColumnsButtons.push({
      label: "Move right",
      onClick: props.moveColumnRight,
      icon: "/icons/move-right.svg",
      code: "move-right",
      withDivider: false,
      disabled: false,
    });
  }

  return [
    {
      label: "Edit field",
      onClick: onEditClick,
      icon: "/icons/edit.svg",
      code: "edit-field",
      withDivider: true,
      disabled: isEditDisabled,
    },
    ...sortButtons,
    ...moveColumnsButtons,
    {
      label: "Hide column",
      onClick: onHideClick,
      icon: "/icons/hide.svg",
      code: "hide-column",
      withDivider: false,
      disabled: isHideDisabled,
    },
    {
      label: "Delete column",
      onClick: () => {
        const action = () => {
          if (isSystem || isSynced) {
            props.deactivateColumnFn();
          } else {
            props.deleteColumnFn();
          }
        };

        Modal.confirm({
          icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
          title: "Permanently delete the column?",
          content: "This will permanently delete the column, it will no longer be accessible to you or anyone else. This action is irreversible.",
          okText: "Delete",
          okButtonProps: okButtonProps,
          cancelText: "Cancel",
          cancelButtonProps: cancelButtonProps,
          onOk: action,
          zIndex: 1040,
        });
      },
      icon: "/icons/x.svg",
      code: "delete-column",
      disabled: isDeleteDisabled,
      withDivider: false,
    },
  ];
};

export default menuConfig;
