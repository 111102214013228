import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BoardInterface, BoardInterfaceExtended } from "lib/types/boardTypes";
import { EntityId } from "lib/types/entity";

interface InitialStateType {
  board: BoardInterfaceExtended | null;
}

const initialState: InitialStateType = {
  board: null
};

export const currentBoardSlice = createSlice({
  name: "currentBoard",
  initialState,
  reducers: {
    setBoard: (state, action: PayloadAction<BoardInterfaceExtended | null>) => {
      state.board = action.payload;
    },
    performBoardOptimisticUpdate: (state, action: PayloadAction<{ id?: EntityId, update: Partial<BoardInterface> }>) => {
      if (state.board && state.board?.id === action.payload.id) {
        const data = action.payload.update;
        for (const [code, value] of Object.entries(data)) {
          state.board[code as keyof BoardInterface] = value as never;
        }
      }
    }
  },
});

export const { setBoard, performBoardOptimisticUpdate } = currentBoardSlice.actions;

export default currentBoardSlice.reducer;
