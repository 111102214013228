import moment from "moment/moment";
import { TaskInterface } from "../../types/tasksTypes";
import { FilterVersion, TAnyFilters } from "../filters";
import { filterV2 } from "./dateFilter";

enum datePresets {
  "None" = "None",
  "Before today" = "Before today",
  "Today" = "Today",
  "Tomorrow" = "Tomorrow",
  "This week" = "This week",
  "Next week" = "Next week",
  "Next 14 days" = "Next 14 days"
}

const filterV1 = (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
  const filterDate = currentFilter.value.valueKey as keyof typeof datePresets | string;

  const today = moment();

  switch (filterDate) {
    case datePresets["Before today"]: {
      return tasks.filter(task => {
        const dueDate = moment(task.dueDate);
        return dueDate <= today;
      });
    }

    case datePresets.Today: {
      return tasks.filter(task => {
        const dueDate = moment(task.dueDate);
        return Math.ceil(dueDate.diff(today, "days", true)) === 0;
      });
    }

    case datePresets.Tomorrow: {
      return tasks.filter(task => {
        const dueDate = moment(task.dueDate);
        return Math.ceil(dueDate.diff(today, "days", true)) === 1;
      });
    }

    case datePresets["This week"]: {
      return tasks.filter(task => {
        const dueDate = moment(task.dueDate);
        const start = moment().startOf("isoWeek");
        const end = moment().endOf("isoWeek");
        return dueDate >= start && dueDate <= end;
      });
    }

    case datePresets["Next week"]: {
      return tasks.filter(task => {
        const dueDate = moment(task.dueDate);
        const start = moment().add(1, "weeks").startOf("isoWeek");
        const end = moment().add(1, "weeks").endOf("isoWeek");
        return dueDate >= start && dueDate <= end;
      });
    }

    case datePresets["Next 14 days"]: {
      return tasks.filter(task => {
        const dueDate = moment(task.dueDate);
        const in14days = moment().add(14, "days");
        return dueDate >= today && dueDate <= in14days;
      });
    }

    default: {
      return tasks;
    }
  }
};

export const dueDateFilter = {
  filterValues: (...args: any[]) => {
    return Object.keys(datePresets).map(value => {
      return {
        text: value,
        valueKey: value
      };
    });
  },
  filterFn: (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
    if (currentFilter.version && currentFilter.version !== FilterVersion.v1) {
      return filterV2(tasks, currentFilter);
    }

    return filterV1(tasks, currentFilter);
  },
};
