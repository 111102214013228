import styled from "@emotion/styled/macro";

export const BoardChangeIconGrid = styled.div`
  width: 262px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #e4e4e4;
`;
