import { FC } from "react";
import { Tooltip } from "antd";

import { ColumnTypeDisplayMessages } from "lib/helpers/ColumnTypeDisplayMessages";
import { PropertyValueType } from "lib/types/dataTypes";
import { TaskPropertyClass } from "../TaskTable/constants";
import TaskFieldIcon from "../TaskFields/FieldIcons";
import { FieldTitleWrapper, FieldTitleTextWrapper, FieldTitleImg, FieldTitleIcon } from "./styled";

interface FieldTitleProps {
  title: string;
  type: PropertyValueType | string;
  propertyClass: TaskPropertyClass;
  icon?: string;
  hideTooltip?: boolean;
}

const FieldTitle: FC<FieldTitleProps> = (props) => {
  const { title, type, propertyClass, icon, hideTooltip } = props;

  const tooltipTitle = ColumnTypeDisplayMessages(type, propertyClass);

  const field = () => (
    <>
      {!type
        ? <FieldTitleImg src={icon} alt="" />
        : (<FieldTitleIcon>
          <TaskFieldIcon propertyValueType={type} color="#a7a8a9" />
        </FieldTitleIcon>)
      }
    </>
  );

  return (
    <FieldTitleWrapper>
      {hideTooltip
        ? field()
        : (<Tooltip title={tooltipTitle}>
          <div>
            {field()}
          </div>
        </Tooltip>)
      }
      <FieldTitleTextWrapper>
        {title}
      </FieldTitleTextWrapper>
    </FieldTitleWrapper>
  );
};

export default FieldTitle;
