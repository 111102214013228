import styled from "@emotion/styled/macro";
import { Menu } from "antd";

export const HeaderAvatarUser = styled(Menu.Item)`
  cursor: auto;
  &:hover{
    background-color: #fff;
  }
`;

export const HeaderAvatarUserName = styled.div`
  max-width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
  font-size: 15px;
  margin-right: 14px;
`;

export const HeaderAvatarUserEmail = styled.div`
  color: #999;
  font-size: 12px;
  font-weight: 600;
`;
