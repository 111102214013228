import { selectCurrentFilter, setFilter } from "app/slices/tasksFilterAndSortSlice";
import { useAppDispatch } from "app/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { TAnyFilters } from "../../filters/filters";
import useBoardView from "./useBoardView";

type TUseBoardViewFilter = () => TAnyFilters;

const useBoardViewFilter: TUseBoardViewFilter = () => {
  const dispatch = useAppDispatch();
  const view = useBoardView();
  const currentFilter = useSelector(selectCurrentFilter);

  useEffect(() => {
    const filterFromView = view?.appliedFilter?.filter;

    if (filterFromView) {
      dispatch(setFilter(filterFromView));
    }
  }, [dispatch, view]);

  return currentFilter;
};

export default useBoardViewFilter;
