import styled from "@emotion/styled/macro";
import {
  customSelectsWrapper,
  customSelectsGroup,
  customSelectsSearch
} from "../../../../../ui/CustomSelects/styled";


export const SingleSelectFieldWrapper = styled.div`
  ${customSelectsWrapper};
`;

export const SingleSelectFieldSearch = styled.div`
  ${customSelectsSearch};
`;

export const SingleSelectFieldGroup = styled.div`
  ${customSelectsGroup};
`;
