import { FC } from "react";
import { Skeleton, Space, Table } from "antd";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";

const COLUMNS_NUMBER = Math.floor(Math.random() * (12 - 8)) + 8;

const SpaceWrapper = styled(Space)<{ isSimplified?: boolean }>`
  width: 100%;
  padding-top: ${({ isSimplified }) => (isSimplified ? "10px" : "35px")};
  .ant-space-item {
    width: 100%;
  }
  .ant-table-container {
    border-top: 1px solid #f0f0f0;
  }
  .ant-skeleton-element {
    display: flex;
  }
  .ant-table-cell {
    padding: 12px 16px;
  }
  ${({ isSimplified }) =>
    isSimplified &&
    css`
      .ant-table-cell {
        padding: 8px 16px;
      }
    `};
`;

const RawWrapper = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  margin: 0 auto;
`;

const renderTableData = (cols: number, width?: number) => {
  const data = [];
  for (let i = 0; i < cols; i++) {
    const dataDefault: any = {};
    const rowLength = Math.floor(Math.random() * (280 - 180)) + 180;
    dataDefault["col0"] = (
      <RawWrapper width={width}>
        <div style={{ display: "flex", alignItems: "center", paddingLeft: "20px" }}>
          <Skeleton.Button style={{ marginRight: 16 }} shape="circle" size="small" active />
          <Skeleton.Input style={{ width: rowLength, height: 18, borderRadius: 20 }} size="small" active />
        </div>
      </RawWrapper>
    );
    data.push({
      key: i,
      ...dataDefault,
    });
  }
  const columns = [
    {
      title: "col_0",
      dataIndex: "col0"
    }
  ];
  return { columns, data };
};

const SkeletonTable: FC<{ width?: number }> = (props) => {
  const { width } = props;
  const { columns, data } = renderTableData(COLUMNS_NUMBER, width);
  return (
    <SpaceWrapper align="center" size={20} isSimplified={!!width}>
      <Table
        columns={columns}
        dataSource={data}
        showHeader={false}
        pagination={false}
      />
    </SpaceWrapper>
  );
};

export default SkeletonTable;
