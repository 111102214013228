import { FC } from "react";
import IconSvgWrapper from "components/icons/IconSvgWrapper";
import { IconProps } from "components/icons/lib";

const SaveSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M10.9628 1.88322L10.1168 1.03719C9.77303 0.69294 9.30692 0.5 8.82058 0.5H1.76044C1.06422 0.5 0.5 1.06422 0.5 1.76044V10.2396C0.5 10.9358 1.06422 11.5 1.76044 11.5H10.2396C10.9358 11.5 11.5 10.9358 11.5 10.2396V3.17942C11.5 2.69308 11.3071 2.22697 10.9628 1.88322V1.88322ZM2.33331 3.36456V2.21875C2.33331 2.029 2.4873 1.875 2.67706 1.875H7.48956C7.67931 1.875 7.83331 2.029 7.83331 2.21875V3.36456C7.83331 3.55431 7.67931 3.70831 7.48956 3.70831H2.67706C2.4873 3.70831 2.33331 3.55431 2.33331 3.36456ZM6 9.66669C4.86108 9.66669 3.9375 8.74312 3.9375 7.60419C3.9375 6.46519 4.86108 5.54169 6 5.54169C7.13892 5.54169 8.0625 6.46519 8.0625 7.60419C8.0625 8.74312 7.13892 9.66669 6 9.66669Z" />
    </IconSvgWrapper>
  );
};

export default SaveSvg;
