import React, { FC, useEffect, useState } from "react";
import { Modal } from "antd";

import KeyCodes from "lib/types/keyCodes";
import { TaskFieldSetterGetter } from "lib/types/applicationTypes";
import { TextAreaStyled } from "components/blocks/TaskTable/components/TaskTitle/styled";
import { StyledButton } from "styles/common";

import { MultiLineOpenButton, MultiLineListInput } from "./styled";
import { MemberRoles } from "lib/types/types";
import useMemberPermission from "lib/customHooks/useMemberPermission";

const MultiLineTextList: FC<TaskFieldSetterGetter> = (props) => {
  const { code, value, setValue } = props;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [textValue, setTextValue] = useState(value);

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  const resetModal = () => {
    setTextValue(value);
    setIsModalVisible(false);
  };

  const showModal = () => {
    if (allowedToEdit) {
      setIsModalVisible(true);
    }
  };

  const handleOk = () => {
    if (textValue !== value) {
      setValue({ cellName: code, cellValue: textValue });
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    resetModal();
  };

  const handleTextAreaOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(event.target.value);
  };

  const handleTextAreaOnKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.keyCode === KeyCodes.Escape) {
      resetModal();
    }
  };

  return (
    <>
      <MultiLineListInput readOnly value={textValue} size="small" placeholder="Empty" onClick={showModal} disabled={!allowedToEdit} />
      {allowedToEdit &&
        <MultiLineOpenButton onClick={showModal}>
          <img src={process.env.PUBLIC_URL + "/icons/edit.svg"} alt="edit" />
        </MultiLineOpenButton>
      }
      {isModalVisible &&
        <Modal
          title="Edit value"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <div key="cancel" style={{ display: "inline-block", marginRight: "10px" }}>
              <StyledButton onClick={handleCancel}>
                Cancel
              </StyledButton>
            </div>,
            <StyledButton purple key="delete" onClick={handleOk}>
              Update
            </StyledButton>,
          ]}
        >
          <TextAreaStyled
            autoFocus
            value={textValue}
            autoSize={{ minRows: 4, maxRows: 8 }}
            onChange={handleTextAreaOnChange}
            onKeyDown={handleTextAreaOnKeyDown}
          />
        </Modal>
      }
    </>
  );

};

export default MultiLineTextList;
