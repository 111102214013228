import { useAppSelector } from "app/store";
import { useParams } from "react-router-dom";
import { BoardViewInterface } from "../../types/boardTypes";

type TUseBoardView = () => undefined | BoardViewInterface

const useBoardView: TUseBoardView = () => {
  const board = useAppSelector(state => state.currentBoardReducer.board);
  const { view } = useParams<{ id: string, view: string }>();

  return board?.views.find(boardView => boardView.uid === view);
};

export default useBoardView;
