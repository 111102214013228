import { FC } from "react";
import { Tooltip } from "antd";
import moment from "moment";
import ProjectFileContainersDropdown
  from "pages/Project/ProjectOverview/ProjectFileContainers/ProjectFileContainersDropdown";
import { FileContainerInterface } from "lib/types/fileContainer";
import { getFileDetailUri } from "lib/helpers/navigationUtils";
import FileIconWithExtension from "components/icons/resizable/extensions/FileIconWithExtension";
import { DateFormats } from "lib/theme/lib";
import {
  FileContainerCardWrapper,
  FileContainerCardControls,
  FileContainerCardLastUpdated,
  FileContainerCardPreview,
  FileContainerCardCard,
  FileContainerCardTitleWrapper,
  FileContainerCardTitle
} from "components/blocks/FileContainerCard/styled";

interface FileContainerCardProps {
  fileContainer: FileContainerInterface;
}

const FileContainerCard: FC<FileContainerCardProps> = (props) => {
  const { fileContainer } = props;

  return (
    <FileContainerCardWrapper>
      <FileContainerCardCard to={getFileDetailUri(fileContainer.uid)}>
        <FileContainerCardControls>
          <Tooltip title={moment(fileContainer.updated_at).format(DateFormats.HumanFull)} placement="top">
            <FileContainerCardLastUpdated>{moment(fileContainer.updated_at).format(DateFormats.HumanShort)}</FileContainerCardLastUpdated>
          </Tooltip>
        </FileContainerCardControls>
        <FileContainerCardPreview>
          <FileIconWithExtension ext={fileContainer.file.ext} size={50} />
        </FileContainerCardPreview>
        <FileContainerCardTitleWrapper>
          <Tooltip title={fileContainer.title} placement="bottom">
            <FileContainerCardTitle>{fileContainer.title || "Untitled"}</FileContainerCardTitle>
          </Tooltip>
        </FileContainerCardTitleWrapper>
      </FileContainerCardCard>
      <ProjectFileContainersDropdown fileContainer={fileContainer} />
    </FileContainerCardWrapper>
  );
};

export default FileContainerCard;
