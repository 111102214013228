import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const AntCloseSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path d="M57.5656 50L97.8264 2.0092C98.5012 1.21166 97.9337 0 96.8908 0H84.6515C83.9307 0 83.2405 0.322085 82.765 0.874233L49.5595 40.4601L16.354 0.874233C15.8939 0.322085 15.2037 0 14.4675 0H2.22822C1.18528 0 0.617793 1.21166 1.29264 2.0092L41.5534 50L1.29264 97.9908C1.14147 98.1686 1.04449 98.386 1.0132 98.6172C0.98192 98.8485 1.01765 99.0838 1.11616 99.2954C1.21466 99.5069 1.37181 99.6858 1.56893 99.8107C1.76605 99.9356 1.99487 100.001 2.22822 100H14.4675C15.1883 100 15.8785 99.6779 16.354 99.1258L49.5595 59.5399L82.765 99.1258C83.2251 99.6779 83.9153 100 84.6515 100H96.8908C97.9337 100 98.5012 98.7883 97.8264 97.9908L57.5656 50Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default AntCloseSvg;
