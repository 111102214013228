import React, { FC, RefObject, useEffect, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Empty, Input, Select } from "antd";
import { SelectTag } from "pages/Board/BoardKanban/LaneHeader/styled";
import { TaskPropertyOption } from "lib/types/kanbanTypes";
import { TaskFieldSetterGetter, TaskSourceComponent } from "lib/types/applicationTypes";
import { FieldEmptyState } from "../../styled";
import { COLOR_DEFAULT_GREY } from "lib/configs/colors";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import { SingleSelectFieldWrapper } from "./styled";
import SyncedFieldTooltip from "components/blocks/SyncedFieldTooltip";
import { useBoardContext } from "lib/contexts/BoardContext";
import { useTaskContext } from "lib/contexts/TaskContext";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import { SingleSelectFieldGroup, SingleSelectFieldSearch } from "./styled";
import { CustomSelectsEmpty } from "components/ui/CustomSelects/styled";
import { EntityId } from "lib/types/entity";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import { DefaultColumnCodes } from "lib/types/boardTypes";
import HiddenCell from "../../HiddenCell";

interface SingleSelectGenericProps extends TaskFieldSetterGetter {
  selectedOption?: TaskPropertyOption;
  options?: Array<TaskPropertyOption>;
  isStatusField?: boolean;
  component: TaskSourceComponent;
  propertyType: TaskPropertyClass;
}

const { Option, OptGroup } = Select;

const SingleSelectGeneric: FC<SingleSelectGenericProps> = (props) => {
  const { code, setValue, selectedOption, options: initialOptions, component, isStatusField } = props;

  const { board } = useBoardContext();
  const { task } = useTaskContext();
  const isSyncedBoard = !!board?.externalDataSource || !!task?.board?.externalDataSource;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const isSyncedField = isSyncedBoard && (props.propertyType === TaskPropertyClass.Synced);
  const isEditDisabled = isSyncedField || !allowedToEdit;
  const { isStatusEnabled } = useBoardFeatures(task?.board);
  const isHidden = (code === DefaultColumnCodes.Status) && !isStatusEnabled;

  const wrapperRef = useRef(null) as RefObject<any>;
  const selectDropdownParent = (wrapperRef && wrapperRef.current) ? wrapperRef.current.offsetParent : wrapperRef.current;

  const [options, setOptions] = useState(initialOptions);
  const [currentOption, setCurrentOption] = useState(selectedOption);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  useEffect(() => {
    setCurrentOption(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  const handleSelectOnChange = (optionId: EntityId) => {
    const newOption = options?.find(o => o.id === optionId);
    if (newOption) {
      setValue({ cellName: code, cellValue: optionId });
      setCurrentOption(newOption);
    }

    if (optionId === "") {
      setValue({ cellName: code, cellValue: null });
      setCurrentOption(undefined);
    }
    setIsSelectOpen(false);
  };

  const handleSelectClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const dropdownElement = (e.target as HTMLElement).closest(".ant-select-dropdown");
    if (!dropdownElement) {
      setIsSelectOpen(!isSelectOpen);
    }
  };

  const handleOnOutsideClick = (e: MouseEvent) => {
    if (component === TaskSourceComponent.Detailed) {
      const dropdownElement = (e.target as HTMLElement).closest(".ant-select-dropdown");
      if (!dropdownElement) {
        setIsSelectOpen(false);
      }
    }

    if (selectDropdownParent && !selectDropdownParent.contains(e.target as HTMLElement)) {
      return setIsSelectOpen(false);
    }

    return null;
  };

  const handleSearchOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value?.toLowerCase().trim();
    if (value?.length) {
      const nextState = initialOptions?.filter(option => option.title.toLowerCase().includes(value));
      setOptions(nextState);
    } else {
      setOptions(initialOptions);
    }
  };

  const renderOptions = () => (
    <OptGroup label="Select an option">
      {!isStatusField &&
        <Option value="" key="empty-option">
          <CustomSelectsEmpty>Empty</CustomSelectsEmpty>
        </Option>
      }
      {options?.map(selectOption => (
        <Option value={selectOption.id} key={selectOption.id}>
          <div style={{ display: "flex", maxWidth: 200 }}>
            <SelectTag bg={selectOption.color || COLOR_DEFAULT_GREY} clickable={!isEditDisabled}>
              {selectOption?.title}
            </SelectTag>
          </div>
        </Option>
      ))}
    </OptGroup>
  );

  return (
    <SingleSelectFieldWrapper onClick={handleSelectClick} ref={wrapperRef}>
      {isHidden &&
        <HiddenCell />
      }
      {!isHidden &&
        <OutsideClickHandler onOutsideClick={handleOnOutsideClick}>
          {(currentOption === undefined) &&
            <FieldEmptyState>Empty</FieldEmptyState>
          }
          {!!currentOption &&
            <SelectTag
              clickable={!isEditDisabled}
              bg={currentOption.color || COLOR_DEFAULT_GREY}
              style={{ maxWidth: 375 }}
            >
              {currentOption?.title}
            </SelectTag>
          }
          {!isEditDisabled &&
            <Select
              value={currentOption?.id || ""}
              onChange={handleSelectOnChange}
              open={isSelectOpen}
              getPopupContainer={() => selectDropdownParent}
              dropdownStyle={{ minWidth: 180, maxWidth: 240 }}
              dropdownRender={menu => (
                <>
                  <SingleSelectFieldSearch>
                    <Input
                      allowClear
                      placeholder="Search option…"
                      onChange={handleSearchOnChange}
                      bordered={false}
                      onKeyDown={e => e.stopPropagation()}
                      onClick={e => e.preventDefault()}
                    />
                  </SingleSelectFieldSearch>
                  <SingleSelectFieldGroup>
                    {menu}
                    {isStatusField && !options?.length &&
                      <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                  </SingleSelectFieldGroup>
                </>
              )}
            >
              {renderOptions()}
            </Select>
          }
        </OutsideClickHandler>
      }
      {isSyncedField &&
        <SyncedFieldTooltip marginLeft />
      }
    </SingleSelectFieldWrapper>
  );
};

export default SingleSelectGeneric;
