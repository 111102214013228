import LogRocket from "logrocket";

import { useAppSelector } from "app/store";
import { getUserFullName } from "lib/helpers/userUtils";

const useLogRocket = () => {
  const user = useAppSelector(state => state.currentUserReducer.user);
  if (user) {
    LogRocket.identify(String(user.id), {
      email: user.email,
      name: getUserFullName(user) || user.email,
      updated_at: String(user.updated_at),
    });
  }
};

export default useLogRocket;
