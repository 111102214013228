import { FC } from "react";
import { Tooltip } from "antd";
import { CustomTooltipContainer } from "components/ui/CustomTooltip/styled";
import { TooltipPlacement } from "antd/es/tooltip";

interface CustomTooltipProps {
  placement?: TooltipPlacement;
  content: string;
  iconTopMargin?: number;
}

const CustomTooltip: FC<CustomTooltipProps> = (props) => {
  const iconTopMargin = props?.iconTopMargin || 0;

  return (
    <CustomTooltipContainer>
      <Tooltip
        placement={props.placement || "right"}
        title={props.content}
      >
        <img
          style={{ marginTop: iconTopMargin }}
          src={process.env.PUBLIC_URL + "/icons/question.svg"}
          alt="menu"
        />
      </Tooltip>
    </CustomTooltipContainer>
  );
};

export default CustomTooltip;
