import { FC } from "react";
import IconSvgWrapper from "components/icons/IconSvgWrapper";
import { IconProps } from "components/icons/lib";

const SortSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M0.666525 3.64753H7.38331V2.31445H0.666525C0.29843 2.31445 0 2.61288 0 2.98098C0 3.34907 0.29843 3.64753 0.666525 3.64753Z" />
      <path d="M0.666525 6.32233H7.38331V4.98926H0.666525C0.29843 4.98926 0 5.28769 0 5.65581C0 6.02393 0.29843 6.32233 0.666525 6.32233Z" />
      <path d="M0.666525 7.66504V7.66507C0.29843 7.66507 0 7.96347 0 8.33159C0 8.69971 0.29843 8.99811 0.666525 8.99811H5.33285C5.28014 8.83578 5.25231 8.66455 5.25231 8.48892C5.25231 8.19479 5.32943 7.91266 5.47348 7.66504H0.666525Z" />
      <path d="M11.7527 7.89228C11.4236 7.5631 10.8899 7.5631 10.5607 7.89228L9.8686 8.58436V1.38C9.8686 0.914463 9.49122 0.537109 9.02569 0.537109C8.56015 0.537109 8.18277 0.914489 8.18277 1.38V8.58436L7.49069 7.89228C7.16151 7.56312 6.62782 7.5631 6.29864 7.89228C5.96946 8.22146 5.96946 8.75515 6.29864 9.08433L8.42953 11.2152C8.75806 11.5438 9.29243 11.545 9.62184 11.2152L11.7527 9.08433C12.0819 8.75515 12.0819 8.22146 11.7527 7.89228Z" />
    </IconSvgWrapper>
  );
};

export default SortSvg;
