import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const RectangleSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M91.6667 8.33325H8.33337V91.6666H91.6667V8.33325ZM83.3334 16.6666H16.6667V83.3333H83.3334V16.6666Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default RectangleSvg;
