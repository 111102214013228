import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const FilesSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.5 8.33337C3.35786 8.33337 0 11.6912 0 15.8334V84.1667C0 87.7911 2.57087 90.815 5.98849 91.5143C6.47672 91.6143 6.98223 91.6667 7.5 91.6667H92.5C96.6421 91.6667 100 88.3088 100 84.1667V39.1667C100 35.0246 96.6421 31.6667 92.5 31.6667H54.1667V15.8334C54.1667 11.6912 50.8088 8.33337 46.6667 8.33337H7.5ZM85 46.6667H46.6667C42.5245 46.6667 39.1667 43.3088 39.1667 39.1667V23.3334H15V76.6667H85V46.6667Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default FilesSvg;
