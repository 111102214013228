import styled from "@emotion/styled/macro";

export const CircleDeleteButtonContainer = styled.div<{ offsetValue?: number; }>`
  position: absolute;
  opacity: 0;
  left: auto;
  top: 0;
  right: 0;
  bottom: auto;
  margin-top: ${({ offsetValue }) => offsetValue ? `-${offsetValue}px` : "-6px"};
  margin-right: ${({ offsetValue }) => offsetValue ? `-${offsetValue}px` : "-6px"};
  cursor: pointer;
`;

export const CircleDeleteButtonContent = styled.div`
  display: flex;
  width: 12px;
  height: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f2f2f2;
  transition: opacity 200ms ease;
`;
