import { Modal } from "antd";
import { setBoard } from "app/slices/currentBoardSlice";
import { setLoading } from "app/slices/progressSlice";
import { useAppDispatch } from "app/store";
import useSavingState from "lib/customHooks/useSavingState";
import { ModalComponentProps } from "lib/types/components";
import { EntityId } from "lib/types/entity";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import BoardService from "services/BoardService";
import { StyledButton } from "styles/common";
import CustomStyledButton from "../CustomStyledButton";
import { commonModalProps } from "../ModalDialog/lib";

interface DeleteBoardModalProps extends ModalComponentProps {
  boardId: EntityId;
  boardName: string;
}

const DeleteBoardModal: FC<DeleteBoardModalProps> = (props) => {
  const { boardId, boardName, visible, onClose } = props;

  const { setSavingStart, setSavingFinish } = useSavingState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleDeleteBoardOnClick = async () => {
    dispatch(setLoading(true));
    setIsLoading(true);
    setSavingStart();
    const deletedBoard = await BoardService.deleteBoard(boardId);
    dispatch(setLoading(false));
    setIsLoading(false);
    setSavingFinish();
    if (deletedBoard) {
      dispatch(setBoard(null));
      history.push("/home");
    }
    onClose();
  };

  const handleOnClose: React.MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <Modal
      {...commonModalProps}
      title={`Delete the "${boardName}" board?`}
      visible={visible}
      onCancel={handleOnClose}
      footer={[
        <div key="cancel" style={{ display: "inline-block", marginRight: "10px" }}>
          <StyledButton onClick={handleOnClose}>
            Cancel
          </StyledButton>
        </div>,
        <CustomStyledButton
          btnColor="redOutlined"
          key="delete"
          onClick={handleDeleteBoardOnClick}
          value="Delete"
          isLoading={isLoading}
        />,
      ]}
    >
      <div>
        <p>This will delete the board, along with the following:</p>
        <ul style={{ marginBottom: 0 }}>
          <li>any tasks and their viewpoints;</li>
          <li>any custom fields and their settings.</li>
        </ul>
      </div>
    </Modal>
  );
};

export default DeleteBoardModal;
