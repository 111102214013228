import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Typography } from "antd";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { getProjectSettingsUri, copyLink, getProjectUri } from "lib/helpers/navigationUtils";
import { MemberRoles, WorkspaceInterface } from "lib/types/types";
import DeleteProjectModal from "../DeleteProjectModal";
import DuplicateProjectModal from "../DuplicateProjectModal";
import InviteProjectModal from "../InviteProjectModal";
import SettingsSvg from "components/icons/resizable/settings";
import MenuItemIcon from "components/ui/MenuItemIcon";
import DuplicateSvg from "components/icons/resizable/duplicate";
import UrlSvg from "components/icons/resizable/url";
import TrashSvg from "components/icons/resizable/trash";
import InviteSvg from "components/icons/invite";

interface ProjectContextMenuProps {
  workspace: WorkspaceInterface;
  toggleVisibility: () => void;
}

const ProjectContextMenu: FC<ProjectContextMenuProps> = (props) => {
  const { workspace, toggleVisibility } = props;

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);

  const allowedToDelete = useMemberPermission([MemberRoles.Owner]);
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);

  return (
    <Menu onClick={toggleVisibility}>
      <Menu.Item key="project-settings">
        <Link to={getProjectSettingsUri(workspace.uid)}>
          <MenuItemIcon text="Settings" icon={<SettingsSvg size={12}/>} />
        </Link>
      </Menu.Item>
      {allowedToEdit &&
        <>
          <Menu.Item key="project-invite" onClick={() => setIsInviteModalVisible(true)}>
            <Typography.Text>
              <MenuItemIcon text="Invite people" icon={<InviteSvg />} />
            </Typography.Text>
          </Menu.Item>
          <Menu.Item key="project-duplicate" onClick={() => setIsDuplicateModalVisible(true)}>
            <Typography.Text>
              <MenuItemIcon text="Duplicate project" icon={<DuplicateSvg size={12}/>} />
            </Typography.Text>
          </Menu.Item>
          {isInviteModalVisible &&
            <InviteProjectModal
              visible={isInviteModalVisible}
              onClose={() => setIsInviteModalVisible(false)}
            />
          }
          {isDuplicateModalVisible &&
            <DuplicateProjectModal
              visible={isDuplicateModalVisible}
              onClose={() => setIsDuplicateModalVisible(false)}
            />
          }
        </>
      }
      <Menu.Item key="project-copyLink" onClick={() => copyLink(getProjectUri(workspace.uid))}>
        <MenuItemIcon text="Copy project link" icon={<UrlSvg size={12}/>} />
      </Menu.Item>
      {allowedToDelete &&
        <>
          <Menu.Divider />
          <Menu.Item key="project-delete" onClick={() => setIsDeleteModalVisible(true)}>
            <Typography.Text>
              <MenuItemIcon text="Delete project" icon={<TrashSvg size={12}/>} red />
            </Typography.Text>
          </Menu.Item>
          {isDeleteModalVisible &&
            <DeleteProjectModal
              visible={isDeleteModalVisible}
              onClose={() => setIsDeleteModalVisible(false)}
            />
          }
        </>
      }
    </Menu>
  );
};

export default ProjectContextMenu;
