const icons = [
  "bookmarkTabs",
  "brick",
  "buildingConstruction",
  "carpentrySaw",
  "chains",
  "construction",
  "constructionWorker",
  "controlKnobs",
  "electricPlug",
  "fountainPen",
  "gear",
  "hammer",
  "hammerAndWrench",
  "memo",
  "nutAndBolt",
  "package",
  "screwdriver",
  "toolbox",
  "triangularRuler",
  "wrench",
];

export default icons;