import { FC } from "react";
import { Tooltip } from "antd";
import ButtonIcon from "components/ui/ButtonIcon";

interface NotificationButtonProps {
  type: "MarkAsRead" | "Archive" | "Unarchive" | "Unread";
  handleClick: () => void;
}

const NotificationButton: FC<NotificationButtonProps> = (props) => {
  const { type, handleClick } = props;

  const buttonProps = {
    MarkAsRead: {
      text: "Mark as read",
      icon: "/icons/checkmark-outline.svg"
    },
    Archive: {
      text: "Archive",
      icon: "/icons/trash-bin.svg"
    },
    Unarchive: {
      text: "Unarchive",
      icon: "/icons/inbox.svg"
    },
    Unread: {
      text: "Mark as unread",
      icon: "/icons/circle.svg"
    }
  };

  return (
    <Tooltip placement="bottom" title={buttonProps[type].text}>
      <div>
        <ButtonIcon onClick={handleClick} iconUrl={buttonProps[type].icon} />
      </div>
    </Tooltip>
  );
};

export default NotificationButton;
