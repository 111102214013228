import { deleteNotification } from "app/slices/notificationsSlice";
import { useAppDispatch } from "app/store";
import { NotificationInterface } from "lib/types/notificationTypes";
import { WsEvents } from "lib/types/wsTypes";
import { useCallback } from "react";
import useWSSubscription from "../useWSSubscription";

const useWSDeleteNotification = () => {
  const dispatch = useAppDispatch();

  const callback = useCallback((data: NotificationInterface) => {
    dispatch(deleteNotification(data));
  }, [dispatch]);

  useWSSubscription<NotificationInterface>({
    eventName: WsEvents.Notification.Delete,
    callback,
    fnKey: "useWSDeleteNotification"
  });
};

export default useWSDeleteNotification;
