import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const RssSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.6792 20.5167C64.4009 20.5145 62.2163 19.6087 60.605 17.998C58.9934 16.3874 58.0867 14.2034 58.0834 11.925V0H19.6959C16.7221 0 13.8701 1.18131 11.7674 3.28406C9.66465 5.38679 8.4834 8.23875 8.4834 11.2125V78.8042C8.48781 81.775 9.67102 84.6225 11.7733 86.7217C13.8756 88.8208 16.725 90 19.6959 90H65.7792C68.7529 90 71.605 88.8187 73.7075 86.7158C75.8104 84.6133 76.9917 81.7612 76.9917 78.7875V20.5167H66.6792Z" fill="#FF9908" />
        <path d="M76.9917 20.5167H66.6792C64.4009 20.5145 62.2163 19.6087 60.605 17.998C58.9934 16.3874 58.0867 14.2034 58.0834 11.925V0L76.9917 20.5167Z" fill="#FF9908" />
        <path d="M85.125 71.0833H33.025C29.3293 71.0833 26.3334 74.0791 26.3334 77.775V93.3083C26.3334 97.0041 29.3293 100 33.025 100H85.125C88.8209 100 91.8167 97.0041 91.8167 93.3083V77.775C91.8167 74.0791 88.8209 71.0833 85.125 71.0833Z" fill="#FF9908" />
        <path d="M49.2878 93.7925C49.0541 93.9413 48.831 93.9871 48.6185 93.9304C48.406 93.8738 48.2253 93.7288 48.0766 93.495L44.7828 88.0125H41.8078V92.8575C41.8078 93.4525 41.5103 93.75 40.9153 93.75C40.3203 93.75 40.0228 93.4525 40.0228 92.8575V80.235C40.0228 79.64 40.3203 79.3425 40.9153 79.3425H45.8878C47.1841 79.3425 48.1757 79.6896 48.8628 80.3838C49.557 81.0709 49.9041 82.0625 49.9041 83.3588V83.9963C49.9041 85.1225 49.642 86.0221 49.1178 86.695C48.5937 87.3609 47.8357 87.775 46.8441 87.9379L49.6278 92.5813C49.897 93.0771 49.7837 93.4809 49.2878 93.7925ZM41.8078 86.2275H45.8878C46.6953 86.2275 47.2691 86.0575 47.6091 85.7175C47.9491 85.3775 48.1191 84.8038 48.1191 83.9963V83.3588C48.1191 82.5584 47.9491 81.9879 47.6091 81.6479C47.2691 81.3009 46.6953 81.1275 45.8878 81.1275H41.8078V86.2275Z" fill="white" />
        <path d="M56.9059 93.75C55.6946 93.75 54.7492 93.5055 54.0692 93.0167C53.3963 92.528 52.9746 91.7738 52.8046 90.7538C52.7763 90.4421 52.8401 90.2084 52.9959 90.0525C53.1517 89.8967 53.3784 89.8188 53.6759 89.8188C53.9734 89.8188 54.1896 89.8896 54.3242 90.0313C54.4659 90.1729 54.5613 90.3925 54.6109 90.69C54.7171 91.1788 54.9438 91.515 55.2909 91.6992C55.645 91.8763 56.1834 91.965 56.9059 91.965H59.9659C60.8796 91.965 61.4996 91.8021 61.8255 91.4763C62.1584 91.1434 62.3246 90.5271 62.3246 89.6275C62.3246 88.7138 62.1546 88.0904 61.8146 87.7575C61.4746 87.4175 60.8584 87.2475 59.9659 87.2475H57.3309C55.9921 87.2475 54.9971 86.9217 54.3455 86.27C53.7009 85.6113 53.3784 84.6125 53.3784 83.2738C53.3784 81.9421 53.6971 80.9538 54.3346 80.3092C54.9792 79.6646 55.9709 79.3425 57.3096 79.3425H59.8384C60.9646 79.3425 61.8467 79.5725 62.4842 80.0329C63.1288 80.4934 63.5359 81.1984 63.7059 82.1475C63.7484 82.4521 63.6917 82.6859 63.5359 82.8488C63.38 83.0046 63.1463 83.0825 62.8346 83.0825C62.5513 83.0825 62.3388 83.0079 62.1971 82.8592C62.0555 82.7104 61.9634 82.4946 61.9209 82.2113C61.8076 81.7934 61.5917 81.51 61.273 81.3613C60.9542 81.2054 60.4759 81.1275 59.8384 81.1275H57.3096C56.4596 81.1275 55.8896 81.2796 55.5992 81.5842C55.3088 81.8817 55.1634 82.445 55.1634 83.2738C55.1634 84.1096 55.3121 84.6834 55.6096 84.995C55.9071 85.3067 56.4809 85.4625 57.3309 85.4625H59.9659C61.3613 85.4625 62.3992 85.8025 63.0792 86.4825C63.7663 87.1625 64.1096 88.2109 64.1096 89.6275C64.1096 91.0371 63.7696 92.0784 63.0896 92.7513C62.4096 93.4171 61.3684 93.75 59.9659 93.75H56.9059Z" fill="white" />
        <path d="M71.6191 93.75C70.4079 93.75 69.4621 93.5055 68.7821 93.0167C68.1091 92.528 67.6879 91.7738 67.5179 90.7538C67.4896 90.4421 67.5533 90.2084 67.7091 90.0525C67.865 89.8967 68.0916 89.8188 68.3891 89.8188C68.6866 89.8188 68.9025 89.8896 69.0371 90.0313C69.1787 90.1729 69.2746 90.3925 69.3241 90.69C69.4304 91.1788 69.6571 91.515 70.0041 91.6992C70.3583 91.8763 70.8966 91.965 71.6191 91.965H74.6791C75.5929 91.965 76.2125 91.8021 76.5383 91.4763C76.8712 91.1434 77.0379 90.5271 77.0379 89.6275C77.0379 88.7138 76.8679 88.0904 76.5279 87.7575C76.1879 87.4175 75.5716 87.2475 74.6791 87.2475H72.0441C70.7054 87.2475 69.71 86.9217 69.0583 86.27C68.4137 85.6113 68.0916 84.6125 68.0916 83.2738C68.0916 81.9421 68.4104 80.9538 69.0479 80.3092C69.6925 79.6646 70.6841 79.3425 72.0229 79.3425H74.5516C75.6779 79.3425 76.5596 79.5725 77.1971 80.0329C77.8416 80.4934 78.2491 81.1984 78.4191 82.1475C78.4616 82.4521 78.405 82.6859 78.2491 82.8488C78.0933 83.0046 77.8596 83.0825 77.5479 83.0825C77.2646 83.0825 77.0521 83.0079 76.9104 82.8592C76.7687 82.7104 76.6766 82.4946 76.6341 82.2113C76.5208 81.7934 76.3046 81.51 75.9858 81.3613C75.6671 81.2054 75.1891 81.1275 74.5516 81.1275H72.0229C71.1729 81.1275 70.6025 81.2796 70.3121 81.5842C70.0216 81.8817 69.8766 82.445 69.8766 83.2738C69.8766 84.1096 70.0254 84.6834 70.3229 84.995C70.6204 85.3067 71.1941 85.4625 72.0441 85.4625H74.6791C76.0746 85.4625 77.1121 85.8025 77.7921 86.4825C78.4791 87.1625 78.8229 88.2109 78.8229 89.6275C78.8229 91.0371 78.4829 92.0784 77.8029 92.7513C77.1229 93.4171 76.0816 93.75 74.6791 93.75H71.6191Z" fill="white" />
        <path d="M32.5916 58.1708C34.26 58.1708 35.6125 56.8183 35.6125 55.15C35.6125 53.4817 34.26 52.1292 32.5916 52.1292C30.9233 52.1292 29.5708 53.4817 29.5708 55.15C29.5708 56.8183 30.9233 58.1708 32.5916 58.1708Z" fill="#FF9908" />
        <path fillRule="evenodd" clipRule="evenodd" d="M51.9029 56.5075C51.9721 57.4246 52.7116 58.1709 53.6316 58.1709H54.2354C55.1566 58.1709 55.9087 57.4225 55.8496 56.5034C55.4387 50.1304 52.725 44.1013 48.1828 39.5589C43.6405 35.0166 37.6111 32.3028 31.2383 31.8921C30.3191 31.8328 29.5708 32.5848 29.5708 33.506V34.1102C29.5708 35.0299 30.3169 35.7694 31.2341 35.8386C36.5632 36.2408 41.595 38.5371 45.3998 42.3417C49.2046 46.1467 51.5008 51.1784 51.9029 56.5075ZM31.2314 44.4563C30.3185 44.3446 29.5708 43.6092 29.5708 42.6896V42.0854C29.5708 41.1641 30.3199 40.4095 31.237 40.4969C35.3335 40.8872 39.1877 42.69 42.1218 45.6242C45.0558 48.5584 46.8587 52.4125 47.249 56.5088C47.3364 57.4259 46.5818 58.175 45.6606 58.175H45.0563C44.1366 58.175 43.4011 57.4275 43.2896 56.5146C42.9171 53.4625 41.5352 50.6034 39.3388 48.4071C37.1423 46.2109 34.2834 44.8288 31.2314 44.4563Z" fill="#FF9908" />
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default RssSvg;
