import React, { FC, useEffect, useRef, useState } from "react";
import { Checkbox, Spin } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { TaskSubtaskInterface } from "lib/types/tasksTypes";
import { TaskCommentEditControl } from "pages/Task/TaskComment/styled";
import { ChecklistReorderContainer, ChecklistDeleteContainer, ChecklistRow, ChecklistInput } from "./styled";
import keyCodes from "lib/types/keyCodes";
import { LoadingOutlined } from "@ant-design/icons";

interface SubtaskProps {
  subtask: Partial<TaskSubtaskInterface>;
  onDelete: (item: TaskSubtaskInterface) => void;
  onUpdate: (item: TaskSubtaskInterface, data: Partial<TaskSubtaskInterface>) => void;
  disabled?: boolean;
}

const SubtaskItem: FC<SubtaskProps> = (props) => {
  const { subtask, onUpdate, onDelete, disabled } = props;

  const ref = useRef<HTMLTextAreaElement>(null);
  const [completed, setCompleted] = useState(subtask.completed);
  const [title, setTitle] = useState(subtask.title);

  useEffect(() => {
    setCompleted(subtask.completed);
  }, [subtask.completed]);

  useEffect(() => {
    setTitle(subtask.title);
  }, [subtask.title]);

  const handleCheckboxOnChange = (event: CheckboxChangeEvent) => {
    const value = event.target.checked;
    setCompleted(value);
    updateSubtask({ completed: value });
  };

  const updateSubtask = (data: Partial<TaskSubtaskInterface>) => {
    onUpdate(subtask as TaskSubtaskInterface, data);
  };

  const handleInputOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTitle(event.target.value);
  };

  const handleInputOnBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    const nextState = title?.trim();
    if (nextState === subtask.title) {
      return;
    }

    if (!nextState) {
      setTitle(subtask.title);
      return;
    }

    updateSubtask({ title: nextState });
  };

  const handleInputOnKeydown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if ((event.keyCode === keyCodes.Enter && !event.shiftKey) || event.keyCode === keyCodes.Tab) {
      ref.current?.blur();
    }
    if (event.keyCode === keyCodes.Escape) {
      event.stopPropagation();
      ref.current?.blur();
    }
  };

  return (
    <ChecklistRow>
      <ChecklistReorderContainer>
        <img src={process.env.PUBLIC_URL + "/icons/drag.svg"} alt="drag" style={{ marginRight: "7px" }} />
      </ChecklistReorderContainer>
      {subtask.isLoading
        ? <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
        : <Checkbox
          checked={completed}
          onChange={handleCheckboxOnChange}
          disabled={disabled}
        />
      }
      <ChecklistInput
        ref={ref}
        size="small"
        placeholder="Enter title"
        bordered={false}
        value={title}
        isChecked={completed}
        onChange={handleInputOnChange}
        onKeyDown={handleInputOnKeydown}
        onBlur={handleInputOnBlur}
        disabled={disabled || subtask.isLoading}
        readOnly={disabled || subtask.isLoading}
        autoSize={{ minRows: 1 }}
      />
      {(!disabled && !subtask.isLoading) &&
        <ChecklistDeleteContainer>
          <TaskCommentEditControl onClick={() => onDelete(subtask as TaskSubtaskInterface)}>
            <img alt="" src={process.env.PUBLIC_URL + "/icons/close_6px.svg"} />
          </TaskCommentEditControl>
        </ChecklistDeleteContainer>
      }
    </ChecklistRow>
  );
};

export default SubtaskItem;
