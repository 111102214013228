import { Form, FormInstance, Input, Modal } from "antd";
import CustomStyledButton from "components/blocks/CustomStyledButton";
import { commonModalProps } from "components/blocks/ModalDialog/lib";
import { ViewModalGrid } from "layout/HeaderCustom/BoardHeader/AddViewModal/styled";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import useSavingState from "lib/customHooks/useSavingState";
import { getBoardViewUri } from "lib/helpers/navigationUtils";
import { DisplayView } from "lib/types/applicationTypes";
import { ModalComponentProps } from "lib/types/components";
import { captureException } from "lib/utils/sentry";
import { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import BoardService from "services/BoardService";

import { SectionSubheading, StyledButton } from "styles/common";
import { ViewItems } from "./lib";
import { ViewSwitchCardIcon, ViewSwitchCardTile } from "components/ui/ViewSwitchCard/styled";

interface AddViewModalProps extends ModalComponentProps {
  initialView?: DisplayView;
}

type TFormType = {
  title: string
}

const AddViewModal: FC<AddViewModalProps> = (props) => {
  const { initialView, visible, onClose } = props;

  const { setSavingStart, setSavingFinish } = useSavingState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [formView, setFormView] = useState(initialView || DisplayView.List);

  const { board } = useBoardContext() as BoardContextInterface;
  const history = useHistory();
  const [form] = Form.useForm();
  const formRef = useRef<FormInstance>(null);
  const formInitialValues = {
    title: "",
    view: formView,
  };

  useEffect(() => {
    if (initialView) {
      setFormView(initialView);
      form.setFieldsValue({ view: initialView });
    }
  }, [form, initialView]);

  const onCloseModal = () => {
    form.resetFields();
    onClose();
  };

  const handleViewItemOnClick = (viewItem: DisplayView) => {
    setFormView(viewItem);
    form.setFieldsValue({ view: viewItem });
  };

  const handleOnSubmit = () => {
    formRef?.current?.submit();
  };

  const handleOnFinish = async () => {
    setSavingStart();
    setIsLoading(true);
    const payload = form.getFieldsValue();
    try {
      const { data } = await BoardService.viewsCreate(String(board?.id), payload);

      const newViewUId = data?.data?.uid;
      if (newViewUId) {
        const target = getBoardViewUri(board.uid, newViewUId);
        history.push(target);
      }
    } catch (error) {
      captureException(error);
    }
    setIsLoading(false);
    setSavingFinish();
    onCloseModal();
  };

  const onValueChange = (changedValue: any, allValues: TFormType) => {
    const title = allValues["title"] || "";
    setIsSaveEnabled(!!title.trim());
  };

  useEffect(() => {
    const title = form?.getFieldValue("title") || "";
    setIsSaveEnabled(!!title.trim());
  }, [form]);

  return (
    <Modal
      {...commonModalProps}
      width={360}
      title="New view"
      visible={visible}
      onOk={onCloseModal}
      onCancel={onCloseModal}
      bodyStyle={{ padding: "15px 20px" }}
      footer={[
        <div key="cancel" style={{ display: "inline-block", marginRight: "10px" }}>
          <StyledButton onClick={() => onCloseModal()}>
            Cancel
          </StyledButton>
        </div>,
        <CustomStyledButton
          key="ok"
          btnColor="purple"
          disabled={isLoading || !isSaveEnabled}
          isLoading={isLoading}
          onClick={handleOnSubmit}
          value="Create"
        />
      ]}
    >
      <SectionSubheading style={{ marginTop: 0 }}>Name</SectionSubheading>
      <Form
        form={form}
        name="board-view-form"
        ref={formRef}
        onFinish={handleOnFinish}
        onValuesChange={onValueChange}
        initialValues={formInitialValues}
      >
        <Form.Item name="title">
          <Input autoFocus />
        </Form.Item>
        <Form.Item name="view">
          <ViewModalGrid>
            {ViewItems.map(item => (
              <ViewSwitchCardTile
                select={formView === item.code}
                onClick={() => handleViewItemOnClick(item.code)}
                key={item.code}
              >
                <ViewSwitchCardIcon>
                  {item.icon}
                </ViewSwitchCardIcon>
                {item.title}
              </ViewSwitchCardTile>
            ))}
          </ViewModalGrid>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddViewModal;
