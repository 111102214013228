import styled from "@emotion/styled/macro";
import { DefaultIconColor } from "components/icons/lib";
import { selectTagCss } from "styles/common-css";

interface HeaderTagProps {
  bg: string | undefined;
  clickable?: boolean;
}

export const LaneHeaderDiv = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  margin: 0 4px;
  padding-bottom: 0 !important;
  overflow: hidden !important;
`;

export const LaneHeaderTag = styled.div<HeaderTagProps>`
  ${selectTagCss};
  background-color: ${({ bg }) => bg};
`;

export const SelectTag = styled.div<HeaderTagProps>`
  ${selectTagCss};
  flex-grow: 0;
  background-color: ${({ bg }) => bg};
  cursor: ${({ clickable }) => clickable ? "pointer" : "default"};
`;

export const LaneHeaderTitle = styled.div`
  position: relative;
  input {
    max-width: 200px;
  }
`;

export const LaneHeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LaneMenuDivider = styled.div`
  height: 1px;
  background-color: ${DefaultIconColor};
  opacity: 0.25;
  margin: 8px 0;
`;

export const LaneMenuHeader = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #999;
`;

export const LaneMenu = styled.div`
  position: relative;
  padding-top: 7px;
  background-color: white;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.12) 0 3px 6px -4px, rgba(0, 0, 0, 0.08) 0px 6px 16px, rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  outline: none;
`;

export const LaneMenuItem = styled.div`
  display: block;
  padding: 6px 12px;
  cursor: pointer;
  transition: 0.2s;
  &:first-of-type {
    margin-top: 0;
  }
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const LaneMenuRow = styled.div`
  margin: 0 12px;
`;

export const LaneMenuRowHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 3px;
`;

export const LaneMenuRowBody = styled.div`
  margin: 0 -3px;
  padding: 3px 0 10px 0;
`;
