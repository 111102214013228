import React, { FC } from "react";
import { CircleDeleteButtonContainer, CircleDeleteButtonContent } from "components/blocks/CircleDeleteButton/styled";

interface CircleDeleteButtonProps {
  onClick?: () => void;
  offsetValue?: number;
}

const CircleDeleteButton: FC<CircleDeleteButtonProps> = (props) => {

  const { onClick, offsetValue } = props;

  return (
    <CircleDeleteButtonContainer onClick={onClick} offsetValue={offsetValue}>
      <CircleDeleteButtonContent>
        <img alt="" src={process.env.PUBLIC_URL + "/icons/close_6px.svg"} />
      </CircleDeleteButtonContent>
    </CircleDeleteButtonContainer>
  );
};

export default CircleDeleteButton;
