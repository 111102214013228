import { FC } from "react";
import { Switch } from "antd";
import { BoardViewItemClickHandler, IBoardFeature } from "../lib";
import { BoardFeaturesToggled } from "lib/types/boardTypes";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import {
  BoardSettingsViewCardIcon,
  BoardSettingsViewCardTitle,
} from "pages/Board/BoardSettings/BoardSettingsDisplayView/styled";
import {
  BoardSettingsFeaturesCard,
  BoardSettingsFeaturesCardContent, BoardSettingsFeaturesCardType
} from "pages/Board/BoardSettings/BoardSettingsFeatures/styled";
import useBoardSetFeatures from "lib/customHooks/dataLayer/board/useBoardSetFeatures";

interface BoardSettingsDisplayViewItemProps {
  boardDisplayBlock: IBoardFeature;
  initialValues: BoardFeaturesToggled;
  editable: boolean;
}

const BoardSettingsDisplayBlockItem: FC<BoardSettingsDisplayViewItemProps> = (props) => {
  const { boardDisplayBlock, editable, initialValues } = props;

  const { board } = useBoardContext() as BoardContextInterface;
  const { setBoardFeatures } = useBoardSetFeatures({ board, updateDetailed: true });

  const handleSwitchOnChange: BoardViewItemClickHandler = async (item, checked) => {
    const payload = { [item]: checked };
    setBoardFeatures(payload);
  };

  const selected = initialValues[boardDisplayBlock.code] || false;

  return (
    <BoardSettingsFeaturesCard selected={!!initialValues[boardDisplayBlock.code]}>
      <BoardSettingsFeaturesCardContent>
        <BoardSettingsFeaturesCardType>
          <BoardSettingsViewCardIcon selected={selected} style={{ marginRight: 5 }}>
            {boardDisplayBlock.icon}
          </BoardSettingsViewCardIcon>
          <BoardSettingsViewCardTitle selected={selected}>
            {boardDisplayBlock.title}
          </BoardSettingsViewCardTitle>
        </BoardSettingsFeaturesCardType>
        <Switch
          size="small"
          onChange={(checked) => handleSwitchOnChange(boardDisplayBlock.code, checked)}
          checked={selected}
          disabled={!editable}
        />
      </BoardSettingsFeaturesCardContent>
    </BoardSettingsFeaturesCard>
  );
};

export default BoardSettingsDisplayBlockItem;
