import styled from "@emotion/styled/macro";
import { DatePicker, Input } from "antd";
import { customSelectsWrapper } from "components/ui/CustomSelects/styled";

export const FilterTypesCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
  margin-right: 7px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 0 11px;
`;

export const FilterTypesDatePicker = styled(DatePicker)`
  width: 125px;
  margin-right: 7px;
`;

export const FilterTypesInput = styled(Input)`
  width: 125px;
  margin-right: 7px;
`;

export const FilterSingleSelectFieldWrapper = styled.div`
  // ${customSelectsWrapper};
`;

export const FilterMultipleSelectFieldWrapper = styled.div`
  .ant-select-selection-item-remove {
  }
  .ant-select-selection-item {
    border-radius: 16px;
  }
  .filter-multiple-select-tag {
    margin-right: 0 !important;
  }
`;
