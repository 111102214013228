import React, { FC, RefObject, useEffect, useRef, useState } from "react";
import urlRegexSafe from "url-regex-safe";
import { Tooltip } from "antd";

import SyncedFieldTooltip from "components/blocks/SyncedFieldTooltip";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { KeyboardKeys } from "lib/types/keyCodes";
import { TaskFieldSetterGetter } from "lib/types/applicationTypes";
import { useBoardContext } from "lib/contexts/BoardContext";
import { useTaskContext } from "lib/contexts/TaskContext";
import { MemberRoles } from "lib/types/types";
import { HttpsRegExp } from "lib/helpers/urlUtils";
import { UrlOpenButton, UrlInput } from "./styled";

interface UrlGenericProps extends TaskFieldSetterGetter {
  propertyType: TaskPropertyClass;
}

const UrlGeneric: FC<UrlGenericProps> = (props) => {
  const { code, value, setValue } = props;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const { board } = useBoardContext();
  const { task } = useTaskContext();
  const isSyncedBoard = !!board?.externalDataSource || !!task?.board?.externalDataSource;
  const isSyncedField = isSyncedBoard && (props.propertyType === TaskPropertyClass.Synced);
  const isEditDisabled = isSyncedField || !allowedToEdit;

  const inputRef = useRef(null) as RefObject<any>;

  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  const isValidUrl = urlRegexSafe({ strict: false }).test(displayValue?.toLowerCase());

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayValue(event.target.value);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (displayValue !== value) {
      setValue({ cellName: code, cellValue: displayValue });
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyboardKeys.Escape) {
      event.preventDefault();
      inputRef.current.blur();
    }

    if (event.key === KeyboardKeys.Tab || event.key === KeyboardKeys.Enter) {
      event.preventDefault();
      inputRef.current.blur();
    }
  };

  const handleUrlOnClick = () => {
    let targetUrl = displayValue;
    if (!HttpsRegExp.test(displayValue)) {
      targetUrl = "http://" + displayValue;
    }
    window.open(targetUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <UrlInput
        ref={inputRef}
        value={displayValue}
        size="small"
        autoComplete="off"
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        placeholder="Empty"
        isValidUrl={isValidUrl}
        disabled={isEditDisabled}
        suffix={isSyncedField &&
          <SyncedFieldTooltip />
        }
      />
      {(isValidUrl) &&
        <Tooltip title="Open URL">
          <UrlOpenButton onClick={handleUrlOnClick}>
            <img src={process.env.PUBLIC_URL + "/icons/url.svg"} alt="view task" />
          </UrlOpenButton>
        </Tooltip>
      }
    </>
  );
};

export default UrlGeneric;
