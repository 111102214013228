interface JSendSuccess<T> {
  status: JSendStatus.Success;
  data: {
    eventName: string;
    contentType: string;
    id: string;
    data: T | null;
    [key: string]: any;
  };
}

export interface JSendError {
  status: JSendStatus.Error;
  message: string;
  code?: string;
  data?: any;
}

interface JSendFail {
  status: JSendStatus.Fail;
  data?: any;
}

export enum JSendStatus {
  Success = "success",
  Error = "error",
  Fail = "fail",
}

export type JSendResponse<T = any> = JSendSuccess<T> | JSendError | JSendFail;
