import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const PsdSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M68.1958 20.5167C65.9179 20.5145 63.7337 19.6086 62.1229 17.9978C60.5121 16.387 59.6063 14.203 59.6042 11.925V0H21.2125C18.2387 0 15.3868 1.1813 13.2841 3.28405C11.1813 5.38679 10 8.23879 10 11.2125V78.8C10.0033 81.7717 11.1861 84.6204 13.2885 86.7204C15.3909 88.8204 18.2409 90 21.2125 90H67.2958C68.7687 90 70.2275 89.71 71.5883 89.1462C72.9492 88.5825 74.1858 87.7563 75.2271 86.7146C76.2687 85.6733 77.095 84.4367 77.6587 83.0758C78.2225 81.715 78.5125 80.2562 78.5125 78.7833V20.5L68.1958 20.5167Z" fill="#005FAD"/>
        <path d="M78.4916 20.5167H68.1749C65.897 20.5145 63.7128 19.6086 62.102 17.9978C60.4912 16.387 59.5853 14.203 59.5833 11.925V0L78.4916 20.5167Z" fill="#005FAD"/>
        <path d="M82.9584 71.0834H30.8584C27.1627 71.0834 24.1667 74.0792 24.1667 77.775V93.3084C24.1667 97.0042 27.1627 100 30.8584 100H82.9584C86.6543 100 89.6501 97.0042 89.6501 93.3084V77.775C89.6501 74.0792 86.6543 71.0834 82.9584 71.0834Z" fill="#005FAD"/>
        <path d="M46.1049 93.75C45.5099 93.75 45.2124 93.4525 45.2124 92.8575V80.235C45.2124 79.64 45.5099 79.3425 46.1049 79.3425H51.0774C52.3738 79.3425 53.3655 79.6896 54.0526 80.3838C54.7467 81.0709 55.0938 82.0625 55.0938 83.3588V83.9963C55.0938 85.2925 54.7467 86.2875 54.0526 86.9817C53.3655 87.6688 52.3738 88.0125 51.0774 88.0125H46.9974V92.8575C46.9974 93.4525 46.6999 93.75 46.1049 93.75ZM46.9974 86.2275H51.0774C51.8851 86.2275 52.4588 86.0575 52.7988 85.7175C53.1388 85.3775 53.3088 84.8038 53.3088 83.9963V83.3588C53.3088 82.5584 53.1388 81.9879 52.7988 81.6479C52.4588 81.3009 51.8851 81.1275 51.0774 81.1275H46.9974V86.2275Z" fill="white"/>
        <path d="M61.8463 93.75C60.6351 93.75 59.6896 93.5055 59.0096 93.0167C58.3367 92.528 57.9151 91.7738 57.7451 90.7538C57.7167 90.4421 57.7805 90.2084 57.9363 90.0525C58.0921 89.8967 58.3188 89.8188 58.6163 89.8188C58.9138 89.8188 59.1301 89.8896 59.2646 90.0313C59.4063 90.1729 59.5017 90.3925 59.5513 90.69C59.6576 91.1788 59.8842 91.515 60.2313 91.6992C60.5855 91.8763 61.1238 91.965 61.8463 91.965H64.9063C65.8201 91.965 66.4401 91.8021 66.7659 91.4763C67.0988 91.1434 67.2651 90.5271 67.2651 89.6275C67.2651 88.7138 67.0951 88.0904 66.7551 87.7575C66.4151 87.4175 65.7988 87.2475 64.9063 87.2475H62.2713C60.9326 87.2475 59.9376 86.9217 59.2859 86.27C58.6413 85.6113 58.3188 84.6125 58.3188 83.2738C58.3188 81.9421 58.6376 80.9538 59.2751 80.3092C59.9196 79.6646 60.9113 79.3425 62.2501 79.3425H64.7788C65.9051 79.3425 66.7871 79.5725 67.4246 80.0329C68.0692 80.4934 68.4763 81.1984 68.6463 82.1475C68.6888 82.4521 68.6321 82.6859 68.4763 82.8488C68.3205 83.0046 68.0867 83.0825 67.7751 83.0825C67.4917 83.0825 67.2792 83.0079 67.1376 82.8592C66.9959 82.7104 66.9038 82.4946 66.8613 82.2113C66.748 81.7934 66.5321 81.51 66.2134 81.3613C65.8946 81.2054 65.4163 81.1275 64.7788 81.1275H62.2501C61.4001 81.1275 60.8301 81.2796 60.5396 81.5842C60.2492 81.8817 60.1038 82.445 60.1038 83.2738C60.1038 84.1096 60.2526 84.6834 60.5501 84.995C60.8476 85.3067 61.4213 85.4625 62.2713 85.4625H64.9063C66.3017 85.4625 67.3396 85.8025 68.0196 86.4825C68.7067 87.1625 69.0501 88.2109 69.0501 89.6275C69.0501 91.0371 68.7101 92.0784 68.0301 92.7513C67.3501 93.4171 66.3088 93.75 64.9063 93.75H61.8463Z" fill="white"/>
        <path d="M27.895 52.9924C27.5691 53.6041 26.7362 53.5679 26.5591 52.8979C26.0784 51.0795 25.6573 47.9408 27.0876 44.6641C27.3683 44.0208 28.2677 44.1024 28.5914 44.7254C28.7477 45.0258 28.9209 45.3149 29.1001 45.5366C29.3926 45.8987 29.7052 45.5724 29.612 45.1166C29.3432 43.8024 28.9327 41.2847 29.4973 40.3388C29.5047 40.3264 29.5117 40.3159 29.52 40.3041C29.7181 40.023 32.4001 36.2531 35.5946 33.5959C36.1014 33.1743 36.8575 33.5334 36.9784 34.1815C37.0266 34.4397 37.0875 34.7009 37.1641 34.9386C37.3408 35.4859 37.818 35.2584 37.9004 34.6892C38.0116 33.9207 38.2718 32.8105 38.8692 31.4667C38.9239 31.3436 39.0092 31.2366 39.1194 31.159C40.1076 30.4624 45.4802 26.9719 53.9529 26.9348C54.5974 26.932 54.9749 27.6434 54.6299 28.1877C53.3362 30.2282 50.7296 34.4035 49.0666 37.4958C47.9293 39.6555 46.6849 41.757 45.3381 43.7924C45.1847 44.0241 44.9478 44.2466 44.7022 44.3766C44.171 44.6579 43.1639 45.1537 42.1094 45.4737C41.5267 45.6504 40.8016 46.2349 41.3816 46.4208C41.9204 46.5933 43.0085 47.3258 42.678 47.7849C40.9848 50.1374 36.8844 54.8062 30.4833 55.752C30.2024 55.7937 29.9549 55.9654 29.839 56.2245C29.4175 57.167 28.4158 59.572 28.0819 61.8345C28.0441 62.0908 27.8999 62.3204 27.6713 62.4425C27.3569 62.6095 26.8804 62.8424 26.428 62.9799C25.9768 63.1174 25.6796 62.7045 25.8195 62.2541C26.8963 58.7887 31.7867 45.0541 44.398 34.6438C44.5461 34.5216 44.5337 34.4807 44.3536 34.5474C42.8776 35.0937 35.6809 38.3794 27.895 52.9924Z" fill="#005FAD"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default PsdSvg;
