import { FC } from "react";
import { BoardInterface } from "lib/types/boardTypes";
import { BoardLabelContainer, BoardLabelIcon, BoardLabelTitle } from "components/blocks/BoardLabel/styled";
import { Tooltip } from "antd";

interface BoardLabelProps {
  board: Pick<BoardInterface, "id" | "name" | "icon">;
  disabled?: boolean;
}

const BoardLabel: FC<BoardLabelProps> = (props) => {
  const { board, disabled } = props;

  return (
    <Tooltip
      title={disabled ? "Viewpoints should be enabled in the board settings" : ""}
      mouseLeaveDelay={0}
    >
      <BoardLabelContainer key={board.id}>
        <BoardLabelIcon
          src={`/images/${board.icon}.png`}
          alt="icon"
        />
        <BoardLabelTitle>{board.name}</BoardLabelTitle>
      </BoardLabelContainer>
    </Tooltip>
  );
};

export default BoardLabel;
