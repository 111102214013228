import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const DllSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.6101 20.4357C64.3404 20.4335 62.1644 19.5309 60.5595 17.9261C58.9549 16.3212 58.052 14.1451 58.0499 11.8755V0H19.8217C16.8603 0 14.0202 1.17639 11.9261 3.27042C9.83211 5.36444 8.65576 8.20461 8.65576 11.166V78.4772C8.65576 81.4386 9.83211 84.2788 11.9261 86.3726C14.0202 88.4668 16.8603 89.6432 19.8217 89.6432H65.7138C68.6752 89.6432 71.5155 88.4668 73.6092 86.3726C75.7034 84.2788 76.8798 81.4386 76.8798 78.4772V20.4357H66.6101Z" fill="#A140FF"/>
        <path d="M76.8797 20.4357H66.61C64.3403 20.4335 62.1643 19.5309 60.5593 17.9261C58.9548 16.3212 58.0519 14.1451 58.0498 11.8755V0L76.8797 20.4357Z" fill="#A140FF"/>
        <path d="M84.9794 70.7925H33.0955C29.4152 70.7925 26.4316 73.7759 26.4316 77.4564V92.9253C26.4316 96.6058 29.4152 99.5892 33.0955 99.5892H84.9794C88.6599 99.5892 91.6433 96.6058 91.6433 92.9253V77.4564C91.6433 73.7759 88.6599 70.7925 84.9794 70.7925Z" fill="#A140FF"/>
        <path d="M42.9362 93.3609C42.3436 93.3609 42.0474 93.0646 42.0474 92.4721V79.902C42.0474 79.3094 42.3436 79.0132 42.9362 79.0132H48.0362C49.8772 79.0132 51.2315 79.4505 52.0991 80.3252C52.9738 81.1929 53.4112 82.5472 53.4112 84.3883V87.9858C53.4112 89.8269 52.9776 91.1846 52.1099 92.0592C51.2494 92.9269 49.8913 93.3609 48.0362 93.3609H42.9362ZM43.825 91.5833H48.0362C48.9391 91.5833 49.6515 91.4738 50.1734 91.2551C50.7025 91.0294 51.0763 90.6555 51.295 90.1335C51.5207 89.6045 51.6336 88.8887 51.6336 87.9858V84.3883C51.6336 83.4924 51.5207 82.7833 51.295 82.2613C51.0763 81.7323 50.7025 81.3551 50.1734 81.1294C49.6515 80.9036 48.9391 80.7908 48.0362 80.7908H43.825V91.5833Z" fill="white"/>
        <path d="M58.1876 93.3609C57.5951 93.3609 57.2988 93.0646 57.2988 92.4721V79.902C57.2988 79.3094 57.5951 79.0132 58.1876 79.0132C58.7802 79.0132 59.0764 79.3094 59.0764 79.902V91.5833H64.9383C65.5308 91.5833 65.827 91.8796 65.827 92.4721C65.827 93.0646 65.5308 93.3609 64.9383 93.3609H58.1876Z" fill="white"/>
        <path d="M69.6366 93.3609C69.0441 93.3609 68.7478 93.0646 68.7478 92.4721V79.902C68.7478 79.3094 69.0441 79.0132 69.6366 79.0132C70.2291 79.0132 70.5254 79.3094 70.5254 79.902V91.5833H76.3872C76.9798 91.5833 77.276 91.8796 77.276 92.4721C77.276 93.0646 76.9798 93.3609 76.3872 93.3609H69.6366Z" fill="white"/>
        <path d="M37.0289 66.1413C36.5557 66.1413 36.1019 65.9533 35.7673 65.6189C35.4327 65.284 35.2446 64.8301 35.2446 64.3571V61.6143C35.2446 61.3803 35.2909 61.1483 35.3806 60.9322C35.4703 60.716 35.6017 60.5197 35.7675 60.3542C35.9332 60.189 36.13 60.0579 36.3464 59.9687C36.5629 59.8795 36.7948 59.8338 37.0289 59.8342C37.501 59.8342 37.9538 60.0218 38.2876 60.3558C38.6214 60.6894 38.809 61.1421 38.809 61.6143V64.3571C38.809 64.8297 38.6216 65.2828 38.2879 65.6172C37.9542 65.9517 37.5014 66.14 37.0289 66.1413Z" fill="#A140FF"/>
        <path d="M37.0289 44.025C36.7948 44.0254 36.5629 43.9798 36.3464 43.8906C36.13 43.8014 35.9332 43.6706 35.7675 43.5051C35.6017 43.3399 35.4703 43.1433 35.3806 42.9271C35.2909 42.7109 35.2446 42.4789 35.2446 42.2449V39.0541C35.2446 38.5809 35.4327 38.127 35.7673 37.7924C36.1019 37.4578 36.5557 37.2698 37.0289 37.2698C37.5014 37.2709 37.9542 37.4594 38.2879 37.7939C38.6216 38.1284 38.809 38.5816 38.809 39.0541V42.2449C38.809 42.7171 38.6214 43.1698 38.2876 43.5038C37.9538 43.8375 37.501 44.025 37.0289 44.025Z" fill="#A140FF"/>
        <path d="M43.3567 64.4481C43.0432 64.4477 42.7354 64.3651 42.4638 64.2087C42.1922 64.0523 41.9664 63.827 41.809 63.556L40.4812 61.249C40.245 60.8394 40.1809 60.3531 40.303 59.8963C40.4252 59.4398 40.7235 59.0502 41.1326 58.8133C41.5425 58.5776 42.0295 58.5141 42.4862 58.6369C42.9429 58.7597 43.3321 59.0589 43.5683 59.4689L44.9003 61.7718C45.1361 62.1817 45.1996 62.6685 45.0766 63.1253C44.9537 63.5822 44.6544 63.9714 44.2446 64.2075C43.9751 64.3647 43.6688 64.4477 43.3567 64.4481Z" fill="#A140FF"/>
        <path d="M32.34 45.3611C32.0264 45.3615 31.7182 45.2793 31.4465 45.1229C31.1748 44.966 30.9491 44.7407 30.7923 44.4689L29.1616 41.6391C29.0442 41.4368 28.9679 41.2133 28.9371 40.9814C28.9063 40.7496 28.9216 40.5139 28.9821 40.288C29.0426 40.062 29.1472 39.8503 29.2897 39.6649C29.4324 39.4795 29.6102 39.3241 29.8131 39.2075C30.0157 39.09 30.2394 39.0137 30.4716 38.9828C30.7038 38.9519 30.9398 38.9672 31.1661 39.0277C31.3924 39.0882 31.6044 39.1929 31.7902 39.3355C31.976 39.4782 32.1318 39.656 32.2487 39.859L33.8794 42.6889C33.9969 42.8914 34.0733 43.1154 34.1042 43.3474C34.1351 43.5797 34.1197 43.8158 34.0592 44.042C33.9987 44.2681 33.8942 44.4802 33.7516 44.666C33.6089 44.8519 33.431 45.0075 33.228 45.1246C32.958 45.2802 32.6516 45.3615 32.34 45.3611Z" fill="#A140FF"/>
        <path d="M47.9875 59.8134C47.6742 59.8125 47.3666 59.7295 47.0954 59.5727L44.7884 58.2407C44.3795 58.0046 44.0813 57.6159 43.9591 57.1598C43.8369 56.7038 43.9009 56.2179 44.1369 55.8092C44.3731 55.3993 44.7623 55.1001 45.2191 54.9773C45.6758 54.8544 46.1626 54.9179 46.5726 55.1536L48.8797 56.4856C49.2888 56.7225 49.5871 57.1121 49.7092 57.5685C49.8315 58.0254 49.7674 58.5117 49.5311 58.9212C49.3753 59.1926 49.1504 59.4183 48.8794 59.5752C48.6083 59.7316 48.3006 59.8138 47.9875 59.8134Z" fill="#A140FF"/>
        <path d="M28.8961 48.7968C28.5842 48.7955 28.2781 48.7125 28.0081 48.5561L25.1782 46.9254C24.7691 46.6885 24.4708 46.2989 24.3487 45.8424C24.2265 45.3856 24.2906 44.8993 24.5269 44.4897C24.763 44.0798 25.1522 43.7806 25.609 43.6578C26.0657 43.5349 26.5525 43.598 26.9625 43.8341L29.7924 45.469C30.2015 45.7059 30.4998 46.0955 30.622 46.552C30.7441 47.0088 30.6801 47.4951 30.4439 47.9047C30.2871 48.176 30.0614 48.4018 29.7897 48.5582C29.5179 48.715 29.2097 48.7972 28.8961 48.7968Z" fill="#A140FF"/>
        <path d="M49.6805 53.4896H46.9336C46.4608 53.4884 46.0075 53.3 45.6732 52.966C45.3388 52.6315 45.1505 52.1784 45.1494 51.7054C45.1505 51.2328 45.339 50.7801 45.6734 50.4465C46.0079 50.1128 46.4612 49.9253 46.9336 49.9253H49.6805C50.1531 49.9253 50.6062 50.1128 50.9407 50.4465C51.2751 50.7801 51.4635 51.2328 51.4647 51.7054C51.4635 52.1784 51.2751 52.6315 50.9411 52.966C50.6066 53.3 50.1535 53.4884 49.6805 53.4896Z" fill="#A140FF"/>
        <path d="M27.5642 53.4896H24.3775C23.9046 53.4884 23.4514 53.3 23.1171 52.966C22.7827 52.6315 22.5943 52.1784 22.5933 51.7054C22.5943 51.2328 22.7828 50.7801 23.1173 50.4465C23.4518 50.1128 23.905 49.9253 24.3775 49.9253H27.5642C28.0363 49.9253 28.489 50.1128 28.8229 50.4469C29.1567 50.7805 29.3443 51.2332 29.3443 51.7054C29.3443 52.178 29.1569 52.6311 28.8232 52.9655C28.4895 53.3 28.0367 53.4884 27.5642 53.4896Z" fill="#A140FF"/>
        <path d="M45.4854 48.6059C45.1717 48.6063 44.8635 48.5242 44.5918 48.3673C44.32 48.2109 44.0944 47.9856 43.9376 47.7138C43.8209 47.5109 43.7453 47.2872 43.7151 47.0557C43.6849 46.8237 43.7007 46.5881 43.7616 46.3619C43.8225 46.1362 43.9273 45.9246 44.07 45.7395C44.2127 45.554 44.3904 45.3988 44.5932 45.2823L47.0828 43.8341C47.4928 43.598 47.9797 43.5349 48.4364 43.6578C48.8932 43.7806 49.2824 44.0798 49.5185 44.4897C49.7548 44.8993 49.8189 45.3856 49.6966 45.8424C49.5745 46.2989 49.2762 46.6885 48.8671 46.9254L46.3774 48.3694C46.1064 48.5262 45.7985 48.608 45.4854 48.6059Z" fill="#A140FF"/>
        <path d="M26.0704 59.8133C25.7575 59.8129 25.4501 59.7303 25.1791 59.5739C24.9082 59.4175 24.6833 59.1922 24.5269 58.9212C24.2906 58.5117 24.2265 58.0254 24.3487 57.5685C24.4708 57.1121 24.7691 56.7225 25.1782 56.4855L27.8173 54.9627C28.227 54.7274 28.7131 54.6644 29.1695 54.7864C29.6258 54.9084 30.0153 55.2059 30.2529 55.6142C30.4891 56.0237 30.5532 56.51 30.4311 56.9669C30.309 57.4233 30.0106 57.8129 29.6015 58.0498L26.9625 59.5727C26.6913 59.7295 26.3837 59.8125 26.0704 59.8133Z" fill="#A140FF"/>
        <path d="M37.2489 63.3985H36.9626C34.9233 63.3545 32.9336 62.7607 31.2032 61.6806C29.4927 60.62 28.0905 59.1292 27.1368 57.357C26.2454 55.6881 25.7781 53.8259 25.7759 51.9337C25.7759 51.8342 25.7759 51.7429 25.7759 51.6474C25.825 49.6976 26.3704 47.7927 27.3609 46.1122C28.35 44.4341 29.7493 43.035 31.4273 42.0458C33.1146 41.0546 35.0269 40.5105 36.9833 40.4649H37.2447C39.1391 40.4625 41.0043 40.9317 42.6721 41.83C44.443 42.7806 45.9326 44.1798 46.9916 45.8881C48.0742 47.6267 48.6676 49.625 48.7094 51.6723V51.9337C48.7098 53.9146 48.1966 55.8619 47.2198 57.5852C46.1987 59.3939 44.7039 60.8902 42.8961 61.913C41.1735 62.8873 39.228 63.3993 37.2489 63.3985ZM37.2032 44.025H37.0911C35.7371 44.0524 34.4128 44.4267 33.2448 45.1122C32.0862 45.7943 31.1196 46.7595 30.4356 47.9171C29.7513 49.0798 29.3756 50.398 29.3443 51.747V51.9296C29.3447 53.2321 29.6653 54.5146 30.2779 55.664C30.9361 56.8889 31.9049 57.9192 33.087 58.6516C34.284 59.3943 35.6581 59.8026 37.0663 59.8341H37.2489C38.6145 59.8358 39.957 59.4827 41.1451 58.8092C42.3927 58.103 43.4242 57.0702 44.1286 55.8217C44.8012 54.635 45.1543 53.2939 45.1535 51.9296V51.7719C45.1228 50.3545 44.7145 48.9707 43.9709 47.7636C43.2377 46.5856 42.2092 45.62 40.9875 44.9628C39.8223 44.3437 38.5225 44.0217 37.2032 44.025Z" fill="#A140FF"/>
        <path d="M41.8256 45.1743C41.514 45.1752 41.2076 45.0934 40.9376 44.9378C40.7346 44.8208 40.5567 44.6652 40.414 44.4793C40.2714 44.2934 40.1669 44.0814 40.1064 43.8553C40.0459 43.6291 40.0306 43.393 40.0614 43.1607C40.0923 42.9287 40.1687 42.7046 40.2862 42.5021L41.809 39.859C41.9259 39.656 42.0817 39.4782 42.2675 39.3355C42.4532 39.1929 42.6653 39.0882 42.8916 39.0277C43.1179 38.9672 43.3539 38.9519 43.586 38.9828C43.8182 39.0137 44.042 39.09 44.2446 39.2075C44.4474 39.3241 44.6252 39.4795 44.7679 39.6648C44.9106 39.8501 45.0155 40.0617 45.0763 40.2875C45.1372 40.5133 45.153 40.7489 45.1228 40.9808C45.0926 41.2127 45.0169 41.4364 44.9003 41.6391L43.3733 44.2822C43.2165 44.554 42.9908 44.7793 42.7191 44.9358C42.4474 45.0926 42.1392 45.1748 41.8256 45.1743Z" fill="#A140FF"/>
        <path d="M30.7052 64.4483C30.3932 64.4479 30.0868 64.3649 29.8173 64.2077C29.6143 64.0907 29.4364 63.9351 29.2938 63.7492C29.1511 63.5633 29.0465 63.3512 28.986 63.1251C28.9255 62.899 28.9102 62.6629 28.941 62.4305C28.9719 62.1985 29.0483 61.9745 29.1658 61.772L30.6057 59.2824C30.7225 59.0795 30.8784 58.9014 31.0642 58.7587C31.2499 58.6164 31.462 58.5114 31.6883 58.4512C31.9146 58.3907 32.1506 58.3753 32.3827 58.406C32.6149 58.4371 32.8387 58.5135 33.0413 58.6309C33.4504 58.8678 33.7488 59.2575 33.871 59.7139C33.9931 60.1707 33.929 60.657 33.6928 61.0666L32.253 63.5562C32.0962 63.828 31.8706 64.0533 31.5989 64.2102C31.3271 64.3666 31.0189 64.4488 30.7052 64.4483Z" fill="#A140FF"/>
        <path d="M53.4604 41.7678C53.172 41.7678 52.8957 41.6533 52.6915 41.4494C52.4878 41.2455 52.3733 40.969 52.3733 40.6807V39.021C52.3733 38.8784 52.4015 38.7373 52.4563 38.6056C52.5106 38.4739 52.5911 38.3543 52.692 38.2537C52.7928 38.1531 52.9127 38.0734 53.0447 38.0192C53.1766 37.9651 53.3177 37.9375 53.4604 37.938C53.7476 37.938 54.0231 38.0521 54.2264 38.2552C54.4293 38.4582 54.5434 38.7337 54.5434 39.021V40.6807C54.5438 40.8233 54.5164 40.9646 54.4621 41.0964C54.4081 41.2283 54.3285 41.3482 54.2276 41.4492C54.1272 41.55 54.0073 41.6305 53.8758 41.6848C53.7442 41.7396 53.6032 41.7678 53.4604 41.7678Z" fill="#A140FF"/>
        <path d="M53.4604 28.3113C53.3177 28.3118 53.1766 28.2842 53.0447 28.23C52.9127 28.1759 52.7928 28.0962 52.692 27.9956C52.5911 27.8949 52.5106 27.7754 52.4563 27.6437C52.4015 27.512 52.3733 27.3709 52.3733 27.2283V25.2864C52.3733 24.9981 52.4878 24.7215 52.6915 24.5177C52.8957 24.3138 53.172 24.1992 53.4604 24.1992C53.6032 24.1992 53.7442 24.2274 53.8758 24.2821C54.0073 24.3368 54.1272 24.4169 54.2276 24.5179C54.3285 24.6189 54.4081 24.7388 54.4621 24.8707C54.5164 25.0025 54.5438 25.1438 54.5434 25.2864V27.2283C54.5434 27.5155 54.4293 27.791 54.2264 27.9941C54.0231 28.1972 53.7476 28.3113 53.4604 28.3113Z" fill="#A140FF"/>
        <path d="M57.3111 40.7345C57.1207 40.735 56.9331 40.6855 56.768 40.5908C56.6028 40.4961 56.4651 40.3596 56.3692 40.1951L55.5601 38.7926C55.4182 38.5405 55.3825 38.2425 55.4601 37.964C55.5381 37.6855 55.7231 37.4492 55.975 37.3071C56.0995 37.237 56.2368 37.1921 56.3783 37.175C56.5202 37.1579 56.6642 37.169 56.8016 37.2075C56.9393 37.2461 57.068 37.3113 57.1804 37.3996C57.2924 37.4879 57.3862 37.5975 57.4563 37.7221L58.2862 39.1245C58.3563 39.2491 58.4012 39.3861 58.4182 39.5279C58.4356 39.6698 58.4244 39.8136 58.3858 39.9512C58.3472 40.0888 58.2821 40.2174 58.1937 40.3298C58.1053 40.4421 57.9958 40.5359 57.8713 40.6059C57.6999 40.6992 57.5061 40.7437 57.3111 40.7345Z" fill="#A140FF"/>
        <path d="M50.6223 29.1246C50.4314 29.1242 50.2439 29.0739 50.0787 28.9785C49.9136 28.8831 49.7762 28.7461 49.6804 28.581L48.6887 26.859C48.6186 26.7345 48.5737 26.5974 48.5566 26.4556C48.5395 26.3137 48.5506 26.1699 48.5892 26.0323C48.6277 25.8948 48.6929 25.7661 48.7812 25.6538C48.8695 25.5415 48.9791 25.4477 49.1037 25.3777C49.228 25.3071 49.3651 25.2617 49.507 25.2444C49.649 25.227 49.7933 25.2379 49.9306 25.2765C50.0684 25.315 50.197 25.3805 50.3095 25.4691C50.4219 25.5577 50.5153 25.6677 50.585 25.7927L51.5767 27.5146C51.6468 27.6391 51.6916 27.7762 51.7086 27.9181C51.726 28.0599 51.7148 28.2037 51.6763 28.3413C51.6377 28.4789 51.5725 28.6075 51.4841 28.7199C51.3958 28.8322 51.2862 28.926 51.1617 28.996C50.9958 29.0839 50.8103 29.1281 50.6223 29.1246Z" fill="#A140FF"/>
        <path d="M60.1286 37.9171C59.9378 37.918 59.7502 37.8679 59.5851 37.7719L58.1826 36.942C58.0581 36.872 57.9485 36.7782 57.8602 36.6659C57.7718 36.5535 57.7066 36.4249 57.668 36.2873C57.6295 36.1498 57.6182 36.0059 57.6357 35.8641C57.6527 35.7222 57.6975 35.5851 57.7676 35.4606C57.8373 35.3356 57.9307 35.2257 58.0431 35.1371C58.1552 35.0485 58.2842 34.983 58.422 34.9444C58.5597 34.9059 58.7033 34.8949 58.8456 34.9123C58.9875 34.9297 59.1245 34.975 59.249 35.0457L60.6514 35.8756C60.7763 35.9451 60.8863 36.0388 60.9751 36.1511C61.0635 36.2634 61.129 36.3921 61.1676 36.5298C61.2062 36.6675 61.217 36.8115 61.1996 36.9535C61.1826 37.0954 61.1369 37.2326 61.0664 37.3569C60.9739 37.5253 60.8386 37.666 60.6734 37.7645C60.5087 37.8629 60.3207 37.9156 60.1286 37.9171Z" fill="#A140FF"/>
        <path d="M48.5145 31.2117C48.3236 31.2126 48.136 31.1625 47.971 31.0664L46.2489 30.0747C46.1243 30.0048 46.0148 29.911 45.9265 29.7986C45.8382 29.6863 45.7729 29.5576 45.7344 29.4201C45.6959 29.2825 45.6848 29.1387 45.7019 28.9968C45.719 28.855 45.7639 28.7179 45.834 28.5935C45.9761 28.3415 46.2123 28.1564 46.4909 28.0786C46.7694 28.0008 47.0675 28.0367 47.3195 28.1785L49.0373 29.1702C49.2892 29.3123 49.4744 29.5485 49.5522 29.8271C49.63 30.1056 49.594 30.4036 49.4522 30.6557C49.359 30.823 49.2232 30.9626 49.0584 31.0603C48.8937 31.1579 48.706 31.2102 48.5145 31.2117Z" fill="#A140FF"/>
        <path d="M61.1575 34.0705H59.4978C59.355 34.0705 59.214 34.0424 59.0824 33.9877C58.9509 33.933 58.8314 33.8529 58.7306 33.7519C58.6302 33.6509 58.5501 33.531 58.4961 33.3991C58.4418 33.2672 58.4144 33.126 58.4148 32.9834C58.4148 32.6962 58.5289 32.4207 58.7318 32.2176C58.9351 32.0145 59.2107 31.9004 59.4978 31.9004H61.1575C61.3003 31.8999 61.4414 31.9275 61.5733 31.9816C61.7053 32.0358 61.8252 32.1155 61.926 32.2161C62.0268 32.3167 62.1073 32.4363 62.1617 32.568C62.2165 32.6997 62.2447 32.8408 62.2447 32.9834C62.2447 33.2717 62.1302 33.5482 61.9264 33.7521C61.7223 33.956 61.4459 34.0705 61.1575 34.0705Z" fill="#A140FF"/>
        <path d="M47.7178 34.0705H45.7759C45.6333 34.0705 45.4921 34.0424 45.3605 33.9877C45.2288 33.933 45.1093 33.8529 45.0086 33.7518C44.908 33.6508 44.8283 33.5309 44.7741 33.3991C44.7199 33.2672 44.6923 33.126 44.6929 32.9834C44.6929 32.8411 44.7209 32.7004 44.7753 32.5689C44.8298 32.4376 44.9095 32.3182 45.0101 32.2176C45.1106 32.117 45.2301 32.0373 45.3615 31.9828C45.4929 31.9284 45.6337 31.9004 45.7759 31.9004H47.7178C48.005 31.9004 48.2804 32.0145 48.4835 32.2176C48.6866 32.4207 48.8008 32.6962 48.8008 32.9834C48.8013 33.126 48.7738 33.2672 48.7196 33.3991C48.6654 33.5309 48.5857 33.6508 48.485 33.7518C48.3844 33.8529 48.2649 33.933 48.1332 33.9877C48.0016 34.0424 47.8603 34.0705 47.7178 34.0705Z" fill="#A140FF"/>
        <path d="M58.6058 31.1203C58.4149 31.12 58.2274 31.0696 58.0622 30.9743C57.8971 30.8789 57.7597 30.7418 57.6639 30.5768C57.5937 30.4522 57.5489 30.3152 57.5319 30.1734C57.5145 30.0315 57.5257 29.8877 57.5643 29.7501C57.6029 29.6125 57.668 29.4839 57.7564 29.3715C57.8448 29.2592 57.9543 29.1654 58.0788 29.0954L59.6016 28.2158C59.7261 28.1457 59.863 28.1008 60.0049 28.0837C60.1469 28.0666 60.2908 28.0777 60.4282 28.1162C60.5659 28.1547 60.6946 28.22 60.807 28.3083C60.9191 28.3966 61.0128 28.5061 61.083 28.6307C61.1535 28.7551 61.1991 28.8922 61.2161 29.0342C61.2336 29.1761 61.2228 29.3201 61.1842 29.4578C61.1456 29.5956 61.08 29.7243 60.9917 29.8366C60.9029 29.9488 60.7929 30.0425 60.668 30.112L59.1452 30.9917C58.9792 31.0796 58.7937 31.1239 58.6058 31.1203Z" fill="#A140FF"/>
        <path d="M46.7925 37.917C46.6022 37.9172 46.4151 37.867 46.2505 37.7716C46.0858 37.6761 45.9492 37.5387 45.8548 37.3734C45.7827 37.2504 45.7358 37.1145 45.7166 36.9732C45.6974 36.832 45.7064 36.6883 45.743 36.5506C45.7797 36.4128 45.8433 36.2838 45.9301 36.1708C46.017 36.0577 46.1253 35.963 46.2489 35.8921L47.8548 34.9668C47.9792 34.8962 48.1162 34.8508 48.2582 34.8335C48.4002 34.8161 48.5442 34.827 48.682 34.8656C48.8197 34.9041 48.9483 34.9696 49.0606 35.0582C49.1729 35.1468 49.2665 35.2568 49.3361 35.3818C49.4061 35.5063 49.4511 35.6433 49.4681 35.7852C49.4852 35.927 49.4742 36.0709 49.4356 36.2084C49.3971 36.346 49.3319 36.4746 49.2436 36.587C49.1553 36.6993 49.0457 36.7931 48.9211 36.8631L47.3195 37.7925C47.1571 37.8776 46.9759 37.9204 46.7925 37.917Z" fill="#A140FF"/>
        <path d="M53.5932 40.0996H53.4148C52.1758 40.0727 50.9675 39.7114 49.9169 39.054C48.8701 38.4113 48.0114 37.5039 47.4273 36.4233C46.8814 35.4021 46.5964 34.2618 46.5974 33.1038V32.9253C46.6276 31.7396 46.9603 30.5812 47.5641 29.5602C48.168 28.5316 49.0251 27.6745 50.0538 27.0706C51.0808 26.4677 52.2447 26.1365 53.4355 26.1079H53.5932C54.7513 26.1043 55.8924 26.3896 56.9127 26.9378C57.9932 27.5221 58.9007 28.3808 59.5434 29.4274C60.199 30.4863 60.56 31.701 60.5891 32.9461V33.1079C60.5899 34.3121 60.2783 35.4959 59.6845 36.5436C59.0621 37.6452 58.1513 38.5562 57.0497 39.1785C55.9974 39.7813 54.8061 40.0988 53.5932 40.0996ZM53.5642 28.3112H53.5019C52.6766 28.325 51.8692 28.5523 51.1575 28.971C50.4517 29.3864 49.8633 29.9748 49.448 30.6805C49.0321 31.3873 48.8037 32.1886 48.7841 33.0084V33.1204C48.7837 33.9135 48.9789 34.6946 49.3525 35.3943C49.7531 36.1371 50.3409 36.7622 51.058 37.2075C51.787 37.6596 52.6235 37.9089 53.4812 37.9295H53.5932C54.4235 37.9301 55.2397 37.7156 55.9625 37.3071C56.7219 36.8774 57.3501 36.2491 57.7799 35.4897C58.1874 34.7665 58.4019 33.9505 58.4024 33.1204V33.0208C58.3837 32.1595 58.136 31.3189 57.6845 30.5851C57.2376 29.8679 56.6111 29.28 55.8671 28.8797C55.1584 28.5026 54.3671 28.3072 53.5642 28.3112Z" fill="#A140FF"/>
        <path d="M56.3776 29.0084C56.1879 29.0088 56.002 28.9586 55.8381 28.8632C55.7137 28.7932 55.6041 28.6993 55.5157 28.587C55.4274 28.4747 55.3622 28.3461 55.3236 28.2085C55.285 28.0709 55.2742 27.9271 55.2913 27.7852C55.3083 27.6434 55.3531 27.5063 55.4232 27.3818L56.3527 25.776C56.4228 25.6514 56.5166 25.5419 56.6286 25.4536C56.741 25.3653 56.8697 25.3 57.0074 25.2615C57.1448 25.2229 57.2888 25.2119 57.4307 25.229C57.5726 25.2461 57.7095 25.291 57.834 25.361C57.9585 25.431 58.068 25.5248 58.1564 25.6371C58.2448 25.7495 58.3099 25.8781 58.3485 26.0157C58.3871 26.1533 58.3979 26.2971 58.3809 26.439C58.3639 26.5808 58.3191 26.7178 58.2489 26.8424L57.3195 28.4523C57.2261 28.6205 57.09 28.7608 56.924 28.8586C56.7585 28.9563 56.5701 29.0081 56.3776 29.0084Z" fill="#A140FF"/>
        <path d="M49.6099 40.7345C49.4204 40.7349 49.2342 40.6847 49.0705 40.5892C48.9459 40.5193 48.8363 40.4255 48.748 40.3131C48.6597 40.2008 48.5945 40.0722 48.5559 39.9346C48.5174 39.797 48.5064 39.6532 48.5234 39.5113C48.5405 39.3695 48.5854 39.2324 48.6555 39.1079L49.5311 37.5892C49.601 37.4647 49.6948 37.3551 49.807 37.2668C49.9194 37.1785 50.0481 37.1133 50.1858 37.0747C50.3232 37.0362 50.4671 37.0252 50.6091 37.0422C50.751 37.0593 50.8879 37.1042 51.0124 37.1743C51.1369 37.2442 51.2464 37.3381 51.3348 37.4504C51.4232 37.5628 51.4883 37.6914 51.5269 37.8289C51.5655 37.9665 51.5763 38.1103 51.5593 38.2522C51.5422 38.394 51.4974 38.5311 51.4273 38.6557L50.5518 40.1784C50.4572 40.3455 50.3207 40.4848 50.1551 40.5824C49.99 40.68 49.802 40.7325 49.6099 40.7345Z" fill="#A140FF"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default DllSvg;
