import { FC, MouseEventHandler } from "react";
import { AuthButton } from "pages/Auth/styled";

const AuthSignInButton: FC<{ text: string, onClick?: MouseEventHandler<HTMLButtonElement> }> = (props) => {
  return (
    <AuthButton onClick={props.onClick} type="submit">{props.text}</AuthButton>
  );
};

export default AuthSignInButton;
