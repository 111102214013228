import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import authReducers from "./slices/authSlice";
import currentBoardReducer from "./slices/currentBoardSlice";
import currentTaskReducer from "./slices/currentTaskSlice";
import currentUserReducer from "./slices/currentUserSlice";
import notificationsSlice from "./slices/notificationsSlice";
import progressReducer from "./slices/progressSlice";
import tasksSlice from "./slices/tasksSlice";
import workspaceReducer from "./slices/workspaceSlice";
import boardFilterAndSortReducer from "./slices/tasksFilterAndSortSlice";
import organizationSlice from "./slices/organizationSlice";
import fileContainerSlice from "./slices/fileContainerSlice";
import documentsSlice from "./slices/documentsSlice";

export const store = configureStore({
  reducer: {
    auth: authReducers,
    workspaces: workspaceReducer,
    organizations: organizationSlice,
    progress: progressReducer,
    tasks: tasksSlice,
    currentUserReducer: currentUserReducer,
    currentBoardReducer: currentBoardReducer,
    currentTaskReducer: currentTaskReducer,
    notifications: notificationsSlice,
    boardFilterAndSort: boardFilterAndSortReducer,
    fileContainers: fileContainerSlice,
    documents: documentsSlice,
  },
  middleware: [],
});

export type AppDispatch = typeof store.dispatch
export type StoreType = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, StoreType, unknown, Action<string>>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<StoreType> = useSelector;
