import styled from "@emotion/styled/macro";

export const ViewModalGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 20px 0;
`;

