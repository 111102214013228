import { FC } from "react";
import { Select, SelectProps } from "antd";

import { FormItem } from "../styled";
import { getUserFullName } from "lib/helpers/userUtils";
import ReusableSelectOption from "components/blocks/ReusableFields/ReusableMultiSelectOption";

type SelectorMode = "multiple" | "tags" | undefined;

interface SelectFieldProps extends SelectProps {
  label: string;
  name: string;
  placeholder: string;
  mode?: SelectorMode;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  rules?: Array<any>
  initialValue?: string;
  data?: Array<any>;
  optionValue?: string;
  optionLabel?: string;
  optionDisplayType?: "status" | "user";
  defaultActiveFirstOption?: boolean;
  filterOption?: boolean | any;
}

const { Option } = Select;

const SelectField: FC<SelectFieldProps> = (props) => {
  const {
    label,
    name,
    placeholder,
    mode,
    onChange,
    onFocus,
    onBlur,
    rules,
    children,
    initialValue,
    data,
    disabled,
    filterOption,
    optionValue,
    optionLabel,
    optionDisplayType,
    defaultActiveFirstOption,
  } = props;

  const getOptionValue = (option: Record<string, any>) => {
    if (optionValue) {
      return option[optionValue];
    }

    if (option.value || option.value === 0) {
      return option.value;
    }

    return option.name;
  };

  const getOptionLabel = (option: Record<string, any>) => {
    if (optionLabel) {
      return option[optionLabel];
    }

    return option.name;
  };

  const renderOption = (option: any) => {
    switch (optionDisplayType) {
      case "user":
        return (
          <Option value={option.id} key={option.id} >
            <ReusableSelectOption selectTitle={getUserFullName(option)} selectColor={option.color} />
          </Option>);
      case "status":
        return (
          <Option value={String(option.id)} key={option.id}>
            <ReusableSelectOption selectTitle={option?.title} selectColor={option.color} />
          </Option>);
      default:
        return (
          <Option value={getOptionValue(option)} key={option.id}>
            {getOptionLabel(option)}
          </Option>
        );
    }
  };

  return (
    <FormItem label={label} name={name} rules={rules} initialValue={initialValue}>
      <Select
        {...props}
        style={{ width: "100%" }}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        optionFilterProp="children"
        mode={mode}
        disabled={disabled}
        defaultActiveFirstOption={defaultActiveFirstOption}
        filterOption={filterOption}
      >
        {data && data.map((item, i) => (
          renderOption(item)
        ))}
        {!data && children}
      </Select>
    </FormItem>
  );
};

export default SelectField;
