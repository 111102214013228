import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInterface } from "lib/types/types";

interface UserStateType {
  user: UserInterface | null;
}

const initialState: UserStateType = {
  user: null,
};

export const currentUserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserInterface>) => {
      state.user = action.payload;
    },
    performUserOptimisticUpdate: (state, action: PayloadAction<Partial<UserInterface>>) => {
      if (!state.user) {
        return;
      }

      const data = action.payload;
      for (const [code, value] of Object.entries(data)) {
        state.user[code as keyof UserInterface] = value as never;
      }
    }
  },
});

export const { setUser, performUserOptimisticUpdate } = currentUserSlice.actions;

export default currentUserSlice.reducer;
