import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const NoStrokeSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M49.9999 91.6666C73.0118 91.6666 91.6666 73.0118 91.6666 49.9999C91.6666 26.988 73.0118 8.33325 49.9999 8.33325C26.988 8.33325 8.33325 26.988 8.33325 49.9999C8.33325 73.0118 26.988 91.6666 49.9999 91.6666ZM49.9999 83.3332C68.4094 83.3332 83.3332 68.4094 83.3332 49.9999C83.3332 31.5904 68.4094 16.6666 49.9999 16.6666C31.5904 16.6666 16.6666 31.5904 16.6666 49.9999C16.6666 68.4094 31.5904 83.3332 49.9999 83.3332Z"/>
        <path d="M29.5523 23.673L76.3269 70.4475C74.6017 72.6656 72.6023 74.6599 70.3796 76.3796L23.6203 29.6203C25.3399 27.3976 27.3342 25.3982 29.5523 23.673Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default NoStrokeSvg;
