import { FC } from "react";
import { Form } from "antd";
import { Action, Trigger } from "../../types";
import { AutomationModalBuilder, AutomationsInput } from "../styled";
import Actions from "./Actions/Actions";
import Triggers from "./Triggers/Triggers";
import Scrollbars from "react-custom-scrollbars-2";

interface AutomationsBuilderProps {
  triggerComponent: Trigger;
  actionComponent: Action;
  onClickTrigger: () => void;
  onClickAction: () => void;
}

const AutomationsBuilder: FC<AutomationsBuilderProps> = (props) => {
  const { triggerComponent, actionComponent } = props;

  return (
    <Scrollbars autoHide style={{ display: "flex", flexGrow: 1, width: "auto" }}>
      <AutomationModalBuilder>
        <Form.Item
          name="title"
          rules={[{ required: true }]}
        >
          <AutomationsInput
            name="title"
            placeholder="Add automation title"
            autoFocus
          />
        </Form.Item>
        {Triggers[triggerComponent]}
        <img src={process.env.PUBLIC_URL + "/icons/arrow-illustration.svg"} alt="arrow" />
        {Actions[actionComponent]}
      </AutomationModalBuilder>
    </Scrollbars>
  );
};

export default AutomationsBuilder;
