import styled from "@emotion/styled/macro";
import { basicButton } from "styles/common";

export const ButtonControlsWrapper = styled.div`
  margin: 0 2px;
  font-size: 14px;
`;

export const ButtonControlsButton = styled.button`
  ${basicButton};
  border-color: transparent;
  padding: 0;
`;

export const ButtonControlsContent = styled.div`
  display: flex;
  align-items: center;
  margin: 3px 8px;
  height: 20px;
`;

export const ButtonControlsIcon = styled.div<{hasText?: boolean}>`
  display: flex;
  align-items: center;
  margin-right: ${({ hasText }) => hasText ? "5px" : 0};
`;

export const ButtonControlsText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
