import styled from "@emotion/styled/macro";
import { cardShadow } from "styles/common";

export const ProjectFileContainersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  margin: 12px 0;
  max-width: 1200px;
`;

export const ProjectFileContainersEmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProjectFileContainersCard = styled.div`
  ${cardShadow};
  position: relative;
  padding: 12px 16px;
  height: 150px;
  box-shadow: none;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    box-shadow: none;
  }
`;

export const ProjectFileContainersAdd = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ProjectFileContainersAddIcon = styled.img`
  height: 20px;
  width: 20px;
  margin: 16px 0 12px 0;
`;

export const ProjectFileContainersTitle = styled.div`
  font-size: 13px;
  color: black;
  text-overflow: ellipsis;
`;

export const ProjectFileContainersControls = styled.div`
  margin: 5px 5px 0 0;
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  bottom: auto;
`;
