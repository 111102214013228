import styled from "@emotion/styled/macro";
import { DefaultIconColor } from "components/icons/lib";

interface KanbanCardTitleProps {
  isCompleted: boolean;
}

export const KanbanCardContainer = styled.div`
  width: 240px;
  margin-bottom: 10px;
`;

export const KanbanCardSettings = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 7px;
  opacity: 0;
  background-color: white;
  transition: opacity 200ms ease;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  padding: 4px;
`;

export const KanbanCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  padding: 16px;
  border: solid 1px #e4e4e4;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition: border-color 200ms ease, box-shadow 200ms ease;
  &:hover {
    border: solid 1px #cdcdcd;
    box-shadow: 0 4px 6px 0 rgba(21, 7, 38, 0.06);
    ${KanbanCardSettings} {
      opacity: 1;
    }
  }
`;

export const KanbanCardHeader = styled.div`
  overflow: hidden;
  width: 100%;
`;

export const KanbanCardCheck = styled.div`
  display: inline-block;
  transform: translateY(3px);
`;

export const KanbanCardTitle = styled.div<KanbanCardTitleProps>`
  display: inline;
  white-space: normal;
  word-wrap: break-word;
  width: 100%;
  color: ${({ isCompleted }) => isCompleted ? DefaultIconColor : "default"};
`;

export const KanbanCardFooter = styled.div`
  display: flex;
  align-items: center;
  margin-left: -3px;
  margin-top: 10px;
`;
