import React, { FC, RefObject, useEffect, useRef } from "react";
import { Form } from "antd";

import { KeyboardKeys } from "lib/types/keyCodes";
import { TaskFieldSetterGetter } from "lib/types/applicationTypes";

import { InputStyled } from "components/blocks/TaskTable/components/TaskTitle/styled";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";

const SingleLineTextGeneric: FC<TaskFieldSetterGetter> = (props) => {
  const { code, value, setValue, taskId } = props;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const [form] = Form.useForm();
  const inputRef = useRef(null) as RefObject<any>;
  const fieldName = `${code}_${taskId}`;

  useEffect(() => {
    form.setFieldsValue({ [fieldName]: value });
  }, [fieldName, form, value]);

  const onChange = () => {
    form.validateFields().then(values => {
      if (values[fieldName] === value) {
        return;
      }

      setValue({ cellName: code, cellValue: values[fieldName] });
    });
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyboardKeys.Escape) {
      event.preventDefault();
      inputRef.current.blur();
      form.setFieldsValue({ [fieldName]: value });
    }

    if (event.key === KeyboardKeys.Tab || event.key === KeyboardKeys.Enter) {
      event.preventDefault();
      inputRef.current.blur();
    }
  };

  return (
    <Form form={form} initialValues={{ [fieldName]: value }} style={{ flexGrow: 1 }}>
      <Form.Item name={fieldName}>
        <InputStyled
          ref={inputRef}
          size="small"
          autoComplete="off"
          onKeyDown={onKeyDown}
          onBlur={onChange}
          placeholder="Empty"
          disabled={!allowedToEdit}
        />
      </Form.Item>
    </Form>
  );
};

export default SingleLineTextGeneric;
