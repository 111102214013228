export default class WSSubscriptionFunctionSaver {
  functions: Record<string, Record<string, (data: any) => void>>;

  constructor() {
    this.functions = {};
  }

  saveCallback(eventName: string, fnKey: string, fn: (data: any) => void) {
    if (!this.functions[eventName]) {
      this.functions[eventName] = {};
      this.functions[eventName][fnKey] = fn;
    } else {
      this.functions[eventName][fnKey] = fn;
    }
  }

  removeCallback(eventName: string, fnKey: string) {
    if (this.functions[eventName]) {
      delete this.functions[eventName][fnKey];
    }
  }

  getAllCallbacksByEventName(eventName: string) {
    if (this.functions[eventName]) {
      return Object.values(this.functions[eventName]);
    }
    return [];
  }

  getCallbackByFnKey(eventName: string, fnKey: string) {
    if (this.functions[eventName] && this.functions[eventName][fnKey]) {
      return this.functions[eventName][fnKey];
    }
    return null;
  }
}
