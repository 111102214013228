import { FC } from "react";
import NotFoundSection from "components/blocks/NotFoundSection";

const BoardNotFound: FC = () => {
  const target = "/home";

  return (
    <NotFoundSection
      text="Board not found"
      backLink={target}
      backLinkText="Back home"
    />
  );
};

export default BoardNotFound;
