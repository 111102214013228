import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const DwgSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.4583 20.5167C64.1796 20.5155 61.9946 19.6102 60.3829 17.9992C58.7713 16.3884 57.8646 14.2037 57.8625 11.925V0H19.4583C17.9855 -1e-07 16.5272 0.29014 15.1665 0.853883C13.8059 1.41762 12.5697 2.2439 11.5284 3.28552C10.4872 4.32712 9.66139 5.56371 9.09815 6.92454C8.53492 8.28537 8.2453 9.74387 8.24585 11.2167V78.8042C8.25027 81.775 9.43353 84.6225 11.5358 86.7217C13.6381 88.8208 16.4875 90 19.4583 90H65.5417C67.0146 90 68.4729 89.71 69.8333 89.1462C71.1942 88.5825 72.4304 87.7563 73.4717 86.7146C74.5129 85.6729 75.3388 84.4362 75.9021 83.0754C76.465 81.7146 76.7546 80.2562 76.7542 78.7833V20.5L66.4583 20.5167Z" fill="#005FAD"/>
        <path d="M76.7542 20.5167H66.4583C64.1796 20.5155 61.9946 19.6102 60.3829 17.9993C58.7712 16.3884 57.8646 14.2037 57.8625 11.925V0L76.7542 20.5167Z" fill="#005FAD"/>
        <path d="M59.7 35.9751H22.6166C22.2597 35.9751 21.9174 35.8333 21.665 35.5809C21.4126 35.3285 21.2708 34.9862 21.2708 34.6293C21.2708 34.2723 21.4126 33.93 21.665 33.6776C21.9174 33.4252 22.2597 33.2834 22.6166 33.2834H59.7C59.8766 33.2834 60.0512 33.3183 60.2146 33.386C60.3775 33.4537 60.5254 33.5529 60.65 33.6779C60.775 33.8029 60.8737 33.9513 60.9408 34.1145C61.0079 34.2778 61.0421 34.4527 61.0416 34.6293C61.0416 34.9855 60.9004 35.3272 60.6487 35.5795C60.3975 35.8317 60.0562 35.974 59.7 35.9751Z" fill="#005FAD"/>
        <path d="M84.8334 71.0833H32.7333C29.0376 71.0833 26.0417 74.0791 26.0417 77.7749V93.3083C26.0417 97.0041 29.0376 99.9999 32.7333 99.9999H84.8334C88.5292 99.9999 91.525 97.0041 91.525 93.3083V77.7749C91.525 74.0791 88.5292 71.0833 84.8334 71.0833Z" fill="#005FAD"/>
        <path d="M37.6764 93.75C37.0814 93.75 36.7839 93.4525 36.7839 92.8575V80.235C36.7839 79.64 37.0814 79.3425 37.6764 79.3425H42.7977C44.6464 79.3425 46.0064 79.7817 46.8777 80.66C47.756 81.5313 48.1952 82.8913 48.1952 84.74V88.3525C48.1952 90.2013 47.7596 91.5646 46.8883 92.443C46.0241 93.3142 44.6606 93.75 42.7977 93.75H37.6764ZM38.5689 91.965H42.7977C43.7044 91.965 44.4198 91.855 44.9439 91.6354C45.4752 91.4088 45.8506 91.0334 46.0702 90.5092C46.2969 89.9779 46.4102 89.2592 46.4102 88.3525V84.74C46.4102 83.8405 46.2969 83.1284 46.0702 82.6042C45.8506 82.0729 45.4752 81.6942 44.9439 81.4675C44.4198 81.2409 43.7044 81.1275 42.7977 81.1275H38.5689V91.965Z" fill="white"/>
        <path d="M54.7758 93.75C54.3862 93.75 54.11 93.495 53.9471 92.985L50.0158 80.5325C49.9308 80.2634 49.9696 79.9942 50.1325 79.725C50.3025 79.4559 50.5471 79.3213 50.8658 79.3213C51.0358 79.3213 51.1987 79.378 51.3546 79.4913C51.5104 79.5975 51.6237 79.7675 51.6946 80.0013L55.1583 91.03H54.6908L57.9846 80.1075C58.0625 79.8525 58.1933 79.6613 58.3775 79.5338C58.5687 79.4063 58.7637 79.3425 58.9621 79.3425C59.1746 79.3425 59.38 79.4063 59.5783 79.5338C59.7767 79.6613 59.9112 79.8525 59.9821 80.1075L63.2758 91.03H62.8083L66.2721 80.0013C66.35 79.7675 66.4596 79.6009 66.6012 79.5017C66.75 79.3955 66.9096 79.3425 67.0796 79.3425C67.3912 79.3425 67.6392 79.463 67.8233 79.7038C68.0075 79.9446 68.05 80.2209 67.9508 80.5325L64.0196 92.985C63.8496 93.495 63.5379 93.75 63.0846 93.75H63.0421C62.5675 93.75 62.2558 93.495 62.1071 92.985L58.6858 81.9138H59.1533L55.7108 92.985C55.64 93.24 55.5337 93.4313 55.3921 93.5588C55.2575 93.6863 55.0521 93.75 54.7758 93.75Z" fill="white"/>
        <path d="M74.5483 93.7499C72.9262 93.7499 71.7258 93.3637 70.9467 92.5916C70.1746 91.8124 69.7883 90.612 69.7883 88.9899V84.1024C69.7883 82.4662 70.1746 81.262 70.9467 80.4899C71.7187 79.7108 72.9121 79.3283 74.5271 79.3424H77.0983C78.43 79.3424 79.4467 79.6187 80.1479 80.1712C80.8492 80.7166 81.2562 81.5808 81.3696 82.7637C81.4121 83.0612 81.3554 83.2878 81.1996 83.4437C81.0437 83.5995 80.8171 83.6774 80.5196 83.6774C79.9812 83.6774 79.6696 83.3799 79.5846 82.7849C79.5137 82.1333 79.2871 81.6941 78.9046 81.4674C78.5292 81.2408 77.9271 81.1274 77.0983 81.1274H74.5271C73.7762 81.1203 73.185 81.2053 72.7529 81.3824C72.3279 81.5595 72.0233 81.8674 71.8392 82.3066C71.6621 82.7387 71.5733 83.3374 71.5733 84.1024V88.9899C71.5733 89.7478 71.6621 90.3428 71.8392 90.7749C72.0233 91.207 72.3312 91.5149 72.7633 91.6991C73.1954 91.8762 73.7904 91.9649 74.5483 91.9649H77.0983C77.7571 91.9649 78.2708 91.8941 78.6392 91.7524C79.0146 91.6037 79.2767 91.3449 79.4254 90.9766C79.5742 90.6083 79.6483 90.0878 79.6483 89.4149V88.4162L77.9908 88.3949C77.3958 88.3949 77.0983 88.0974 77.0983 87.5024C77.0983 86.9074 77.3958 86.6099 77.9908 86.6099L80.5408 86.6312C81.1358 86.6312 81.4333 86.9287 81.4333 87.5237V89.4149C81.4333 90.9237 81.0829 92.0249 80.3817 92.7191C79.6804 93.4062 78.5858 93.7499 77.0983 93.7499H74.5483Z" fill="white"/>
        <path d="M50.1292 24.2375V61.3208C50.1296 61.4979 50.0954 61.6729 50.0279 61.8366C49.9604 62 49.8612 62.1487 49.7362 62.2737C49.6112 62.3987 49.4625 62.4979 49.299 62.5654C49.1355 62.6329 48.9602 62.6671 48.7833 62.6666C48.6068 62.6666 48.432 62.6316 48.2689 62.5641C48.1059 62.4962 47.9578 62.3971 47.8332 62.2721C47.7085 62.1471 47.6097 61.9987 47.5426 61.8354C47.4754 61.6721 47.4411 61.4975 47.4417 61.3208V24.2375C47.4417 23.8813 47.5829 23.5396 47.8344 23.2873C48.0859 23.035 48.4271 22.8927 48.7833 22.8916C49.1402 22.8916 49.4826 23.0334 49.735 23.2858C49.9875 23.5382 50.1292 23.8806 50.1292 24.2375Z" fill="#005FAD"/>
        <path d="M51.9167 30.1499H45.8292C45.1388 30.1499 44.5792 30.7095 44.5792 31.3999V37.4874C44.5792 38.1777 45.1388 38.7374 45.8292 38.7374H51.9167C52.6071 38.7374 53.1667 38.1777 53.1667 37.4874V31.3999C53.1667 30.7095 52.6071 30.1499 51.9167 30.1499Z" fill="#005FAD"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default DwgSvg;
