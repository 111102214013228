import { EntityId } from "lib/types/entity";
import { JSendResponse } from "lib/types/jsend";
import { NotificationInterface, NotificationStatus } from "lib/types/notificationTypes";
import HttpService from "./HttpService";

class NotificationService extends HttpService {
  constructor() {
    super("notifications");
  }

  async fetchAll() {
    const data: Array<NotificationInterface> = await this._get({});

    return data || [];
  }

  async getUnreadCount() {
    const data: number = await this._get({
      additionalUrl: "/count",
    });

    return data || 0;
  }

  async setStatus(id: EntityId, status: NotificationStatus) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}/${status}`,
    });

    return data || {};
  }

  async setStatusBatch(filter: Record<string, any>, status: NotificationStatus) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/batch/${status}`,
      body: filter,
    });

    return data || {};
  }
}

export default new NotificationService();
