import { css } from "@emotion/react/macro";
import styled from "@emotion/styled/macro";
import { TaskTitleDragDrop } from "./components/TaskTitle/styled";
import { TaskExpandButton } from "pages/Viewer/ViewerTasks/TaskItem/styled";
import { ViewerViewpointsGradient } from "pages/Viewer/ViewerTasks/ViewerViewpoints/styled";
import { CSSProperties } from "react";

interface TrProps {
  isAddRow?: boolean;
  isDragging?: boolean;
  shadow?: boolean;
}

interface ThProps {
  isNoSelect?: boolean;
}

interface TdProps {
  isSorted?: boolean;
  isFiltered?: boolean;
}

interface WidthProps {
  width?: number;
}

export const tableCommon = css`
  white-space: nowrap;
  margin-left: -1px;
  margin-bottom: -1px;
  border: 1px solid #f2f2f2;
  position: relative;
  transition: border 200ms ease;
  &:hover {
    border: 1px solid #e4e4e4;
    z-index: 10;
    cursor: pointer;
  }
  &:first-of-type {
    border-left: 0;
  }
  &:last-child {
    &:hover {
      border: 1px solid #f2f2f2;
      cursor: auto;
    }
  }
`;

export const dataInput = css`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  padding: 8px 12px;
  resize: none;
  background-color: transparent;
  box-sizing: border-box;
  cursor: auto;
  font-size: 13px;
  overflow: hidden;
  &:focus {
    outline: none;
  }
`;

export const noSelect = css`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const noSelectCSS: CSSProperties = {
  userSelect: "none",
  MozUserSelect: "none",
  msUserSelect: "none",
  WebkitUserSelect: "none",
  WebkitTouchCallout: "none",
};

export const tablePadding = 24;
const tableHeaderHeight = 35;

export const TableHeader = styled.div<WidthProps>`
  position: relative;
  width: ${({ width = 0 }) => width + "px"};
  min-width: 100%;
`;

export const TableContent = styled.div<WidthProps>`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 100%;
  min-height: calc(100% - ${tableHeaderHeight}px);
  padding-bottom: 1px;
`;

export const Th = styled.div<ThProps>`
  ${tableCommon};
  color: #8f9090;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  &:first-of-type {
    padding-left: ${tablePadding - 6}px;
  }
  &:last-child {
    flex-grow: 0.99;
    border-right: 0;
    &:hover {
      border: 1px solid #e4e4e4;
      border-right: 0;
      cursor: pointer;
    }
  }
  ${({ isNoSelect }) => isNoSelect && css`
    ${noSelect};
  `}
`;

export const Td = styled.div<TdProps>`
  ${tableCommon};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0;
  color: #424242;
  cursor: default !important;
  ${({ isSorted }) => isSorted && css`
    background-color: #fcfaff;
  `}
  ${({ isFiltered }) => isFiltered && css`
    background-color: #fffaf7;
  `}
  &:first-of-type {
    border-left: 0;
    padding-left: ${tablePadding}px;
  }
  &:last-child {
    flex-grow: 0.99;
    border-right: 0;
    &:hover {
      border-right: 0;
    }
  }
  & > div:first-of-type {
    height: 100%;
  }
`;

export const Tr = styled.div<TrProps>`
  position: relative;
  display: flex;
  min-width: 100%;
  background-color: white;
  
  &:focus,
  &:hover {
    background-color: #fbfbfb;
    ${TaskTitleDragDrop} {
      background-color: #fbfbfb;
      opacity: 1;
    }
    ${TaskExpandButton} {
      opacity: 1;
    }
    ${ViewerViewpointsGradient} {
      background-image: linear-gradient(to right, transparent, #fbfbfb 65%);
    }
  }
  ${({ isDragging }) => isDragging && css`
    background-color: #fbfbfb;

    ${TaskTitleDragDrop} {
      background-color: #fbfbfb;
      opacity: 1;
    }
  `}
  ${({ isAddRow }) => !isAddRow && css`
    cursor: auto !important;
  `}
  ${({ isAddRow }) => isAddRow && css`
    ${addRow};
  `}
  ${({ shadow }) => shadow && css`
    z-index: 1;
  `}
`;

export const ThContent = styled.div`
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding: 8px;
  display: flex;
  align-items: center;
  min-height: 35px;
`;

export const TaskTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  min-width: 100%;
  height: 100%;
  border-spacing: 0;
  border-top: 1px solid #e0e0e0;
  margin-top: -1px;
`;

export const Resizer = styled.div`
  display: inline-block;
  background: transparent;
  width: 8px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  cursor: col-resize;
  touch-action: none;
  &:hover {
    background-color: #8ecae6;
  }
`;

export const addRow = css`
  color: #9e9e9e;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
  margin-top: 1px;
  padding: 8px 8px 8px ${tablePadding}px;
`;
