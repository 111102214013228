import { FC } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled/macro";
import { TaskFieldSetterGetter } from "lib/types/applicationTypes";
import { LaneHeaderTag } from "pages/Board/BoardKanban/LaneHeader/styled";

const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.75);
  &:hover {
    color: rgba(0, 0, 0, 0.75);
  }
`;

const StyledHeaderTag = styled(LaneHeaderTag)`
  border: solid 1px #e4e4e4;
  transition: background-color 200ms ease;
  &:hover {
    background-color: #f6f6f6;
  }
`;

const LinkGeneric: FC<TaskFieldSetterGetter> = (props) => {
  const { value } = props;

  return (
    <>
      {(value?.href && value?.title) &&
        <StyledHeaderTag bg="transparent">
          <StyledLink to={value.href}>
            {value.title}
          </StyledLink>
        </StyledHeaderTag>
      }
    </>
  );
};

export default LinkGeneric;
