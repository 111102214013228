import styled from "@emotion/styled/macro";
import { CircleDeleteButtonContainer } from "components/blocks/CircleDeleteButton/styled";

export const UploadMultipleRelativeContainer = styled.div`
  position: relative;
  &:hover {
    ${CircleDeleteButtonContainer} {
      opacity: 1;
    }
  }
`;
