import styled from "@emotion/styled/macro";

export const SavingStateIconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #999;
  font-size: 14px;
  margin-left: auto;
  padding-right: 10px;
`;

export const SavingStateIconImage = styled.img`
  margin-top: -1px;
  margin-right: 5px;
`;
