// Reference: https://github.com/signavio/react-mentions#styling

import { css } from "@emotion/css";
import { scrollBar } from "styles/common";

export const reactMentionsSuggestionStyle = (textAreaHeight: number) => ({
  control: {
    backgroundColor: "#fff",
    borderRadius: "2px"
  },

  highlighter: {
    overflow: "hidden"
  },

  input: {
    margin: 0,
    border: "1px solid black",
    overflow: "auto"
  },

  "&multiLine": {
    control: {
      height: "100%"
    },

    highlighter: {
      padding: "4px 11px",
      height: textAreaHeight,
      boxSizing: "border-box",
      overflow: "hidden",
      transition: "all 200ms ease"
    },

    input: {
      padding: "4px 11px",
      height: textAreaHeight,
      overflow: "auto",
      minHeight: 20,
      outline: 0,
      border: 0
    }
  },

  suggestions: {
    borderRadius: "5px",
    overflow: "hidden",
    border: "1px solid #e4e4e4",
    list: {
      maxHeight: "160px",
      overflowY: "scroll",
      overflowX: "hidden",
      backgroundColor: "white",
      borderRadius: "5px",
      boxShadow: "rgb(0 0 0 / 12%) 0 3px 6px -4px, rgb(0 0 0 / 8%) 0px 6px 16px, rgb(0 0 0 / 5%) 0px 9px 28px 8px"
    },
    item: {
      padding: "5px 11px",
      display: "flex",
      alignItems: "center",
      "&focused": {
        backgroundColor: "#f5f5f5"
      }
    }
  }
});

export const reactMentionsMentionStyle = {
  backgroundColor: "#f6f6f6",
  borderRadius: "2px"
};

export const mentionTextareaStyle = css`
  textarea {
    border-radius: 5px;
    transition: all 200ms ease;
    border: 1px solid #d9d9d9 !important;

    :hover {
      border: 1px solid #40a9ff !important;
    }

    :focus {
      border: 1px solid #40a9ff !important;
      box-shadow: 0 0 0 2px rgb(24 144 255 / 20%) !important;
    }
    
    ::placeholder {
      color: #bfbfbf;
    }
  }
`;

export const reactMentionsSuggestionScroll = css`
  ul {
    ${scrollBar};
    overflow-x: hidden;
  }
`;
