import { CommentInterface, ViewpointPayload } from "lib/types/types";
import { TaskExportFormats, TaskInterface, TaskPayloadInterface, TaskQueryPayload, TaskReorderListPayload, TaskRequestImportOptions, TaskSubtaskInterface } from "lib/types/tasksTypes";
import { SortObject } from "lib/types/kanbanTypes";
import HttpService from "./HttpService";
import { JSendResponse } from "lib/types/jsend";
import { constructQueryString } from "lib/helpers/urlUtils";
import { EntityId } from "lib/types/entity";
import { EditorJsSaveFormat } from "components/blocks/EditorJS/lib";

class TasksService extends HttpService {
  constructor() {
    super("tasks");
  }

  async getTasks(board: EntityId) {
    const data: Array<TaskInterface> = await this._get({
      additionalUrl: `/${board}/board`
    });

    return data || [];
  }

  async query(filter: TaskQueryPayload) {
    const data: Array<TaskInterface> = await this._post({
      additionalUrl: "/query",
      body: filter,
    });

    return data || [];
  }

  async getTasksList(board: EntityId) {
    const data: Array<TaskInterface> = await this._get({
      additionalUrl: `/${board}/list`
    });

    return data || [];
  }

  async addNewTask(body: Partial<TaskPayloadInterface>) {
    const data: JSendResponse<TaskInterface> = await this._post({
      additionalUrl: "",
      body
    });

    return data;
  }

  async getDetailTask(id: EntityId) {
    const data: TaskInterface = await this._get({
      additionalUrl: `/${id}`
    });

    return data || null;
  }

  async duplicateTask(id: EntityId, payload?: Partial<TaskPayloadInterface>) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/duplicate`,
      body: payload,
    });

    return data;
  }

  async deleteTask(id: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}`
    });

    return data;
  }

  async modifyTask(id: EntityId, body: Partial<TaskInterface>) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}`,
      body
    });

    return data;
  }

  async modifyDescriptionBlock(id: EntityId, body: Partial<EditorJsSaveFormat>) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}/description`,
      body
    });

    return data;
  }

  async modifyTaskProperty(id: EntityId, body: any) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}/property`,
      body
    });

    return data;
  }

  async reorderKanban(id: EntityId, body: SortObject[]) {
    const data: JSendResponse = await this._patch({
      additionalUrl: `/${id}/kanban/order`,
      body
    });

    return data;
  }

  async reorderList(id: EntityId, body: TaskReorderListPayload) {
    const data: JSendResponse = await this._patch({
      additionalUrl: `/${id}/list/order`,
      body
    });

    return data;
  }

  async addComment(id: EntityId, body: Partial<CommentInterface>) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/comments`,
      body
    });

    return data;
  }

  async editComment(id: EntityId, commentId: EntityId, body: Partial<CommentInterface>) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}/comments/${commentId}`,
      body
    });

    return data;
  }

  async deleteComment(id: EntityId, commentId: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}/comments/${commentId}`,
    });

    return data;
  }

  async reactComment(id: EntityId, commentId: EntityId, reaction: string) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/comments/${commentId}/reaction`,
      body: { reaction }
    });

    return data;
  }

  async unreactComment(id: EntityId, commentId: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}/comments/${commentId}/reaction`,
    });

    return data;
  }

  async hideChange(id: EntityId, changeId: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}/changes/${changeId}`
    });

    return data;
  }

  async createSubtask(id: EntityId, body: Partial<TaskSubtaskInterface>) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/subtasks`,
      body
    });

    return data;
  }

  async createSubtasksBatch(id: EntityId, body: Partial<TaskSubtaskInterface>[]) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/subtasks/batch`,
      body
    });

    return data;
  }

  async updateSubtask(id: EntityId, subtaskId: EntityId, body: Partial<TaskSubtaskInterface>) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}/subtasks/${subtaskId}`,
      body
    });

    return data;
  }

  async deleteSubtask(id: EntityId, subtaskId: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${id}/subtasks/${subtaskId}`,
    });

    return data;
  }

  async reorderSubtasks(id: EntityId, body: Partial<TaskSubtaskInterface>[]) {
    const data: JSendResponse = await this._patch({
      additionalUrl: `/${id}/subtasks/order`,
      body
    });

    return data;
  }

  async subscribeCollaborator(id: EntityId) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/collaborators/subscribe`,
    });

    return data;
  }

  async unsubscribeCollaborator(id: EntityId) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/collaborators/unsubscribe`,
    });

    return data;
  }

  async export(id: EntityId, format: TaskExportFormats | string) {
    const data: JSendResponse | Buffer = await this._post({
      additionalUrl: `/${id}/export/${format}`
    });

    return data || {};
  }

  async importBatch(boardId: EntityId, file: File, options: TaskRequestImportOptions = {}, signal?: AbortSignal) {
    const formData = new FormData();
    formData.append("file", file);

    const data: JSendResponse = await this._postFormData({
      body: formData,
      additionalUrl: `/${boardId}/board/import?${constructQueryString(options)}`,
      signal,
    });

    return data;
  }

  // TODO: support fields later
  async getTasksWorkspace(workspaceId: EntityId, fields?: Array<keyof TaskInterface>) {
    const data: Array<TaskInterface> = await this._get({
      additionalUrl: `/${workspaceId}/model`,
    });

    return data || [];
  }

  async getMyTasks() {
    const data: Array<TaskInterface> = await this._get({
      additionalUrl: "/view/my"
    });

    return data || [];
  }

  async createViewpoint(id: EntityId, payload: ViewpointPayload, signal?: AbortSignal) {
    const body = {
      uid: payload.uid,
      viewpoint: JSON.stringify(payload.viewpoint),
      picture: payload.picture || null,
    };

    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/viewpoints`,
      body: body,
      signal,
    });

    return data;
  }

  async updateViewpoint(id: EntityId, viewpointId: EntityId, payload: Partial<ViewpointPayload>) {
    const body = {
      ...(payload.viewpoint && { viewpoint: JSON.stringify(payload.viewpoint) }),
      ...(payload.picture && { picture: payload.picture }),
    };

    const data: JSendResponse = await this._put({
      additionalUrl: `/${id}/viewpoints/${viewpointId}`,
      body: body,
    });

    return data;
  }

  async addViewpointsBatch(id: EntityId, viewpoints: Array<ViewpointPayload>) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${id}/viewpoints/batch`,
      body: viewpoints,
    });

    return data;
  }

  async deleteViewpoint(taskId: EntityId, viewpointId: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${taskId}/viewpoints/${viewpointId}`
    });

    return data;
  }

  async addAnnotation(taskId: EntityId, annotationPayload: any) {
    const data: JSendResponse = await this._post({
      additionalUrl: `/${taskId}/annotations`,
      body: annotationPayload
    });

    return data;
  }

  async deleteAnnotation(taskId: EntityId, annotationId: EntityId) {
    const data: JSendResponse = await this._delete({
      additionalUrl: `/${taskId}/annotations/${annotationId}`
    });

    return data;
  }

}

export default new TasksService();
