import { FC, useState } from "react";
import { Menu, Typography, Image } from "antd";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles, ViewpointPayload, WorkspaceInterface } from "lib/types/types";
import MenuItemIcon from "components/ui/MenuItemIcon";
import TrashSvg from "components/icons/resizable/trash";
import UrlSvg from "components/icons/resizable/url";
import ExpandSvg from "components/icons/resizable/expand";
import DownloadSvg from "components/icons/resizable/download";
import { TaskExportFormats, TaskRequestExportOptions, ViewpointType } from "lib/types/tasksTypes";
import { copyLink, getTaskDetailModelUri } from "lib/helpers/navigationUtils";
import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import useWorkspaceById from "lib/customHooks/useWorkspaceById";
import useTaskSetViewpoints from "lib/customHooks/dataLayer/task/useTaskSetViewpoints";
import { extractQueryParams, setQueryParams, unsetQueryParams } from "lib/helpers/urlUtils";
import { useHistory } from "react-router-dom";
import configuration from "lib/configs/configuration";
import { getDownloadFilename } from "lib/helpers/stringUtils";
import { fetchDownload } from "lib/helpers/fetchDownload";
import ModelSvg from "components/icons/resizable/model";
import { ViewpointContextMenuItems } from "./lib";
import { useViewerContext } from "lib/contexts/ViewerContext";
import { asyncGetScreenShot, saveViewerState } from "lib/helpers/viewerUtils";
import RefreshSvg from "components/icons/resizable/refresh";

interface ViewpointContextMenuProps {
  menuItems: Array<ViewpointContextMenuItems>;
  viewpoint: ViewpointType;
  toggleVisibility: () => void;
}

const ViewpointCardMenuItems: FC<ViewpointContextMenuProps> = (props) => {
  const { viewpoint, toggleVisibility, menuItems } = props;
  const history = useHistory();

  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const { viewer } = useViewerContext();
  const { task } = useTaskContext() as TaskContextInterface;
  const { updateViewpoint, deleteViewpoint } = useTaskSetViewpoints({ task, updateDetailed: true });
  const { v: viewpointId } = extractQueryParams();
  const sourceWorkspace = useWorkspaceById(task?.board.workspace) as WorkspaceInterface;

  const isOwner = useMemberPermission([MemberRoles.Owner]);
  const isAdmin = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const isEditor = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const detailedUrl = getTaskDetailModelUri(sourceWorkspace?.uid, task?.board?.uid, task?.uid, { v: viewpoint?.uid });

  const handleViewpointPreview: MenuClickEventHandler = (info) => {
    info.domEvent.stopPropagation();
    setIsPreviewVisible(!isPreviewVisible);
  };

  const handleViewpointNavigate: MenuClickEventHandler = (info) => {
    info.domEvent.stopPropagation();
    window.open(detailedUrl, "_blank");
  };

  const handleViewpointCopyLink: MenuClickEventHandler = (info) => {
    copyLink(detailedUrl);
  };

  const handleViewpointDownload: MenuClickEventHandler = async (info) => {
    const selectedFormat = TaskExportFormats.Viewpoints;
    const path = `${configuration.backend.url}tasks/${task.id}/export/${selectedFormat}`;
    const fileName = getDownloadFilename(task.title, selectedFormat);
    const options: TaskRequestExportOptions = {
      filterBy: {
        code: "id",
        value: viewpoint.id
      }
    };
    await fetchDownload(path, fileName, options);
  };

  const handleViewpointUpdate: MenuClickEventHandler = async (info) => {
    info.domEvent.stopPropagation();

    if (!viewer) {
      return;
    }

    const rawViewpoint = saveViewerState(viewer);
    const blob = await asyncGetScreenShot(viewer);

    if (!blob || !rawViewpoint) {
      return;
    }

    setQueryParams(history, { v: viewpoint.uid });

    const payload: Partial<ViewpointPayload> = {
      viewpoint: rawViewpoint,
      picture: null,
      isLoading: true,
    };

    updateViewpoint(viewpoint.id, payload, blob);
  };

  const handleViewpointDelete: MenuClickEventHandler = (info) => {
    info.domEvent.stopPropagation();

    if (viewpoint) {
      deleteViewpoint(viewpoint.id);
    }
    if (viewpoint.uid === viewpointId) {
      unsetQueryParams(history);
    }
  };

  return (
    <>
      <Menu onClick={toggleVisibility}>
        {/*Preview*/}
        {menuItems.includes(ViewpointContextMenuItems.OpenImagePreview) &&
          <Menu.Item key="preview" onClick={handleViewpointPreview} disabled={!viewpoint?.picture}>
            <MenuItemIcon text="Preview image" disabled={!viewpoint?.picture} icon={<ExpandSvg size={12} />} />
          </Menu.Item>
        }

        {/* Open in viewer */}
        {menuItems.includes(ViewpointContextMenuItems.OpenInViewer) &&
          <Menu.Item key="navigate" onClick={handleViewpointNavigate} >
            <MenuItemIcon text="Open in viewer" icon={<ModelSvg size={12} />} />
          </Menu.Item>
        }

        {/* Update from view */}
        {menuItems.includes(ViewpointContextMenuItems.UpdateFromView) && isEditor &&
          <Menu.Item key="update" onClick={handleViewpointUpdate} >
            <MenuItemIcon text="Update from view" icon={<RefreshSvg size={12} />} />
          </Menu.Item>
        }

        {/*Copy link*/}
        {menuItems.includes(ViewpointContextMenuItems.CopyLink) &&
          <Menu.Item key="copyLink" onClick={handleViewpointCopyLink}>
            <MenuItemIcon text="Copy viewpoint link" icon={<UrlSvg size={12} />} />
          </Menu.Item>
        }

        {/*Download*/}
        {menuItems.includes(ViewpointContextMenuItems.DownloadXml) && isAdmin &&
          <Menu.Item key="download" onClick={handleViewpointDownload}>
            <MenuItemIcon text="Download XML" icon={<DownloadSvg size={12} />} />
          </Menu.Item>
        }

        {/*Delete*/}
        {menuItems.includes(ViewpointContextMenuItems.Delete) && isOwner &&
          <>
            <Menu.Divider />
            <Menu.Item key="delete" onClick={handleViewpointDelete}>
              <Typography.Text>
                <MenuItemIcon text="Delete viewpoint" icon={<TrashSvg size={12} />} red />
              </Typography.Text>
            </Menu.Item>
          </>
        }
      </Menu>

      {menuItems.includes(ViewpointContextMenuItems.OpenImagePreview) &&
        <Image
          key={viewpoint.uid}
          src={viewpoint?.picture?.url}
          style={{ display: "none" }}
          preview={{
            visible: isPreviewVisible,
            src: viewpoint?.picture?.url,
            destroyOnClose: true,
            onVisibleChange: (visible) => setIsPreviewVisible(visible),
          }}
        />
      }
    </>
  );
};

export default ViewpointCardMenuItems;
