import { Form, Select, Collapse, Empty } from "antd";
import { FormInstance } from "antd/lib/form";
import { AddTaskRow } from "components/blocks/AddTaskModal/TasksForm/styled";
import { TaskFormProvider } from "lib/contexts/TaskFormContext";
import { CollapseWrapper } from "pages/Board/BoardKanban/Card/CardDetails/styled";
import FormPublicField from "pages/FormPublic/FormPublicField";
import { FC, useEffect, useState } from "react";
import { TaskCreateFlowTab } from "../TaskModalCreateAnnotation/lib";
import { TaskModalFlowTabs } from "../TaskModalCreateAnnotation/styled";
import { BoardInterface, BoardViewInterface, DefaultColumnCodes } from "lib/types/boardTypes";
import { FormResponseInterface } from "lib/types/form";
import { UserInterface, WorkspaceInterface } from "lib/types/types";
import { enrichBoardEntity } from "lib/helpers/boardUtils";
import { DefaultOptionType } from "antd/lib/select";
import BoardLabel from "components/blocks/BoardLabel";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import { SystemPropertyCode } from "lib/types/dataTypes";
import useUserPreferences from "lib/customHooks/useUserPreferences";
import { EntityId } from "lib/types/entity";
import { Column } from "lib/types/tasksTypes";
import { CreateFlowFormCustomization, CreateFlowFormSource } from "./lib";

interface CreateFlowFormProps {
  form: FormInstance;
  workspace: WorkspaceInterface | null | undefined;
  initialBoard?: EntityId | null;
  selectedBoard: EntityId | null;
  onChangeBoard: (id: EntityId | null) => void;
  type: CreateFlowFormSource;
  disableBoard?: (board: BoardInterface) => boolean;
  customizations?: Array<CreateFlowFormCustomization> | null;
}

const CreateFlowForm: FC<CreateFlowFormProps> = (props) => {
  const { form, workspace, selectedBoard, onChangeBoard, type,
    disableBoard = (board) => false,
    initialBoard = null,
    customizations,
  } = props;
  const { createFlow: { tab }, savePreferences } = useUserPreferences();

  const [selectedTab, setSelectedTab] = useState(tab);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);

  useEffect(() => {
    form.resetFields();
    onChangeBoard(initialBoard);

    if (selectedTab === "from-template") {
      setSelectedTemplate(null);
    }
  }, [selectedTab]);

  const boards = (workspace?.boards || []).filter(board => {
    if (type === "board") {
      return String(board.id) === String(initialBoard);
    } else {
      return true;
    }
  });

  const boardsWithTemplates: DefaultOptionType[] = boards
    .filter(board => board.recordTemplates.length > 0)
    .map(board => {
      const options: DefaultOptionType[] = board.recordTemplates.map(template => ({
        value: String(template.id),
        label: template.title,
        template: template,
        board: board,
      }));

      return {
        label: <BoardLabel board={board} />,
        options: options,
      };
    });

  const templatesCount = boards.reduce((total, board) => total + board.recordTemplates.length, 0);
  const users = (workspace?.collaborators || []) as UserInterface[];
  const selected = boards.find(board => board.id === selectedBoard);

  const { excludedColumns, insertColumns } = useBoardFeatures(selected as BoardInterface);
  const ignoredColumns = [
    DefaultColumnCodes.Completion,
    DefaultColumnCodes.CreatedBy,
    DefaultColumnCodes.CreatedTime,
    DefaultColumnCodes.LastModified,
    DefaultColumnCodes.LastModifiedBy,
    DefaultColumnCodes.ClosingDate,
    DefaultColumnCodes.Collaborators,
    DefaultColumnCodes.Viewpoints,
    DefaultColumnCodes.Annotations,
    ...Object.values(SystemPropertyCode),
  ];

  const boardOptions: Array<DefaultOptionType> = boards.map(board => ({
    label: <BoardLabel board={board} />,
    text: board.name,
    value: board.id,
    disabled: disableBoard(board),
  }));

  const currentBoard = enrichBoardEntity(selected, users);

  const fields = Array.from(currentBoard?.columns || [])
    .concat(insertColumns)
    .sort((prev, next) => prev.sort - next.sort)
    .filter(column => ![...excludedColumns, ...ignoredColumns].includes(column.code));

  const visibleFields = fields.filter(field => !field.hidden);
  const hiddenFields = fields.filter(field => field.hidden);

  const data: FormResponseInterface = {
    board: currentBoard as BoardInterface,
    view: {} as BoardViewInterface,
    columns: (currentBoard?.columns ?? []) as Column[],
    members: users as UserInterface[],
  };

  const handleOnSelectTemplate = (value: string | null, option: DefaultOptionType) => {
    const { board, template } = option;
    form.resetFields();

    setSelectedTemplate(value);
    onChangeBoard(board.id);

    form.setFieldsValue(template.template);
  };

  const handleOnSetTab = (tab: TaskCreateFlowTab) => {
    setSelectedTab(tab);
    savePreferences({
      fileContainer: {
        createFlow: {
          tab: tab,
        }
      }
    });
  };

  return (
    <TaskFormProvider form={data}>
      <Form.Provider>
        <Form
          form={form}
          layout="vertical"
          preserve={false}
          requiredMark={false}
        >
          <TaskModalFlowTabs
            id="ant-tab-content"
            activeKey={selectedTab}
            onChange={key => handleOnSetTab(key as TaskCreateFlowTab)}
            items={[
              {
                label: "From scratch",
                key: "from-scratch" as TaskCreateFlowTab,
                children: null,
              },
              {
                label: "Use template",
                key: "from-template" as TaskCreateFlowTab,
                children: (
                  <>
                    {templatesCount > 0
                      ?
                      <AddTaskRow>
                        <Select
                          showSearch
                          value={selectedTemplate}
                          options={boardsWithTemplates}
                          placeholder="Choose template..."
                          onChange={(value, option: any) => handleOnSelectTemplate(value, option)}
                          allowClear={false}
                          style={{ width: "100%" }}
                          filterOption={(input, option) =>
                            String(option?.label)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        />
                      </AddTaskRow>
                      : <Empty description="No templates" image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginBottom: 14 }} />
                    }
                  </>
                ),
              },
            ]}
          />

          {selectedTab === "from-scratch" &&
            <AddTaskRow>
              <Select
                disabled={!!initialBoard}
                showSearch
                value={selectedBoard}
                options={boardOptions}
                placeholder="Select board..."
                onChange={onChangeBoard}
                allowClear
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  String(option?.text)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </AddTaskRow>
          }
          {((selectedTab === "from-scratch" && selectedBoard) || (selectedTab === "from-template" && selectedTemplate)) &&
            <>
              {/* Visible fields */}
              {visibleFields.map(column => {
                const modifier = customizations?.find(item => item.code === column.code);
                return (
                  <FormPublicField
                    key={column.id}
                    column={column}
                    customization={modifier?.component}
                  />
                );
              })}

              {/* Hidden fields */}
              {(hiddenFields.length > 0) &&
                <CollapseWrapper>
                  <Collapse ghost>
                    <Collapse.Panel header="Hidden fields" key="form-hidden-fields">
                      {hiddenFields.map(column => {
                        const modifier = customizations?.find(item => item.code === column.code);
                        return (
                          <FormPublicField
                            key={column.id}
                            column={column}
                            customization={modifier?.component}
                          />
                        );
                      })}
                    </Collapse.Panel>
                  </Collapse>
                </CollapseWrapper>
              }
            </>
          }
        </Form>
      </Form.Provider>
    </TaskFormProvider>
  );
};

export default CreateFlowForm;
