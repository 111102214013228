import configuration from "lib/configs/configuration";

function keepOnPage(e: BeforeUnloadEvent) {
  const message = "Are you sure you want to leave?";
  e.returnValue = message;
  return message;
}

export const promptWindowUnsavedWarning = (isDirty: boolean) => {
  if (configuration.app.isDevelopment) {
    console.log("[Window] promptWindowUnsavedWarning:", isDirty);
  }

  if (isDirty) {
    window.addEventListener("beforeunload", keepOnPage);
  } else {
    window.removeEventListener("beforeunload", keepOnPage);
  }
};
