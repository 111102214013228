import { FC, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { Badge, Drawer, Dropdown, Empty, Menu, Tabs } from "antd";
import { orderBy } from "lodash";
import { useAppSelector } from "app/store";
import ButtonIcon from "components/ui/ButtonIcon";
import useGetNotifications from "lib/customHooks/useGetNotifications";
import useWSCreateNotification from "lib/customHooks/websocketNotification/useWSCreateNotification";
import useWSDeleteNotification from "lib/customHooks/websocketNotification/useWSDeleteNotification";
import useWSUpdateNotification from "lib/customHooks/websocketNotification/useWSUpdateNotification";
import { NotificationStatus } from "lib/types/notificationTypes";
import NotificationCard from "pages/Notifications/NotificationCard";
import {
  NotificationsDrawerSettings,
  NotificationsDrawerTabs,
  NotificationsDrawerWrapper,
  virtuosoCustomClass
} from "pages/Notifications/NotificationsDrawer/styled";
import { groupNotifications } from "../lib";
import NotificationGroup from "../NotificationGroup";
import NotificationService from "services/NotificationService";
import CustomScrollbar from "components/blocks/CustomScrollbar";

const NotificationsDrawer: FC = () => {

  useGetNotifications();
  useWSCreateNotification();
  useWSUpdateNotification();
  useWSDeleteNotification();

  const [visible, setVisible] = useState(false);

  const count = useAppSelector(state => state.notifications.count);
  let notifications = useAppSelector(state => state.notifications.notifications);
  notifications = orderBy(notifications, ["created_at"], ["desc"]);

  const notificationsInbox = groupNotifications(notifications.filter(notification => notification.status !== NotificationStatus.Archived));
  const notificationsArchived = notifications.filter(notification => notification.status === NotificationStatus.Archived);

  const countNew = notifications.filter(notification => notification.status === NotificationStatus.New).length;
  const countInbox = notifications.filter(notification => notification.status !== NotificationStatus.Archived).length;

  const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);

  const setStatusBatch = async (status: NotificationStatus) => {
    const ids = notifications
      .filter(notification => notification.status !== NotificationStatus.Archived)
      .map(notification => notification.id);

    return await NotificationService.setStatusBatch({ "id_in": ids }, status);
  };

  const notificationsKeys = Object.keys(notificationsInbox);

  const virtualNotifications = (i: number) => {
    const notificationKey = notificationsKeys[i];

    return (
      <NotificationGroup
        key={notificationKey}
        groupCode={notificationKey}
        notifications={notificationsInbox[notificationKey]}
        closeDrawerFn={hideDrawer}
      />
    );
  };

  const virtualArchivedNotifications = (i: number) => {
    const notification = notificationsArchived[i];

    return (
      <NotificationCard key={notification.id} notification={notification} closeDrawerFn={hideDrawer} />
    );
  };

  const virtualListHeight = window.innerHeight - 90;

  const menuItems = [
    {
      title: "Read all",
      status: NotificationStatus.Read,
      hidden: countNew === 0,
    },
    {
      title: "Archive all",
      status: NotificationStatus.Archived,
      hidden: countInbox === 0,
    },
  ];

  return (
    <NotificationsDrawerWrapper>
      <Badge count={count} size="small" style={{ margin: "4px 4px 0 0" }}>
        <ButtonIcon onClick={showDrawer} iconUrl="/icons/bell.svg" iconOutlined />
      </Badge>
      <Drawer
        placement="right"
        closable={false}
        onClose={hideDrawer}
        open={visible}
        width={500}
        bodyStyle={{ padding: 0, overflow: "hidden" }}
      >
        <NotificationsDrawerTabs>
          <Dropdown
            placement="bottomLeft"
            trigger={["click"]}
            overlay={
              <Menu>
                {menuItems.map(item => (
                  <Menu.Item
                    key={item.status}
                    onClick={() => setStatusBatch(item.status)}
                    disabled={item.hidden}
                  >
                    {item.title}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <NotificationsDrawerSettings>
              <ButtonIcon iconUrl="/icons/more.svg" />
            </NotificationsDrawerSettings>
          </Dropdown>
          <Tabs defaultActiveKey="notificationsInbox">
            <Tabs.TabPane tab="Inbox" key="notificationsInbox">
              <Virtuoso
                style={{ height: virtualListHeight, minHeight: "100%", width: "100%" }}
                totalCount={notificationsKeys.length}
                itemContent={virtualNotifications}
                className={virtuosoCustomClass}
                components={{
                  Scroller: CustomScrollbar,
                  Footer: () => (<div style={{ height: 15 }} />),
                  EmptyPlaceholder: () => <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
                }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Archived" key="notificationsArchived">
              <Virtuoso
                style={{ height: virtualListHeight, minHeight: "100%", width: "100%" }}
                totalCount={notificationsArchived.length}
                itemContent={virtualArchivedNotifications}
                className={virtuosoCustomClass}
                components={{
                  Scroller: CustomScrollbar,
                  Footer: () => (<div style={{ height: 15 }} />),
                  EmptyPlaceholder: () => <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
                }}
              />
            </Tabs.TabPane>
          </Tabs>
        </NotificationsDrawerTabs>
      </Drawer>
    </NotificationsDrawerWrapper>
  );
};

export default NotificationsDrawer;
