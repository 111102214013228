import styled from "@emotion/styled/macro";

export const DuplicateTaskModalContainer = styled.div`
  display: flex;
  align-items: center;
  padding-inline: 3px;
  .ant-form-item-control-input {
    display: flex;
    align-items: center;
  }
`;
