import { FC, useCallback, useEffect, useState } from "react";
import { Modal, Select, Typography } from "antd";
import { getUserFullName } from "lib/helpers/userUtils";
import useAsyncData from "lib/customHooks/useAsyncData";
import { MemberRoles, WorkspaceMemberInterface } from "lib/types/types";
import WorkspaceService from "services/WorkspaceService";
import { TwoColumnGrid } from "styles/common";

import { UserRightsWrapper, UserRightsSpaceBetween, UserRightsRemoveButton, UserRightsPending, UserRightsResend } from "./styled";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRights } from "./lib";
import { useProjectContext } from "lib/contexts/ProjectContext";
import { useMemberPermissionContext } from "lib/contexts/MemberPermissionContext";
import { okButtonProps, cancelButtonProps } from "styles/common-vars";
import { EntityId } from "lib/types/entity";
import SingleMember from "components/ui/SingleUser";

const { Link } = Typography;
const { Option } = Select;

interface UserRightsProps {
  workspaceId: EntityId;
  member: WorkspaceMemberInterface;
}

const UserRights: FC<UserRightsProps> = (props) => {
  const { member, workspaceId } = props;
  const { user } = member;

  const { workspace } = useProjectContext();
  const [isInvitationResent, setIsInvitationResent] = useState(false);
  const [role, setRole] = useState(member.role);

  useEffect(() => {
    setRole(member.role);
  }, [member.role]);

  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const currentUserRole = useMemberPermissionContext().role;

  const owners = workspace?.members.filter(m => m.role === MemberRoles.Owner) || [];
  const isOwnerDisabled = (owners?.length < 2) && (member.role === MemberRoles.Owner);

  const isTargetOwner = (currentUserRole === MemberRoles.Admin) && (role === MemberRoles.Owner);

  const isRoleOptionDisabled = (code: MemberRoles) => {
    switch (currentUserRole) {
      case MemberRoles.Owner:
        return false;

      case MemberRoles.Admin:
        return ![MemberRoles.Admin, MemberRoles.Commenter, MemberRoles.Editor, MemberRoles.Viewer].includes(code);

      default:
        return true;
    }
  };

  const resendInvitation = () => {
    if (allowedToEdit) {
      WorkspaceService.membersResendInvite(workspaceId, user.id);
      setIsInvitationResent(true);
    }
  };

  const removeCollaborator = useCallback(() => {
    return WorkspaceService.membersDelete(workspaceId, user.id);
  }, [user.id, workspaceId]);

  const { loadData } = useAsyncData({
    fetchFn: removeCollaborator
  });

  const onDelete = () => {
    Modal.confirm({
      title: `Remove ${getUserFullName(user)}?`,
      content: "This will remove user from the project. This action is irreversible.",
      okButtonProps: okButtonProps,
      cancelButtonProps: cancelButtonProps,
      okText: "Remove",
      async onOk() {
        await loadData().finally();
      }
    });
  };

  const handleRoleOnChange = (value: MemberRoles) => {
    Modal.confirm({
      title: `Change role for ${getUserFullName(user)}?`,
      content: "You are about to change user's role. This action may be irreversible.",
      okText: "Confirm",
      okButtonProps: okButtonProps,
      cancelButtonProps: cancelButtonProps,
      async onOk() {
        setRole(value);
        await WorkspaceService.membersSetRole(workspaceId, user.id, value);
      }
    });
  };

  return (
    <UserRightsWrapper allowedToEdit={allowedToEdit}>
      <TwoColumnGrid>
        <SingleMember user={user} type="full" fullWidth />
        <UserRightsSpaceBetween>
          <Select
            size="small"
            value={role}
            dropdownStyle={{ minWidth: 110 }}
            disabled={!allowedToEdit || isOwnerDisabled || isTargetOwner}
            onChange={handleRoleOnChange}
            bordered={false}
          >
            {MemberRights.map(permission => (
              <Option
                value={permission.code}
                key={permission.code}
                disabled={isRoleOptionDisabled(permission.code)}
              >
                {permission.value}
              </Option>
            ))}
          </Select>
          <div style={{ display: "flex", alignItems: "center" }}>
            {!user.confirmed &&
              <>
                <UserRightsPending>Pending invite</UserRightsPending>
                {allowedToEdit &&
                  <UserRightsResend>
                    {(!isInvitationResent)
                      ? <Link onClick={resendInvitation}>Resend invite</Link>
                      : <span style={{ color: "#00bf9c" }}>Invite sent</span>
                    }
                  </UserRightsResend>
                }
              </>
            }
            {(allowedToEdit && !isOwnerDisabled) &&
              <UserRightsRemoveButton onClick={onDelete} >
                <img alt="" src={process.env.PUBLIC_URL + "/icons/x.svg"} />
              </UserRightsRemoveButton>
            }
          </div>
        </UserRightsSpaceBetween>
      </TwoColumnGrid>
    </UserRightsWrapper>
  );
};

export default UserRights;
