import styled from "@emotion/styled/macro";

export const TaskChangeBody = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #6f7782;
  margin: 4px 0 4px 50px;
`;

export const TaskChangeUserName = styled.div`
  max-width: 70px;
  font-weight: 600;
  margin-right: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 0 0 auto;
`;

export const TaskChangeWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-right: 5px;
  justify-content: space-between;
`;

export const TaskChangeContent = styled.div`
  display: flex;
`;

export const TaskChangeRenderMessage = styled.div`
  display: flex;
  flex-grow: 0;
  margin-right: 4px;
`;

export const TaskChangeSelect = styled.div`
  display: inline;
  padding: 0 5px;
  margin: 0 2px;
  border-radius: 10px;
  background-color: #f6f6f6;
`;

export const TaskChangeText = styled.div`
  display: inline;
`;

export const TaskChangeDatetime = styled.div`
  font-size: 12px;
  color: #6f7782;
  flex-shrink: 0;
`;

export const TaskChangeOldValueWrapper = styled.span`
  ${TaskChangeSelect} {
    text-decoration: line-through;
    opacity: 0.65;
    background-color: transparent !important;
    padding: 0 2px;
    margin: 0;
  };
  ${TaskChangeText} {
    text-decoration: line-through;
    opacity: 0.65;
  }
`;
