enum KeyCodes {
  Escape = 27,
  Tab = 9,
  Enter = 13,
}

export enum KeyboardKeys {
  Escape = "Escape",
  Tab = "Tab",
  Enter = "Enter",
}

export default KeyCodes;
