import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";

export const CustomSelectsEmpty = styled.div`
  color: #bfbfbf;
  font-size: 13px;
`;

export const customSelectsWrapper = css`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  
  .ant-select-single, .ant-select-multiple {
    position: absolute;
    top: 100%;
    left: 0;
    height: 0;
    max-height: 0;
    visibility: hidden;
  }
  
  .ant-select-selector {
    height: 0 !important;
    width: 0 !important;
  }
  
  > div:first-of-type {
    display: flex;
  }
`;

export const customSelectsSearch = css`
  border-bottom: 1px solid #f0f0f0;
  margin-top: -6px;
  padding: 4px 2px 2px 2px;
  background-color: #f6f6f6;
`;

export const customSelectsGroup = css`
  .ant-select-item-group {
    font-weight: 600;
  }
  .ant-select-item-option-grouped {
    padding-left: 12px;
  }
`;
