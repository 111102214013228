import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { KanbanCardSettings } from "pages/Board/BoardKanban/Card/styled";

export const RecordTemplateMoreContainer = styled(KanbanCardSettings)`
  position: absolute;
  top: auto;
  bottom: auto;
  margin: 0 8px 0 0;
`;

const RecordTemplateCssCard = css`
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 16px;
  border: solid 1px #e4e4e4;
  border-radius: 6px;
  transition: border-color 200ms ease;
  background-color: white;
  user-select: none;
  &:hover {
    border: solid 1px #cdcdcd;
    ${RecordTemplateMoreContainer} {
      opacity: 100%;
    }
  }
`;

export const RecordTemplateItemCard = styled.div`
  ${RecordTemplateCssCard};
  padding: 8px 8px 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
  transition: all 200ms ease;
`;

export const RecordTemplateItemContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RecordTemplateItemTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RecordTemplateItemDate = styled.div`
  font-size: 13px;
  color: #999;
`;

export default RecordTemplateCssCard;
