import { FC } from "react";

interface ForgeViewerLinkProps {
  to: string;
  className?: string;
}

const ForgeViewerLink: FC<ForgeViewerLinkProps> = (props) => {

  const handleOnClick = () => {
    window.location.replace(props.to);
  };

  return (
    <div
      onClick={handleOnClick}
      className={props.className}
      style={{ cursor: "pointer" }}
    >
      {props.children}
    </div>
  );
};

export default ForgeViewerLink;
