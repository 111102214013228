import { FC } from "react";
import IconSvgWrapper from "./IconSvgWrapper";
import { IconProps } from "./lib";

const InviteSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M4.20005 1.20007C2.87456 1.20007 1.80005 2.27459 1.80005 3.60007C1.80005 4.92553 2.87456 6.00007 4.20005 6.00007C5.52551 6.00007 6.60005 4.92553 6.60005 3.60007C6.60005 2.27459 5.52551 1.20007 4.20005 1.20007Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.57166 6.60006C5.56445 6.6 5.55723 6.59998 5.55 6.59998H2.85C1.27599 6.59998 0 7.87594 0 9.44998C0 10.1955 0.604416 10.8 1.35 10.8H7.05C7.08851 10.8 7.12664 10.7984 7.16433 10.7952C7.05861 10.5515 7 10.2826 7 10C5.89543 10 5 9.10457 5 8C5 7.45494 5.21804 6.9608 5.57166 6.60006Z"/>
      <path d="M6 8C6 7.50294 6.40294 7.1 6.9 7.1H11.1C11.5971 7.1 12 7.50294 12 8C12 8.49706 11.5971 8.9 11.1 8.9H6.9C6.40294 8.9 6 8.49706 6 8Z"/>
      <path d="M9 11C8.50294 11 8.1 10.5971 8.1 10.1V5.9C8.1 5.40294 8.50294 5 9 5C9.49706 5 9.9 5.40294 9.9 5.9V10.1C9.9 10.5971 9.49706 11 9 11Z"/>
    </IconSvgWrapper>
  );
};

export default InviteSvg;
