import { FC } from "react";
import AiSvg from "./ai";
import AviSvg from "./avi";
import BmpSvg from "./bmp";
import CdrSvg from "./cdr";
import CsvSvg from "./csv";
import DllSvg from "./dll";
import DocSvg from "./doc";
import DocxSvg from "./docx";
import DwgSvg from "./dwg";
import EpsSvg from "./eps";
import ExeSvg from "./exe";
import FlvSvg from "./flv";
import GifSvg from "./gif";
import HtmlSvg from "./html";
import IsoSvg from "./iso";
import JpgSvg from "./jpg";
import JsSvg from "./js";
import MdbSvg from "./mdb";
import MidSvg from "./mid";
import MovSvg from "./mov";
import Mp3Svg from "./mp3";
import Mp4Svg from "./mp4";
import MpegSvg from "./mpeg";
import PdfSvg from "./pdf";
import PngSvg from "./png";
import PptSvg from "./ppt";
import PsdSvg from "./psd";
import PubSvg from "./pub";
import RarSvg from "./rar";
import RawSvg from "./raw";
import RssSvg from "./rss";
import SvgSvg from "./svg";
import TiffSvg from "./tiff";
import TxtSvg from "./txt";
import WavSvg from "./wav";
import WmaSvg from "./wma";
import XlsSvg from "./xls";
import XmlSvg from "./xml";
import ZipSvg from "./zip";
import FallbackSvg from "./fallback";

export const FileIconExtensionMap: Record<string, FC> = {
  "ai": AiSvg,
  "avi": AviSvg,
  "bmp": BmpSvg,
  "cdr": CdrSvg,
  "csv": CsvSvg,
  "dll": DllSvg,
  "doc": DocSvg,
  "docx": DocxSvg,
  "dwg": DwgSvg,
  "eps": EpsSvg,
  "exe": ExeSvg,
  "flv": FlvSvg,
  "gif": GifSvg,
  "html": HtmlSvg,
  "iso": IsoSvg,
  "jpg": JpgSvg,
  "jpeg": JpgSvg,
  "js": JsSvg,
  "mdb": MdbSvg,
  "mid": MidSvg,
  "mov": MovSvg,
  "mp3": Mp3Svg,
  "mp4": Mp4Svg,
  "mpeg": MpegSvg,
  "pdf": PdfSvg,
  "png": PngSvg,
  "ppt": PptSvg,
  "psd": PsdSvg,
  "pub": PubSvg,
  "rar": RarSvg,
  "raw": RawSvg,
  "rss": RssSvg,
  "svg": SvgSvg,
  "tiff": TiffSvg,
  "txt": TxtSvg,
  "wav": WavSvg,
  "wma": WmaSvg,
  "xls": XlsSvg,
  "xml": XmlSvg,
  "zip": ZipSvg,
  "fallback": FallbackSvg,
};
