import styled from "@emotion/styled/macro";
import { Form } from "antd";
import { SectionSubheading } from "styles/common";

export const AutomationsFormItem = styled(Form.Item)`
  margin-bottom: 0;
  .ant-select-selection-item {
    border-radius: 12px;
  }
  .ant-select-selection-item-remove {
    margin-right: 1px;
  }
`;

export const AutomationsSubheading = styled(SectionSubheading)`
  margin-top: 0;
`;

export const AutomationsCardWrapper = styled.div`
  flex-grow: 1;
`;

export const AutomationsCardMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

export const AutomationsCardHeader = styled.div`
  margin-right: 5px;
  padding: 5px;
`;
