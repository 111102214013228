import { FC } from "react";
import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import BarcodeGeneric from "./Display/generic";
import styled from "@emotion/styled/macro";
import { dataInput } from "components/blocks/TaskTable/styled";
import { fieldDetailedWrapper } from "components/blocks/TaskFields/Components/styled";

const BarcodeListWrapper = styled.div`
  ${dataInput};
  padding: 6px 8px;
`;

const BarcodeDetailedWrapper = styled.div`
  ${fieldDetailedWrapper};
`;

const BarcodeField: FC<TaskFieldPropsGeneric> = (props) => {
  const { component } = props;

  switch (component) {
    case TaskSourceComponent.Cell:
      return (
        <BarcodeListWrapper>
          <BarcodeGeneric {...props} />
        </BarcodeListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <BarcodeDetailedWrapper>
          <BarcodeGeneric {...props} />
        </BarcodeDetailedWrapper>
      );
    default:
      return null;
  }
};

export default BarcodeField;
