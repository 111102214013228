import { FC } from "react";
import styled from "@emotion/styled/macro";
import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import { dataInput } from "components/blocks/TaskTable/styled";
import ViewpointsCell from "./Display/cell";
import ViewpointsDetailed from "./Display/detailed";
import { fieldDetailedWrapper } from "../styled";

const ViewpointsListWrapper = styled.div`
  ${dataInput};
  padding: 6px 8px;
  display: grid;
  justify-content: start;
  grid-auto-flow: column;
  grid-template-columns: min-content;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
`;

const ViewpointsDetailedWrapper = styled.div`
  ${fieldDetailedWrapper};
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
`;

const ViewpointsField: FC<TaskFieldPropsGeneric> = (props) => {
  const { component } = props;

  switch (component) {
    case TaskSourceComponent.Card:
      return null;
    case TaskSourceComponent.Cell:
      return (
        <ViewpointsListWrapper>
          <ViewpointsCell {...props} />
        </ViewpointsListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <ViewpointsDetailedWrapper>
          <ViewpointsDetailed {...props} />
        </ViewpointsDetailedWrapper>
      );
    default:
      return null;
  }
};

export default ViewpointsField;
