import { FC, useState } from "react";
import { Image } from "antd";
import QRCode from "qrcode";

import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import { getTaskDetailUri } from "lib/helpers/navigationUtils";
import { TaskFieldPropsGeneric } from "lib/types/applicationTypes";

const BarcodeGeneric: FC<TaskFieldPropsGeneric> = (props) => {
  const { task } = useTaskContext() as TaskContextInterface;
  const [qrCodeString, setQrCodeString] = useState("");

  const path = getTaskDetailUri(task?.uid);
  const url = window.location.origin + path;

  QRCode.toDataURL(url, {
    type: "image/png",
    scale: 10,
  }).then((img) => {
    setQrCodeString(img);
  });

  return (
    <>
      {qrCodeString.length > 0 &&
        <Image
          preview={{
            src: `${qrCodeString}`,
          }}
          src={process.env.PUBLIC_URL + "/images/qrCodePreview.svg"}
          width={36}
          height={36}
          style={{
            border: "2px solid #f2f2f2",
            borderRadius: "7px",
          }}
        />
      }
    </>
  );
};

export default BarcodeGeneric;
