import { FC, useState } from "react";
import { Select, Button, Divider, Form } from "antd";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useForm } from "antd/lib/form/Form";

import { MemberRights } from "components/blocks/User/UserRights/lib";
import { ProjectContextInterface, useProjectContext } from "lib/contexts/ProjectContext";
import { emailRe } from "lib/helpers/consts";
import { MemberRoles } from "lib/types/types";
import WorkspaceService from "services/WorkspaceService";
import { HomeInviteModalDesc, HomeInviteModalGrid, HomeInviteModalTag } from "../styled";

const ProjectCollaboratorsInvite: FC = () => {
  const { workspace } = useProjectContext() as ProjectContextInterface;
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    emails: [],
    role: MemberRoles.Editor,
  };

  const handleOnSubmit = async (values: typeof initialValues) => {
    setIsLoading(true);

    const emails = values.emails.filter(email => emailRe.test(email));
    const role = values.role;

    if (emails) {
      await WorkspaceService.membersAdd(workspace?.id, emails, role);
    }

    setIsLoading(false);
    form.resetFields();
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const color = emailRe.test(label as string) ? "default" : "red";

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <HomeInviteModalTag
        color={color}
        crossColor={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </HomeInviteModalTag>
    );
  };

  return (
    <Form form={form} onFinish={handleOnSubmit} initialValues={initialValues}>
      <div style={{ marginTop: 10 }}>
        <HomeInviteModalDesc>
          This project has {workspace?.members.length} members. Adding a project member will give them access to all boards within this project.
        </HomeInviteModalDesc>
        <a
          style={{ display: "inline-block" }}
          target="_blank"
          href="https://airtasks.notion.site/060bd15c77114b90b62c4edf67ae0e4c"
          rel="noreferrer"
        >
          <img src={process.env.PUBLIC_URL + "/icons/question.svg"} alt="menu" style={{ marginLeft: 4, marginTop: -2 }} />
        </a>
      </div>
      <HomeInviteModalGrid>
        <Form.Item name="emails" rules={[{ required: true }]}>
          <Select
            placeholder="Invite more project members via email"
            mode="tags"
            tagRender={tagRender}
            style={{ width: "100%" }}
            tokenSeparators={[",", " ", ";"]}
            dropdownStyle={{ display: "none" }}
          />
        </Form.Item>

        <Form.Item name="role">
          <Select
            style={{ width: 125 }}
            dropdownStyle={{ minWidth: 110 }}
          >
            {MemberRights.map(role => (
              <Select.Option
                value={role.code}
                key={role.code}
              >
                {role.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Button type="primary" htmlType="submit" loading={isLoading}>
          Invite
        </Button>
      </HomeInviteModalGrid>
      <Divider style={{ marginBlock: "24px 14px" }} />
    </Form>
  );
};

export default ProjectCollaboratorsInvite;
