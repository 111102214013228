import { useEffect, useState } from "react";
import { useAppDispatch } from "app/store";
import { setFileContainerCollection } from "app/slices/fileContainerSlice";
import { EntityQueryPayload } from "lib/types/backend";
import FileContainerService from "services/FileContainerService";
import { extractFilterRelation } from "lib/helpers/MultiRelationalEntity";
import { FileContainerInterface } from "lib/types/fileContainer";
import { captureException } from "lib/utils/sentry";

const useGetFileContainersCollection = (filter: EntityQueryPayload<FileContainerInterface>) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const { contentTypeFilter } = extractFilterRelation(filter);

  useEffect(() => {
    setIsLoading(true);
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      try {
        const data = await FileContainerService.query(filter);

        if (!signal.aborted) {
          dispatch(setFileContainerCollection({
            data: data,
            filter: contentTypeFilter,
          }));
        }

      } catch (error) {
        captureException(error);
      }
      setIsLoading(false);
      setIsLoaded(true);
    };

    fetchData();

    return () => {
      abortController.abort();
      setIsLoading(false);
      setIsLoaded(false);
    };
  }, [dispatch]);

  return { isLoaded, isLoading };
};

export default useGetFileContainersCollection;
