import styled from "@emotion/styled/macro";

const SearchItemTitleWidth = 180;

export const SearchItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchItemCheckmarkWrapper = styled.div`
  margin-right: 10px;
`;

export const SearchItemTaskTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
  font-weight: 600;
  font-size: 14px;
  width: ${SearchItemTitleWidth}px;
`;

export const SearchItemBoardTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 600;
  color: #6f7782;
  width: ${SearchItemTitleWidth}px;
`;
