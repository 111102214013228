import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { Tabs } from "antd";

export const TaskModalCreateContainer = styled.div`
  .ant-select-selection-item {
    border-radius: 12px !important;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const TaskModalFlowTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 1px;
  }
`;

export const TaskModalCreateOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
`;

export const TaskModalCreateOption = styled.div<{ select?: boolean, disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-block: 5px;
  padding: 10px 14px;
  flex-grow: 1;
  min-height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 200ms ease;
  &:hover {
    border: solid 1px #40a9ff;
  }
  ${({ select }) => select && css`
    font-weight: 600;
    border: 1px solid #40a9ff;
    color: #1890ff;
  `}
  ${({ disabled }) => disabled && css`
    color: #333;
  `}
`;

export const TaskModalDescriptionMetaData = styled.div`
  font-size: 13px;
  color: #999;
  cursor: pointer;
  transform: translateY(-6px);
  &:hover {
    color: #ccc;
  }
`;
