import { FC, useState } from "react";
import { Modal, Switch, Upload, UploadProps } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { useAppDispatch } from "app/store";
import { setTasksLoading } from "app/slices/tasksSlice";
import { EntityId } from "lib/types/entity";
import { TaskRequestImportOptions } from "lib/types/tasksTypes";
import { ModalComponentProps } from "lib/types/components";
import { captureException } from "lib/utils/sentry";
import TasksService from "services/TasksService";
import { StyledButton } from "styles/common";
import CustomStyledButton from "../CustomStyledButton";
import useSavingState from "lib/customHooks/useSavingState";
import { commonModalProps } from "../ModalDialog/lib";
import CustomTooltip from "components/ui/CustomTooltip";

interface ImportBoardModalProps extends ModalComponentProps {
  boardId: EntityId;
}

const ImportBoardModal: FC<ImportBoardModalProps> = (props) => {
  const { setSavingStart, setSavingFinish } = useSavingState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [options, setOptions] = useState<TaskRequestImportOptions>({});
  const controller = new AbortController();

  const handleTasksImport = async () => {
    if (!file) {
      return;
    }
    setSavingStart();
    setIsLoading(true);
    dispatch(setTasksLoading(true));

    try {
      await TasksService.importBatch(props.boardId, file, options, controller.signal);
    } catch (error) {
      captureException(error);
    }

    setIsLoading(false);
    dispatch(setTasksLoading(false));
    setFile(null);
    setSavingFinish();
    props.onClose();
  };

  const handleModalOnClose = () => {
    setFile(null);
    controller.abort();
    props.onClose();
  };

  const handleSwitchOnChange = (checked: boolean) => {
    const nextState = {
      ...options,
      updateExisting: checked ? true : undefined,
    };
    setOptions(nextState);
  };

  const uploaderProps: UploadProps = {
    name: "file",
    accept: ".xlsx,.xls",
    multiple: false,
    style: {
      width: "100%",
      height: "100%",
      display: file ? "none" : "block",
    },
    listType: "picture",
    beforeUpload(file) {
      setFile(file);
      return false;
    },
    onRemove() {
      setFile(null);
    }
  };

  const modalActions = [
    <div key="cancel" style={{ display: "inline-block", marginRight: "10px" }}>
      <StyledButton onClick={handleModalOnClose}>
        Cancel
      </StyledButton>
    </div>,
    <CustomStyledButton
      btnColor="purple"
      key="importBoardTasks"
      value="Import"
      isLoading={isLoading}
      disabled={isLoading}
      onClick={handleTasksImport}
    />
  ];

  return (
    <Modal
      {...commonModalProps}
      destroyOnClose
      title="Import tasks"
      visible={props.visible}
      onOk={handleModalOnClose}
      onCancel={handleModalOnClose}
      footer={file ? modalActions : null}
    >
      <Upload.Dragger {...uploaderProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Upload.Dragger>
      <div style={{ display: file ? "block" : "none", marginTop: "15px" }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "3px" }}>
          <Switch defaultChecked={options.updateExisting} size="small" onChange={handleSwitchOnChange} />
          <div style={{ margin: "-1px 0 0 7px" }}>Update existing</div>
          <CustomTooltip placement="top" content={"This option will update existing tasks, records are matched based on the \"ID\" field."} />
        </div>
      </div>
    </Modal>
  );
};

export default ImportBoardModal;
