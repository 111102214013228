import { FC } from "react";
import IconSvgWrapper from "./IconSvgWrapper";
import { IconProps } from "./lib";

const FormSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M0 1C0 0.447715 0.447715 0 1 0H4C4.55228 0 5 0.447715 5 1C5 1.55228 4.55228 2 4 2H1C0.447715 2 0 1.55228 0 1Z"/>
      <path d="M0 11C0 10.4477 0.447715 10 1 10H11C11.5523 10 12 10.4477 12 11C12 11.5523 11.5523 12 11 12H1C0.447715 12 0 11.5523 0 11Z"/>
      <path d="M0 4.3C0 3.74772 0.447715 3.3 1 3.3H11C11.5523 3.3 12 3.74772 12 4.3C12 4.85228 11.5523 5.3 11 5.3H1C0.447715 5.3 0 4.85228 0 4.3Z"/>
      <path d="M0 7.6C0 7.04772 0.447715 6.6 1 6.6H11C11.5523 6.6 12 7.04772 12 7.6C12 8.15228 11.5523 8.6 11 8.6H1C0.447715 8.6 0 8.15228 0 7.6Z"/>
    </IconSvgWrapper>
  );
};

export default FormSvg;
