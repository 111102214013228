import React, { FC, useContext } from "react";
import { useAppSelector } from "app/store";
import { MemberRoles } from "lib/types/types";
import useWorkspaceById from "lib/customHooks/useWorkspaceById";
import { EntityId } from "lib/types/entity";

interface MemberPermissionContextProps {
  workspaceId: EntityId | null;
  role?: MemberRoles;
}

const MemberPermissionContext = React.createContext<MemberPermissionContextProps>({
  workspaceId: null,
  role: MemberRoles.Viewer,
});

export const useMemberPermissionContext = () => useContext(MemberPermissionContext);

export const MemberPermissionProvider: FC<MemberPermissionContextProps> = (props) => {
  const { workspaceId } = props;
  const user = useAppSelector(state => state.currentUserReducer.user);

  const workspace = useWorkspaceById(workspaceId);
  const member = workspace?.members.find(member => String(member.user?.id) === String(user?.id));
  const role = member?.role || MemberRoles.Viewer;

  return (
    <MemberPermissionContext.Provider value={{ role, workspaceId }}>
      {props.children}
    </MemberPermissionContext.Provider>
  );
};
