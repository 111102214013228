import { FC } from "react";
import moment from "moment";
import { Tooltip } from "antd";
import { isEmpty } from "lodash";

import { getUserFirstName, getUserFullName } from "lib/helpers/userUtils";
import { DefaultColumnCodes } from "lib/types/boardTypes";
import { PropertyValueType } from "lib/types/dataTypes";
import { TaskPropertyOption, TaskPropertyValue } from "lib/types/kanbanTypes";
import { ChangeInterface, ChangeValue, TaskChangeSources } from "lib/types/types";
import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import useWorkspaceById from "lib/customHooks/useWorkspaceById";
import { TaskChangeActions } from "./lib";
import { isEmptyValues } from "lib/helpers/dataUtils";
import { DateFormats } from "lib/theme/lib";
import { AnonymousUser, AutomationUser } from "components/blocks/Automations/lib";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import LinkifySecure from "components/blocks/LinkifySecure";
import {
  TaskChangeBody,
  TaskChangeWrapper,
  TaskChangeDatetime,
  TaskChangeUserName,
  TaskChangeContent,
  TaskChangeRenderMessage, TaskChangeText, TaskChangeOldValueWrapper, TaskChangeSelect
} from "./styled";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import { formatDateTimeString } from "lib/helpers/dateUtils";
import { getColumnPrecision } from "lib/helpers/boardUtils";

interface TaskChangeProps {
  change: ChangeInterface;
}

const EmptyString = "";

const TaskChange: FC<TaskChangeProps> = (props) => {
  const { change, } = props;
  const { user, createdAt, columnId, hidden, oldValue, newValue, source } = change;

  const { task } = useTaskContext() as TaskContextInterface;
  const { terminology, isCompletionEnabled, isStatusEnabled, isAssigneesEnabled, isDueDatesEnabled, } = useBoardFeatures(task.board);
  const { propertyValues: columns } = task;
  const workspace = useWorkspaceById(task?.board.workspace || "");

  const column = columns?.find((column: TaskPropertyValue) => String(column.columnId) === String(columnId));
  const columnTitle = column?.title.toLowerCase();

  const fallbackUser = !isEmpty(user) ? user : AnonymousUser;
  const displayUser = (source === TaskChangeSources.Automation) ? AutomationUser : fallbackUser;
  const itemType = (column?.code === DefaultColumnCodes.Status) ? "status" : "option";
  const precision = getColumnPrecision(column?.precision);
  const currency = column?.currency || "$";

  let customText = EmptyString;
  let skipRender = false;

  if (column?.code === DefaultColumnCodes.Completion) {
    if (!isCompletionEnabled) {
      skipRender = true;
    }
    customText = (newValue?.value === true) ? `marked this ${terminology.single.toLowerCase()} complete` : "marked incomplete";
  }

  if (column?.code === DefaultColumnCodes.Status) {
    if (!isStatusEnabled) {
      skipRender = true;
    }
  }

  if (column?.code === DefaultColumnCodes.Assignees) {
    if (!isAssigneesEnabled) {
      skipRender = true;
    }
  }

  if (column?.code === DefaultColumnCodes.DueDate) {
    if (!isDueDatesEnabled) {
      skipRender = true;
    }
  }

  if ((column?.type === PropertyValueType.Boolean) && [TaskPropertyClass.Custom, TaskPropertyClass.Synced].includes(column.class)) {
    customText = (newValue?.value === true) ? `checked ${columnTitle}` : `unchecked ${columnTitle}`;
  }

  const renderChangeValue = (changeValue: ChangeValue | undefined) => {
    if (!changeValue) {
      return false;
    }

    const { type, value } = changeValue;

    switch (type) {
      case PropertyValueType.SingleLineText:
      case PropertyValueType.MultiLineText:
        // FIXME: this is a workaround to hide DescriptionRich change which breaks the app
        if (value.blocks) {
          skipRender = true;
        }

        return (
          <TaskChangeText>
            {value}
          </TaskChangeText>
        );

      case PropertyValueType.Url:
        return (<LinkifySecure>{value}</LinkifySecure>);

      case PropertyValueType.Number:
        return Number(value).toFixed(precision);
      case PropertyValueType.Percent:
        return Number(value).toFixed(precision) + " %";
      case PropertyValueType.Currency:
        return Number(value).toFixed(precision) + " " + currency;

      case PropertyValueType.SingleSelect: {
        let optionValue = value as (TaskPropertyOption | undefined);

        /** Support for legacy history change items (saved as optionId string) */
        if (typeof optionValue === "string" || typeof optionValue === "number") {
          optionValue = column?.options.find(option => String(optionValue) === String(option.id));
        }

        return (!optionValue)
          ? (<TaskChangeSelect>Deleted {itemType}</TaskChangeSelect>)
          : (
            <TaskChangeSelect style={{ backgroundColor: optionValue?.color }}>
              {optionValue?.title}
            </TaskChangeSelect>
          );
      }

      case PropertyValueType.MultiSelect: {
        if (Array.isArray(value)) {
          return value.map((option, index) => {
            let optionValue = option as (TaskPropertyOption | undefined);

            /** Support for legacy history change items (saved as optionId string) */
            if (typeof optionValue === "string" || typeof optionValue === "number") {
              optionValue = column?.options.find(option => String(optionValue) === String(option.id));
            }

            return (!optionValue)
              ? (<TaskChangeSelect key={index}>Deleted {itemType}</TaskChangeSelect>)
              : (
                <TaskChangeSelect key={optionValue?.id || index} style={{ backgroundColor: optionValue?.color }}>
                  {optionValue?.title}
                </TaskChangeSelect>
              );
          });
        }
        return EmptyString;
      }

      case PropertyValueType.Users: {
        if (Array.isArray(value)) {
          return value.map((user, index) => {
            const userValue = workspace?.collaborators.find(collaborator => String(user?.id || user) === String(collaborator.id)) || user;
            return (
              <TaskChangeSelect key={user?.id || index}>
                {getUserFullName(userValue)}
              </TaskChangeSelect>
            );
          });
        }
        return EmptyString;
      }

      case PropertyValueType.User: {
        const user = workspace?.collaborators.find(collaborator => String(value?.id || value) === String(collaborator.id)) || value;
        return (
          <TaskChangeSelect>
            {getUserFullName(user)}
          </TaskChangeSelect>
        );
      }

      case PropertyValueType.Date:
      case PropertyValueType.DateTime:
        const dateTimeString = formatDateTimeString(value, column?.dateFormat, column?.timeFormat);

        return (
          <TaskChangeText>
            {dateTimeString}
          </TaskChangeText>
        );

      case PropertyValueType.Boolean:
        return value;

      case PropertyValueType.Formula:
      case PropertyValueType.File:
      case PropertyValueType.Files:
      case PropertyValueType.Viewpoint:
      case PropertyValueType.Viewpoints:
      case PropertyValueType.Annotations:
      case PropertyValueType.Element:
      case PropertyValueType.Elements:
      case PropertyValueType.Id:
      case PropertyValueType.Guid:
      default: {
        skipRender = true;
        return value.toString();
      }
    }
  };

  const from = !isEmptyValues(oldValue?.value)
    ? (<span> from <TaskChangeOldValueWrapper>{renderChangeValue(oldValue)}</TaskChangeOldValueWrapper></span>)
    : (null);

  const to = !isEmptyValues(newValue?.value)
    ? (<span> to {renderChangeValue(newValue)}</span>)
    : (null);

  const getActionMessage = () => {
    if (isEmptyValues(oldValue?.value) && !isEmptyValues(newValue?.value)) {
      return TaskChangeActions.Set;
    }

    if (!isEmptyValues(oldValue?.value) && !isEmptyValues(newValue?.value)) {
      return TaskChangeActions.Changed;
    }

    if (!isEmptyValues(oldValue?.value) && isEmptyValues(newValue?.value)) {
      return TaskChangeActions.Removed;
    }

    /** If cannot get action message -> skip render */
    skipRender = true;
  };

  const action = getActionMessage();

  const renderMessage = () => {
    const prefix = `${action} ${columnTitle}`;

    switch (action) {
      case TaskChangeActions.Removed:
        return (<span>{prefix}</span>);
      default:
        return (<span>{prefix}{from}{to}</span>);
    }
  };

  if (hidden || skipRender) {
    return (null);
  }

  return (
    <TaskChangeBody>
      <TaskChangeWrapper>
        <TaskChangeContent>
          <TaskChangeUserName>{getUserFirstName(displayUser)}</TaskChangeUserName>
          <TaskChangeRenderMessage>
            {(customText.length > 0)
              ? customText
              : renderMessage()
            }
          </TaskChangeRenderMessage>
        </TaskChangeContent>
        <Tooltip title={moment(createdAt).format(DateFormats.HumanFull)} placement="top">
          <TaskChangeDatetime>{moment(createdAt).format(DateFormats.Friendly)}</TaskChangeDatetime>
        </Tooltip>
      </TaskChangeWrapper>
    </TaskChangeBody>
  );
};

export default TaskChange;
