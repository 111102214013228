import EventObserver from "lib/utils/eventObserver";
import React from "react";

type ContextProps = {
  setOverlay: (value: boolean) => void;
  kanbanWrapperRef: any;
};

export const KanbanContext = React.createContext<Partial<ContextProps>>({});

export const eventObserver = new EventObserver();
