import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const TextSmallSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.6001 34.3999C26.6001 29.9816 30.1818 26.3999 34.6001 26.3999V43.3999C34.6001 45.609 32.8092 47.3999 30.6001 47.3999C28.391 47.3999 26.6001 45.609 26.6001 43.3999V34.3999Z"/>
        <path d="M73.6001 34.3999C73.6001 29.9816 70.0184 26.3999 65.6001 26.3999V43.3999C65.6001 45.609 67.391 47.3999 69.6001 47.3999C71.8092 47.3999 73.6001 45.609 73.6001 43.3999V34.3999Z"/>
        <path d="M34.6001 26.3999H65.6001V34.3999H34.6001L34.6001 26.3999Z"/>
        <path d="M46.0001 34.3999H54.0001V65.3999H46.0001V34.3999Z"/>
        <path d="M33.4001 69.3999C33.4001 67.1908 35.191 65.3999 37.4001 65.3999H62.6001C64.8092 65.3999 66.6001 67.1908 66.6001 69.3999C66.6001 71.609 64.8092 73.3999 62.6001 73.3999H37.4001C35.191 73.3999 33.4001 71.609 33.4001 69.3999Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default TextSmallSvg;
