import { TaskInterface } from "../../types/tasksTypes";
import { FilterFunction } from "../filterFunctions";
import { FilterValueNone, FilterVersion, TAnyFilters } from "../filters";

const getPropertyValue = (task: TaskInterface, currentFilter: TAnyFilters) => {
  const customProperty = task.propertyValues.find(value => value.title === currentFilter.titleKey);
  return customProperty?.value;
};

const filterV1 = (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
  return tasks.filter(task => {
    return getPropertyValue(task, currentFilter) === currentFilter.value.valueKey;
  });
};

const filterV2 = (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
  switch (currentFilter.function) {
    case FilterFunction["="]: {
      return tasks.filter(task => {
        return getPropertyValue(task, currentFilter) === currentFilter.value.valueKey;
      });
    }

    case FilterFunction["≠"]: {
      return tasks.filter(task => {
        return getPropertyValue(task, currentFilter) !== currentFilter.value.valueKey;
      });
    }

    case FilterFunction[">"]: {
      return tasks.filter(task => {
        return getPropertyValue(task, currentFilter) > currentFilter.value.valueKey;
      });
    }

    case FilterFunction["<"]: {
      return tasks.filter(task => {
        return getPropertyValue(task, currentFilter) < currentFilter.value.valueKey;
      });
    }

    case FilterFunction["≥"]: {
      return tasks.filter(task => {
        return getPropertyValue(task, currentFilter) >= currentFilter.value.valueKey;
      });
    }

    case FilterFunction["≤"]: {
      return tasks.filter(task => {
        return getPropertyValue(task, currentFilter) <= currentFilter.value.valueKey;
      });
    }

    case FilterFunction["Is empty"]: {
      return tasks.filter(task => {
        const value = getPropertyValue(task, currentFilter);
        return value === null || value === undefined;
      });
    }

    case FilterFunction["Is not empty"]: {
      return tasks.filter(task => {
        const value = getPropertyValue(task, currentFilter);
        return value !== null && value !== undefined;
      });
    }
  }

  return tasks;
};

export const numberFilter = {
  filterValues: () => [],
  filterFn: (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
    const filterText = currentFilter.value.text;

    if (currentFilter.version && currentFilter.version !== FilterVersion.v1) {
      return filterV2(tasks, currentFilter);
    }

    if (filterText === null || filterText === undefined || filterText === FilterValueNone) {
      return tasks;
    }

    return filterV1(tasks, currentFilter);
  }
};
