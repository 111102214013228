export enum PropertyValueType {
  SingleLineText = "SingleLineText",
  MultiLineText = "MultiLineText",
  Number = "Number",
  Percent = "Percent",
  Formula = "Formula",
  Currency = "Currency",
  SingleSelect = "SingleSelect",
  MultiSelect = "MultiSelect",
  Boolean = "Boolean",
  User = "User",
  Users = "Users",
  Date = "Date",
  DateTime = "DateTime",
  File = "File",
  Files = "Files",
  Viewpoint = "Viewpoint",
  Viewpoints = "Viewpoints",
  Element = "Element",
  Elements = "Elements",
  Id = "Id",
  Guid = "Guid",
  Link = "Link",
  Url = "Url",
  Progress = "Progress",
  Barcode = "Barcode",
  Annotations = "Annotations",
  Subtasks = "Subtasks",
}

export enum SystemPropertyCode {
  CreatedTime = "created_at",
  LastModified = "updated_at",
  CreatedBy = "owner",
  LastModifiedBy = "lastEditor",
  ClosingDate = "closingDate",
  Id = "id",
  Progress = "progress",
  Barcode = "barcode",
}

export enum AutodeskPropertyCodes {
  Url = "synced_bim360_url",
  Id = "synced_bim360_id",
  Status = "synced_bim360_status",
}

export const SyncedPropertyCodes = {
  Autodesk: AutodeskPropertyCodes
};
