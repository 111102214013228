import React, { FC, RefObject, useEffect, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { DatePicker } from "antd";
import { PickerProps } from "antd/lib/date-picker/generatePicker";
import moment, { Moment } from "moment";

import useMemberPermission from "lib/customHooks/useMemberPermission";
import { useBoardContext } from "lib/contexts/BoardContext";
import { useTaskContext } from "lib/contexts/TaskContext";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import { TaskFieldSetterGetter } from "lib/types/applicationTypes";
import { DefaultColumnCodes } from "lib/types/boardTypes";
import { TaskPropertyDateTimeFormat, TaskPropertyTimeFormat } from "lib/types/kanbanTypes";
import { MemberRoles } from "lib/types/types";
import useUpdateEffect from "lib/customHooks/useUpdateEffect";
import { compareMomentDates } from "lib/helpers/dateUtils";
import TimePickerCustom from "components/blocks/TaskFields/Components/Date/Display/TimePickerCustom";
import SyncedFieldTooltip from "components/blocks/SyncedFieldTooltip";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import { DatePickerHidden } from "pages/Board/BoardKanban/Card/Calendar/styled";
import { FieldEmptyState } from "../../styled";
import DateValue from "./DateValue";
import HiddenCell from "../../HiddenCell";

interface DateGenericProps extends TaskFieldSetterGetter {
  propertyType: TaskPropertyClass;
  dateFormat?: TaskPropertyDateTimeFormat;
  timeFormat?: TaskPropertyTimeFormat;
}

const DateGeneric: FC<DateGenericProps> = (props) => {
  const { code, value, setValue, propertyType } = props;

  const { board } = useBoardContext();
  const { task } = useTaskContext();

  const boardObj = board ?? task?.board;
  const fallbackDate = moment({ h: 0, m: 0, s: 0 });

  const field = boardObj?.columns.find(column => column.code === code);
  const dateFormat = props.dateFormat ?? field?.dateFormat;
  const timeFormat = props.timeFormat ?? field?.timeFormat;

  const initialValue = value ? moment(value) : null;
  const [dateTimeValue, setDateTimeValue] = useState<moment.Moment | null>(initialValue);
  const [isDateOpened, setIsDateOpened] = useState(false);

  const wrapperRef = useRef(null) as RefObject<any>;
  const selectDropdownParent = (wrapperRef?.current) ? wrapperRef.current.offsetParent : wrapperRef.current;

  const isSyncedBoard = !!boardObj?.externalDataSource;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const { isCompletionEnabled, isDueDatesEnabled } = useBoardFeatures(task?.board);
  const isHidden = (code === DefaultColumnCodes.DueDate) && !isDueDatesEnabled;

  const calendarPickerProps: PickerProps<Moment> = {
    ...((timeFormat && dateFormat && timeFormat !== TaskPropertyTimeFormat.None) && {
      renderExtraFooter: (mode) => (
        <TimePickerCustom
          value={dateTimeValue}
          saveTime={setTime}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
        />
      )
    })
  };

  useUpdateEffect(() => {
    if (!isDateOpened && !compareMomentDates(dateTimeValue, initialValue)) {
      setValue({ cellName: code, cellValue: dateTimeValue?.toISOString() });
    }
  }, [isDateOpened]);

  useEffect(() => {
    const nextState = value ? moment(value) : null;
    setDateTimeValue(nextState);
  }, [value]);

  useEffect(() => {
    if (value && (timeFormat === TaskPropertyTimeFormat.None)) {
      setTime(moment({ hour: 0, minute: 0, second: 0 }));
    }
  }, [timeFormat]);

  const setDate = (momentObject: Moment | null) => {
    if (momentObject) {
      const nextState = dateTimeValue?.clone() || fallbackDate;
      nextState?.year(momentObject?.year());
      nextState?.month(momentObject?.month());
      nextState?.date(momentObject?.date());

      setDateTimeValue(nextState);
    }
  };

  const setTime = (momentObject: Moment | null) => {
    if (momentObject) {
      const nextState = dateTimeValue?.clone() || fallbackDate;
      nextState?.hour(momentObject?.hour());
      nextState?.minute(momentObject?.minute());
      nextState?.second(momentObject?.second());

      setDateTimeValue(nextState);
    }
  };

  const onCalendarOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    const dropdownElement = (e.target as HTMLElement).closest(".ant-picker-dropdown");

    if (!dropdownElement) {
      setIsDateOpened(!isDateOpened);
    }
  };

  const handleOnOutsideClick = (e: MouseEvent) => {
    const dropdownElement = (e.target as HTMLElement).closest(".ant-picker-dropdown");

    if (dropdownElement) {
      return null;
    }

    if (selectDropdownParent && !selectDropdownParent.contains(e.target)) {
      return setIsDateOpened(false);
    }

    return setIsDateOpened(false);
  };

  const handleCalendarOnChange = (value: moment.Moment | null, dateString: string) => {
    setDate(value);
  };

  const setDateNull = (event: React.MouseEvent) => {
    event.stopPropagation();

    setDateTimeValue(null);
    setValue({ cellName: code, cellValue: null });
    setIsDateOpened(false);
  };

  return (
    <div onClick={onCalendarOpen} ref={wrapperRef} style={{ width: "100%" }}>
      {isHidden &&
        <HiddenCell />
      }
      {!isHidden &&
        <OutsideClickHandler onOutsideClick={handleOnOutsideClick}>
          {(!isSyncedBoard || (props.propertyType === TaskPropertyClass.Custom)) &&
            <DatePickerHidden>
              <DatePicker
                {...calendarPickerProps}
                open={isDateOpened}
                bordered={false}
                disabled={!allowedToEdit}
                value={dateTimeValue}
                onChange={handleCalendarOnChange}
                onOk={() => setIsDateOpened(false)}
                getPopupContainer={() => selectDropdownParent}
              />
            </DatePickerHidden>
          }
          <div style={{ display: "flex", alignItems: "center" }}>
            {!dateTimeValue &&
              <FieldEmptyState>Empty</FieldEmptyState>
            }
            {dateTimeValue &&
              <DateValue
                dateValue={dateTimeValue}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                isCompleted={task?.completed}
                propertyType={propertyType}
                setDateNull={setDateNull}
                disableColors={!isCompletionEnabled}
                disableClear={isSyncedBoard || !allowedToEdit}
              />
            }
            {(isSyncedBoard && (code === DefaultColumnCodes.DueDate)) &&
              <SyncedFieldTooltip marginLeft />
            }
          </div>
        </OutsideClickHandler>
      }
    </div>
  );
};

export default DateGeneric;
