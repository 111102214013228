import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

import { TaskInterface } from "lib/types/tasksTypes";
import { EntityId } from "lib/types/entity";

interface InitialStateType {
  tasks: TaskInterface[];
  isLoading: boolean;
}

const initialState: InitialStateType = {
  tasks: [],
  isLoading: false,
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setTasksLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTasks: (state, action: PayloadAction<TaskInterface[]>) => {
      state.tasks = action.payload;
    },
    addTask: (state, action: PayloadAction<TaskInterface | any>) => {
      state.tasks = [...state.tasks, action.payload];
    },
    updateTask: (state, action: PayloadAction<TaskInterface | any>) => {
      state.tasks = state.tasks.map(task => {
        if (String(task.id) === String(action.payload.id)) {
          return { ...task, ...action.payload };
        }

        return task;
      });
    },
    performTasksOptimisticUpdate: (state, action: PayloadAction<{ id: EntityId, data: Partial<TaskInterface> }>) => {
      const { id, data } = action.payload;

      const findIndex = state.tasks.findIndex(task => String(task.id) === String(id));
      if (findIndex !== - 1) {
        for (const [code, value] of Object.entries(data)) {
          state.tasks[findIndex][code as keyof TaskInterface] = value as never;
        }
      }
    },
    deleteTask: (state, action: PayloadAction<TaskInterface>) => {
      state.tasks = cloneDeep(state.tasks).filter(task => task.id !== action.payload.id);
    },
    setReorderedTasks: (state, action: PayloadAction<{ status: string, tasks: Array<TaskInterface>, target: string }>) => {
      const { status, tasks } = action.payload;

      state.tasks = state.tasks.map(task => {
        const editedTask = tasks.find((t: any) => t.id === task.id);
        if (editedTask) {
          return {
            ...task,
            status: Number(status),
            // TODO: refactor `tasksSort` function to match the current data structure
            sort__kanban: editedTask.sort as unknown as number,
          };
        }

        return task;
      });
    },
  },
});

export const { setTasksLoading, setTasks, addTask, updateTask, performTasksOptimisticUpdate, deleteTask, setReorderedTasks } = tasksSlice.actions;

export default tasksSlice.reducer;
