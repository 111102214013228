import { FC, useEffect, useState } from "react";
import { TaskFieldSetterGetter } from "lib/types/applicationTypes";
import { InputNumberStyled } from "components/blocks/TaskTable/components/TaskTitle/styled";
import { PercentPostfix } from "./styled";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import { getColumnPrecision } from "lib/helpers/boardUtils";

interface PercentGenericProps extends TaskFieldSetterGetter {
  precision: number;
}

const PercentGeneric: FC<PercentGenericProps> = (props) => {
  const { precision, value, setValue, code } = props;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  const handleInputNumberOnChange = (nextValue: string | number) => {
    setDisplayValue(nextValue);
  };

  const handleInputNumberUpdate = (e: any) => {
    const nextValue = e.target?.value || null;
    setValue({ cellName: code, cellValue: nextValue });
  };

  return (
    <>
      <InputNumberStyled
        type="number"
        value={displayValue}
        precision={getColumnPrecision(precision)}
        onChange={value => handleInputNumberOnChange(value as number)}
        onBlur={handleInputNumberUpdate}
        onPressEnter={handleInputNumberUpdate}
        style={{ width: "100%" }}
        size="small"
        placeholder="Empty"
        disabled={!allowedToEdit}
      />
      <PercentPostfix>
        %
      </PercentPostfix>
    </>
  );

};

export default PercentGeneric;
