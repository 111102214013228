import styled from "@emotion/styled/macro";

export const ExportModalOptionWrapper = styled.div`
  margin: 7px -10px 7px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ExportModalHeading = styled.label`
  font-size: 14px;
  color: #9e9e9e;
`;
