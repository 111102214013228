import { FC } from "react";
import { DefaultIconColor, DefaultIconSize, IconProps } from "../lib";

const IconSvgWrapper: FC<IconProps> = (props) => {
  const color = props?.color || DefaultIconColor;
  const size = props?.size || DefaultIconSize;

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} style={{ fill: color }} xmlns="http://www.w3.org/2000/svg">
      {props.children}
    </svg>
  );
};

export default IconSvgWrapper;
