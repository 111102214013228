/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useReducer } from "react";
import { cloneDeep } from "lodash";

import { Column } from "lib/types/tasksTypes";

import listReducer, { ListReducerActionKind, ListReducerState } from "./utils/listReducer";
import { TableElementType } from "./constants";
import { DefaultTableProps } from "./types";

import Table from "./Table";
import TableLite from "./Table/TableLite";
import { DefaultColumnCodes } from "lib/types/boardTypes";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import LoadingPanel from "../LoadingPanel";

const addColumn: Partial<Column> = {
  id: "-2",
  accessor: TableElementType.addBtn,
  code: TableElementType.addBtn,
  type: TableElementType.addBtn,
  sort: 9e16,
  width: 20,
  maxWidth: 35,
};

const excludedColumns: Array<DefaultColumnCodes | string> = [
  DefaultColumnCodes.Completion,
  DefaultColumnCodes.Description,
  DefaultColumnCodes.DescriptionRTE,
];

const TaskTable: FC<DefaultTableProps> = (props) => {
  const { boardId, columns, data, lite, columnsProps, customization, addRow, onItemExpand,
    isLoading, isAddColumn, isResize, currentSort, currentFilter, wrapMemberProvider, wrapTaskProvider } = props;

  const initialState: ListReducerState = { columns, data, skipReset: false };
  const [state, dispatch] = useReducer(listReducer, initialState);

  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);

  useEffect(() => {
    const { newColumns, newData } = processingData();

    dispatch({ type: ListReducerActionKind.setList, columns: newColumns, data: newData });
  }, [columns, data]);

  const processingData = () => {
    const newColumns = cloneDeep(columns)
      .map((column) => {
        column.accessor = column.code;
        return column;
      })
      .filter((column) => !excludedColumns.includes(column.code) && column.active && !column.hidden);

    if (!lite && allowedToEdit && isAddColumn) {
      newColumns.push(addColumn as Column);
    }

    newColumns.sort((a, b) => {
      const first = a.sort || 0;
      const second = b.sort || 0;

      return first - second;
    });

    const newData = cloneDeep(data)
      .map((taskItem) => ({
        ...taskItem,
        ...taskItem.customProperties,
      }));

    return { newColumns, newData };
  };

  const commonProps = {
    columns: state.columns,
    data: state.data,
    columnsProps,
    customization,
    isResize,
    currentSort,
    currentFilter,
    wrapMemberProvider,
    wrapTaskProvider,
    onItemExpand,
  };

  return (
    <>
      {isLoading &&
        <LoadingPanel />
      }
      {!isLoading &&
        <>
          {lite
            ? <TableLite {...commonProps} />
            : <Table
              {...commonProps}
              boardId={boardId}
              skipReset={state.skipReset}
              addRow={addRow}
              dispatch={dispatch}
            />
          }
        </>
      }
    </>
  );
};

export default TaskTable;
