import React, { FC, useState } from "react";
import { Select, Modal, Form } from "antd";

import CustomStyledButton from "components/blocks/CustomStyledButton";
import { StyledButton } from "styles/common";
import { EntityId } from "lib/types/entity";
import { useAppSelector } from "app/store";
import BoardService from "services/BoardService";
import { useHistory } from "react-router-dom";
import { getBoardUri } from "lib/helpers/navigationUtils";
import { ExportModalOptionWrapper, ExportModalHeading } from "../ExportBoardModal/styled";
import { MemberRoles } from "lib/types/types";
import { ModalComponentProps } from "lib/types/components";
import { captureException } from "lib/utils/sentry";
import useSavingState from "lib/customHooks/useSavingState";
import { commonModalProps } from "../ModalDialog/lib";

interface DuplicateBoardModalProps extends ModalComponentProps {
  boardId: EntityId;
}

const DuplicateBoardModal: FC<DuplicateBoardModalProps> = (props) => {
  const { boardId, visible, onClose } = props;
  const history = useHistory();

  const workspaces = useAppSelector(state => state.workspaces.workspaces);
  const user = useAppSelector(state => state.currentUserReducer.user);

  const { setSavingStart, setSavingFinish } = useSavingState();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const workspacesList = workspaces.filter(workspace => {
    const member = workspace.members.find(member => String(member.user?.id) === String(user?.id));
    const hasPermission = member ? [MemberRoles.Owner, MemberRoles.Admin].includes(member.role) : false;
    return hasPermission;
  });

  const handleOnSubmit = async (event: React.MouseEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setSavingStart();
    try {
      const data = await BoardService.duplicate(boardId, form.getFieldValue("workspace"));
      const target = getBoardUri(data.data?.data?.uid);
      history.push(target);
    } catch (error) {
      captureException(error);
    }
    setSavingFinish();
    setIsLoading(false);
    onClose();
  };

  const handleOnClose: React.MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <Modal
      {...commonModalProps}
      title="Duplicate board"
      visible={visible}
      onCancel={handleOnClose}
      width={325}
      footer={[
        <div key="cancel" style={{ display: "inline-block", marginRight: "10px" }}>
          <StyledButton
            onClick={handleOnClose}
          >
            Cancel
          </StyledButton>
        </div>,
        <CustomStyledButton
          key="ok"
          value="Duplicate"
          btnColor="purple"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={handleOnSubmit}
        />
      ]}
    >
      <ExportModalOptionWrapper>
        <ExportModalHeading>Target project</ExportModalHeading>
        <Form
          form={form}
          initialValues={{
            workspace: workspaces[0]?.id || "",
          }}
        >
          <Form.Item name="workspace">
            <Select
              size="small"
              placement="bottomRight"
              bordered={false}
              dropdownStyle={{ minWidth: 200 }}
              style={{ maxWidth: 160 }}
            >
              {workspacesList.map(workspace => (
                <Select.Option key={workspace.id} value={workspace.id} label={workspace.name}>{workspace.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </ExportModalOptionWrapper>
    </Modal>
  );
};

export default DuplicateBoardModal;
