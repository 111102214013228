import Compressor from "compressorjs";
import { createLogger } from "lib/utils/BaseClassLoggable";
import { captureException } from "lib/utils/sentry";

const logger = createLogger({ prefix: "[ImageConversion]" });

export const blobToFile = (blob: any, fileName: string): File => {
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  return blob;
};

/*
* converts blob to url for using in <img>
*/
export const blobToUrl = (blob: Blob) => {
  const reader = new FileReader();
  return new Promise<string | ArrayBuffer | null>((res, rej) => {
    reader.readAsDataURL(blob);
    reader.onload = () => {
      res(reader.result);
    };
    reader.onerror = (e) => {
      rej(e);
    };
  });
};

export const imageToBlob = async (img: any) => {
  try {
    return await fetch(img).then(r => r.blob());
  } catch (e) {
    captureException(e);
    return null;
  }
};

export const convertAndCompressImage = async (img: any) => {
  const blob: Blob = await fetch(img).then(r => r.blob());
  const file = blobToFile(blob, "image");

  return new Promise<Blob>((res, rej) => {
    new Compressor(file, {
      quality: 0.7,
      width: 1000,
      height: 1000,
      success(result) {
        res(result);
      },
      error(err) {
        console.warn(err.message);
        rej(err);
      },
    });
  });
};

export const compressBlob = async (blob: Blob, maxSize = 2_000, quality = 0.80) => {
  const methodName = "compressBlob";
  const file = blobToFile(blob, "image");
  logger.debug(methodName, "bytes before compression:", file.size);

  return new Promise<Blob>((res, rej) => {
    new Compressor(file, {
      quality: quality,
      maxHeight: maxSize,
      maxWidth: maxSize,
      success(result) {
        logger.debug(methodName, "bytes after compression:", result.size);
        res(result);
      },
      error(err) {
        logger.warn(err.message);
        rej(err);
      },
    });
  });
};
