import { TaskSetValueHandler, TaskOptimisticUpdatePayload } from "lib/types/tasksTypes";
import TasksService from "services/TasksService";
import useTaskPerformRequest, { TaskPerformRequestHookProps } from "./useTaskPerformRequest";

const useTaskSetValue = (props: TaskPerformRequestHookProps) => {
  const { task } = props;
  const performRequest = useTaskPerformRequest(props);

  const setTaskValue: TaskSetValueHandler = ({ cellName, cellValue }) => {
    if (!task) {
      return undefined;
    }

    const requestPayload: TaskOptimisticUpdatePayload = {
      [cellName]: cellValue,
    };
    const requestFn = () => TasksService.modifyTaskProperty(task.id, requestPayload);

    return performRequest(requestPayload, requestFn);
  };

  return { setTaskValue };
};

export default useTaskSetValue;
