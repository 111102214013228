import { useEffect } from "react";

import TasksService from "services/TasksService";
import { setTasks, setTasksLoading } from "app/slices/tasksSlice";
import { useAppDispatch } from "app/store";
import { EntityId } from "lib/types/entity";

const useGetTaskList = (boardId: EntityId): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTasksLoading(true));
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getData = async () => {
      const tasks = await TasksService.getTasks(boardId);

      if (!signal.aborted) {
        dispatch(setTasks(tasks));
        dispatch(setTasksLoading(false));
      }
    };
    getData();

    return () => {
      abortController.abort();
      dispatch(setTasksLoading(false));
    };
  }, [dispatch, boardId]);
};

export default useGetTaskList;
