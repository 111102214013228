import { FC } from "react";
import { Form, Input } from "antd";

interface AuthNewPasswordProps {
  text: string;
  passwordNameField: string;
}

const AuthNewPassword: FC<AuthNewPasswordProps> = (props) => {
  return (
    <Form.Item
      name="confirm"
      rules={[
        {
          required: true,
          message: "",
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue(props.passwordNameField) === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error("Passwords do not match!"));
          },
        }),
      ]}
      label={props.text}
    >
      <Input.Password id="new-password-confirm" autoComplete="new-password" />
    </Form.Item>
  );
};

export default AuthNewPassword;
