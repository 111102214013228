import { FC } from "react";
import { Avatar } from "antd";

import AvatarCustom from "components/ui/AvatarCustom";
import ButtonIcon from "components/ui/ButtonIcon";
import { useTaskContext, TaskContextInterface } from "lib/contexts/TaskContext";
import useTaskSetCollaborators from "lib/customHooks/dataLayer/task/useTaskSetCollaborators";
import { CollabBody, CollabText, CollabWrapper } from "./styled";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

const TaskCollaborators: FC = () => {
  const { task } = useTaskContext() as TaskContextInterface;
  const { collaborators } = task;
  const { subscribeCollaborator, unsubscribeCollaborator, isCollaborator } = useTaskSetCollaborators({ task, updateDetailed: true });
  const { terminology } = useBoardFeatures(task.board);

  const handleOnClick = () => {
    const actionFn = isCollaborator ? unsubscribeCollaborator : subscribeCollaborator;
    return actionFn();
  };

  return (
    <CollabWrapper>
      <CollabBody>
        <CollabBody>
          <CollabText>{
            (collaborators?.length)
              ? "Followers"
              : `No one's following this ${terminology.single.toLowerCase()}`
          }
          </CollabText>
          <Avatar.Group>
            {collaborators?.map(user =>
              (<AvatarCustom key={user.id} user={user} tooltip />)
            )}
          </Avatar.Group>
        </CollabBody>
        <div>
          <ButtonIcon
            iconUrl="/icons/bell.svg"
            text={isCollaborator ? "Leave" : "Follow"}
            onClick={handleOnClick}
            iconOutlined
          />
        </div>
      </CollabBody>
    </CollabWrapper>
  );
};

export default TaskCollaborators;
