import { FC } from "react";
import IconSvgWrapper from "components/icons/IconSvgWrapper";
import { IconProps } from "components/icons/lib";

const TasksSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M10.2424 10.2424C12.5859 7.89899 12.5859 4.10101 10.2424 1.75758C7.89899 -0.585859 4.10101 -0.585859 1.75758 1.75758C-0.585859 4.10101 -0.585859 7.89899 1.75758 10.2424C4.10101 12.5859 7.90144 12.5859 10.2424 10.2424ZM3.84879 5.08295L5.19804 6.4322L8.15366 3.47903L9.19927 4.52464L6.2461 7.47781L5.19804 8.52342L4.15243 7.47781L2.80318 6.12856L3.84879 5.08295Z" />
    </IconSvgWrapper>
  );
};

export default TasksSvg;
