import styled from "@emotion/styled/macro";

export const StyledIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const StyledIconBox = styled.div<{ disabled?: boolean, selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 7px;
  background-color: ${({ selected }) => selected ? "#f2f2f2" : "none"};
  &:hover {
    background-color: ${({ disabled }) => disabled ? "none" : "#f2f2f2"};
    transition: background-color 200ms ease;
    cursor: ${({ disabled }) => disabled ? "not-allowed" : "pointer"};
  }
`;
