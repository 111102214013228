import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const SettingsSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M35.8333 49.9996C49.1802 49.9996 60 39.1798 60 25.8329C60 12.4861 49.1802 1.66626 35.8333 1.66626C22.4864 1.66626 11.6667 12.4861 11.6667 25.8329C11.6667 39.1798 22.4864 49.9996 35.8333 49.9996ZM35.8333 34.9996C40.8959 34.9996 45 30.8955 45 25.8329C45 20.7703 40.8959 16.6663 35.8333 16.6663C30.7707 16.6663 26.6667 20.7703 26.6667 25.8329C26.6667 30.8955 30.7707 34.9996 35.8333 34.9996Z"/>
        <path d="M58.8136 33.3329C59.5837 30.9719 60 28.451 60 25.8329C60 23.2148 59.5837 20.694 58.8136 18.3329H92.5C96.6425 18.3329 100 21.6908 100 25.8329C100 29.9751 96.6425 33.3329 92.5 33.3329H58.8136Z"/>
        <path d="M12.8531 33.3329H7.5C3.35787 33.3329 0 29.9751 0 25.8329C0 21.6908 3.35787 18.3329 7.5 18.3329H12.8531C12.083 20.694 11.6667 23.2148 11.6667 25.8329C11.6667 28.451 12.083 30.9719 12.8531 33.3329Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M64.1667 98.3333C77.5136 98.3333 88.3333 87.5133 88.3333 74.1667C88.3333 60.8197 77.5136 50 64.1667 50C50.8197 50 40 60.8197 40 74.1667C40 87.5133 50.8197 98.3333 64.1667 98.3333ZM64.1667 83.3333C69.2292 83.3333 73.3333 79.2292 73.3333 74.1667C73.3333 69.1041 69.2292 65 64.1667 65C59.1041 65 55 69.1041 55 74.1667C55 79.2292 59.1041 83.3333 64.1667 83.3333Z"/>
        <path d="M87.1467 81.6666C87.9167 79.3056 88.3333 76.7847 88.3333 74.1666C88.3333 71.5485 87.9167 69.0276 87.1467 66.6666H92.5C96.6425 66.6666 100 70.0245 100 74.1666C100 78.3088 96.6425 81.6666 92.5 81.6666H87.1467Z"/>
        <path d="M41.1864 81.6666H7.5C3.35787 81.6666 0 78.3088 0 74.1666C0 70.0245 3.35787 66.6666 7.5 66.6666H41.1864C40.4163 69.0276 40 71.5485 40 74.1666C40 76.7847 40.4163 79.3056 41.1864 81.6666Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default SettingsSvg;
