import { Moment } from "moment";
import moment from "moment/moment";
import { TaskInterface } from "../../types/tasksTypes";
import { FilterFunction } from "../filterFunctions";
import { FilterValueNone, FilterVersion, TAnyFilters } from "../filters";
import { FilterType } from "../filterTypes";

const getFilterDate = (currentFilter: TAnyFilters) => {
  return moment(currentFilter.value.valueKey as string);
};

const getCustomPropertyDate = (task: TaskInterface, currentFilter: TAnyFilters, isDueDateVariant?: boolean): Moment => {
  const customProperty = task.propertyValues.find(value => value.title === currentFilter.titleKey);

  if (isDueDateVariant) {
    return moment(task.dueDate);
  }

  if (
    currentFilter.titleKey === FilterType["Created time"] ||
    currentFilter.titleKey === FilterType["Closing date"] ||
    currentFilter.titleKey === FilterType["Last modified"]
  ) {
    // Need to adjust time from DB to local and remove HH:mm
    const utcTaskDate = moment(customProperty?.value || "");
    const offsetFromUtcToCurrent = utcTaskDate.utcOffset();
    const taskDateWithoutTimeZone = utcTaskDate.utcOffset(offsetFromUtcToCurrent).format("YYYY-MM-DD");
    return moment(taskDateWithoutTimeZone);
  }

  return moment(customProperty?.value || "");
};

const filterV1 = (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
  const filterDate = getFilterDate(currentFilter);

  return tasks.filter(task => {
    const customProperty = task.propertyValues.find(value => value.title === currentFilter.titleKey);
    const taskDate = moment(customProperty?.value || "");
    return Math.ceil(taskDate.diff(filterDate, "days", true)) === 0;
  });
};

export const filterV2 = (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
  const filterDate = getFilterDate(currentFilter);
  const isDueDateVariant = currentFilter.titleKey === FilterType["Due date"];

  switch (currentFilter.function) {
    case FilterFunction.Is: {
      return tasks.filter(task => {
        const taskDate = getCustomPropertyDate(task, currentFilter, isDueDateVariant);
        return Math.ceil(taskDate.diff(filterDate, "days", true)) === 0;
      });
    }

    case FilterFunction["Is before"]: {
      return tasks.filter(task => {
        const taskDate = getCustomPropertyDate(task, currentFilter, isDueDateVariant);
        return Math.ceil(taskDate.diff(filterDate, "days", true)) < 0;
      });
    }

    case FilterFunction["Is after"]: {
      return tasks.filter(task => {
        const taskDate = getCustomPropertyDate(task, currentFilter, isDueDateVariant);
        return Math.ceil(taskDate.diff(filterDate, "days", true)) > 0;
      });
    }

    case FilterFunction["Is on or before"]: {
      return tasks.filter(task => {
        const taskDate = getCustomPropertyDate(task, currentFilter, isDueDateVariant);
        return Math.ceil(taskDate.diff(filterDate, "days", true)) <= 0;
      });
    }

    case FilterFunction["Is on or after"]: {
      return tasks.filter(task => {
        const taskDate = getCustomPropertyDate(task, currentFilter, isDueDateVariant);
        return Math.ceil(taskDate.diff(filterDate, "days", true)) >= 0;
      });
    }

    case FilterFunction["Is empty"]: {
      return tasks.filter(task => {
        const taskDate = getCustomPropertyDate(task, currentFilter, isDueDateVariant);
        return !taskDate.isValid();
      });
    }

    case FilterFunction["Is not empty"]: {
      return tasks.filter(task => {
        const taskDate = getCustomPropertyDate(task, currentFilter, isDueDateVariant);
        return taskDate.isValid();
      });
    }

    case FilterFunction["This week"]: {
      return tasks.filter(task => {
        const dueDate = getCustomPropertyDate(task, currentFilter, isDueDateVariant);
        const start = moment().startOf("isoWeek");
        const end = moment().endOf("isoWeek");
        return dueDate >= start && dueDate <= end;
      });
    }

    default: {
      return tasks;
    }
  }
};

export const dateFilter = {
  filterValues: () => [],
  filterFn: (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
    if (currentFilter.version && currentFilter.version !== FilterVersion.v1) {
      return filterV2(tasks, currentFilter);
    }

    if (currentFilter.value.valueKey === FilterValueNone || !currentFilter.value.valueKey) {
      return tasks;
    }

    return filterV1(tasks, currentFilter);
  },
};
