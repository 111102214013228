import { FC } from "react";
import IconSvgWrapper from "./IconSvgWrapper";
import { IconProps } from "./lib";

const CompletionSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M11.6485 4.0348L5.31801 10.3176C4.84923 10.783 4.08879 10.783 3.61956 10.3176L0.351719 7.07394C-0.11724 6.60856 -0.11724 5.85371 0.351719 5.38825C0.820766 4.9227 1.58114 4.9227 2.04998 5.38807L4.46904 7.78911L9.94997 2.34903C10.419 1.88348 11.1795 1.88383 11.6483 2.34903C12.1172 2.81449 12.1172 3.56908 11.6485 4.0348Z"/>
    </IconSvgWrapper>
  );
};

export default CompletionSvg;
