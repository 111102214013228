import React, { FC, useContext } from "react";
import { DisplayView } from "lib/types/applicationTypes";
import { BoardViewInterface } from "lib/types/boardTypes";

interface BoardViewContextProps {
  view: string;
  viewData?: BoardViewInterface;
  displayView: DisplayView;
  isCustomView: boolean;
}

const BoardViewContext = React.createContext<BoardViewContextProps>({
  view: "list",
  displayView: DisplayView.List,
  isCustomView: false,
});

export const useBoardViewContext = () => useContext(BoardViewContext);

export const BoardViewProvider: FC<BoardViewContextProps> = (props) => {
  return (
    <BoardViewContext.Provider value={{ ...props }}>
      {props.children}
    </BoardViewContext.Provider>
  );
};

