import { FC, useEffect } from "react";

import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import { EntityId } from "lib/types/entity";
import ButtonIcon from "components/ui/ButtonIcon";

import { eventObserver } from "../lib";
import { ADD_CARD } from "../types";
import { useBoardContext } from "lib/contexts/BoardContext";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

interface AddCardLinkProps {
  laneId: EntityId;
  onClick: () => void;
}

const handleMessage = (data: any, id: EntityId, callback: () => void) => {
  const { type, laneId } = data;

  if (type === ADD_CARD && laneId === id) {
    callback();
  }
};

const AddCardLink: FC<AddCardLinkProps> = (props) => {
  const { onClick, laneId } = props;

  const { board } = useBoardContext();
  const { terminology } = useBoardFeatures(board);
  const allowedToCreate = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const btnText = "Add " + terminology.single.toLowerCase();

  useEffect(() => {
    eventObserver.subscribe((data: any) => handleMessage(data, laneId, onClick));

    return function cleanup() {
      eventObserver.unsubscribe((data: any) => handleMessage(data, laneId, onClick));
    };
  });

  if (allowedToCreate) {
    return (
      <ButtonIcon text={btnText} onClick={onClick} />
    );
  } else {
    return (null);
  }
};

export default AddCardLink;
