import { FC } from "react";
import { useHistory } from "react-router-dom";
import { Empty } from "antd";
import { BoardViewEmptyState } from "pages/Board/BoardView/styled";
import { StyledButton, StyledButtonProps } from "styles/common";
import { HttpsRegExp } from "lib/helpers/urlUtils";

interface NotFoundSectionProps {
  text?: string;
  backLink?: string;
  backAction?: React.MouseEventHandler<HTMLButtonElement>;
  backLinkText?: string;
  buttonProps?: StyledButtonProps;
}

const NotFoundSection: FC<NotFoundSectionProps> = (props) => {
  const { text, backLink, backLinkText, backAction, buttonProps } = props;
  const history = useHistory();

  const handleRedirect: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (backAction) {
      backAction(event);
      return;
    } else if (backLink) {
      if (HttpsRegExp.test(backLink)) {
        window.open(backLink, "_blank");
      } else {
        history.push(backLink);
      }
    }
  };

  return (
    <BoardViewEmptyState>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={text || "0 annotations found"} />
      {(backLink || backAction) &&
        <StyledButton {...buttonProps} onClick={handleRedirect}>{backLinkText}</StyledButton>
      }
    </BoardViewEmptyState>
  );
};

export default NotFoundSection;
