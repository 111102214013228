import { FC, useState } from "react";

import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import { useBoardContext, BoardContextInterface } from "lib/contexts/BoardContext";
import ButtonIcon from "components/ui/ButtonIcon";
import DeleteBoardModal from "components/blocks/DeleteBoardModal";
import { SectionContent } from "styles/common";
import TrashSvg from "components/icons/resizable/trash";

const DeleteBoard: FC = () => {
  const { board } = useBoardContext() as BoardContextInterface;
  const allowedToDelete = useMemberPermission([MemberRoles.Owner]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <SectionContent>
      {allowedToDelete &&
        <ButtonIcon
          redOutlined
          text="Delete board"
          icon={<TrashSvg color="#ed4758" />}
          color="#ed4758"
          onClick={showModal}
        />
      }
      {board &&
        <DeleteBoardModal
          boardId={String(board.id)}
          boardName={board?.name}
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
        />
      }
    </SectionContent >
  );
};

export default DeleteBoard;
