import { FC, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form } from "antd";

import AuthContainer from "pages/Auth/index";
import AuthSignInButton from "pages/Auth/AuthComponents/SignInButton";
import AuthResetPassword from "pages/Auth/AuthComponents/ResetPassword";
import AuthNewPassword from "pages/Auth/AuthComponents/NewPassword";
import { captureException } from "lib/utils/sentry";
import useGetQueryValue from "lib/customHooks/routeHooks/useGetQueryValue";
import { getErrorMessage } from "lib/helpers/getErrorMessage";
import WebSocketService from "lib/API/socket";
import useAuthorizedRedirect from "lib/customHooks/routeHooks/useAuthorizedRedirect";
import AuthService from "services/AuthService";
import { setToken } from "app/slices/authSlice";
import { useAppDispatch } from "app/store";

const ChangePassword: FC = () => {
  useAuthorizedRedirect();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const code = useGetQueryValue("code");
  if (code === null) {
    history.push("/auth/login");
  }

  type valueChanged = {
    password?: string
    confirm?: string
  };

  const onValuesChange = (valueChanged: valueChanged) => {
    if (valueChanged.password) {
      setPassword(valueChanged.password);
    } else if (valueChanged.confirm) {
      setPasswordConfirmation(valueChanged.confirm);
    }
  };

  const onFinish = async () => {
    if (code) {
      try {
        const data = await AuthService.changePassword(code, password, passwordConfirmation);
        if (data && data.jwt) {
          await dispatch(setToken(data.jwt));
          WebSocketService.connect();
        }
      } catch (e) {
        const errorMessage = getErrorMessage(e);
        captureException(e, true, errorMessage);
      }
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      style={{ width: "100%" }}
      requiredMark={false}
      layout="vertical"
    >
      <AuthContainer
        header="Set a new password"
        linkComponent={<Link to="/auth/login">Back to Sign In</Link>}
        resetPasswordComponent={<AuthResetPassword autoComplete="new-password" text="Password" />}
        passwordConfirm={<AuthNewPassword passwordNameField="password" text="Confirm Password" />}
        signInButton={<AuthSignInButton text="Confirm" />}
      />
    </Form>
  );
};

export default ChangePassword;
