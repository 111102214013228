export const Precisions = [
  {
    name: "1",
    value: 0,
    id: 0,
  },
  {
    name: "1.0",
    value: 1,
    id: 1,
  },
  {
    name: "1.00",
    value: 2,
    id: 2,
  },
  {
    name: "1.000",
    value: 3,
    id: 3,
  },
  {
    name: "1.0000",
    value: 4,
    id: 4,
  },
];
