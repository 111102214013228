export interface ForgeViewerStateViewport {
  aspectRatio: number;
  distanceToOrbit: number;
  eye: Array<number>;
  fieldOfView: number;
  isOrthographic: boolean;
  name: string;
  pivotPoint: Array<number>;
  projection: "perspective" | "orthographic";
  target: Array<number>;
  up: Array<number>;
  worldUpVector: Array<number>;
  orthographicHeight?: number;
}

export interface ForgeViewerStateObject {
  id: Array<any>;
  idType: string;
  isolated: Array<any>;
  hidden: Array<any>;
  explodeScale: number;
  explodeOptions: Record<string, any>;
}

export interface ForgeViewerStateRenderOptions {
  environment: string;
  ambientOcclusion: {
    enabled: boolean;
    radius: number;
    intensity: number
  };
  toneMap: {
    method: number;
    exposure: number;
    lightMultiplier: number;
  };
  appearance: {
    ghostHidden: boolean;
    ambientShadow: boolean;
    antiAliasing: boolean;
    progressiveDisplay: boolean;
    swapBlackAndWhite: boolean;
    displayLines: boolean;
    displayPoints: boolean
  }
}

export type ForgeViewerStateCutPlanes = Array<number>

export interface ForgeViewerEvent {
  target: Autodesk.Viewing.GuiViewer3D;
  type: string;
  value: any;
}

export interface ForgeViewerStateMarkup2d {
  data: string | null;
}

export interface ForgeViewerStateMarkup3d {
  data: string | null;
}

export interface ForgeViewerState {
  seedURN: string;
  objectSet: Array<ForgeViewerStateObject>;
  viewport: ForgeViewerStateViewport;
  renderOptions?: ForgeViewerStateRenderOptions;
  cutplanes?: Array<ForgeViewerStateCutPlanes>;
  sectionMode?: string | null;
  markup2d?: ForgeViewerStateMarkup2d;
  markup3d?: ForgeViewerStateMarkup3d;
}

export enum ForgeViewerMode {
  Navigation = "navigation",
  MarkupsViewer = "markupsViewer",
  MarkupsEditor = "markupsEditor",
  HomeViewEditor = "homeViewEditor",
}

export interface ForgeViewerNavigationChangeEvent {
  id: string;
  type: string;
  target: Autodesk.Viewing.GuiViewer3D;
}
