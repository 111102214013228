import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import configuration from "lib/configs/configuration";
import { setSentryScopeTag } from "lib/utils/sentry";

export const initLogRocket = () => {
  LogRocket.init(configuration.logRocket.appId, {
    release: configuration.app.version,
  });
  setupLogRocketReact(LogRocket);

  LogRocket.getSessionURL(sessionURL => {
    setSentryScopeTag("sessionURL", sessionURL);
  });
};
