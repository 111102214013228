import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Typography } from "antd";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import { copyLink, getBoardSettingsUri, getBoardUri } from "lib/helpers/navigationUtils";
import { BoardInterface, BoardInterfaceExtended } from "lib/types/boardTypes";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import DuplicateBoardModal from "../DuplicateBoardModal";
import DeleteBoardModal from "../DeleteBoardModal";
import MenuItemIcon from "components/ui/MenuItemIcon";
import TrashSvg from "components/icons/resizable/trash";
import UrlSvg from "components/icons/resizable/url";
import DuplicateSvg from "components/icons/resizable/duplicate";
import SettingsSvg from "components/icons/resizable/settings";

interface BoardContextMenuProps {
  board: BoardInterface | BoardInterfaceExtended;
  toggleVisibility: () => void;
}

const BoardCardMenuItems: FC<BoardContextMenuProps> = (props) => {
  const { board, toggleVisibility } = props;

  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const allowedToDelete = useMemberPermission([MemberRoles.Owner]);
  const allowedToCreate = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);

  const handleBoardCopyLink: MenuClickEventHandler = (info) => {
    const { domEvent } = info;
    domEvent.stopPropagation();
    copyLink(getBoardUri(board.uid));
  };

  const handleBoardDuplicate: MenuClickEventHandler = (info) => {
    const { domEvent } = info;
    domEvent.stopPropagation();
    setIsDuplicateModalVisible(true);
  };

  const handleBoardDelete: MenuClickEventHandler = (info) => {
    const { domEvent } = info;
    domEvent.stopPropagation();
    setIsDeleteModalVisible(true);
  };

  return (
    <Menu onClick={toggleVisibility}>
      <Menu.Item key="settings">
        <Link to={getBoardSettingsUri(board.uid)}>
          <MenuItemIcon text="Settings" icon={<SettingsSvg size={12}/>} />
        </Link>
      </Menu.Item>
      <Menu.Item key="copyLink" onClick={handleBoardCopyLink}>
        <MenuItemIcon text="Copy board link" icon={<UrlSvg size={12}/>} />
      </Menu.Item>
      {allowedToCreate &&
        <>
          <Menu.Item key="duplicate" onClick={handleBoardDuplicate}>
            <MenuItemIcon text="Duplicate board" icon={<DuplicateSvg size={12}/>} />
          </Menu.Item>
          {isDuplicateModalVisible &&
            <DuplicateBoardModal
              boardId={board.id}
              visible={isDuplicateModalVisible}
              onClose={() => setIsDuplicateModalVisible(false)}
            />
          }
        </>
      }
      {allowedToDelete &&
        <>
          <Menu.Divider />
          <Menu.Item key="delete" onClick={handleBoardDelete}>
            <Typography.Text>
              <MenuItemIcon text="Delete board" icon={<TrashSvg size={12}/>} red />
            </Typography.Text>
          </Menu.Item>
          {isDeleteModalVisible &&
            <DeleteBoardModal
              boardId={String(board.id)}
              boardName={board.name}
              visible={isDeleteModalVisible}
              onClose={() => setIsDeleteModalVisible(false)}
            />
          }
        </>
      }
    </Menu>
  );
};

export default BoardCardMenuItems;
