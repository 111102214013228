import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const CsvSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.2532 20.4357C63.9847 20.4335 61.8096 19.5313 60.2055 17.9272C58.6013 16.3231 57.6992 14.1481 57.6972 11.8796V0H19.4648C16.5034 0 13.6633 1.17644 11.5693 3.27047C9.47524 5.36448 8.29883 8.20452 8.29883 11.1659V78.4772C8.30323 81.4357 9.48158 84.2714 11.5751 86.3618C13.6687 88.4523 16.5063 89.6266 19.4648 89.6266H65.3569C66.8237 89.627 68.276 89.3386 69.6312 88.778C70.9864 88.217 72.2179 87.3946 73.2553 86.3577C74.2926 85.3208 75.1154 84.0896 75.6768 82.7344C76.2383 81.3797 76.527 79.9274 76.527 78.4606V20.4191L66.2532 20.4357Z" fill="#00C650"/>
        <path d="M76.5269 20.4357H66.253C63.9846 20.4335 61.8095 19.5313 60.2053 17.9272C58.6012 16.3231 57.6991 14.1481 57.697 11.8796V0L76.5269 20.4357Z" fill="#00C650"/>
        <path d="M84.6888 70.9543H32.805C29.1246 70.9543 26.1411 73.9377 26.1411 77.6182V93.0871C26.1411 96.7676 29.1246 99.751 32.805 99.751H84.6888C88.3693 99.751 91.3527 96.7676 91.3527 93.0871V77.6182C91.3527 73.9377 88.3693 70.9543 84.6888 70.9543Z" fill="#00C650"/>
        <path d="M42.9146 92.3725C41.2992 92.3725 40.1036 91.9879 39.3276 91.219C38.5588 90.4431 38.1743 89.2476 38.1743 87.6323V82.765C38.1743 81.1356 38.5588 79.9364 39.3276 79.1675C40.0965 78.3916 41.2851 78.0107 42.8934 78.0248H45.454C46.7801 78.0248 47.7924 78.2999 48.4907 78.8501C49.189 79.3933 49.5946 80.2538 49.7075 81.4319C49.7498 81.7281 49.6934 81.9538 49.5382 82.109C49.383 82.2642 49.1573 82.3418 48.861 82.3418C48.3249 82.3418 48.0146 82.0455 47.9299 81.453C47.8594 80.804 47.6337 80.3667 47.2527 80.141C46.8789 79.9153 46.2793 79.8024 45.454 79.8024H42.8934C42.1457 79.7953 41.5567 79.88 41.1264 80.0563C40.7032 80.2327 40.3998 80.5393 40.2164 80.9767C40.0401 81.407 39.9519 82.0032 39.9519 82.765V87.6323C39.9519 88.387 40.0401 88.9796 40.2164 89.4099C40.3998 89.8401 40.7067 90.1468 41.137 90.3302C41.5673 90.5065 42.1598 90.5949 42.9146 90.5949H45.454C46.2793 90.5949 46.8789 90.4821 47.2527 90.2563C47.6337 90.0236 47.8594 89.5862 47.9299 88.9443C48.0146 88.3518 48.3249 88.0555 48.861 88.0555C49.1573 88.0555 49.383 88.1331 49.5382 88.2883C49.6934 88.4435 49.7498 88.6692 49.7075 88.9655C49.5946 90.1505 49.189 91.0144 48.4907 91.5576C47.7924 92.1007 46.7801 92.3725 45.454 92.3725H42.9146Z" fill="white"/>
        <path d="M57.0038 92.3726C55.7976 92.3726 54.8561 92.1291 54.1789 91.6423C53.5088 91.1556 53.0889 90.4046 52.9196 89.3888C52.8914 89.0784 52.9549 88.8457 53.1101 88.6905C53.2652 88.5353 53.491 88.4577 53.7872 88.4577C54.0835 88.4577 54.2988 88.5282 54.4329 88.6693C54.574 88.8104 54.669 89.0291 54.7184 89.3253C54.8242 89.812 55.0499 90.1469 55.3955 90.3303C55.7482 90.5066 56.2843 90.595 57.0038 90.595H60.0511C60.9611 90.595 61.5785 90.4328 61.903 90.1083C62.2345 89.7768 62.4001 89.1631 62.4001 88.2672C62.4001 87.3573 62.2308 86.7365 61.8922 86.405C61.5536 86.0664 60.9399 85.8971 60.0511 85.8971H57.4271C56.0939 85.8971 55.103 85.5726 54.454 84.9237C53.8121 84.2676 53.491 83.273 53.491 81.9398C53.491 80.6137 53.8084 79.6295 54.4432 78.9876C55.0852 78.3457 56.0727 78.0249 57.4059 78.0249H59.9242C61.0457 78.0249 61.9242 78.254 62.559 78.7125C63.2009 79.171 63.6063 79.873 63.7756 80.8183C63.8179 81.1216 63.7615 81.3544 63.6063 81.5166C63.4511 81.6718 63.2184 81.7494 62.908 81.7494C62.6258 81.7494 62.4142 81.6751 62.2731 81.527C62.132 81.3788 62.0403 81.1639 61.998 80.8818C61.8852 80.4656 61.6702 80.1834 61.3528 80.0353C61.0354 79.8801 60.559 79.8025 59.9242 79.8025H57.4059C56.5594 79.8025 55.9918 79.954 55.7026 80.2573C55.4134 80.5535 55.2686 81.1145 55.2686 81.9398C55.2686 82.7722 55.4167 83.3436 55.713 83.654C56.0092 83.9643 56.5806 84.1195 57.4271 84.1195H60.0511C61.4408 84.1195 62.4744 84.4581 63.1515 85.1353C63.8358 85.8125 64.1777 86.8564 64.1777 88.2672C64.1777 89.671 63.8391 90.7079 63.1619 91.378C62.4847 92.0411 61.4478 92.3726 60.0511 92.3726H57.0038Z" fill="white"/>
        <path d="M72.5764 92.3725C72.2096 92.3725 71.9203 92.1397 71.7087 91.6741L66.4817 79.2945C66.3759 79.0476 66.3477 78.8289 66.3971 78.6385C66.4465 78.441 66.6087 78.2787 66.8838 78.1517C67.4622 77.8625 67.8714 78.0107 68.1112 78.5961L73.0527 90.2986H72.439L77.2531 78.5961C77.4859 78.0177 77.895 77.8696 78.4805 78.1517C78.7274 78.2787 78.8793 78.441 78.9357 78.6385C78.9992 78.8289 78.9813 79.0476 78.8826 79.2945L73.6133 91.6741C73.3946 92.1397 73.0772 92.3725 72.661 92.3725H72.5764Z" fill="white"/>
        <path d="M57.6308 62.5726H27.1951C24.5603 62.5726 22.4192 59.9502 22.4192 56.722V34.6514C22.4192 31.4274 24.5603 28.8049 27.1951 28.8049H57.6308C60.2657 28.8049 62.4067 31.4274 62.4067 34.6514V56.722C62.4067 59.9502 60.2657 62.5726 57.6308 62.5726ZM27.1951 31.7926C25.9088 31.7926 24.859 33.0747 24.859 34.6514V56.722C24.859 58.2988 25.9088 59.5809 27.1951 59.5809H57.6308C58.9171 59.5809 59.9628 58.2988 59.9628 56.722V34.6514C59.9628 33.0747 58.9171 31.7926 57.6308 31.7926H27.1951Z" fill="#00C650"/>
        <path d="M61.1869 49.2822H23.6392V52.2739H61.1869V49.2822Z" fill="#00C650"/>
        <path d="M61.1869 39.0249H23.6392V42.0166H61.1869V39.0249Z" fill="#00C650"/>
        <path d="M51.5728 30.2988H48.5811V61.0748H51.5728V30.2988Z" fill="#00C650"/>
        <path d="M36.2449 30.2988H33.2532V61.0748H36.2449V30.2988Z" fill="#00C650"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default CsvSvg;
