import { FC } from "react";
import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import AnnotationsGeneric from "./Display/generic";
import styled from "@emotion/styled/macro";
import { dataInput } from "components/blocks/TaskTable/styled";
import { fieldDetailedWrapper } from "components/blocks/TaskFields/Components/styled";

const AnnotationsListWrapper = styled.div`
  ${dataInput};
  display: grid;
  justify-content: start;
  grid-auto-flow: column;
  grid-template-columns: min-content;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
`;

const AnnotationsDetailedWrapper = styled.div`
  ${fieldDetailedWrapper};
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
`;

const AnnotationsField: FC<TaskFieldPropsGeneric> = (props) => {
  const { component } = props;

  switch (component) {
    case TaskSourceComponent.Cell:
      return (
        <AnnotationsListWrapper>
          <AnnotationsGeneric cardSize={40} {...props} />
        </AnnotationsListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <AnnotationsDetailedWrapper>
          <AnnotationsGeneric cardSize={58} {...props} />
        </AnnotationsDetailedWrapper>
      );
    default:
      return null;
  }
};

export default AnnotationsField;
