import { PropertyValueType } from "lib/types/dataTypes";

enum TableActionColumns {
  addBtn = "add_btn",
}

export const TableElementType = { ...PropertyValueType, ...TableActionColumns };

export enum TaskPropertyClass {
  Default = "default",
  Custom = "custom",
  System = "system",
  Synced = "synced",
}
