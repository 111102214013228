import React, { FC, useContext } from "react";
import { BoardInterfaceExtended } from "lib/types/boardTypes";

interface BoardContextProps {
  board: BoardInterfaceExtended | null;
  loading?: boolean;
}

export interface BoardContextInterface {
  board: BoardInterfaceExtended;
}

const BoardContext = React.createContext<BoardContextProps>({
  board: null,
});

export const useBoardContext = () => useContext(BoardContext);

export const BoardProvider: FC<BoardContextProps> = (props) => {
  return (
    <BoardContext.Provider value={{ ...props }}>
      {props.children}
    </BoardContext.Provider>
  );
};

