import { FC, useCallback, useRef } from "react";
import { createReactEditorJS } from "react-editor-js";

import TasksService from "services/TasksService";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import { TaskDetailedHeading } from "pages/Task/TaskDetailed/styled";
import { EditorJsCore, EditorJsSaveFormat } from "components/blocks/EditorJS/lib";
import { EditorJSInitialState, EditorJsTaskDescriptionConfig } from "components/blocks/EditorJS/tools";

import { TaskDetailedDescriptionRTEContainer, TaskDetailedDescriptionRTEWrapper } from "./styled";
import "./styles.css";
import { EntityId } from "@reduxjs/toolkit";

interface TaskDetailedDescriptionRTEProps {
  id: EntityId;
  value: EditorJsSaveFormat | null;
}

const TaskDetailedDescriptionRTE: FC<TaskDetailedDescriptionRTEProps> = (props) => {
  const { id, value } = props;

  const ReactEditorJS = createReactEditorJS();
  const editorCore = useRef<EditorJsCore | null>(null);
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const initialData = value ?? EditorJSInitialState;

  const handleInitialize = useCallback((instance: EditorJsCore) => {
    editorCore.current = instance;
  }, []);

  const handleOnChange = useCallback(async () => {
    const savedData = await editorCore?.current?.save();

    if (id && savedData) {
      TasksService.modifyDescriptionBlock(id, savedData);
    }
  }, [id]);

  return (
    <TaskDetailedDescriptionRTEWrapper>
      <TaskDetailedHeading>Description</TaskDetailedHeading>
      <TaskDetailedDescriptionRTEContainer>
        <ReactEditorJS
          key={id}
          tools={EditorJsTaskDescriptionConfig}
          defaultValue={initialData}
          onChange={handleOnChange}
          onInitialize={handleInitialize}
          readOnly={!allowedToEdit}
        />
      </TaskDetailedDescriptionRTEContainer>
    </TaskDetailedDescriptionRTEWrapper>
  );
};

export default TaskDetailedDescriptionRTE;
