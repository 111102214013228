import { EntityId } from "lib/types/entity";
import { JSendResponse } from "lib/types/jsend";
import HttpService from "./HttpService";

class PublicService extends HttpService {
  constructor() {
    super("public");
  }

  formFetch = async (id: EntityId) => {
    const data = await this._get({
      additionalUrl: `/forms/${id}`,
    });

    return data;
  };

  formSubmit = async (id: EntityId, payload: any) => {
    const data: JSendResponse = await this._post({
      additionalUrl: `/forms/${id}`,
      body: payload,
    });

    return data;
  };
}

export default new PublicService();
