import { errorType } from "../types/errorTypes";

export const getErrorMessage = (e: unknown): string => {
  const error = e as errorType;

  if (error.data?.length) {
    return error?.data[0]?.messages[0].message ||
      error?.data[0]?.messages[0].id;
  } else {
    return error.error;
  }
};