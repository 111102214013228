import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Image, Tooltip } from "antd";
import { useBoardContext } from "lib/contexts/BoardContext";
import { useTaskContext } from "lib/contexts/TaskContext";
import { getTaskDetailModelUri } from "lib/helpers/navigationUtils";
import { DisplayView, TaskFieldSetterGetter, TaskSourceComponent } from "lib/types/applicationTypes";
import { AddViewpoint } from "pages/Viewer/ViewerTasks/TaskItem/styled";
import ViewpointCardMenu from "components/blocks/ViewpointCard/ViewpointCardMenu";
import { ViewpointType } from "lib/types/tasksTypes";
import { ViewpointLikeCardContentContainer, ViewpointLikeCardOuterContainer } from "components/blocks/ViewpointLikeCard/styled";
import { ViewpointLikeCardImage } from "components/blocks/ViewpointLikeCard/styled";
import GeometrySvg from "components/icons/resizable/geomerty";
import { ViewpointContextMenuItems } from "components/blocks/ViewpointCard/ViewpointCardMenu/ViewpointCardMenuItems/lib";

interface ViewpointsCellProps extends TaskFieldSetterGetter {
  displayView: DisplayView;
  component: TaskSourceComponent;
}

const cardSize = 36;

const ViewpointsCell: FC<ViewpointsCellProps> = (props) => {
  const { value, displayView } = props;

  const [displayValue, setDisplayValue] = useState(value);
  const { task } = useTaskContext();
  const { board } = useBoardContext();
  const path = getTaskDetailModelUri(String(board?.workspace.uid), String(board?.uid), String(task?.uid || props.taskId));

  useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  const renderComponent = () => {
    switch (displayView) {
      case DisplayView.Model:
        return (
          <>
            {displayValue?.map((viewpoint: ViewpointType, index: number) => (
              <ViewpointLikeCardOuterContainer cardSize={cardSize} key={index} bgImageUrl={viewpoint.picture?.url || process.env.PUBLIC_URL + "/icons/geometry.svg"}>
                <ViewpointCardMenu
                  viewpoint={viewpoint}
                  menuItems={[
                    ViewpointContextMenuItems.OpenImagePreview,
                    ViewpointContextMenuItems.CopyLink,
                    ViewpointContextMenuItems.DownloadXml,
                    ViewpointContextMenuItems.Delete,
                  ]}
                />
              </ViewpointLikeCardOuterContainer>
            ))}
            <Tooltip title="Add a viewpoint">
              <AddViewpoint cardSize={cardSize}>
                <img src={process.env.PUBLIC_URL + "/icons/add.svg"} alt="" />
              </AddViewpoint>
            </Tooltip>
          </>
        );
      default:
        return (
          <Image.PreviewGroup>
            {displayValue?.map((viewpoint: ViewpointType, index: number) => (
              <ViewpointLikeCardOuterContainer cardSize={cardSize} key={viewpoint.id} geometry nonClickable={!(viewpoint.picture?.url)}>
                {viewpoint.picture?.url && (
                  <ViewpointLikeCardImage
                    cardSize={cardSize}
                    preview={{ src: viewpoint.picture?.url }}
                    src={viewpoint.picture?.formats.thumbnail.url || viewpoint.picture?.url}
                  />
                )}
                {!viewpoint.picture?.url && (
                  <ViewpointLikeCardContentContainer>
                    <GeometrySvg size={14} />
                  </ViewpointLikeCardContentContainer>
                )}
                <ViewpointCardMenu
                  viewpoint={viewpoint}
                  menuItems={[
                    ViewpointContextMenuItems.OpenInViewer,
                    ViewpointContextMenuItems.CopyLink,
                    ViewpointContextMenuItems.DownloadXml,
                    ViewpointContextMenuItems.Delete,
                  ]}
                />
              </ViewpointLikeCardOuterContainer>
            ))}
          </Image.PreviewGroup>
        );
    }
  };

  return (
    <>
      {!!displayValue?.length &&
        renderComponent()
      }
      <Link to={path} target="_blank" rel="noopener noreferrer">
        <Tooltip title="Open in viewer">
          <AddViewpoint cardSize={cardSize}>
            <img src={process.env.PUBLIC_URL + "/icons/model.svg"} alt="" />
          </AddViewpoint>
        </Tooltip>
      </Link>
    </>
  );
};

export default ViewpointsCell;
