import { FC } from "react";
import IconSvgWrapper from "components/icons/IconSvgWrapper";
import { IconProps } from "components/icons/lib";

const CrossSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M0.304718 0.538902C0.518233 0.450517 0.700473 0.486856 0.8516 0.64833L1.86722 1.65625C2.4245 1.13018 3.06118 0.72265 3.77734 0.433579C4.49349 0.144535 5.2344 0 6.00003 0C6.8125 0 7.58852 0.158929 8.32809 0.476595C9.06772 0.794288 9.70575 1.2213 10.2422 1.75777C10.7786 2.29429 11.2058 2.93225 11.5234 3.67184C11.8411 4.41148 12 5.18741 12 6C12 6.81235 11.8411 7.58847 11.5234 8.32808C11.2056 9.06775 10.7786 9.70577 10.2422 10.2422C9.70573 10.7785 9.0677 11.2056 8.32807 11.5234C7.58849 11.8411 6.81247 12 6 12C5.10424 12 4.25244 11.8112 3.44528 11.4336C2.63807 11.0561 1.95056 10.5235 1.38272 9.83591C1.34113 9.78384 1.32159 9.72522 1.32427 9.66012C1.32671 9.59505 1.35161 9.54167 1.39845 9.49999L2.46858 8.42189C2.52607 8.37507 2.59097 8.35167 2.66392 8.35167C2.74725 8.36207 2.80709 8.39343 2.84359 8.4455C3.2238 8.9404 3.68994 9.32311 4.24205 9.59415C4.79405 9.86489 5.37998 10.0002 5.99978 10.0002C6.54136 10.0002 7.0583 9.89474 7.55049 9.68377C8.04272 9.4729 8.46855 9.18774 8.82786 8.82835C9.18714 8.46892 9.47229 8.04325 9.68326 7.55095C9.89418 7.05876 9.99966 6.54194 9.99966 6.00022C9.99966 5.45855 9.8941 4.94149 9.68326 4.4494C9.47237 3.95727 9.18725 3.53143 8.82786 3.17212C8.46844 2.81272 8.04272 2.52756 7.55049 2.31659C7.05841 2.1057 6.54136 2.00018 5.99978 2.00018C4.95289 2.00018 4.04399 2.35698 3.27314 3.07054L4.3515 4.14851C4.51303 4.30479 4.54936 4.48451 4.46098 4.68761C4.3723 4.89595 4.21865 5.0001 3.9998 5.0001H0.499872C0.364452 5.0001 0.24731 4.95065 0.148309 4.85168C0.0494458 4.75273 -1.64258e-08 4.63556 -1.64258e-08 4.50014V1.00006C5.47101e-05 0.781373 0.101354 0.627725 0.304718 0.538902Z" />
    </IconSvgWrapper>
  );
};

export default CrossSvg;
