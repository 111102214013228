import { FC, useEffect, useRef, useState } from "react";
import { Dropdown, Tooltip } from "antd";
import { groupBy, uniqueId } from "lodash";
import OutsideClickHandler from "react-outside-click-handler";
import { BaseEmoji, Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";

import { useAppSelector } from "app/store";
import { getUserFullName } from "lib/helpers/userUtils";
import { CommentInterface, CommentReactionInterface } from "lib/types/types";
import TasksService from "services/TasksService";
import { useTaskContext } from "lib/contexts/TaskContext";
import { PickerWrapper, ReactionsWrapper, ReactionsButton, EmojiWrapper } from "./styled";

interface TaskCommentReactionsProps {
  comment: CommentInterface;
}

const TaskCommentReactions: FC<TaskCommentReactionsProps> = (props) => {
  const { comment } = props;
  const user = useAppSelector(state => state.currentUserReducer.user);
  const { task } = useTaskContext();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [commentReactions, setCommentReactions] = useState<Array<CommentReactionInterface>>(comment.reactions || []);
  useEffect(() => {
    setCommentReactions(comment.reactions || []);
  }, [comment.reactions]);

  const reactions = groupBy(commentReactions, reaction => reaction.reaction);

  const setLocalReaction = (emoji: string) => {
    if (user) {
      const reaction: CommentReactionInterface = {
        id: uniqueId(),
        reaction: emoji,
        user: user,
      };

      const prevState = commentReactions.filter(commentReaction => String(commentReaction.user.id) !== String(user.id));
      const nextState = [...prevState, reaction];

      setCommentReactions(nextState);
    }
  };

  const unsetLocalReaction = () => {
    if (user) {
      const nextState = commentReactions.filter(commentReaction => String(commentReaction.user.id) !== String(user.id));
      setCommentReactions(nextState);
    }
  };

  const handleCounterOnClick = (emoji: string, hasCurrentUser: boolean) => {
    if (hasCurrentUser) {
      unsetLocalReaction();
      TasksService.unreactComment(String(task?.id), String(comment.id));
    } else {
      setLocalReaction(emoji);
      TasksService.reactComment(String(task?.id), String(comment.id), emoji);
    }
  };

  const handlePickerOnSelect = (emoji: BaseEmoji) => {
    setShowEmojiPicker(!showEmojiPicker);
    setLocalReaction(emoji.native);
    TasksService.reactComment(String(task?.id), String(comment.id), emoji.native);
  };

  const handleOnOutsideClick = (event: MouseEvent) => {
    const buttonElement = buttonRef.current?.contains(event.target as HTMLElement);
    if (!buttonElement) {
      setShowEmojiPicker(false);
    }
  };

  const ReactionsGroup = () => {
    return Object.keys(reactions).map(reaction => {
      const users = reactions[reaction].map(r => getUserFullName(r.user)).join(", ");
      const count = reactions[reaction].length;
      const hasCurrentUser = reactions[reaction].findIndex(r => String(r.user.id) === String(user?.id)) !== -1;
      const styles = hasCurrentUser ? { purple: true } : {};

      return (
        <Tooltip title={users} key={reaction}>
          <ReactionsButton
            {...styles}
            onClick={() => handleCounterOnClick(reaction, hasCurrentUser)}
            active={hasCurrentUser}
            style={{ marginRight: 5 }}
          >
            <EmojiWrapper>{reaction}</EmojiWrapper>
            {count}
          </ReactionsButton>
        </Tooltip>
      );
    });
  };

  const Overlay = (
    <OutsideClickHandler onOutsideClick={handleOnOutsideClick}>
      <PickerWrapper>
        <Picker
          color="#ccc"
          showPreview={false}
          showSkinTones={false}
          onSelect={handlePickerOnSelect}
        />
      </PickerWrapper>
    </OutsideClickHandler>
  );

  return (
    <ReactionsWrapper>
      {ReactionsGroup()}
      <Dropdown
        overlay={Overlay}
        trigger={["click"]}
        open={showEmojiPicker}
        destroyPopupOnHide
      >
        <ReactionsButton
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          ref={buttonRef}
        >
          <img src={process.env.PUBLIC_URL + "/icons/add-reaction.svg"} alt="reaction" />
        </ReactionsButton>
      </Dropdown>
    </ReactionsWrapper>
  );
};

export default TaskCommentReactions;
