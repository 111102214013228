import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import { FC } from "react";
import PercentGeneric from "./Display/generic";

import { PercentListWrapper, PercentDetailedWrapper } from "./Display/styled";

interface PercentFieldProps extends TaskFieldPropsGeneric {
  precision: number;
}

const PercentField: FC<PercentFieldProps> = (props) => {
  const { component } = props;

  switch (component) {
    case TaskSourceComponent.Card:
      return null;
    case TaskSourceComponent.Cell:
      return (
        <PercentListWrapper>
          <PercentGeneric {...props} />
        </PercentListWrapper>
      );

    case TaskSourceComponent.Detailed:
      return (
        <PercentDetailedWrapper>
          <PercentGeneric {...props} />
        </PercentDetailedWrapper>
      );
    default:
      return null;
  }
};

export default PercentField;
