import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FileContainerInterface } from "lib/types/fileContainer";
import { EntityId } from "lib/types/entity";
import { MultiRelationalEntityFilter } from "lib/types/backend";
import { doesMatchFilter } from "lib/helpers/MultiRelationalEntity";

interface InitialStateType {
  collection: Array<FileContainerInterface>;
  current: FileContainerInterface | null;
  filter: MultiRelationalEntityFilter | null;
}

const initialState: InitialStateType = {
  collection: [],
  current: null,
  filter: null,
};

export const fileContainerSlice = createSlice({
  name: "file-containers",
  initialState: initialState,
  reducers: {
    setFileContainerCollection: (state, action: PayloadAction<{ data: Array<FileContainerInterface>, filter: MultiRelationalEntityFilter | null }>) => {
      state.filter = action.payload.filter;
      state.collection = action.payload.data;
    },
    addFileContainerToCollection: (state, action: PayloadAction<FileContainerInterface>) => {
      if (doesMatchFilter(state.filter, action.payload)) {
        state.collection.push(action.payload);
      }
    },
    updateFileContainerInCollection: (state, action: PayloadAction<FileContainerInterface>) => {
      const findIndex = state.collection.findIndex(fileContainer => fileContainer.id === action.payload.id);
      if (findIndex !== -1) {
        state.collection[findIndex] = action.payload;
      }
    },
    deleteFileContainerFromCollection: (state, action: PayloadAction<EntityId>) => {
      const findIndex = state.collection.findIndex(fileContainer => String(fileContainer.id) === String(action.payload));
      if (findIndex !== -1) {
        state.collection.splice(findIndex, 1);
      }
    },
    setFileContainerDetailed: (state, action: PayloadAction<FileContainerInterface | null>) => {
      state.current = action.payload;
    },
    updateFileContainerDetailed: (state, action: PayloadAction<FileContainerInterface>) => {
      if (action.payload.id === state.current?.id) {
        state.current = action.payload;
      }
    },
    performFileContainerOptimisticUpdate: (state, action: PayloadAction<{ id?: EntityId; update: Partial<FileContainerInterface>; updateDetailed?: boolean }>) => {
      const findIndex = state.collection.findIndex(item => String(item.id) === String(action.payload.id));
      if (findIndex !== -1) {
        const data = action.payload.update;
        for (const [code, value] of Object.entries(data)) {
          state.collection[findIndex][code as keyof FileContainerInterface] = value as never;
        }
      }

      if (action.payload.updateDetailed && state.current && String(action.payload.id) === String(state.current?.id)) {
        const data = action.payload.update;
        for (const [code, value] of Object.entries(data)) {
          state.current[code as keyof FileContainerInterface] = value as never;
        }
      }
    },
  }
});

export const {
  setFileContainerCollection,
  addFileContainerToCollection,
  updateFileContainerInCollection,
  deleteFileContainerFromCollection,
  setFileContainerDetailed,
  updateFileContainerDetailed,
  performFileContainerOptimisticUpdate,
} = fileContainerSlice.actions;

export default fileContainerSlice.reducer;
