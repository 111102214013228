import { FC } from "react";
import IconSvgWrapper from "./IconSvgWrapper";
import { IconProps } from "./lib";

const ImportSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M3.79995 0C4.29701 0 4.69995 0.402944 4.69995 0.9C4.69995 1.39706 4.29701 1.8 3.79995 1.8L1.8 1.8L1.8 10.2H3.79995C4.29701 10.2 4.69995 10.6029 4.69995 11.1C4.69995 11.597 4.29701 12 3.79995 12H0.909474C0.90632 12 0.903162 12 0.9 12C0.66698 12 0.454646 11.9114 0.294812 11.7661C0.272502 11.7459 0.251216 11.7245 0.231038 11.7021C0.0873858 11.5426 -4.8548e-05 11.3315 -4.8548e-05 11.1C-4.8548e-05 11.0968 -3.25263e-05 11.0936 2.80139e-07 11.0905L2.80139e-07 0.909473C-3.25263e-05 0.90632 -4.8548e-05 0.903162 -4.8548e-05 0.9C-4.8548e-05 0.402944 0.402895 0 0.899951 0L3.79995 0Z"/>
      <path d="M5.62798 8.93464C5.97946 9.28611 6.5493 9.28611 6.90078 8.93464C7.25225 8.58317 7.25225 8.01332 6.90078 7.66185L6.13889 6.89997H10.9999C11.497 6.89997 11.8999 6.49703 11.8999 5.99997C11.8999 5.50291 11.497 5.09997 10.9999 5.09997L6.21004 5.09997L6.90082 4.4092C7.25229 4.05773 7.25229 3.48788 6.90082 3.13641C6.54934 2.78493 5.9795 2.78493 5.62802 3.13641L3.37246 5.39197C3.37004 5.39434 3.36764 5.39671 3.36524 5.39911C3.01377 5.75058 3.01377 6.32043 3.36524 6.6719L5.62798 8.93464Z"/>
    </IconSvgWrapper>
  );
};

export default ImportSvg;
