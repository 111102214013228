import { setBoard } from "app/slices/currentBoardSlice";
import { useAppDispatch, useAppSelector } from "app/store";

import BoardHeader from "layout/HeaderCustom/BoardHeader";
import HeaderPortal from "layout/HeaderCustom/HeaderPortal";
import { BoardProvider } from "lib/contexts/BoardContext";
import { MemberPermissionProvider } from "lib/contexts/MemberPermissionContext";
import useGoHome from "lib/customHooks/routeHooks/useGoHome";
import useAsyncData from "lib/customHooks/useAsyncData";
import { useDocumentTitle } from "lib/customHooks/useDocumentTitle";
import useWSDeleteBoard from "lib/customHooks/websocketBoard/useWSDeleteBoard";
import useWSUpdateCurrentBoard from "lib/customHooks/websocketBoard/useWSUpdateCurrentBoard";
import { useCallback, useEffect } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import BoardService from "services/BoardService";
import BoardNotFound from "./BoardNotFound";

import BoardSettings from "./BoardSettings/BoardSettings";
import BoardView from "./BoardView";
import useAppDataLoaded from "lib/customHooks/useAppDataLoaded";

const BoardPage = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const board = useAppSelector(state => state.currentBoardReducer.board);
  const { isLoaded: isAppDataLoaded } = useAppDataLoaded();

  useWSUpdateCurrentBoard();
  useWSDeleteBoard();
  useDocumentTitle(board?.name);

  const fetch = useCallback(() => BoardService.getById(id), [id]);

  const { data, isLoaded, isLoading } = useAsyncData({
    fetchFn: fetch,
    loadOnMount: isAppDataLoaded,
  });

  useEffect(() => {
    if (data) {
      dispatch(setBoard(data));
    }
    return () => {
      dispatch(setBoard(null));
    };
  }, [data, dispatch]);

  useGoHome(isLoaded && !board);

  if (!board) {
    return (
      <>
        {isLoaded &&
          <BoardNotFound />
        }
      </>
    );
  }

  return (
    <BoardProvider board={board} loading={isLoading}>
      <MemberPermissionProvider workspaceId={String(board?.workspace.id)}>
        <HeaderPortal>
          <BoardHeader
            workspaceId={board.workspace.id}
            isLoading={isLoading}
            showSavingState
          />
        </HeaderPortal>
        <Switch>
          <Route exact path="/board/:id/settings">
            <BoardSettings />
          </Route>
          <Route path="/board/:id/:view">
            <BoardView />
          </Route>
          <Redirect from="/board/:id" to="/board/:id/list" />
        </Switch>
      </MemberPermissionProvider>
    </BoardProvider>
  );
};

export default BoardPage;
