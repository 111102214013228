import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const NoFillSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M49.9999 91.6668C73.0118 91.6668 91.6666 73.012 91.6666 50.0002C91.6666 26.9883 73.0118 8.3335 49.9999 8.3335C26.9881 8.3335 8.33325 26.9883 8.33325 50.0002C8.33325 73.012 26.9881 91.6668 49.9999 91.6668ZM49.9999 83.3335C68.4094 83.3335 83.3332 68.4097 83.3332 50.0002C83.3332 31.5907 68.4094 16.6668 49.9999 16.6668C31.5904 16.6668 16.6666 31.5907 16.6666 50.0002C16.6666 68.4097 31.5904 83.3335 49.9999 83.3335Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default NoFillSvg;
