import styled from "@emotion/styled/macro";

import {
  ThContent as ThContentInitial,
  Th as ThInitial,
} from "../../styled";

export const SvgAddWrapper = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: -1px;
  padding-left: 10px;
  img {
    height: 12px;
    width: 12px;
  }
  svg {
    stroke: #9e9e9e;
  }
`;

export const SvgSelectWrapper = styled.span`
  display: flex;
  margin-right: 6px;
  img {
    height: 12px;
    width: 12px;
  }
`;

export const ThContent = styled(ThContentInitial)`
  display: flex;
  align-items: center;
`;

export const Th = styled(ThInitial)`
  display: inline-block;
  &:hover {
    border: 1px solid #e4e4e4;
    &:first-of-type {
      border-left: 0;
    }
  }
`;

export const TableHeaderTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MenuDivider = styled.div`
  box-sizing: border-box;
  margin: 3px 0;
  padding: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
`;
