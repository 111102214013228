import React, { FC } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Modal } from "antd";
import { useProjectContext } from "lib/contexts/ProjectContext";
import { ModalComponentProps } from "lib/types/components";
import ProjectCollaborators from "pages/Project/ProjectSettings/ProjectCollaborators/ProjectCollaborators";
import { commonModalProps } from "../ModalDialog/lib";

const InviteProjectModal: FC<ModalComponentProps> = (props) => {
  const { visible, onClose } = props;
  const { workspace } = useProjectContext();

  const handleOnClose: React.MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <Modal
      {...commonModalProps}
      title={`Invite people to ${workspace?.name}`}
      visible={visible}
      onCancel={handleOnClose}
      footer={null}
      width={600}
      bodyStyle={{ maxHeight: "70vh", padding: 0 }}
      centered
    >
      <Scrollbars autoHide style={{ height: "70vh" }}>
        <div style={{ padding: "14px 24px" }}>
          <div>
            <ProjectCollaborators modal />
          </div>
        </div>
      </Scrollbars>
    </Modal>
  );
};

export default InviteProjectModal;
