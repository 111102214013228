import styled from "@emotion/styled/macro";
import { squareWrapperCss, styledInputCss } from "styles/common-css";
import { dataInput } from "components/blocks/TaskTable/styled";
import { fieldDetailedInput } from "../../styled";
import { Input } from "antd";
import { TaskTitleSyncedIcon } from "components/blocks/TaskTable/components/TaskTitle/styled";
import { SyncedFieldTooltipIcon } from "components/blocks/SyncedFieldTooltip/styled";

export const UrlOpenButton = styled.div`
  ${squareWrapperCss};
  margin-left: 6px;
  opacity: 0;
`;

export const UrlListWrapper = styled.div`
  ${dataInput};
  &:hover {
    ${UrlOpenButton} {
      opacity: 1;
    }
    ${TaskTitleSyncedIcon} {
      display: block;
    }
  }
  .ant-input-disabled {
    border-color: transparent;
    background-color: transparent;
    color: black;
    cursor: default;
  }
`;

export const UrlDetailedWrapper = styled.div`
  ${fieldDetailedInput};
  display: flex;
  align-items: center;
  &:hover {
    ${UrlOpenButton} {
      opacity: 1;
    }
  }
  .ant-input-disabled {
    border-color: transparent;
    background-color: transparent;
    color: black;
    cursor: default;
  }
`;

export const UrlInput = styled(Input) <{ isValidUrl?: boolean }>`
  ${styledInputCss};
  color: ${({ isValidUrl }) => isValidUrl ? "#40a9ff" : "black"};
  &:hover {
    ${SyncedFieldTooltipIcon} {
      display: block;
    }
  }
  &:disabled {
    background-color: transparent;
    &:hover {
      background-color: white;
    }
  }
`;
