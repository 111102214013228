import { FC } from "react";
import IconSvgWrapper from "components/icons/IconSvgWrapper";
import { DefaultIconColor } from "components/icons/lib";

interface CheckIconProps {
  isCompleted?: boolean;
}

const CheckIcon: FC<CheckIconProps> = ({ isCompleted }) => {
  const strokeColor = isCompleted ? "#6fe4c9" : DefaultIconColor;
  const bgColor = isCompleted ? "#e8fefa" : "transparent";

  return (
    <IconSvgWrapper size={17}>
      <circle fill={bgColor} cx="8.5" cy="8.5" r="8.5" />
      <path fill={strokeColor} d="M12.2574 5.80289C12.5168 6.06229 12.5168 6.48278 12.2574 6.74205L7.80247 11.1971C7.54308 11.4564 7.12272 11.4564 6.86332 11.1971L4.7426 9.07626C4.4832 8.81699 4.4832 8.3965 4.7426 8.13723C5.00187 7.87783 5.42236 7.87783 5.68163 8.13723L7.33283 9.78844L11.3182 5.80289C11.5776 5.54362 11.9981 5.54362 12.2574 5.80289V5.80289ZM17 8.5C17 13.1984 13.1977 17 8.5 17C3.80163 17 0 13.1977 0 8.5C0 3.80163 3.80228 0 8.5 0C13.1984 0 17 3.80228 17 8.5ZM15.6719 8.5C15.6719 4.53573 12.4638 1.32812 8.5 1.32812C4.53573 1.32812 1.32812 4.53625 1.32812 8.5C1.32812 12.4643 4.53625 15.6719 8.5 15.6719C12.4643 15.6719 15.6719 12.4638 15.6719 8.5Z" />
    </IconSvgWrapper >
  );
};

export default CheckIcon;
