import { FC } from "react";
import IconSvgWrapper from "./IconSvgWrapper";
import { IconProps } from "./lib";

const LinkSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.7773 1.5633L3.64857 1.56323C3.47362 1.56323 3.30902 1.6318 3.18471 1.75611C3.06026 1.88056 2.99183 2.04516 2.99176 2.22004L2.99183 2.77654C2.99176 2.95128 3.06026 3.11609 3.18464 3.24047C3.30895 3.36478 3.47769 3.43722 3.65243 3.43715L7.23174 3.44108L1.55217 9.12065C1.2961 9.37672 1.29424 9.77953 1.55038 10.0357L1.9438 10.4291C2.19995 10.6853 2.62297 10.7036 2.87904 10.4475L8.57889 4.74767L8.57261 8.35719C8.57268 8.53207 8.63801 8.6937 8.76239 8.81808C8.88663 8.94232 9.04992 9.00931 9.22473 9.00931L9.78034 9.00848C9.95529 9.00848 10.1195 8.93963 10.2439 8.81518C10.3683 8.69087 10.4365 8.526 10.4365 8.35105V2.22239C10.4365 2.04696 10.3676 1.8816 10.2427 1.75743C10.118 1.63222 9.95266 1.56316 9.7773 1.5633Z" />
    </IconSvgWrapper>
  );
};

export default LinkSvg;
