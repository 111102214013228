import { FC } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import moment from "moment";
import { isEmpty } from "lodash";

import { getUserFullName } from "lib/helpers/userUtils";
import { NotificationInterface, NotificationStatus } from "lib/types/notificationTypes";
import AvatarCustom from "components/ui/AvatarCustom";
import { NotificationItemContent, NotificationItemDetails } from "pages/Notifications/NotificationItem/styled";
import {
  NotificationsButtonsWrapper,
  NotificationsCreated, NotificationsEventWrapper, NotificationsEventName,
  NotificationsUnreadCircle,
  NotificationsUserName, NotificationsUserAvatarWrapper,
  NotificationsCreatedWrapper
} from "pages/Notifications/styled";
import { RenderNotificationMessage } from "../lib";
import { RenderNotificationButtons } from "../common";
import { DateFormats } from "lib/theme/lib";
import { AnonymousUser } from "components/blocks/Automations/lib";
import LinkSvg from "components/icons/link";

interface NotificationItemProps {
  notification: NotificationInterface;
}

const NotificationItem: FC<NotificationItemProps> = (props) => {
  const { notification } = props;

  const { id, status, initiator, eventName, created_at } = notification;
  const user = !isEmpty(initiator) ? initiator : AnonymousUser;
  const customLink = notification.meta?.url;

  return (
    <NotificationsEventWrapper>
      <NotificationItemContent>
        <NotificationsUserAvatarWrapper>
          <AvatarCustom user={user} tooltip />
        </NotificationsUserAvatarWrapper>
        <div>
          <NotificationItemDetails>
            <NotificationsUserName>{getUserFullName(user)}</NotificationsUserName>
            <NotificationsEventName>{RenderNotificationMessage(eventName, notification.contentType)}</NotificationsEventName>
            {notification.meta?.url &&
              <Tooltip title="Go to mention">
                <Link to={customLink} style={{ display: "inline-block", marginLeft: 5 }}>
                  <LinkSvg />
                </Link>
              </Tooltip>
            }
          </NotificationItemDetails>
          <NotificationsCreatedWrapper>
            <Tooltip title={moment(created_at).format(DateFormats.HumanFull)}>
              <NotificationsCreated>
                {moment(created_at).format(DateFormats.Friendly)}
              </NotificationsCreated>
            </Tooltip>
          </NotificationsCreatedWrapper>
        </div>
      </NotificationItemContent>
      {status === NotificationStatus.New && (
        <NotificationsUnreadCircle />
      )}
      <NotificationsButtonsWrapper>
        {RenderNotificationButtons(id, status)}
      </NotificationsButtonsWrapper>
    </NotificationsEventWrapper>
  );
};

export default NotificationItem;
