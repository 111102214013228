import { DisplayView } from "lib/types/applicationTypes";
import { EntityId } from "lib/types/entity";
import { BoardViewInterface } from "lib/types/boardTypes";
import configuration from "lib/configs/configuration";
import { OAuthProviders } from "lib/types/types";
import LoadingMessage from "components/blocks/LoadingMessage";
import { appendSearchParams } from "./urlUtils";
import { SecondaryPanelCodes } from "pages/FileContainer/FileContainerRevisionDetailed/lib";

export const getTaskDetailUri = (taskId: EntityId) => {
  return `/r/${taskId}`;
};

export const getFileDetailUri = (fileId: EntityId) => {
  return `/f/${fileId}`;
};

export const getFileRevisionUri = (fileId: EntityId, revisionId: EntityId, params?: Record<string, string>, panel?: SecondaryPanelCodes) => {
  const searchParams = appendSearchParams(params);
  const panelString = panel ? `/${panel}` : "";
  return `/f/${fileId}/${revisionId}${panelString}${searchParams}`;
};

export const getFileRevisionAnnotationUri = (fileId: EntityId, revisionId: EntityId, annotationId: string) => {
  return `/f/${fileId}/${revisionId}?a=${annotationId}`;
};

export const getTaskDetailBoardView = (boardId: EntityId, view: DisplayView | string, taskId: EntityId) => {
  return `/board/${boardId}/${view}/${taskId}`;
};

export const getBoardUri = (boardId: EntityId) => {
  return `/board/${boardId}`;
};

export const getBoardViewUri = (boardId: EntityId, view: DisplayView | string, customView?: BoardViewInterface) => {
  if (view === customView?.view) {
    return `/board/${boardId}/${customView.uid}`;
  } else {
    return `/board/${boardId}/${view}`;
  }
};

export const getBoardSettingsUri = (boardId: EntityId) => {
  return `/board/${boardId}/settings`;
};

export const getProjectUri = (projectId: EntityId) => {
  return `/project/${projectId}`;
};

export const getProjectTasksUri = (projectId: EntityId) => {
  return `/project/${projectId}/r`;
};

export const getProjectFilesUri = (projectId: EntityId) => {
  return `/project/${projectId}/f`;
};

export const getProjectFileDetailedUri = (projectId: EntityId, fileId: EntityId) => {
  return `/project/${projectId}/f/${fileId}`;
};

export const getProjectRecentTasksDetailedUri = (projectId: EntityId, taskId: EntityId) => {
  return `/project/${projectId}/${taskId}`;
};

export const getProjectTasksDetailedUri = (projectId: EntityId, taskId: EntityId) => {
  return `/project/${projectId}/r/${taskId}`;
};

export const getProjectSettingsUri = (projectId: EntityId) => {
  return `/project/${projectId}/settings`;
};

export const getDocumentDetailUri = (workspaceId: EntityId, documentId: string) => {
  return `/project/${workspaceId}/docs/${documentId}`;
};

export const getProjectFileContainerDetailUri = (workspaceId: EntityId, fileContainerId: string) => {
  return `/project/${workspaceId}/f/${fileContainerId}`;
};

export const getProjectPlansUri = (projectId: EntityId) => {
  return `/project/${projectId}/plans`;
};

export const getProjectModelsUri = (projectId: EntityId) => {
  return `/viewer/${projectId}/models`;
};

export const getProjectModelSettingsUri = (projectId: EntityId) => {
  return `/viewer/${projectId}/settings`;
};

export const getProjectModelTasksUri = (projectId: EntityId) => {
  return `/viewer/${projectId}/r`;
};

export const getProjectModelTaskDetailedUri = (projectId: EntityId, taskId: EntityId): string => {
  return `/viewer/${projectId}/r/${taskId}`;
};

export const getBoardModelUri = (projectId: EntityId, boardId: EntityId) => {
  return `/viewer/${projectId}/board/${boardId}`;
};

export const getTaskDetailModelUri = (workspaceId: EntityId, boardId: EntityId, taskId: EntityId, params?: Record<string, string>) => {
  const searchParams = appendSearchParams(params);
  return `/viewer/${workspaceId}/board/${boardId}/${taskId}${searchParams}`;
};

export const getReportingPageUri = (projectId: EntityId, boardId?: EntityId) => {
  return `/reporting/${projectId}/${boardId || ""}`;
};

export const getOrganizationUri = (uid: string) => {
  return `/admin/${uid}`;
};

export const getFormPublicUri = (id: EntityId) => {
  return `/form/${id}`;
};

export const extractFilterFromCtxParams: (param?: any) => { code: "id" | "uid", value: string | number } = (param) => {
  if (isNaN(param)) {
    return {
      code: "uid",
      value: param,
    };
  } else {
    return {
      code: "id",
      value: Number(param),
    };
  }
};

export const getOAuthLink = (provider: OAuthProviders | string) => {
  return `${configuration.backend.url}connect/${provider}`;
};

export const copyCurrentLink = () => {
  const target = window.location.pathname + window.location.search;
  return copyLink(target);
};

export const copyLink = (path: string) => {
  const link = window.location.origin + path;
  navigator.clipboard.writeText(link);
  LoadingMessage.complete("Link copied to your clipboard");
};
