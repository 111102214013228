import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const SubtasksSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path d="M22 5C23.6569 5 25 6.34315 25 8V22C25 23.6569 23.6569 25 22 25H8C6.34315 25 5 23.6569 5 22V8C5 6.34315 6.34315 5 8 5H22Z"/>
        <path d="M88 8C91.866 8 95 11.134 95 15C95 18.866 91.866 22 88 22H39C35.134 22 32 18.866 32 15C32 11.134 35.134 8 39 8H88Z"/>
        <path d="M25 43C25 41.3431 23.6569 40 22 40H8C6.34315 40 5 41.3431 5 43V57C5 58.6569 6.34315 60 8 60H22C23.6569 60 25 58.6569 25 57V43Z"/>
        <path d="M88 43C91.866 43 95 46.134 95 50C95 53.866 91.866 57 88 57H39C35.134 57 32 53.866 32 50C32 46.134 35.134 43 39 43H88Z"/>
        <path d="M25 78C25 76.3431 23.6569 75 22 75H8C6.34315 75 5 76.3431 5 78V92C5 93.6569 6.34315 95 8 95H22C23.6569 95 25 93.6569 25 92V78Z"/>
        <path d="M88 78C91.866 78 95 81.134 95 85C95 88.866 91.866 92 88 92H39C35.134 92 32 88.866 32 85C32 81.134 35.134 78 39 78H88Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default SubtasksSvg;
