import { FC } from "react";
import { Checkbox } from "antd";

import { ChecklistRow, ChecklistReorderContainer, ChecklistInput } from "../SubtaskItem/styled";

interface SubtaskInputProps {
  onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => Promise<void>;
  onBlur: (event: React.FocusEvent<HTMLTextAreaElement>) => Promise<void>;
  onPaste: (event: React.ClipboardEvent<HTMLTextAreaElement>) => Promise<void>;
}

const SubtaskInput: FC<SubtaskInputProps> = (props) => {

  return (
    <ChecklistRow>
      <ChecklistReorderContainer>
        <img src={process.env.PUBLIC_URL + "/icons/drag.svg"} alt="drag" style={{ marginRight: "7px" }} />
      </ChecklistReorderContainer>
      <Checkbox checked={false} />
      <ChecklistInput
        autoFocus
        size="small"
        placeholder="Enter title and press enter"
        bordered={false}
        isChecked={false}
        onKeyDown={props.onKeyDown}
        onBlur={props.onBlur}
        onPasteCapture={props.onPaste}
        autoSize={{ minRows: 1 }}
      />
    </ChecklistRow>
  );
};

export default SubtaskInput;
