import { Dropdown, Menu } from "antd";

import AddSvg from "components/ui/ButtonControls/icons/add";
import {
  ViewMenuAddView,
  ViewMenuDefaultTag,
  ViewMenuDefaultWrapper
} from "layout/HeaderCustom/BoardHeader/ViewMenu/styled";
import { HeaderAvatarUser, HeaderAvatarUserEmail } from "layout/HeaderCustom/HeaderAvatar/styled";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { getBoardViewUri } from "lib/helpers/navigationUtils";
import { DisplayView, DropdownTrigger } from "lib/types/applicationTypes";
import { MemberRoles } from "lib/types/types";
import { BoardDisplayViews } from "pages/Board/BoardSettings/BoardSettingsDisplayView/lib";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import ViewMenuItem from "./ViewMenuItem";
import { useAppDispatch } from "app/store";
import { resetAllFilersAndSort } from "app/slices/tasksFilterAndSortSlice";

interface ViewMenuProps {
  displayView: DisplayView;
  setIsAddViewModalVisible: (isVisible: boolean) => void;
  setDisplayView: (displayView: DisplayView) => void;
  trigger: Array<DropdownTrigger>;
}

const ViewMenu: FC<ViewMenuProps> = (props) => {
  const { displayView, setIsAddViewModalVisible, trigger, setDisplayView } = props;
  const { view } = useParams<{ id: string, view: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { board } = useBoardContext() as BoardContextInterface;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);

  const viewsFiltered = board?.views.filter(view => view.view === displayView);
  const defaultViewTitle = BoardDisplayViews.find(boardDisplayView => boardDisplayView.code === displayView)?.title;

  const handleSetDefaultViewOnClick = () => {
    const path = getBoardViewUri(board.uid, displayView);
    dispatch(resetAllFilersAndSort());
    history.push(path);
  };

  const handleSetViewOnClick = (viewUid: string) => {
    if (view === viewUid) {
      return;
    }

    const path = getBoardViewUri(board.uid, viewUid);
    history.push(path);
  };

  const handleAddViewOnClick: MenuClickEventHandler = (info) => {
    const { domEvent } = info;
    domEvent.stopPropagation();
    setDisplayView(displayView);
    setIsAddViewModalVisible(true);
  };

  const ViewMenuItems = (
    <Menu style={{ width: 170 }} selectedKeys={[view]}>
      <HeaderAvatarUser key="gm-quick-filter">
        <HeaderAvatarUserEmail>Views</HeaderAvatarUserEmail>
      </HeaderAvatarUser>
      <Menu.Item key={displayView} onClick={handleSetDefaultViewOnClick}>
        <ViewMenuDefaultWrapper>
          {defaultViewTitle}<ViewMenuDefaultTag>Default</ViewMenuDefaultTag>
        </ViewMenuDefaultWrapper>
      </Menu.Item>
      {viewsFiltered?.map(viewItem => (
        <Menu.Item key={viewItem.uid}>
          <ViewMenuItem view={viewItem} onClick={() => handleSetViewOnClick(viewItem.uid)} />
        </Menu.Item>
      ))}
      {allowedToEdit &&
        <>
          <Menu.Divider />
          <Menu.Item
            key="addBoardView"
            icon={<AddSvg />}
            onClick={handleAddViewOnClick}
          >
            <ViewMenuAddView>Add view</ViewMenuAddView>
          </Menu.Item>
        </>
      }
    </Menu>
  );

  return (
    <Dropdown
      overlay={ViewMenuItems}
      placement="bottom"
      destroyPopupOnHide
      trigger={trigger}
      overlayStyle={{ minWidth: 150 }}
    >
      {props.children}
    </Dropdown>
  );
};

export default ViewMenu;
