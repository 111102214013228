import { FC } from "react";
import { Form, Input } from "antd";
import { AuthName } from "pages/Auth/styled";

interface AuthFirstLastNameProps {
  autoFocus?: boolean;
}

const AuthFirstLastName: FC<AuthFirstLastNameProps> = (props) => {
  return (
    <AuthName>
      <Form.Item label="First name" name={["firstName"]} rules={[{ required: true, message: "" }]}>
        <Input autoFocus={props.autoFocus} id="given-name" name="given-name" autoComplete="given-name" />
      </Form.Item>
      <Form.Item label="Last name" name={["lastName"]} rules={[{ required: true, message: "" }]}>
        <Input id="family-name" name="family-name" autoComplete="family-name" />
      </Form.Item>
    </AuthName>
  );
};

export default AuthFirstLastName;
