import { FC } from "react";
import IconSvgWrapper from "./IconSvgWrapper";
import { IconProps } from "./lib";

const ExportSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M9.4009 3.13641C9.04943 2.78493 8.47958 2.78493 8.12811 3.13641C7.77664 3.48788 7.77664 4.05773 8.12811 4.4092L8.81892 5.10001H4.00004C3.50298 5.10001 3.10004 5.50295 3.10004 6.00001C3.10004 6.49707 3.50298 6.90001 4.00004 6.90001L8.88992 6.90001L8.12807 7.66186C7.7766 8.01333 7.7766 8.58318 8.12807 8.93465C8.47955 9.28612 9.04939 9.28612 9.40087 8.93465L11.6569 6.67861C11.6592 6.6764 11.6614 6.67418 11.6636 6.67194C12.0089 6.32665 12.015 5.77059 11.6819 5.41788C11.6759 5.41157 11.6698 5.40533 11.6636 5.39915C11.6637 5.39916 11.6636 5.39914 11.6636 5.39915C11.6636 5.39911 11.6635 5.39903 11.6635 5.39899L9.4009 3.13641Z"/>
      <path d="M3.79995 0C4.29701 0 4.69995 0.402944 4.69995 0.9C4.69995 1.39706 4.29701 1.8 3.79995 1.8L1.8 1.8L1.8 10.2H3.79995C4.29701 10.2 4.69995 10.6029 4.69995 11.1C4.69995 11.597 4.29701 12 3.79995 12H0.909474C0.90632 12 0.903162 12 0.9 12C0.66698 12 0.454646 11.9114 0.294812 11.7661C0.272502 11.7459 0.251216 11.7245 0.231038 11.7021C0.0873858 11.5426 -4.8548e-05 11.3315 -4.8548e-05 11.1C-4.8548e-05 11.0968 -3.25263e-05 11.0936 2.80139e-07 11.0905L2.80139e-07 0.909473C-3.25263e-05 0.90632 -4.8548e-05 0.903162 -4.8548e-05 0.9C-4.8548e-05 0.402944 0.402895 0 0.899951 0L3.79995 0Z"/>
    </IconSvgWrapper>
  );
};

export default ExportSvg;
