import { FC } from "react";

import { BoardViewAppliedColumn } from "lib/types/boardTypes";
import { Column } from "lib/types/tasksTypes";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import { useTaskFormContext } from "lib/contexts/TaskFormContext";
import FormFieldItem from "./FormFieldItem";
import {
  FormPublicFieldContainer,
  FormPublicFieldTitle, FormPublicFieldTitleAsterisk,
  FormPublicFieldTitleContainer
} from "pages/FormPublic/FormPublicField/styled";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

interface FormPublicFieldProps {
  column: Column & BoardViewAppliedColumn;
  customization?: EmotionJSX.Element | null;
}

const FormPublicField: FC<FormPublicFieldProps> = (props) => {
  const { column, customization } = props;
  const { form } = useTaskFormContext();

  const itemName = ([TaskPropertyClass.Custom, TaskPropertyClass.Synced].includes(column.class as TaskPropertyClass))
    ? ["customProperties", column.code]
    : column.code;

  return (
    <FormPublicFieldContainer>
      <FormPublicFieldTitleContainer>
        <FormPublicFieldTitle>{column.title}</FormPublicFieldTitle>
        {column.required &&
          <FormPublicFieldTitleAsterisk>*</FormPublicFieldTitleAsterisk>
        }
      </FormPublicFieldTitleContainer>
      <>
        {customization}
      </>
      <FormFieldItem
        column={column}
        members={form?.members}
        fieldName={itemName}
      />
    </FormPublicFieldContainer>
  );
};

export default FormPublicField;
