import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { ReactNode } from "react";

export enum Trigger {
  default = "default",
  toThisBoard = "To this board",
  completed = "Marked complete",
  assignees = "Assignees",
  dueDate = "Due date",
  status = "Status",
  comments = "Comment",
  dueDateIsApproaching = "Due date is approaching",
  taskIsOverdue = "Task is overdue",
}

export enum Action {
  default = "default",
  completed = "Completion status",
  assignees = "Assignees",
  dueDate = "Due date",
  status = "Status",
  collaborators = "Followers",
  comments = "Comment",
  createProjectTaskItem = "Create Project Task Item",
  createManpowerLog = "Create Manpower Log",
  createPunchItem = "Create Punch Item",
  createRFI = "Create RFI",
}

interface TAutomationField {
  component?: ReactNode | EmotionJSX.Element | null;
  custom?: boolean;
  disabled?: boolean;
}

export type TAutomationTriggerFieldsInterface = TAutomationField & {
  text?: Trigger
}

export type TAutomationActionFieldsInterface = TAutomationField & {
  text?: Action
}

export enum TActiveTab {
  Triggers = "triggers",
  Actions = "actions"
}

export type TFormType = {
  title: string
  triggerValue?: string
  updateValue?: string
  enabled?: boolean
}
