import { FC } from "react";
import { Tabs } from "antd";
import Scrollbars from "react-custom-scrollbars-2";

import { useBoardContext } from "lib/contexts/BoardContext";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import { AutomationActionFields } from "../../tabConfig/actionFields";
import { AutomationTriggerFields } from "../../tabConfig/triggerFields";
import { Action, TActiveTab, Trigger } from "../../types";
import { AutomationsModalControls, AutomationsTabPane, AutomationsTabsCard } from "../styled";

interface AutomationsTabsProps {
  onClickTrigger: (trigger: Trigger) => void;
  onClickAction: (action: Action) => void;
  activeKey: TActiveTab;
  setActiveKey: (tab: TActiveTab) => void;
}

const AutomationsTabs: FC<AutomationsTabsProps> = (props) => {
  const { onClickTrigger, onClickAction, activeKey, setActiveKey } = props;
  const { board } = useBoardContext();
  const { isCompletionEnabled, isAssigneesEnabled, isDueDatesEnabled, isStatusEnabled, terminology } = useBoardFeatures(board);

  const excludedItems: string[] = [
    ...(!isCompletionEnabled ? [Trigger.completed, Action.completed] : []),
    ...(!isAssigneesEnabled ? [Trigger.assignees, Action.assignees] : []),
    ...(!isDueDatesEnabled ? [Trigger.dueDate, Trigger.taskIsOverdue, Trigger.dueDateIsApproaching, Action.dueDate] : []),
    ...(!isStatusEnabled ? [Trigger.status, Action.status] : []),
  ];
  const triggers = AutomationTriggerFields(terminology.single).filter(item => !excludedItems.includes(item.text as string));
  const actions = AutomationActionFields(terminology.single).filter(item => !excludedItems.includes(item.text as string));

  return (
    <Scrollbars autoHide style={{ width: 300 }}>
      <AutomationsModalControls>
        <Tabs defaultActiveKey={TActiveTab.Triggers} activeKey={activeKey} onTabClick={(tab) => setActiveKey(tab as TActiveTab)} style={{ backgroundColor: "lightpink !important" }}>
          <AutomationsTabPane tab="Triggers" key={TActiveTab.Triggers}>
            {triggers.map(field => {
              if (field.custom) {
                return field.component;
              }
              return (
                <AutomationsTabsCard
                  onClick={() => {
                    if (field.text && !field.disabled) {
                      onClickTrigger(field.text);
                      setActiveKey(TActiveTab.Actions);
                    }
                  }}
                  key={field.text}
                  disabled={field.disabled}
                >
                  {field.text}
                </AutomationsTabsCard>
              );
            })}
          </AutomationsTabPane>
          <AutomationsTabPane tab="Actions" key={TActiveTab.Actions} style={{ padding: "0 20px 8px 20px" }}>
            {actions.map(field => {
              if (field.custom) {
                return field.component;
              }
              return (
                <AutomationsTabsCard
                  onClick={() => {
                    if (field.text && !field.disabled) {
                      onClickAction(field.text);
                    }
                  }}
                  key={field.text}
                  disabled={field.disabled}
                >
                  {field.text}
                </AutomationsTabsCard>
              );
            })}
          </AutomationsTabPane>
        </Tabs>
      </AutomationsModalControls>
    </Scrollbars>
  );
};

export default AutomationsTabs;
