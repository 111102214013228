import React, { FC } from "react";
import {
  ViewSwitchCardCheckContainer,
  ViewSwitchCardIcon,
  ViewSwitchCardTile
} from "components/ui/ViewSwitchCard/styled";
import ViewSwitchCheckSvg from "components/ui/ViewSwitchCard/check";

interface ViewSwitchCardProps {
  title: string;
  icon: React.ReactNode;
  select: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const ViewSwitchCard: FC<ViewSwitchCardProps> = (props) => {
  return (
    <ViewSwitchCardTile
      select={props.select}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <ViewSwitchCardCheckContainer>
        <ViewSwitchCheckSvg />
      </ViewSwitchCardCheckContainer>
      <ViewSwitchCardIcon>
        {props.icon}
      </ViewSwitchCardIcon>
      {props.title}
    </ViewSwitchCardTile>
  );
};

export default ViewSwitchCard;
