import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const FillSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M91.6666 49.9999C91.6666 73.0118 73.0118 91.6666 49.9999 91.6666C26.9881 91.6666 8.33325 73.0118 8.33325 49.9999C8.33325 26.9881 26.9881 8.33325 49.9999 8.33325C73.0118 8.33325 91.6666 26.9881 91.6666 49.9999Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default FillSvg;
