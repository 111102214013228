import styled from "@emotion/styled/macro";
import { cardShadow } from "styles/common";
import { Link } from "react-router-dom";

export const FileContainerCardWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const FileContainerCardCard = styled(Link)`
  ${cardShadow};
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  height: 150px;
  box-shadow: none;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    box-shadow: none;
  }
`;

export const FileContainerCardControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 5px 0 0 10px;
`;

export const FileContainerCardLastUpdated = styled.div`
  font-size: 12px;
  color: #999;
`;

export const FileContainerCardPreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 0 14px 12px 14px;
`;

export const FileContainerCardTitleWrapper = styled.div`
  display: flex;
  align-items: start;
  padding: 6px 14px;
  border-top: 1px solid #e4e4e4;
`;

export const FileContainerCardTitle = styled.div`
  max-width: 410px;
  font-size: 13px;
  font-weight: 500;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const FileContainerMore = styled.div`
  margin: 5px 5px 0 0;
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  bottom: auto;
`;
