import React, { FC, useContext } from "react";
import { ApplicationThemeInterface, DefaultAppTheme } from "./lib";

interface AppThemeContextProps {
  theme: Partial<ApplicationThemeInterface> | null;
}

const AppThemeContext = React.createContext<AppThemeContextProps>({
  theme: DefaultAppTheme,
});

export const useAppThemeContext = () => useContext(AppThemeContext);

export const AppThemeProvider: FC<AppThemeContextProps> = (props) => {
  return (
    <AppThemeContext.Provider value={{
      theme: {
        ...DefaultAppTheme,
        ...props.theme,
      }
    }}>
      {props.children}
    </AppThemeContext.Provider>
  );
};

