import { useCallback, useEffect, useState } from "react";
import { sortBy } from "lodash";
import { useAppSelector } from "app/store";
import { StatusInterface } from "lib/types/kanbanTypes";
import useBoardSetViews from "../dataLayer/board/useBoardSetViews";
import useBoardView from "./useBoardView";

const useBoardViewStatuses = () => {
  const board = useAppSelector(state => state.currentBoardReducer.board);
  const [statuses, setStatuses] = useState<StatusInterface[]>([]);
  const { updateBoardView } = useBoardSetViews({ board, updateDetailed: true });
  const storeStatuses: StatusInterface[] = board?.statuses || [];

  const view = useBoardView();
  const appliedStatuses = view?.appliedStatuses || [];

  useEffect(() => {
    if (appliedStatuses.length) {
      let temp: StatusInterface[] = storeStatuses?.map(status => {
        const appliedStatus = appliedStatuses.find((item: any) => String(item.id) === String(status.id));
        return appliedStatus
          ? { ...status, sort: appliedStatus.sort }
          : status;
      });
      temp = sortBy(temp, item => item.sort);
      setStatuses(temp);
    } else {
      setStatuses(storeStatuses);
    }

  }, [appliedStatuses, storeStatuses]);

  const saveViewStatuses = useCallback(async (data: StatusInterface[]) => {
    if (!view) {
      return;
    }
    const payload = data.map((item, index) => ({
      id: item.id,
      sort: index,
    }));

    updateBoardView(view.id, { appliedStatuses: payload });

  }, [updateBoardView, view]);

  return {
    appliedStatuses: statuses,
    saveViewStatuses
  };
};

export default useBoardViewStatuses;
