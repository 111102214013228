import { BoardInterface, BoardInterfaceExtended } from "../../types/boardTypes";
import { TaskInterface } from "../../types/tasksTypes";
import { FilterFunction } from "../filterFunctions";
import { FilterValueNone, FilterVersion, TAnyFilters } from "../filters";

const getFilteredArray = (currentFilter: TAnyFilters) => {
  const filterValueKey = currentFilter.value.valueKey;
  return Array.isArray(filterValueKey)
    ? filterValueKey.map((item: string) => Number(item))
    : [];
};

const getTaskFilterValue = (task: TaskInterface, currentFilter: TAnyFilters) => {
  const customProperty = task.propertyValues.find(value => value.title === currentFilter.titleKey);
  return customProperty?.value || [];
};

const filterV1 = (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
  const filteredArray = getFilteredArray(currentFilter);

  return tasks.filter(task => {
    const taskValue = getTaskFilterValue(task, currentFilter);
    return filteredArray.some(item => taskValue.includes(item));
  });
};

const filterV2 = (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
  const filteredArray = getFilteredArray(currentFilter);

  switch (currentFilter.function) {
    case FilterFunction.Contains: {
      return tasks.filter(task => {
        const taskValue = getTaskFilterValue(task, currentFilter);
        return filteredArray.some(item => taskValue.includes(item));
      });
    }

    case FilterFunction["Does not contain"]: {
      return tasks.filter(task => {
        const taskValue = getTaskFilterValue(task, currentFilter);
        return filteredArray.every(item => !taskValue.includes(item));
      });
    }

    case FilterFunction["Is empty"]: {
      return tasks.filter(task => {
        const taskValue = getTaskFilterValue(task, currentFilter);
        return taskValue.length === 0;
      });
    }

    case FilterFunction["Is not empty"]: {
      return tasks.filter(task => {
        const taskValue = getTaskFilterValue(task, currentFilter);
        return taskValue.length !== 0;
      });
    }
  }

  return tasks;
};

export const multiSelectFilter = {
  filterValues: (item: BoardInterfaceExtended | BoardInterface | null, currentFilter: TAnyFilters) => {
    if (item === null) {
      return [];
    }

    const customColumnName = currentFilter.titleKey;
    const options = item.columns.find(column => column.title === customColumnName)?.options;

    return options?.map(option => {
      return {
        text: option.title,
        valueKey: String(option.id),
        color: option?.color || ""
      };
    }) || [];
  },
  filterFn: (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
    if (currentFilter.version && currentFilter.version !== FilterVersion.v1) {
      return filterV2(tasks, currentFilter);
    }

    if (currentFilter.value.valueKey === FilterValueNone || !currentFilter.value.valueKey) {
      return tasks;
    }

    return filterV1(tasks, currentFilter);
  },
};
