import { Form, Input } from "antd";
import { FC } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";

interface AuthResetPasswordProps {
  text: string;
  autoComplete?: string;
}

const AuthResetPassword: FC<AuthResetPasswordProps> = (props) => {
  return (
    <Form.Item
      name="password"
      rules={[
        {
          min: 8,
          message: "",
        }, {
          required: true,
          message: "",
        },
      ]}
      label={props.text}
      tooltip={{ title: "Password must be at least 8 characters", icon: <InfoCircleOutlined /> }}
    >
      <Input.Password
        id="password"
        name="password"
        autoComplete={props.autoComplete || "current-password"}
      />
    </Form.Item >
  );
};

export default AuthResetPassword;
