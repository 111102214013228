import { FC } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { BoardViewLoaderWrapper } from "pages/Board/BoardView/styled";

const PageLoader: FC = () => {
  return (
    <BoardViewLoaderWrapper>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </BoardViewLoaderWrapper>
  );
};

export default PageLoader;
