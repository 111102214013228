import { FC, useState } from "react";
import { Switch } from "antd";

import { ProjectSettingsSwitch, ProjectSettingsIntegration, ProjectSettingsIntegrationDescription } from "pages/Project/ProjectSettings/styled";
import BoardService from "services/BoardService";
import { SectionContent, SectionHeading } from "styles/common";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import { OAuthProviders, MemberRoles, WorkspaceConnectionInterface } from "lib/types/types";
import { InstructionsWrapper } from "pages/Settings/styled";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { NotificationsUserName } from "pages/Notifications/styled";
import useSavingState from "lib/customHooks/useSavingState";
import configuration from "lib/configs/configuration";

const BoardSettingsSync: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setSavingStart, setSavingFinish } = useSavingState();
  const { board } = useBoardContext() as BoardContextInterface;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);

  const connections = board?.workspace?.connections as WorkspaceConnectionInterface[];

  const connection = connections?.find(c => c.provider === OAuthProviders.Autodesk);
  const connectionId = String(connection?.id);

  const handleSwitchOnChange = async (enabled: boolean) => {
    setSavingStart();
    setIsLoading(true);
    if (enabled) {
      await BoardService.externalSyncEnable(board?.id, connectionId);
    } else {
      await BoardService.externalSyncDisable(board?.id);
    }
    setIsLoading(false);
    setSavingFinish();
  };

  if (!(connection && connection.connectedId)) {
    return (null);
  } else {
    return (
      <>
        <SectionContent>
          <SectionHeading>Sync data</SectionHeading>
          <ProjectSettingsSwitch>
            <Switch
              size="small"
              onChange={handleSwitchOnChange}
              checked={!!board?.externalDataSource}
              loading={isLoading}
              style={{ marginRight: "10px" }}
              disabled={!allowedToEdit}
            />
            <ProjectSettingsIntegration>
              <NotificationsUserName>Sync Autodesk Build issues to this board.</NotificationsUserName>
              <ProjectSettingsIntegrationDescription>
                You’ll be able to open your Autodesk Build issues right from {configuration.app.title} and track everything in a one place.
              </ProjectSettingsIntegrationDescription>
            </ProjectSettingsIntegration>
          </ProjectSettingsSwitch>
          {!allowedToEdit &&
            <InstructionsWrapper style={{ marginTop: "13px" }}>
              Board sync can only be configured by the project owner.
            </InstructionsWrapper>
          }
        </SectionContent>
      </>
    );
  }
};

export default BoardSettingsSync;
