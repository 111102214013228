import { ApplicationInstanceInfo } from "./applicationTypes";
import { EntityId } from "./entity";

export interface WsInterface<T> {
  data: T;
  entity: string;
  event: string;
  id: number;
  version: string;
  initiator: TransactionInitiatorInterface;
  instance?: {
    id: string;
    name: string;
  }
}

export interface WsConnectionMessage {
  socketId: string;
  userId: number;
  instance: ApplicationInstanceInfo;
}

export interface TransactionInitiatorInterface {
  socketId: string;
  userId: EntityId;
  method: string;
  date?: Date;
}

enum User {
  Status = "status-user",
  Connect = "connect-user",
  Update = "update-user",
}

enum Task {
  Create = "create-task",
  Update = "update-task",
  Delete = "delete-task",
}

enum Board {
  Create = "create-board",
  Update = "update-board",
  Delete = "delete-board",
}

enum Notification {
  Create = "create-notification",
  Update = "update-notification",
  Delete = "delete-notification",
}

enum Workspace {
  Create = "create-workspace",
  Update = "update-workspace",
  Delete = "delete-workspace",
}

enum Organization {
  Create = "create-organization",
  Update = "update-organization",
  Delete = "delete-organization",
}

enum FileContainer {
  Create = "create-file-container",
  Update = "update-file-container",
  Delete = "delete-file-container",
}

enum Document {
  Create = "create-document",
  Update = "update-document",
  Delete = "delete-document",
}

enum Service {
  Connect = "connect",
  Error = "error",
  Disconnect = "disconnect",
  Reconnect = "reconnect",
  ReconnectAttempt = "reconnect_attempt",
  Reconnecting = "reconnecting",
  ReconnectError = "reconnect_error",
  ReconnectFailed = "reconnect_failed",
}

enum Revision {
  Update = "update-revision"
}

export const WsEvents = {
  User,
  Workspace,
  Board,
  Task,
  Notification,
  Service,
  Organization,
  FileContainer,
  Document,
  Revision,
};

export enum WsTaskMethods {
  // TaskService
  create = "TaskService.create",
  duplicate = "TaskService.duplicate",
  update = "TaskService.update",
  delete = "TaskService.delete",
  reorderKanban = "TaskService.reorderKanban",
  reorderList = "TaskService.reorderList",

  // TaskCommentService
  addComment = "TaskCommentService.create",
  updateComment = "TaskCommentService.update",
  deleteComment = "TaskCommentService.delete",
  reactComment = "TaskCommentService.react",
  unreactComment = "TaskCommentService.unreact",

  // TaskCollaboratorService
  addCollaborator = "TaskCollaboratorService.add",
  removeCollaborator = "TaskCollaboratorService.remove",
  removeCollaboratorAndAssignee = "TaskCollaboratorService.removeAssignee",
  setCollaborators = "TaskCollaboratorService.addBatch",

  // TaskPropertyService
  addPropertyTable = "TaskPropertyService.addPropertyTable",
  fixPropertyTables = "TaskPropertyService.fixPropertyTables",
  syncPropertyTables = "TaskPropertyService.syncPropertyTables",
  deletePropertyTable = "TaskPropertyService.deletePropertyTable",
  updatePropertyValueByCode = "TaskPropertyService.setPropertyValueByCode",
  setPropertyValueByCodeSampleData = "TaskPropertyService.setPropertyValueByCodeSampleData",
  deletePropertyValue = "TaskPropertyService.deletePropertyValue",
  removeStatusTableValue = "TaskPropertyService.removeStatusTableValue",
  syncCompletedStatus = "TaskPropertyService.syncCompletedStatus",

  // TaskBimDataService
  addViewpoint = "TaskBimDataService.addViewpoint",
  updateViewpoint = "TaskBimDataService.updateViewpoint",
  addViewpointsBatch = "TaskBimDataService.addViewpointsBatch",
  deleteViewpoint = "TaskBimDataService.deleteViewpoint",

  // TaskAnnotationService
  linkAnnotationToTask = "TaskAnnotationService.linkAnnotationToTask",
  addAnnotation = "TaskAnnotationService.addAnnotation",
  removeAnnotation = "TaskAnnotationService.removeAnnotation",

  // TaskSubtaskService
  createSubtask = "TaskSubtaskService.create",
  createSubtasks = "TaskSubtaskService.createBatch",
  updateSubtask = "TaskSubtaskService.update",
  deleteSubtask = "TaskSubtaskService.delete",
  reorderSubtasks = "TaskSubtaskService.reorder",

  // TaskDependencyService
  addDependency = "TaskDependencyService.create",
  updateDependency = "TaskDependencyService.update",
  deleteDependency = "TaskDependencyService.delete",
}

export enum WsBoardMethods {
  // BoardService
  create = "BoardService.create",
  createFrom = "BoardService.createFrom",
  update = "BoardService.update",
  delete = "BoardService.delete",

  // BoardOperationService
  duplicateToProject = "BoardOperationService.duplicate",

  // BoardStatusService
  addStatus = "BoardStatusService.create",
  updateStatus = "BoardStatusService.update",
  deleteStatus = "BoardStatusService.delete",
  reorderStatuses = "BoardStatusService.reorder",

  // BoardColumnService
  addColumn = "BoardColumnService.create",
  updateColumn = "BoardColumnService.update",
  deleteColumn = "BoardColumnService.delete",
  activateColumn = "BoardColumnService.activate",
  deactivateColumn = "BoardColumnService.deactivate",
  reorderColumns = "BoardColumnService.reorderColumns",

  // BoardAutomationService
  addAutomation = "BoardAutomationService.create",
  updateAutomation = "BoardAutomationService.update",
  deleteAutomation = "BoardAutomationService.delete",

  // BoardRecordTemplateService
  addRecordTemplate = "BoardRecordTemplateService.create",
  updateRecordTemplate = "BoardRecordTemplateService.update",
  deleteRecordTemplate = "BoardRecordTemplateService.delete",

  // BoardViewService
  viewsCreate = "BoardViewService.create",
  viewsUpdate = "BoardViewService.update",
  viewsDelete = "BoardViewService.delete",
  viewsReorder = "BoardViewService.reorder",
  displayViewUpdate = "BoardViewService.displayViewUpdate",

  // BoardFeatureService
  featuresUpdate = "BoardFeatureService.update",

  // BoardSyncService
  externalSyncEnable = "BoardSyncService.enable",
  externalSyncDisable = "BoardSyncService.disable",
  externalSyncProcess = "BoardSyncService.process",
}

export enum WsFileContainerMethods {
  // FileContainerService
  create = "FileContainerService.create",
  update = "FileContainerService.update",
  delete = "FileContainerService.delete",

  revisionsAdd = "FileContainerService.revisionsAdd",
  revisionsDelete = "FileContainerService.revisionsDelete",

  annotationManager = "FileContainerService.annotationManager",
  annotationTransfer = "FileContainerService.annotationTransfer",
  mentionManager = "FileContainerService.mentionManager",

  commentsAdd = "FileContainerService.commentsAdd",
  commentsEdit = "FileContainerService.commentsEdit",
  commentsDelete = "FileContainerService.commentsDelete",
  commentsReact = "FileContainerService.commentsReact",
  commentsUnreact = "FileContainerService.commentsUnreact",

  unlinkAnnotation = "TaskAnnotationService.removeAnnotation",
  afterDeleteFromRecord = "AnnotationService.afterDeleteFromRecord",
}

export enum WsWorkspaceMethods {
  // WorkspaceService
  create = "WorkspaceService.create",
  createSample = "WorkspaceService.createSample",
  duplicate = "WorkspaceService.duplicate",
  update = "WorkspaceService.update",
  reorder = "WorkspaceService.reorder",
  delete = "WorkspaceService.delete",
  addCollaborators = "WorkspaceService.addCollaborators",
  resendInviteCollaborator = "WorkspaceService.resendInviteCollaborator",
  requestPlanInfo = "WorkspaceService.requestPlanInfo",
  removeCollaborator = "WorkspaceService.removeCollaborator",
  uploadBimModel = "WorkspaceService.uploadBimModel",
  replaceBimModel = "WorkspaceService.replaceBimModel",
  deleteBimModel = "WorkspaceService.deleteBimModel",
  fetchModelDownloadLink = "WorkspaceService.fetchModelDownloadLink",
  addBimModelThumbnail = "WorkspaceService.addBimModelThumbnail",
  markBimModelProcessed = "WorkspaceService.markBimModelProcessed",
  forgeSettingsUpdate = "WorkspaceService.forgeSettingsUpdate",
  forgeSettingsSetHome = "WorkspaceService.forgeSettingsSetHome",
  bim360FetchToken = "WorkspaceService.bim360FetchToken",
  bim360AddModels = "WorkspaceService.bim360AddModels",
  bim360ReplaceModel = "WorkspaceService.bim360ReplaceModel",

  // WorkspaceMemberService
  membersAdd = "WorkspaceMemberService.addMass",
  membersUpdate = "WorkspaceMemberService.update",
  membersRemove = "WorkspaceMemberService.remove",
  membersResendInvitation = "WorkspaceMemberService.resendInvitation",

  // WorkspaceConnectionService
  connectionCreate = "WorkspaceConnectionService.create",
  connectionUpdate = "WorkspaceConnectionService.update",
  connectionRefreshCredentials = "WorkspaceConnectionService.updateProviderCredentials",
  connectionDelete = "WorkspaceConnectionService.delete",
  connectionAuthorize = "WorkspaceConnectionService.authorize",
  connectionTest = "WorkspaceConnectionService.test",
  connectionConfiguration = "WorkspaceConnectionService.getConfigurationData",

  // WorkspaceReportingService
  reportingCreate = "WorkspaceReportingService.create",
  reportingUpdate = "WorkspaceReportingService.update",
  reportingDelete = "WorkspaceReportingService.delete",
}

export const WsMessageEvents = {
  JoinRoomRequest: "join-room",
  LeaveRoomRequest: "leave-room",
};
