import styled from "@emotion/styled/macro";

export const FormPublicContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  background-color: #fafafa;
  .ant-select-selection-item {
    border-radius: 12px !important;
  }
`;

export const FormPublicHeaderContainer = styled.div`
  background-color: white;
  padding-block: 28px;
`;

export const FormPublicTitle = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: #333;
`;

export const FormPublicBodyContainer = styled.div`
  min-height: 400px;
  padding-block: 30px;
`;

export const FormPublicFooterContainer = styled.div`
  background-color: white;
  padding-block: 54px;
`;

export const FormPublicLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FormPublicLogo = styled.img`
  height: 36px;
`;

export const FormPublicContent = styled.div`
  max-width: 580px;
  padding: 10px 30px;
  margin: auto;
`;

export const FormPublicDisclaimer = styled.div`
  font-size: 11px;
  color: #a4a4a4;
  margin-top: 14px;
`;

export const FormPublicConfirmation = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const FormPublicConfirmationText = styled.div`
  font-size: 22px;
  color: #4d4d4d;
  margin-bottom: 15px;
`;
