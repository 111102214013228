import { ChangeEventHandler, ClipboardEventHandler, FC, KeyboardEventHandler } from "react";
import colors from "lib/configs/colors";

import { ColorObject } from "./types";

import { ColorPaletteWrapper, ColorOption } from "./styled";
import Input from "antd/lib/input/Input";
import { HexColorPrefix, HexColorRegexIncomplete } from "../TaskTable/utils/utils";
import { DefaultIconColor } from "components/icons/lib";
import OutsideClickHandler from "react-outside-click-handler";

interface ColorPaletteProps {
  changeColor: (color: string, closePopup?: boolean) => void;
  titleColor: string;
}

const ColorPalette: FC<ColorPaletteProps> = (props) => {
  const { changeColor, titleColor } = props;

  const parseHexValue = (value: string) => {
    return value?.startsWith(HexColorPrefix)
      ? value.substring(1)
      : value;
  };

  const inputColorValue = parseHexValue(titleColor || DefaultIconColor);

  const closeDropdown = () => {
    changeColor(titleColor || DefaultIconColor, true);
  };

  const handleOnInputPaste: ClipboardEventHandler<HTMLInputElement> = (event) => {
    event.preventDefault();

    const clipboardData = event.clipboardData;
    const pastedData = clipboardData?.getData("text") || "";

    const parsedData = parseHexValue(pastedData.trim());
    const nextState = HexColorPrefix + parsedData;
    const isValid = HexColorRegexIncomplete.test(nextState);

    if (isValid) {
      changeColor(nextState);
    }
  };

  const handleInputOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = HexColorPrefix + event.target.value.trim();
    const isValid = HexColorRegexIncomplete.test(inputValue);

    if (isValid) {
      changeColor(inputValue);
    }
  };

  const handleOnPressEnter: KeyboardEventHandler<HTMLInputElement> = (event) => {
    closeDropdown();
  };

  const handleOnOutsideClick = () => {
    closeDropdown();
  };


  return (
    <OutsideClickHandler onOutsideClick={handleOnOutsideClick}>
      <ColorPaletteWrapper>
        {colors.map((color: ColorObject) => (
          <ColorOption
            key={color.normal}
            colors={color}
            onClick={() => changeColor(color.normal, true)}
          >
            {titleColor === color.normal && (
              <img src={process.env.PUBLIC_URL + "/icons/color-checkmark.svg"} alt="white_check" />
            )}
          </ColorOption>
        ))}
        <Input
          size="small"
          value={inputColorValue}
          onChange={handleInputOnChange}
          onPaste={handleOnInputPaste}
          onPressEnter={handleOnPressEnter}
          maxLength={6}
          style={{ margin: "5px 3px 3px 3px", borderRadius: 4 }}
          prefix={HexColorPrefix}
        />
      </ColorPaletteWrapper>
    </OutsideClickHandler>
  );
};

export default ColorPalette;
