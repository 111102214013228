import { batch } from "react-redux";

import { performTaskOptimisticUpdate, setTask } from "app/slices/currentTaskSlice";
import { performTasksOptimisticUpdate, updateTask } from "app/slices/tasksSlice";
import { enqueueTransaction, dequeueTransaction } from "app/slices/progressSlice";
import { useAppDispatch, useAppSelector } from "app/store";
import { performApiRequestWithStore } from "lib/API/http-redux";
import { JSendResponse } from "lib/types/jsend";
import { TaskInterface, TaskOptimisticUpdatePayload } from "lib/types/tasksTypes";
import { updateTaskProperties } from "lib/helpers/taskUtils";

export interface TaskPerformRequestHookProps {
  task: TaskInterface | null;
  updateDetailed?: boolean;
}

const useTaskPerformRequest = (props: TaskPerformRequestHookProps) => {
  const { task } = props;
  const user = useAppSelector(state => state.currentUserReducer.user);
  const dispatch = useAppDispatch();

  const performRequest = (requestPayload: TaskOptimisticUpdatePayload, requestFn: () => Promise<JSendResponse<any> | void>) => {
    if (!task || !user) {
      return undefined;
    }

    const dispatchPayload = {
      id: task.id,
      data: updateTaskProperties(task, requestPayload, user),
    };

    const onMutate = () => {
      batch(() => {
        dispatch(enqueueTransaction());
        dispatch(performTasksOptimisticUpdate(dispatchPayload));
        if (props.updateDetailed) {
          dispatch(performTaskOptimisticUpdate(dispatchPayload));
        }
      });
    };

    const onError = () => {
      batch(() => {
        dispatch(updateTask(task));
        if (props.updateDetailed) {
          dispatch(setTask(task));
        }
      });
    };

    const onSettled = () => {
      batch(() => {
        dispatch(dequeueTransaction());
      });
    };

    onMutate();

    performApiRequestWithStore(requestFn, onError, onSettled);
  };

  return performRequest;
};

export default useTaskPerformRequest;
