import styled from "@emotion/styled/macro";

import {
  customSelectsWrapper,
  customSelectsGroup,
  customSelectsSearch
} from "../../../../../ui/CustomSelects/styled";

export const UserFieldWrapper = styled.div`
  ${customSelectsWrapper};
`;

export const UserFieldGroup = styled.div`
  ${customSelectsGroup};
`;

export const UserFieldSearch = styled.div`
  ${customSelectsSearch};
`;
