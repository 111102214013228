import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const ModelSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M52.03 0.431245L97.03 20.431C98.835 21.236 100 23.026 100 25.0009V75.0003C100 76.9753 98.835 78.7653 97.03 79.5703L52.03 99.57C51.385 99.855 50.69 100 50 100C49.31 100 48.615 99.855 47.97 99.57L2.97 79.5703C1.165 78.7653 0 76.9753 0 75.0003V25.0009C0 23.026 1.165 21.236 2.965 20.431L47.965 0.431245C49.26 -0.143748 50.735 -0.143748 52.03 0.431245ZM75.3907 24.8453L50.293 13.7125L25.293 24.8453L50.293 35.9781L75.3907 24.8453ZM42.1875 50.1382L13.3789 37.2477L13.2812 69.5718L42.1875 82.4625V50.1382ZM57.8125 50.3336L86.6208 37.443L86.7183 69.7672L57.8125 82.6578V50.3336Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default ModelSvg;
