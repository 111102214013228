import { FC, ChangeEventHandler, KeyboardEventHandler, FocusEventHandler } from "react";
import { Input } from "antd";

import { FormItem } from "../styled";

interface TextFieldProps {
  label?: string;
  name: string | string[];
  placeholder: string;
  initialValue?: string;
  bordered?: boolean | true;
  disabled?: boolean;
  autoFocus?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  rules?: Array<any>
  fieldKey?: Array<any>
}

const TextField: FC<TextFieldProps> = (props) => {
  const {
    label,
    name,
    placeholder,
    disabled,
    onChange,
    onKeyDown,
    onBlur,
    rules,
    initialValue,
    bordered,
    fieldKey,
    autoFocus,
  } = props;

  return (
    <FormItem
      label={label}
      name={name}
      rules={rules}
      initialValue={initialValue}
      fieldKey={fieldKey}
    >
      <Input
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        bordered={bordered}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}
      />
    </FormItem>
  );
};

export default TextField;
