import { JSendResponse } from "lib/types/jsend";
import { OrganizationInsightsInterface, OrganizationInterface } from "lib/types/organization";
import HttpService from "./HttpService";

class OrganizationService extends HttpService {
  constructor() {
    super("organizations");
  }

  async getOrganizations(): Promise<OrganizationInterface[]> {
    const data = await this._get({});
    return data || [];
  }

  async getInsights(uid: string) {
    const data: JSendResponse<OrganizationInsightsInterface> = await this._get({
      additionalUrl: `/${uid}/insights`
    });

    return data;
  }

  async update(uid: string, payload: Partial<OrganizationInterface>) {
    const data: JSendResponse = await this._put({
      additionalUrl: `/${uid}`,
      body: payload,
    });

    return data;
  }

}

export default new OrganizationService();
