import { useEffect } from "react";
import WorkspaceService from "services/WorkspaceService";
import { setWorkspaces, setWorkspacesLoading } from "app/slices/workspaceSlice";
import { setLoading } from "app/slices/progressSlice";
import { useAppDispatch } from "app/store";

const useGetWorkspaces = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(setWorkspacesLoading(true));
    const abortController = new AbortController();
    const signal = abortController.signal;
    const getData = async () => {
      const data = await WorkspaceService.getAll();
      if (!signal.aborted) {
        dispatch(setWorkspaces(data));
        dispatch(setLoading(false));
        dispatch(setWorkspacesLoading(false));
      }
    };
    getData();
    return () => {
      abortController.abort();
      dispatch(setLoading(false));
      dispatch(setWorkspacesLoading(false));
    };
  }, [dispatch]);
};

export default useGetWorkspaces;
