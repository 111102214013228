import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { DeleteButton } from "components/blocks/ViewpointLikeCard/styled";

export const DateGenericWrapper = styled.div<{ system?: boolean; color?: string }>`
  position: relative;
  ${({ system }) => system && css`
    color: #999;
    cursor: default;
  `}
  color: ${({ color }) => color ? color : "#424242"};
`;

export const InnerText = styled.div`
  flex: 0 0 auto;
  width: 100%;
`;

export const DateGenericInnerWrapper = styled.div`
  display: flex;
  align-items: center;

  ${DeleteButton} {
    opacity: 0;
  }

  &:hover {
    border-color: #e5e5e5;

    ${DeleteButton} {
      opacity: 1;
    }
  }
`;

export const TimePickerCustomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
`;
