import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";
import { css } from "@emotion/react/macro";
import { Divider } from "antd";

export const AuthPageWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  justify-content: center;
  background-image: url("/images/airtasks-background.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 10px;
  @media (max-width: 750px) {
    background-image: none;
    background-color: white;
  }
`;

export const AuthFormContainer = styled.div`
  width: 425px;
  padding: 5px;
  
  .ant-form-item-label {
    padding-bottom: 2px;
  }
  .ant-form-item-required {
    font-size: 13px;
    font-weight: 600;
    color: #999;
  }
  .ant-row {
    margin-bottom: 6px !important;
  }
  .ant-input, .ant-input-password {
    border-radius: 5px;
  }
  @media (max-width: 575px) {
    width: 375px;
    .ant-form, .ant-form-item {
      flex-flow: row wrap; !important;
    }
  }
`;

export const AuthFormCard = styled.div`
  padding: 10px 40px 20px 40px;
  margin-bottom: 10px;
  border: 2px solid #f2f2f2;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 5px 20px 0 rgba(109, 110, 111, 0.08);

  @media (max-width: 575px) {
    box-shadow: none;
    border: 2px solid transparent;
  }
  
`;

export const AuthLogoWrapper = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin-bottom: 10px;
`;

export const AuthLogo = styled.img`
  margin-left: -10px;
  width: 100px;
`;

export const AuthDivider = styled(Divider)`
  font-size: 20px !important;
  font-weight: 700 !important;
  margin-bottom: 20px  !important;
`;

export const AuthFooter = styled.div`
  margin: 0 20px;
`;

export const AuthParagraph = styled.div`
  font-size: 13px;
  color: #646f79;
  margin: 10px 0;
  text-align: center;
`;

export const authStyledUrl = css`
  color: #8f64ff;
  &:hover {
    color: #5a27e7;
  }
`;

export const AuthLink = styled.a`
  ${authStyledUrl}
`;

export const AuthReactLink = styled(Link)`
  ${authStyledUrl}
`;

export const AuthButton = styled.button`
  display: inline-block;
  padding: 9px 15px;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  border-radius: 3px;
  outline: none;
  transition: background-color 200ms ease;

  width: 100%;
  margin: 10px 0;
  background-color: #8f64ff;
  color: white;
  font-size: 16px;
  font-weight: 600;
  
  &:hover {
    background-color: #5a27e7;
  }
`;

export const AuthName = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
`;
