import { ContentType } from "./contentTypes";
import { BaseEntityInterface } from "./entity";
import { UserInterface } from "./types";

export interface NotificationInterface extends BaseEntityInterface {
  initiator: UserInterface;
  status: NotificationStatus;
  contentType: ContentType;
  itemId: string;
  eventName: NotificationEvent;
  data: Record<string, any> | null;
  meta: Record<string, any> | null;
}

export enum NotificationStatus {
  New = "new",
  Read = "read",
  Archived = "archived",
}

export enum NotificationEvent {
  Assign = "assigned",
  Comment = "commented",
  Mention = "mentioned",
  Join = "joined",
  Complete = "completed",
}
