import { InboxOutlined } from "@ant-design/icons";
import { Modal, Switch, Upload, UploadProps } from "antd";
import useSavingState from "lib/customHooks/useSavingState";

import { NavisworksViewpointParser } from "lib/importers/NavisworksViewpointParser";
import {
  importViewpointsIntoBoardNoImages,
  importViewpointsIntoBoardWithImages,
  importViewpointsIntoTaskNoImages,
  importViewpointsIntoTaskWithImages
} from "lib/importers/TaskViewpointsImporter";
import { ModalComponentProps } from "lib/types/components";
import { EntityId } from "lib/types/entity";
import { captureException } from "lib/utils/sentry";
import { FC, useState } from "react";

import { StyledButton } from "styles/common";
import CustomStyledButton from "../CustomStyledButton";
import LoadingMessage from "../LoadingMessage";
import { commonModalProps } from "../ModalDialog/lib";
import CustomTooltip from "components/ui/CustomTooltip";
import configuration from "lib/configs/configuration";

interface ImportViewpointsModalProps extends ModalComponentProps {
  taskId?: EntityId;
  boardId?: EntityId;
  viewer: Autodesk.Viewing.Viewer3D;
  method: "task" | "board";
}

const ImportViewpointsModal: FC<ImportViewpointsModalProps> = (props) => {
  const { taskId, boardId } = props;

  const { setSavingStart, setSavingFinish } = useSavingState();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [isGenerateScreenshots, setIsGenerateScreenshots] = useState(false);
  const controller = new AbortController();

  const handleImportViewpoints = async () => {
    if (!file) {
      return;
    }
    setSavingStart();
    setIsLoading(true);

    const parser = new NavisworksViewpointParser(props.viewer);

    try {
      if (props.method === "board" && boardId) {
        const data = await parser.parseMultipleTasks(file);

        if (isGenerateScreenshots) {
          await importViewpointsIntoBoardWithImages(boardId, props.viewer, data, controller.signal);
        } else {
          await importViewpointsIntoBoardNoImages(boardId, data, controller.signal);
        }
      }

      if ((props.method === "task") && taskId) {
        const data = await parser.parseSingleTask(file);

        if (isGenerateScreenshots) {
          await importViewpointsIntoTaskWithImages(taskId, props.viewer, data, controller.signal);
        } else {
          await importViewpointsIntoTaskNoImages(taskId, data, controller.signal);
        }
      }
    } catch (error) {
      captureException(error);
      const errorText = String(error);
      if (!errorText.includes("aborted a request")) {
        LoadingMessage.error(errorText);
      }
    }
    setIsLoading(false);
    setSavingFinish();
    handleModalOnClose();
  };

  const handleModalOnClose = () => {
    setFile(null);
    controller.abort();
    props.onClose();
  };

  const handleCheckboxOnChange = (checked: boolean) => {
    setIsGenerateScreenshots(checked);
  };

  const uploaderProps: UploadProps = {
    name: "file",
    accept: ".xml",
    multiple: false,
    style: {
      width: "100%",
      height: "100%",
      display: file ? "none" : "block",
    },
    listType: "picture",
    disabled: isLoading,
    beforeUpload(file) {
      if (file.type !== "text/xml") {
        LoadingMessage.error(`Unsupported file format: ${file.type}`);
      }
      setFile(file);
      return false;
    },
    onRemove() {
      setFile(null);
    }
  };

  const modalActions = [
    <div key="cancel" style={{ display: "inline-block", marginRight: "10px" }}>
      <StyledButton onClick={handleModalOnClose}>
        Cancel
      </StyledButton>
    </div>,
    <CustomStyledButton
      btnColor="purple"
      key="importViewpoints"
      value="Import"
      isLoading={isLoading}
      disabled={isLoading}
      onClick={handleImportViewpoints}
    />
  ];

  return (
    <Modal
      destroyOnClose
      {...commonModalProps}
      title="Import viewpoints"
      open={props.visible}
      onOk={handleModalOnClose}
      onCancel={handleModalOnClose}
      footer={file ? modalActions : null}
    >
      <Upload.Dragger {...uploaderProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          {configuration.app.title} currently supports Navisworks XML viewpoints. More formats coming soon.
        </p>
      </Upload.Dragger>
      <div style={{ display: file ? "block" : "none", marginTop: "15px" }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "3px" }}>
          <Switch
            id="create-snapshots"
            checked={isGenerateScreenshots}
            onChange={handleCheckboxOnChange}
            size="small"
            style={{ marginRight: 7 }}
            disabled={isLoading}
          />
          <label htmlFor="create-snapshots" style={{ marginTop: -1 }}>Create snapshots</label>
          <CustomTooltip placement="top" content="This option will generate snapshots for each viewpoint based on current model view." />
        </div>
      </div>
    </Modal>
  );
};

export default ImportViewpointsModal;
