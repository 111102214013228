import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const IsoSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.5477 20.4357C64.2788 20.4324 62.1037 19.5293 60.5 17.9246C58.8958 16.3198 57.9938 14.1444 57.9917 11.8755V7.71041e-07H19.7635C18.2968 -0.000544262 16.8443 0.287871 15.4892 0.848768C14.1339 1.40967 12.9026 2.23204 11.8653 3.26895C10.828 4.30585 10.0051 5.53697 9.44373 6.89195C8.88233 8.24693 8.59334 9.6993 8.59334 11.166V78.4772C8.59334 79.944 8.88233 81.3963 9.44373 82.751C10.0051 84.1062 10.828 85.3374 11.8653 86.3743C12.9026 87.4112 14.1339 88.2336 15.4892 88.7946C16.8443 89.3552 18.2968 89.6436 19.7635 89.6432H65.6556C67.122 89.6432 68.5739 89.3544 69.9286 88.7934C71.2834 88.232 72.5141 87.4096 73.551 86.3726C74.588 85.3357 75.4104 84.105 75.9718 82.7502C76.5328 81.3954 76.8216 79.9436 76.8216 78.4772V20.4357H66.5477Z" fill="#0072FF"/>
        <path d="M76.8216 20.4357H66.5477C64.2788 20.4324 62.1037 19.5293 60.5 17.9246C58.8958 16.3198 57.9938 14.1444 57.9917 11.8755V0L76.8216 20.4357Z" fill="#0072FF"/>
        <path d="M84.9212 70.7925H33.0373C29.357 70.7925 26.3735 73.7759 26.3735 77.4564V92.9253C26.3735 96.6058 29.357 99.5892 33.0373 99.5892H84.9212C88.6017 99.5892 91.5851 96.6058 91.5851 92.9253V77.4564C91.5851 73.7759 88.6017 70.7925 84.9212 70.7925Z" fill="#0072FF"/>
        <path d="M43.8971 93.361C43.5938 93.361 43.3681 93.2904 43.22 93.1494C43.0789 93.0012 43.0083 92.7755 43.0083 92.4722V79.9232C43.0083 79.6128 43.0789 79.3871 43.22 79.246C43.3681 79.105 43.5938 79.0344 43.8971 79.0344C44.2075 79.0344 44.4332 79.105 44.5743 79.246C44.7154 79.3871 44.7859 79.6128 44.7859 79.9232V92.4722C44.7859 92.7755 44.7154 93.0012 44.5743 93.1494C44.4332 93.2904 44.2075 93.361 43.8971 93.361Z" fill="white"/>
        <path d="M52.7743 93.361C51.568 93.361 50.6266 93.1179 49.9494 92.6311C49.2791 92.1444 48.8594 91.393 48.6901 90.3772C48.6619 90.0668 48.7254 89.8341 48.8806 89.6789C49.0358 89.5237 49.2615 89.4461 49.5578 89.4461C49.8539 89.4461 50.0693 89.5166 50.2033 89.6577C50.3444 89.7988 50.4394 90.0175 50.4888 90.3137C50.5946 90.8004 50.8203 91.1357 51.166 91.3191C51.5187 91.4955 52.0548 91.5834 52.7743 91.5834H55.8216C56.7315 91.5834 57.349 91.4212 57.6734 91.0967C58.005 90.7652 58.1705 90.1515 58.1705 89.2556C58.1705 88.3457 58.0012 87.7249 57.6627 87.3934C57.3241 87.0548 56.7104 86.8855 55.8216 86.8855H53.1975C51.8643 86.8855 50.8734 86.561 50.2245 85.9121C49.5824 85.256 49.2615 84.2614 49.2615 82.9282C49.2615 81.6021 49.5789 80.6183 50.2137 79.976C50.8556 79.3345 51.8431 79.0133 53.1763 79.0133H55.6946C56.8162 79.0133 57.6946 79.2428 58.3295 79.7013C58.9714 80.1598 59.3768 80.8614 59.5461 81.8067C59.5884 82.11 59.5319 82.3428 59.3768 82.505C59.2216 82.6602 58.9888 82.7378 58.6784 82.7378C58.3963 82.7378 58.1846 82.6635 58.0436 82.5154C57.9025 82.3677 57.8108 82.1523 57.7685 81.8702C57.6556 81.454 57.4407 81.1718 57.1232 81.0237C56.8058 80.8685 56.3295 80.7909 55.6946 80.7909H53.1763C52.3299 80.7909 51.7622 80.9428 51.473 81.2457C51.1838 81.5424 51.039 82.1029 51.039 82.9282C51.039 83.7606 51.1871 84.332 51.4834 84.6424C51.7797 84.9527 52.351 85.1079 53.1975 85.1079H55.8216C57.2112 85.1079 58.2448 85.4465 58.922 86.1237C59.6062 86.8009 59.9481 87.8448 59.9481 89.2556C59.9481 90.6594 59.6095 91.6963 58.9324 92.3664C58.2552 93.0295 57.2183 93.361 55.8216 93.361H52.7743Z" fill="white"/>
        <path d="M68.0718 93.361C66.4564 93.361 65.261 92.9768 64.4851 92.2079C63.7162 91.432 63.3315 90.2361 63.3315 88.6208V83.7324C63.3315 82.1029 63.7195 80.9075 64.4954 80.1457C65.2714 79.3768 66.4635 78.9992 68.0718 79.0133H70.6112C72.2336 79.0133 73.4295 79.398 74.1983 80.1664C74.9672 80.9357 75.3515 82.1312 75.3515 83.7536V88.6208C75.3515 90.2361 74.9672 91.432 74.1983 92.2079C73.4295 92.9768 72.2336 93.361 70.6112 93.361H68.0718ZM68.0718 91.5834H70.6112C71.366 91.5834 71.9585 91.4955 72.3888 91.3191C72.8191 91.1357 73.1224 90.8287 73.2988 90.3984C73.4822 89.9681 73.5739 89.3756 73.5739 88.6208V83.7536C73.5739 83.0059 73.4822 82.4171 73.2988 81.9868C73.1224 81.5565 72.8191 81.2494 72.3888 81.066C71.9585 80.8826 71.366 80.7909 70.6112 80.7909H68.0718C67.3241 80.7839 66.7353 80.8685 66.305 81.0449C65.8747 81.2212 65.5676 81.5245 65.3842 81.9548C65.2008 82.3851 65.1091 82.9776 65.1091 83.7324V88.6208C65.1091 89.3756 65.1975 89.9681 65.3739 90.3984C65.5573 90.8287 65.8639 91.1357 66.2942 91.3191C66.7245 91.4955 67.317 91.5834 68.0718 91.5834Z" fill="white"/>
        <path d="M41.166 60.4149C37.254 60.4104 33.5035 58.8543 30.7373 56.0884C27.9712 53.322 26.4152 49.5718 26.4108 45.6597C26.4152 41.7477 27.9712 37.9973 30.7373 35.2311C33.5035 32.4649 37.254 30.9089 41.166 30.9045C45.0779 30.9089 48.8284 32.4649 51.5946 35.2311C54.361 37.9973 55.9166 41.7477 55.9212 45.6597C55.9166 49.5718 54.361 53.322 51.5946 56.0884C48.8284 58.8543 45.0779 60.4104 41.166 60.4149ZM41.166 31.7842C37.4914 31.7875 33.9682 33.2487 31.3699 35.847C28.7715 38.4454 27.3104 41.9684 27.3071 45.6431C27.3114 49.3174 28.773 52.8398 31.3711 55.4382C33.9692 58.0361 37.4917 59.4975 41.166 59.502C44.8402 59.4975 48.3628 58.0361 50.961 55.4382C53.5589 52.8398 55.0203 49.3174 55.0249 45.6431C55.017 41.9713 53.5539 38.4525 50.956 35.8577C48.3583 33.263 44.8377 31.8041 41.166 31.8008V31.7842Z" fill="#0072FF"/>
        <path d="M41.1867 31.2738V43.0082C40.4842 43.0095 39.8109 43.2892 39.3146 43.7863C38.8182 44.2833 38.5394 44.9572 38.5394 45.6597C38.5396 46.1211 38.6597 46.5742 38.888 46.975L28.7759 52.8132L28.5519 52.3983C27.4336 50.3136 26.849 47.9842 26.8506 45.6182C26.8506 45.502 26.8506 45.3941 26.8506 45.2863C26.9013 42.8352 27.583 40.4386 28.8299 38.3277C30.0705 36.2296 31.822 34.4796 33.9212 33.2406C36.0315 31.9961 38.4261 31.3146 40.8755 31.2614L41.1867 31.2738Z" fill="#0072FF"/>
        <path d="M55.5436 45.6432C55.5452 48.1158 54.905 50.5465 53.6846 52.6971C52.405 54.9639 50.5311 56.8386 48.2655 58.1203C46.2291 59.2809 43.9395 59.9195 41.6007 59.9826C41.372 59.9884 41.1867 59.8025 41.1867 59.5734V48.7183C41.1867 48.4905 41.3713 48.3087 41.5959 48.2714C42.1418 48.1809 42.6495 47.9207 43.0441 47.5232C43.5358 47.0274 43.8122 46.3581 43.8133 45.6598C43.8148 45.3315 43.754 45.0075 43.6356 44.7042C43.5516 44.4892 43.6154 44.2336 43.8152 44.1183L53.2631 38.6598C53.4618 38.5452 53.7158 38.6128 53.8249 38.8144C54.9021 40.8088 55.4909 43.0328 55.5394 45.3029C55.5436 45.4108 55.5436 45.5187 55.5436 45.6432Z" fill="#0072FF"/>
        <path d="M41.166 47.4688C40.6861 47.4688 40.226 47.2784 39.8868 46.939C39.5475 46.5995 39.3568 46.1394 39.3568 45.6597C39.3568 45.1796 39.5473 44.7191 39.8865 44.3788C40.2256 44.039 40.6858 43.8477 41.166 43.8464C41.6461 43.8477 42.1063 44.039 42.4455 44.3788C42.7846 44.7191 42.9751 45.1796 42.9751 45.6597C42.9751 46.1394 42.7845 46.5995 42.4452 46.939C42.1059 47.2784 41.6458 47.4688 41.166 47.4688ZM41.166 44.7468C40.9239 44.7468 40.6917 44.8431 40.5205 45.0141C40.3493 45.1854 40.2531 45.4178 40.2531 45.6597C40.2542 45.9016 40.3507 46.1332 40.5217 46.3041C40.6926 46.4751 40.9242 46.5713 41.166 46.5726C41.4077 46.5713 41.6393 46.4751 41.8102 46.3041C41.9812 46.1332 42.0778 45.9016 42.0788 45.6597C42.0788 45.5398 42.0552 45.4211 42.0094 45.3103C41.9635 45.1995 41.8962 45.0991 41.8114 45.0141C41.7267 44.9294 41.6261 44.8622 41.5153 44.8161C41.4046 44.7705 41.2858 44.7468 41.166 44.7468Z" fill="#0072FF"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default IsoSvg;
