import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const TextSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.6001 20.3999C12.6001 15.9816 16.1818 12.3999 20.6001 12.3999V29.3999C20.6001 31.609 18.8092 33.3999 16.6001 33.3999C14.391 33.3999 12.6001 31.609 12.6001 29.3999V20.3999Z"/>
        <path d="M87.6001 20.3999C87.6001 15.9816 84.0184 12.3999 79.6001 12.3999V29.3999C79.6001 31.609 81.391 33.3999 83.6001 33.3999C85.8092 33.3999 87.6001 31.609 87.6001 29.3999V20.3999Z"/>
        <path d="M20.6001 12.3999H79.6001V20.3999H20.6001V12.3999Z"/>
        <path d="M46.0001 20.3999H54.0001V79.3999H46.0001V20.3999Z"/>
        <path d="M33.4001 83.3999C33.4001 81.1908 35.191 79.3999 37.4001 79.3999H62.6001C64.8092 79.3999 66.6001 81.1908 66.6001 83.3999C66.6001 85.609 64.8092 87.3999 62.6001 87.3999H37.4001C35.191 87.3999 33.4001 85.609 33.4001 83.3999Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default TextSvg;
