import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const JpgSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.4999 20.5167C64.2216 20.5155 62.037 19.61 60.4258 17.999C58.8149 16.388 57.9095 14.2033 57.9083 11.925V0H19.5166C18.0439 -1e-07 16.5855 0.29014 15.2249 0.853883C13.8642 1.41763 12.6279 2.2439 11.5867 3.28552C10.5454 4.32713 9.71967 5.56371 9.15644 6.92454C8.5932 8.28538 8.30353 9.74388 8.30408 11.2167V78.8042C8.30353 80.2771 8.5932 81.7354 9.15644 83.0963C9.71967 84.4571 10.5454 85.6938 11.5867 86.7354C12.6279 87.7771 13.8642 88.6033 15.2249 89.1671C16.5855 89.7308 18.0439 90.0208 19.5166 90.0208H65.5999C67.0729 90.0208 68.5312 89.7308 69.8916 89.1671C71.2524 88.6033 72.4887 87.7771 73.5299 86.7354C74.5712 85.6938 75.397 84.4571 75.9599 83.0963C76.5233 81.7354 76.8129 80.2771 76.8124 78.8042V20.5208L66.4999 20.5167Z" fill="#00C650" />
        <path d="M76.8125 20.5167H66.5C64.2217 20.5155 62.0371 19.61 60.4258 17.999C58.815 16.388 57.9096 14.2033 57.9083 11.925V0L76.8125 20.5167Z" fill="#00C650" />
        <path d="M84.9457 71.0834H32.8458C29.1501 71.0834 26.1541 74.0792 26.1541 77.775V93.3084C26.1541 97.0042 29.1501 100 32.8458 100H84.9457C88.6416 100 91.6374 97.0042 91.6374 93.3084V77.775C91.6374 74.0792 88.6416 71.0834 84.9457 71.0834Z" fill="#00C650" />
        <path d="M43.6372 93.75C42.3126 93.75 41.2961 93.4775 40.5878 92.9321C39.8865 92.3796 39.4722 91.5188 39.3447 90.35C39.3163 90.0525 39.3801 89.8225 39.5359 89.6596C39.6918 89.4967 39.9184 89.415 40.2159 89.415C40.7613 89.415 41.0659 89.7125 41.1297 90.3075C41.2147 90.9521 41.4449 91.3913 41.8203 91.625C42.1957 91.8517 42.8013 91.965 43.6372 91.965H45.4009C46.0597 91.965 46.5732 91.8909 46.9415 91.7421C47.317 91.5934 47.579 91.3346 47.7278 90.9663C47.8765 90.5979 47.9509 90.0809 47.9509 89.415V80.235C47.9509 79.64 48.2484 79.3425 48.8434 79.3425C49.4384 79.3425 49.7359 79.64 49.7359 80.235V89.415C49.7359 90.9096 49.3853 92.0075 48.684 92.7088C47.9828 93.403 46.8884 93.75 45.4009 93.75H43.6372Z" fill="white" />
        <path d="M54.9438 93.75C54.3488 93.75 54.0513 93.4525 54.0513 92.8575V80.235C54.0513 79.64 54.3488 79.3425 54.9438 79.3425H59.9163C61.2125 79.3425 62.2042 79.6896 62.8913 80.3838C63.5854 81.0709 63.9325 82.0625 63.9325 83.3588V83.9963C63.9325 85.2925 63.5854 86.2879 62.8913 86.9821C62.2042 87.6692 61.2125 88.0125 59.9163 88.0125H55.8363V92.8575C55.8363 93.4525 55.5388 93.75 54.9438 93.75ZM55.8363 86.2275H59.9163C60.7238 86.2275 61.2975 86.0575 61.6375 85.7175C61.9775 85.3775 62.1475 84.8038 62.1475 83.9963V83.3588C62.1475 82.5584 61.9775 81.9884 61.6375 81.6484C61.2975 81.3013 60.7238 81.1275 59.9163 81.1275H55.8363V86.2275Z" fill="white" />
        <path d="M71.3334 93.75C69.7113 93.75 68.5109 93.3642 67.7317 92.5921C66.9596 91.813 66.5734 90.6121 66.5734 88.99V84.1025C66.5734 82.4663 66.9596 81.2621 67.7317 80.49C68.5038 79.7109 69.6971 79.3284 71.3121 79.3425H73.8834C75.215 79.3425 76.2317 79.6188 76.933 80.1713C77.6342 80.7167 78.0413 81.5809 78.1546 82.7638C78.1971 83.0613 78.1404 83.288 77.9846 83.4438C77.8288 83.5996 77.6021 83.6775 77.3046 83.6775C76.7663 83.6775 76.4546 83.38 76.3696 82.785C76.2988 82.1334 76.0721 81.6942 75.6896 81.4675C75.3142 81.2409 74.7121 81.1275 73.8834 81.1275H71.3121C70.5613 81.1205 69.97 81.2055 69.538 81.3825C69.113 81.5596 68.8084 81.868 68.6242 82.3071C68.4471 82.7392 68.3584 83.3375 68.3584 84.1025V88.99C68.3584 89.748 68.4471 90.343 68.6242 90.775C68.8084 91.2071 69.1163 91.5155 69.5484 91.6996C69.9804 91.8767 70.5754 91.965 71.3334 91.965H73.8834C74.5421 91.965 75.0559 91.8942 75.4242 91.7525C75.7996 91.6038 76.0617 91.3455 76.2104 90.9771C76.3592 90.6088 76.4334 90.088 76.4334 89.415V88.4163L74.7759 88.395C74.1809 88.395 73.8834 88.0975 73.8834 87.5025C73.8834 86.9075 74.1809 86.61 74.7759 86.61L77.3259 86.6313C77.9209 86.6313 78.2184 86.9288 78.2184 87.5238V89.415C78.2184 90.9238 77.8679 92.0255 77.1667 92.7196C76.4654 93.4067 75.3709 93.75 73.8834 93.75H71.3334Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M33.3584 61.0041H51.7625C53.5638 61.0021 55.2904 60.285 56.5638 59.0108C57.8371 57.7371 58.5529 56.0096 58.5542 54.2083V35.8042C58.5529 34.0028 57.8371 32.2756 56.5638 31.0015C55.2904 29.7273 53.5638 29.0105 51.7625 29.0083H33.3584C31.5567 29.0105 29.8294 29.7272 28.5554 31.0012C27.2814 32.2752 26.5647 34.0025 26.5625 35.8042V54.2083C26.5647 56.01 27.2814 57.7375 28.5554 59.0112C29.8294 60.2854 31.5567 61.0021 33.3584 61.0041ZM30.6784 33.1243C31.3893 32.4134 32.3531 32.0136 33.3584 32.0125H51.7625C52.7675 32.0136 53.7308 32.4136 54.4408 33.1245C55.1513 33.8355 55.55 34.7993 55.55 35.8042V50.5116C55.55 50.8445 55.3521 51.1541 55.0246 51.2141C53.8929 51.4208 52.2808 51.075 50.8438 50.7666C50.2625 50.642 49.7099 50.5233 49.2291 50.45C48.831 50.3891 48.4878 50.4262 48.1935 50.5246C47.4387 50.7766 46.0136 50.7516 45.7217 50.0112L44.775 47.61C44.558 47.0596 43.8529 46.9041 43.4248 47.3125L41.3331 49.3058C41.0971 49.5308 40.7517 49.5962 40.4467 49.4812C36.5502 48.012 33.1646 47.7546 30.5107 47.9737C30.009 48.015 29.5667 47.6279 29.5667 47.1241V35.8042C29.5677 34.7989 29.9676 33.8351 30.6784 33.1243Z" fill="#00C650" />
        <path d="M50.6083 39.5625C51.7383 39.5625 52.6542 38.6466 52.6542 37.5167C52.6542 36.3868 51.7383 35.4708 50.6083 35.4708C49.4784 35.4708 48.5625 36.3868 48.5625 37.5167C48.5625 38.6466 49.4784 39.5625 50.6083 39.5625Z" fill="#00C650" />
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default JpgSvg;
