import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const GeometrySvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path d="M93.1109 22.8953L51.8609 4.56194C50.6738 4.03485 49.3217 4.03485 48.1347 4.56194L6.88466 22.8953C5.23466 23.6332 4.16675 25.274 4.16675 27.0845V72.9178C4.16675 74.7282 5.23466 76.369 6.88925 77.107L48.1392 95.44C48.7305 95.7016 49.3676 95.8341 50.0001 95.8341C50.6326 95.8341 51.2697 95.7016 51.8609 95.44L93.1109 77.107C94.7659 76.369 95.8334 74.7282 95.8334 72.9178V27.0845C95.8334 25.274 94.7659 23.6332 93.1109 22.8953ZM50.0001 13.7653L79.9659 27.0845L50.0001 40.4036L20.0342 27.0845L50.0001 13.7653ZM13.3334 34.1382L45.4167 48.397V84.2016L13.3334 69.9386V34.1382Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default GeometrySvg;
