import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Input, Modal } from "antd";

import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { getBoardViewUri } from "lib/helpers/navigationUtils";
import { BoardViewInterface } from "lib/types/boardTypes";
import { MemberRoles } from "lib/types/types";
import {
  ViewMenuItemControls,
  ViewMenuItemTitle,
  ViewMenuItemWrapper
} from "layout/HeaderCustom/BoardHeader/ViewMenu/ViewMenuItem/styled";
import { okButtonProps, cancelButtonProps } from "styles/common-vars";
import useBoardSetViews from "lib/customHooks/dataLayer/board/useBoardSetViews";

interface ViewMenuItemProps {
  view: BoardViewInterface;
  onClick: () => void;
}

const ViewMenuItem: FC<ViewMenuItemProps> = (props) => {
  const { view } = props;

  const history = useHistory();
  const { board } = useBoardContext() as BoardContextInterface;
  const { updateBoardView, deleteBoardView } = useBoardSetViews({ board, updateDetailed: true });
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleEditViewOnClick: React.MouseEventHandler<HTMLImageElement> = (event) => {
    event.stopPropagation();
    setIsEditMode(true);
  };

  const handleDeleteViewOnClick: React.MouseEventHandler<HTMLImageElement> = (event) => {
    // event.stopPropagation();

    Modal.confirm({
      title: "Permanently delete the view?",
      content: "This will permanently delete the view. This item will no longer be accessible to you or anyone else. This action is irreversible.",
      okText: "Delete",
      okButtonProps: okButtonProps,
      cancelText: "Cancel",
      cancelButtonProps: cancelButtonProps,
      onOk() {
        deleteBoardView(view.id);
        history.push(getBoardViewUri(board.uid, view.view));
      }
    });
  };

  const handleUpdateView = (event: any) => {
    event.stopPropagation();
    const title = event.target?.value?.trim();

    if ((title.length > 0) && (title !== view.title)) {
      updateBoardView(view.id, { title });
    }

    setIsEditMode(false);
  };

  return (
    <ViewMenuItemWrapper>
      {isEditMode
        ? <Input
          autoFocus
          size="small"
          bordered={false}
          defaultValue={view.title}
          onBlur={handleUpdateView}
          onPressEnter={handleUpdateView}
          onClick={e => e.stopPropagation()}
          style={{ padding: 0, width: "100%" }}
        />
        : <ViewMenuItemTitle onClick={props.onClick}>{view.title}</ViewMenuItemTitle>
      }
      {(allowedToEdit && !isEditMode) &&
        <ViewMenuItemControls hidden={isEditMode}>
          <img
            style={{ padding: 5 }}
            src={process.env.PUBLIC_URL + "/icons/edit.svg"}
            alt="edit"
            onClick={handleEditViewOnClick}
          />
          <img
            style={{ padding: 5, marginRight: -5 }}
            src={process.env.PUBLIC_URL + "/icons/x.svg"}
            alt="delete"
            onClick={handleDeleteViewOnClick}
          />
        </ViewMenuItemControls>
      }
    </ViewMenuItemWrapper>
  );
};

export default ViewMenuItem;
