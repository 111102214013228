
import { useAppSelector } from "app/store";
import { TaskOptimisticUpdatePayload } from "lib/types/tasksTypes";
import TasksService from "services/TasksService";
import useTaskPerformRequest, { TaskPerformRequestHookProps } from "./useTaskPerformRequest";

const useTaskSetCollaborators = (props: TaskPerformRequestHookProps) => {
  const { task } = props;
  const performRequest = useTaskPerformRequest(props);
  const user = useAppSelector(state => state.currentUserReducer.user);
  const isCollaborator = task?.collaborators?.some(collaborator => collaborator.id === user?.id);

  const subscribeCollaborator = () => {
    if (!task || !user) {
      return undefined;
    }

    const nextState = [...task.collaborators, user];
    const requestFn = () => TasksService.subscribeCollaborator(task.id);

    const requestPayload: TaskOptimisticUpdatePayload = {
      collaborators: nextState,
    };

    return performRequest(requestPayload, requestFn);
  };

  const unsubscribeCollaborator = () => {
    if (!task) {
      return undefined;
    }

    const nextState = task.collaborators.filter(collaborator => collaborator.id !== user?.id);
    const requestFn = () => TasksService.unsubscribeCollaborator(task.id);

    const requestPayload: TaskOptimisticUpdatePayload = {
      collaborators: nextState,
    };

    return performRequest(requestPayload, requestFn);
  };

  return { subscribeCollaborator, unsubscribeCollaborator, isCollaborator };
};

export default useTaskSetCollaborators;
