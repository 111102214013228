import styled from "@emotion/styled/macro";

export const TaskCalendarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

export const TaskCalendarHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #999;
`;

export const TaskCalendarHeaderControls = styled.div`
  display: flex;
  align-items: center;
`;

export const TaskCalendarItem = styled.div<{ completed?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  margin: 6px 0;
  padding: 2px 10px;
  position: relative;
  min-height: 35px;
  border: solid 1px #e4e4e4;
  border-radius: 8px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition: border-color 200ms ease, box-shadow 200ms ease;
  cursor: pointer;
  &:hover {
    border: solid 1px #cdcdcd;
    box-shadow: 0 4px 6px 0 rgba(21, 7, 38, 0.06);
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  span {
    color: ${({ completed }) => completed ? "rgb(176, 176, 176)" : "default"};
  }
`;

export const TaskCalendarMore = styled.div`
  margin: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #333;
`;

export const TaskCalendarModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: move;
`;

export const TaskCalendarModalHeaderWeekday = styled.div`
  color: #70757a;
  font-size: 11px;
  line-height: 1.4;
  text-transform: uppercase;
`;

export const TaskCalendarModalHeaderDate = styled.div`
  font-weight: 400;
  outline: none;
  position: relative;
  font-size: 26px;
  line-height: 1.4;
  color: #3c4043;
`;
