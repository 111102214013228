interface ExportTaskOption {
  code: string;
  text: string;
}

export interface ExportTaskOptionChangeHandler {
  (checked: boolean, property: string): void;
}

export const ExportTaskIncludeOptions: Array<ExportTaskOption> = [
  {
    code: "comments",
    text: "Include comments",
  },
];
