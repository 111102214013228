import { useCallback, useEffect } from "react";

import { setTask } from "app/slices/currentTaskSlice";
import TasksService from "services/TasksService";

import useGoHome from "./routeHooks/useGoHome";
import useAsyncData from "./useAsyncData";
import { useAppDispatch } from "app/store";
import { EntityId } from "lib/types/entity";
import useAppDataLoaded from "./useAppDataLoaded";

const useGetTaskDetailed = (id: EntityId, showProgress = true) => {
  const dispatch = useAppDispatch();
  const { isLoaded: isAppDataLoaded } = useAppDataLoaded();

  const fetchTaskCallback = useCallback(() => {
    return (id) ? TasksService.getDetailTask(id) : null;
  }, [id]);

  const { data: taskData, isLoaded, isLoading, isError } = useAsyncData({
    fetchFn: fetchTaskCallback,
    loadOnMount: isAppDataLoaded,
  });

  useGoHome(isLoaded && !taskData);

  useEffect(() => {
    if (taskData) {
      dispatch(setTask(taskData));
    }
    return () => {
      dispatch(setTask(null));
    };
  }, [dispatch, taskData]);

  return { isLoaded, isLoading, isError, taskData };
};

export default useGetTaskDetailed;
