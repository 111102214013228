import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const ExpandSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
        <path d="M5.06997 87.1934C5.06997 91.3355 8.42784 94.6934 12.57 94.6934C16.7121 94.6934 20.07 91.3355 20.07 87.1934L20.07 60.5267C20.07 56.3846 16.7121 53.0267 12.57 53.0267C8.42784 53.0267 5.06997 56.3846 5.06997 60.5267L5.06997 87.1934Z"/>
        <path d="M12.5699 94.6934H39.2366C43.3787 94.6934 46.7366 91.3355 46.7366 87.1934C46.7366 83.0512 43.3787 79.6934 39.2366 79.6934H12.5699C8.4278 79.6934 5.06993 83.0512 5.06993 87.1934C5.06993 91.3355 8.4278 94.6934 12.5699 94.6934Z"/>
        <path d="M10.3033 78.7835C7.37437 81.7124 7.37437 86.4611 10.3033 89.3901C13.2322 92.319 17.981 92.319 20.9099 89.3901L60.39 49.9099C63.319 46.981 63.319 42.2323 60.39 39.3033C57.4611 36.3744 52.7124 36.3744 49.7834 39.3033L10.3033 78.7835Z"/>
        <path d="M94.6234 12.5C94.6234 8.35786 91.2655 5 87.1234 5C82.9812 5 79.6234 8.35786 79.6234 12.5V39.1667C79.6234 43.3088 82.9812 46.6667 87.1234 46.6667C91.2655 46.6667 94.6234 43.3088 94.6234 39.1667V12.5Z"/>
        <path d="M87.1234 5L60.4567 5C56.3146 5 52.9567 8.35786 52.9567 12.5C52.9567 16.6421 56.3146 20 60.4567 20H87.1234C91.2655 20 94.6234 16.6421 94.6234 12.5C94.6234 8.35786 91.2655 5 87.1234 5Z"/>
        <path d="M89.39 20.9099C92.319 17.981 92.319 13.2322 89.39 10.3033C86.4611 7.37437 81.7124 7.37437 78.7834 10.3033L39.3033 49.7834C36.3744 52.7124 36.3744 57.4611 39.3033 60.39C42.2322 63.319 46.981 63.319 49.9099 60.39L89.39 20.9099Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default ExpandSvg;
