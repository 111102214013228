import React, { FC, RefObject, useEffect, useRef, useState } from "react";
import { Input, Select } from "antd";
import OutsideClickHandler from "react-outside-click-handler";

import { useAppSelector } from "app/store";
import { DisplayView, TaskFieldSetterGetter, TaskSourceComponent } from "lib/types/applicationTypes";
import { getUserFullName } from "lib/helpers/userUtils";
import AvatarCustom from "components/ui/AvatarCustom";
import DottedPlaceholder from "components/blocks/TaskTable/components/placeholders/DottedPlaceholder";

import { FieldEmptyState } from "../../styled";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import { UserFieldWrapper, UserFieldSearch, UserFieldGroup } from "./styled";
import UserCustom from "components/ui/UserCustom";
import { CustomSelectsEmpty } from "components/ui/CustomSelects/styled";
import { EntityId } from "lib/types/entity";
import { AnonymousUser } from "components/blocks/Automations/lib";
import { useTaskContext, TaskContextInterface } from "lib/contexts/TaskContext";

interface UserCellProps extends TaskFieldSetterGetter {
  displayView: DisplayView;
  component: TaskSourceComponent;
  propertyType: TaskPropertyClass;
}

const { Option, OptGroup } = Select;

const UserGeneric: FC<UserCellProps> = (props) => {
  const { code, component, value, setValue, propertyType } = props;
  const { task } = useTaskContext() as TaskContextInterface;

  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);
  const workspaceId = task.board.workspace;

  const workspaces = useAppSelector(state => state.workspaces.workspaces);
  const sourceWorkspace = workspaces.find(workspace => String(workspace.id) === String(workspaceId));
  const collaborators = sourceWorkspace?.collaborators || [];
  const initialSuggestions = collaborators.map(user => ({ display: getUserFullName(user), id: user.id, user: user }));

  const [selectedUser, setSelectedUser] = useState(value);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(initialSuggestions);

  const wrapperRef = useRef(null) as RefObject<any>;
  const selectDropdownParent = wrapperRef && wrapperRef.current ? wrapperRef.current.offsetParent : wrapperRef.current;

  useEffect(() => {
    setSelectedUser(value);
  }, [value]);

  useEffect(() => {
    if (!selectedUser && ([TaskPropertyClass.System, TaskPropertyClass.Default].includes(propertyType))) {
      setSelectedUser(AnonymousUser);
    }
  }, [propertyType, selectedUser]);

  const handleSelectOnChange = (userId: EntityId) => {
    const newUser = collaborators?.find(u => String(u.id) === String(userId));
    if (newUser) {
      setSelectedUser(newUser);
      setValue({ cellName: code, cellValue: newUser });
    }

    if (userId === "") {
      setSelectedUser(undefined);
      setValue({ cellName: code, cellValue: null });
    }
  };

  const handleSelectClick = (e: React.MouseEvent) => {
    setIsSelectOpen(!isSelectOpen);
  };

  const handleOnOutsideClick = (e: MouseEvent) => {
    if (component === TaskSourceComponent.Detailed) {
      const dropdownElement = (e.target as HTMLElement).closest(".ant-select-dropdown");
      if (!dropdownElement) {
        setIsSelectOpen(false);
      }
    }

    if (selectDropdownParent && !selectDropdownParent.contains(e.target)) {
      setIsSelectOpen(false);
    }
  };

  const handleSearchOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value?.toLowerCase().trim();
    if (value?.length) {
      const nextState = initialSuggestions.filter(suggestion => suggestion.display?.toLowerCase().includes(value));
      setSuggestions(nextState);
    } else {
      setSuggestions(initialSuggestions);
    }
  };

  const renderEmptyState = () => {
    return (component === TaskSourceComponent.Detailed)
      ? <FieldEmptyState>Empty</FieldEmptyState>
      : <DottedPlaceholder icon="/icons/assignees.svg" />;
  };

  const renderOptions = () => (
    <OptGroup label="Select a person">
      <Option value="" key="empty-option">
        <CustomSelectsEmpty>Empty</CustomSelectsEmpty>
      </Option>
      {suggestions?.map(user => (
        <Option value={user.id} key={user.id} >
          <UserCustom user={user.user} name={user.display} />
        </Option>
      ))}
    </OptGroup>
  );

  return (
    <UserFieldWrapper onClick={handleSelectClick} ref={wrapperRef}>
      <OutsideClickHandler onOutsideClick={handleOnOutsideClick}>
        {(!selectedUser) &&
          renderEmptyState()
        }
        {(!!selectedUser) &&
          <AvatarCustom user={selectedUser} tooltip />
        }
        {(propertyType !== TaskPropertyClass.System) &&
          <Select
            // @ts-ignore
            disabled={!allowedToEdit}
            value={selectedUser?.id || ""}
            onChange={handleSelectOnChange}
            open={isSelectOpen}
            getPopupContainer={() => selectDropdownParent}
            dropdownStyle={{ minWidth: 180, maxWidth: 240 }}
            dropdownRender={menu => (
              <>
                <UserFieldSearch>
                  <Input
                    allowClear
                    placeholder="Search person…"
                    onChange={handleSearchOnChange}
                    bordered={false}
                    onKeyDown={e => e.stopPropagation()}
                    onClick={e => e.stopPropagation()}
                  />
                </UserFieldSearch>
                <UserFieldGroup>
                  {menu}
                </UserFieldGroup>
              </>
            )}
          >
            {renderOptions()}
          </Select>
        }
      </OutsideClickHandler>
    </UserFieldWrapper >
  );
};

export default UserGeneric;
