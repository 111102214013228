import { FC, useEffect, useState } from "react";
import { ColumnInstance } from "react-table";
import { Form } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { sortBy } from "lodash";

import { useAppSelector } from "app/store";
import BoardService from "services/BoardService";
import { EntityId } from "lib/types/entity";
import { Column } from "lib/types/tasksTypes";
import useBoardSetColumns from "lib/customHooks/dataLayer/board/useBoardSetColumns";
import useSavingState from "lib/customHooks/useSavingState";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import TextField from "components/blocks/FormComponents/TextField";
import { TwoColumnGrid } from "styles/common";
import CustomStyledButton from "components/blocks/CustomStyledButton";

import { customFields, syncedBim360Fields, systemFields, TaskFieldInterface } from "../../FieldsConfig";
import FieldAdditional from "../../FieldAdditional";
import TypeSelect from "../TypeSelect";
import { TaskFieldsModalFormProps } from "../../lib";

import { ButtonCancel } from "../styled";
import { Footer } from "./styled";

interface ModalFormProps {
  onCancel: () => void;
  columnData?: ColumnInstance<Column>;
}

const ModalForm: FC<ModalFormProps> = (props) => {
  const { columnData, onCancel } = props;
  const fields = [...customFields, ...systemFields, ...syncedBim360Fields];
  const [form] = Form.useForm<TaskFieldsModalFormProps>();

  const board = useAppSelector(state => state.currentBoardReducer.board);
  const currentField = fields.find(item => item.code === columnData?.code);

  const { activateBoardColumn, updateBoardColumn } = useBoardSetColumns({ board, updateDetailed: true });
  const { setSavingStart, setSavingFinish } = useSavingState();

  const [fieldType, setFieldType] = useState<Partial<TaskFieldInterface> | null>(null);
  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [isFieldSystem, setFieldSystem] = useState<boolean>(false);
  const [isCreating, setCreating] = useState<boolean>(false);

  useEffect(() => {
    if (columnData?.type) {
      handleSelectFieldOnChange(columnData.type, columnData.code);
    }
    if (columnData?.class) {
      setFieldSystem(columnData.class !== TaskPropertyClass.Custom);
    }
  }, [columnData]);

  useEffect(() => {
    if (fieldType?.name) {
      const prevTitle = form.getFieldValue("title");
      const nextTitle = (isFieldSystem) ? fieldType.name : prevTitle;

      form.setFieldsValue({ title: nextTitle });
      checkSubmitAvailability(nextTitle);
    }
  }, [fieldType, form, isFieldSystem]);

  const handleCancelModal = () => {
    onCancel();
    setSubmitDisabled(true);
    setFieldSystem(false);
    if (!columnData) {
      setFieldType(null);
    }
    form.resetFields();
  };

  const handleCreateButtonClick = () => {
    if (!board || !fieldType?.code) {
      return;
    }

    form.validateFields()
      .then(async (values) => {
        values.title = values.title.trim();
        values.options = (values.options || []).filter((option: any) => option);
        values.options = sortBy(values.options, item => item.index).map((value: any) =>
          ({ sort: value.index, id: value.id, title: value.title || "", color: value.color })
        );

        setSavingStart();
        setCreating(true);

        if (columnData) {
          updateBoardColumn(columnData.id, values);
          return;
        }

        if (isFieldSystem) {
          activateBoardColumn(values.type);
        } else {
          await BoardService.addBoardColumn(board.id, values);
        }
      })
      .then(handleCancelModal)
      .catch(() => setCreating(false))
      .finally(() => setSavingFinish());
  };

  const checkSubmitAvailability = (type: string) => {
    if (isFieldSystem) {
      setSubmitDisabled(false);
    } else {
      const title = form.getFieldValue("title");

      const isTitleExist = Boolean(title && title.trim());
      const isTheSameColumnExist = checkIfTitleExists(title, columnData?.id);
      const isSubmitDisabled = !isTitleExist || !type || isTheSameColumnExist;

      setSubmitDisabled(isSubmitDisabled);
    }
  };

  const checkIfTitleExists = (title: string, columnId?: EntityId) => {
    const findColumn = board?.columns
      .filter(column => String(column.id) !== String(columnId))
      .find(column => column?.title?.trim().toLowerCase() === title?.trim().toLowerCase());
    return Boolean(findColumn);
  };

  const renderAdditionalFields = () => {
    if (fieldType) {
      return <FieldAdditional data={fieldType} form={form} />;
    } else {
      return null;
    }
  };

  const handleFormOnValuesChange = (changedValues: Record<string, any>, allValues: Record<string, any>) => {
    const field = fields.find(item => item.code === changedValues.type);
    if (field) {
      setFieldType(field);
    }
    checkSubmitAvailability(allValues.type);
  };

  const handleSelectFieldOnChange = (value: string, code?: string) => {
    const field = fields.find(item => item.code === value);

    if (field) {
      const isSystemOrSynced = [TaskPropertyClass.System, TaskPropertyClass.Synced].includes(field.class);

      // Reset title when changing from system/synced back to custom
      if (isFieldSystem && !isSystemOrSynced) {
        form.setFieldsValue({ title: "" });
      }

      setFieldSystem(isSystemOrSynced);

      const nextFieldState = { ...field };

      if (code && columnData) {
        nextFieldState.name = columnData?.title;
      }

      setFieldType(nextFieldState);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={handleFormOnValuesChange}
      preserve={false}
      initialValues={columnData}
      requiredMark={false}
    >
      <TwoColumnGrid>
        <div>
          <TextField
            label="Field title"
            name="title"
            placeholder="Type field title"
            disabled={isFieldSystem}
            rules={
              [
                { required: true, message: "required", whitespace: true },
                {
                  validator: async (_: any, fieldValue: string) => {
                    const isColumnExists = checkIfTitleExists(fieldValue, columnData?.id);
                    if (isColumnExists && !isFieldSystem) {
                      return Promise.reject();
                    }
                  },
                  message: "There is already a column with the same title",
                }
              ]
            }
          />
        </div>
        <div>
          <TypeSelect
            showSearch
            label="Field type"
            property="type"
            placeholder="Select field type"
            onChange={handleSelectFieldOnChange}
            disabled={!!columnData}
            filterOption={(input: string, option: DefaultOptionType) => {
              const foundKeys = String(option?.value).toLowerCase().indexOf(input.toLowerCase()) >= 0;
              // @ts-ignore
              const foundTitles = String(option?.children?.props.children[1]).toLowerCase().indexOf(input.toLowerCase()) >= 0;
              const found = foundKeys || foundTitles;
              return found;
            }}
            rules={
              [{ required: true, message: "required" }]
            }
          />
        </div>
      </TwoColumnGrid>

      {renderAdditionalFields()}

      <Footer>
        <ButtonCancel key="cancel" onClick={handleCancelModal}>
          Cancel
        </ButtonCancel>
        <CustomStyledButton
          key="ok"
          btnColor="purple"
          onClick={handleCreateButtonClick}
          disabled={isSubmitDisabled || isCreating}
          isLoading={isCreating}
          value={columnData ? "Update field" : "Create field"}
        />
      </Footer>
    </Form>
  );
};

export default ModalForm;
