import { FC, useEffect, useState } from "react";
import { Dropdown } from "antd";

import icons from "lib/helpers/iconsArr";
import { BoardChangeIconGrid } from "./styled";
import IconComponent from "components/ui/IconComponent";
import { MemberRoles } from "lib/types/types";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import useBoardSetValue from "lib/customHooks/dataLayer/board/useBoardSetValue";
import { BoardHeaderIconWrapper, BoardHeaderIcon } from "layout/HeaderCustom/BoardHeader/styled";
import { getBoardIconPath } from "lib/helpers/boardUtils";

interface BoardChangeIconProps {
  onClick?: () => void;
}

const BoardChangeIcon: FC<BoardChangeIconProps> = (props) => {
  const { board } = useBoardContext() as BoardContextInterface;
  const { setBoardValue } = useBoardSetValue({ board, updateDetailed: true });
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const [currentBoardIcon, setCurrentBoardIcon] = useState(board.icon);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setCurrentBoardIcon(board.icon);
  }, [board.icon]);

  const toggleVisibility = () => setIsDropdownOpen(!isDropdownOpen);

  const handleOnClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
    if (props.onClick) {
      props.onClick();
    }
  };

  const onClickIcon = async (icon: string) => {
    if (icon !== board?.icon && allowedToEdit) {
      setCurrentBoardIcon(icon);
      setBoardValue({ icon });
      toggleVisibility();
    }
  };

  const iconMenu = (
    <BoardChangeIconGrid>
      {icons.map(icon =>
        <IconComponent
          key={icon}
          icon={icon}
          disabled={!allowedToEdit}
          onClick={() => onClickIcon(icon)}
        />
      )}
    </BoardChangeIconGrid>
  );

  return (
    <Dropdown
      overlay={iconMenu}
      trigger={["click"]}
      open={isDropdownOpen}
      onOpenChange={toggleVisibility}
    >
      <BoardHeaderIconWrapper onClick={handleOnClick}>
        <BoardHeaderIcon src={getBoardIconPath(currentBoardIcon)} alt="board-icon" />
      </BoardHeaderIconWrapper>
    </Dropdown>
  );
};

export default BoardChangeIcon;
