import { Dropdown, Menu } from "antd";
import { useAppSelector } from "app/store";
import ConditionalWrapper from "components/blocks/ConditionalWrapper";

import DeleteBoardModal from "components/blocks/DeleteBoardModal";
import DuplicateBoardModal from "components/blocks/DuplicateBoardModal";
import ExportBoardModal from "components/blocks/ExportBoardModal";
import ImportBoardModal from "components/blocks/ImportBoardModal";
import SavingStateIcon from "components/blocks/SavingStateIcon";
import CalendarSvg from "components/icons/calendar";
import DuplicateSvg from "components/icons/resizable/duplicate";
import ExportSvg from "components/icons/export";
import DueDatesSvg from "components/icons/due-date";
import FilesSvg from "components/icons/resizable/files";
import FormSvg from "components/icons/form";
import ImportSvg from "components/icons/import";
import KanbanSvg from "components/icons/kanban";
import ListSvg from "components/icons/list";
import ModelSvg from "components/icons/model";
import TrashSvg from "components/icons/resizable/trash";
import UrlSvg from "components/icons/resizable/url";
import ButtonControls from "components/ui/ButtonControls";
import AddSvg from "components/ui/ButtonControls/icons/add";
import MenuItemIcon from "components/ui/MenuItemIcon";
import SkeletonHeader from "components/ui/Skeletons/Header";
import AddViewModal from "layout/HeaderCustom/BoardHeader/AddViewModal";
import ViewMenu from "layout/HeaderCustom/BoardHeader/ViewMenu";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import useWorkspaceById from "lib/customHooks/useWorkspaceById";
import useBoardView from "lib/customHooks/views/useBoardView";
import {
  copyLink,
  getBoardModelUri,
  getBoardSettingsUri,
  getBoardUri,
  getBoardViewUri
} from "lib/helpers/navigationUtils";
import { DisplayView, DropdownTrigger } from "lib/types/applicationTypes";
import { EntityId } from "lib/types/entity";
import { MemberRoles } from "lib/types/types";
import BoardChangeIcon from "pages/Board/BoardChangeIcon";
import { BoardDefaultDisplayView } from "pages/Board/BoardSettings/BoardSettingsDisplayView/lib";
import { TaskCommentEditControl } from "pages/Task/TaskComment/styled";
import { FC, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageTitle } from "styles/common";
import { HeaderCustomSettingsBtn, HeaderCustomWrapper } from "../styled";
import { BoardViewTabInterface, getCustomViewTitle } from "./lib";
import {
  BoardHeaderBoardData,
  BoardHeaderBoardTitle,
  BoardHeaderContainer,
  BoardHeaderView,
  BoardHeaderViewIcon,
  BoardHeaderViews,
  BoardHeaderViewTab,
  BoardHeaderViewTitle,
  BoardHeaderWrapper
} from "./styled";
import { ViewMenuBadge } from "./ViewMenu/styled";

interface BoardHeaderProps {
  workspaceId: EntityId;
  onClick?: () => void;
  isLoading?: boolean;
  showSavingState?: boolean;
}

const BoardHeader: FC<BoardHeaderProps> = (props) => {
  const { isLoading, showSavingState, workspaceId } = props;

  const history = useHistory();
  const params = useParams<{ id: string, view: string }>();
  const { board } = useBoardContext() as BoardContextInterface;
  const tasks = useAppSelector(state => state.tasks.tasks);
  const workspace = useWorkspaceById(workspaceId);
  const viewData = useBoardView();
  const isCustomView = !Object.values(DisplayView).includes(params.view as DisplayView);
  const currentDisplayView = isCustomView ? viewData?.view : (params.view as DisplayView);

  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isAddViewModalVisible, setIsAddViewModalVisible] = useState(false);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);
  const [addViewModalInitialState, setAddViewModalInitialState] = useState(DisplayView.List);

  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const allowedToDelete = useMemberPermission([MemberRoles.Owner]);

  const isExportAvailable = (tasks.length > 0);
  const isImportAvailable = !board?.externalDataSource && allowedToEdit;

  let ViewTabs: Array<BoardViewTabInterface> = [
    {
      title: getCustomViewTitle(DisplayView.List, viewData) || "List",
      displayView: DisplayView.List,
      path: getBoardViewUri(board?.uid, DisplayView.List, viewData),
      icon: <ListSvg />
    },
    {
      title: getCustomViewTitle(DisplayView.Kanban, viewData) || "Kanban",
      displayView: DisplayView.Kanban,
      path: getBoardViewUri(board?.uid, DisplayView.Kanban, viewData),
      icon: <KanbanSvg />
    },
    {
      title: getCustomViewTitle(DisplayView.Calendar, viewData) || "Calendar",
      displayView: DisplayView.Calendar,
      path: getBoardViewUri(board?.uid, DisplayView.Calendar, viewData),
      icon: <DueDatesSvg />
    },
    {
      title: getCustomViewTitle(DisplayView.Form, viewData) || "Form",
      displayView: DisplayView.Form,
      path: getBoardViewUri(board?.uid, DisplayView.Form, viewData),
      icon: <FormSvg />
    },
    {
      title: getCustomViewTitle(DisplayView.Files, viewData) || "Files",
      displayView: DisplayView.Files,
      path: getBoardViewUri(board?.uid, DisplayView.Files, viewData),
      icon: <FilesSvg />,
    },
    {
      title: "Model",
      displayView: DisplayView.Model,
      path: getBoardModelUri((workspace?.uid || workspaceId), board?.uid),
      icon: <ModelSvg />,
      onClickOverride: (event) => {
        event.stopPropagation();
        event.preventDefault();
        window.location.replace(getBoardModelUri((workspace?.uid || workspaceId), board?.uid));
      }
    },
  ];

  const displayViews = board?.displayView || BoardDefaultDisplayView;
  // @ts-expect-error
  ViewTabs = ViewTabs.filter(viewTab => displayViews[viewTab.displayView]);

  const boardDropdown = (
    <Menu>
      <Menu.Item key="boardDropdownCopy" onClick={() => copyLink(getBoardUri(board?.uid))}>
        <MenuItemIcon text="Copy board link" icon={<UrlSvg size={12} />} />
      </Menu.Item>
      {allowedToEdit &&
        <Menu.Item key="boardDropdownDuplicate" onClick={() => setIsDuplicateModalVisible(true)}>
          <MenuItemIcon text="Duplicate board" icon={<DuplicateSvg size={12} />} />
        </Menu.Item>
      }
      {isImportAvailable &&
        <Menu.SubMenu
          key="importSubMenu"
          title={<MenuItemIcon text="Import data" icon={<ImportSvg />} />}
        >
          <Menu.Item
            key="excel"
            onClick={() => setIsImportModalVisible(true)}
          >
            Microsoft Excel
          </Menu.Item>
          <Menu.Item
            key="navisworks"
            onClick={() => history.push(getBoardModelUri(String(workspace?.uid), board.uid))}
          >
            Navisworks XML
          </Menu.Item>
        </Menu.SubMenu>

      }
      {isExportAvailable &&
        <Menu.Item key="boardDropdownExport" onClick={() => setIsExportModalVisible(true)}>
          <MenuItemIcon text="Export data" icon={<ExportSvg />} />
        </Menu.Item>
      }
      {allowedToDelete &&
        <>
          <Menu.Divider />
          <Menu.Item key="boardDropdownDelete" onClick={() => setIsDeleteModalVisible(true)}>
            <MenuItemIcon text="Delete project" icon={<TrashSvg size={12} />} red />
          </Menu.Item>
        </>
      }
    </Menu >
  );

  const wrapViewMenuItemFn = (children: JSX.Element, displayView: DisplayView, trigger: Array<DropdownTrigger>) => (
    <ViewMenu
      displayView={displayView}
      setIsAddViewModalVisible={setIsAddViewModalVisible}
      setDisplayView={setAddViewModalInitialState}
      trigger={trigger}
    >
      {children}
    </ViewMenu>
  );

  return (
    <HeaderCustomWrapper>
      {isLoading &&
        <SkeletonHeader />
      }
      {!isLoading && board && (
        <>
          <BoardHeaderWrapper>
            <BoardChangeIcon onClick={props.onClick} />
            <BoardHeaderBoardData>
              <BoardHeaderBoardTitle>
                <PageTitle>{board?.name}</PageTitle>
                <Dropdown overlay={boardDropdown} trigger={["click"]}>
                  <TaskCommentEditControl>
                    <img alt="" src="/icons/down_6px.svg" />
                  </TaskCommentEditControl>
                </Dropdown>
                {isImportModalVisible &&
                  <ImportBoardModal
                    boardId={board?.id}
                    visible={isImportModalVisible}
                    onClose={() => setIsImportModalVisible(false)}
                  />
                }
                {isExportModalVisible &&
                  <ExportBoardModal
                    boardId={board?.id}
                    visible={isExportModalVisible}
                    onClose={() => setIsExportModalVisible(false)}
                  />
                }
                {isDeleteModalVisible &&
                  <DeleteBoardModal
                    boardName={board?.name}
                    boardId={board?.id}
                    visible={isDeleteModalVisible}
                    onClose={() => setIsDeleteModalVisible(false)}
                  />
                }
                {isDuplicateModalVisible &&
                  <DuplicateBoardModal
                    boardId={board?.id}
                    visible={isDuplicateModalVisible}
                    onClose={() => setIsDuplicateModalVisible(false)}
                  />
                }
              </BoardHeaderBoardTitle>
              <HeaderCustomSettingsBtn to={getBoardSettingsUri(board?.uid)}>
                Settings
              </HeaderCustomSettingsBtn>
            </BoardHeaderBoardData>
            <BoardHeaderContainer>
              <BoardHeaderViews>
                {ViewTabs.map((item) => {
                  const ignoreDropDown = [DisplayView.Model, DisplayView.Files];
                  const viewsFiltered = board?.views.filter(view => view.view === item.displayView);
                  const viewsCount = 1 + (viewsFiltered?.length || 0);
                  const isViewEnabled = !!board && (!ignoreDropDown.includes(item.displayView));
                  const isActive = currentDisplayView === item.displayView;

                  return (
                    <BoardHeaderView key={item.path}>
                      <ConditionalWrapper
                        condition={isViewEnabled}
                        wrapper={children => wrapViewMenuItemFn(children, item.displayView, ["contextMenu", ...(isActive ? ["click" as DropdownTrigger] : [])])}
                      >
                        <BoardHeaderViewTab to={item.path} onClick={item.onClickOverride}>
                          <BoardHeaderViewIcon>
                            {item.icon}
                          </BoardHeaderViewIcon>
                          <BoardHeaderViewTitle>
                            {item.title}
                          </BoardHeaderViewTitle>
                        </BoardHeaderViewTab>
                      </ConditionalWrapper>

                      {isViewEnabled && (viewsCount > 1) &&
                        <ConditionalWrapper
                          condition={isViewEnabled}
                          wrapper={children => wrapViewMenuItemFn(children, item.displayView, ["click", "contextMenu"])}
                        >
                          <ViewMenuBadge>
                            {viewsCount}
                          </ViewMenuBadge>
                        </ConditionalWrapper>
                      }
                    </BoardHeaderView>
                  );
                })}
              </BoardHeaderViews>
              {(allowedToEdit && board) &&
                <div style={{ margin: "-1px 7px 0px -2px" }}>
                  <ButtonControls
                    text="Add view"
                    onClick={() => setIsAddViewModalVisible(true)}
                    icon={<AddSvg />}
                    buttonStyle={{ fontSize: 13, fontWeight: 600 }}
                  />
                  {isAddViewModalVisible &&
                    <AddViewModal
                      visible={isAddViewModalVisible}
                      onClose={() => setIsAddViewModalVisible(false)}
                      initialView={addViewModalInitialState}
                    />
                  }
                </div>
              }
            </BoardHeaderContainer>
            {showSavingState &&
              <SavingStateIcon />
            }
          </BoardHeaderWrapper>
        </>
      )}
    </HeaderCustomWrapper>
  );
};

export default BoardHeader;
