import { dequeueTransaction, enqueueTransaction } from "app/slices/progressSlice";
import { useAppDispatch } from "app/store";

const useSavingState = () => {
  const dispatch = useAppDispatch();

  const setSavingStart = () => dispatch(enqueueTransaction());
  const setSavingFinish = () => dispatch(dequeueTransaction());

  return { setSavingStart, setSavingFinish };
};

export default useSavingState;
