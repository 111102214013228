import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const SvgSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M68.2542 20.5167C65.9763 20.5145 63.7921 19.6086 62.1813 17.9978C60.5704 16.387 59.6646 14.203 59.6625 11.925V0H21.2751C18.3002 0 15.4471 1.18173 13.3436 3.28527C11.2401 5.38879 10.0583 8.24183 10.0583 11.2167V78.8042C10.0583 81.7792 11.2401 84.6321 13.3436 86.7354C15.4471 88.8392 18.3002 90.0208 21.2751 90.0208H67.3583C68.8313 90.0208 70.2896 89.7308 71.65 89.1671C73.0108 88.6033 74.2471 87.7771 75.2883 86.7354C76.3296 85.6938 77.1554 84.4571 77.7183 83.0963C78.2817 81.7354 78.5713 80.2771 78.5708 78.8042V20.5208L68.2542 20.5167Z" fill="#FF9908"/>
        <path d="M78.5501 20.5167H68.2334C65.9555 20.5145 63.7713 19.6086 62.1605 17.9978C60.5501 16.387 59.6438 14.203 59.6417 11.925V0L78.5501 20.5167Z" fill="#FF9908"/>
        <path d="M82.9541 71.0833H30.8541C27.1584 71.0833 24.1625 74.0791 24.1625 77.775V93.3083C24.1625 97.0041 27.1584 100 30.8541 100H82.9541C86.65 100 89.6458 97.0041 89.6458 93.3083V77.775C89.6458 74.0791 86.65 71.0833 82.9541 71.0833Z" fill="#FF9908"/>
        <path d="M41.2916 93.75C40.0804 93.75 39.1348 93.5059 38.4548 93.0171C37.7818 92.5284 37.3604 91.7738 37.1904 90.7538C37.162 90.4421 37.2258 90.2084 37.3816 90.0525C37.5375 89.8967 37.7641 89.8188 38.0616 89.8188C38.3591 89.8188 38.5752 89.8896 38.7098 90.0313C38.8514 90.1729 38.947 90.3925 38.9966 90.69C39.1029 91.1788 39.3295 91.5154 39.6766 91.6996C40.0308 91.8767 40.5691 91.965 41.2916 91.965H44.3516C45.2654 91.965 45.8852 91.8021 46.211 91.4763C46.5439 91.1434 46.7104 90.5271 46.7104 89.6275C46.7104 88.7138 46.5404 88.0904 46.2004 87.7575C45.8604 87.4175 45.2441 87.2475 44.3516 87.2475H41.7166C40.3779 87.2475 39.3827 86.9217 38.731 86.27C38.0864 85.6113 37.7641 84.6125 37.7641 83.2738C37.7641 81.9421 38.0829 80.9542 38.7204 80.3096C39.365 79.665 40.3566 79.3425 41.6954 79.3425H44.2241C45.3504 79.3425 46.2323 79.5729 46.8698 80.0334C47.5143 80.4938 47.9216 81.1984 48.0916 82.1475C48.1341 82.4521 48.0775 82.6859 47.9216 82.8488C47.7658 83.0046 47.532 83.0825 47.2204 83.0825C46.937 83.0825 46.7245 83.0084 46.5829 82.8596C46.4412 82.7109 46.3491 82.4946 46.3066 82.2113C46.1933 81.7934 45.9773 81.51 45.6585 81.3613C45.3398 81.2054 44.8616 81.1275 44.2241 81.1275H41.6954C40.8454 81.1275 40.2752 81.28 39.9848 81.5846C39.6943 81.8821 39.5491 82.445 39.5491 83.2738C39.5491 84.1096 39.6979 84.6834 39.9954 84.995C40.2929 85.3067 40.8666 85.4625 41.7166 85.4625H44.3516C45.747 85.4625 46.7848 85.8025 47.4648 86.4825C48.1518 87.1625 48.4954 88.2109 48.4954 89.6275C48.4954 91.0371 48.1554 92.0784 47.4754 92.7513C46.7954 93.4171 45.7541 93.75 44.3516 93.75H41.2916Z" fill="white"/>
        <path d="M56.9291 93.75C56.5608 93.75 56.2704 93.5162 56.0579 93.0487L50.8091 80.6175C50.7029 80.3695 50.6745 80.15 50.7241 79.9587C50.7737 79.7604 50.9366 79.5975 51.2129 79.47C51.7937 79.1795 52.2046 79.3283 52.4454 79.9162L57.4071 91.6675H56.7908L61.6254 79.9162C61.8591 79.3354 62.27 79.1866 62.8579 79.47C63.1058 79.5975 63.2579 79.7604 63.3146 79.9587C63.3783 80.15 63.3608 80.3695 63.2616 80.6175L57.9704 93.0487C57.7508 93.5162 57.4321 93.75 57.0141 93.75H56.9291Z" fill="white"/>
        <path d="M69.8925 93.75C68.2704 93.75 67.07 93.3641 66.2908 92.5921C65.5187 91.8129 65.1324 90.6121 65.1324 88.99V84.1025C65.1324 82.4662 65.5187 81.2621 66.2908 80.49C67.0629 79.7108 68.2562 79.3283 69.8712 79.3425H72.4424C73.7741 79.3425 74.7908 79.6187 75.492 80.1712C76.1933 80.7166 76.6004 81.5808 76.7137 82.7637C76.7562 83.0612 76.6995 83.2879 76.5437 83.4437C76.3879 83.5996 76.1612 83.6775 75.8637 83.6775C75.3254 83.6775 75.0137 83.38 74.9287 82.785C74.8579 82.1333 74.6312 81.6942 74.2487 81.4675C73.8733 81.2408 73.2712 81.1275 72.4424 81.1275H69.8712C69.1204 81.1204 68.5291 81.2054 68.097 81.3825C67.672 81.5596 67.3675 81.8679 67.1833 82.3071C67.0062 82.7392 66.9175 83.3375 66.9175 84.1025V88.99C66.9175 89.7479 67.0062 90.3429 67.1833 90.775C67.3675 91.2071 67.6754 91.5154 68.1074 91.6996C68.5395 91.8766 69.1345 91.965 69.8925 91.965H72.4424C73.1012 91.965 73.6149 91.8941 73.9833 91.7525C74.3587 91.6037 74.6208 91.3454 74.7695 90.9771C74.9183 90.6087 74.9924 90.0879 74.9924 89.415V88.4162L73.3349 88.395C72.7399 88.395 72.4424 88.0975 72.4424 87.5025C72.4424 86.9075 72.7399 86.61 73.3349 86.61L75.8849 86.6312C76.4799 86.6312 76.7775 86.9287 76.7775 87.5237V89.415C76.7775 90.9237 76.427 92.0254 75.7258 92.7196C75.0245 93.4066 73.9299 93.75 72.4424 93.75H69.8925Z" fill="white"/>
        <path d="M57.5709 53.2209H56.5334C56.528 49.0159 54.8546 44.985 51.8809 42.0121C48.9073 39.039 44.8758 37.3669 40.6709 37.3625C36.4663 37.3669 32.4353 39.0392 29.4622 42.0121C26.4892 44.9854 24.817 49.0163 24.8125 53.2209H23.7709C23.7764 48.7409 25.5588 44.4459 28.7271 41.2783C31.8954 38.1108 36.1908 36.3294 40.6709 36.3251C45.151 36.3294 49.4463 38.1108 52.6146 41.2783C55.783 44.4459 57.5655 48.7409 57.5709 53.2209Z" fill="#FF9908"/>
        <path d="M64.6833 36.3251H16.6583V37.3625H64.6833V36.3251Z" fill="#FF9908"/>
        <path d="M25.1167 51.5625H23.4625C23.0022 51.5625 22.6292 51.9354 22.6292 52.3958V54.05C22.6292 54.5104 23.0022 54.8833 23.4625 54.8833H25.1167C25.5769 54.8833 25.95 54.5104 25.95 54.05V52.3958C25.95 51.9354 25.5769 51.5625 25.1167 51.5625Z" fill="#FF9908"/>
        <path d="M57.875 51.5625H56.2208C55.7604 51.5625 55.3875 51.9354 55.3875 52.3958V54.05C55.3875 54.5104 55.7604 54.8833 56.2208 54.8833H57.875C58.3354 54.8833 58.7083 54.5104 58.7083 54.05V52.3958C58.7083 51.9354 58.3354 51.5625 57.875 51.5625Z" fill="#FF9908"/>
        <path d="M41.4958 35.1292H39.8416C39.3814 35.1292 39.0083 35.5023 39.0083 35.9625V37.6167C39.0083 38.0769 39.3814 38.45 39.8416 38.45H41.4958C41.956 38.45 42.3291 38.0769 42.3291 37.6167V35.9625C42.3291 35.5023 41.956 35.1292 41.4958 35.1292Z" fill="#FF9908"/>
        <path d="M16.6042 38.4542C17.5223 38.4542 18.2667 37.7099 18.2667 36.7917C18.2667 35.8735 17.5223 35.1292 16.6042 35.1292C15.686 35.1292 14.9417 35.8735 14.9417 36.7917C14.9417 37.7099 15.686 38.4542 16.6042 38.4542Z" fill="#FF9908"/>
        <path d="M64.5249 38.4542C65.4433 38.4542 66.1874 37.7099 66.1874 36.7917C66.1874 35.8735 65.4433 35.1292 64.5249 35.1292C63.6066 35.1292 62.8624 35.8735 62.8624 36.7917C62.8624 37.7099 63.6066 38.4542 64.5249 38.4542Z" fill="#FF9908"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default SvgSvg;
