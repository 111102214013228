import { FC, memo } from "react";
import { isEqual } from "lodash";

import FieldTitle from "components/blocks/FieldTitle";
import BooleanField from "components/blocks/TaskFields/Components/Boolean";
import CurrencyField from "components/blocks/TaskFields/Components/Currency";
import DateField from "components/blocks/TaskFields/Components/Date";
import FilesField from "components/blocks/TaskFields/Components/Files";
import IdField from "components/blocks/TaskFields/Components/Id";
import MultiLineTextField from "components/blocks/TaskFields/Components/MultiLineText";
import MultiSelectField from "components/blocks/TaskFields/Components/MultiSelect";
import NumberField from "components/blocks/TaskFields/Components/Number";
import PercentField from "components/blocks/TaskFields/Components/Percent";
import SingleLineTextField from "components/blocks/TaskFields/Components/SingleLineText";
import SingleSelectField from "components/blocks/TaskFields/Components/SingleSelect";
import UrlField from "components/blocks/TaskFields/Components/Url";
import UserField from "components/blocks/TaskFields/Components/User";
import UsersField from "components/blocks/TaskFields/Components/Users";
import ViewpointsField from "components/blocks/TaskFields/Components/Viewpoints";
import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import { DisplayView, TaskSourceComponent } from "lib/types/applicationTypes";
import { PropertyValueType } from "lib/types/dataTypes";
import { TaskPropertyValue } from "lib/types/kanbanTypes";

import { TaskDetailedContent, TaskDetailedHeader, TaskDetailedRow } from "./styled";
import DateTimeField from "components/blocks/TaskFields/Components/DateTime";
import BarcodeField from "components/blocks/TaskFields/Components/Barcode";
import AnnotationsField from "components/blocks/TaskFields/Components/Annotations";

interface TaskDetailedPropertyProps {
  propertyValue: TaskPropertyValue;
  displayView: DisplayView;
  component: TaskSourceComponent;
  customization?: Partial<Record<PropertyValueType, any>>
}

const TaskDetailedProperty: FC<TaskDetailedPropertyProps> = (props) => {
  const { propertyValue, displayView, component, customization } = props;

  const { setValue } = useTaskContext() as TaskContextInterface;
  const taskProperties = { ...propertyValue, setValue, displayView, component, propertyType: propertyValue.class };

  const renderTaskPropertyValueComponent = () => {
    switch (propertyValue.type) {

      case PropertyValueType.Boolean:
        return <BooleanField {...taskProperties} />;

      case PropertyValueType.Currency:
        return <CurrencyField {...taskProperties} />;

      case PropertyValueType.Date:
        return <DateField {...taskProperties} />;

      case PropertyValueType.DateTime:
        return <DateTimeField {...taskProperties} />;

      case PropertyValueType.Element:
        return null;

      case PropertyValueType.Elements:
        return null;

      case PropertyValueType.File:
        return null;

      case PropertyValueType.Files:
        return <FilesField {...taskProperties} />;

      case PropertyValueType.Formula:
        return null;

      case PropertyValueType.Guid:
        return null;

      case PropertyValueType.Progress:
        return null;

      case PropertyValueType.Barcode:
        return <BarcodeField {...taskProperties} />;

      case PropertyValueType.Id:
        return <IdField {...taskProperties} />;

      case PropertyValueType.MultiLineText:
        return <MultiLineTextField {...taskProperties} />;

      case PropertyValueType.MultiSelect:
        return <MultiSelectField {...taskProperties} />;

      case PropertyValueType.Number:
        return <NumberField {...taskProperties} />;

      case PropertyValueType.Percent:
        return <PercentField {...taskProperties} />;

      case PropertyValueType.SingleLineText:
        return <SingleLineTextField {...taskProperties} />;

      case PropertyValueType.SingleSelect:
        return <SingleSelectField {...taskProperties} />;

      case PropertyValueType.User:
        return <UserField {...taskProperties} />;

      case PropertyValueType.Users:
        return <UsersField {...taskProperties} />;

      case PropertyValueType.Viewpoint:
        return null;

      case PropertyValueType.Viewpoints:
        if (customization?.[PropertyValueType.Viewpoints]) {
          return (customization?.[PropertyValueType.Viewpoints]?.(taskProperties));
        } else {
          return <ViewpointsField {...taskProperties} />;
        }

      case PropertyValueType.Url:
        return <UrlField {...taskProperties} />;

      case PropertyValueType.Annotations:
        return <AnnotationsField {...taskProperties} />;

      default:
        return null;
    }
  };

  // TODO: remove render function, replace with a lookup component: <PropertyComponent {...taskProperties} />
  const rendererLookup = {
    [PropertyValueType.Boolean]: BooleanField,
    [PropertyValueType.Currency]: CurrencyField,
    [PropertyValueType.Date]: DateField,
    [PropertyValueType.DateTime]: DateTimeField,
    [PropertyValueType.Element]: null,
    [PropertyValueType.Elements]: null,
    [PropertyValueType.File]: null,
    [PropertyValueType.Files]: FilesField,
    [PropertyValueType.Formula]: null,
    [PropertyValueType.Guid]: null,
    [PropertyValueType.Progress]: null,
    [PropertyValueType.Id]: IdField,
    [PropertyValueType.MultiLineText]: MultiLineTextField,
    [PropertyValueType.MultiSelect]: MultiSelectField,
    [PropertyValueType.Number]: NumberField,
    [PropertyValueType.Percent]: PercentField,
    [PropertyValueType.SingleLineText]: SingleLineTextField,
    [PropertyValueType.SingleSelect]: SingleSelectField,
    [PropertyValueType.User]: UserField,
    [PropertyValueType.Users]: UsersField,
    [PropertyValueType.Viewpoint]: null,
    [PropertyValueType.Viewpoints]: customization?.[PropertyValueType.Viewpoints]
      ? () => customization?.[PropertyValueType.Viewpoints]
      : ViewpointsField,
    [PropertyValueType.Url]: UrlField,
  };

  const PropertyComponent = rendererLookup[propertyValue.type as never] || null;

  return (
    <TaskDetailedRow>
      <TaskDetailedHeader>
        <FieldTitle
          title={propertyValue.title}
          type={propertyValue.type}
          propertyClass={propertyValue.class}
        />
      </TaskDetailedHeader>
      <TaskDetailedContent>
        {renderTaskPropertyValueComponent()}
      </TaskDetailedContent>
    </TaskDetailedRow>
  );
};

const customEqual = (prevProps: TaskDetailedPropertyProps, nextProps: TaskDetailedPropertyProps) => {
  return isEqual(prevProps.propertyValue, nextProps.propertyValue);
};

export default memo(TaskDetailedProperty, customEqual);
