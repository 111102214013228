import styled from "@emotion/styled/macro";
import { squareWrapperCss } from "styles/common-css";

export const ExpandWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  border: 5px;
  transition: background-color 200ms ease;
  cursor: pointer;
`;

export const TaskExpandButton = styled.div`
  ${squareWrapperCss};
  opacity: 0;
  padding: 2px;
`;

export const AddViewpoint = styled.div<{ cardSize?: number }>`
  flex: 0 0 auto;
  display: flex;
  width: 24px;
  height: ${({ cardSize }) => cardSize ? `${cardSize}px` : "36px"};
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f2f2f2;
  transition: background-color 200ms ease;
  cursor: pointer;
  &:hover {
    background-color: #e5e5e5;
  }
`;
