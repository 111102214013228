import { FC } from "react";
import { ButtonProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { CustomSpin } from "../TaskFieldsModal/Modal/Form/styled";
import { StyledButton } from "styles/common";

interface CustomStyledButtonProps extends ButtonProps {
  isLoading?: boolean;
  btnColor?: "purple" | "red" | "redOutlined";
  htmlType?: "submit" | "reset" | "button" | undefined;
}

const CustomStyledButton: FC<CustomStyledButtonProps> = (props) => {
  const color = (props.btnColor) ? { [props.btnColor]: true } : {};

  return (
    <StyledButton  {...color} onClick={props.onClick} disabled={props.disabled} type={props.htmlType}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CustomSpin indicator={<LoadingOutlined style={{ fontSize: 15, color: "#999" }} spin />} style={{ marginRight: "5px" }} disabled={props.isLoading} />
        {props.value}
      </div>
    </StyledButton>
  );
};

export default CustomStyledButton;
