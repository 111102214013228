import styled from "@emotion/styled/macro";

export const ReactEditorJSContainer = styled.div`
  .ce-header {
    line-height: 1.4;
    padding: 6px 0 2px 0;
  }
  .cdx-list__item {
    padding: 2px 0 2px 4px;
  }
`;
