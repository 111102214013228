import { FormInstance } from "antd";
import { createContext } from "react";
import { TActiveTab } from "../types";

interface RemovePropertyContextProps {
  removeTrigger: () => void,
  removeAction: () => void,
  onClickTriggerHeader: () => void,
  onClickActionHeader: () => void,
}

export const AutomationFormContext = createContext<FormInstance<any> | null>(null);
export const AutomationActiveTabContext = createContext<TActiveTab>(TActiveTab.Actions);
export const RemovePropertyContext = createContext<RemovePropertyContextProps>({
  removeTrigger: () => console.warn("no function"),
  removeAction: () => console.warn("no function"),
  onClickTriggerHeader: () => console.warn("no function"),
  onClickActionHeader: () => console.warn("no function"),
});
