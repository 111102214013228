import { FC, useRef, useState } from "react";
import { Collapse, Form, FormInstance, Modal } from "antd";
import { BoardInterface, BoardViewInterface, DefaultColumnCodes, RecordTemplateInterface } from "lib/types/boardTypes";
import { commonModalProps } from "components/blocks/ModalDialog/lib";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import { SystemPropertyCode } from "lib/types/dataTypes";
import { TaskFormProvider } from "lib/contexts/TaskFormContext";
import { FormResponseInterface } from "lib/types/form";
import { CollapseWrapper } from "pages/Board/BoardKanban/Card/CardDetails/styled";
import FormPublicField from "pages/FormPublic/FormPublicField";
import { AddTaskRow, FormInner } from "components/blocks/AddTaskModal/TasksForm/styled";
import { TaskModalCreateContainer } from "components/blocks/TaskModals/TaskModalCreateAnnotation/styled";
import LoadingMessage from "components/blocks/LoadingMessage";
import BoardService from "services/BoardService";
import { RecordTemplateTitle } from "components/blocks/RecordTemplates/RecordTemplateDrawer/RecordTemplateModal/styled";
import { ModalComponentProps } from "lib/types/components";
import CustomStyledButton from "components/blocks/CustomStyledButton";
import { StyledButton } from "styles/common";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { cancelButtonProps, okButtonProps } from "styles/common-vars";
import useBoardSetRecordTemplates from "lib/customHooks/dataLayer/board/useBoardSetRecordTemplates";

interface RecordTemplateModalProps extends ModalComponentProps {
  template?: RecordTemplateInterface;
}

const RecordTemplateModal: FC<RecordTemplateModalProps> = (props) => {
  const { template, onClose, visible } = props;

  const { board } = useBoardContext() as BoardContextInterface;
  const { updateBoardRecordTemplate, deleteBoardRecordTemplate } = useBoardSetRecordTemplates({ board: board, updateDetailed: true });

  const { excludedColumns, insertColumns } = useBoardFeatures(board);
  const ignoredColumns = [
    DefaultColumnCodes.Title, // Ignoring "title" since we inherit that from template.title
    DefaultColumnCodes.Completion,
    DefaultColumnCodes.CreatedBy,
    DefaultColumnCodes.CreatedTime,
    DefaultColumnCodes.LastModified,
    DefaultColumnCodes.LastModifiedBy,
    DefaultColumnCodes.ClosingDate,
    DefaultColumnCodes.Collaborators,
    DefaultColumnCodes.Viewpoints,
    DefaultColumnCodes.Annotations,
    ...Object.values(SystemPropertyCode),
  ];

  const formInitialValues = template ? { ...template.template } : {};

  type FormDataShape = typeof formInitialValues

  const fields = Array.from(board?.columns || [])
    .concat(insertColumns)
    .sort((prev, next) => prev.sort - next.sort)
    .filter(column => ![...excludedColumns, ...ignoredColumns].includes(column.code));

  const visibleFields = fields.filter(field => !field.hidden);
  const hiddenFields = fields.filter(field => field.hidden);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef<FormInstance<FormDataShape>>(null);
  const [form] = Form.useForm<FormDataShape>();

  const data: FormResponseInterface = {
    board: board as unknown as BoardInterface,
    view: {} as BoardViewInterface,
    columns: board.columns,
    members: board.workspace.members.map(member => member.user),
  };

  const handleOnOk = async () => {
    try {
      setIsSubmitting(true);
      const values = await form.validateFields();
      const payload = {
        title: values.title,
        template: values,
      };

      if (template) {
        updateBoardRecordTemplate(template.id, payload);
      } else {
        await BoardService.recordTemplatesCreate(board.id, payload);
      }

      onClose();
    } catch (error) {
      console.log("form.validateFields: error", error);
      LoadingMessage.error("Looks like you missed some required fields");
    }

    setIsSubmitting(false);
  };

  const handleOnDelete = async () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      title: "Permanently delete the template?",
      content: "This will permanently delete the template. This item will no longer be accessible to you or anyone else. This action is irreversible.",
      okText: "Delete",
      okButtonProps: okButtonProps,
      cancelText: "Cancel",
      cancelButtonProps: cancelButtonProps,
      onOk() {
        if (template?.id) {
          deleteBoardRecordTemplate(template.id);
        }
        onClose();
      },
      zIndex: 1040,
    });
  };

  return (
    <Modal
      {...commonModalProps}
      title={template ? "Edit template" : "Create template"}
      open={visible}
      onOk={onClose}
      onCancel={onClose}
      bodyStyle={{ maxHeight: "60vh", overflowY: "scroll" }}
      centered
      footer={[
        <div key="footer" style={{ display: "flex", justifyContent: "space-between" }}>
          {template
            ? <CustomStyledButton
              key="ok"
              btnColor="redOutlined"
              value="Delete"
              onClick={handleOnDelete}
            />
            : <div />
          }
          <div>
            <div key="cancel" style={{ display: "inline-block", marginRight: "10px" }}>
              <StyledButton onClick={onClose}>
                Cancel
              </StyledButton>
            </div>
            <CustomStyledButton
              onClick={handleOnOk}
              isLoading={isSubmitting}
              key="ok"
              btnColor="purple"
              value={template ? "Save" : "Create"}
            />
          </div>
        </div>
      ]}
    >
      <TaskModalCreateContainer>
        <TaskFormProvider form={data}>
          <Form.Provider>
            <Form
              form={form}
              ref={formRef}
              name="template-form"
              layout="vertical"
              preserve={false}
              requiredMark={false}
              initialValues={formInitialValues}
            >
              <FormInner>
                <AddTaskRow>
                  <Form.Item
                    name="title"
                    rules={[{ required: true }]}
                    style={{ width: "100%" }}
                  >
                    <RecordTemplateTitle
                      name="title"
                      placeholder="Add template title"
                      autoFocus
                    />
                  </Form.Item>
                </AddTaskRow>

                {/* Visible fields */}
                {visibleFields.map(column => (
                  <FormPublicField
                    key={column.id}
                    column={column}
                  />
                ))}

                {/* Hidden fields */}
                {(hiddenFields.length > 0) &&
                  <CollapseWrapper>
                    <Collapse ghost>
                      <Collapse.Panel header="Hidden fields" key="form-hidden-fields">
                        {hiddenFields.map(column => (
                          <FormPublicField
                            key={column.id}
                            column={column}
                          />
                        ))}
                      </Collapse.Panel>
                    </Collapse>
                  </CollapseWrapper>
                }
              </FormInner>
            </Form>
          </Form.Provider>
        </TaskFormProvider>
      </TaskModalCreateContainer>
    </Modal>
  );
};

export default RecordTemplateModal;
