import { FC } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { getUserFullName } from "lib/helpers/userUtils";
import { useAppSelector } from "app/store";
import AvatarCustom from "components/ui/AvatarCustom";
import AuthService from "services/AuthService";
import { HeaderAvatarUser, HeaderAvatarUserName, HeaderAvatarUserEmail } from "./styled";
import CannyIdentify from "components/blocks/Canny/CannyIdentify";

const HeaderAvatar: FC = () => {
  const history = useHistory();
  const user = useAppSelector(state => state.currentUserReducer.user);
  const organizations = useAppSelector(state => state.organizations.organizations);
  const fullName = getUserFullName(user);

  const handleOnClickSettings = () => {
    history.push("/settings");
  };

  const handleOnClickAdminConsole = () => {
    history.push("/admin");
  };

  const handleOnClickFeatureRequest = () => {
    window.open("https://feedback.airtasks.com");
  };

  const handleOnClickLogout = () => {
    AuthService.logout();
  };

  const menu = (
    <Menu>
      <HeaderAvatarUser key="user">
        <div onClick={e => e.stopPropagation()}>
          <HeaderAvatarUserName>{fullName}</HeaderAvatarUserName>
          <HeaderAvatarUserEmail>{user?.email}</HeaderAvatarUserEmail>
        </div>
      </HeaderAvatarUser>
      <Menu.Divider />
      <Menu.Item key="settings" onClick={handleOnClickSettings}>Settings</Menu.Item>
      {!!organizations.length &&
        <Menu.Item key="admin" onClick={handleOnClickAdminConsole}>Admin Console</Menu.Item>
      }
      <Menu.Item key="feedback" onClick={handleOnClickFeatureRequest}>Feedback</Menu.Item>
      <Menu.Item danger key="logout" onClick={handleOnClickLogout}>Log out</Menu.Item>
    </Menu>
  );

  return (
    <>
      <CannyIdentify user={user}>
        <Dropdown overlay={menu} trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            <AvatarCustom
              user={user}
              size="large"
            />
          </a>
        </Dropdown>
      </CannyIdentify>
    </>
  );
};

export default HeaderAvatar;
