import { FC, useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Modal, Upload, UploadProps } from "antd";

import configuration from "lib/configs/configuration";
import { ModalComponentProps } from "lib/types/components";
import { commonModalProps } from "../ModalDialog/lib";
import LoadingMessage from "../LoadingMessage";
import { useAppSelector } from "app/store";
import { FileInterface } from "lib/types/types";
import { captureException } from "lib/utils/sentry";
import { JSendResponse } from "lib/types/jsend";

interface AddFileContainerModalProps extends ModalComponentProps {
  uploadFn: (file: FileInterface) => Promise<JSendResponse | void>;
  title?: string;
  text?: string;
  hint?: string;
}

const AddFileContainerModal: FC<AddFileContainerModalProps> = (props) => {
  const { visible, onClose, uploadFn, title, text, hint } = props;

  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<FileInterface | null>(null);

  const token = useAppSelector(state => state.auth.token);

  const uploaderProps: UploadProps = {
    name: "files",
    multiple: false,
    style: {
      width: "100%",
      height: "100%",
      display: (file || isUploading) ? "none" : "block",
      borderRadius: 5,
      borderWidth: 2,
    },
    listType: "picture",
    headers: { authorization: `Bearer ${token}` },
    action: `${configuration.backend.url}upload`,
    showUploadList: {
      showRemoveIcon: !isUploading,
    },
    defaultFileList: [],
    beforeUpload(file) {
      setIsUploading(true);
    },
    onChange(info) {
      if (info.file.status === "done") {
        const [response] = info.file.response as FileInterface[];
        setFile(response);
        LoadingMessage.complete(`"${info.file.name}" has been uploaded successfully`);
      } else if (info.file.status === "error") {
        captureException(info, true, `"${info.file.name}" upload failed.`);
      }
    },
    onRemove() {
      setFile(null);
    }
  };

  const handleOnCreate = async () => {
    if (!file) {
      return;
    }

    setIsLoading(true);
    await uploadFn(file);
    setIsLoading(false);
    setFile(null);

    onClose();
  };

  useEffect(() => {
    if (file?.id) {
      setIsLoading(false);
      handleOnCreate();
    }
  }, [file?.id]);

  return (
    <Modal
      destroyOnClose
      {...commonModalProps}
      footer={null}
      title={title || "Upload file"}
      open={visible}
      onOk={onClose}
      onCancel={onClose}
    >
      <Upload.Dragger {...uploaderProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          {text || "Click or drag file to this area to upload"}
        </p>
        <p className="ant-upload-hint">
          {hint || "Upload your files by clicking here or simply dragging them into this area"}
        </p>
      </Upload.Dragger>
    </Modal>
  );
};

export default AddFileContainerModal;
