import { Form } from "antd";
import { setToken } from "app/slices/authSlice";

import WebSocketService from "lib/API/socket";
import useAuthorizedRedirect from "lib/customHooks/routeHooks/useAuthorizedRedirect";
import { FC, useCallback, useEffect, useState } from "react";
import AuthService from "services/AuthService";
import useAsyncData from "lib/customHooks/useAsyncData";
import AuthEmail from "pages/Auth/AuthComponents/Email";
import AuthFirstLastName from "pages/Auth/AuthComponents/FirstLastName";
import AuthResetPassword from "pages/Auth/AuthComponents/ResetPassword";
import AuthSignInButton from "pages/Auth/AuthComponents/SignInButton";
import AuthContainer from "pages/Auth/index";
import { AuthLink, AuthReactLink } from "pages/Auth/styled";
import { useAppDispatch } from "app/store";
import configuration from "lib/configs/configuration";
import { extractQueryParams } from "lib/helpers/urlUtils";
import { UserPlatformVersion } from "lib/types/types";

type ValueChanged = {
  firstName?: string
  lastName?: string
  email?: string
  password?: string
};

const SignUp: FC = () => {
  useAuthorizedRedirect();

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const initialValues = extractQueryParams<ValueChanged>("form_");

  const onValuesChange = (valueChanged: ValueChanged) => {
    if (valueChanged.firstName) {
      setFirstName(valueChanged.firstName);
    } else if (valueChanged.lastName) {
      setLastName(valueChanged.lastName);
    } else if (valueChanged.email) {
      setEmail(valueChanged.email);
    } else if (valueChanged.password) {
      setPassword(valueChanged.password);
    }
  };

  const fetch = useCallback(() => {
    return AuthService.register({
      firstName,
      lastName,
      email,
      password,
      username: email,
      version: UserPlatformVersion.Amvp,
    });
  }, [email, firstName, lastName, password]);

  const { data, loadData } = useAsyncData<any>({
    fetchFn: fetch
  });

  useEffect(() => {
    if (data && data.jwt) {
      dispatch(setToken(data.jwt));
      WebSocketService.connect();
    }
  }, [data]);

  return (
    <Form
      form={form}
      onFinish={loadData}
      onValuesChange={onValuesChange}
      style={{ width: "100%" }}
      requiredMark={false}
      layout="vertical"
      initialValues={initialValues}
    >
      <AuthContainer
        header="Create an account"
        firstLastNameComponent={<AuthFirstLastName autoFocus />}
        emailComponent={<AuthEmail />}
        resetPasswordComponent={<AuthResetPassword text="Create a password" />}
        signInButton={<AuthSignInButton text="Sign up for free" />}
        termsOfPolicy={
          <>
            By signing up, I agree to the {configuration.app.title} <AuthLink href="https://airtasks.com/legal/privacy-policy" target="_blank">Privacy Policy</AuthLink> and <AuthLink href="https://airtasks.com/legal/terms-of-service" target="_blank">Terms of Service</AuthLink>.
          </>
        }
        haveAccountText="Already have an account?"
        linkComponent={<AuthReactLink to="/auth/login">Sign In</AuthReactLink>}
      />
    </Form>
  );
};

export default SignUp;
