import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const FreehandSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.8125 91.0009C13.9846 90.9989 13.1666 90.8205 12.4131 90.4776C11.6596 90.1347 10.9877 89.6351 10.4424 89.0122C9.89712 88.3893 9.49083 87.6573 9.25062 86.865C9.01042 86.0727 8.94182 85.2384 9.0494 84.4175L11.1309 68.511C11.3211 67.0715 11.9796 65.7345 13.0049 64.7063L67.8458 9.86538C69.0437 8.67082 70.6665 8 72.3582 8C74.05 8 75.6727 8.67082 76.8706 9.86538L90.1346 23.1294C91.3292 24.3273 92 25.95 92 27.6418C92 29.3335 91.3292 30.9562 90.1346 32.1542L35.2937 86.9951C34.2657 88.0207 32.9286 88.6792 31.489 88.8691L15.5825 90.9506C15.3272 90.9842 15.07 91.001 14.8125 91.0009ZM19.1003 70.0738L17.4711 82.5289L29.9262 80.8997L83.1832 27.6426L72.3574 16.8167L19.1003 70.0738Z"/>
        <path d="M74.956 45.6535C74.4241 45.6551 73.8971 45.5517 73.4052 45.3492C72.9134 45.1466 72.4664 44.849 72.0898 44.4734L55.5252 27.9088C54.7651 27.1478 54.3383 26.1159 54.339 25.0403C54.3396 23.9647 54.7674 22.9334 55.5284 22.1732C56.2894 21.4131 57.3213 20.9864 58.3969 20.987C59.4725 20.9876 60.5038 21.4155 61.264 22.1765L77.8221 38.7346C78.389 39.3014 78.7752 40.0236 78.9316 40.8099C79.088 41.5961 79.0078 42.4111 78.701 43.1518C78.3942 43.8924 77.8746 44.5255 77.208 44.9708C76.5413 45.4161 75.7576 45.6537 74.956 45.6535Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default FreehandSvg;
