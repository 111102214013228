import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const UploadSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
        <path d="M26.1368 21.6592C23.2078 24.5881 23.2078 29.3369 26.1368 32.2658C29.0657 35.1947 33.8144 35.1947 36.7433 32.2658L42.5001 26.509V66.6664C42.5001 70.8085 45.8579 74.1664 50.0001 74.1664C54.1423 74.1664 57.5001 70.8085 57.5001 66.6664V25.9174L63.8488 32.2661C66.7778 35.1951 71.5265 35.1951 74.4554 32.2661C77.3843 29.3371 77.3843 24.5885 74.4554 21.6595L55.6551 2.85922C55.6367 2.84005 55.6182 2.82171 55.5995 2.80338C52.7221 -0.0741193 48.0883 -0.124945 45.149 2.65089C45.0964 2.70089 45.0444 2.75171 44.9929 2.80338L26.1368 21.6592Z"/>
        <path d="M0 68.3333C0 64.1912 3.35787 60.8333 7.5 60.8333C11.6422 60.8333 15 64.1912 15 68.3333V84.9996H85V68.3333C85 64.1912 88.3575 60.8333 92.5 60.8333C96.6417 60.8333 100 64.1912 100 68.3333V92.4206C100 92.4469 100 92.4732 100 92.4996C100 94.4414 99.2617 96.2109 98.0508 97.5428C97.8825 97.7287 97.7042 97.9061 97.5175 98.0743C96.1883 99.2714 94.4292 100 92.5 100C92.4733 100 92.4467 99.9999 92.4208 99.9996H7.57894C7.55267 99.9999 7.52635 100 7.5 100C3.35787 100 0 96.6421 0 92.5L0 68.3333Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default UploadSvg;
