export function shortId() {
  return "_" + Math.random().toString(36).substr(2, 9);
}

export function randomColor() {
  return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`;
}

export const HexColorPrefix = "#";
export const HexColorRegexStrict = /^#([0-9A-F]{3}){1,2}$/i;
export const HexColorRegexIncomplete = /^#([0-9A-F]{0,6})$/i;
