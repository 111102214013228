import { FC, useState, useEffect } from "react";
import { Dropdown, Input } from "antd";

import colors, { randomColor } from "lib/configs/colors";
import ColorPalette from "components/blocks/ColorPalette";
import { ColorObject } from "components/blocks/ColorPalette/types";
import { ColorOption } from "components/blocks/ColorPalette/styled";

import { FormItem } from "../styled";
import { PaletteWrapper } from "./styled";

interface ColorPickerFieldProps {
  name: string[];
  form?: Record<string, any>;
  disabled?: boolean;
}

const ColorPickerField: FC<ColorPickerFieldProps> = (props) => {
  const { name, form, disabled } = props;
  const defaultColor = randomColor();

  const [currentColor, setCurrentColor] = useState<ColorObject>(defaultColor);
  const [fieldValue, setFieldValue] = useState<string>(defaultColor.normal);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  useEffect(() => {
    const fields = form?.getFieldsValue(true);
    setFieldValue(fields.options[name[1]].color);
  }, []);

  useEffect(() => {
    setColor(fieldValue);
  }, [fieldValue]);

  const setColor = (color: string) => {
    const fullColor = colors.find(item => item.normal === color);
    if (fullColor) {
      setCurrentColor(fullColor);
      return setColorFieldValue(color);
    }

    if (color) {
      setCurrentColor({ normal: color, hover: color });
      return setColorFieldValue(color);
    }

    return null;
  };

  const setColorFieldValue = (color: string) => {
    const fields = form?.getFieldsValue(true);
    fields.options[name[1]].color = color;

    form?.setFieldsValue({ options: fields.options });
  };

  const onChangeColor = (color: string, closePopup?: boolean) => {
    setFieldValue(color);
    if (closePopup) {
      setIsDropdownOpened(false);
    }
  };

  const dropdown = (
    <PaletteWrapper>
      <ColorPalette changeColor={onChangeColor} titleColor={fieldValue} />
    </PaletteWrapper>
  );

  return (
    <>
      <Dropdown
        open={isDropdownOpened}
        trigger={["click"]}
        overlay={dropdown}
        overlayStyle={{
          ...(!!disabled && { display: "none" })
        }}
        placement="bottomLeft"
        disabled={disabled}
      >
        <ColorOption
          size={16}
          colors={currentColor}
          disabled={disabled}
          onClick={() => setIsDropdownOpened(true)}
        />
      </Dropdown >
      <FormItem name={name} hidden>
        <Input />
      </FormItem>
    </>
  );
};

export default ColorPickerField;
