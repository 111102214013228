import { FC } from "react";

interface ContainerProps {
  animationDuration: number;
  isFinished: boolean;
}

const Container: FC<ContainerProps> = (props) => {
  const { animationDuration, children, isFinished } = props;

  return (
    <div
      style={{
        opacity: isFinished ? 0 : 1,
        pointerEvents: "none",
        transition: `opacity ${animationDuration}ms linear`,
      }}
    >
      {children}
    </div>
  );
};

export default Container;
