import React, { FC, useContext } from "react";
import { FilterValueType } from "../filters/filters";

interface FilterValuesProps {
  filterValues?: FilterValueType[]
  filterValuesOfAllFilterTypes?: Record<string, FilterValueType[]> | null
}

const FilterValuesContext = React.createContext<FilterValuesProps>({
  filterValues: [],
  filterValuesOfAllFilterTypes: null
});

export const useFilterValuesContext = () => useContext(FilterValuesContext);

export const FilterValuesProvider: FC<FilterValuesProps> = (props) => {
  return (
    <FilterValuesContext.Provider value={{ ...props }}>
      {props.children}
    </FilterValuesContext.Provider>
  );
};

