import React, { FC, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Progress } from "antd";
import { sortBy } from "lodash";

import KeyCodes from "lib/types/keyCodes";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import { calculateTaskProgress } from "lib/helpers/taskUtils";
import { TaskContextInterface, useTaskContext } from "lib/contexts/TaskContext";
import useTaskSetSubtasks from "lib/customHooks/dataLayer/task/useTaskSetSubtasks";
import { TaskSubtaskInterface } from "lib/types/tasksTypes";
import { TaskDetailedHeading } from "pages/Task/TaskDetailed/styled";

import { TagButton } from "styles/common";
import SubtaskItem from "./SubtaskItem";
import SubtaskInput from "./SubtaskInput";
import { nanoid } from "nanoid";

const TaskSubtasks: FC = () => {
  const { task } = useTaskContext() as TaskContextInterface;

  const subtasks = sortBy(task.subtasks, ["sort"]);
  const [isAddActive, setIsAddActive] = useState(false);

  const { createSubtask, createSubtasks, updateSubtask, deleteSubtask, reorderSubtasks } = useTaskSetSubtasks({ task, updateDetailed: true });
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const handleAddButtonOnClick = () => {
    setIsAddActive(true);
  };

  const handleSubtaskDelete = (subtask: TaskSubtaskInterface) => {
    return deleteSubtask(subtask.id);
  };

  const handleSubtaskUpdate = (subtask: TaskSubtaskInterface, data: Partial<TaskSubtaskInterface>) => {
    updateSubtask(subtask.id, data);
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if ((!destination) || (result.destination?.index === result.source.index)) {
      return;
    }

    const newData = Array.from(subtasks);
    const [movedRow] = newData.splice(source.index, 1);
    newData.splice(destination.index, 0, movedRow);
    const nextState = newData.map((item, index) => ({ ...item, sort: index }));

    reorderSubtasks(nextState);
  };

  const handleAddInputOnKeydown = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if ((event.keyCode === KeyCodes.Enter && !event.shiftKey) || event.keyCode === KeyCodes.Tab) {
      // @ts-expect-error
      event.target.blur();
    }
    if (event.keyCode === KeyCodes.Escape) {
      event.stopPropagation();
      setIsAddActive(false);
    }
  };

  const handleAddInputOnBlur = async (event: React.FocusEvent<HTMLTextAreaElement>) => {
    const title = event.target.value.trim();
    if (title) {
      const payload: Partial<TaskSubtaskInterface> = {
        id: nanoid(),
        isLoading: true,
        title: title,
        completed: false,
      };
      createSubtask(payload);
    }
    setIsAddActive(false);
  };

  const handleAddInputOnPaste = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();

    const pasted = event.clipboardData.getData("text").trim();
    const subtaskTitles = pasted.split(/\r?\n/);
    const payload = subtaskTitles.map(taskTitle => ({
      id: nanoid(),
      isLoading: true,
      title: taskTitle,
      completed: false,
    }));

    createSubtasks(payload);
  };

  return (
    <div style={{ marginBottom: "25px" }}>
      {(subtasks?.length > 0) &&
        <>
          <TaskDetailedHeading>
            Checklists
          </TaskDetailedHeading>
          <Progress percent={calculateTaskProgress(subtasks)} />
        </>
      }
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="task-subtasks-list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {subtasks?.map((subtask, index) => (
                  <Draggable
                    draggableId={`draggableId-${subtask.id}`}
                    key={`draggableId-${subtask.id}`}
                    index={index}
                    isDragDisabled={!subtask.id || !allowedToEdit || subtask.isLoading}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <SubtaskItem
                          subtask={subtask}
                          onDelete={handleSubtaskDelete}
                          onUpdate={handleSubtaskUpdate}
                          disabled={!allowedToEdit}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {allowedToEdit && !isAddActive &&
          <TagButton
            onClick={handleAddButtonOnClick}
            style={{ margin: "7px 0 0 0" }}
          >
            Add checklist item
          </TagButton>
        }
        {!!isAddActive &&
          <SubtaskInput
            onKeyDown={handleAddInputOnKeydown}
            onBlur={handleAddInputOnBlur}
            onPaste={handleAddInputOnPaste}
          />
        }
      </div>
    </div>
  );
};

export default TaskSubtasks;
