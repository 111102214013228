import { cloneDeep, isEmpty } from "lodash";
import { BoardDisplayView, BoardFeatures, BoardUnit, DefaultColumnCodes } from "lib/types/boardTypes";
import BoardService from "services/BoardService";
import useBoardPerformRequest, { BoardPerformRequestHookProps } from "./useBoardPerformRequest";
import { BoardDefaultFeatures } from "pages/Board/BoardSettings/BoardSettingsFeatures/lib";
import { captureException } from "lib/utils/sentry";
import { BoardFeatureCodes, DisplayView } from "lib/types/applicationTypes";

const useBoardSetFeatures = (props: BoardPerformRequestHookProps) => {
  const { board } = props;
  const performRequest = useBoardPerformRequest(props);

  const setBoardFeatures = (payload: Partial<BoardFeatures>) => {
    if (!board) {
      return;
    }

    const { columns, displayView } = cloneDeep(board);
    const features = (!isEmpty(board?.features)) ? cloneDeep(board.features) : BoardDefaultFeatures;
    const featureCodes = Object.keys(payload);

    function setColumnState(columnCode: string, columnState: boolean) {
      const findIndex = columns.findIndex(column => column.code === columnCode);
      if (findIndex !== -1) {
        columns[findIndex].active = columnState;
      }
    }

    function setDisplayViewState(displayViewCode: keyof BoardDisplayView, displayViewState: boolean) {
      displayView![displayViewCode] = displayViewState;
    }

    featureCodes?.forEach(feature => {

      const value = payload[feature as never];
      const state = !!value;

      switch (feature) {
        case BoardFeatureCodes.Completion: {
          features.completion = state;
          break;
        }

        case BoardFeatureCodes.Statuses: {
          features.statuses = state;
          // Enable/disable "Kanban" displayView and "Status column"
          setDisplayViewState(DisplayView.Kanban, state);
          setColumnState(DefaultColumnCodes.Status, state);
          break;
        }

        case BoardFeatureCodes.Assignees: {
          features.assignees = state;
          // Enable/disable "Assignees" column
          setColumnState(DefaultColumnCodes.Assignees, state);
          break;
        }

        case BoardFeatureCodes.DueDates: {
          features.dueDates = state;
          // Enable/disable "Calendar" displayView and "Due Date" column
          setColumnState(DefaultColumnCodes.DueDate, state);
          setDisplayViewState(DisplayView.Calendar, state);
          break;
        }

        case BoardFeatureCodes.Models: {
          features.models = state;
          // Enable/disable "Models" displayView and "ViewerViewpoints" column
          setColumnState(DefaultColumnCodes.Viewpoints, state);
          setDisplayViewState(DisplayView.Model, state);
          break;
        }

        case BoardFeatureCodes.Annotations: {
          features.annotations = state;
          // Enable/disable "Annotations" column
          setColumnState(DefaultColumnCodes.Annotations, state);
          break;
        }

        case BoardFeatureCodes.Unit: {
          if (Object.values(BoardUnit).includes(value)) {
            features.unit = value;
          } else {
            captureException(`Unsupported feature unit: ${value}`);
          }
          break;
        }

        default:
          break;
      }
    });

    const requestFn = () => BoardService.featuresUpdate(board.id, payload);

    return performRequest({ columns, displayView, features }, requestFn);
  };

  return { setBoardFeatures };
};

export default useBoardSetFeatures;
