import { FC } from "react";
import { Link } from "react-router-dom";
import { getBoardUri, getTaskDetailUri, getProjectUri, getFileDetailUri } from "lib/helpers/navigationUtils";
import { ContentType } from "lib/types/contentTypes";
import { NotificationInterface, NotificationStatus } from "lib/types/notificationTypes";
import TaskCheckmark from "pages/Task/TaskCheckmark";
import { NotificationGroupingSeparator } from "../lib";
import NotificationItem from "../NotificationItem";
import NotificationService from "services/NotificationService";
import NotificationButton from "pages/Notifications/NotificationButton";
import { NotificationGroupButtons, NotificationGroupContainer } from "pages/Notifications/NotificationGroup/styled";
import {
  NotificationsBoardIcon,
  NotificationsBoardName,
  NotificationsBoardWrapper,
  NotificationsCheckmarkWrapper, NotificationsHeader,
  NotificationsTaskTitle,
  NotificationsTaskWrapper, NotificationsVirtuosoContainer
} from "pages/Notifications/styled";

interface NotificationGroupProps {
  groupCode: string;
  notifications: Array<NotificationInterface>;
  closeDrawerFn: () => void;
}

const NotificationGroup: FC<NotificationGroupProps> = (props) => {
  const { groupCode, notifications, closeDrawerFn } = props;

  const lastDashIndex = groupCode.lastIndexOf(NotificationGroupingSeparator);
  const [contentType,] = [
    groupCode.substring(0, lastDashIndex),
    groupCode.substring(lastDashIndex + 1)
  ];

  if (!notifications.length) {
    return (null);
  }

  const notificationData = notifications[0];
  const { data, itemId } = notificationData;

  const taskLinkColor = data?.completed ? "#8c8c8c" : "#1890ff";
  const taskTitle = data?.title || "Untitled task";

  const renderGroupHeader = () => {
    switch (contentType) {
      case ContentType.Task:
        return (
          <>
            {data?.board &&
              <NotificationsBoardWrapper>
                <NotificationsBoardIcon src={`/images/${data?.board.icon}.png`} alt={data?.board.icon} />
                <Link to={getBoardUri(data?.board?.uid || data?.board?.id)} style={{ textDecoration: "none" }} onClick={closeDrawerFn}>
                  <NotificationsBoardName>{data?.board.name}</NotificationsBoardName>
                </Link>
              </NotificationsBoardWrapper>
            }
            <NotificationsTaskWrapper>
              <NotificationsCheckmarkWrapper>
                <TaskCheckmark isCompleted={data?.completed} />
              </NotificationsCheckmarkWrapper>
              <Link
                to={getTaskDetailUri(data?.uid || data?.id || itemId)}
                style={{ color: taskLinkColor, textDecoration: "none", overflow: "hidden" }}
                onClick={closeDrawerFn}
              >
                <NotificationsTaskTitle>{taskTitle}</NotificationsTaskTitle>
              </Link>
            </NotificationsTaskWrapper>
          </>
        );

      case ContentType.Workspace:
        return (
          <Link to={getProjectUri(data?.uid || data?.id || itemId)} onClick={closeDrawerFn}>
            <NotificationsBoardName>
              {data?.name}
            </NotificationsBoardName>
          </Link>
        );

      case ContentType.FileContainer:
        return (
          <Link to={getFileDetailUri(data?.uid || data?.id || itemId)} onClick={closeDrawerFn}>
            <NotificationsBoardName>
              {data?.title || "Untitled"}
            </NotificationsBoardName>
          </Link>
        );

      default:
        return (null);
    }
  };

  const handleButtonClick = async (status: NotificationStatus) => {
    const filter = {
      "id_in": notifications.map(notification => notification.id)
    };

    return await NotificationService.setStatusBatch(filter, status);
  };

  return (
    <NotificationsVirtuosoContainer>
      <NotificationGroupContainer>
        <NotificationsHeader>
          {renderGroupHeader()}
        </NotificationsHeader>
        {notifications.map(notification => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
        <NotificationGroupButtons>
          <NotificationButton type="Archive" handleClick={() => handleButtonClick(NotificationStatus.Archived)} />
        </NotificationGroupButtons>
      </NotificationGroupContainer>
    </NotificationsVirtuosoContainer>
  );
};

export default NotificationGroup;
