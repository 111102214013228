import { FC } from "react";
import styled from "@emotion/styled/macro";
import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import BooleanGeneric from "./Display/generic";
import { dataInput } from "components/blocks/TaskTable/styled";
import { fieldDetailedWrapper } from "components/blocks/TaskFields/Components/styled";

const BooleanListWrapper = styled.div`
  ${dataInput};
  justify-content: center;
`;

const BooleanDetailedWrapper = styled.div`
  ${fieldDetailedWrapper};
`;

const BooleanField: FC<TaskFieldPropsGeneric> = (props) => {
  const { component } = props;

  switch (component) {
    case TaskSourceComponent.Card:
      return null;
    case TaskSourceComponent.Cell:
      return (
        <BooleanListWrapper>
          <BooleanGeneric {...props} />
        </BooleanListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <BooleanDetailedWrapper>
          <BooleanGeneric {...props} />
        </BooleanDetailedWrapper>
      );
    default:
      return null;
  }
};

export default BooleanField;
