import styled from "@emotion/styled/macro";
import NumberFieldComponent from "components/blocks/FormComponents/NumberField";

export const SelectOptionHiddenElements = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: background-color 200ms ease;
`;

export const SelectOptionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -8px;
  padding: 0 7px;
  border-radius: 2px;
  transition: background-color 200ms ease;
  &:hover{
    background-color: #f6f6f6;
    ${SelectOptionHiddenElements} {
      opacity: 1;
    }
  }
`;

export const NumberField = styled(NumberFieldComponent)`
  width: 0;
  height: 0;
  visibility: hidden;
  pointer-events: none;
`;

export const OptionInner = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  .ant-form-item-has-error input {
    border: 1px solid #ff4d4f;
  }
  .ant-form-item-has-error input:hover,
  .ant-form-item-has-error input:focus {
    border: 1px solid #ff4d4f;
  }
  .ant-input{
    height: 24px;
    padding: 0 7px;
  }
`;

export const Dragger = styled.div`
  cursor: pointer;
  img {
    margin-right: 7px;
  }
`;
