import { EntityId } from "lib/types/entity";
import { NotificationStatus } from "lib/types/notificationTypes";
import NotificationService from "services/NotificationService";
import NotificationButton from "./NotificationButton";

export const RenderNotificationButtons = (id: EntityId, status: NotificationStatus) => {

  const handleButtonClick = async (status: NotificationStatus) => {
    await NotificationService.setStatus(id, status);
  };

  switch (status) {
    case NotificationStatus.New:
      return (
        <>
          <NotificationButton type="MarkAsRead" handleClick={() => handleButtonClick(NotificationStatus.Read)} />
          <NotificationButton type="Archive" handleClick={() => handleButtonClick(NotificationStatus.Archived)} />
        </>
      );
    case NotificationStatus.Read:
      return (
        <>
          <NotificationButton type="Unread" handleClick={() => handleButtonClick(NotificationStatus.New)} />
          <NotificationButton type="Archive" handleClick={() => handleButtonClick(NotificationStatus.Archived)} />
        </>
      );
    case NotificationStatus.Archived:
      return (
        <NotificationButton type="Unarchive" handleClick={() => handleButtonClick(NotificationStatus.Read)} />
      );
    default:
      return null;
  }
};
