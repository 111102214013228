import { CSSProperties, forwardRef, ReactNode, useCallback } from "react";
import OverlayScrollbars from "overlayscrollbars";
import { OverlayScrollbarsComponent, OverlayScrollbarsComponentProps } from "overlayscrollbars-react";

interface CustomScrollbarProps extends OverlayScrollbarsComponentProps {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties | undefined;
}

const CustomScrollbar = forwardRef<HTMLDivElement, CustomScrollbarProps>(
  ({ children, className, style, ...props }, ref) => {

    const options: OverlayScrollbars.Options = {
      scrollbars: {
        autoHide: "scroll"
      }
    };

    const refSetter = useCallback(
      (scrollbarsRef) => {
        if (scrollbarsRef && ref) {
          //@ts-ignore
          ref.current = scrollbarsRef.osInstance()?.getElements()?.viewport;
        }
      },
      [ref]
    );

    return (
      <OverlayScrollbarsComponent
        ref={refSetter}
        className={className}
        style={style}
        options={options}
        {...props}
      >
        {children}
      </OverlayScrollbarsComponent>
    );
  }

);

export default CustomScrollbar;
