import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const EpsSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M68.4916 20.5167C66.2137 20.5145 64.0295 19.6086 62.4187 17.9978C60.8079 16.387 59.902 14.203 59.9 11.925V0H21.5125C20.0395 0 18.5809 0.290141 17.22 0.853829C15.8591 1.41752 14.6227 2.2437 13.5811 3.28527C12.5395 4.32683 11.7133 5.56338 11.1496 6.92425C10.5859 8.28508 10.2958 9.74371 10.2958 11.2167V78.8042C10.2958 80.2771 10.5859 81.7358 11.1496 83.0967C11.7133 84.4575 12.5395 85.6942 13.5811 86.7354C14.6227 87.7771 15.8591 88.6033 17.22 89.1671C18.5809 89.7308 20.0395 90.0208 21.5125 90.0208H67.5958C69.0687 90.0208 70.527 89.7308 71.8875 89.1671C73.2483 88.6033 74.4845 87.7771 75.5258 86.7354C76.567 85.6938 77.3929 84.4571 77.9558 83.0963C78.5191 81.7354 78.8087 80.2771 78.8083 78.8042V20.5208L68.4916 20.5167Z" fill="#FF9908"/>
        <path d="M78.7875 20.5167H68.4708C66.1929 20.5145 64.0087 19.6086 62.3979 17.9978C60.7871 16.387 59.8812 14.203 59.8792 11.925V0L78.7875 20.5167Z" fill="#FF9908"/>
        <path d="M83.1917 71.0833H31.0916C27.3959 71.0833 24.4 74.0791 24.4 77.7749V93.3083C24.4 97.0041 27.3959 99.9999 31.0916 99.9999H83.1917C86.8875 99.9999 89.8833 97.0041 89.8833 93.3083V77.7749C89.8833 74.0791 86.8875 71.0833 83.1917 71.0833Z" fill="#FF9908"/>
        <path d="M39.9112 93.75C39.3162 93.75 39.0187 93.4525 39.0187 92.8575V80.235C39.0187 79.64 39.3162 79.3425 39.9112 79.3425H47.5399C48.1349 79.3425 48.4324 79.64 48.4324 80.235C48.4324 80.83 48.1349 81.1275 47.5399 81.1275H40.8037V85.59H45.0324C45.6274 85.59 45.9249 85.8875 45.9249 86.4825C45.9249 87.0775 45.6274 87.375 45.0324 87.375H40.8037V91.965H47.5399C48.1349 91.965 48.4324 92.2625 48.4324 92.8575C48.4324 93.4525 48.1349 93.75 47.5399 93.75H39.9112Z" fill="white"/>
        <path d="M52.715 93.75C52.12 93.75 51.8225 93.4525 51.8225 92.8575V80.235C51.8225 79.64 52.12 79.3425 52.715 79.3425H57.6875C58.9838 79.3425 59.9754 79.6896 60.6625 80.3838C61.3567 81.0709 61.7038 82.0625 61.7038 83.3588V83.9963C61.7038 85.2925 61.3567 86.2875 60.6625 86.9817C59.9754 87.6688 58.9838 88.0125 57.6875 88.0125H53.6075V92.8575C53.6075 93.4525 53.31 93.75 52.715 93.75ZM53.6075 86.2275H57.6875C58.495 86.2275 59.0688 86.0575 59.4088 85.7175C59.7488 85.3775 59.9188 84.8038 59.9188 83.9963V83.3588C59.9188 82.5584 59.7488 81.9879 59.4088 81.6479C59.0688 81.3009 58.495 81.1275 57.6875 81.1275H53.6075V86.2275Z" fill="white"/>
        <path d="M68.4567 93.75C67.2454 93.75 66.3 93.5055 65.62 93.0167C64.9471 92.528 64.5254 91.7738 64.3554 90.7538C64.3271 90.4421 64.3908 90.2084 64.5467 90.0525C64.7025 89.8967 64.9292 89.8188 65.2267 89.8188C65.5242 89.8188 65.7404 89.8896 65.875 90.0313C66.0167 90.1729 66.1121 90.3925 66.1617 90.69C66.2679 91.1788 66.4946 91.515 66.8417 91.6992C67.1958 91.8763 67.7342 91.965 68.4567 91.965H71.5167C72.4304 91.965 73.0504 91.8021 73.3762 91.4763C73.7092 91.1434 73.8754 90.5271 73.8754 89.6275C73.8754 88.7138 73.7054 88.0904 73.3654 87.7575C73.0254 87.4175 72.4092 87.2475 71.5167 87.2475H68.8817C67.5429 87.2475 66.5479 86.9217 65.8962 86.27C65.2517 85.6113 64.9292 84.6125 64.9292 83.2738C64.9292 81.9421 65.2479 80.9538 65.8854 80.3092C66.53 79.6646 67.5217 79.3425 68.8604 79.3425H71.3892C72.5154 79.3425 73.3975 79.5725 74.035 80.0329C74.6796 80.4934 75.0867 81.1984 75.2567 82.1475C75.2992 82.4521 75.2425 82.6859 75.0867 82.8488C74.9308 83.0046 74.6971 83.0825 74.3854 83.0825C74.1021 83.0825 73.8896 83.0079 73.7479 82.8592C73.6062 82.7104 73.5142 82.4946 73.4717 82.2113C73.3583 81.7934 73.1425 81.51 72.8237 81.3613C72.505 81.2054 72.0267 81.1275 71.3892 81.1275H68.8604C68.0104 81.1275 67.4404 81.2796 67.15 81.5842C66.8596 81.8817 66.7142 82.445 66.7142 83.2738C66.7142 84.1096 66.8629 84.6834 67.1604 84.995C67.4579 85.3067 68.0317 85.4625 68.8817 85.4625H71.5167C72.9121 85.4625 73.95 85.8025 74.63 86.4825C75.3171 87.1625 75.6604 88.2109 75.6604 89.6275C75.6604 91.0371 75.3204 92.0784 74.6404 92.7513C73.9604 93.4171 72.9192 93.75 71.5167 93.75H68.4567Z" fill="white"/>
        <path d="M57.8083 53.2207H56.7708C56.7654 49.0157 55.092 44.9849 52.1183 42.012C49.1447 39.0389 45.1132 37.3668 40.9083 37.3624C36.7038 37.3668 32.6727 39.039 29.6996 42.012C26.7265 44.9853 25.0544 49.0162 25.05 53.2207H24.0125C24.018 48.7416 25.7999 44.447 28.9672 41.2797C32.1346 38.1123 36.429 36.3305 40.9083 36.325C45.3884 36.3293 49.6837 38.1107 52.852 41.2782C56.0204 44.4457 57.8029 48.7407 57.8083 53.2207Z" fill="#FF9908"/>
        <path d="M64.9209 36.325H16.8959V37.3624H64.9209V36.325Z" fill="#FF9908"/>
        <path d="M25.3584 51.5625H23.7042C23.2439 51.5625 22.8708 51.9354 22.8708 52.3958V54.05C22.8708 54.5104 23.2439 54.8833 23.7042 54.8833H25.3584C25.8186 54.8833 26.1917 54.5104 26.1917 54.05V52.3958C26.1917 51.9354 25.8186 51.5625 25.3584 51.5625Z" fill="#FF9908"/>
        <path d="M58.1167 51.5625H56.4625C56.0021 51.5625 55.6292 51.9354 55.6292 52.3958V54.05C55.6292 54.5104 56.0021 54.8833 56.4625 54.8833H58.1167C58.5771 54.8833 58.95 54.5104 58.95 54.05V52.3958C58.95 51.9354 58.5771 51.5625 58.1167 51.5625Z" fill="#FF9908"/>
        <path d="M41.7375 35.1292H40.0833C39.6231 35.1292 39.25 35.5022 39.25 35.9625V37.6166C39.25 38.0769 39.6231 38.4499 40.0833 38.4499H41.7375C42.1978 38.4499 42.5708 38.0769 42.5708 37.6166V35.9625C42.5708 35.5022 42.1978 35.1292 41.7375 35.1292Z" fill="#FF9908"/>
        <path d="M16.8417 38.4541C17.7599 38.4541 18.5042 37.7098 18.5042 36.7916C18.5042 35.8734 17.7599 35.1292 16.8417 35.1292C15.9235 35.1292 15.1792 35.8734 15.1792 36.7916C15.1792 37.7098 15.9235 38.4541 16.8417 38.4541Z" fill="#FF9908"/>
        <path d="M64.7625 38.4541C65.6808 38.4541 66.425 37.7098 66.425 36.7916C66.425 35.8734 65.6808 35.1292 64.7625 35.1292C63.8441 35.1292 63.1 35.8734 63.1 36.7916C63.1 37.7098 63.8441 38.4541 64.7625 38.4541Z" fill="#FF9908"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default EpsSvg;
