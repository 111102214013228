import { DisplayView } from "lib/types/applicationTypes";
import ListSvg from "components/icons/list";
import KanbanSvg from "components/icons/kanban";
import DueDatesSvg from "components/icons/due-date";
import FormSvg from "components/icons/form";

export const ViewItems = [
  {
    title: "List",
    code: DisplayView.List,
    icon: <ListSvg />,
  },
  {
    title: "Kanban",
    code: DisplayView.Kanban,
    icon: <KanbanSvg />,
  },
  {
    title: "Calendar",
    code: DisplayView.Calendar,
    icon: <DueDatesSvg />,
  },
  {
    title: "Form",
    code: DisplayView.Form,
    icon: <FormSvg />,
  }
];
