import React, { FC, useContext } from "react";
import { TaskInterface } from "lib/types/tasksTypes";
import { DisplayView } from "lib/types/applicationTypes";

interface TasksContextProps {
  tasks: Array<TaskInterface>;
  sortedUnfilteredTasks?: Array<TaskInterface>;
  source: DisplayView;
  isViewerBoardView?: boolean;
}

const TasksContext = React.createContext<TasksContextProps>({
  tasks: [],
  sortedUnfilteredTasks: [],
  source: DisplayView.List,
});

export const useTasksContext = () => useContext(TasksContext);

export const TasksProvider: FC<TasksContextProps> = (props) => {
  return (
    <TasksContext.Provider value={{ ...props }}>
      {props.children}
    </TasksContext.Provider>
  );
};

