import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const WmaSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.5292 20.5167C64.2512 20.5145 62.0671 19.6086 60.4562 17.9978C58.8454 16.387 57.9396 14.203 57.9375 11.925V0H19.5458C16.5728 -2.04167e-07 13.7215 1.18075 11.6189 3.2826C9.51623 5.38446 8.33443 8.23533 8.33333 11.2083V78.8C8.33664 81.7717 9.51942 84.6204 11.6218 86.7204C13.7242 88.8204 16.5742 90 19.5458 90H65.6292C67.1021 90.0004 68.5604 89.7108 69.9212 89.1475C71.2821 88.5846 72.5187 87.7587 73.5604 86.7175C74.6021 85.6762 75.4283 84.44 75.9921 83.0792C76.5558 81.7187 76.8458 80.2604 76.8458 78.7875V20.5167H66.5292Z" fill="#FF9908"/>
        <path d="M76.8458 20.5167H66.5292C64.2512 20.5145 62.0671 19.6086 60.4562 17.9978C58.8454 16.387 57.9396 14.203 57.9375 11.925V0L76.8458 20.5167Z" fill="#FF9908"/>
        <path d="M84.975 71.0833H32.875C29.1793 71.0833 26.1833 74.0792 26.1833 77.775V93.3083C26.1833 97.0042 29.1793 100 32.875 100H84.975C88.6708 100 91.6667 97.0042 91.6667 93.3083V77.775C91.6667 74.0792 88.6708 71.0833 84.975 71.0833Z" fill="#FF9908"/>
        <path d="M38.7768 93.75C38.3872 93.75 38.111 93.495 37.948 92.985L34.0168 80.5325C33.9318 80.2633 33.9708 79.9942 34.1337 79.725C34.3037 79.4558 34.548 79.3213 34.8668 79.3213C35.0368 79.3213 35.1997 79.3779 35.3555 79.4913C35.5114 79.5975 35.6247 79.7675 35.6955 80.0013L39.1593 91.03H38.6918L41.9855 80.1075C42.0635 79.8525 42.1945 79.6613 42.3787 79.5338C42.5699 79.4063 42.7647 79.3425 42.963 79.3425C43.1755 79.3425 43.381 79.4063 43.5793 79.5338C43.7776 79.6613 43.9122 79.8525 43.9831 80.1075L47.2768 91.03H46.8093L50.2729 80.0013C50.3508 79.7675 50.4608 79.6013 50.6025 79.5021C50.7513 79.3958 50.9104 79.3425 51.0804 79.3425C51.3921 79.3425 51.64 79.4629 51.8242 79.7038C52.0083 79.9446 52.0508 80.2208 51.9517 80.5325L48.0205 92.985C47.8505 93.495 47.5389 93.75 47.0856 93.75H47.043C46.5685 93.75 46.2568 93.495 46.1081 92.985L42.6868 81.9138H43.1543L39.7118 92.985C39.641 93.24 39.5347 93.4313 39.393 93.5588C39.2585 93.6863 39.053 93.75 38.7768 93.75Z" fill="white"/>
        <path d="M55.6071 93.75C55.0121 93.75 54.7146 93.4525 54.7146 92.8575V80.235C54.7146 79.64 55.0121 79.3425 55.6071 79.3425C55.9967 79.3425 56.3225 79.5337 56.5846 79.9162L61.5996 87.46L61.2171 87.5238L66.2746 79.9162C66.5154 79.5337 66.8767 79.3425 67.3583 79.3425C67.84 79.3425 68.0808 79.64 68.0808 80.235V92.8575C68.0808 93.4525 67.7833 93.75 67.1883 93.75C66.5933 93.75 66.2958 93.4525 66.2958 92.8575V82.02L66.8483 82.2113L62.3858 88.7775C62.1238 89.16 61.7625 89.3512 61.3021 89.3512C60.9833 89.3512 60.6929 89.16 60.4308 88.7775L55.9683 82.2113L56.4996 82.36V92.8575C56.4996 93.4525 56.2021 93.75 55.6071 93.75Z" fill="white"/>
        <path d="M71.8188 93.665C71.2521 93.4454 71.0821 93.0558 71.3088 92.4962L76.3663 80.0437C76.5788 79.5762 76.9117 79.3425 77.365 79.3425H77.4075C77.8679 79.3637 78.1796 79.5975 78.3425 80.0437L83.485 92.4962C83.7117 93.0558 83.5487 93.4454 82.9962 93.665C82.4367 93.8846 82.0471 93.7217 81.8275 93.1763L80.68 90.3925H74.0608L72.9663 93.1763C72.7467 93.7288 72.3642 93.8917 71.8188 93.665ZM74.7621 88.6075H79.9471L77.3012 82.1688L74.7621 88.6075Z" fill="white"/>
        <path d="M57.3542 54.9667H52.7917V36.525C52.7917 35.9073 52.5463 35.3149 52.1096 34.8781C51.6725 34.4413 51.0804 34.1959 50.4625 34.1959H38.7958C38.1781 34.1959 37.5857 34.4413 37.1489 34.8781C36.7121 35.3149 36.4667 35.9073 36.4667 36.525V54.9667H31.8833V34.9667C31.8844 33.5496 32.4481 32.191 33.4505 31.1893C34.453 30.1877 35.8121 29.625 37.2292 29.625H51.9833C53.4 29.625 54.7587 30.1878 55.7604 31.1896C56.7621 32.1913 57.325 33.55 57.325 34.9667L57.3542 54.9667Z" fill="#FF9908"/>
        <path d="M51.9458 60.375C54.9329 60.375 57.3542 57.9537 57.3542 54.9667C57.3542 51.9796 54.9329 49.5583 51.9458 49.5583C48.9589 49.5583 46.5375 51.9796 46.5375 54.9667C46.5375 57.9537 48.9589 60.375 51.9458 60.375Z" fill="#FF9908"/>
        <path d="M31.0833 60.375C34.0703 60.375 36.4917 57.9537 36.4917 54.9667C36.4917 51.9796 34.0703 49.5583 31.0833 49.5583C28.0964 49.5583 25.675 51.9796 25.675 54.9667C25.675 57.9537 28.0964 60.375 31.0833 60.375Z" fill="#FF9908"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default WmaSvg;
