import { setTasks } from "app/slices/tasksSlice";
import { useAppDispatch } from "app/store";
import ConditionalWrapper from "components/blocks/ConditionalWrapper";
import CustomScrollbar from "components/blocks/CustomScrollbar";
import DnDHeightPreservingItem from "components/blocks/DnDHeightPreservingItem";
import { tableScroll } from "components/blocks/TaskTable/Table/styled";
import { useBoardContext } from "lib/contexts/BoardContext";
import useBoardSetColumns from "lib/customHooks/dataLayer/board/useBoardSetColumns";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import useSavingState from "lib/customHooks/useSavingState";
import { EntityId } from "lib/types/entity";
import { TableLiteCellProps } from "lib/types/table";
import { MemberRoles } from "lib/types/types";
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
  OnDragEndResponder
} from "react-beautiful-dnd";
import { Column, useBlockLayout, useResizeColumns, useSortBy, useTable } from "react-table";
import { Virtuoso } from "react-virtuoso";
import TasksService from "services/TasksService";
import TaskTableEmptyState from "../components/TaskTableEmptyState";

import { TableContent, TableHeader, TaskTableWrapper, Td, Tr } from "../styled";
import { TableProps } from "../types";
import { ListReducerActionKind } from "../utils/listReducer";
import Cell from "./Cell";
import Header from "./Header";
import { wrapMemberProviderFn, wrapTaskProviderFn } from "./lib";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

type FooterProps = {
  addRow?: boolean
  onAddRow: () => void
  isAddRowShown: boolean
  isSyncedBoard: boolean
  allowedToCreate: boolean
}

const Footer: FC<FooterProps> = (props) => {
  const { addRow, onAddRow, isAddRowShown, isSyncedBoard, allowedToCreate } = props;
  const { board } = useBoardContext();
  const { terminology } = useBoardFeatures(board);
  const btnText = "Add " + terminology.single.toLowerCase();

  return (
    <>
      {addRow && isAddRowShown && !isSyncedBoard && allowedToCreate && (
        <Tr isAddRow onClick={onAddRow}>
          {btnText}...
        </Tr>
      )}
    </>
  );
};

interface VirtualItemProps extends Omit<TableProps, "dispatch" | "data"> {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  rubric: DraggableRubric;
  rows: any;
  prepareRow: any
  handleOnAfterCreate: () => void
  wrapTaskProvider?: boolean;
  onItemExpand?: (itemId: string) => void;
}

const VirtualItem: FC<VirtualItemProps> = memo((props) => {
  const { provided, rubric, snapshot, rows, prepareRow, wrapTaskProvider, wrapMemberProvider, currentSort, currentFilter, customization, onItemExpand, boardId, handleOnAfterCreate } = props;
  const index = rubric.source.index;
  const row = rows[index];

  prepareRow(row);

  return (
    <Tr
      {...row.getRowProps()}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      className={`item ${snapshot.isDragging ? "is-dragging" : ""}`}
      isDragging={snapshot.isDragging}
      style={{
        ...provided.draggableProps.style,
        ...row.getRowProps().style,
      }}
      key={index}
    >
      {(row.cells as TableLiteCellProps[]).map((cell, index) => (
        <ConditionalWrapper
          key={index}
          condition={!!wrapTaskProvider}
          wrapper={children => wrapTaskProviderFn(children, cell)}
        >
          <ConditionalWrapper condition={!!wrapMemberProvider}
            wrapper={children => wrapMemberProviderFn(children, cell)}>
            <Td
              {...cell.getCellProps()}
              isSorted={currentSort?.field?.code === cell.column.code}
              isFiltered={currentFilter?.titleKey === cell.column.title}
            >
              {cell.render("Cell", {
                dragHandleProps: provided.dragHandleProps,
                isSomethingDragging: snapshot.draggingOver,
                task: row.original,
                customization: customization?.cells,
                boardId,
                afterCreate: handleOnAfterCreate,
                onItemExpand: onItemExpand,
              })}
            </Td>
          </ConditionalWrapper>
        </ConditionalWrapper>
      ))}
    </Tr>
  );
});

const Table: FC<TableProps> = (props) => {
  const { columns, data: tasks, dispatch: dataDispatch, boardId, columnsProps, addRow, customization, onItemExpand, isResize, currentSort, currentFilter, wrapMemberProvider, wrapTaskProvider } = props;

  const dispatch = useAppDispatch();
  const { board } = useBoardContext();
  const { updateBoardColumn } = useBoardSetColumns({ board, updateDetailed: true });
  const tableHeaderRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const [resizedColumn, setResizedColumn] = useState<string | null>(null);
  const [isAddRowShown, setIsAddRowShown] = useState(true);
  const { setSavingStart, setSavingFinish } = useSavingState();

  const isSyncedBoard = !!board?.externalDataSource;
  const allowedToCreate = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  const defaultColumn: Partial<Column<object>> = useMemo(
    () => ({
      minWidth: columnsProps?.minWidth || 150,
      width: columnsProps?.width || 200,
      maxWidth: columnsProps?.maxWidth || 450,
      Cell: Cell,
      Header: Header as never,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
    state: { columnResizing },
  } = useTable(
    {
      columns,
      data: tasks,
      defaultColumn,
      dataDispatch
    },
    useBlockLayout,
    useResizeColumns,
    useSortBy
  );

  useEffect(() => {
    setResizedColumn(columnResizing.isResizingColumn || null);
    if (columnResizing.isResizingColumn === null && resizedColumn) {
      setColumnWidth(String(resizedColumn), columnResizing.columnWidths[resizedColumn]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnResizing]);

  const setColumnWidth = (columnId: EntityId, width: number) => {
    if (boardId) {
      updateBoardColumn(columnId, { width });
    }
  };

  const reorderData = useCallback((startIndex: number, endIndex: number) => {
    setSavingStart();

    const newData = Array.from(tasks);
    const [movedRow] = newData.splice(startIndex, 1);
    newData.splice(endIndex, 0, movedRow);
    const nextSate = newData.map((item, index) => ({ ...item, sort__list: index }));

    dispatch(setTasks(nextSate));
    dataDispatch({ type: ListReducerActionKind.setRows, newData: nextSate });

    const payload = nextSate.map((item, index) => ({
      id: item.id,
      sort: index,
    }));

    if (boardId) {
      TasksService.reorderList(boardId, payload)
        .finally(() => {
          setSavingFinish();
        });
    }

    return newData;
  }, [boardId, dataDispatch, dispatch, tasks]);

  const handleOnDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      if (!result
        || !result.destination
        || result.source.index === result.destination.index
      ) {
        return;
      }

      reorderData(result.source.index, result.destination.index);
    }, [reorderData]);

  const onAddRow = () => {
    setIsAddRowShown(!isAddRowShown);
    const newRow = rows.find((row) => Object.keys(row.original).length <= 1);

    if (!newRow) {
      dataDispatch({ type: ListReducerActionKind.addRow });
    }
  };

  const handleOnAfterCreate = useCallback(() => {
    setIsAddRowShown(!isAddRowShown);
    dataDispatch({ type: ListReducerActionKind.removeRow });
  }, [dataDispatch, isAddRowShown]);

  const virtualListHeight = Number(tableRef?.current?.clientHeight) - Number(tableHeaderRef?.current?.clientHeight);
  const virtualListWidth = (Number(tableRef?.current?.clientWidth) > totalColumnsWidth)
    ? Number(tableRef?.current?.clientWidth)
    : totalColumnsWidth;

  /**
   * Implementing react-virtuoso
   * https://virtuoso.dev/react-beautiful-dnd/
   */

  const errorListener = (e: any) => {
    if (e.message === "ResizeObserver loop completed with undelivered notifications." || e.message === "ResizeObserver loop limit exceeded") {
      e.stopImmediatePropagation();
    }
  };
  // Virtuoso's resize observer can this error, which is caught by DnD and aborts dragging.
  useEffect(() => {
    window.addEventListener("error", errorListener);

    return () => {
      window.removeEventListener("error", errorListener);
    };
  }, []);

  return (
    <TaskTableWrapper {...getTableProps()} ref={tableRef}>
      <TableHeader width={totalColumnsWidth} ref={tableHeaderRef}>
        {headerGroups.map((headerGroup, i) => (
          <Tr {...headerGroup.getHeaderGroupProps()} key={i} shadow>
            {headerGroup.headers.map((column) =>
              column.render("Header", { key: column.id, headerRef: tableHeaderRef, isResize }))
            }
          </Tr>
        ))}
      </TableHeader>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable
          droppableId="droppable"
          mode="virtual"
          renderClone={(provided, snapshot, rubric) => (
            <VirtualItem
              wrapTaskProvider={wrapTaskProvider}
              rows={rows}
              prepareRow={prepareRow}
              columns={columns}
              provided={provided}
              snapshot={snapshot}
              rubric={rubric}
              handleOnAfterCreate={handleOnAfterCreate}
              customization={customization}
              currentSort={currentSort}
              currentFilter={currentFilter}
              wrapMemberProvider={wrapMemberProvider}
              boardId={boardId}
            />
          )}
        >
          {(provided) => {
            // @ts-ignore
            return (
              <TableContent
                ref={provided.innerRef}
                {...provided.droppableProps}
                {...getTableBodyProps()}
              >
                <Virtuoso
                  // @ts-ignore
                  scrollerRef={provided.innerRef}
                  className={tableScroll}
                  style={{ width: virtualListWidth, height: virtualListHeight, overflowX: "hidden" }}
                  data={rows}
                  components={{
                    Item: DnDHeightPreservingItem,
                    Footer:
                      () =>
                        <Footer
                          allowedToCreate={allowedToCreate}
                          isAddRowShown={isAddRowShown}
                          isSyncedBoard={isSyncedBoard}
                          onAddRow={onAddRow}
                          addRow={addRow}
                        />,
                    Scroller: CustomScrollbar,
                    EmptyPlaceholder: TaskTableEmptyState,
                  }}
                  itemContent={(index, item) => {
                    return (
                      <Draggable
                        draggableId={String(item.id)}
                        key={item.id}
                        index={index}
                        isDragDisabled={!allowedToCreate}
                      >
                        {(provided, snapshot, rubric) => (
                          <VirtualItem
                            wrapTaskProvider={wrapTaskProvider}
                            rows={rows}
                            prepareRow={prepareRow}
                            columns={columns}
                            provided={provided}
                            snapshot={snapshot}
                            rubric={rubric}
                            handleOnAfterCreate={handleOnAfterCreate}
                            customization={customization}
                            currentSort={currentSort}
                            currentFilter={currentFilter}
                            wrapMemberProvider={wrapMemberProvider}
                            boardId={boardId}
                            onItemExpand={onItemExpand}
                          />
                        )}
                      </Draggable>
                    );
                  }}
                />
                {provided.placeholder}
              </TableContent>
            );
          }}
        </Droppable>
      </DragDropContext>
    </TaskTableWrapper>
  );
};

export default Table;
