import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const CustomizeSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path d="M50.5207 36.4582H63.5414V49.4789C63.5414 53.7936 67.0392 57.2914 71.3539 57.2914C75.6686 57.2914 79.1664 53.7936 79.1664 49.4789V36.4582H92.1874C96.5021 36.4582 99.9999 32.9604 99.9999 28.6457C99.9999 24.331 96.5021 20.8332 92.1874 20.8332H79.1667V7.81248C79.1667 3.49779 75.6689 0 71.3542 0C67.0395 0 63.5417 3.49779 63.5417 7.81248V20.8332H50.521C46.2063 20.8332 42.7085 24.331 42.7085 28.6457C42.7085 32.9604 46.206 36.4582 50.5207 36.4582Z"/>
        <path d="M28.6457 42.7081C12.8252 42.7081 0 55.5333 0 71.3541C0 87.1748 12.8252 99.9997 28.6457 99.9997C44.4662 99.9997 57.2914 87.1746 57.2914 71.3541C57.2743 55.5406 44.4594 42.7255 28.6457 42.7081ZM28.6457 84.3748C21.4545 84.3748 15.625 78.5452 15.625 71.3541C15.625 64.1629 21.4545 58.3333 28.6457 58.3333C35.8368 58.3333 41.6664 64.1629 41.6664 71.3541C41.6664 78.5452 35.8371 84.3748 28.6457 84.3748Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default CustomizeSvg;
