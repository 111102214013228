import { selectCurrentFilter, selectCurrentSort, selectGeneralFilter } from "app/slices/tasksFilterAndSortSlice";
import { useAppSelector } from "app/store";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { isEqualViewFiltersAndCurrentFilters } from "../../filters/filterHelpers";
import useBoardSetViews from "../dataLayer/board/useBoardSetViews";
import useBoardView from "./useBoardView";

type TSaveViewParams = {
  generalFilter?: any
  filter?: any
  sort?: any
}

const useBoardViewSave = () => {
  const board = useAppSelector(state => state.currentBoardReducer.board);
  const currentGeneralFilter = useSelector(selectGeneralFilter);
  const currentFilter = useSelector(selectCurrentFilter);
  const currentSort = useSelector(selectCurrentSort);
  const view = useBoardView();
  const { updateBoardView } = useBoardSetViews({ board, updateDetailed: true });

  const saveView = useCallback(({ generalFilter, filter, sort }: TSaveViewParams) => {
    if (!board || !view) {
      return;
    }

    const payload = {
      appliedFilter: {
        generalFilter: generalFilter || currentGeneralFilter,
        filter: filter || currentFilter
      },
      appliedSort: sort || currentSort
    };

    updateBoardView(view.id, payload);

  }, [board, currentFilter, currentGeneralFilter, currentSort, view]);

  const saveViewFilters = useCallback((props?: Partial<TSaveViewParams>) => {
    if (!board || !view) {
      return;
    }

    // Not needed to save view filters if they are the same to current filters
    if (isEqualViewFiltersAndCurrentFilters(currentFilter, view.appliedFilter?.filter)) {return;}

    const payload = {
      appliedFilter: {
        generalFilter: currentGeneralFilter,
        filter: props?.filter || {
          titleKey: currentFilter.titleKey,
          value: currentFilter.value,
          function: currentFilter.function,
          version: currentFilter.version,
          multiple: currentFilter.multiple,
          operator: currentFilter.operator
        }
      },
      appliedSort: currentSort
    };

    updateBoardView(view.id, payload);
  }, [board, currentFilter, currentGeneralFilter, currentSort, updateBoardView, view]);

  return { saveView, saveViewFilters };
};

export default useBoardViewSave;
