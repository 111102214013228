import { FC, useEffect } from "react";
import { Form, Input, Select } from "antd";
import { Link } from "react-router-dom";

import { BoardInterfaceExtended, BoardUnit } from "lib/types/boardTypes";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import useBoardSetValue from "lib/customHooks/dataLayer/board/useBoardSetValue";
import useBoardSetFeatures from "lib/customHooks/dataLayer/board/useBoardSetFeatures";
import BoardSettingsDisplayView from "pages/Board/BoardSettings/BoardSettingsDisplayView";
import SingleMember from "components/ui/SingleUser";
import { SectionContent, SectionHeading, SectionSubheading, TwoColumnGrid } from "styles/common";
import BoardSettingsFeatures from "pages/Board/BoardSettings/BoardSettingsFeatures";
import { HomeInviteModalDesc } from "pages/Project/ProjectSettings/ProjectCollaborators/styled";
import CustomTooltip from "components/ui/CustomTooltip";
import { BoardUnitsSelectOptions, DefaultBoardUnit } from "./lib";

const BoardFields: FC = () => {
  const { board } = useBoardContext() as BoardContextInterface;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const { setBoardValue } = useBoardSetValue({ board, updateDetailed: true });
  const { setBoardFeatures } = useBoardSetFeatures({ board, updateDetailed: true });
  const [form] = Form.useForm<Partial<BoardInterfaceExtended>>();

  useEffect(() => {
    form.setFieldsValue({
      name: board?.name,
      description: board?.description,
    });
  }, [board, form]);

  const handleOnFieldChange = async (code: keyof BoardInterfaceExtended, value: string, restrictEmpty?: boolean) => {
    const updateValue = value.trim();
    if (updateValue === board[code]) {
      return;
    }

    if (restrictEmpty && !updateValue) {
      form.setFieldValue(code, board[code]);
      return;
    }

    setBoardValue({ [code]: updateValue });
  };

  const handleUnitOnChange = async (value: BoardUnit) => {
    setBoardFeatures({ unit: value });
  };

  return (
    <Form
      form={form}
      initialValues={{
        name: board?.name,
        description: board?.description,
      }}>
      <SectionContent>
        <SectionHeading>General</SectionHeading>
        <HomeInviteModalDesc>
          <span>{"This board belongs to "}
            <span>
              <Link to={`/project/${board?.workspace?.uid}`}>
                {board?.workspace?.name}
              </Link>
            </span>{" project."}</span>
        </HomeInviteModalDesc>
        <TwoColumnGrid>
          <div>
            <SectionSubheading>Name</SectionSubheading>
            <Form.Item name="name">
              <Input
                disabled={!allowedToEdit}
                onBlur={(e) => handleOnFieldChange("name", e.currentTarget.value, true)}
              />
            </Form.Item>
          </div>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <SectionSubheading>Record type</SectionSubheading>
              <CustomTooltip placement="top" content="What should records be called?" iconTopMargin={5} />
            </div>
            <Select
              showSearch
              defaultValue={board.features?.unit || DefaultBoardUnit.code}
              style={{ width: "100%" }}
              onChange={handleUnitOnChange}
              options={BoardUnitsSelectOptions}
              disabled={!allowedToEdit}
            />
          </div>
        </TwoColumnGrid>
        <div>
          <SectionSubheading>Description</SectionSubheading>
          <Form.Item name="description">
            <Input.TextArea
              disabled={!allowedToEdit}
              rows={3}
              onBlur={(e) => handleOnFieldChange("description", e.currentTarget.value)}
            />
          </Form.Item>
        </div>
        <div>
          <BoardSettingsFeatures />
        </div>
        <div>
          <BoardSettingsDisplayView />
        </div>
        {board &&
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <SectionSubheading style={{ marginRight: "5px" }}>Board creator</SectionSubheading>
            </div>
            <SingleMember user={board.owner} type="full" fullWidth/>
          </div>
        }
      </SectionContent>
    </Form>
  );
};

export default BoardFields;
