import { FC } from "react";
import { DefaultIconSize, IconProps } from "../lib";
import { IconSvgContainer } from "components/icons/IconSvgWrapperNew/styled";

const IconSvgWrapperNewColor: FC<IconProps> = (props) => {
  const size = props?.size || DefaultIconSize;

  return (
    <IconSvgContainer size={size}>
      {props.children}
    </IconSvgContainer>
  );
};

export default IconSvgWrapperNewColor;
