import { FC } from "react";
import IconSvgWrapper from "./IconSvgWrapper";
import { IconProps } from "./lib";

const KanbanSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.870738 0C0.389843 0 0 0.389843 0 0.870738V7.02585C0 7.50674 0.389843 7.89658 0.870738 7.89658H5.12927V11.1293C5.12927 11.6101 5.51911 12 6 12H11.1293C11.6102 12 12 11.6101 12 11.1293V0.870738C12 0.389843 11.6102 0 11.1293 0H6C6 0 6.00001 0 6 0H0.870738ZM1.74148 6.15511V1.74148H5.12926V6.15511H1.74148ZM6.87074 10.2585V1.74148H10.2585V10.2585H6.87074Z" />
    </IconSvgWrapper>
  );
};

export default KanbanSvg;
