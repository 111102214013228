import { FC } from "react";
import styled from "@emotion/styled/macro";
import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import { dataInput } from "components/blocks/TaskTable/styled";
import { fieldDetailedWrapper } from "../styled";
import FilesGeneric from "./Display/generic";

const FilesListWrapper = styled.div`
  ${dataInput};
  padding: 6px 8px;
  display: flex;
  align-items: center;
  .ant-spin-container {
    display: grid;
    justify-content: start;
    grid-auto-flow: column;
    grid-template-columns: min-content;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }
  .ant-spin-nested-loading {
    display: flex;
    align-items: center;
    width: 100%;
  }
`;

const FilesDetailedWrapper = styled.div`
  ${fieldDetailedWrapper};
  display: flex;
  align-items: center;
  .ant-spin-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }
  .ant-spin-nested-loading {
    display: flex;
    align-items: center;
    width: 100%;
  }
`;

const FilesField: FC<TaskFieldPropsGeneric> = (props) => {
  const { component } = props;

  switch (component) {
    case TaskSourceComponent.Cell:
      return (
        <FilesListWrapper>
          <FilesGeneric {...props} />
        </FilesListWrapper>
      );
    case TaskSourceComponent.Card:
      return null;
    case TaskSourceComponent.Detailed:
      return (
        <FilesDetailedWrapper>
          <FilesGeneric {...props} />
        </FilesDetailedWrapper>
      );
    default:
      return null;
  }
};

export default FilesField;
