import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";

interface BoardSettingsProps {
  selected?: boolean;
}

export const BoardSettingsFeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const BoardSettingsFeaturesCard = styled.div<BoardSettingsProps>`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 10px 10px 10px 14px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  ${({ selected }) => selected && css`
    border: solid 1px rgba(64, 169, 255, 0.6);
    color: #40a9ff;
    font-weight: 500;
  `}
`;

export const BoardSettingsFeaturesCardContent = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`;

export const BoardSettingsFeaturesCardType = styled.div`
  display: flex;
  flex-direction: column;
`;
