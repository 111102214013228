import { useCallback } from "react";
import { useAppDispatch } from "app/store";
import { WsEvents } from "lib/types/wsTypes";
import useWSSubscription from "../useWSSubscription";
import { FileContainerInterface } from "lib/types/fileContainer";
import { updateFileContainerInCollection } from "app/slices/fileContainerSlice";
import { isAllowedFileContainerUpdateMethod, isCurrentInitiator } from "lib/helpers/websocketUtils";

const useWSUpdateFileContainer = () => {
  const dispatch = useAppDispatch();

  const callback = useCallback((data: FileContainerInterface) => {
    const updateAvailable = !isCurrentInitiator(data.transactionInitiator) || isAllowedFileContainerUpdateMethod(data.transactionInitiator);
    if (updateAvailable) {
      dispatch(updateFileContainerInCollection(data));
    }
  }, [dispatch]);

  useWSSubscription<FileContainerInterface>({
    eventName: WsEvents.FileContainer.Update,
    callback,
    fnKey: "useWSUpdateFileContainer"
  });
};

export default useWSUpdateFileContainer;
