import styled from "@emotion/styled/macro";

export const NotificationItemContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1px;
`;

export const NotificationItemDetails = styled.div`
  display: inline;
  //align-items: center;
`;
