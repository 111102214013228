import { useEffect, useState } from "react";
import WebSocketService from "../API/socket";
import { Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";
import WSSubscriptionFunctionSaver from "../helpers/wsSubscriptionFunctionSaver";
import { WsInterface } from "../types/wsTypes";

const wsSubscriptionFunctionSaver = new WSSubscriptionFunctionSaver();

interface useWSSubscriptionProps<T> {
  eventName: string
  callback: (data: T) => void
  fnKey: string
}

/*
* hook:
* 1. subscribes and unsubscribes from eventName automatically
* 2. calls callback after receive an eventName event
* callback should be wrapper in useCallback
*/
const useWSSubscription = <T>({ eventName, callback, fnKey }: useWSSubscriptionProps<T>) => {
  const [socket, setSocket] = useState<Socket<DefaultEventsMap, DefaultEventsMap> | null>(null);
  if (WebSocketService.socket && !socket) {
    setSocket(WebSocketService.socket);
  }

  useEffect(() => {
    wsSubscriptionFunctionSaver.saveCallback(eventName, fnKey, callback);

    WebSocketService.subscribe<WsInterface<T>>(
      eventName,
      (data: any) => {
        const fn = wsSubscriptionFunctionSaver.getCallbackByFnKey(eventName, fnKey);
        if (fn) {
          fn(data.data);
        }
      });

    return () => {
      wsSubscriptionFunctionSaver.removeCallback(eventName, fnKey);

      const fns = wsSubscriptionFunctionSaver.getAllCallbacksByEventName(eventName);
      if (!fns.length) {
        WebSocketService.unsubscribe(eventName);
      }
    };
  }, [callback, eventName, fnKey, socket]);
};

export default useWSSubscription;
