import { AutomationsTabsHeading } from "../AutomationsModal/styled";
import { TAutomationTriggerFieldsInterface, Trigger } from "../types";

export const AutomationTriggerFields = (terminology: string): TAutomationTriggerFieldsInterface[] => ([
  {
    custom: true,
    component: <AutomationsTabsHeading key="added">{terminology} added</AutomationsTabsHeading>,
  },
  {
    text: Trigger.toThisBoard,
  },
  {
    custom: true,
    component: <AutomationsTabsHeading key="updated" style={{ marginTop: "20px" }}>{terminology} updated</AutomationsTabsHeading>
  },
  {
    text: Trigger.completed,
  },
  {
    text: Trigger.assignees,
  },
  {
    text: Trigger.dueDate,
  },
  {
    text: Trigger.status,
  },
  {
    text: Trigger.comments,
  },
  {
    text: Trigger.dueDate,
    custom: true,
    component: <AutomationsTabsHeading key="timing" style={{ marginTop: "20px" }}>{terminology} timing</AutomationsTabsHeading>
  },
  {
    text: Trigger.dueDateIsApproaching,
    disabled: true,
  },
  {
    text: Trigger.taskIsOverdue,
    disabled: true,
  },
]);
