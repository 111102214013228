import styled from "@emotion/styled/macro";
import { scrollBar } from "styles/common";
import { css } from "@emotion/css";

export const NotificationsDrawerWrapper = styled.div`
  display: flex;
  margin-right: 10px;
`;

export const NotificationsDrawerTabs = styled.div`
  position: relative;
  height: 100%;

  // TODO: (boris@airtasks.com) Look into scrollbars
  //[data-test-id="virtuoso-item-list"] {
  //  padding: 0 !important;
  //}
  
  .ant-tabs-nav-wrap {
    padding-inline: 20px;
  }
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs, .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-tabpane {
    height: 100%;
  }
  .ant-tabs-tabpane > div {
    overflow-x: hidden;
    ${scrollBar};
    &::-webkit-scrollbar {
      width: 12px;
      height: 0;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      margin-block: -2px;
    }
    &::-webkit-scrollbar-thumb {
      border: 3px solid white;
      border-radius: 6px;
    }
  }
`;

export const virtuosoCustomClass = css`
  > div {
    padding-block: 15px;
  }
`;

export const NotificationsDrawerSettings = styled.div`
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  bottom: auto;
  z-index: 1;
  margin-right: 20px;
  height: 44px;
  display: flex;
  align-items: center;
`;
