import styled from "@emotion/styled/macro";
import { TwoColumns } from "styles/common";

export const FormInner = styled.div`
  margin: -15px 0;
`;

export const AddTaskOneCol = styled.div`
  margin: 15px 5px;
  .ant-select-selection-item {
    border-radius: 12px;
  }
  .ant-select-selection-item-remove {
    margin-right: 1px;
  }
  .ant-select-selection-item-content {
    div:first-child {
      //margin: 0 !important;
    }
  }
`;

export const AddTaskRow = styled(TwoColumns)`
  margin: 15px 0;
`;
