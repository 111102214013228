import { FC, useEffect, useState } from "react";
import { ConfigProvider, Form, FormInstance, Modal } from "antd";

import { MemberRoles, WorkspaceInterface } from "lib/types/types";
import { BoardInterface, DefaultColumnCodes } from "lib/types/boardTypes";
import { useAppSelector } from "app/store";

import TextField from "components/blocks/FormComponents/TextField";
import TextAreaField from "components/blocks/FormComponents/TextAreaField";
import CascaderField from "components/blocks/FormComponents/CascaderField";
import SelectField from "components/blocks/FormComponents/SelectField";
import DateField from "components/blocks/FormComponents/DateField";

import { Column } from "styles/common";
import { AddTaskOneCol, AddTaskRow, FormInner } from "./styled";
import { EntityId } from "lib/types/entity";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import CustomStyledButton from "components/blocks/CustomStyledButton";
import { commonModalProps } from "components/blocks/ModalDialog/lib";
import { ButtonCancel } from "components/blocks/TaskFieldsModal/Modal/styled";
import { EditorJsCore } from "components/blocks/EditorJS/lib";

interface TasksFormProps {
  form: FormInstance;
  boardId?: EntityId;
  isLoading: boolean;
  isVisible: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  editorRef: React.MutableRefObject<EditorJsCore | null>
}

const TasksForm: FC<TasksFormProps> = (props) => {
  const { form, boardId, onCancel, onSubmit, isLoading, isVisible } = props;
  const user = useAppSelector(state => state.currentUserReducer.user);
  const workspacesFromRedux = useAppSelector(state => state.workspaces.workspaces);

  const workspaces = workspacesFromRedux?.filter(workspace => workspace.members.find(m =>
    (String(m.user?.id) === String(user?.id)) && ([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor].includes(m.role))
  ));

  const [boardSelectorData, setBoardSelectorData] = useState([]);
  const [currentWorkspace, setCurrentWorkspace] = useState<WorkspaceInterface | null>(null);
  const [currentBoard, setCurrentBoard] = useState<BoardInterface | null>(null);
  const { isStatusEnabled, isDueDatesEnabled, isAssigneesEnabled, terminology } = useBoardFeatures(currentBoard);
  const unitName = terminology.single.toLowerCase();

  const isCollaboratorsFieldDisabled = !(currentWorkspace && currentWorkspace.collaborators);
  const collaboratorsFieldData = currentWorkspace ? currentWorkspace.collaborators : [];

  const isStatusFieldDisabled = !(currentBoard && currentBoard.statuses);
  const statusFieldData = currentBoard ? currentBoard.statuses : [];

  useEffect(() => {
    if (boardId) {
      const [workspace, wSpaceBoard] = workspaces.reduce((acc: any, workspace: WorkspaceInterface) => {
        const wSpaceBoard = workspace.boards.find((board: any) => String(board.id) === String(boardId));

        if (wSpaceBoard) {
          acc = [workspace, wSpaceBoard];
        }

        return acc;
      }, []);

      setCurrentWorkspace(workspace);
      setCurrentBoard(wSpaceBoard);

      form.setFieldsValue({ board: [workspace.id, wSpaceBoard.id] });

      const defaultStatus = (wSpaceBoard as BoardInterface).statuses.find(status => status.system);
      if (defaultStatus) {
        form.setFieldsValue({
          [DefaultColumnCodes.Status]: String(defaultStatus.id),
        });
      }
    }
  }, []);

  useEffect(() => {
    const getBoardSelectorData = workspaces.reduce((acc: any, workspace: WorkspaceInterface) => {
      const boards = workspace.boards.map((board) => ({
        value: board.id,
        label: board.name,
        icon: board.icon,
      }));

      const data = {
        value: workspace.id,
        label: workspace.name,
        children: boards,
        disabled: !boards?.length
      };
      acc.push(data);

      return acc;
    }, []);

    setBoardSelectorData(getBoardSelectorData);
  }, [workspacesFromRedux]);

  const onCascaderChange = (values: Array<number>) => {
    const workspace = workspaces.find(workspace => workspace.id === values[0]);
    const board = workspace?.boards.find(board => board.id === values[1]);

    form.setFieldsValue({
      [DefaultColumnCodes.Assignees]: [],
      [DefaultColumnCodes.Status]: null
    });

    if (workspace) {
      setCurrentWorkspace(workspace);
    }

    if (board) {
      return setCurrentBoard(board);
    }

    setCurrentWorkspace(null);
    return setCurrentBoard(null);
  };

  return (
    <Modal
      {...commonModalProps}
      title={`Add ${unitName}`}
      visible={isVisible}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <ButtonCancel onClick={onCancel} key="cancel">Cancel</ButtonCancel>,
        <CustomStyledButton
          key="ok"
          onClick={onSubmit}
          disabled={isLoading}
          btnColor="purple"
          isLoading={isLoading}
          value={`Create ${unitName}`}
        />
      ]}
    >
      <ConfigProvider getPopupContainer={trigger => trigger?.parentElement as HTMLElement}>
        <Form
          form={form}
          layout="vertical"
          preserve={false}
          requiredMark={false}
        >
          <FormInner>
            <AddTaskRow>
              <Column>
                <TextField
                  label="Title"
                  name={DefaultColumnCodes.Title}
                  placeholder={`Type ${unitName} title`}
                  rules={
                    [{ required: true, message: "required", whitespace: true }]
                  }
                />
              </Column>
              <Column>
                <CascaderField
                  label="Board"
                  name="board"
                  placeholder="Select board"
                  options={boardSelectorData}
                  onChange={onCascaderChange}
                  rules={
                    [{ type: "array", required: true, message: "required", whitespace: true }]
                  }
                />
              </Column>
            </AddTaskRow>
            <AddTaskRow>
              {isStatusEnabled &&
                <Column>
                  <SelectField
                    label="Status"
                    name={DefaultColumnCodes.Status}
                    placeholder={`Select ${unitName} status`}
                    disabled={isStatusFieldDisabled}
                    data={statusFieldData}
                    optionValue="id"
                    optionLabel="title"
                    optionDisplayType="status"
                    rules={
                      [{ required: true, message: "required" }]
                    }
                  />
                </Column>
              }
              {isDueDatesEnabled &&
                <Column>
                  <DateField
                    label="Due date"
                    name={DefaultColumnCodes.DueDate}
                    placeholder={`Select ${unitName} due date`}
                  />
                </Column>
              }
            </AddTaskRow>
            {isAssigneesEnabled &&
              <AddTaskOneCol>
                <SelectField
                  mode="multiple"
                  label="Assignees"
                  name="assignees"
                  placeholder={`Select ${unitName} assignees`}
                  disabled={isCollaboratorsFieldDisabled}
                  data={collaboratorsFieldData}
                  optionValue="id"
                  optionLabel="username"
                  optionDisplayType="user"
                />
              </AddTaskOneCol>
            }
            <AddTaskOneCol>
              <TextAreaField
                label="Description"
                editorRef={props.editorRef}
              />
            </AddTaskOneCol>
          </FormInner>
        </Form>
      </ConfigProvider>
    </Modal>
  );
};

export default TasksForm;
