import { FC, MutableRefObject, useEffect, useRef, useState } from "react";
import { RadioChangeEvent } from "antd";
import TaskChangelogRenderer from "components/blocks/TaskDetailed/TaskChangelogRenderer";
import { CommentInterface } from "lib/types/types";
import { CommentSection } from "pages/Board/BoardKanban/Card/CardDetails/styled";
import TaskCollaborators from "pages/Task/TaskCollaborators";
import TaskCommentBox, { TaskCommentBoxProps } from "pages/Task/TaskCommentBox";
import { TaskCommentSectionViews } from "../lib";
import { TaskDetailedTabs, TaskDetailedTabPane } from "../styled";

interface TaskDetailedChangelogProps {
  onEditComment: (comment: CommentInterface) => void;
  onDiscardEditComment: { (): void; (): void; };
  hideCommentBox: any;
  commentBoxOptions: Omit<TaskCommentBoxProps, "taskId" | "onDiscard" | "workspaceId">;
}

const TaskDetailedChangelog: FC<TaskDetailedChangelogProps> = (props) => {
  const [commentSectionView, setCommentSectionView] = useState(TaskCommentSectionViews.Comments);
  const comments = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      comments.current.scrollIntoView();
    }
  }, []);

  const handleSwitchCommentSectionView = (e: RadioChangeEvent) => {
    setCommentSectionView(e.target.value);
  };

  return (
    <>
      <CommentSection id="comments" ref={comments}>
        <TaskDetailedTabs size="small" onChange={handleSwitchCommentSectionView} defaultValue={commentSectionView}>
          <TaskDetailedTabPane value={TaskCommentSectionViews.Comments}>Comments</TaskDetailedTabPane>
          <TaskDetailedTabPane value={TaskCommentSectionViews.Changes}>Changes</TaskDetailedTabPane>
          <TaskDetailedTabPane value={TaskCommentSectionViews.All}>All</TaskDetailedTabPane>
        </TaskDetailedTabs >
        <TaskChangelogRenderer
          view={commentSectionView}
          onEditComment={props.onEditComment}
          onDiscardEditComment={props.onDiscardEditComment}
        />
      </CommentSection >
      {!props.hideCommentBox &&
        <div>
          <TaskCommentBox onDiscard={props.onDiscardEditComment} {...props.commentBoxOptions} />
          <TaskCollaborators />
        </div>
      }
    </>
  );
};

export default TaskDetailedChangelog;
