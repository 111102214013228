import styled from "@emotion/styled/macro";
import { Input } from "antd";

const { TextArea } = Input;

export const ChecklistReorderContainer = styled.div`
  display: flex;
  align-items: center;
  //align-self: stretch;
  margin-top: 6px;
  opacity: 0;
  transition: background-color 200ms ease;
`;

export const ChecklistDeleteContainer = styled.div`
  display: flex;
  align-items: center;
  //align-self: stretch;
  //margin-top: 5px;
  opacity: 0;
  transition: background-color 200ms ease;
`;

export const ChecklistRow = styled.div`
  display: flex;
  align-items: start;
  margin: 3px -8px;
  padding: 3px 7px;
  border-radius: 2px;
  transition: background-color 200ms ease;
  &:hover{
    background-color: #f6f6f6;
    ${ChecklistReorderContainer} {
      opacity: 1;
    }
    ${ChecklistDeleteContainer} {
      opacity: 1;
    }
  }
`;

export const ChecklistInput = styled(TextArea) <{ isChecked?: boolean }>`
  text-decoration: ${({ isChecked }) => isChecked ? "line-through" : "none"};
  color: #999;
  font-size: 13px;
  margin-top: 1px;
  overflow: hidden;
`;
