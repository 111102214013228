import { useCallback } from "react";

import { updateTask } from "app/slices/tasksSlice";
import { TaskInterface } from "lib/types/tasksTypes";
import { WsEvents } from "lib/types/wsTypes";

import useWSSubscription from "../useWSSubscription";
import { useAppDispatch } from "app/store";
import { isAllowedTaskUpdateMethod, isCurrentInitiator } from "lib/helpers/websocketUtils";

const useWSUpdateTask = (forceUpdate = true) => {
  const dispatch = useAppDispatch();

  const callback = useCallback((data: TaskInterface) => {
    const updateAvailable = !isCurrentInitiator(data.transactionInitiator) || isAllowedTaskUpdateMethod(data.transactionInitiator);

    if (forceUpdate || updateAvailable) {
      dispatch(updateTask(data));
    }
  }, [dispatch, forceUpdate]);

  useWSSubscription<TaskInterface>({
    eventName: WsEvents.Task.Update,
    callback,
    fnKey: "useWSUpdateTask"
  });
};

export default useWSUpdateTask;
