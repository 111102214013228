import { setFilterTypes } from "app/slices/tasksFilterAndSortSlice";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import {
  assigneeFilter,
  booleanFilter,
  dateFilter,
  multiSelectFilter,
  numberFilter,
  selectFilter,
  textFilter
} from "lib/filters/filterPresets";
import { unionBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/store";
import { FilterOperatorEnum } from "../../filters/filterOperators";
import { noneFilter, } from "../../filters/filterPresets/filterPresets";
import { FilterVersion, MultipleFilter } from "../../filters/filters";
import { FilterType, TFilterFn } from "../../filters/filterTypes";
import { BoardInterface, BoardInterfaceExtended } from "../../types/boardTypes";
import { PropertyValueType } from "../../types/dataTypes";
import { TaskInterface } from "../../types/tasksTypes";
import useBoardView from "../views/useBoardView";
import useBoardViewFilter from "../views/useBoardViewFilter";

type TUseCustomColumnsFilters = (originalFilters: any, board?: BoardInterfaceExtended | BoardInterface | null) => {
  allFilters: TFilterFn
  filterFn: (...args: any[]) => TaskInterface[]
}

const getIsActive = (filter: string, board?: BoardInterfaceExtended | BoardInterface | null): boolean => {
  const isActive = board?.columns.find(column => {
    return (filter === FilterType.uID)
      ? column.title === "ID"
      : column.title === filter;

  })?.active;

  if (typeof isActive === "boolean") {
    return isActive;
  }

  return true;
};

const getSortedAndActiveFilters = (originalFilters: any, board?: BoardInterfaceExtended | BoardInterface | null) => {
  const filters: any = {};

  for (const filter in originalFilters) {
    if (!getIsActive(filter, board)) {
      continue;
    }

    filters[filter] = {
      ...originalFilters[filter],
      sort: board?.columns?.find(column => column.title === filter)?.sort
    };
  }

  return filters;
};

const useCustomColumnsFilters: TUseCustomColumnsFilters = (originalFilters, board) => {
  const dispatch = useDispatch();
  const currentBoard = useAppSelector(state => state.currentBoardReducer.board);
  const view = useBoardView();
  const currentFilter = useBoardViewFilter();
  const [allFilters, setAllFilters] = useState<any>(getSortedAndActiveFilters(originalFilters, currentBoard || board));

  useEffect(() => {
    const customColumns = (currentBoard || board)?.columns.filter(column => column.class === TaskPropertyClass.Custom) || [];
    const types: Record<string, FilterType> = {};
    const filters = customColumns.reduce((previousValue, column) => {
      const sort = column.sort;

      switch (column.type) {
        /** Text fields */
        case PropertyValueType.SingleLineText:
        case PropertyValueType.MultiLineText:
        case PropertyValueType.Url: {
          types[column.title as string] = FilterType.Text;
          return {
            ...previousValue,
            [column.title as string]: { ...textFilter, sort }
          };
        }

        /** Boolean fields */
        case PropertyValueType.Boolean: {
          types[column.title as string] = FilterType.Boolean;
          return {
            ...previousValue,
            [column.title as string]: { ...booleanFilter, sort }
          };
        }

        /** Number fields */
        case PropertyValueType.Number:
        case PropertyValueType.Currency:
        case PropertyValueType.Percent: {
          types[column.title as string] = FilterType.Number;
          return {
            ...previousValue,
            [column.title as string]: { ...numberFilter, sort }
          };
        }


        /** Single-select fields */
        case PropertyValueType.SingleSelect: {
          types[column.title as string] = FilterType.SingleSelect;
          return {
            ...previousValue,
            [column.title as string]: { ...selectFilter, sort }
          };
        }

        /** Single user fields */
        case PropertyValueType.User: {
          types[column.title as string] = FilterType.User;
          return {
            ...previousValue,
            [column.title as string]: { ...assigneeFilter, sort }
          };
        }

        /** Multi-users fields */
        case PropertyValueType.Users: {
          types[column.title as string] = FilterType.Users;
          return {
            ...previousValue,
            [column.title as string]: { ...assigneeFilter, sort }
          };
        }

        /** Multi-select fields */
        case PropertyValueType.MultiSelect: {
          types[column.title as string] = FilterType.MultipleSelect;
          return {
            ...previousValue,
            [column.title as string]: { ...multiSelectFilter, sort }
          };
        }

        /** Date fields */
        case PropertyValueType.Date: {
          types[column.title as string] = FilterType.Date;
          return {
            ...previousValue,
            [column.title as string]: { ...dateFilter, sort }
          };
        }

        default:
          return previousValue;
      }
    }, {});

    dispatch(setFilterTypes(types));
    setAllFilters({ ...getSortedAndActiveFilters(originalFilters, currentBoard || board), ...filters });

  }, [board, currentBoard, dispatch, originalFilters, view]);

  const filterFn = useCallback((tasks: TaskInterface[]) => {
    // Backward compatibility. Filtering for previous versions v1 and v2
    if (currentFilter.version && currentFilter.version !== FilterVersion.v3) {
      return allFilters[currentFilter.titleKey]?.filterFn(tasks, currentFilter) || noneFilter.filterFn(tasks);
    }

    // Filtering for v3
    if (currentFilter.multiple?.length) {
      // OR
      if (currentFilter.operator === FilterOperatorEnum.or) {

        let filteredTasks: TaskInterface[] = [];
        currentFilter.multiple.forEach((currentValue) => {
          const partOfFilteredTasks = allFilters[currentValue.titleKey]?.filterFn(tasks, currentValue) || noneFilter.filterFn(tasks);
          filteredTasks = [...filteredTasks, ...partOfFilteredTasks];
        });

        return unionBy(filteredTasks, "id");
      }

      // AND
      return currentFilter.multiple.reduce((accumulator: TaskInterface[], currentValue: MultipleFilter) => {
        return allFilters[currentValue.titleKey]?.filterFn(accumulator, currentValue) || noneFilter.filterFn(accumulator);
      }, tasks);
    }

    return allFilters[currentFilter.titleKey]?.filterFn(tasks, currentFilter) || noneFilter.filterFn(tasks);
  }, [allFilters, currentFilter]);

  return {
    filterFn,
    allFilters
  };
};

export default useCustomColumnsFilters;
