import { FC } from "react";
import { Progress } from "antd";
import { TaskFieldSetterGetter } from "lib/types/applicationTypes";
import { TaskSubtaskInterface } from "lib/types/tasksTypes";
import { FieldEmptyState } from "../../styled";
import { calculateTaskProgress } from "lib/helpers/taskUtils";

interface ProgressListProps extends TaskFieldSetterGetter {
  subtasks: Array<TaskSubtaskInterface>
}

const ProgressList: FC<ProgressListProps> = (props) => {
  const { subtasks } = props;

  return (
    <>
      {subtasks?.length
        ? <Progress percent={calculateTaskProgress(subtasks)} />
        : <FieldEmptyState>Empty</FieldEmptyState>
      }
    </>
  );
};

export default ProgressList;
