import { StoreType } from "../../app/store";
import { TAnyFilters } from "./filters";
import { FilterType, getDefaultFilterTypeByTitle } from "./filterTypes";

export type TFilterFunctions =
  "=" |
  "≠" |
  ">" |
  "<" |
  "≥" |
  "≤" |
  "This week" |
  "Is before" |
  "Is after" |
  "Is on or before" |
  "Is on or after" |
  "" |
  "Is" |
  "Is not" |
  "Is not empty" |
  "Is empty" |
  "Contains" |
  "Does not contain" |
  "Starts with" |
  "Ends with";

// TODO: Boris, is it possible to create a type from this enum the same as TFilterFunctions?
export enum FilterFunction {
  "=" = "=",
  "≠" = "≠",
  ">" = ">",
  "<" = "<",
  "≥" = "≥",
  "≤" = "≤",
  "This week" = "This week",
  "Is before" = "Is before",
  "Is after" = "Is after",
  "Is on or before" = "Is on or before",
  "Is on or after" = "Is on or after",
  "Is" = "Is",
  "Is not" = "Is not",
  "Is not empty" = "Is not empty",
  "Is empty" = "Is empty",
  "Contains" = "Contains",
  "Does not contain" = "Does not contain",
  "Starts with" = "Starts with",
  "Ends with" = "Ends with"
}

const FilterFunctionsDateKeys: TFilterFunctions[] = [
  "Is",
  "Is before",
  "Is after",
  "Is on or before",
  "Is on or after",
  "Is empty",
  "Is not empty",
];

const FilterFunctionsDueDateKeys: TFilterFunctions[] = [
  ...FilterFunctionsDateKeys,
  "This week"
];

const FilterFunctionsNumberKeys: TFilterFunctions[] = [
  "=",
  "≠",
  ">",
  "<",
  "≥",
  "≤",
  "Is empty",
  "Is not empty"
];

const FilterFunctionsBooleanKeys: TFilterFunctions[] = [
  "Is",
  "Is not"
];

const FilterFunctionsSingleSelectKeys: TFilterFunctions[] = [
  "Is",
  "Is not",
  "Is empty",
  "Is not empty"
];

const FilterFunctionsStatusKeys: TFilterFunctions[] = [
  "Is",
  "Is not"
];

const FilterFunctionsMultiSelectKeys: TFilterFunctions[] = [
  "Contains",
  "Does not contain",
  "Is empty",
  "Is not empty"
];

const FilterFunctionsTextKeys: TFilterFunctions[] = [
  "Is",
  "Is not",
  "Is empty",
  "Is not empty",
  "Contains",
  "Does not contain",
  "Starts with",
  "Ends with",
];

export const MappedFilterFunctions = {
  None: [],
  Assignees: FilterFunctionsMultiSelectKeys,
  "Due date": FilterFunctionsDueDateKeys,
  Status: FilterFunctionsStatusKeys,
  Title: FilterFunctionsTextKeys,
  [FilterType.SingleSelect]: FilterFunctionsSingleSelectKeys,
  [FilterType.Text]: FilterFunctionsTextKeys,
  [FilterType.MultipleSelect]: FilterFunctionsMultiSelectKeys,
  [FilterType.Date]: FilterFunctionsDateKeys,
  [FilterType["Created time"]]: FilterFunctionsDateKeys,
  [FilterType["Last modified"]]: FilterFunctionsDateKeys,
  [FilterType["Closing date"]]: FilterFunctionsDateKeys,
  [FilterType.Boolean]: FilterFunctionsBooleanKeys,
  [FilterType.Number]: FilterFunctionsNumberKeys,
  [FilterType.Users]: FilterFunctionsMultiSelectKeys,
  [FilterType.User]: FilterFunctionsSingleSelectKeys,
  [FilterType["Created by"]]: FilterFunctionsSingleSelectKeys,
  [FilterType["Last modified by"]]: FilterFunctionsSingleSelectKeys,
  [FilterType.uID]: FilterFunctionsTextKeys,
};

export type TMappedFilterFunctions = typeof MappedFilterFunctions

export const getMappedFilterFunction = (
  currentFilter: StoreType["boardFilterAndSort"]["filter"] | TAnyFilters,
  types: { [key: string]: FilterType }
) => {
  const currentType = getDefaultFilterTypeByTitle(currentFilter.titleKey) || types?.[currentFilter.titleKey];
  return MappedFilterFunctions[currentType ? currentType : currentFilter.titleKey as keyof TMappedFilterFunctions];
};
