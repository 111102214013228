import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { TaskFieldSetterGetter } from "lib/types/applicationTypes";
import { MemberRoles } from "lib/types/types";
import { FC, useEffect, useState } from "react";

const BooleanGeneric: FC<TaskFieldSetterGetter> = (props) => {
  const { value, setValue, code } = props;

  const [currentValue, setCurrentValue] = useState(value);
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const onChange = (e: CheckboxChangeEvent) => {
    setCurrentValue(e.target.checked);
    setValue({ cellName: code, cellValue: e.target.checked });
  };

  return (
    <Checkbox checked={currentValue} onChange={onChange} disabled={!allowedToEdit} />
  );
};

export default BooleanGeneric;
