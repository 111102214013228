import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";

const fieldDetailedGeneral = css`
  cursor: auto;
  font-size: 13px;
  padding: 7px 9px;
`;

export const fieldDetailedWrapper = css`
  ${fieldDetailedGeneral};
`;

export const fieldDetailedInput = css`
  ${fieldDetailedGeneral};
  padding-left: 0;
  flex: 1 1 auto;
`;

export const fieldDetailedHover = css`
  ${fieldDetailedGeneral};
  cursor: pointer;
  display: flex;
  border-radius: 5px;
  transition: background-color 200ms ease;
  &:hover {
    background-color: #f6f6f6;
  }
`;

export const FieldEmptyState = styled.div`
  color: #bfbfbf;
`;
