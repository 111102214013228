import styled from "@emotion/styled/macro";
import { notificationsWrapper } from "pages/Notifications/styled";
import { NotificationsButtonsWrapper } from "pages/Notifications/styled";

export const NotificationGroupButtons = styled(NotificationsButtonsWrapper)``;

export const NotificationGroupContainer = styled.div`
  ${notificationsWrapper};
  &:hover {
    ${NotificationGroupButtons} {
      opacity: 1;
    }
  }
`;
