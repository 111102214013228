import { getMarkupExtensions } from "lib/helpers/viewerUtils";
import { ForgeMarkupToolbarElementId } from "pages/Viewer/ForgeMarkupToolbar/lib";
import { ForgeViewerExtension } from "../abstract";

export const ForgeMarkup2dDefaultLayerId = "ViewpointMarkup2d";

export default class Markup2dEditor extends ForgeViewerExtension {
  public static readonly extensionName = "Airtasks.Markup2dEditor";
  public readonly DefaultLayerId = ForgeMarkup2dDefaultLayerId;

  markupToolButton: Autodesk.Viewing.UI.Button | null;
  activeStatus: boolean;

  constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
    super(viewer, options);
    this.viewer = viewer;
    this.activeStatus = false;
    this.markupToolButton = null;
  }

  private markupCoreExt() {
    const { markupCore } = getMarkupExtensions(this.viewer);
    return markupCore;
  }

  private toolbarContainer() {
    return document.getElementById(ForgeMarkupToolbarElementId) as HTMLDivElement;
  }

  activate() {
    this.logger.debug("activate");
    if (!this.activeStatus) {
      this.activeStatus = true;
      this.toolbarContainer().hidden = false;
      this.markupCoreExt()?.enterEditMode();
    }
    return true;
  }

  deactivate() {
    this.logger.debug("deactivate");
    if (this.activeStatus) {
      this.activeStatus = false;
      this.toolbarContainer().hidden = true;
      this.markupCoreExt()?.leaveEditMode();
      this.markupCoreExt()?.hide();
    }
    return true;
  }

  load() {
    this.logger.debug("load");
    return true;
  }

  unload() {
    this.logger.debug("unload");
    return true;
  }

  onToolbarCreated(toolbar: any) {
    this.markupToolButton = new Autodesk.Viewing.UI.Button("toolbar-markupTool");
    this.markupToolButton.setToolTip("Markup");
    this.markupToolButton.setIcon("adsk-icon-markup");
    this.markupToolButton.onClick = () => {
      this.activate();
    };

    const modelTools = toolbar.getControl(Autodesk.Viewing.TOOLBAR.MODELTOOLSID);
    if (modelTools) {
      modelTools.addControl(this.markupToolButton, { index: 0 });
    }
  }

  destroyToolbarUI() {
    if (this.markupToolButton) {
      const toolbar = this.viewer?.toolbar;
      if (toolbar) {
        this.markupToolButton.removeFromParent();
      }
      this.markupToolButton = null;
    }
  }
}

export type ForgeMarkup2dEditorExtension = Markup2dEditor & Autodesk.Viewing.Extension;
