import styled from "@emotion/styled/macro";
import { scrollBar } from "styles/common";

export const boardStyles = {
  height: "100%",
  padding: "0 20px",
  color: "#000",
  backgroundColor: "transparent",
};

export const laneStyles = {
  width: "270px",
  height: "100%",
  maxHeight: "100%",
  margin: 0,
  padding: "10px 5px 0",
  backgroundColor: "transparent",
  cursor: "grab",
};

export const BoardKanbanContainer = styled.div`
  height: 100%;
  padding: 10px 0 0;
  overflow-y: auto;
  background-color: #fdfdfd;
  .draggingCard,
  .draggingLane {
    transform: rotate(0deg);
  }
  .draggingLane {
    background: #f5f5f5 !important;
  }
  .react-trello-board {
    & > div:first-of-type {
      height: 100%;
      & > div {
        height: 100%;
      }
    }
  }
  .react-trello-lane > div {
    width: 100%;
    max-height: 100%;
    padding: 0 10px 20px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    overflow: visible;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  div:first-child {
    ${scrollBar};
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  overflow: hidden;
`;
