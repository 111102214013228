import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const MidSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.3792 20.5167C64.1013 20.5145 61.9171 19.6086 60.3063 17.9978C58.6954 16.387 57.7896 14.203 57.7875 11.925V7.74254e-07H19.4001C17.9272 -0.00054653 16.4687 0.28907 15.1079 0.852304C13.7471 1.41554 12.5105 2.24137 11.4689 3.2826C10.4273 4.32383 9.60097 5.56008 9.03723 6.92071C8.47349 8.28133 8.18335 9.73971 8.18335 11.2125V78.8042C8.18777 81.7758 9.3716 84.6242 11.4748 86.7233C13.5779 88.8225 16.4285 90.0012 19.4001 90H65.4833C68.4571 90 71.3092 88.8187 73.4117 86.7158C75.5146 84.6133 76.6958 81.7612 76.6958 78.7875V20.5167H66.3792Z" fill="#005FAD"/>
        <path d="M76.6959 20.5167H66.3793C64.1013 20.5145 61.9172 19.6086 60.3063 17.9978C58.6955 16.387 57.7897 14.203 57.7876 11.925V0L76.6959 20.5167Z" fill="#005FAD"/>
        <path d="M84.8293 71.0834H32.7293C29.0336 71.0834 26.0376 74.0792 26.0376 77.775V93.3084C26.0376 97.0042 29.0336 100 32.7293 100H84.8293C88.5251 100 91.5209 97.0042 91.5209 93.3084V77.775C91.5209 74.0792 88.5251 71.0834 84.8293 71.0834Z" fill="#005FAD"/>
        <path d="M42.2004 93.75C41.6054 93.75 41.3079 93.4525 41.3079 92.8575V80.235C41.3079 79.64 41.6054 79.3425 42.2004 79.3425C42.5899 79.3425 42.9158 79.5338 43.1779 79.9163L48.1929 87.46L47.8104 87.5238L52.8679 79.9163C53.1087 79.5338 53.4699 79.3425 53.9516 79.3425C54.4333 79.3425 54.6741 79.64 54.6741 80.235V92.8575C54.6741 93.4525 54.3766 93.75 53.7816 93.75C53.1866 93.75 52.8891 93.4525 52.8891 92.8575V82.02L53.4416 82.2113L48.9791 88.7775C48.717 89.16 48.3558 89.3513 47.8954 89.3513C47.5766 89.3513 47.2862 89.16 47.0241 88.7775L42.5616 82.2113L43.0929 82.36V92.8575C43.0929 93.4525 42.7954 93.75 42.2004 93.75Z" fill="white"/>
        <path d="M59.985 93.75C59.6804 93.75 59.4538 93.6792 59.305 93.5375C59.1634 93.3888 59.0925 93.1621 59.0925 92.8575V80.2563C59.0925 79.9446 59.1634 79.7179 59.305 79.5763C59.4538 79.4346 59.6804 79.3638 59.985 79.3638C60.2967 79.3638 60.5234 79.4346 60.665 79.5763C60.8067 79.7179 60.8775 79.9446 60.8775 80.2563V92.8575C60.8775 93.1621 60.8067 93.3888 60.665 93.5375C60.5234 93.6792 60.2967 93.75 59.985 93.75Z" fill="white"/>
        <path d="M66.1896 93.75C65.5946 93.75 65.2971 93.4525 65.2971 92.8575V80.235C65.2971 79.64 65.5946 79.3425 66.1896 79.3425H71.3109C73.1596 79.3425 74.5196 79.7817 75.3909 80.66C76.2692 81.5313 76.7084 82.8913 76.7084 84.74V88.3525C76.7084 90.2013 76.273 91.5646 75.4017 92.443C74.5375 93.3142 73.1738 93.75 71.3109 93.75H66.1896ZM67.0821 91.965H71.3109C72.2175 91.965 72.933 91.855 73.4571 91.6354C73.9884 91.4088 74.3638 91.0334 74.5834 90.5092C74.81 89.9779 74.9234 89.2592 74.9234 88.3525V84.74C74.9234 83.8405 74.81 83.1284 74.5834 82.6042C74.3638 82.0729 73.9884 81.6942 73.4571 81.4675C72.933 81.2409 72.2175 81.1275 71.3109 81.1275H67.0821V91.965Z" fill="white"/>
        <path d="M58.2792 54.9666H53.7167V36.525C53.7167 35.9073 53.4713 35.3148 53.0346 34.878C52.5975 34.4412 52.0055 34.1958 51.3875 34.1958H39.7209C39.1027 34.1958 38.5099 34.4411 38.0725 34.8778C37.635 35.3145 37.3886 35.9069 37.3875 36.525V54.9666H32.8042V34.9667C32.8053 33.5496 33.369 32.1909 34.3714 31.1893C35.3738 30.1876 36.733 29.625 38.1501 29.625H52.9042C54.3209 29.625 55.6796 30.1878 56.6813 31.1895C57.683 32.1913 58.2459 33.55 58.2459 34.9667L58.2792 54.9666Z" fill="#005FAD"/>
        <path d="M52.8708 60.375C55.8579 60.375 58.2791 57.9538 58.2791 54.9667C58.2791 51.9796 55.8579 49.5583 52.8708 49.5583C49.8837 49.5583 47.4624 51.9796 47.4624 54.9667C47.4624 57.9538 49.8837 60.375 52.8708 60.375Z" fill="#005FAD"/>
        <path d="M32.0084 60.375C34.9953 60.375 37.4167 57.9538 37.4167 54.9667C37.4167 51.9796 34.9953 49.5583 32.0084 49.5583C29.0215 49.5583 26.6001 51.9796 26.6001 54.9667C26.6001 57.9538 29.0215 60.375 32.0084 60.375Z" fill="#005FAD"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default MidSvg;
