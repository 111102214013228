import { FC } from "react";
import { Cascader } from "antd";

import { FormItem } from "../styled";
import { FilledFieldNamesType } from "antd/lib/cascader";
import { ShowSearchType } from "rc-cascader";

interface CascaderFieldProps {
  label: string;
  name: string;
  placeholder: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
  rules?: Array<any>
  initialValue?: string;
  options?: Array<any>;
  optionsDisplayType?: "board";
}

const CascaderField: FC<CascaderFieldProps> = (props) => {
  const {
    label,
    name,
    placeholder,
    onChange,
    rules,
    initialValue,
    options,
    disabled,

  } = props;

  const filter = (inputValue: string, path: Array<ShowSearchType>, names: FilledFieldNamesType) => {
    // @ts-ignore
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  };

  const displayRender = (labels: string[], selectedOptions?: Array<any>) => (
    labels.map((label, i) => {
      // @ts-ignore
      const option = selectedOptions[i];
      if (i === labels.length - 1) {
        return (
          <div key={option.value} style={{ display: "flex", alignItems: "center" }}>
            <img src={`/images/${option.icon}.png`} alt="" width="16px" height="16px" style={{ marginRight: "7px" }} />
            {label}
          </div>
        );
      }
    })
  );

  return (
    <FormItem label={label} name={name} rules={rules} initialValue={initialValue}>
      <Cascader
        style={{ width: "100%" }}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        options={options}
        // @ts-ignore
        showSearch={{ filter }}
        displayRender={displayRender}
        dropdownMenuColumnStyle={{ maxWidth: 300 }}
      />
    </FormItem>
  );
};

export default CascaderField;
