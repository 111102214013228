import styled from "@emotion/styled/macro";
import { DeleteButton } from "components/blocks/ViewpointLikeCard/styled";

export const DueDateWrapper = styled.div<{ clickable?: boolean }>`
  font-size: 13px;
  color: #bd3b36;
  margin: 0 3px;
  padding: 4px 7px;
  border-radius: 4px;
  transition: background-color 200ms ease;
  &:hover {
    background-color: ${({ clickable }) => clickable ? "#f6f6f6" : "transparent"};
    cursor: ${({ clickable }) => clickable ? "pointer" : "default"};
    ${DeleteButton} {
      opacity: 1;
    }
  }
`;

export const PlaceholderWrapper = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  margin-right: 3px;
  margin-left: 3px;
  justify-content: center;
  align-items: center;
  border: dashed 1px #999;
  border-radius: 50%;
  opacity: 0.5;
  transition: background-color 200ms ease;

  &:hover {
    opacity: 1;
    background-color: #f6f6f6;
  }
`;

export const DatePickerWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const DatePickerHidden = styled.div`
  position: relative;
  width: 0;
  height: 0;
  visibility: hidden;
`;

export const PlaceholderImage = styled.img`
  margin: 0 5px;
`;
