import styled from "@emotion/styled/macro";

interface LayoutProps {
  isClosed: boolean;
}

export const App = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
`;

export const Page = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

export const Main = styled.div`
  display: flex;
  flex: 1 1;
  flex-basis: 0;
  min-height: 1px;
`;

export const Sidebar = styled.div<LayoutProps>`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  width: 250px;
  background: white;
  border-right: 1px solid #f2f2f2;
  transition: margin-left .5s;
  margin-left: ${({ isClosed }) => isClosed ? "-250px" : "0"};
`;

export const SidebarRoot = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  display: flex;
  flex-direction: column;
`;

export const SidebarHeader = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  min-height: 60px;
  justify-content: space-between;
  padding: 0 15px;
`;

export const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 920px;
  position: relative;
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #f2f2f2;
  height: 60px;
  padding: 0 15px;
`;

export const HeaderButton = styled.div<LayoutProps>`
  display: ${({ isClosed }) => isClosed ? "flex" : "none"};
  margin-right: ${({ isClosed }) => isClosed ? "10px" : "0"};
`;

export const FullPage = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 1px;
  position: relative;
`;

export const Logo = styled.img`
  height: 30px;
`;
