import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import AddViewModal from "layout/HeaderCustom/BoardHeader/AddViewModal";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import { getBoardViewUri } from "lib/helpers/navigationUtils";
import { DisplayView } from "lib/types/applicationTypes";
import { SecondaryBarContainer } from "pages/Board/SecondaryBar/styled";
import OpenSvg from "components/icons/resizable/open";
import ButtonControls from "components/ui/ButtonControls";
import { StyledButton } from "styles/common";
import {
  BoardFormFallbackContainer,
  BoardFormFallbackOverlay,
  BoardFormFallbackCard,
  BoardFormFallbackTitle, BoardFormFallbackView,
} from "pages/Board/BoardForm/BoardFormFallback/styled";
import {
  BoardFormPageContainer,
  BoardFormFieldsContainer,
  BoardFormFieldsSpan,
  BoardFormBodyContainer,
  BoardFormBodyTitle,
  BoardFormBodyDescription,
  BoardFormBodyEmptyState,
  BoardFormBody,
  BoardFormBodyHeader,
  BoardFormBodyFields, BoardFormFieldsInnerContainer,
} from "pages/Board/BoardForm/styled";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";

const BoardFormFallback: FC = () => {
  const { board } = useBoardContext() as BoardContextInterface;
  const views = board?.views.filter(view => view.view === DisplayView.Form);

  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <BoardFormFallbackContainer>
      <BoardFormFallbackOverlay>
        <BoardFormFallbackCard>
          {!!views?.length &&
            <>
              <BoardFormFallbackTitle>
                Select a form
                {allowedToEdit && " or create a new one"}
              </BoardFormFallbackTitle>
              <div style={{ marginBottom: 20 }}>
                {views?.map(view => (
                  <Link to={getBoardViewUri(board.uid, view.uid)} key={view.id}>
                    <BoardFormFallbackView>
                      {view.title}
                    </BoardFormFallbackView>
                  </Link>
                ))}
              </div>
            </>
          }
          {!views?.length &&
            <BoardFormFallbackTitle>
              You don't have any forms yet
            </BoardFormFallbackTitle>
          }
          {allowedToEdit &&
            <>
              <StyledButton onClick={() => setIsModalVisible(true)} key="ok" purple>Create a form</StyledButton>
              {isModalVisible &&
                <AddViewModal visible={isModalVisible} onClose={() => setIsModalVisible(false)} initialView={DisplayView.Form} />
              }
            </>
          }
        </BoardFormFallbackCard>
      </BoardFormFallbackOverlay>
      <SecondaryBarContainer>
        <ButtonControls
          icon={<OpenSvg size={12}/>}
          text="Share form"
        />
      </SecondaryBarContainer>
      <BoardFormPageContainer>
        <BoardFormFieldsContainer>
          <BoardFormFieldsInnerContainer>
            <BoardFormFieldsSpan>Fields</BoardFormFieldsSpan>
            <span style={{ color: "#bfbfbf" }}>All fields are already in use</span>
          </BoardFormFieldsInnerContainer>
        </BoardFormFieldsContainer>
        <BoardFormBodyContainer>
          <BoardFormBody>
            <BoardFormBodyHeader>
              <BoardFormBodyTitle
                placeholder="Form name"
                size="large"
                autoSize={{ minRows: 1, maxRows: 4 }}
              />
              <BoardFormBodyDescription
                placeholder="Add a description for this form"
                autoSize={{ minRows: 1, maxRows: 4 }}
              />
            </BoardFormBodyHeader>
            <BoardFormBodyFields>
              <BoardFormBodyEmptyState>
                Add fields to this form
              </BoardFormBodyEmptyState>
            </BoardFormBodyFields>
          </BoardFormBody>
        </BoardFormBodyContainer>
      </BoardFormPageContainer>
    </BoardFormFallbackContainer>
  );
};

export default BoardFormFallback;
