import { useAppSelector } from "app/store";
import ConditionalWrapper from "components/blocks/ConditionalWrapper";
import PageLoader from "components/blocks/PageLoader";
import TaskDrawerInRoute from "components/blocks/TaskDrawerInRoute";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import { TasksProvider } from "lib/contexts/TasksContext";
import useCustomColumnsFilters from "lib/customHooks/filters/useCustomColumnsFilters";
import useGetTaskList from "lib/customHooks/useGetTaskList";
import useBoardViewSortAndFilters from "lib/customHooks/views/useBoardViewSortAndFilters";
import useWSCreateTask from "lib/customHooks/websocketTask/useWSCreateTask";
import useWSDeleteTask from "lib/customHooks/websocketTask/useWSDeleteTask";
import useWSUpdateTask from "lib/customHooks/websocketTask/useWSUpdateTask";
import { listFilters } from "lib/filters/filters";
import { filterTasks } from "lib/filters/generalFilter";
import { getBoardViewUri } from "lib/helpers/navigationUtils";
import { sortTasks } from "lib/sort/tasksSort";
import { DisplayView } from "lib/types/applicationTypes";
import { cloneDeep, sortBy } from "lodash";
import { FC } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";

interface BoardTasksRendererProps {
  renderBoardFn: () => JSX.Element;
  displayView: DisplayView;
  loading?: boolean;
  useTasksProvider?: boolean;
}

const BoardTasksRenderer: FC<BoardTasksRendererProps> = (props) => {
  const { displayView, loading, renderBoardFn, useTasksProvider } = props;
  const history = useHistory();
  const { view } = useParams<{ id: string, view: string }>();
  const { board } = useBoardContext() as BoardContextInterface;
  const { currentGeneralFilter, currentSort } = useBoardViewSortAndFilters();
  const { filterFn } = useCustomColumnsFilters(listFilters);

  useGetTaskList(board?.id);
  useWSCreateTask(board?.id);
  useWSUpdateTask(false);
  useWSDeleteTask();

  let tasks = useAppSelector(state => state.tasks.tasks);
  tasks = sortBy(tasks, ["sort__list"]);
  tasks = sortTasks(tasks, currentSort);
  const sortedUnfilteredTasks = cloneDeep(tasks);
  tasks = filterFn(tasks);
  tasks = filterTasks(tasks, currentGeneralFilter);

  const wrapperFn = (children: JSX.Element) => (
    <TasksProvider
      tasks={tasks}
      source={displayView}
      sortedUnfilteredTasks={sortedUnfilteredTasks}
    >
      {children}
    </TasksProvider>
  );

  return (
    <ConditionalWrapper
      condition={!!useTasksProvider}
      wrapper={wrapperFn}
    >
      <>
        {loading
          ? <PageLoader />
          : renderBoardFn()
        }
        <Switch>
          <Route exact path="/board/:id/:view/:taskId">
            <TaskDrawerInRoute
              displayView={displayView}
              onClose={() => history.push(getBoardViewUri(board.uid, view))}
              drawerWidth={570}
            />
          </Route>
        </Switch>
      </>
    </ConditionalWrapper>
  );
};

export default BoardTasksRenderer;
