import { FC } from "react";
import IconSvgWrapper from "./IconSvgWrapper";
import { IconProps } from "./lib";

const ModelSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.2436 0.0517494L11.6436 2.45172C11.8602 2.54832 12 2.76312 12 3.00011V9.00004C12 9.23704 11.8602 9.45183 11.6436 9.54843L6.2436 11.9484C6.1662 11.9826 6.0828 12 6 12C5.9172 12 5.8338 11.9826 5.7564 11.9484L0.3564 9.54843C0.1398 9.45183 0 9.23704 0 9.00004V3.00011C0 2.76312 0.1398 2.54832 0.3558 2.45172L5.7558 0.0517494C5.9112 -0.0172498 6.0882 -0.0172498 6.2436 0.0517494ZM9.04688 2.98144L6.03516 1.6455L3.03516 2.98144L6.03516 4.31737L9.04688 2.98144ZM5.0625 6.01659L1.60547 4.46972L1.59375 8.34862L5.0625 9.8955V6.01659ZM6.9375 6.04003L10.3945 4.49316L10.4062 8.37206L6.9375 9.91894V6.04003Z" />
    </IconSvgWrapper>
  );
};

export default ModelSvg;
