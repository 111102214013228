export const ErrorMessages: Record<string, string> = {
  "board.workspace.policy.notCollaborator": "Cannot complete operation: insufficient permissions",
  "board.workspace.notSpecified": "Cannot complete operation: bad request",
  "board.policy.notOwner": "Cannot complete operation: insufficient permissions",
  "board.notFound": "Board does not exist",
  "board.export.noTasks": "There are no tasks to export",
  "task.workspace.policy.notCollaborator": "Cannot complete operation: insufficient permissions",
  "task.boardIsNull": "Cannot complete operation: bad request",
  "task.notFound": "Task does not exist",
  "task.order.notArray": "Cannot complete operation: bad request",
  "task.board.notSpecified": "Cannot complete operation: bad request",
  "task.columnId.notSpecified": "Cannot complete operation: bad request",
  "task.viewpoints.picture.requestNotMultipart": "Cannot complete operation: bad request",
  "task.viewpoints.picture.invalidFormat": "Cannot complete operation: invalid file format",
  "workspace.policy.notCollaborator": "Cannot complete operation: insufficient permissions",
  "workspace.policy.notOwner": "Cannot complete operation: insufficient permissions",
  "workspace.model.noFileProvided": "Cannot complete operation: no data was provided",
  "workspace.model.badRequest": "Cannot complete operation: bad request",
  "workspace.model.delete.noParams": "Cannot complete operation: bad request",
  "workspace.model.picture.requestNotMultipart": "Cannot complete operation: bad request",
  "workspace.model.picture.invalidFormat": "Cannot complete operation: invalid file format",
  "workspace.collaborators.removeCollaborator.noParams": "",
  "workspace.collaborators.addCollaborators.noParams": "",
  "user.invalidToken": "Cannot complete operation: bad request",
  "user.code.notProvided": "Cannot complete operation: bad request",
  "user.picture.invalidFormat": "Cannot complete operation: invalid file format",
  "auth.provider.disabled": "Authentication provider is disabled",
  "auth.form.error.email.provide": "Please provide your username or your e-mail",
  "auth.form.error.password.provide": "Please provide your password",
  "auth.form.error.invalid": "Identifier or password invalid",
  "auth.form.error.notConfirmed": "Your account email is not confirmed",
  "auth.form.error.alreadyConfirmed": "Your account has already been confirmed",
  "auth.form.error.blocked": "Your account has been blocked by an administrator",
  "auth.form.error.password.local": "This user never set a local password, please login with the provider used during account creation",
  "auth.form.error.code.provide": "Incorrect code provided or the link has expired",
  "auth.form.error.password.matching": "Passwords do not match",
  "auth.form.error.params.provide": "Incorrect params provided",
  "auth.form.error.email.format": "Please provide valid email address",
  "auth.form.error.user.notExist": "This email does not exist",
  "auth.advanced.allowRegister": "Register action is currently disabled",
  "auth.form.error.password.format": "Your password cannot contain more than three times the symbol `$`",
  "auth.form.error.role.notFound": "Impossible to find the default role",
  "auth.form.error.email.taken": "Email already in use",
  "auth.form.error.username.taken": "Username already taken",
};
