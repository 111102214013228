import { FC, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Empty, Form } from "antd";

import FormPublicField from "pages/FormPublic/FormPublicField";
import PublicService from "services/PublicService";
import useAsyncData from "lib/customHooks/useAsyncData";
import { captureException } from "lib/utils/sentry";
import { FormResponseInterface } from "lib/types/form";
import { TaskFormProvider } from "lib/contexts/TaskFormContext";
import LoadingPanel from "components/blocks/LoadingPanel";
import NotFoundSection from "components/blocks/NotFoundSection";
import CustomStyledButton from "components/blocks/CustomStyledButton";
import LoadingMessage from "components/blocks/LoadingMessage";
import { StyledButton } from "styles/common";
import {
  FormPublicContainer,
  FormPublicHeaderContainer,
  FormPublicBodyContainer,
  FormPublicFooterContainer,
  FormPublicContent,
  FormPublicTitle,
  FormPublicLogo,
  FormPublicLogoContainer, FormPublicDisclaimer, FormPublicConfirmation, FormPublicConfirmationText
} from "pages/FormPublic/styled";
import configuration from "lib/configs/configuration";
import { BoardInterface } from "lib/types/boardTypes";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

const FormPublic: FC = () => {
  const { id } = useParams<{ id: string }>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [form] = Form.useForm();

  const getFormData = useCallback(() => {
    return PublicService.formFetch(id);
  }, [id]);

  const { data, isLoading, isError } = useAsyncData<FormResponseInterface>({
    loadOnMount: true,
    fetchFn: getFormData,
    defaultDataValue: null,
    showErrorMessage: false,
  });

  const { excludedColumns } = useBoardFeatures(data?.board as BoardInterface);

  const fields = (data?.columns ?? [])
    .sort((a, b) => a.sort - b.sort)
    .filter(column => !excludedColumns.includes(column.code));

  const isEmpty = !fields?.length;

  const handleOnSubmitForm = async (values: any) => {
    const payload = values;
    payload.board = data.board.id;

    setIsSubmitting(true);

    try {
      await PublicService.formSubmit(id, payload);
      setIsSubmitted(true);
    } catch (error) {
      captureException(error, true);
    }

    setIsSubmitting(false);
  };

  const handleOnFormReset = () => {
    window.location.reload();
  };

  const handleOnValidationError = () => {
    LoadingMessage.error("Looks like you missed some required fields");
  };

  return (
    <FormPublicContainer>
      {isLoading &&
        <LoadingPanel />
      }
      {!isLoading && data &&
        <>
          {!isSubmitted &&
            <TaskFormProvider form={data}>
              <div>
                <FormPublicHeaderContainer>
                  <FormPublicContent>
                    <FormPublicTitle>
                      {data.view.title}
                    </FormPublicTitle>
                    <div>
                      {data.view.description}
                    </div>
                  </FormPublicContent>
                </FormPublicHeaderContainer>
                <FormPublicBodyContainer>
                  <FormPublicContent>
                    {isEmpty &&
                      <>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="There's no fields added to this form." />
                        <CustomStyledButton
                          btnColor="purple"
                          value="Submit"
                          disabled
                        />
                      </>
                    }
                    {!isEmpty &&
                      <>
                        <Form.Provider >
                          <Form
                            form={form}
                            onFinish={handleOnSubmitForm}
                            onFinishFailed={handleOnValidationError}
                          >
                            {fields?.map(column => (
                              <FormPublicField
                                key={column.id}
                                column={column}
                              />
                            ))}
                            <CustomStyledButton
                              btnColor="purple"
                              value="Submit"
                              htmlType="submit"
                              isLoading={isSubmitting}
                              disabled={isSubmitting}
                            />
                          </Form>
                        </Form.Provider>
                        <FormPublicDisclaimer>
                          Never submit passwords through {configuration.app.title} forms.
                        </FormPublicDisclaimer>
                      </>
                    }
                  </FormPublicContent>
                </FormPublicBodyContainer>
              </div>
            </TaskFormProvider>
          }
          {isSubmitted &&
            <FormPublicConfirmation>
              <FormPublicConfirmationText>Thank you for submitting the form!</FormPublicConfirmationText>
              <StyledButton onClick={handleOnFormReset} key="ok" purple>Submit another response</StyledButton>
            </FormPublicConfirmation>
          }
        </>
      }
      {((!isLoading && !data) || isError) &&
        <FormPublicConfirmation>
          <NotFoundSection
            text="This form doesn't exist or is no longer available."
          />
        </FormPublicConfirmation>
      }
      <FormPublicFooterContainer>
        <FormPublicContent>
          <FormPublicLogoContainer>
            <FormPublicLogo src={process.env.PUBLIC_URL + "/logo.svg"} alt="logo" />
          </FormPublicLogoContainer>
        </FormPublicContent>
      </FormPublicFooterContainer>
    </FormPublicContainer>
  );
};

export default FormPublic;
