import { EntityId } from "lib/types/entity";
import { TFilterFunctions } from "./filterFunctions";
import { FilterOperatorEnum } from "./filterOperators";
import { assigneeFilter, dateFilter, dueDateFilter, statusFilter, textFilter } from "./filterPresets";
import { boardFilter, noneFilter, projectFilter, viewpointsFilter } from "./filterPresets/filterPresets";
import { FilterType } from "./filterTypes";

export const FilterValueNone = "None";

export const noneFilterValue = {
  text: FilterValueNone,
  valueKey: FilterValueNone,
};

export type FilterValueType = {
  text: string;
  valueKey: string | boolean;
  color?: string | null;
  id?: EntityId;
};

export const listFilters = {
  None: noneFilter,
  Assignees: assigneeFilter,
  "Due date": dueDateFilter,
  "Created time": dateFilter,
  "Last modified": dateFilter,
  "Closing date": dateFilter,
  Status: statusFilter,
  Title: textFilter,
  "Created by": assigneeFilter,
  "Last modified by": assigneeFilter,
  uID: textFilter,
};

export type ListFiltersKeys = keyof typeof listFilters;

export const kanbanFilters = {
  None: noneFilter,
  Assignees: assigneeFilter,
  Title: textFilter,
  "Due date": dueDateFilter,
  "Created time": dateFilter,
  "Last modified": dateFilter,
  "Closing date": dateFilter,
  "Created by": assigneeFilter,
  "Last modified by": assigneeFilter,
  uID: textFilter,
};

export type KanbanFiltersKeys = keyof typeof kanbanFilters;

export const myTasksFilters = {
  None: noneFilter,
  Title: { ...textFilter, sort: 1 },
  "Due date": { ...dueDateFilter, sort: 2 },
  Project: { ...projectFilter, sort: 3 },
  Board: { ...boardFilter, sort: 4 },
};

export type MyTasksFilters = keyof typeof myTasksFilters;

export const viewerFilters = {
  None: noneFilter,
  Assignees: assigneeFilter,
  "Due date": dueDateFilter,
  Viewpoints: viewpointsFilter,
  Title: textFilter
};

export const viewerBoardFilter = {
  None: noneFilter,
  Assignees: assigneeFilter,
  "Due date": dueDateFilter,
  Status: statusFilter,
  Viewpoints: viewpointsFilter,
  Title: textFilter,
  "Created time": dateFilter,
  "Last modified": dateFilter,
  "Closing date": dateFilter,
  "Created by": assigneeFilter,
  "Last modified by": assigneeFilter,
  uID: textFilter,
};

export type ViewerBoardFilters = keyof typeof viewerBoardFilter;

export const viewerBoardsAdditionalFilters = {
  Board: boardFilter,
};

export type ViewerFilters = keyof typeof viewerFilters;

export const reportingFilters = {
  None: noneFilter,
  Assignees: assigneeFilter,
  Status: statusFilter,
  Board: boardFilter,
};

export type ReportingFilters = keyof typeof reportingFilters;

export const projectFilters = {
  None: noneFilter,
  Title: { ...textFilter, sort: 1 },
  Assignees: { ...assigneeFilter, sort: 2 },
  "Due date": { ...dueDateFilter, sort: 3 },
  "Created time": dateFilter,
  "Last modified": dateFilter,
  "Closing date": dateFilter,
  "Created by": assigneeFilter,
  "Last modified by": assigneeFilter,
  uID: textFilter,
};
export type TProjectFilters = keyof typeof projectFilters;

export type MultipleFilter = {
  id: string
  titleKey: string
  value: {
    text: any
    valueKey: string | number | boolean | string[]
  }
  function: TFilterFunctions
  operator: FilterOperatorEnum
  version: FilterVersion
  multiple?: MultipleFilter[]
}

export type TAnyFilters = {
  titleKey: string,
  value: {
    text: any,
    valueKey: string | number | [] | boolean | string[],
    id?: EntityId,
  },
  types?: {
    [key: string]: FilterType
  },
  function?: TFilterFunctions,
  version?: FilterVersion
  multiple?: MultipleFilter[]
  operator?: FilterOperatorEnum
};

export enum FilterVersion {
  v1 = "v1", // old filters no functions
  v2 = "v2", // filters with functions
  v3 = "v3", // functions + multiple filters
}
