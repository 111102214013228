import styled from "@emotion/styled/macro";
import { squareWrapperCss } from "styles/common-css";
import { dataInput } from "components/blocks/TaskTable/styled";
import { fieldDetailedInput } from "../../styled";

export const PercentPostfix = styled.div`
  ${squareWrapperCss};
  margin-left: 5px;
  opacity: 0;
  background-color: white;
  border: solid 1px #e4e4e4;
  cursor: auto;
  &:hover {
    background-color: white;
  }
`;

export const PercentListWrapper = styled.div`
  ${dataInput};
  &:hover {
    ${PercentPostfix} {
      opacity: 1;
    }
  }
`;

export const PercentDetailedWrapper = styled.div`
  ${fieldDetailedInput};
  display: flex;
  align-items: center;
  &:hover {
    ${PercentPostfix} {
      opacity: 1;
    }
  }
`;
