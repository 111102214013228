import { FC, useEffect, useState } from "react";
import TasksService from "services/TasksService";
import CheckIcon from "./CheckIcon";
import {
  CheckmarkButton,
  CheckmarkControlsWrapper,
  completedButtonClass
} from "./styled";
import ButtonControls from "components/ui/ButtonControls";
import TasksSvg from "components/ui/ButtonControls/icons/tasks";
import { EntityId } from "lib/types/entity";
import { TaskSetValueHandler } from "lib/types/tasksTypes";

interface TaskCheckmarkProps {
  id?: EntityId;
  isCompleted: boolean;
  clickable?: boolean;
  button?: boolean;
  setValue?: TaskSetValueHandler;
}

const TaskCheckmark: FC<TaskCheckmarkProps> = (props) => {
  const { isCompleted, clickable, button, id, setValue } = props;

  const [completed, setCompleted] = useState<boolean>(isCompleted);
  useEffect(() => {
    setCompleted(isCompleted);
  }, [isCompleted]);

  const handleCheckmarkButtonClick = () => {
    if (!clickable || !id) {
      return;
    }

    setCompleted(!completed);

    if (setValue) {
      setValue({ cellName: "completed", cellValue: !completed });
    } else {
      TasksService.modifyTask(id, { completed: !completed });
    }
  };

  const cursor = clickable ? "pointer" : "default";

  return (
    <>
      {!button
        ? (
          <CheckmarkButton onClick={handleCheckmarkButtonClick} disabled={!clickable}>
            <CheckIcon isCompleted={completed} />
          </CheckmarkButton>
        )
        : (
          <CheckmarkControlsWrapper onClick={handleCheckmarkButtonClick}>
            <ButtonControls
              icon={<TasksSvg />}
              text={completed ? "Completed" : "Mark complete"}
              className={completed ? completedButtonClass : ""}
              buttonStyle={{ cursor: cursor }}
            />
          </CheckmarkControlsWrapper>
        )
      }
    </>
  );
};

export default TaskCheckmark;
