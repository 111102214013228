import styled from "@emotion/styled/macro";

export const UserRightsSpaceBetween = styled.div`
  margin-left: -8px; // to align dropdown with the heading
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UserRightsRemoveButton = styled.button`
  display: flex;
  align-items: center;
  height: 100%;
  color: #999;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 200ms ease;
  opacity: 0.5;
  margin-left: 5px;
  &:hover {
    color: #333;
    opacity: 1;
  }
`;

export const UserRightsPending = styled.div`
  color: #999999;
`;

export const UserRightsResend = styled.div`
  display: none;
`;

export const UserRightsWrapper = styled.div<{ allowedToEdit?: boolean}>`
  margin-bottom: 7px;
  &:hover {
    ${UserRightsPending} {
      display: ${({ allowedToEdit }) => allowedToEdit ? "none" : "inline"};
    }
    ${UserRightsResend} {
      display: block;
    }
  }
`;
