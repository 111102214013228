import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const TrashSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path fillRule="evenodd" clipRule="evenodd" d="M22 16C22 7.16344 29.1634 0 38 0H62C70.8366 0 78 7.16344 78 16V22.5H85.5C89.6421 22.5 93 25.8579 93 30C93 34.1421 89.6421 37.5 85.5 37.5H86V80C86 91.0457 77.0457 100 66 100H34C22.9543 100 14 91.0457 14 80V37.5H14.5C10.3579 37.5 7 34.1421 7 30C7 25.8579 10.3579 22.5 14.5 22.5H22V16ZM37 22.5H63V15H37V22.5ZM29 37.5H71V77C71 81.4183 67.4183 85 63 85H37C32.5817 85 29 81.4183 29 77V37.5Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default TrashSvg;
