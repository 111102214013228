import { FC, useEffect, useState } from "react";
import { Image, Tooltip } from "antd";
import { useTaskContext } from "lib/contexts/TaskContext";
import useWorkspaceById from "lib/customHooks/useWorkspaceById";
import { getTaskDetailModelUri } from "lib/helpers/navigationUtils";
import { TaskFieldSetterGetter } from "lib/types/applicationTypes";
import { AddViewpoint } from "pages/Viewer/ViewerTasks/TaskItem/styled";
import ViewpointCardMenu from "components/blocks/ViewpointCard/ViewpointCardMenu";
import { ViewpointType } from "lib/types/tasksTypes";
import {
  ViewpointLikeCardContentContainer,
  ViewpointLikeCardImage,
  ViewpointLikeCardOuterContainer
} from "components/blocks/ViewpointLikeCard/styled";
import GeometrySvg from "components/icons/resizable/geomerty";
import { ViewpointContextMenuItems } from "components/blocks/ViewpointCard/ViewpointCardMenu/ViewpointCardMenuItems/lib";

const cardSize = 58;

const ViewpointsDetailed: FC<TaskFieldSetterGetter> = (props) => {
  const { value } = props;

  const { task } = useTaskContext();
  const workspace = useWorkspaceById(task?.board.workspace || "");

  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  const handleOnNavigateViewer = () => {
    if (task && workspace) {
      const path = getTaskDetailModelUri(workspace?.uid, task?.board?.uid, task?.uid);
      window.open(path);
    }
  };

  return (
    <>
      {!!displayValue.length &&
        <Image.PreviewGroup>
          {displayValue?.map((viewpoint: ViewpointType, index: number) => (
            <ViewpointLikeCardOuterContainer
              cardSize={cardSize}
              key={index}
              nonClickable={!(viewpoint.picture?.url)}
              geometry
            >
              {viewpoint.picture?.url && (
                <ViewpointLikeCardImage
                  cardSize={cardSize}
                  preview={{ src: viewpoint.picture?.url }}
                  src={viewpoint.picture?.formats.thumbnail.url || viewpoint.picture?.url}
                />
              )}
              {!viewpoint.picture?.url && (
                <ViewpointLikeCardContentContainer>
                  <GeometrySvg size={14} />
                </ViewpointLikeCardContentContainer>
              )}
              <ViewpointCardMenu
                viewpoint={viewpoint}
                menuItems={[
                  ViewpointContextMenuItems.OpenInViewer,
                  ViewpointContextMenuItems.CopyLink,
                  ViewpointContextMenuItems.DownloadXml,
                  ViewpointContextMenuItems.Delete,
                ]}
              />
            </ViewpointLikeCardOuterContainer>
          ))}
        </Image.PreviewGroup>
      }
      {task &&
        <div onClick={handleOnNavigateViewer}>
          <Tooltip title="Open in viewer">
            <AddViewpoint cardSize={cardSize}>
              <img src={process.env.PUBLIC_URL + "/icons/model.svg"} alt="" />
            </AddViewpoint>
          </Tooltip>
        </div>
      }
    </>
  );
};

export default ViewpointsDetailed;
