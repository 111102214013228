import { uniqBy } from "lodash";
import { BoardInterface, BoardInterfaceExtended, BoardStatusInterface } from "../../types/boardTypes";
import { TaskInterface } from "../../types/tasksTypes";
import { WorkspaceInterface } from "../../types/types";
import { FilterFunction } from "../filterFunctions";
import { FilterValueNone, FilterVersion, TAnyFilters } from "../filters";

const filterV1 = (tasks: TaskInterface[], currentFilter: TAnyFilters, statusId: any) => {
  return tasks.filter(task => {
    return String(task.status) === statusId;
  });
};

const filterV2 = (tasks: TaskInterface[], currentFilter: TAnyFilters, statusId: any) => {
  switch (currentFilter.function) {
    case FilterFunction.Is: {
      return tasks.filter(task => {
        return String(task.status) === statusId;
      });
    }

    case FilterFunction["Is not"]: {
      return tasks.filter(task => {
        return String(task.status) !== statusId;
      });
    }
  }

  return tasks;
};

export const statusFilter = {
  filterValues: (item: BoardInterfaceExtended | BoardInterface | WorkspaceInterface | null) => {
    if (item === null) {
      return [];
    }

    if ("statuses" in item) {
      // board
      return item?.statuses.map(status => {
        return {
          text: status.title,
          valueKey: String(status.id),
          color: status.color,
        };
      }) || [];
    }

    if ("boards" in item) {
      // workspace
      const statuses = item.boards.reduce<BoardStatusInterface[]>((acc, item) => {
        const all = [...acc, ...item.statuses];
        return uniqBy(all, "id");
      }, []);

      return statuses.map(status => ({
        text: status.title,
        valueKey: String(status.id),
        color: status.color,
      }));
    }

    return [];
  },
  filterFn: (tasks: TaskInterface[], currentFilter: TAnyFilters) => {
    const statusId = currentFilter.value.valueKey;

    if (currentFilter.version && currentFilter.version !== FilterVersion.v1) {
      return filterV2(tasks, currentFilter, statusId);
    }

    if (!statusId || statusId === FilterValueNone) {
      return tasks;
    }

    return filterV1(tasks, currentFilter, statusId);
  },
};
