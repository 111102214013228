import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const TextMediumSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.6001 30.3999C22.6001 25.9816 26.1818 22.3999 30.6001 22.3999V39.3999C30.6001 41.609 28.8092 43.3999 26.6001 43.3999C24.391 43.3999 22.6001 41.609 22.6001 39.3999V30.3999Z"/>
        <path d="M77.6001 30.3999C77.6001 25.9816 74.0184 22.3999 69.6001 22.3999V39.3999C69.6001 41.609 71.391 43.3999 73.6001 43.3999C75.8092 43.3999 77.6001 41.609 77.6001 39.3999V30.3999Z"/>
        <path d="M30.6001 22.3999H69.6001V30.3999H30.6001V22.3999Z"/>
        <path d="M46.0001 30.3999H54.0001V69.3999H46.0001V30.3999Z"/>
        <path d="M33.4001 73.3999C33.4001 71.1908 35.191 69.3999 37.4001 69.3999H62.6001C64.8092 69.3999 66.6001 71.1908 66.6001 73.3999C66.6001 75.609 64.8092 77.3999 62.6001 77.3999H37.4001C35.191 77.3999 33.4001 75.609 33.4001 73.3999Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default TextMediumSvg;
