import { useState, useEffect, FC } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FormInstance } from "antd";

import { SectionSubheading, TagButton } from "styles/common";

import SelectOption from "./SelectOption";
import { OptionsWrapper } from "./styled";

interface SelectSettingsProps {
  form?: FormInstance;
}

// eslint-disable-next-line
Array.prototype.move = function (from: number, to: number) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};

const selectName = "options";

const SelectSettings: FC<SelectSettingsProps> = (props) => {
  const { form } = props;
  const [fields, setFields] = useState<Array<number>>([]);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { options } = form?.getFieldsValue(true);

    if (options) {
      const initialFields = options.map((option: any, key: number) => key);
      setFields(initialFields);
    }
  }, []);

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const newArr = [...fields].move(source.index, destination.index);
    setFields(newArr);
  };

  const add = () => {
    setFields([...fields, fields.length]);
    setIsButtonVisible(false);
  };

  const onOptionAdd = () => {
    setIsButtonVisible(true);
  };

  const onOptionRemove = (index: number) => {
    const newFields = [...fields];

    delete newFields[index];
    setFields(newFields);
    setIsButtonVisible(true);
  };

  return (
    <>
      <SectionSubheading>Options</SectionSubheading>
      <OptionsWrapper>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={`${selectName}_list`}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <>
                  {fields.map((fieldIndex, index) => (!!fieldIndex || fieldIndex === 0) && (
                    <Draggable key={fieldIndex} draggableId={String(fieldIndex)} index={index}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <SelectOption
                            selectName={selectName}
                            form={form}
                            fieldIndex={fieldIndex}
                            index={index}
                            removeOption={onOptionRemove}
                            dragHandleProps={provided.dragHandleProps}
                            onAdd={onOptionAdd}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  {isButtonVisible &&
                    <TagButton
                      onClick={add}
                      style={{ margin: "7px 0 0 0" }}
                      type="button"
                    >
                      Add option
                    </TagButton>
                  }
                </>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </OptionsWrapper>
    </>
  );
};

export default SelectSettings;
