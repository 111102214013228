import { FC, useEffect } from "react";
import { useBoardContext } from "lib/contexts/BoardContext";
import { useBoardViewContext } from "lib/contexts/BoardViewContext";
import { FilterValueType } from "lib/filters/filters";
import { SortFieldType } from "lib/types/sortAndFilterTypes";
import { TaskInterface } from "lib/types/tasksTypes";

import FieldsDrawer from "./FieldsDrawer";
import Filter from "./Filter";
import GeneralFilter from "./GeneralFilter";
import Sort from "./Sort";
import { SecondaryBarContainer, ButtonWrapper } from "./styled";
import SyncBoardButton from "./SyncBoardButton";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";
import { useAppDispatch } from "app/store";
import { setGeneralFilter } from "app/slices/tasksFilterAndSortSlice";
import { generalFilters } from "lib/filters/generalFilter";
import Customize from "pages/Board/SecondaryBar/Customize";
import AddRecordBlock from "components/blocks/AddRecordBlock";
import { CreateFlowFormSource } from "components/blocks/TaskModals/CreateFlowForm/lib";

interface SecondaryBarProps {
  filters: {
    [key: string]: {
      filterValues: (...args: any[]) => FilterValueType[]
      filterFn: (...args: any[]) => TaskInterface[]
    }
  };
  sortFields: SortFieldType[];
  showHideFields?: boolean;
  hideAddRecord?: boolean;
  hideAutomations?: boolean;
  hideJustMyTasks?: boolean;
  type: CreateFlowFormSource;
}

const SecondaryBar: FC<SecondaryBarProps> = (props) => {
  const { showHideFields = true, hideAutomations, hideAddRecord = false, type } = props;

  const dispatch = useAppDispatch();
  const { board } = useBoardContext();
  const { isCustomView } = useBoardViewContext();
  const { isCompletionEnabled, excludedColumns } = useBoardFeatures(board);

  useEffect(() => {
    const preset = isCompletionEnabled ? generalFilters.IncompleteTasks : generalFilters.AllTasks;
    dispatch(setGeneralFilter(preset));
  }, [dispatch, isCompletionEnabled]);

  const sortFields = (excludedColumns.length > 0)
    ? props.sortFields.filter(field => !excludedColumns.includes(field.code))
    : props.sortFields;

  return (
    <SecondaryBarContainer>
      <div>
        {!board?.externalDataSource
          ? !hideAddRecord && <AddRecordBlock type={type} />
          : <SyncBoardButton boardId={String(board.id)} externalDataSource={board?.externalDataSource} />
        }
      </div>
      <div />
      <ButtonWrapper>
        {isCompletionEnabled &&
          <GeneralFilter
            isCustomView={isCustomView}
          />
        }
        <Filter
          filters={props.filters}
          hideJustMyTasks={props.hideJustMyTasks}
          isCustomView={isCustomView}
        />
        <Sort
          fields={sortFields}
          isCustomView={isCustomView}
        />
        {showHideFields && <FieldsDrawer />}
        {!hideAutomations && <Customize />}
      </ButtonWrapper>
    </SecondaryBarContainer>
  );
};

export default SecondaryBar;
