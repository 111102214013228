import { FC, useState } from "react";
import { Checkbox, Form, Input, Modal } from "antd";

import { useTaskContext } from "lib/contexts/TaskContext";
import { DefaultColumnCodes } from "lib/types/boardTypes";
import { ModalComponentProps } from "lib/types/components";
import { EntityId } from "lib/types/entity";
import TasksService from "services/TasksService";
import { SectionSubheading, StyledButton } from "styles/common";
import CustomStyledButton from "../CustomStyledButton";
import { getTaskDetailUri } from "lib/helpers/navigationUtils";
import { DuplicateTaskModalContainer } from "components/blocks/DuplicateTaskModal/styled";
import { useHistory } from "react-router-dom";
import { captureException } from "lib/utils/sentry";
import useSavingState from "lib/customHooks/useSavingState";
import { commonModalProps } from "../ModalDialog/lib";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

interface DuplicateTaskModalProps extends ModalComponentProps {
  taskId: EntityId;
}

const DuplicateTaskModal: FC<DuplicateTaskModalProps> = (props) => {
  const { taskId } = props;
  const { task } = useTaskContext();
  const history = useHistory();
  const { isAssigneesEnabled, isDueDatesEnabled, isModelsEnabled, isStatusEnabled, terminology } = useBoardFeatures(task?.board);

  const { setSavingStart, setSavingFinish } = useSavingState();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const options = [
    { text: "Description", code: DefaultColumnCodes.DescriptionRTE },
    { text: "Checklists", code: "subtasks" },
    ...(isStatusEnabled ? [{ text: "Status", code: DefaultColumnCodes.Status }] : []),
    ...(isAssigneesEnabled ? [{ text: "Assignees", code: DefaultColumnCodes.Assignees }] : []),
    ...(isDueDatesEnabled ? [{ text: "Due date", code: DefaultColumnCodes.DueDate }] : []),
    ...(isModelsEnabled ? [{ text: "Viewpoints", code: DefaultColumnCodes.Viewpoints }] : []),
    { text: "Custom fields", code: "customProperties" },
  ];

  const initialValues = {
    title: `Duplicate of ${task?.title}`,
    [DefaultColumnCodes.DescriptionRTE]: true,
    "subtasks": true,
    [DefaultColumnCodes.Status]: isStatusEnabled,
    [DefaultColumnCodes.Assignees]: isAssigneesEnabled,
    [DefaultColumnCodes.DueDate]: isDueDatesEnabled,
    [DefaultColumnCodes.Viewpoints]: isModelsEnabled,
    "customProperties": true,
  };

  const handleOnSubmit = async () => {
    setIsLoading(true);
    setSavingStart();
    try {
      const payload = form.getFieldsValue();
      const result = await TasksService.duplicateTask(taskId, payload);
      const targetUid = result.data.data.uid;

      history.push(getTaskDetailUri(targetUid));
    } catch (error) {
      captureException(error, true);
    }
    setSavingFinish();
    setIsLoading(false);
    props.onClose();
  };

  return (
    <Modal
      {...commonModalProps}
      destroyOnClose
      title={`Duplicate ${terminology.single.toLowerCase()}`}
      visible={props.visible}
      onOk={props.onClose}
      onCancel={props.onClose}
      width={325}
      footer={[
        <div key="cancel" style={{ display: "inline-block", marginRight: "10px" }}>
          <StyledButton onClick={props.onClose}>
            Cancel
          </StyledButton>
        </div>,
        <CustomStyledButton
          key="ok"
          value="Duplicate"
          btnColor="purple"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={handleOnSubmit}
        />
      ]}
    >
      <Form
        form={form}
        initialValues={initialValues}
      >
        <SectionSubheading style={{ marginTop: 0 }}>Title</SectionSubheading>
        <Form.Item name="title" rules={[{ required: true, whitespace: true }]}>
          <Input style={{ marginBottom: 10 }} />
        </Form.Item>

        {options.map(option => (
          <DuplicateTaskModalContainer key={option.code}>
            <Form.Item valuePropName="checked" name={option.code}>
              <Checkbox style={{ marginRight: 7 }} />
            </Form.Item>
            <label htmlFor={option.code} style={{ fontSize: 13, color: "#9e9e9e" }}>
              {option.text}
            </label>
          </DuplicateTaskModalContainer>
        ))}
      </Form>
    </Modal>
  );
};

export default DuplicateTaskModal;
