import { FC } from "react";
import { useHistory } from "react-router-dom";
import { useInstantSearchContext } from "react-instantsearch";
import { SearchClient } from "algoliasearch";
import { AutocompleteComponents, AutocompleteState, getAlgoliaResults, Pragma } from "@algolia/autocomplete-js";
import "@algolia/autocomplete-theme-classic/dist/theme.css";
import { Empty } from "antd";

import { useAppSelector } from "app/store";
import { SearchTaskItem } from "lib/types/searchTypes";
import { getTaskDetailUri } from "lib/helpers/navigationUtils";
import SearchAutocomplete from "./SearchAutocomplete";
import SearchItem from "./SearchItem";

interface TemplateItem {
  components: AutocompleteComponents;
  createElement: Pragma;
  item: SearchTaskItem,
  state: AutocompleteState<any>
}

const Search: FC = () => {
  const history = useHistory();
  const workspaces = useAppSelector(state => state.workspaces.workspaces);
  const { client, indexName } = useInstantSearchContext();

  const mapVisibleFilters = () => {
    return workspaces.map(workspace => `(visible_by:group/workspace-${workspace.id})`).join(" OR ");
  };

  const onClickLink = (item: SearchTaskItem) => {
    history.push(getTaskDetailUri(item.uid || item.id));
  };

  if (workspaces?.length) {
    return (
      <div className="app-container">
        <SearchAutocomplete
          openOnFocus
          getSources={({ query }) => [
            {
              sourceId: "search-items",
              getItems: () => getAlgoliaResults({
                searchClient: client as SearchClient,
                queries: [
                  {
                    indexName: indexName,
                    query,
                    params: {
                      filters: mapVisibleFilters()
                    }
                  },
                ],
              }),
              templates: {
                item: (templateItem: TemplateItem) => (
                  <SearchItem
                    item={templateItem.item}
                    components={templateItem.components}
                    onClickLink={onClickLink}
                  />
                ),
                noResults: () => (
                  <Empty
                    description="No data"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ),
              },
            },
          ]}
        />
      </div>
    );
  } else {
    return (null);
  }
};

export default Search;
