import { selectFilterEnv, selectFilterTypes } from "app/slices/tasksFilterAndSortSlice";
import { useAppSelector } from "app/store";
import { useParams } from "react-router-dom";
import { BoardContextInterface, useBoardContext } from "../../contexts/BoardContext";
import { useFilterValuesContext } from "../../contexts/FilterValuesContext";
import { getFilterValues } from "../../filters/filterHelpers";
import { FilterValueType, projectFilters, TAnyFilters, TProjectFilters } from "../../filters/filters";
import { FilterEnv } from "../../filters/filterTypes";
import { WorkspaceInterface } from "../../types/types";
import useWorkspaceById from "../useWorkspaceById";

interface useFilterValuesProps {
  currentFilter: TAnyFilters;
}

const useFilterValues = ({ currentFilter }: useFilterValuesProps): FilterValueType[] => {
  const { id } = useParams<{ id: string }>();
  const { board } = useBoardContext() as BoardContextInterface;
  const workspace = useWorkspaceById(id) as WorkspaceInterface;
  const filterEnv = useAppSelector(selectFilterEnv);
  const types = useAppSelector(selectFilterTypes);
  // it is not possible to calculate filter values for MyTasks and ViewerTasks inside this hook, that why we need context
  const contextFilterValues = useFilterValuesContext();

  switch (filterEnv) {
    case FilterEnv.MY_TASK: {
      return contextFilterValues.filterValuesOfAllFilterTypes?.[currentFilter.titleKey] || [];
    }

    case FilterEnv.VIEWER_TASKS: {
      return contextFilterValues.filterValuesOfAllFilterTypes?.[currentFilter.titleKey] || [];
    }

    case FilterEnv.PROJECT_TASKS_LIST: {
      return projectFilters[currentFilter.titleKey as TProjectFilters]?.filterValues(workspace);
    }

    default: getFilterValues({
      types,
      board,
      currentFilter
    });
  }

  return getFilterValues({
    types,
    board,
    currentFilter
  });
};

export default useFilterValues;
