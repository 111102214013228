import { getBoardIconPath } from "lib/helpers/boardUtils";
import React, { FC } from "react";
import { StyledIcon, StyledIconBox } from "./styled";

interface IconComponentProps {
  icon: string;
  onClick: () => void;
  disabled?: boolean;
  selected?: boolean;
}

const IconComponent: FC<IconComponentProps> = (props) => {
  const iconPath = getBoardIconPath(props.icon);

  return (
    <StyledIconBox
      onClick={props.onClick}
      disabled={props.disabled}
      selected={props.selected}
    >
      <StyledIcon
        src={iconPath}
        alt={`${props.icon}`}
      />
    </StyledIconBox>
  );
};

export default React.memo(IconComponent);
