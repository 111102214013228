import React, { FC, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Drawer, Empty } from "antd";
import { orderBy } from "lodash";
import ButtonIcon from "components/ui/ButtonIcon";
import { DrawerTitle } from "pages/Board/SecondaryBar/FieldsDrawer/styled";
import { ModalComponentProps } from "lib/types/components";

interface DrawerCollectionBarProps {
  title: string;
  items: any[];
  renderItem: (item: any) => React.ReactNode;
  modal: FC<ModalComponentProps>
  visible: boolean;
  onClose?: () => void;
}

const DrawerCollectionBar: FC<DrawerCollectionBarProps> = (props) => {
  const { title, items, renderItem, modal: Modal, visible, onClose } = props;
  const [isShowModal, setIsShowModal] = useState(false);

  let data = Array.from(items ?? []);
  data = orderBy(data, item => item.createdAt, ["desc"]);

  return (
    <>
      <Drawer
        title={<DrawerTitle>{title}</DrawerTitle>}
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
        width={350}
        bodyStyle={{ padding: 0 }}
      >
        <Scrollbars autoHide>
          <div style={{ marginBlock: "14px 24px", marginInline: 24 }}>
            <ButtonIcon text={`New ${title.toLowerCase().slice(0, -1)}`} onClick={() => setIsShowModal(true)} />
            <div style={{ height: "14px" }} />
            {data?.map(item => renderItem(item))}
            {!data.length && (
              <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </Scrollbars>
      </Drawer>
      {isShowModal && (
        <Modal
          visible={isShowModal}
          onClose={() => setIsShowModal(false)}
        />
      )}
    </>
  );
};

export default DrawerCollectionBar;
