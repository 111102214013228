import { FC } from "react";
import { isEmpty } from "lodash";

import { MemberRoles } from "lib/types/types";
import { useProjectContext } from "lib/contexts/ProjectContext";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import UserRights from "components/blocks/User/UserRights";

import { SectionContent, SectionHeading, SectionSubheading, TwoColumnGrid } from "styles/common";
import ProjectCollaboratorsInvite from "./ProjectCollaboratorsInvite";

interface ProjectCollaboratorsProps {
  modal?: boolean;
}

const ProjectCollaborators: FC<ProjectCollaboratorsProps> = (props) => {
  const { modal } = props;
  const { workspace } = useProjectContext();

  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);
  const workspaceId = workspace?.id || 0;
  const sectionMargin = modal ? 0 : 10;

  return (
    <SectionContent style={{ marginBottom: sectionMargin }}>
      {!modal &&
        <SectionHeading>Members</SectionHeading>
      }
      {allowedToEdit &&
        <ProjectCollaboratorsInvite />
      }
      <TwoColumnGrid>
        <SectionSubheading>Member</SectionSubheading>
        <div style={{ display: "flex", alignItems: "center" }}>
          <SectionSubheading>Permission</SectionSubheading>
        </div>
      </TwoColumnGrid>
      <div style={{ position: "relative" }}>
        {workspace?.members
          .filter(member => !isEmpty(member.user))
          .map(member =>
            <UserRights
              workspaceId={workspaceId}
              key={member.id}
              member={member}
            />
          )}
      </div>
    </SectionContent>
  );
};

export default ProjectCollaborators;
