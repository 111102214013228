import styled from "@emotion/styled";
import { ReactEditorJSContainer } from "styles/ReactEditorJS/styles";

export const TaskDetailedDescriptionRTEWrapper = styled.div`
  margin: 5px 0 25px 0;
`;

export const TaskDetailedDescriptionRTEContainer = styled(ReactEditorJSContainer)`
  .ce-toolbar__content, .ce-block__content {
    max-width: 685px;
  }
  .ce-toolbox, .ce-settings, .ce-popover {
    right: 0;
  }
  .cdx-input.image-tool__caption {
    display: none;
  }
  .cdx-attaches__file-icon {
    width: 36px;
    height: 30px;
    margin-right: 12px;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .cdx-attaches__file-icon-background {
    width: unset;
    height: unset;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-right: 0;
  }
  .cdx-attaches__file-icon-label {
    position: sticky;
  }
  .cdx-attaches__title {
    display: flex;
    overflow: hidden;
    max-width: unset;
    white-space: nowrap;
    line-height: 1.4;
  }
  .cdx-attaches__file-info {
    display: flex;
    flex-direction: column;
    margin-right: 7px;
    overflow: hidden;
  }
  .image-tool__image {
    margin-bottom: 6px;
  }
  .image-tool__caption {
    padding: 4px 12px;
  }
  .image-tool--withBackground .image-tool__image {
    background-color: #f6f6f6;
  }
  .ce-toolbar__plus, .ce-toolbar__settings-btn {
    transition: background-color 200ms ease;
    &:hover {
      background-color: #f6f6f6;
    }
  }
  .ce-popover__search {
    background-color: #f6f6f6;
  }
  .cdx-attaches__download-button {
    transition: background-color 200ms ease;
    background-color: #f6f6f6;
    &:hover {
      background-color: #f2f2f2;
    }
    svg path {
      color: #4d4d4d;
    }
  }
`;
