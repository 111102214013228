import React, { FC, useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Dropdown } from "antd";

import ButtonIcon from "components/ui/ButtonIcon";
import TaskDrawerMenu from "components/blocks/TaskDrawer/TaskDrawerMenu";
import TaskCheckmark from "pages/Task/TaskCheckmark";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { TaskInterface } from "lib/types/tasksTypes";
import { MemberRoles } from "lib/types/types";
import { TaskProvider } from "lib/contexts/TaskContext";
import { DisplayView } from "lib/types/applicationTypes";
import { getTaskDetailBoardView } from "lib/helpers/navigationUtils";
import useTaskSetValue from "lib/customHooks/dataLayer/task/useTaskSetValue";

import { KanbanContext } from "../lib";
import ScrollableLaneContext from "../ScrollableLane/context";

import Assignees from "./Assignees";
import Calendar from "./Calendar";
import {
  KanbanCardFooter,
  KanbanCardHeader,
  KanbanCard,
  KanbanCardCheck,
  KanbanCardContainer,
  KanbanCardSettings,
  KanbanCardTitle
} from "./styled";
import { useBoardContext } from "lib/contexts/BoardContext";
import { useBoardFeatures } from "lib/customHooks/useBoardFeatures";

const Card: FC<TaskInterface & { index: number }> = (props) => {
  const { id, uid, index, completed, title } = props;
  const params = useParams<{ id: string, view: string }>();
  const { board } = useBoardContext();
  const { isCompletionEnabled, isAssigneesEnabled, isDueDatesEnabled } = useBoardFeatures(board || props.board);

  const { setTaskValue } = useTaskSetValue({ task: props });
  const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);
  const [isDropdownOpened, setDropdownState] = useState(false);

  const onContextMenuChange = (visible: boolean) => setIsContextMenuOpened(visible);
  const onVisibleChange = (visible: boolean) => setDropdownState(visible);

  const scrollIndex: any = React.useContext(ScrollableLaneContext) || { min: 0, max: Infinity };
  const context = useContext(KanbanContext);
  const history = useHistory();

  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);
  const allowedToComplete = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);

  if (index < scrollIndex.min || index > scrollIndex.max) {
    return null;
  }

  const onCardClick = (e: React.MouseEvent) => {
    if (e.target !== e.currentTarget) {
      return;
    }
    openDetailTask();
  };

  const openDetailTask = () => {
    onVisibleChange(false);
    const target = getTaskDetailBoardView(params.id, params.view, uid);
    history.push(target);
  };

  const DropDownMenu = (
    <TaskDrawerMenu handleVisibility={onVisibleChange} />
  );

  return (
    <TaskProvider task={props} displayView={DisplayView.Kanban} setValue={setTaskValue}>
      <KanbanCardContainer>
        <Dropdown
          overlay={DropDownMenu}
          trigger={["contextMenu"]}
          placement="bottomRight"
          open={isContextMenuOpened}
          onOpenChange={onContextMenuChange}
        >
          <KanbanCard
            data-id={id}
            onClick={onCardClick}
          >
            <KanbanCardHeader>
              {isCompletionEnabled &&
                <KanbanCardCheck>
                  <TaskCheckmark
                    id={id}
                    setValue={setTaskValue}
                    isCompleted={completed}
                    clickable={allowedToComplete}
                  />
                </KanbanCardCheck>
              }
              <KanbanCardTitle
                isCompleted={isCompletionEnabled && completed}
                onClick={onCardClick}
              >
                {title}
              </KanbanCardTitle>
            </KanbanCardHeader>
            {(isAssigneesEnabled || isDueDatesEnabled) &&
              <KanbanCardFooter>
                {isAssigneesEnabled &&
                  <Assignees kanbanContext={context} />
                }
                {isDueDatesEnabled &&
                  <Calendar kanbanContext={context} disabled={!allowedToEdit} />
                }
              </KanbanCardFooter>
            }
            <Dropdown
              overlay={DropDownMenu}
              trigger={["click"]}
              placement="bottomRight"
              open={isDropdownOpened}
              onOpenChange={onVisibleChange}
            >
              <KanbanCardSettings>
                <ButtonIcon iconUrl="/icons/more.svg" />
              </KanbanCardSettings>
            </Dropdown>
          </KanbanCard>
        </Dropdown>
      </KanbanCardContainer>
    </TaskProvider>
  );
};

export default Card;
