import { cloneDeep, sortBy } from "lodash";
import { BoardInterface, DefaultColumnCodes } from "lib/types/boardTypes";
import { Column } from "lib/types/tasksTypes";
import { UserInterface } from "lib/types/types";

interface BoardPropertyOverride {
  code: DefaultColumnCodes;
  key: keyof Column;
  value: any;
}

export const getBoardIconPath = (iconName?: string | null) => {
  return iconName ? `/images/${iconName}.png` : "/images/brick.png";
};

export const getBoardLastSortColumns = (columns: Column[]) => {
  return 1 + Math.max(...columns.map(column => column.sort), 0);
};

export const getColumnPrecision = (precision?: number | null): number => {
  return (typeof precision === "number") ? precision : 2;
};

export const enrichBoardEntity = (board: BoardInterface | undefined, users: Array<UserInterface>) => {
  if (!board) {
    return null;
  }

  const entity = cloneDeep(board);
  entity.collaborators = users;
  entity.statuses = sortBy(entity.statuses, status => status.sort);
  entity.columns = sortBy(entity.columns, column => column.sort);

  const propsOverride: Array<BoardPropertyOverride> = [
    { code: DefaultColumnCodes.Status, key: "options", value: entity.statuses },
    { code: DefaultColumnCodes.Assignees, key: "options", value: users },
    { code: DefaultColumnCodes.Title, key: "required", value: true },
  ];

  const setColumnProperties = ({ code, key, value }: BoardPropertyOverride) => {
    const columnIndex = (entity?.columns || []).findIndex(column => column.code === code);
    if (columnIndex !== -1) {
      entity.columns[columnIndex][key] = value as never;
    }
  };

  propsOverride.forEach(setColumnProperties);

  return entity;
};
