import styled from "@emotion/styled/macro";

export const BoardFormFallbackContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const BoardFormFallbackOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(249, 249, 248, 0.3);
`;

export const BoardFormFallbackCard = styled.div`
  background-color: white;
  width: 400px;
  padding: 30px 40px;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 60px;
`;

export const BoardFormFallbackTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #4d4d4d;
  margin-bottom: 15px;
`;

export const BoardFormFallbackView = styled.div`
  margin: 4px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #999999;
`;
