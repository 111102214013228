import { FC } from "react";
import IconSvgWrapper from "components/icons/IconSvgWrapper";
import { IconProps } from "components/icons/lib";

const PlusSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path xmlns="http://www.w3.org/2000/svg" d="M11.343 5.06266H11.3437H6.93746V0.664707C6.93746 0.302756 6.64439 0.00012207 6.28244 0.00012207H5.71863C5.35678 0.00012207 5.06244 0.302756 5.06244 0.664707V5.06266H0.656293C0.294537 5.06266 0 5.35534 0 5.71739V6.28441C0 6.64617 0.294439 6.93759 0.656293 6.93759H5.06254V11.3514C5.06254 11.7132 5.35678 12 5.71873 12H6.28254C6.64449 12 6.93756 11.7131 6.93756 11.3514V6.93759H11.343C11.705 6.93759 12 6.64607 12 6.28441V5.71739C12 5.35534 11.705 5.06266 11.343 5.06266Z"/>
    </IconSvgWrapper>
  );
};

export default PlusSvg;
