import { FC, ReactNode } from "react";

interface ReloaderTextProps {
  text?: string | ReactNode,
}

const ReloaderText: FC<ReloaderTextProps> = (props) => {
  return (
    <>
      {props.text}
      <span onClick={() => window.location.reload()}>Refresh page</span>
    </>
  );
};

export default ReloaderText;
