import React, { FC, useEffect, useRef, useState } from "react";
import { Form, Upload } from "antd";
import styled from "@emotion/styled/macro";
import { UploadChangeParam, UploadFile, UploadProps } from "antd/lib/upload/interface";
import { MentionsInput, Mention, SuggestionDataItem, MentionItem } from "react-mentions";
import OutsideClickHandler from "react-outside-click-handler";

import AvatarCustom from "components/ui/AvatarCustom";
import ButtonIcon from "components/ui/ButtonIcon";
import CustomStyledButton from "components/blocks/CustomStyledButton";
import UserCustom from "components/ui/UserCustom";
import LoadingMessage from "components/blocks/LoadingMessage";
import { getUserFullName } from "lib/helpers/userUtils";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import useTaskSetComments from "lib/customHooks/dataLayer/task/useTaskSetComments";
import useSavingState from "lib/customHooks/useSavingState";
import KeyCodes from "lib/types/keyCodes";
import { EntityId } from "lib/types/entity";
import { CommentInterface, FileInterface, MemberRoles } from "lib/types/types";
import configuration from "lib/configs/configuration";
import { useTaskContext, TaskContextInterface } from "lib/contexts/TaskContext";
import { captureException } from "lib/utils/sentry";
import TasksService from "services/TasksService";
import { useAppSelector } from "app/store";

import { StyledButton } from "styles/common";
import {
  TaskCommentBoxAvatar,
  TaskCommentBoxButtonsContainer,
  TaskCommentBoxButtons,
  TaskCommentBoxMainContainer,
  TaskCommentBoxInputContainer
} from "./styled";
import {
  reactMentionsSuggestionStyle,
  reactMentionsMentionStyle,
  mentionTextareaStyle,
  reactMentionsSuggestionScroll
} from "./style";

const StyledMentionsInput = styled(MentionsInput)`
  margin-top: 4px;
`;

export interface TaskCommentBoxProps {
  isEditor: boolean;
  commentId?: EntityId;
  initialText?: string;
  initialFileAttachments?: Array<FileInterface>;
  onDiscard: () => void;
  isActive?: boolean;
}

// TODO: refactor this old component
const TaskCommentBox: FC<TaskCommentBoxProps> = (props) => {
  const { isEditor, initialText, initialFileAttachments, commentId } = props;

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { task } = useTaskContext() as TaskContextInterface;
  const { updateComment } = useTaskSetComments({ task, updateDetailed: true });
  const taskId = task.id;
  const workspaceId = task.board.workspace;

  const initialTextValue = initialText || "";
  const initialFileList: Array<UploadFile<FileInterface>> = (initialFileAttachments || [])
    .filter(attachment => attachment.url)
    .map(attachment => ({
      uid: String(attachment.id),
      name: attachment.name,
      url: attachment.url
    }));
  const initialAttachments = (initialFileAttachments || []).filter(attachment => attachment.url);

  const { setSavingStart, setSavingFinish } = useSavingState();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [value, setValue] = useState(initialTextValue);
  const [fileList, setFileList] = useState(initialFileList);
  const [attachments, setAttachments] = useState(initialAttachments);

  useEffect(() => {
    setValue(initialTextValue);
    setFileList(initialFileList);
    setAttachments(initialAttachments);
    setIsActive(isEditor);
    // TODO: (boris@airtasks.xyz) fix useEffect dependencies
    // eslint-disable-next-line
  }, [initialText, initialFileAttachments]);

  const user = useAppSelector(state => state.currentUserReducer.user);
  const token = useAppSelector(state => state.auth.token);

  const workspaces = useAppSelector(state => state.workspaces.workspaces);
  const sourceWorkspace = workspaces.find(workspace => String(workspace.id) === String(workspaceId));
  const collaborators = sourceWorkspace?.collaborators || [];
  const suggestions = collaborators.map(user => ({ display: getUserFullName(user), id: user.id, user: user }));
  const allowedToComment = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor, MemberRoles.Commenter]);

  let bottomGuardElement: Element | undefined;
  const isSubmitDisabled = (!value.trim().length && !attachments.length) || uploading;

  const handleOnOutsideClick = () => {
    setIsActive(false);
  };

  const resetState = () => {
    setLoading(false);
    setAttachments([]);
    setValue("");
    setFileList([]);
    setIsActive(false);
    if (isEditor) {
      props.onDiscard();
    }
  };

  const uploadProps: UploadProps = {
    name: "files",
    action: `${configuration.backend.url}upload`,
    headers: { authorization: `Bearer ${token}` },
    fileList,
    beforeUpload: () => handleUploadBeforeUpload(),
    onChange: (info: UploadChangeParam) => handleUploadOnChange(info),
    onRemove: (file: UploadFile) => handleUploadOnRemove(file),
  };

  const handleUploadBeforeUpload = () => {
    setUploading(true);
  };

  const handleUploadOnChange = (info: UploadChangeParam) => {
    setFileList(info.fileList);
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      setUploading(false);
      const [response] = info.file.response as FileInterface[];
      LoadingMessage.complete(`"${info.file.name}" has been uploaded successfully`);
      setAttachments(attachments => [...attachments, response]);
    } else if (info.file.status === "error") {
      setUploading(false);
      captureException(info, true, `"${info.file.name}" upload failed.`);
    }
  };

  const handleUploadOnRemove = (file: UploadFile) => {
    const attachmentId = (file.response) ? file.response[0].id : file.uid;
    const updateAttachments = attachments.filter(item => String(item.id) !== String(attachmentId));
    setAttachments(updateAttachments);
    LoadingMessage.complete(`${file.name} file deleted successfully`);
  };

  /** Form handlers */
  const handleCommentSubmit = async () => {
    if (!value && !attachments) {
      return;
    }

    setSavingStart();
    setLoading(true);
    const payload: Partial<CommentInterface> = {
      text: value,
      attachments,
    };
    await TasksService.addComment(taskId, payload);
    setSavingFinish();

    resetState();
  };

  const handleCommentUpdate = () => {
    if ((!value && !attachments) || !commentId) {
      return;
    }

    const payload: Partial<CommentInterface> = {
      text: value,
      attachments,
      edited: true,
    };

    updateComment(commentId, payload);

    resetState();
  };

  const handleCommentCancel = () => {
    resetState();
  };

  /** Textarea handlers */
  const handleTextAreaOnFocus = () => {
    setIsActive(true);
  };

  const handleChange = (event: { target: { value: string } }, newValue: string, newPlainTextValue: string, mentions: MentionItem[]) => {
    setValue(newValue);
  };

  const handleMentionRenderSuggestion = (suggestion: SuggestionDataItem, search: string, highlightedDisplay: React.ReactNode, index: number, focused: boolean) => {
    return (
      <>
        {/*@ts-ignore*/}
        <UserCustom user={suggestion.user} name={suggestion.display} />
      </>
    );
  };

  const handleMentionDisplayTransform = (id: string, display: string) => {
    return `@${display}`;
  };

  const handleOnKeyDown = async (event: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.key === "Enter") && event.metaKey) {
      const submitFn = isEditor ? handleCommentUpdate : handleCommentSubmit;
      return submitFn();
    }
    if (event.keyCode === KeyCodes.Escape) {
      event.stopPropagation();
      setIsActive(false);
      textareaRef.current?.blur();
    }
  };

  if (allowedToComment) {
    return (
      <TaskCommentBoxMainContainer>
        <TaskCommentBoxAvatar>
          <AvatarCustom user={user} size="large" />
        </TaskCommentBoxAvatar>
        <TaskCommentBoxInputContainer>
          <Form>
            <OutsideClickHandler onOutsideClick={handleOnOutsideClick}>
              <Form.Item>
                <div
                  id="TaskCommentBoxInputPortal" ref={(e) => {
                    bottomGuardElement = e || undefined;
                  }}
                  className={reactMentionsSuggestionScroll}
                >
                  <StyledMentionsInput
                    inputRef={textareaRef}
                    value={value}
                    onChange={handleChange}
                    onFocus={handleTextAreaOnFocus}
                    style={reactMentionsSuggestionStyle((isActive) ? 96 : 32)}
                    placeholder="Post an update or mention people using @"
                    suggestionsPortalHost={bottomGuardElement}
                    allowSuggestionsAboveCursor
                    className={mentionTextareaStyle}
                    onKeyDown={handleOnKeyDown}
                  >
                    <Mention
                      trigger="@"
                      data={suggestions}
                      renderSuggestion={handleMentionRenderSuggestion}
                      displayTransform={handleMentionDisplayTransform}
                      appendSpaceOnAdd
                      style={reactMentionsMentionStyle}
                    />
                  </StyledMentionsInput>
                </div>
              </Form.Item>
              <TaskCommentBoxButtonsContainer>
                {(isActive) &&
                  <div>
                    <TaskCommentBoxButtons>
                      {isEditor
                        ? (<div>
                          <StyledButton onClick={handleCommentCancel} style={{ marginRight: "7px" }}>Cancel</StyledButton>
                          <CustomStyledButton
                            onClick={handleCommentUpdate}
                            disabled={loading || isSubmitDisabled}
                            btnColor="purple"
                            isLoading={loading}
                            value="Save changes"
                          />
                        </div>)
                        : <CustomStyledButton
                          onClick={handleCommentSubmit}
                          disabled={loading || isSubmitDisabled}
                          btnColor="purple"
                          isLoading={loading}
                          value="Post comment"
                        />
                      }
                    </TaskCommentBoxButtons>
                    <Upload {...uploadProps} multiple>
                      <div style={{ padding: "2px 0" }}>
                        <ButtonIcon iconUrl="/icons/attachment.svg" iconOutlined />
                      </div>
                    </Upload>
                  </div>
                }
              </TaskCommentBoxButtonsContainer>
            </OutsideClickHandler>
          </Form>
        </TaskCommentBoxInputContainer>
      </TaskCommentBoxMainContainer>
    );
  } else {
    return (null);
  }
};

export default TaskCommentBox;
