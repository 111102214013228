import { FC, MouseEventHandler, useRef, useState } from "react";
import Draggable, { DraggableEventHandler } from "react-draggable";
import { Modal } from "antd";

import { TaskInterface } from "lib/types/tasksTypes";
import { TaskCalendarMaxCell } from "../lib";
import {
  TaskCalendarModalHeader, TaskCalendarModalHeaderDate,
  TaskCalendarModalHeaderWeekday,
  TaskCalendarMore
} from "components/blocks/TaskCalendar/styled";

interface TaskCalendarCellModalProps {
  date: moment.Moment;
  cellTasks: Array<TaskInterface>;
}

const TaskCalendarCellModal: FC<TaskCalendarCellModalProps> = (props) => {
  const { cellTasks, date } = props;
  const dragRef = useRef<HTMLDivElement>(null);
  const clickRef = useRef<HTMLDivElement>(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDragDisabled, setIsDragDisabled] = useState(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const [modalPos, setModalPos] = useState({ left: 0, top: 0 });

  const showModal: MouseEventHandler<HTMLDivElement> = (event) => {
    // TODO: calculate proper modal position
    const targetRect = clickRef?.current?.getBoundingClientRect();
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { clientWidth, clientHeight } = window?.document?.documentElement;

    const pos = {
      left: event.clientX - clientWidth / 2,
      top: event.clientY - 150,
    };

    setModalPos(pos);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onStart: DraggableEventHandler = (event, uiData) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = dragRef?.current?.getBoundingClientRect();

    if (!targetRect) {
      return;
    }

    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y)
    });
  };

  return (
    <>
      <TaskCalendarMore onClick={showModal} ref={clickRef}>
        {(cellTasks.length - TaskCalendarMaxCell)} more
      </TaskCalendarMore>
      {isModalVisible &&
        <Modal
          title={
            <TaskCalendarModalHeader
              onMouseOver={() => {
                if (isDragDisabled) {
                  setIsDragDisabled(false);
                }
              }}
              onMouseOut={() => {
                setIsDragDisabled(true);
              }}
            >
              <TaskCalendarModalHeaderWeekday>{date.format("ddd")}</TaskCalendarModalHeaderWeekday>
              <TaskCalendarModalHeaderDate>{date.format("D")}</TaskCalendarModalHeaderDate>
            </TaskCalendarModalHeader>
          }
          // TODO: calculations
          // style={{ top: modalPos.top, left: modalPos.left, transformOrigin: "unset" }}
          width={340}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          mask={false}
          footer={null}
          destroyOnClose
          bodyStyle={{ maxHeight: "500px", overflow: "auto" }}
          modalRender={modal => (
            <Draggable
              disabled={isDragDisabled}
              bounds={bounds}
              onStart={onStart}
            >
              <div ref={dragRef}>{modal}</div>
            </Draggable>
          )}
        >
          {props.children}
        </Modal>
      }
    </>
  );
};

export default TaskCalendarCellModal;
