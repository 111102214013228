import styled from "@emotion/styled/macro";
import { Input, InputNumber } from "antd";
import DragDrop from "components/ui/DragDrop";
import { styledInputCss } from "styles/common-css";
import { SyncedFieldTooltipIcon } from "components/blocks/SyncedFieldTooltip/styled";
import { DefaultIconColor } from "components/icons/lib";
import { css } from "@emotion/react/macro";

const dragDropWidth = 24;

export const TaskTitleSyncedIcon = styled.img`
  display: none;
`;

export const TitleInput = styled(Input)`
  border: 1px solid transparent;
  transition: 0.2s;
  background-color: transparent;
  &:focus, &:hover {
    background-color: white;
    ${SyncedFieldTooltipIcon} {
      display: block;
    }
`;

export const TaskTitleWrapper = styled.div<{ isCompletionDisabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 12px;
  ${({ isCompletionDisabled }) => isCompletionDisabled && css`
    padding-left: 0;
  `}
  &:focus, &:hover {
    ${TitleInput}
    .ant-form-item-has-error {
      ${TitleInput} {
        border-color: #ff4d4f;
      }
    }
  }
  .ant-input-disabled {
    border-color: transparent;
    background-color: transparent;
    color: black;
    cursor: default;
  }
`;

export const TaskTitleDragDrop = styled(DragDrop)`
  position: absolute;
  left: -${dragDropWidth}px;
  width: ${dragDropWidth}px;
`;

export const TaskTitleInputWrapper = styled.div`
  cursor: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  padding: 8px 6px 8px 0;
  white-space: nowrap;
  resize: none;
  background-color: transparent;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  .ant-form-item-explain {
    position: absolute;
    top: 85%;
    font-size: 10px;
    line-height: 1;
  }
`;

export const InputStyled = styled(Input)`
  ${styledInputCss};
`;

export const InputNumberStyled = styled(InputNumber)`
  ${styledInputCss};
  .ant-input-number-input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const TextAreaStyled = styled(Input.TextArea)`
  font-size: 13px;
  padding: 4px 7px;
`;

export const TextAreaCommentWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 13px;
  color: ${DefaultIconColor};
  transition: border-color 200ms ease;
  padding: 2px 5px;
  margin-right: 5px;
  border-radius: 3px;
  &:hover {
    background-color: #f2f2f2;
  }
`;

export const TextAreaCommentButton = styled.div`
  display: flex;
  align-items: center;
`;
