import { FC } from "react";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { TaskFieldPropsGeneric, TaskSourceComponent } from "lib/types/applicationTypes";
import { dataInput } from "components/blocks/TaskTable/styled";
import { DottedPlaceholderWrapper } from "components/blocks/TaskTable/components/placeholders/DottedPlaceholder/styled";
import { fieldDetailedHover, fieldDetailedWrapper } from "../styled";
import UsersGeneric from "./Display/generic";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";

type UsersFieldProps = TaskFieldPropsGeneric

const UsersListWrapper = styled.div<{ clickable?: boolean }>`
  ${dataInput};
  cursor: pointer;
  &:hover {
    ${DottedPlaceholderWrapper} {
      opacity: 1;
    }
  }
  ${({ clickable }) => !clickable && css`
    cursor: default;
    ${DottedPlaceholderWrapper} {
      display: none;
    }
  `}
`;

const UsersDetailedWrapper = styled.div<{ clickable?: boolean }>`
  ${fieldDetailedWrapper};
  ${fieldDetailedHover};
  cursor: ${({ clickable }) => clickable ? "pointer" : "default"};
  width: 100%;
`;

const UsersField: FC<UsersFieldProps> = (props) => {
  const { component } = props;
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin, MemberRoles.Editor]);

  switch (component) {
    case TaskSourceComponent.Card:
      return (
        <div>
          <UsersGeneric {...props} />
        </div>
      );
    case TaskSourceComponent.Cell:
      return (
        <UsersListWrapper clickable={allowedToEdit}>
          <UsersGeneric {...props} />
        </UsersListWrapper>
      );
    case TaskSourceComponent.Detailed:
      return (
        <UsersDetailedWrapper clickable={allowedToEdit}>
          <UsersGeneric {...props} />
        </UsersDetailedWrapper>
      );
    default:
      return null;
  }
};

export default UsersField;
