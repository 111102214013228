import { FC } from "react";
import { Redirect } from "react-router-dom";
import { useAppSelector } from "app/store";

const ProtectedRoute: FC<{ children: JSX.Element }> = (props) => {
  const jwt = useAppSelector(state => state.auth.token);

  if (jwt) {
    return props.children;
  }
  return <Redirect to="/auth" />;
};

export default ProtectedRoute;
