import { FC } from "react";
import { Tooltip } from "antd";

const HiddenCell: FC = () => {
  return (
    <Tooltip title="This field is disabled in the board settings.">
      <span style={{ color: "#bfbfbf" }}>—</span>
    </Tooltip>
  );
};

export default HiddenCell;
