import styled from "@emotion/styled/macro";

export const BoardViewLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
`;

export const BoardViewEmptyState = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 100px 0;
`;
