import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const MoreSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
        <path d="M62.5 50C62.5 56.9038 56.9038 62.5 50 62.5C43.0962 62.5 37.5 56.9038 37.5 50C37.5 43.0962 43.0962 37.5 50 37.5C56.9038 37.5 62.5 43.0962 62.5 50Z" fill="#B0B0B0"/>
        <path d="M25 50C25 56.9038 19.4038 62.5 12.5 62.5C5.59616 62.5 0 56.9038 0 50C0 43.0962 5.59616 37.5 12.5 37.5C19.4038 37.5 25 43.0962 25 50Z" fill="#B0B0B0"/>
        <path d="M100 50C100 56.9038 94.4042 62.5 87.5 62.5C80.5962 62.5 75 56.9038 75 50C75 43.0962 80.5962 37.5 87.5 37.5C94.4042 37.5 100 43.0962 100 50Z" fill="#B0B0B0"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default MoreSvg;
