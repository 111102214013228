import { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNew from "components/icons/IconSvgWrapperNew";

const OpenSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNew {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
        <path d="M15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5V92.5C0 96.6421 3.35786 100 7.5 100C11.6421 100 15 96.6421 15 92.5V7.5Z"/>
        <path d="M100 65.8333C100 61.6911 96.6421 58.3333 92.5 58.3333C88.3579 58.3333 85 61.6911 85 65.8333V92.4999C85 96.6421 88.3579 99.9999 92.5 99.9999C96.6421 99.9999 100 96.6421 100 92.4999V65.8333Z"/>
        <path d="M100 7.5C100 3.35786 96.6421 0 92.5 0C88.3579 0 85 3.35786 85 7.5V34.1667C85 38.3088 88.3579 41.6667 92.5 41.6667C96.6421 41.6667 100 38.3088 100 34.1667V7.5Z"/>
        <path d="M92.5 85H7.5C3.35786 85 0 88.3579 0 92.5C0 96.6421 3.35786 100 7.5 100H92.5C96.6421 100 100 96.6421 100 92.5C100 88.3579 96.6421 85 92.5 85Z"/>
        <path d="M34.1667 0H7.5C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15H34.1667C38.3088 15 41.6667 11.6421 41.6667 7.5C41.6667 3.35786 38.3088 0 34.1667 0Z"/>
        <path d="M92.5 0H65.8333C61.6912 0 58.3333 3.35786 58.3333 7.5C58.3333 11.6421 61.6912 15 65.8333 15H92.5C96.6422 15 100 11.6421 100 7.5C100 3.35786 96.6422 0 92.5 0Z"/>
        <path d="M94.7666 15.9099C97.6956 12.981 97.6956 8.23223 94.7666 5.3033C91.8377 2.37437 87.089 2.37437 84.16 5.3033L44.6799 44.7834C41.751 47.7124 41.751 52.4611 44.6799 55.39C47.6088 58.319 52.3576 58.319 55.2865 55.39L94.7666 15.9099Z"/>
      </svg>
    </IconSvgWrapperNew>
  );
};

export default OpenSvg;
