import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";

interface OptionWrapperProps {
  disabled?: boolean;
}

export const OptionWrapper = styled.div<OptionWrapperProps>`
  display: flex;
  align-items: center;
  color: #5c5a56;
  ${({ disabled }) => disabled && css`
    color: #949494;
    opacity: 0.7;
  `}
`;

export const Image = styled.img<OptionWrapperProps>`
  margin-right: 6px;
  ${({ disabled }) => disabled && css`
    color: #949494;
    opacity: 0.7;
  `}
`;
