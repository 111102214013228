import React, { FC, useContext } from "react";
import { TaskInterface, TaskSetValueHandler } from "lib/types/tasksTypes";
import { DisplayView } from "lib/types/applicationTypes";

interface TaskContextProps {
  task: TaskInterface | null;
  displayView: DisplayView;
  isViewerBoardView?: boolean;
  setValue?: TaskSetValueHandler;
}

const TaskContext = React.createContext<TaskContextProps>({
  task: null,
  displayView: DisplayView.List,
});

export interface TaskContextInterface {
  task: TaskInterface;
  displayView: DisplayView;
  setValue: TaskSetValueHandler;
}

export const useTaskContext = () => useContext(TaskContext);

export const TaskProvider: FC<TaskContextProps> = (props) => {
  return (
    <TaskContext.Provider value={{ ...props }}>
      {props.children}
    </TaskContext.Provider>
  );
};

