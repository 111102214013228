import { selectGeneralFilter, setGeneralFilter } from "app/slices/tasksFilterAndSortSlice";
import { useAppDispatch } from "app/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useBoardView from "./useBoardView";

type TUseBoardViewGeneralFilter = () => string;

const useBoardViewGeneralFilter: TUseBoardViewGeneralFilter = () => {
  const dispatch = useAppDispatch();
  const view = useBoardView();
  const generalFilter = useSelector(selectGeneralFilter);

  useEffect(() => {
    const generalFilterFromView = view?.appliedFilter?.generalFilter;

    if (generalFilterFromView) {
      dispatch(setGeneralFilter(generalFilterFromView));
    }
  }, [dispatch, view]);

  return generalFilter;
};

export default useBoardViewGeneralFilter;
