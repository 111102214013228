import { BoardAutomationInterface } from "lib/types/boardTypes";
import { EntityId } from "lib/types/entity";
import { captureException } from "lib/utils/sentry";
import BoardService from "services/BoardService";
import useBoardPerformRequest, { BoardPerformRequestHookProps } from "./useBoardPerformRequest";

const useBoardSetAutomations = (props: BoardPerformRequestHookProps) => {
  const { board } = props;
  const performRequest = useBoardPerformRequest(props);

  const updateBoardAutomation = (automationId: EntityId, payload: Partial<BoardAutomationInterface>) => {
    if (!board) {
      return;
    }

    const automationIndex = board.automations.findIndex(automation => String(automation.id) === String(automationId));
    if (automationIndex === -1) {
      captureException(`Cannot find automation ${automationId}`);
      return;
    }

    const automations = Array.from(board.automations);
    const automation = automations[automationIndex];
    automations[automationIndex] = { ...automation, ...payload };

    const requestFn = () => BoardService.updateAutomation(board.id, automationId, payload);

    return performRequest({ automations }, requestFn);
  };

  const deleteBoardAutomation = (automationId: EntityId) => {
    if (!board) {
      return;
    }

    const automationIndex = board.automations.findIndex(automations => String(automations.id) === String(automationId));
    if (automationIndex === -1) {
      captureException(`Cannot find automation ${automationId}`);
      return;
    }

    const automations = Array.from(board.automations);
    automations.splice(automationIndex, 1);

    const requestFn = () => BoardService.deleteAutomation(board.id, automationId);

    return performRequest({ automations }, requestFn);
  };

  return { updateBoardAutomation, deleteBoardAutomation };
};

export default useBoardSetAutomations;
