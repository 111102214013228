import { useState, FC, useEffect } from "react";
import { Link } from "react-router-dom";
import useLocationIncludes from "lib/customHooks/routeHooks/useCheckIsViewerRoute";
import AsideCustom from "./SidebarCustom";
import HeaderCustom from "./HeaderCustom";
import "react-perfect-scrollbar/dist/css/styles.css";

import { App, Page, Main, Sidebar, SidebarRoot, SidebarHeader, MainContent, Header, HeaderButton, FullPage, Logo } from "./styled";
import ButtonIcon from "components/ui/ButtonIcon";

const AppLayout: FC = (props) => {
  const isViewer = useLocationIncludes("viewer");
  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    if (isViewer) {
      setIsClosed(true);
    }
  }, [isViewer]);

  const onClick = () => {
    setIsClosed(!isClosed);
  };

  return (
    <App>
      <Page>
        <Main>
          <Sidebar isClosed={isClosed}>
            <SidebarRoot>
              <SidebarHeader>
                <Link to="/home">
                  <Logo src={process.env.PUBLIC_URL + "/logo.svg"} alt="logo" />
                </Link>
                <ButtonIcon onClick={onClick} iconUrl="/icons/sidenav-close.svg" />
              </SidebarHeader>
              <AsideCustom />
            </SidebarRoot>
          </Sidebar>
          <MainContent>
            <Header>
              <HeaderButton isClosed={isClosed}>
                <ButtonIcon onClick={onClick} iconUrl="/icons/sidenav-open.svg" />
              </HeaderButton>
              <HeaderCustom />
            </Header>
            <FullPage>
              {props.children}
            </FullPage>
          </MainContent>
        </Main>
      </Page>
    </App>
  );
};

export default AppLayout;
