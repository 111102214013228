import { DisplayView } from "./applicationTypes";
import { BaseEntityInterface, EntityId, NestedEntityInterface } from "./entity";
import { Column } from "./tasksTypes";
import { UserInterface, WorkspaceInterface } from "./types";
import { TransactionInitiatorInterface } from "./wsTypes";

export enum DefaultColumnCodes {
  Completion = "completed",
  Title = "title",
  Description = "description",
  DescriptionRTE = "descriptionRich",
  Status = "status",
  Assignees = "assignees",
  StartDate = "startDate",
  DueDate = "dueDate",
  Viewpoints = "viewpoints",
  Annotations = "annotations",
  Geometry = "elements",
  CreatedTime = "created_at",
  LastModified = "updated_at",
  CreatedBy = "owner",
  LastModifiedBy = "lastEditor",
  ClosingDate = "closingDate",
  ID = "id",
  GUID = "guid",
  Progress = "progress",
  Comments = "comments",
  Collaborators = "collaborators",
  Estimate = "estimate",
  Subtasks = "subtasks",
}

export enum BoardDataSources {
  Bim360 = "bim360issues",
}

export interface BoardDataSourceInterface {
  id?: EntityId;
  source: BoardDataSources;
  connectionId: string;
  bidirectional: boolean;
  sourceId: string;
  sourceName: string;
  frequency: "automatic" | "manual";
  syncItemDeletion: boolean;
  lastAttemptStartTime?: Date;
  lastFailureInfo?: string;
  lastFailureTime?: Date;
  lastSuccessTime?: Date;
}

export type BoardDisplayView = {
  list: boolean;
  kanban: boolean;
  calendar: boolean;
  form: boolean;
  files: boolean;
  model: boolean;
}

export enum BoardUnit {
  Asset = "asset",
  Delivery = "delivery",
  Document = "document",
  Drawing = "drawing",
  Entry = "entry",
  Equipment = "equipment",
  File = "file",
  Issue = "issue",
  Item = "item",
  Order = "order",
  Part = "part",
  Plan = "plan",
  Record = "record",
  Request = "request",
  Submission = "submission",
  Task = "task",
  Teammate = "teammate",
  Ticket = "ticket",
  Vendor = "vendor",
  Zone = "zone",
}

export type BoardFeatures = {
  id?: number;
  completion: boolean;
  statuses: boolean;
  assignees: boolean;
  dueDates: boolean;
  models: boolean;
  annotations: boolean;
  unit: BoardUnit;
}

export interface RecordTemplateInterface extends NestedEntityInterface {
  title: string;
  sort: number;
  template: Record<string, any>;
}

export type BoardFeaturesToggled = Omit<BoardFeatures, "id" | "unit">;

export interface BoardInterface extends BaseEntityInterface {
  color: string
  description: string
  icon: string
  name: string
  owner: number
  statuses: BoardStatusInterface[]
  workspace: EntityId,
  columns: Column[];
  collaborators: UserInterface[];
  transactionInitiator: TransactionInitiatorInterface;
  externalDataSource: BoardDataSourceInterface | null;
  automations: Array<BoardAutomationInterface>;
  views: Array<BoardViewInterface>;
  displayView: BoardDisplayView | null;
  features: BoardFeatures | null;
  recordTemplates: Array<RecordTemplateInterface>;
}

export interface BoardInterfaceExtended extends Omit<BoardInterface, "owner" | "workspace"> {
  owner: UserInterface
  workspace: WorkspaceInterface
}

export interface BoardStatusInterface {
  id: EntityId;
  title: string;
  color: string;
  isFinal: boolean;
  sort: number;
  system: boolean;
}

export interface BoardAutomationInterfaceDefault {
  title: string;
  enabled: boolean;
  contentType: string;
  event: BoardAutomationEvents;
  triggerProperty: string;
  triggerValue: string;
  updateProperty: string;
  updateValue: string;
}

export enum BoardAutomationEvents {
  Create = "create",
  Update = "update",
  Delete = "delete",
}

export interface BoardAutomationInterface extends BoardAutomationInterfaceDefault {
  id?: EntityId;
  owner: UserInterface;
  createdAt: Date;
  updatedAt: Date;
  sort: number;
}

export interface BoardViewInterface extends NestedEntityInterface {
  title: string;
  description: string;
  shared: boolean;
  sort: number;
  view: DisplayView;
  appliedFilter: Record<string, any> | null;
  appliedSort: Record<string, any> | null;
  appliedColumns: Array<BoardViewAppliedColumn>;
  appliedStatuses: Record<string, any> | null;
}

export interface BoardViewAppliedColumn {
  id: EntityId;
  sort: number;
  hidden: boolean;
  title?: string;
  required?: boolean;
}
