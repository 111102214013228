import { FC } from "react";

import { DottedPlaceholderWrapper, DottedCircle, Image } from "./styled";

interface PlaceholderProps {
  icon?: string;
  onClick?: () => void;
}

const DottedPlaceholder: FC<PlaceholderProps> = (props) => {
  const icon = props?.icon || "/icons/calendar.svg";

  return (
    <DottedPlaceholderWrapper onClick={props.onClick || (() => false)}>
      <DottedCircle>
        <Image src={process.env.PUBLIC_URL + `${icon}`} alt="menu" />
      </DottedCircle>
    </DottedPlaceholderWrapper >
  );
};

export default DottedPlaceholder;
