import { BoardInterface } from "lib/types/boardTypes";
import BoardService from "services/BoardService";
import useBoardPerformRequest, { BoardPerformRequestHookProps } from "./useBoardPerformRequest";

const useBoardSetValue = (props: BoardPerformRequestHookProps) => {
  const { board } = props;
  const performRequest = useBoardPerformRequest(props);

  const setBoardValue = (payload: Partial<BoardInterface>) => {
    if (!board) {
      return;
    }
    const requestFn = () => BoardService.updateBoard(board.id, payload);
    return performRequest(payload, requestFn);
  };

  return { setBoardValue };
};

export default useBoardSetValue;
