import React, { FC, useState } from "react";
import { Form, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useAppSelector } from "app/store";
import TasksService from "services/TasksService";
import { EntityId } from "lib/types/entity";
import { TaskPayloadInterface } from "lib/types/tasksTypes";
import { KeyboardKeys } from "lib/types/keyCodes";
import { getTaskLastSortKanban, getTaskLastSortList } from "lib/helpers/taskUtils";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import { captureException } from "lib/utils/sentry";
import { Wrapper, Input, Loader } from "./styled";
import useSavingState from "lib/customHooks/useSavingState";

interface NewCardFormProps {
  laneId: EntityId;
  onCancel: () => void;
}

const NewCardForm: FC<NewCardFormProps> = (props) => {
  const { onCancel, laneId } = props;

  const { board } = useBoardContext() as BoardContextInterface;
  const tasks = useAppSelector(state => state.tasks.tasks);
  const { setSavingStart, setSavingFinish } = useSavingState();

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onCardAdd = () => {
    form.validateFields()
      .then(values => {
        setSavingStart();
        const body: TaskPayloadInterface = {
          board: board.id,
          title: values.title,
          status: laneId,
          sort__list: getTaskLastSortList(tasks),
          sort__kanban: getTaskLastSortKanban(tasks, laneId),
        };

        setIsLoading(true);
        TasksService.addNewTask(body)
          .then(onCancel, () => setIsLoading(false))
          .finally(() => setSavingFinish());
      })
      .catch((error) => {
        captureException(error);
        return onCancel();
      });
  };

  const onCardChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyboardKeys.Escape) {
      event.preventDefault();
      onCancel();
    }

    if (event.key === KeyboardKeys.Tab || event.key === KeyboardKeys.Enter) {
      event.preventDefault();
      onCardAdd();
    }
  };

  return (
    <Wrapper>
      <Form form={form}>
        <Form.Item
          name="title"
          rules={
            [{ required: true, message: "", whitespace: true }]
          }
        >
          <Input
            autoFocus
            placeholder="Task name"
            autoComplete="off"
            onKeyDown={onCardChange}
            onBlur={onCardAdd}
            disabled={isLoading}
          />
        </Form.Item>
        {isLoading && (
          <Loader>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
          </Loader>
        )}
      </Form>
    </Wrapper>
  );
};

export default NewCardForm;
