import HttpService from "./HttpService";
import { UserInterface, UserPreferencesInterface } from "lib/types/types";
import { JSendResponse } from "lib/types/jsend";

class UserService extends HttpService {
  constructor() {
    super("users");
  }

  async getSelf() {
    const data: UserInterface = await this._get({
      additionalUrl: "/self"
    });

    return data || null;
  }

  async update(value: Partial<UserInterface>) {
    const data: JSendResponse = await this._put({
      body: value,
      additionalUrl: "/self"
    });

    return data || null;
  }

  async regenerateApiToken() {
    const data: UserInterface = await this._post({
      additionalUrl: "/self/apikey"
    });

    return data || null;
  }

  async uploadPicture(value: File) {
    const formData = new FormData();
    formData.append("files", value);

    const data: UserInterface = await this._postFormData({
      additionalUrl: "/self/picture",
      body: formData,
    });

    return data || null;
  }

  async removePicture() {
    const data: UserInterface = await this._delete({
      additionalUrl: "/self/picture"
    });

    return data || null;
  }

  async onboarding(code: string, firstName: string, lastName: string, password: string) {
    const data: UserInterface & { jwt: string } = await this._put({
      body: {
        code,
        firstName,
        lastName,
        password
      },
      additionalUrl: "/onboarding"
    });

    return data || null;
  }

  async getUserByTokenCode(code: string) {
    const data: UserInterface = await this._get({
      additionalUrl: `/passwordToken?code=${code}`
    });

    return data || null;
  }

  async updatePreferences(payload: Partial<UserPreferencesInterface>) {
    const data: JSendResponse = await this._put({
      additionalUrl: "/self/preferences",
      body: payload,
    });

    return data;
  }
}

export default new UserService();
