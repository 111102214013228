import styled from "@emotion/styled/macro";
import { SectionSubheading } from "styles/common";

export const PhotoWrapper = styled.div`
  margin-right: 10px;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  align-items: center
`;

export const StyledFileUpload = styled.input`
  display: none;
`;

export const StyledLabel = styled.label`
  color: #5EA7f8;
  font-weight: 600;
  cursor: pointer;
`;

export const ControlsDivider = styled.div`
  margin: 0 4px;
  color: #999;
`;

export const InstructionsWrapper = styled.div`
  font-size: 13px;
  color: #999;
`;

export const LineWrapper = styled.div`
  display: flex;
  margin: 5px 0
`;

export const SettingsNotificationType = styled.div`
  font-weight: 600;
  font-size: 12px;
`;

export const SettingsNotificationDesc = styled.div`
  font-style: italic;
`;

export const SettingsAbout = styled(SectionSubheading)`
`;
