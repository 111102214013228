import { FC } from "react";
import IconSvgWrapper from "./IconSvgWrapper";
import { IconProps } from "./lib";

const MenuLeftSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M5.04001 5.99995L9.20001 1.91995L8.08001 0.799952L2.80001 5.99995L8.08001 11.2L9.20001 10.08L5.04001 5.99995Z" />
    </IconSvgWrapper>
  );
};

export default MenuLeftSvg;
