import styled from "@emotion/styled/macro";

export const FieldTitleWrapper = styled.div`
  margin: 0 8px 0 3px;  
  display: flex;
  align-items: center;
  min-height: 28px;
  //color: #999;
  color: #8f9090;
  font-size: 12px;
  font-weight: 500;
  height: 100%;
`;

export const FieldTitleTextWrapper = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 1px;
`;

export const FieldTitleImg = styled.img`
  margin: 0 8px 0 3px;
`;

export const FieldTitleIcon = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8px 0 3px;
`;
