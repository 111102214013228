import { ReactNode } from "react";
import { message } from "antd";
import { ArgsProps, MessageInstance } from "antd/lib/message";
import { CloseCircleFilled } from "@ant-design/icons";
import { DefaultIconColor } from "components/icons/lib";

const LoadingMessage = {
  DefaultDuration: 3,
  MessageId: "LoadingMessageId",

  show(type: keyof MessageInstance, argProps: ArgsProps, dismissOnClick = false) {
    message[type]({
      ...argProps,
      ...(dismissOnClick && { onClick: () => message.destroy(LoadingMessage.MessageId) }),
      key: LoadingMessage.MessageId,
    });
  },

  close() {
    message.destroy(LoadingMessage.MessageId);
  },

  loading(text?: string) {
    message.loading({
      key: "LoadingMessageLoading",
      content: text || "Making the change...",
      duration: 0,
    });
  },

  complete(text?: string) {
    message.destroy("LoadingMessageLoading");
    message.success({
      key: "LoadingMessageCompleted",
      content: text || "You're good to go!",
      onClick: () => message.destroy("LoadingMessageCompleted"),
      duration: LoadingMessage.DefaultDuration,
    });
  },

  action(content: ReactNode | string) {
    message.destroy("LoadingMessageLoading");
    message.success({
      key: "LoadingMessageCompleted",
      content: content || "You're good to go!",
      duration: LoadingMessage.DefaultDuration,
    });
  },

  error(text?: string) {
    message.destroy("LoadingMessageLoading");
    message.open({
      key: "LoadingMessageError",
      content: text || "Oops! Something went wrong...",
      onClick: () => message.destroy("LoadingMessageError"),
      duration: LoadingMessage.DefaultDuration,
      icon: <CloseCircleFilled style={{ color: DefaultIconColor }} />
    });
  },

  warn(text?: string) {
    message.destroy("LoadingMessageLoading");
    message.warn({
      key: "LoadingMessageError",
      content: text || "Oops! Something went wrong...",
      onClick: () => message.destroy("LoadingMessageError"),
      duration: LoadingMessage.DefaultDuration,
    });
  }
};

export default LoadingMessage;
