import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const Mp3Svg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.5584 20.5167C64.2805 20.5145 62.0963 19.6086 60.4855 17.9978C58.8746 16.387 57.9688 14.203 57.9667 11.925V0H19.575C16.6013 0 13.7494 1.18131 11.6466 3.28406C9.54387 5.38679 8.36255 8.23875 8.36255 11.2125V78.8042C8.36697 81.775 9.55023 84.6225 11.6525 86.7217C13.7548 88.8208 16.6042 90 19.575 90H65.6625C68.6342 90 71.4842 88.8204 73.5867 86.7204C75.6888 84.6204 76.8717 81.7717 76.875 78.8V20.5167H66.5584Z" fill="#FF3E4C"/>
        <path d="M76.8751 20.5167H66.5585C64.2805 20.5145 62.0964 19.6086 60.4855 17.9978C58.8747 16.387 57.9689 14.203 57.9668 11.925V0L76.8751 20.5167Z" fill="#FF3E4C"/>
        <path d="M85.0043 71.0834H32.9043C29.2086 71.0834 26.2126 74.0792 26.2126 77.775V93.3084C26.2126 97.0042 29.2086 100 32.9043 100H85.0043C88.7001 100 91.696 97.0042 91.696 93.3084V77.775C91.696 74.0792 88.7001 71.0834 85.0043 71.0834Z" fill="#FF3E4C"/>
        <path d="M40.0221 93.75C39.4271 93.75 39.1296 93.4525 39.1296 92.8575V80.235C39.1296 79.64 39.4271 79.3425 40.0221 79.3425C40.4117 79.3425 40.7376 79.5338 40.9996 79.9163L46.0146 87.46L45.6321 87.5238L50.6895 79.9163C50.9303 79.5338 51.2916 79.3425 51.7732 79.3425C52.2549 79.3425 52.4957 79.64 52.4957 80.235V92.8575C52.4957 93.4525 52.1982 93.75 51.6032 93.75C51.0082 93.75 50.7107 93.4525 50.7107 92.8575V82.02L51.2632 82.2113L46.8009 88.7775C46.5388 89.16 46.1776 89.3513 45.7171 89.3513C45.3984 89.3513 45.108 89.16 44.8459 88.7775L40.3834 82.2113L40.9146 82.36V92.8575C40.9146 93.4525 40.6171 93.75 40.0221 93.75Z" fill="white"/>
        <path d="M57.8066 93.75C57.2116 93.75 56.9141 93.4525 56.9141 92.8575V80.235C56.9141 79.64 57.2116 79.3425 57.8066 79.3425H62.7791C64.0753 79.3425 65.067 79.6896 65.7541 80.3838C66.4482 81.0709 66.7953 82.0625 66.7953 83.3588V83.9963C66.7953 85.2925 66.4482 86.2875 65.7541 86.9817C65.067 87.6688 64.0753 88.0125 62.7791 88.0125H58.6991V92.8575C58.6991 93.4525 58.4016 93.75 57.8066 93.75ZM58.6991 86.2275H62.7791C63.5866 86.2275 64.1603 86.0575 64.5003 85.7175C64.8403 85.3775 65.0103 84.8038 65.0103 83.9963V83.3588C65.0103 82.5584 64.8403 81.9879 64.5003 81.6479C64.1603 81.3009 63.5866 81.1275 62.7791 81.1275H58.6991V86.2275Z" fill="white"/>
        <path d="M72.5541 93.75C71.3145 93.75 70.3549 93.4913 69.6749 92.9742C69.002 92.45 68.5945 91.6463 68.4528 90.5625C68.4245 90.265 68.4882 90.0346 68.6441 89.8717C68.7999 89.7088 69.0266 89.6275 69.3241 89.6275C69.8695 89.6275 70.1741 89.925 70.2378 90.52C70.337 91.0725 70.5603 91.4513 70.9074 91.6567C71.2545 91.8621 71.8032 91.965 72.5541 91.965H74.9766C75.8903 91.965 76.5103 91.8021 76.8362 91.4763C77.1691 91.1434 77.3353 90.5271 77.3353 89.6275C77.3353 88.735 77.1691 88.1221 76.8362 87.7892C76.5103 87.4563 75.8903 87.29 74.9766 87.29H73.3403C72.7453 87.29 72.4478 86.9925 72.4478 86.3975C72.4478 85.8025 72.7453 85.505 73.3403 85.505H75.7841C76.2303 85.505 76.542 85.3067 76.7191 84.91C76.9032 84.5063 76.9953 83.9609 76.9953 83.2738C76.9953 82.4309 76.8432 81.8642 76.5387 81.5738C76.2412 81.2763 75.6778 81.1275 74.8491 81.1275H72.7878C72.122 81.1275 71.6299 81.2159 71.3112 81.3929C70.9924 81.57 70.7903 81.8996 70.7053 82.3813C70.6203 82.9621 70.3087 83.2525 69.7703 83.2525C69.4728 83.2525 69.2462 83.1746 69.0903 83.0188C68.9345 82.8559 68.8778 82.6221 68.9203 82.3175C69.062 81.3188 69.4516 80.575 70.0891 80.0863C70.7337 79.5904 71.6332 79.3425 72.7878 79.3425H74.8491C76.1807 79.3425 77.1691 79.6646 77.8137 80.3092C78.4582 80.9538 78.7803 81.9421 78.7803 83.2738C78.7803 84.5842 78.412 85.5121 77.6753 86.0575C78.1641 86.3763 78.5253 86.8296 78.7591 87.4175C78.9999 88.0054 79.1203 88.7421 79.1203 89.6275C79.1203 91.03 78.7841 92.0675 78.1112 92.7404C77.4382 93.4134 76.3932 93.75 74.9766 93.75H72.5541Z" fill="white"/>
        <path d="M58.4584 54.9666H53.8959V36.525C53.8959 35.9073 53.6505 35.3148 53.2138 34.878C52.7767 34.4412 52.1846 34.1958 51.5667 34.1958H39.9001C39.5942 34.1958 39.2913 34.2561 39.0087 34.3731C38.7261 34.4902 38.4693 34.6618 38.2531 34.878C38.0368 35.0943 37.8652 35.3511 37.7482 35.6337C37.6311 35.9163 37.5709 36.2191 37.5709 36.525V54.9666H32.9875V34.9667C32.9875 34.2648 33.1259 33.5699 33.3946 32.9215C33.6633 32.2732 34.0571 31.6841 34.5535 31.188C35.05 30.692 35.6394 30.2986 36.2879 30.0304C36.9365 29.7622 37.6315 29.6245 38.3334 29.625H53.1251C54.5417 29.625 55.9005 30.1878 56.9021 31.1895C57.9038 32.1913 58.4667 33.55 58.4667 34.9667L58.4584 54.9666Z" fill="#FF3E4C"/>
        <path d="M53.0499 60.375C56.037 60.375 58.4583 57.9538 58.4583 54.9667C58.4583 51.9796 56.037 49.5583 53.0499 49.5583C50.0629 49.5583 47.6416 51.9796 47.6416 54.9667C47.6416 57.9538 50.0629 60.375 53.0499 60.375Z" fill="#FF3E4C"/>
        <path d="M32.1874 60.375C35.1743 60.375 37.5957 57.9538 37.5957 54.9667C37.5957 51.9796 35.1743 49.5583 32.1874 49.5583C29.2005 49.5583 26.7791 51.9796 26.7791 54.9667C26.7791 57.9538 29.2005 60.375 32.1874 60.375Z" fill="#FF3E4C"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default Mp3Svg;
