import { FC } from "react";

import { TaskFieldSetterGetter } from "lib/types/applicationTypes";
import { FieldEmptyState } from "../../styled";
import styled from "@emotion/styled";
import { TaskPropertyDateTimeFormat, TaskPropertyTimeFormat } from "lib/types/kanbanTypes";
import { formatDateTimeString } from "lib/helpers/dateUtils";

const InnerText = styled.div`
  white-space: nowrap;
  color: #999;
`;

interface DateTimeGenericProps extends TaskFieldSetterGetter {
  dateFormat?: TaskPropertyDateTimeFormat;
  timeFormat?: TaskPropertyTimeFormat;
}

/**
 * @description Use this component to render system datetime fields only (e.g. `created_at`, `updated_at`)
 */
const DateTimeGeneric: FC<DateTimeGenericProps> = (props) => {
  const { value } = props;

  const stringValue = formatDateTimeString(value);

  if (!value) {
    return (
      <>
        <FieldEmptyState>
          Empty
        </FieldEmptyState>
      </>
    );
  }

  return (
    <InnerText>
      {stringValue}
    </InnerText>
  );
};

export default DateTimeGeneric;
