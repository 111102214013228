import { FC } from "react";

import { useBoardContext } from "lib/contexts/BoardContext";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import { MemberRoles } from "lib/types/types";
import { SectionSubheading } from "styles/common";
import { BoardDefaultDisplayView, BoardDisplayViews } from "./lib";
import BoardSettingsDisplayViewItem from "./BoardSettingsDisplayViewItem";

import {
  BoardSettingsViewsGrid
} from "pages/Board/BoardSettings/BoardSettingsDisplayView/styled";

const BoardSettingsDisplayView: FC = () => {
  const { board } = useBoardContext();
  const allowedToEdit = useMemberPermission([MemberRoles.Owner, MemberRoles.Admin]);

  const initialValues = board?.displayView || BoardDefaultDisplayView;

  return (
    <>
      {allowedToEdit &&
        <>
          <SectionSubheading>Views</SectionSubheading>
          <BoardSettingsViewsGrid>
            {BoardDisplayViews.map((displayView) => (
              <BoardSettingsDisplayViewItem
                key={displayView.code}
                boardDisplayView={displayView}
                initialValues={initialValues}
                editable={allowedToEdit}
              />
            ))}
          </BoardSettingsViewsGrid>
        </>
      }
    </>
  );
};

export default BoardSettingsDisplayView;
