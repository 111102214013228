import { Switch } from "antd";

import { DefaultColumnCodes } from "lib/types/boardTypes";
import { EntityId } from "lib/types/entity";
import { Column } from "lib/types/tasksTypes";
import { FC, useEffect, useState } from "react";

import { FieldTitle, FieldWrapper } from "./styled";

export interface FieldComponentProps {
  column: Column;
  updateVisibility: (columnId: EntityId, hidden: boolean) => void;
}

const FieldComponent: FC<FieldComponentProps> = (props) => {
  const { column, updateVisibility } = props;
  const { id, title, code, hidden } = column;
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setVisible(!hidden);
  }, [hidden]);

  const handleSwitchOnClick = (checked: boolean) => {
    setVisible(checked);
    updateVisibility(id, !checked);
  };

  return (
    <FieldWrapper>
      <FieldTitle>
        <img src={process.env.PUBLIC_URL + "/icons/drag.svg"} alt="drag" style={{ marginRight: "7px" }} />
        {title}
      </FieldTitle>
      <Switch
        size="small"
        checked={visible}
        onClick={handleSwitchOnClick}
        disabled={code === DefaultColumnCodes.Title}
      />
    </FieldWrapper>
  );
};

export default FieldComponent;
