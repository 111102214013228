import { unsetTask } from "app/slices/currentTaskSlice";
import { useAppDispatch } from "app/store";
import { TaskInterface } from "lib/types/tasksTypes";
import { WsEvents } from "lib/types/wsTypes";
import { useCallback } from "react";
import useWSSubscription from "../useWSSubscription";

const useWSDeleteTaskDetailed = (afterDeleteCallback?: (data: TaskInterface) => void) => {
  const dispatch = useAppDispatch();

  const callback = useCallback((data: TaskInterface) => {
    dispatch(unsetTask(data));
    if (afterDeleteCallback) {
      afterDeleteCallback(data);
    }
  }, [afterDeleteCallback, dispatch]);

  useWSSubscription<TaskInterface>({
    eventName: WsEvents.Task.Delete,
    callback,
    fnKey: "useWSDeleteTaskDetailed"
  });
};

export default useWSDeleteTaskDetailed;
