import styled from "@emotion/styled/macro";

export const TitleControls = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  position: relative;
  padding: 24px;
`;

export const CommentSection = styled.div`
  margin-top: 10px;
`;

export const TaskSection = styled.div`
  height: 100%;
`;

export const CollapseWrapper = styled.div`
  margin: 0 -16px;
  .ant-collapse-header {
    color: #999 !important;
    font-weight: 600 !important;
  }
`;
