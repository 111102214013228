import ButtonIcon from "components/ui/ButtonIcon";
import { DateFormats } from "lib/theme/lib";
import moment from "moment";
import { FC, useState } from "react";
import {
  RecordTemplateItemCard,
  RecordTemplateItemContent,
  RecordTemplateItemDate,
  RecordTemplateItemTitle,
  RecordTemplateMoreContainer
} from "./styled";
import RecordTemplateModal from "components/blocks/RecordTemplates/RecordTemplateDrawer/RecordTemplateModal";
import { RecordTemplateInterface } from "lib/types/boardTypes";

interface RecordTemplateProps {
  item: RecordTemplateInterface;
}

const RecordTemplateItem: FC<RecordTemplateProps> = (props) => {
  const { item } = props;
  const [isShowModal, setIsShowModal] = useState(false);
  const displayDate = moment(item.createdAt).format(DateFormats.Friendly);

  return (
    <>
      <RecordTemplateItemCard>
        <RecordTemplateItemContent>
          <RecordTemplateItemTitle>
            {(item.title ? item.title : "Unnamed template")}
          </RecordTemplateItemTitle>
          <RecordTemplateItemDate>{`Created on ${displayDate}`}</RecordTemplateItemDate>
        </RecordTemplateItemContent>
        <RecordTemplateMoreContainer onClick={() => setIsShowModal(true)}>
          <ButtonIcon iconUrl="/icons/edit.svg" />
        </RecordTemplateMoreContainer>
      </RecordTemplateItemCard>
      {isShowModal &&
        <RecordTemplateModal
          template={item}
          visible={isShowModal}
          onClose={() => setIsShowModal(false)}
        />
      }
    </>
  );
};

export default RecordTemplateItem;
