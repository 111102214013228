import { pick } from "lodash";
import { BoardStatusInterface } from "lib/types/boardTypes";
import { EntityId } from "lib/types/entity";
import { StatusInterface } from "lib/types/kanbanTypes";
import { captureException } from "lib/utils/sentry";
import StatusesService from "services/StatusesService";
import useBoardPerformRequest, { BoardPerformRequestHookProps } from "./useBoardPerformRequest";

const useBoardSetStatuses = (props: BoardPerformRequestHookProps) => {
  const { board } = props;
  const performRequest = useBoardPerformRequest(props);

  const updateBoardStatus = (statusId: EntityId, payload: Partial<StatusInterface>) => {
    if (!board) {
      return;
    }

    const statusIndex = board.statuses.findIndex(status => String(status.id) === String(statusId));
    if (statusIndex === -1) {
      captureException(`Cannot find status ${statusId}`);
      return;
    }

    const statuses = Array.from(board.statuses);
    const status = statuses[statusIndex];
    statuses[statusIndex] = { ...status, ...payload };

    const requestFn = () => StatusesService.modifyStatus(board.id, statusId, payload);

    return performRequest({ statuses }, requestFn);
  };

  const deleteBoardStatus = (statusId: EntityId) => {
    if (!board) {
      return;
    }

    const statusIndex = board.statuses.findIndex(status => String(status.id) === String(statusId));
    if (statusIndex === -1) {
      captureException(`Cannot find status ${statusId}`);
      return;
    }

    const statuses = Array.from(board.statuses);
    statuses.splice(statusIndex, 1);

    const requestFn = () => StatusesService.deleteStatus(board.id, statusId);

    return performRequest({ statuses }, requestFn);
  };

  const reorderBoardStatuses = (data: Array<BoardStatusInterface>) => {
    if (!board) {
      return;
    }

    const payload = data.map(item => pick(item, ["id", "sort"]));
    const requestFn = () => StatusesService.reorderStatuses(board.id, payload);

    return performRequest({ statuses: data }, requestFn);
  };

  return { updateBoardStatus, deleteBoardStatus, reorderBoardStatuses };
};

export default useBoardSetStatuses;
