import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";

interface WrapperProps {
  noChildren?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  ${({ noChildren }) => noChildren && css`
    width: 0;
    height: 0;
  `}
`;
