import React, { FC, RefObject, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { DatePicker, Form } from "antd";
import { PickerProps } from "antd/lib/date-picker/generatePicker";
import moment, { Moment } from "moment";

import { FormComponentProps } from "../lib";
import TimePickerCustom from "components/blocks/TaskFields/Components/Date/Display/TimePickerCustom";
import DateValue from "components/blocks/TaskFields/Components/Date/Display/DateValue";
import { FieldEmptyState } from "components/blocks/TaskFields/Components/styled";
import { DateMasksMap } from "lib/helpers/dateUtils";
import { DateFormats, InputPlaceholders } from "lib/theme/lib";
import { TaskPropertyDateTimeFormat, TaskPropertyTimeFormat } from "lib/types/kanbanTypes";
import useUpdateEffect from "lib/customHooks/useUpdateEffect";
import { DatePickerHidden } from "pages/Board/BoardKanban/Card/Calendar/styled";
import {
  DateTimePickerFieldInnerContainer,
  DateTimePickerFieldOuterContainer
} from "components/blocks/FormComponents/DateTimePickerField/styled";

interface DateTimePickerProps extends FormComponentProps {
  dateFormat: TaskPropertyDateTimeFormat;
  timeFormat: TaskPropertyTimeFormat;
}

const DateTimePickerField: FC<DateTimePickerProps> = (props) => {
  const { fieldName, dateFormat, timeFormat, style } = props;
  const form = Form.useFormInstance();

  const dateMask = (dateFormat) ? DateMasksMap[dateFormat] : DateFormats.Friendly;

  const formValue = form.getFieldValue(fieldName);
  const initialValue = formValue ? moment(formValue) : null;

  const [dateTimeValue, setDateTimeValue] = useState(initialValue);
  const [isDateOpened, setIsDateOpened] = useState(false);

  const wrapperRef = useRef(null) as RefObject<any>;
  const selectDropdownParent = (wrapperRef?.current) ? wrapperRef.current.offsetParent : wrapperRef.current;
  const fallbackDate = moment({ h: 0, m: 0, s: 0 });

  useUpdateEffect(() => {
    if (!isDateOpened) {
      form.setFields([{ name: fieldName, value: dateTimeValue?.toISOString() }]);
    }
  }, [isDateOpened]);

  const onCalendarOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    const dropdownElement = (e.target as HTMLElement).closest(".ant-picker-dropdown");

    if (!dropdownElement) {
      setIsDateOpened(!isDateOpened);
    }
  };

  const handleOnOutsideClick = (e: MouseEvent) => {
    const dropdownElement = (e.target as HTMLElement).closest(".ant-picker-dropdown");

    if (dropdownElement) {
      return null;
    }

    if (selectDropdownParent && !selectDropdownParent.contains(e.target)) {
      return setIsDateOpened(false);
    }

    return setIsDateOpened(false);
  };

  const handleCalendarOnChange = (value: moment.Moment | null, dateString: string) => {
    setDate(value);
    if (timeFormat === TaskPropertyTimeFormat.None) {
      setIsDateOpened(false);
    }
  };

  const setDateNull = (event: React.MouseEvent) => {
    event.stopPropagation();

    setDateTimeValue(null);
    form.setFields([{ name: fieldName, value: null }]);
    setIsDateOpened(false);
  };

  const setDate = (momentObject: Moment | null) => {
    if (momentObject) {
      const nextState = dateTimeValue?.clone() || fallbackDate;
      nextState?.year(momentObject?.year());
      nextState?.month(momentObject?.month());
      nextState?.date(momentObject?.date());

      setDateTimeValue(nextState);
    }
  };

  const setTime = (momentObject: Moment | null) => {
    if (momentObject) {
      const nextState = dateTimeValue?.clone() || fallbackDate;
      nextState?.hour(momentObject?.hour());
      nextState?.minute(momentObject?.minute());
      nextState?.second(momentObject?.second());

      setDateTimeValue(nextState);
    }
  };

  const calendarPickerProps: PickerProps<Moment> = {
    ...((timeFormat && dateFormat && timeFormat !== TaskPropertyTimeFormat.None) && {
      renderExtraFooter: (mode) => (
        <TimePickerCustom
          value={dateTimeValue}
          saveTime={setTime}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
        />
      )
    })
  };

  return (
    <DateTimePickerFieldOuterContainer onClick={onCalendarOpen} ref={wrapperRef}>
      <OutsideClickHandler onOutsideClick={handleOnOutsideClick}>
        <DatePickerHidden>
          <DatePicker
            {...calendarPickerProps}
            open={isDateOpened}
            value={dateTimeValue}
            onChange={handleCalendarOnChange}
            onOk={() => setIsDateOpened(false)}
            getPopupContainer={() => selectDropdownParent}
            format={dateMask}
            placeholder={InputPlaceholders.Empty}
            style={style}
          />
        </DatePickerHidden>
        <DateTimePickerFieldInnerContainer>
          {!dateTimeValue &&
            <FieldEmptyState>Empty</FieldEmptyState>
          }
          {dateTimeValue &&
            <DateValue
              dateValue={dateTimeValue}
              dateFormat={dateFormat}
              timeFormat={timeFormat}
              setDateNull={setDateNull}
              disableColors={true}
            />
          }
        </DateTimePickerFieldInnerContainer>
      </OutsideClickHandler>
    </DateTimePickerFieldOuterContainer>
  );
};

export default DateTimePickerField;
