import { FC } from "react";
import IconSvgWrapper from "./IconSvgWrapper";
import { IconProps } from "./lib";

const AssigneesSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M4.17162 5.572C4.62376 5.572 5.06576 5.43792 5.44169 5.18671C5.81763 4.9355 6.11064 4.57846 6.28365 4.16072C6.45666 3.74299 6.5019 3.28333 6.41366 2.83988C6.32543 2.39643 6.10767 1.9891 5.78792 1.66941C5.46818 1.34973 5.06082 1.13204 4.61735 1.04388C4.17388 0.955717 3.71423 1.00104 3.29653 1.17413C2.87882 1.34721 2.52183 1.64027 2.27069 2.01626C2.01955 2.39224 1.88554 2.83426 1.88562 3.2864C1.88626 3.89245 2.12732 4.4735 2.5559 4.902C2.98448 5.33051 3.56557 5.57147 4.17162 5.572Z"/>
      <path d="M7.162 7.46031C6.58273 6.86615 5.83949 6.45814 5.02722 6.28842C4.21495 6.1187 3.37052 6.19497 2.60181 6.50748C1.83309 6.81999 1.17498 7.35456 0.711543 8.0429C0.248104 8.73123 0.000374954 9.5421 0 10.3719C0 10.478 0.0421427 10.5797 0.117157 10.6548C0.192172 10.7298 0.293913 10.7719 0.4 10.7719H7.944C8.05008 10.7719 8.15183 10.7298 8.22684 10.6548C8.30185 10.5797 8.344 10.478 8.344 10.3719C8.34555 9.94776 8.28007 9.52603 8.15 9.12231C7.95632 8.49816 7.61777 7.92866 7.162 7.46031Z"/>
      <path d="M8.99999 5.77182C9.93887 5.77182 10.7 5.01071 10.7 4.07183C10.7 3.13294 9.93887 2.37183 8.99999 2.37183C8.0611 2.37183 7.29999 3.13294 7.29999 4.07183C7.29999 5.01071 8.0611 5.77182 8.99999 5.77182Z"/>
      <path d="M9 6.23975C8.46382 6.24176 7.93803 6.38778 7.4776 6.66255C7.5644 6.74015 7.652 6.81655 7.734 6.90055C8.27865 7.46037 8.68325 8.14101 8.9148 8.88695C8.9941 9.13252 9.05258 9.38435 9.0896 9.63974H11.6C11.7061 9.63974 11.8078 9.5976 11.8828 9.52259C11.9579 9.44757 12 9.34583 12 9.23975C11.9992 8.44436 11.6828 7.68179 11.1204 7.11936C10.558 6.55694 9.79539 6.24059 9 6.23975Z"/>
    </IconSvgWrapper>
  );
};

export default AssigneesSvg;
