export const getTokenFromStorage = () => {
  let tokenFromStorage = "";
  if (localStorage.getItem("jwt")) {
    tokenFromStorage = JSON.parse(localStorage.getItem("jwt") as string);
  }

  return tokenFromStorage;
};

export const removeTokenFromStorage = () => {
  if (localStorage.getItem("jwt")) {
    localStorage.removeItem("jwt");
  }
};

export const getKeyByValueFromEnum = (enumItem: any, value: number | string): string | undefined => {
  return Object.keys(enumItem)[Object.values(enumItem).indexOf(value)];
};
