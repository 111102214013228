import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const DocSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.5476 20.4357C64.2792 20.4335 62.1041 19.5313 60.4999 17.9272C58.8958 16.3231 57.9937 14.1481 57.9916 11.8796V7.71176e-07H19.7634C18.2967 -0.000544262 16.8443 0.28782 15.4891 0.848718C14.1339 1.40962 12.9025 2.23204 11.8652 3.26895C10.8279 4.30585 10.0051 5.53697 9.44365 6.89195C8.88225 8.24693 8.59326 9.69925 8.59326 11.1659V78.4772C8.59326 79.944 8.88225 81.3963 9.44365 82.751C10.0051 84.1062 10.8279 85.3374 11.8652 86.3743C12.9025 87.4112 14.1339 88.2336 15.4891 88.7942C16.8443 89.3552 18.2967 89.6436 19.7634 89.6432H65.6555C67.1219 89.6432 68.5738 89.3544 69.9285 88.7934C71.2833 88.232 72.514 87.4096 73.551 86.3726C74.5879 85.3357 75.4103 84.105 75.9717 82.7502C76.5327 81.3954 76.8215 79.9436 76.8215 78.4772V20.4357H66.5476Z" fill="#0072FF"/>
        <path d="M76.8216 20.4357H66.5477C64.2793 20.4335 62.1041 19.5313 60.5 17.9272C58.8958 16.3231 57.9938 14.1481 57.9917 11.8796V0L76.8216 20.4357Z" fill="#0072FF"/>
        <path d="M62.7841 34.7427H19.9459C19.5343 34.7427 19.1396 34.5791 18.8486 34.2881C18.5575 33.997 18.394 33.6024 18.394 33.1908C18.3935 32.9866 18.4333 32.7844 18.511 32.5956C18.5887 32.4069 18.703 32.2353 18.8471 32.0907C18.9913 31.9462 19.1626 31.8315 19.3511 31.7533C19.5396 31.6751 19.7418 31.6348 19.9459 31.6348H62.7841C63.197 31.6348 63.5924 31.7987 63.8845 32.0905C64.1762 32.3823 64.3401 32.7781 64.3401 33.1908C64.3389 33.6027 64.1745 33.9975 63.8828 34.2884C63.5911 34.5793 63.1961 34.7427 62.7841 34.7427Z" fill="#0072FF"/>
        <path d="M62.7841 44.0248H19.9459C19.5343 44.0248 19.1396 43.8613 18.8486 43.5705C18.5575 43.2792 18.394 42.8846 18.394 42.473C18.3935 42.2688 18.4333 42.0667 18.511 41.8779C18.5887 41.6892 18.703 41.5174 18.8471 41.373C18.9913 41.2284 19.1626 41.1138 19.3511 41.0355C19.5396 40.9573 19.7418 40.917 19.9459 40.917H62.7841C63.197 40.917 63.5924 41.0809 63.8845 41.3727C64.1762 41.6647 64.3401 42.0605 64.3401 42.473C64.3389 42.885 64.1745 43.2796 63.8828 43.5705C63.5911 43.8613 63.1961 44.0248 62.7841 44.0248Z" fill="#0072FF"/>
        <path d="M62.7841 53.3071H19.9459C19.7418 53.3071 19.5396 53.2669 19.3511 53.1884C19.1626 53.1104 18.9913 52.9955 18.8471 52.8511C18.703 52.7067 18.5887 52.5349 18.511 52.3461C18.4333 52.1573 18.3935 51.9552 18.394 51.7511C18.394 51.3395 18.5575 50.9449 18.8486 50.6536C19.1396 50.3627 19.5343 50.1992 19.9459 50.1992H62.7841C63.1961 50.1992 63.5911 50.3627 63.8828 50.6536C64.1745 50.9444 64.3389 51.3391 64.3401 51.7511C64.3401 52.1639 64.1762 52.5594 63.8845 52.8515C63.5924 53.1432 63.197 53.3071 62.7841 53.3071Z" fill="#0072FF"/>
        <path d="M45.8878 62.5893H19.9459C19.7418 62.5893 19.5396 62.5491 19.3511 62.4707C19.1626 62.3926 18.9913 62.2777 18.8471 62.1333C18.703 61.9889 18.5887 61.8171 18.511 61.6283C18.4333 61.4399 18.3935 61.2375 18.394 61.0333C18.394 60.6217 18.5575 60.2271 18.8486 59.9358C19.1396 59.6449 19.5343 59.4814 19.9459 59.4814H45.8878C46.2994 59.4814 46.6941 59.6449 46.9852 59.9358C47.2762 60.2271 47.4397 60.6217 47.4397 61.0333C47.4402 61.2375 47.4005 61.4399 47.3228 61.6283C47.245 61.8171 47.1308 61.9889 46.9867 62.1333C46.8425 62.2777 46.6712 62.3926 46.4827 62.4707C46.2941 62.5491 46.092 62.5893 45.8878 62.5893Z" fill="#0072FF"/>
        <path d="M84.921 70.7925H33.0371C29.3568 70.7925 26.3733 73.7759 26.3733 77.4564V92.9253C26.3733 96.6058 29.3568 99.5892 33.0371 99.5892H84.921C88.6015 99.5892 91.5849 96.6058 91.5849 92.9253V77.4564C91.5849 73.7759 88.6015 70.7925 84.921 70.7925Z" fill="#0072FF"/>
        <path d="M39.3197 93.3609C38.7272 93.3609 38.4309 93.0646 38.4309 92.4721V79.902C38.4309 79.3094 38.7272 79.0132 39.3197 79.0132H44.4197C46.2608 79.0132 47.6151 79.4505 48.4828 80.3252C49.3575 81.1929 49.7946 82.5472 49.7946 84.3883V87.9858C49.7946 89.8269 49.361 91.185 48.4934 92.0597C47.6328 92.9273 46.2749 93.3609 44.4197 93.3609H39.3197ZM40.2085 91.5833H44.4197C45.3226 91.5833 46.0351 91.4742 46.557 91.2555C47.0861 91.0298 47.46 90.6559 47.6786 90.1339C47.9044 89.6049 48.0172 88.8887 48.0172 87.9858V84.3883C48.0172 83.4924 47.9044 82.7837 47.6786 82.2617C47.46 81.7327 47.0861 81.3551 46.557 81.1294C46.0351 80.9036 45.3226 80.7908 44.4197 80.7908H40.2085V91.5833Z" fill="white"/>
        <path d="M57.9146 93.361C56.2992 93.361 55.1038 92.9768 54.3278 92.2079C53.559 91.432 53.1743 90.2361 53.1743 88.6208V83.7324C53.1743 82.1029 53.5623 80.9075 54.3382 80.1457C55.1142 79.3768 56.3063 78.9992 57.9146 79.0133H60.454C62.0764 79.0133 63.2722 79.398 64.0411 80.1664C64.81 80.9357 65.1942 82.1312 65.1942 83.7536V88.6208C65.1942 90.2361 64.81 91.432 64.0411 92.2079C63.2722 92.9768 62.0764 93.361 60.454 93.361H57.9146ZM57.9146 91.5834H60.454C61.2088 91.5834 61.8013 91.4955 62.2316 91.3191C62.6619 91.1357 62.9652 90.8287 63.1415 90.3984C63.3249 89.9681 63.4166 89.3756 63.4166 88.6208V83.7536C63.4166 83.0059 63.3249 82.4171 63.1415 81.9868C62.9652 81.5565 62.6619 81.2494 62.2316 81.066C61.8013 80.8826 61.2088 80.7909 60.454 80.7909H57.9146C57.1668 80.7839 56.5781 80.8685 56.1478 81.0449C55.7175 81.2212 55.4104 81.5245 55.227 81.9548C55.0436 82.3851 54.9519 82.9776 54.9519 83.7324V88.6208C54.9519 89.3756 55.0403 89.9681 55.2166 90.3984C55.4 90.8287 55.7067 91.1357 56.137 91.3191C56.5673 91.4955 57.1598 91.5834 57.9146 91.5834Z" fill="white"/>
        <path d="M73.3108 93.361C71.6955 93.361 70.4996 92.9768 69.7237 92.2079C68.9548 91.432 68.5706 90.2361 68.5706 88.6208V83.7536C68.5706 82.1241 68.9548 80.9249 69.7237 80.1561C70.4925 79.3801 71.6813 78.9992 73.2896 79.0133H75.8502C77.1764 79.0133 78.1884 79.2884 78.8867 79.8386C79.5851 80.3818 79.9909 81.2424 80.1038 82.4204C80.1461 82.7166 80.0896 82.9424 79.9345 83.0976C79.7793 83.2527 79.5535 83.3303 79.2573 83.3303C78.7212 83.3303 78.4108 83.0341 78.3262 82.4415C78.2556 81.7926 78.0299 81.3552 77.649 81.1295C77.2751 80.9038 76.6755 80.7909 75.8502 80.7909H73.2896C72.5419 80.7839 71.9527 80.8685 71.5224 81.0449C71.0992 81.2212 70.7959 81.5283 70.6125 81.9656C70.4361 82.3959 70.3481 82.9917 70.3481 83.7536V88.6208C70.3481 89.3756 70.4361 89.9681 70.6125 90.3984C70.7959 90.8287 71.1029 91.1357 71.5332 91.3191C71.9635 91.4955 72.556 91.5834 73.3108 91.5834H75.8502C76.6755 91.5834 77.2751 91.4706 77.649 91.2449C78.0299 91.0121 78.2556 90.5747 78.3262 89.9328C78.4108 89.3403 78.7212 89.044 79.2573 89.044C79.5535 89.044 79.7793 89.1216 79.9345 89.2768C80.0896 89.432 80.1461 89.6577 80.1038 89.954C79.9909 91.139 79.5851 92.0034 78.8867 92.5465C78.1884 93.0897 77.1764 93.361 75.8502 93.361H73.3108Z" fill="white"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default DocSvg;
