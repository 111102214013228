import { ForgeViewerSectionModeCaps } from "components/blocks/ForgeViewer/extensions/Autodesk/Section";
import { ForgeViewerState, ForgeViewerStateCutPlanes, ForgeViewerStateViewport } from "lib/types/ForgeViewer";
import NavisworksSchema from "lib/types/NavisworksSchema";
import { ViewpointInterfaceLocal } from "lib/types/tasksTypes";

export class NavisworksViewpointParser {
  private offsetMatrix: THREE.Matrix4;
  private globalOffset: THREE.Vector3;

  constructor(private viewer: Autodesk.Viewing.Viewer3D) {
    this.offsetMatrix = this.viewer?.model?.getInversePlacementWithOffset() || new THREE.Matrix4();
    this.globalOffset = this.viewer?.model?.getData().globalOffset;
  }

  public async parseSingleTask(file: File) {
    const forgeViewpoints: Array<ForgeViewerState> = [];
    const viewpointNodes = await this.extractViewpointsNode(file);
    const viewNodes = viewpointNodes.getElementsByTagName("view");

    Array.from(viewNodes).forEach(viewNode => {
      const viewpoint = this.parseViewNode(viewNode);
      forgeViewpoints.push(viewpoint);
    });

    return forgeViewpoints;
  }

  public async parseMultipleTasks(file: File) {
    const forgeViewpoints: Array<{ title: string, viewpoints: ViewpointInterfaceLocal[] }> = [];
    const viewpointNodes = await this.extractViewpointsNode(file);

    Array.from(viewpointNodes.children).forEach((element) => {
      if (element.nodeName === "viewfolder") {
        const name = element.getAttribute("name") || "";
        const forgeViewpointSet = this.parseViewFolderNode(element);

        forgeViewpoints.push({
          title: name,
          viewpoints: forgeViewpointSet
        });
      }
    });

    return forgeViewpoints;
  }

  private async extractViewpointsNode(file: File) {
    const xmlString = await file.text();
    const doc = new DOMParser().parseFromString(xmlString, "text/xml");

    const viewpointNodes = doc.getElementsByTagName("viewpoints")[0];
    return viewpointNodes;
  }

  private parseViewNode(element: Element) {
    const viewerStateCutplanes: Array<number[]> = [];
    let sectionMode: ForgeViewerSectionModeCaps | null = null;

    const viewpoint = element.getElementsByTagName("viewpoint")[0];
    const clipplaneset = element.getElementsByTagName("clipplaneset")[0];
    const comments = element.getElementsByTagName("comments")[0];
    const redlines = element.getElementsByTagName("redlines")[0];

    const clipPlaneEnabled = clipplaneset.getAttribute("enabled");
    const clipPlaneModeType = clipplaneset.getAttribute("mode");

    if (clipPlaneEnabled === "1") {

      if (clipPlaneModeType === NavisworksSchema.clipPlaneModeType.Planes) {
        sectionMode = null;
        const clipplanes = clipplaneset.getElementsByTagName("clipplane");
        const activeClipPlanes = Array.from(clipplanes).filter(clipplane => clipplane.getAttribute("state") === "enabled");
        if (activeClipPlanes.length === 1) {
          const cpalignment = activeClipPlanes[0].getAttribute("alignment");
          switch (cpalignment) {
            case "top":
            case "bottom":
              sectionMode = "Z";
              break;
            case "front":
            case "back":
              sectionMode = "Y";
              break;
            case "left":
            case "right":
              sectionMode = "X";
              break;
            default:
              break;
          }
        }

        Array.from(clipplanes).forEach(clipplane => {
          const clipplaneState = clipplane.getAttribute("state");

          if (clipplaneState === NavisworksSchema.clipPlaneStateType.Enabled) {
            const clipPos = clipplane.getElementsByTagName("vec3f")[0];
            const plane = clipplane.getElementsByTagName("plane")[0];

            const planeDistance = Number(plane.getAttribute("distance"));

            const c1 = Number(clipPos.getAttribute("x"));
            const c2 = Number(clipPos.getAttribute("y"));
            const c3 = Number(clipPos.getAttribute("z"));

            const clipOffset = (this.globalOffset.x * c1 + this.globalOffset.y * c2 + this.globalOffset.z * c3) - planeDistance;

            const cutplane = new THREE.Vector4(-c1, -c2, -c3, -clipOffset);
            viewerStateCutplanes.push(cutplane.toArray());
          }
        });
      }

      if (clipPlaneModeType === NavisworksSchema.clipPlaneModeType.Box) {
        sectionMode = "BOX";
        const boxNode = clipplaneset.getElementsByTagName("box")[0];
        const boxRotation = clipplaneset.getElementsByTagName("box-rotation")[0];

        const boxPosMin = boxNode.getElementsByTagName("min")[0];
        const boxPosMax = boxNode.getElementsByTagName("max")[0];

        const boxPos3fMin = boxPosMin.getElementsByTagName("pos3f")[0];
        const boxPos3fMax = boxPosMax.getElementsByTagName("pos3f")[0];

        const minPt = new THREE.Vector3(
          Number(boxPos3fMin.getAttribute("x")),
          Number(boxPos3fMin.getAttribute("y")),
          Number(boxPos3fMin.getAttribute("z")),
        );
        const maxPt = new THREE.Vector3(
          Number(boxPos3fMax.getAttribute("x")),
          Number(boxPos3fMax.getAttribute("y")),
          Number(boxPos3fMax.getAttribute("z")),
        );

        const boxQuaternion = boxRotation.getElementsByTagName("quaternion")[0];
        const boxQN = new THREE.Quaternion(
          Number(boxQuaternion.getAttribute("a")),
          Number(boxQuaternion.getAttribute("b")),
          Number(boxQuaternion.getAttribute("c")),
          Number(boxQuaternion.getAttribute("d")),
        );
        // TODO: apply rotation quaternion to the planes (???)

        const normals = [
          new THREE.Vector3(1, 0, 0),
          new THREE.Vector3(0, 1, 0),
          new THREE.Vector3(0, 0, 1),
          new THREE.Vector3(-1, 0, 0),
          new THREE.Vector3(0, -1, 0),
          new THREE.Vector3(0, 0, -1)
        ];

        const bbox = new THREE.Box3(minPt, maxPt);

        normals.forEach((normal, i) => {
          const plane = new THREE.Plane(normal, -1 * maxPt.dot(normal));

          if (i > 2) {
            const ptMax = plane.orthoPoint(bbox.max);
            const ptMin = plane.orthoPoint(bbox.min);
            const size = new THREE.Vector3().subVectors(ptMax, ptMin);
            plane.constant -= size.length();
          }

          const boxCutplane = new THREE.Vector4(plane.normal.x, plane.normal.y, plane.normal.z, plane.constant);
          viewerStateCutplanes.push(boxCutplane.toArray());
        });
      }
    }

    const name = element.getAttribute("name") || "";

    const focal = Number(viewpoint.getAttribute("focal"));
    const linear = Number(viewpoint.getAttribute("linear"));
    const angular = Number(viewpoint.getAttribute("angular"));

    const camera = element.getElementsByTagName("camera")[0];

    const aspect = Number(camera.getAttribute("aspect"));
    const height = Number(camera.getAttribute("height"));
    const projection = camera.getAttribute("projection");
    const isOrthographic = (projection === NavisworksSchema.projectionType.Orthographic);

    const position = camera.getElementsByTagName("pos3f")[0];

    const threePos = new THREE.Vector3(
      Number(position.getAttribute("x")),
      Number(position.getAttribute("y")),
      Number(position.getAttribute("z")),
    );

    const quaternion = camera.getElementsByTagName("quaternion")[0];
    const threeQN = new THREE.Quaternion(
      Number(quaternion.getAttribute("a")),
      Number(quaternion.getAttribute("b")),
      Number(quaternion.getAttribute("c")),
      Number(quaternion.getAttribute("d")),
    );

    const v1 = -2 * (threeQN.x * threeQN.z + threeQN.w * threeQN.y);
    const v2 = -2 * (threeQN.y * threeQN.z - threeQN.w * threeQN.x);
    const v3 = -1 + 2 * (threeQN.x * threeQN.x + threeQN.y * threeQN.y);

    const threeTarget = new THREE.Vector3(
      threePos.x + focal * v1,
      threePos.y + focal * v2,
      threePos.z + focal * v3,
    );

    const offsetPos = threePos.applyMatrix4(this.offsetMatrix);
    const offsetTarget = threeTarget.applyMatrix4(this.offsetMatrix);
    const fieldOfView = height * 180 / Math.PI;

    const u1 = 2 * (threeQN.x * threeQN.y - threeQN.w * threeQN.z);
    const u2 = 1 - 2 * (threeQN.x * threeQN.x + threeQN.z * threeQN.z);
    const u3 = 2 * (threeQN.y * threeQN.z + threeQN.w * threeQN.x);

    const up = new THREE.Vector3(u1, u2, u3);

    const viewerNode = viewpoint.getElementsByTagName("viewer")[0];
    const eye_height = Number(viewerNode.getAttribute("eye_height"));

    const viewerStateViewport: ForgeViewerStateViewport = {
      aspectRatio: aspect,
      isOrthographic: isOrthographic,
      projection: (isOrthographic) ? "orthographic" : "perspective",
      fieldOfView: (isOrthographic) ? height : fieldOfView,
      distanceToOrbit: focal,
      name: name,
      target: offsetTarget.toArray(),
      up: up.toArray(),
      eye: threePos.toArray(),
      pivotPoint: offsetTarget.toArray(),
      worldUpVector: [0, 0, 1],
    };

    if (isOrthographic) {
      viewerStateViewport.orthographicHeight = height;
    }

    const res = this.constructForgeViewpoint(viewerStateViewport, viewerStateCutplanes, sectionMode);
    return res;
  }

  private parseViewFolderNode(element: Element) {
    const views = element.getElementsByTagName("view");
    return Array.from(views).map((node) => {
      const viewpoint = this.parseViewNode(node);
      return { viewpoint };
    });
  }

  private constructForgeViewpoint(viewport: ForgeViewerStateViewport, cutplanes: Array<ForgeViewerStateCutPlanes>, sectionMode?: string | null) {
    const viewerState: ForgeViewerState = {
      seedURN: "",
      objectSet: [
        {
          "id": [],
          "idType": "lmv",
          "isolated": [],
          "hidden": [],
          "explodeScale": 0,
          "explodeOptions": {
            "magnitude": 4,
            "depthDampening": 0
          }
        }
      ],
      cutplanes: cutplanes,
      viewport: viewport,
      sectionMode: sectionMode,
    };
    return viewerState;
  }
}
