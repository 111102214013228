import WebSocketService from "lib/API/socket";
import { TransactionInitiatorInterface, WsBoardMethods, WsFileContainerMethods, WsTaskMethods, WsWorkspaceMethods } from "lib/types/wsTypes";
import configuration from "lib/configs/configuration";
import { createLogger } from "lib/utils/BaseClassLoggable";

export const isCurrentInitiator = (transactionInitiator: TransactionInitiatorInterface) => {
  const initialSocketId = WebSocketService.getSocketId();
  const { socketId } = transactionInitiator;
  const isTheSameInitiator = (initialSocketId === socketId);

  return isTheSameInitiator;
};

const logger = createLogger({ prefix: WebSocketService.LogPrefix });

export const isAllowedTaskUpdateMethod = (transactionInitiator: TransactionInitiatorInterface) => {
  const allowedMethods: Array<string> = [
    WsTaskMethods.createSubtask,
    WsTaskMethods.createSubtasks,
    WsTaskMethods.addComment,
    WsTaskMethods.addViewpoint,
    WsTaskMethods.updateViewpoint,
    WsTaskMethods.addViewpointsBatch,
    WsTaskMethods.reorderList,
    WsTaskMethods.addPropertyTable,
    WsTaskMethods.setPropertyValueByCodeSampleData,
    WsTaskMethods.removeStatusTableValue,
    WsTaskMethods.update,
  ];

  const isAllowedMethod = allowedMethods.includes(transactionInitiator.method);

  if (configuration.app.isDevelopment) {
    logger.debug("isAllowedTaskUpdateMethod:", transactionInitiator.method, "isAllowedMethod:", isAllowedMethod);
  }

  return isAllowedMethod;
};

export const isAllowedBoardUpdateMethod = (transactionInitiator: TransactionInitiatorInterface) => {
  const allowedMethods: Array<string> = [
    WsBoardMethods.create,
    WsBoardMethods.createFrom,

    WsBoardMethods.addStatus,

    WsBoardMethods.addColumn,
    WsBoardMethods.updateColumn,

    WsBoardMethods.addAutomation,

    WsBoardMethods.addRecordTemplate,

    WsBoardMethods.viewsCreate,
  ];

  const isAllowedMethod = allowedMethods.includes(transactionInitiator.method);

  if (configuration.app.isDevelopment) {
    logger.debug("isAllowedBoardUpdateMethod:", transactionInitiator.method, "isAllowedMethod:", isAllowedMethod);
  }

  return isAllowedMethod;
};


export const isAllowedWorkspaceUpdateMethod = (transactionInitiator: TransactionInitiatorInterface) => {
  const allowedMethods: Array<string> = Object.values(WsWorkspaceMethods);

  const isAllowedMethod = allowedMethods.includes(transactionInitiator.method);

  if (configuration.app.isDevelopment) {
    logger.debug("isAllowedWorkspaceUpdateMethod:", transactionInitiator.method, "isAllowedMethod:", isAllowedMethod);
  }

  return isAllowedMethod;
};

// TODO: check available methods listed here
export const isAllowedFileContainerUpdateMethod = (transactionInitiator: TransactionInitiatorInterface) => {
  const allowedMethods: Array<string> = [
    WsFileContainerMethods.create,
    WsFileContainerMethods.update,
    WsFileContainerMethods.delete,

    WsFileContainerMethods.revisionsAdd,
    WsFileContainerMethods.revisionsDelete,

    WsFileContainerMethods.commentsAdd,
    WsFileContainerMethods.commentsEdit,
    WsFileContainerMethods.commentsDelete,
    WsFileContainerMethods.commentsReact,
    WsFileContainerMethods.commentsUnreact,

    WsFileContainerMethods.annotationManager,
    WsFileContainerMethods.annotationTransfer,
    WsFileContainerMethods.unlinkAnnotation,
    WsFileContainerMethods.afterDeleteFromRecord,
  ];

  const isAllowedMethod = allowedMethods.includes(transactionInitiator.method);

  if (configuration.app.isDevelopment) {
    logger.debug("isAllowedFileContainerUpdateMethod:", transactionInitiator.method, "isAllowedMethod:", isAllowedMethod);
  }

  return isAllowedMethod;
};
