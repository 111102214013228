import { useAppSelector } from "app/store";

const useAppDataLoaded = () => {
  const isWorkspacesLoading = useAppSelector(state => state.workspaces.loading);
  const isUserLoaded = useAppSelector(state => !!state.currentUserReducer.user?.id);

  const isAppDataLoaded = !isWorkspacesLoading && isUserLoaded;
  const isAppDataLoading = isWorkspacesLoading && !isUserLoaded;

  return {
    isLoaded: isAppDataLoaded,
    isLoading: isAppDataLoading,
  };
};

export default useAppDataLoaded;
