import { ExportOptionTag } from "../../ExportTaskModal/styled";
import { AutomationsTabsHeading } from "../AutomationsModal/styled";
import { Action, TAutomationActionFieldsInterface } from "../types";

export const AutomationActionFields = (terminology: string): TAutomationActionFieldsInterface[] => ([
  {
    custom: true,
    component: <AutomationsTabsHeading key="update-action">Update {terminology.toLowerCase()}</AutomationsTabsHeading>,
  },
  {
    text: Action.completed,
  },
  {
    text: Action.assignees,
  },
  {
    text: Action.dueDate,
  },
  {
    text: Action.status,
  },
  {
    text: Action.collaborators,
  },
  {
    text: Action.comments,
  },
  {
    custom: true,
    component:
      <div key="procore action" style={{ display: "flex", alignItems: "center" }}>
        <AutomationsTabsHeading style={{ marginTop: "20px" }}>Procore</AutomationsTabsHeading>
        <ExportOptionTag style={{ marginLeft: "5px", marginTop: "14px" }}>Coming…</ExportOptionTag>
      </div>,
  },
  {
    text: Action.createProjectTaskItem,
    disabled: true
  },
  {
    text: Action.createManpowerLog,
    disabled: true
  },
  {
    text: Action.createPunchItem,
    disabled: true
  },
  {
    text: Action.createRFI,
    disabled: true
  },
]);
