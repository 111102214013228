import { useAppSelector } from "app/store";
import { FC, useEffect, useRef, useState } from "react";
import { SavingStateIconWrapper } from "./styled";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { DefaultIconColor } from "components/icons/lib";

const SavingStateIcon: FC = () => {
  const isSaving = useAppSelector(state => state.progress.transactionQueue) !== 0;

  const [isVisible, setIsVisible] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const style: React.CSSProperties = { color: DefaultIconColor, fontSize: 18 };

  useEffect(() => {
    if (!isSaving) {
      timerRef.current = setTimeout(() => setIsVisible(false), 5000);
    } else {
      setIsVisible(true);
    }

    return () => {
      !!timerRef.current && clearTimeout(timerRef.current);
    };
  }, [isSaving]);

  if (!isVisible) {
    return (null);
  }

  return (
    <SavingStateIconWrapper>
      {(isSaving)
        ? (
          <Tooltip title="Saving…">
            <LoadingOutlined style={style} spin />
          </Tooltip>
        )
        : (
          <Tooltip title="Saved to cloud">
            <CheckOutlined style={style} />
          </Tooltip>
        )
      }
    </SavingStateIconWrapper>
  );
};

export default SavingStateIcon;
