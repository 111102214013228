import { FC } from "react";
import { TwoColumnGrid } from "styles/common";
import { DateFieldFormats, TimeFieldFormats } from "./config";
import SelectField from "components/blocks/FormComponents/SelectField";

const DateTimeSettings: FC = () => {
  return (
    <>
      <TwoColumnGrid style={{ marginBottom: 10 }}>
        <div key="1">
          <SelectField
            label="Date format"
            name="dateFormat"
            placeholder="Select date format"
            data={DateFieldFormats}
          />
        </div>
      </TwoColumnGrid>
      <TwoColumnGrid style={{ marginBottom: 10 }}>
        <SelectField
          label="Time format"
          name="timeFormat"
          placeholder="Select time format"
          data={TimeFieldFormats}
          defaultValue={TimeFieldFormats[0].value}
        />
      </TwoColumnGrid>
    </>
  );
};

export default DateTimeSettings;
