import styled from "@emotion/styled/macro";

export const ButtonIconContent = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonIconIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
`;

export const ButtonIconText = styled.div`
  margin-right: 9px;
`;
