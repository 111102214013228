import { FC } from "react";
import { Tooltip } from "antd";
import { SyncedFieldTooltipIcon } from "./styled";

const SyncedFieldTooltip: FC<{ marginLeft?: boolean }> = (props) => {
  return (
    <div style={{ marginLeft: (props?.marginLeft) ? "5px" : 0 }}>
      <Tooltip title="This field is synced from an external source and can't be edited.">
        <SyncedFieldTooltipIcon src={process.env.PUBLIC_URL + "/icons/sync-thin.svg"} alt="view task" />
      </Tooltip>
    </div>
  );
};

export default SyncedFieldTooltip;
