import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import { FilterValueNone } from "lib/filters/filters";
import { calculateTaskProgress } from "lib/helpers/taskUtils";
import { BoardInterface, BoardInterfaceExtended, DefaultColumnCodes } from "lib/types/boardTypes";
import { PropertyValueType, SystemPropertyCode } from "lib/types/dataTypes";
import { SortFieldType } from "lib/types/sortAndFilterTypes";
import { TaskInterface } from "lib/types/tasksTypes";
import { orderBy } from "lodash";

export enum TaskSortDirection {
  Ascending = "Ascending",
  Descending = "Descending",
}

export const DefaultSort = {
  fieldName: FilterValueNone,
  order: TaskSortDirection.Ascending,
};

export type TasksSortType = {
  field: SortFieldType,
  order: TaskSortDirection,
}

export const sortTasks = (tasks: TaskInterface[], sort: TasksSortType) => {
  const order = sort.order === TaskSortDirection.Ascending ? "asc" : "desc";
  const code = sort.field?.code;
  tasks = tasks.map(task => ({
    ...task,
    progress: calculateTaskProgress(task.subtasks),
  }));

  if (tasks?.length) {
    // TODO: refactor custom fields column
    const column = tasks[0]?.propertyValues?.find(pv => pv.code === code);

    if (column && [TaskPropertyClass.Custom, TaskPropertyClass.Synced].includes(column.class)) {
      switch (column.type) {
        case PropertyValueType.Boolean: {
          return orderBy(tasks, `customProperties.${code}`, order);
        }

        case PropertyValueType.Number:
        case PropertyValueType.Currency:
        case PropertyValueType.Percent: {
          return orderBy(tasks, `customProperties.${code}`, order);
        }

        case PropertyValueType.Date:
        case PropertyValueType.DateTime: {
          return orderBy(tasks, `customProperties.${code}`, order);
        }

        case PropertyValueType.Link:
        case PropertyValueType.MultiLineText:
        case PropertyValueType.SingleLineText:
        case PropertyValueType.Url: {
          return orderBy(tasks, `customProperties.${code}`, order);
        }

        case PropertyValueType.SingleSelect: {
          return orderBy(tasks, `customProperties.${code}`, order);
        }

        case PropertyValueType.MultiSelect: {
          return orderBy(tasks, `customProperties.${code}`, order);
        }

        case PropertyValueType.User: {
          return orderBy(tasks, `customProperties.${code}.firstName`, order);
        }

        case PropertyValueType.Users: {
          return orderBy(tasks, `customProperties.${code}`, order);
        }

        default:
          return orderBy(tasks, `customProperties.${code}`, order);
      }
    }
  }

  switch (code) {
    case SystemPropertyCode.CreatedBy: {
      return orderBy(tasks, `${SystemPropertyCode.CreatedBy}.firstName`, order);
    }
    case SystemPropertyCode.LastModifiedBy: {
      return orderBy(tasks, `${SystemPropertyCode.LastModifiedBy}.firstName`, order);
    }
    case SystemPropertyCode.Progress: {
      return orderBy(tasks, "progress", order);
    }
    case "workspaceData": {
      return orderBy(tasks, "workspaceData.title", order);
    }
    case "boardData": {
      return orderBy(tasks, "boardData.title", order);
    }
    case "title": {
      return orderBy(tasks, [task => task.title.toLowerCase()], order);
    }
    default:
      return orderBy(tasks, code, order);
  }
};

export const DEFAULT_SORT_FIELD = {
  title: FilterValueNone,
  code: FilterValueNone
};

export const ProjectSortFields = [
  DEFAULT_SORT_FIELD,
  { title: "Completion", code: DefaultColumnCodes.Completion, sort: 1 },
  { title: "Title", code: DefaultColumnCodes.Title, sort: 2 },
  { title: "Status", code: DefaultColumnCodes.Status, sort: 3 },
  { title: "Assignees", code: DefaultColumnCodes.Assignees, sort: 4 },
  { title: "Due Date", code: DefaultColumnCodes.DueDate, sort: 5 },
  { title: "Created time", code: DefaultColumnCodes.CreatedTime, sort: 6 },
  { title: "Created by", code: DefaultColumnCodes.CreatedBy, sort: 7 },
  { title: "Last modified", code: DefaultColumnCodes.LastModified, sort: 8 },
  { title: "Closing date", code: DefaultColumnCodes.ClosingDate, sort: 9 },
  { title: "Last modified by", code: DefaultColumnCodes.LastModifiedBy, sort: 10 },
  { title: "ID", code: DefaultColumnCodes.ID, sort: 11 }
];

export const MyTasksSortFields = [
  DEFAULT_SORT_FIELD,
  { title: "Completion", code: DefaultColumnCodes.Completion, sort: 1 },
  { title: "Title", code: DefaultColumnCodes.Title, sort: 2 },
  { title: "Status", code: DefaultColumnCodes.Status, sort: 3 },
  { title: "Assignees", code: DefaultColumnCodes.Assignees, sort: 4 },
  { title: "Due Date", code: DefaultColumnCodes.DueDate, sort: 5 },
  { title: "Project", code: "workspaceData", sort: 6 },
  { title: "Board", code: "boardData", sort: 7 },
  { title: "Created time", code: DefaultColumnCodes.CreatedTime, sort: 8 },
  { title: "Last modified", code: DefaultColumnCodes.LastModified, sort: 9 },
  { title: "Closing date", code: DefaultColumnCodes.ClosingDate, sort: 10 },
  { title: "Created by", code: DefaultColumnCodes.CreatedBy, sort: 11 },
  { title: "Last modified by", code: DefaultColumnCodes.LastModifiedBy, sort: 12 },
  { title: "ID", code: DefaultColumnCodes.ID, sort: 13 }
];

const EXCLUDED_FIELDS: Array<DefaultColumnCodes | string> = [
  DefaultColumnCodes.Description,
  DefaultColumnCodes.DescriptionRTE,
  DefaultColumnCodes.Geometry,
  DefaultColumnCodes.Viewpoints,
];

export const getSortFields = (board: BoardInterface | BoardInterfaceExtended | null): SortFieldType[] => {
  return board?.columns.reduce((acc: SortFieldType[], column, index) => {
    if (index === 0) {
      acc.push(DEFAULT_SORT_FIELD);
    }
    if ((EXCLUDED_FIELDS).includes(column.code)) {
      return acc;
    }

    acc.push({
      title: column.title || "",
      code: column.code || "",
      sort: column.sort || 0
    });
    return acc;
  }, []) || [];
};
