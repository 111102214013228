import { AssigneesType } from "pages/Board/BoardKanban/types";
import { BoardInterface } from "./boardTypes";
import { ChangeInterface, CommentInterface, PictureInterface, UserInterface } from "./types";
import { SortInterface, TaskPropertyDateTimeFormat, TaskPropertyOption, TaskPropertyTimeFormat, TaskPropertyValue } from "./kanbanTypes";
import { ForgeViewerState } from "./ForgeViewer";
import { QueryPayload } from "./backend";
import { BaseEntityInterface, EntityId } from "./entity";
import { PropertyValueType } from "./dataTypes";
import { JSendResponse } from "./jsend";
import { TransactionInitiatorInterface } from "./wsTypes";
import { EditorJsSaveFormat } from "components/blocks/EditorJS/lib";
import { TaskPropertyClass } from "components/blocks/TaskTable/constants";
import { AnnotationInterface } from "./fileContainer";

export interface Column {
  id: EntityId;
  title: string;
  code: string;
  sort: number;
  width?: number | string;
  hidden: boolean;
  type: PropertyValueType | string;
  active: boolean;
  class: TaskPropertyClass;
  dateFormat: TaskPropertyDateTimeFormat;
  timeFormat: TaskPropertyTimeFormat;
  precision: number;
  currency: string;
  formula: any;
  options: Array<TaskPropertyOption>;

  accessor: string | any;
  maxWidth: number;
  required?: boolean;
}

export interface EmailNotifications {
  id: EntityId;
  activityUpdates: boolean;
  dailySummaries: boolean;
  mentionsOnly: boolean;
}

export type ViewpointType = {
  id: EntityId;
  uid: string;
  picture: PictureInterface | null;
  viewpoint: string;
  isLoading?: boolean;
};

export interface ViewpointInterfaceLocal {
  picture?: PictureInterface;
  viewpoint: ForgeViewerState;
}

export interface TaskSubtaskInterface {
  _id?: EntityId;
  isLoading?: boolean;
  id: EntityId;
  completed: boolean;
  title: string;
  sort: number;
}

export interface TaskPayloadInterface {
  title: string;
  description?: string;
  assignees?: Array<AssigneesType | number | string>;
  board?: number | string;
  status?: number | string | null;
  dueDate?: Date | string | null;
  completed?: boolean;
  viewpoints?: Array<ViewpointType | ViewpointInterfaceLocal>;
  comments?: Array<Partial<CommentInterface>>;
  /** @deprecated */
  sort?: Partial<SortInterface>;
  sort__list: number | null;
  sort__kanban: number | null;
  collaborators?: Array<UserInterface | number | string>;
  subtasks?: Array<Partial<TaskSubtaskInterface>>;
}

export interface TaskInterface extends BaseEntityInterface {
  completed: boolean;
  title: string;
  /** @deprecated */
  description: string;
  descriptionRich: EditorJsSaveFormat | null;
  assignees: Array<AssigneesType>;
  board: BoardInterface;
  status: EntityId | null;
  dueDate: Date | string | null;
  closingDate: Date;
  startDate: Date;
  owner: UserInterface;
  lastEditor: UserInterface;
  guid: any;
  viewpoints: ViewpointType[];
  annotations: AnnotationInterface[];
  comments: CommentInterface[];
  element: any;
  properties: any;
  /** @deprecated */
  sort: SortInterface;
  changelog: Array<CommentInterface | ChangeInterface>;
  collaborators: Array<UserInterface>;
  propertyValues: Array<TaskPropertyValue>
  customProperties: any;
  subtasks: Array<TaskSubtaskInterface>;
  transactionInitiator: TransactionInitiatorInterface;
  externalId: string;
  estimate: number | null;
  sort__list: number | null;
  sort__kanban: number | null;
}

export type TaskQueryPayload = Partial<TaskInterface> & QueryPayload<TaskInterface>

export type TaskReorderListPayload = Array<{ id: number | string, sort: number }>

export interface TaskViewpointsPayload {
  title: string;
  viewpoints: Array<ViewpointInterfaceLocal>;
}

export enum TaskExportFormats {
  Csv = "csv",
  Xlsx = "xlsx",
  Pdf = "pdf",
  Bcf = "bcf",
  Xml = "xml",
  Viewpoints = "viewpoints.xml",
}

export interface TaskRequestImportOptions {
  updateExisting?: boolean;
}

export interface TaskRequestExportOptions {
  groupBy?: string;
  sortBy?: string;
  filterBy?: Record<string, any>;
  include?: Record<string, boolean>;
  view?: EntityId | null;
}

export interface TaskSetValueProps {
  cellName: keyof TaskInterface | string;
  cellValue: any | never | null;
}

export interface TaskSetValueHandler {
  (props: TaskSetValueProps): Promise<JSendResponse<any> | void> | void;
}

export type TaskOptimisticUpdatePayload = Partial<TaskInterface>
