import React, { FC } from "react";
import { IconProps } from "components/icons/lib";
import IconSvgWrapperNewColor from "components/icons/IconSvgWrapperColor";

const ZipSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapperNewColor {...props}>
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.15" d="M66.4896 20.4357C64.2208 20.4324 62.0457 19.5293 60.4419 17.9246C58.8378 16.3198 57.9357 14.1444 57.9336 11.8755V0H19.7013C16.7398 0 13.8998 1.17639 11.8057 3.27042C9.7117 5.36444 8.53528 8.20461 8.53528 11.166V78.4772C8.53968 81.4357 9.71803 84.2714 11.8116 86.3618C13.9051 88.4523 16.7427 89.6266 19.7013 89.6266H65.5975C68.556 89.6266 71.3938 88.4523 73.4871 86.3618C75.5809 84.2714 76.7589 81.4357 76.7635 78.4772V20.4357H66.4896Z" fill="#FF3E4C"/>
        <path d="M76.7635 20.4357H66.4896C64.2207 20.4324 62.0456 19.5293 60.4419 17.9246C58.8377 16.3198 57.9357 14.1444 57.9336 11.8755V0L76.7635 20.4357Z" fill="#FF3E4C"/>
        <path d="M84.8589 70.7925H32.9751C29.2947 70.7925 26.3112 73.7759 26.3112 77.4564V92.9253C26.3112 96.6058 29.2947 99.5892 32.9751 99.5892H84.8589C88.5394 99.5892 91.5228 96.6058 91.5228 92.9253V77.4564C91.5228 73.7759 88.5394 70.7925 84.8589 70.7925Z" fill="#FF3E4C"/>
        <path d="M43.8334 93.361C43.255 93.361 42.9658 93.0647 42.9658 92.4722C42.9658 92.3734 42.994 92.2639 43.0504 92.144C43.1069 92.0241 43.1915 91.8797 43.3044 91.7104L51.8112 80.5793V80.7909H46.1612C45.7098 80.7909 45.3571 80.8577 45.1031 80.9917C44.8492 81.1257 44.6728 81.3481 44.5741 81.6585C44.5106 81.9195 44.4119 82.1241 44.2778 82.2722C44.1438 82.4133 43.9322 82.4838 43.643 82.4838C43.3467 82.4838 43.1139 82.4025 42.9446 82.2402C42.7824 82.078 42.726 81.849 42.7753 81.5527C42.9446 80.7344 43.3114 80.1066 43.8758 79.6693C44.4471 79.232 45.2089 79.0133 46.1612 79.0133H53.2291C53.8216 79.0133 54.1179 79.3095 54.1179 79.9021C54.1179 80.0149 54.0934 80.1349 54.044 80.2618C53.9946 80.3888 53.9063 80.5369 53.7793 80.7062L45.3782 91.668V91.5834H51.6419C52.1216 91.5834 52.4884 91.5199 52.7424 91.3929C52.9963 91.2589 53.1656 91.0332 53.2502 90.7158C53.3278 90.4477 53.4336 90.2432 53.5677 90.1021C53.7087 89.961 53.9204 89.8905 54.2025 89.8905C54.4988 89.8905 54.7282 89.9714 54.8905 90.1336C55.0527 90.2958 55.1125 90.5253 55.0702 90.8216C54.7245 92.5145 53.5818 93.361 51.6419 93.361H43.8334Z" fill="white"/>
        <path d="M59.3125 93.361C59.0092 93.361 58.7835 93.2905 58.6353 93.1494C58.4942 93.0012 58.4237 92.7755 58.4237 92.4722V79.9232C58.4237 79.6129 58.4942 79.3871 58.6353 79.2461C58.7835 79.105 59.0092 79.0344 59.3125 79.0344C59.6229 79.0344 59.8486 79.105 59.9897 79.2461C60.1308 79.3871 60.2013 79.6129 60.2013 79.9232V92.4722C60.2013 92.7755 60.1308 93.0012 59.9897 93.1494C59.8486 93.2905 59.6229 93.361 59.3125 93.361Z" fill="white"/>
        <path d="M65.4913 93.361C64.8988 93.361 64.6025 93.0647 64.6025 92.4722V79.9021C64.6025 79.3095 64.8988 79.0133 65.4913 79.0133H70.4432C71.7341 79.0133 72.7216 79.3589 73.4059 80.0502C74.0971 80.7344 74.4428 81.722 74.4428 83.0129V83.6477C74.4428 84.9386 74.0971 85.9295 73.4059 86.6207C72.7216 87.305 71.7341 87.6473 70.4432 87.6473H66.3801V92.4722C66.3801 93.0647 66.0839 93.361 65.4913 93.361ZM66.3801 85.8697H70.4432C71.2474 85.8697 71.8187 85.7004 72.1573 85.3618C72.4959 85.0232 72.6652 84.4519 72.6652 83.6477V83.0129C72.6652 82.2158 72.4959 81.6477 72.1573 81.3091C71.8187 80.9635 71.2474 80.7909 70.4432 80.7909H66.3801V85.8697Z" fill="white"/>
        <path d="M42.6431 63.2863C41.2477 63.2842 39.9101 62.7286 38.9237 61.7415C37.9374 60.7544 37.3828 59.4162 37.3817 58.0207V53.5851C37.3817 53.1266 37.7533 52.7552 38.2116 52.7552H47.0788C47.5372 52.7552 47.9087 53.1266 47.9087 53.5851V58.0207C47.9076 59.417 47.3525 60.7556 46.3652 61.7427C45.378 62.7303 44.0393 63.2851 42.6431 63.2863ZM40.9045 55.4481C40.4462 55.4481 40.0747 55.8195 40.0747 56.278V58.0207C40.0747 58.7025 40.3451 59.356 40.8267 59.8386C41.3083 60.3207 41.9615 60.5921 42.6431 60.5934C43.3251 60.5921 43.9788 60.3207 44.461 59.8386C44.9433 59.3564 45.2146 58.7025 45.2157 58.0207V56.278C45.2157 55.8195 44.8442 55.4481 44.3859 55.4481H40.9045Z" fill="#FF3E4C"/>
        <path d="M38.6224 51.9295H41.4108C42.0982 51.9295 42.6556 51.3722 42.6556 50.6847V47.8963C42.6556 47.2087 42.0982 46.6515 41.4108 46.6515H38.6224C37.9349 46.6515 37.3776 47.2087 37.3776 47.8963V50.6847C37.3776 51.3722 37.9349 51.9295 38.6224 51.9295Z" fill="#FF3E4C"/>
        <path d="M43.8879 46.6556H46.6763C47.3637 46.6556 47.9211 46.0983 47.9211 45.4108V42.6224C47.9211 41.9349 47.3637 41.3776 46.6763 41.3776H43.8879C43.2004 41.3776 42.6431 41.9349 42.6431 42.6224V45.4108C42.6431 46.0983 43.2004 46.6556 43.8879 46.6556Z" fill="#FF3E4C"/>
        <path d="M38.6224 41.3818H41.4108C42.0982 41.3818 42.6556 40.8244 42.6556 40.1369V37.3486C42.6556 36.6611 42.0982 36.1037 41.4108 36.1037H38.6224C37.9349 36.1037 37.3776 36.6611 37.3776 37.3486V40.1369C37.3776 40.8244 37.9349 41.3818 38.6224 41.3818Z" fill="#FF3E4C"/>
        <path d="M43.8879 36.1079H46.6763C47.3637 36.1079 47.9211 35.5505 47.9211 34.8631V32.0747C47.9211 31.3872 47.3637 30.8299 46.6763 30.8299H43.8879C43.2004 30.8299 42.6431 31.3872 42.6431 32.0747V34.8631C42.6431 35.5505 43.2004 36.1079 43.8879 36.1079Z" fill="#FF3E4C"/>
      </svg>
    </IconSvgWrapperNewColor>
  );
};

export default ZipSvg;
