import { FC } from "react";
import IconSvgWrapper from "./IconSvgWrapper";
import { IconProps } from "./lib";

const DueDatesSvg: FC<IconProps> = (props) => {
  return (
    <IconSvgWrapper {...props}>
      <path d="M0.5 2.8C0.5 2.35817 0.858172 2 1.3 2C1.74183 2 2.1 2.35817 2.1 2.8V11.2C2.1 11.6418 1.74183 12 1.3 12C0.858172 12 0.5 11.6418 0.5 11.2V2.8Z"/>
      <path d="M2.3 0.9C2.3 0.402944 2.70294 0 3.2 0C3.69706 0 4.1 0.402944 4.1 0.9V2H2.3V0.9Z"/>
      <path d="M3.1 6.2C3.1 5.86863 3.36863 5.6 3.7 5.6H5.3C5.63137 5.6 5.9 5.86863 5.9 6.2V7.8C5.9 8.13137 5.63137 8.4 5.3 8.4H3.7C3.36863 8.4 3.1 8.13137 3.1 7.8V6.2Z"/>
      <path d="M7.9 0.9C7.9 0.402944 8.30294 0 8.8 0C9.29706 0 9.7 0.402944 9.7 0.9V2H7.9V0.9Z"/>
      <path d="M0.5 11.2C0.5 10.7582 0.858172 10.4 1.3 10.4H10.7C11.1418 10.4 11.5 10.7582 11.5 11.2C11.5 11.6418 11.1418 12 10.7 12H1.3C0.858172 12 0.5 11.6418 0.5 11.2Z"/>
      <path d="M0.5 2.9C0.5 2.40294 0.902944 2 1.4 2H10.6C11.0971 2 11.5 2.40294 11.5 2.9V3.7C11.5 4.19706 11.0971 4.6 10.6 4.6H1.4C0.902944 4.6 0.5 4.19706 0.5 3.7V2.9Z"/>
      <path d="M9.9 2.8C9.9 2.35817 10.2582 2 10.7 2C11.1418 2 11.5 2.35817 11.5 2.8V11.2C11.5 11.6418 11.1418 12 10.7 12C10.2582 12 9.9 11.6418 9.9 11.2V2.8Z"/>
    </IconSvgWrapper>
  );
};

export default DueDatesSvg;
