import { FC, useState } from "react";
import { Dropdown, Menu } from "antd";
import AutomationsSvg from "components/ui/ButtonControls/icons/automations";
import { DefaultIconColor, IconProps } from "components/icons/lib";
import ButtonControls from "components/ui/ButtonControls";
import MenuItemIcon from "components/ui/MenuItemIcon";
import DrawerCollectionBar from "./DrawerCollectionBar";
import RecordTemplateItem from "components/blocks/RecordTemplates/RecordTemplateDrawer/RecordTemplateItem";
import AutomationItem from "components/blocks/Automations/AutomationsDrawer/AutomationItem";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import { ModalComponentProps } from "lib/types/components";
import RecordTemplateModal from "components/blocks/RecordTemplates/RecordTemplateDrawer/RecordTemplateModal";
import AutomationsModal from "components/blocks/Automations/AutomationsModal";
import CustomizeSvg from "components/icons/resizable/customize";
import TemplatesSvg from "components/icons/resizable/tempaltes";

enum CollectionDrawer {
  Templates = "templates",
  Automations = "automations",
}

interface CustomizeItem {
  code: CollectionDrawer;
  icon: FC<IconProps>;
  title: string;
  collectionKey: "automations" | "recordTemplates";
  itemComponent: FC<{ item: any }>;
  modalComponent: FC<ModalComponentProps>;
}

const Customize: FC = () => {
  const { board } = useBoardContext() as BoardContextInterface;
  const [activeItem, setActiveItem] = useState<CustomizeItem | null>(null);
  const items: Array<CustomizeItem> = [
    {
      code: CollectionDrawer.Templates,
      icon: TemplatesSvg,
      title: "Templates",
      collectionKey: "recordTemplates",
      itemComponent: RecordTemplateItem,
      modalComponent: RecordTemplateModal,
    },
    {
      code: CollectionDrawer.Automations,
      icon: AutomationsSvg,
      title: "Automations",
      collectionKey: "automations",
      itemComponent: AutomationItem,
      modalComponent: AutomationsModal,
    },
  ];

  const contextMenuOverlay = (
    <Menu>
      {items.map(item => (
        <Menu.Item key={item.code} onClick={() => setActiveItem(item)}>
          <MenuItemIcon text={item.title} icon={<item.icon size={12} />} />
        </Menu.Item>
      ))}
    </Menu >
  );

  return (
    <>
      <Dropdown
        overlay={contextMenuOverlay}
        trigger={["click"]}
        placement="bottomRight"
      >
        <ButtonControls
          text="Customize"
          icon={<CustomizeSvg color={DefaultIconColor} />}
        />
      </Dropdown>

      {activeItem &&
        <DrawerCollectionBar
          title={activeItem.title}
          items={board[activeItem?.collectionKey]}
          renderItem={item => (
            <activeItem.itemComponent key={item.id} item={item} />
          )}
          modal={activeItem.modalComponent}
          visible={!!activeItem}
          onClose={() => setActiveItem(null)}
        />
      }
    </>
  );
};

export default Customize;
