import React, { FC, RefObject, useState } from "react";
import { ColumnInstance } from "react-table";
import { Dropdown, Menu, Tooltip } from "antd";

import { useAppDispatch } from "app/store";
import { setSortField, setSortOrder } from "app/slices/tasksFilterAndSortSlice";
import TaskFieldIcon from "components/blocks/TaskFields/FieldIcons";
import TaskFieldsModal from "components/blocks/TaskFieldsModal";
import { TableElementType } from "components/blocks/TaskTable/constants";
import { BoardContextInterface, useBoardContext } from "lib/contexts/BoardContext";
import { useBoardViewContext } from "lib/contexts/BoardViewContext";
import useBoardSetColumns from "lib/customHooks/dataLayer/board/useBoardSetColumns";
import useMemberPermission from "lib/customHooks/useMemberPermission";
import useBoardViewColumns from "lib/customHooks/views/useBoardViewColumns";
import { ColumnTypeDisplayMessages } from "lib/helpers/ColumnTypeDisplayMessages";
import { TaskSortDirection } from "lib/sort/tasksSort";
import { DefaultColumnCodes } from "lib/types/boardTypes";
import { PropertyValueType } from "lib/types/dataTypes";
import { SortFieldType } from "lib/types/sortAndFilterTypes";
import { Column } from "lib/types/tasksTypes";
import { MemberRoles } from "lib/types/types";
import { DropdownIcon, DropdownWrapper } from "styles/common";

import { Resizer } from "../../styled";
import menuConfig from "./libs/menuConfig";
import { MenuDivider, SvgSelectWrapper, TableHeaderTitle, Th, ThContent, } from "./styled";

interface TableHeaderProps {
  column: ColumnInstance<Column>;
  lite: boolean;
  headerRef: RefObject<any>;
  isResize?: boolean;
}

const TableHeader: FC<TableHeaderProps> = (props) => {
  const {
    column: {
      id,
      type,
      title,
      code,
      getResizerProps,
      getHeaderProps,
      class: columnClass,
      sort,
    },
    lite,
    headerRef,
    isResize,
  } = props;

  const dispatch = useAppDispatch();
  const { board } = useBoardContext() as BoardContextInterface;
  const {
    updateBoardColumn,
    deleteBoardColumn,
    deactivateBoardColumn,
    reorderBoardColumns,
  } = useBoardSetColumns({ board, updateDetailed: true });

  const allowedToEdit = useMemberPermission([
    MemberRoles.Owner,
    MemberRoles.Admin,
  ]);

  const [isTaskFieldsModalVisible, setTaskFieldsModalVisible] = useState(false);
  const onEditClick = () => setTaskFieldsModalVisible(true);

  const columnsFromBoard = board?.columns?.filter(column => {
    return (column.active && ![DefaultColumnCodes.Completion, DefaultColumnCodes.Description].includes(column.code as DefaultColumnCodes));
  });

  const { appliedColumns, saveViewColumns, toggleHidden } = useBoardViewColumns(
    board?.id,
    columnsFromBoard || []
  );
  const { isCustomView } = useBoardViewContext();

  const onHideClick = () => {
    isCustomView
      ? toggleHidden(id, true)
      : updateBoardColumn(id, { hidden: true });
  };

  const onAscending = (field: SortFieldType) => {
    dispatch(setSortField(field));
    dispatch(setSortOrder(TaskSortDirection.Ascending));
  };

  const onDescending = (field: SortFieldType) => {
    dispatch(setSortField(field));
    dispatch(setSortOrder(TaskSortDirection.Descending));
  };

  const onColumnDeactivate = () => {
    deactivateBoardColumn(code);
  };

  const onColumnDelete = () => {
    deleteBoardColumn(id);
  };

  const moveColumn = (direction: "left" | "right") => {
    const movingCol = appliedColumns.find((item) => item.id === id);

    if (!movingCol) {
      return;
    }

    const sortedAppliedColumns = [...appliedColumns.sort((a, b) => a.sort - b.sort)];

    const movingColIdx = sortedAppliedColumns.findIndex((item) => item.id === id);
    let nextIndex = (direction === "left") ? movingColIdx - 1 : movingColIdx + 1;

    while (nextIndex >= 0 && nextIndex < appliedColumns.length && appliedColumns[nextIndex].hidden) {
      nextIndex += (direction === "left") ? -1 : 1;
    }

    sortedAppliedColumns[movingColIdx] = sortedAppliedColumns[nextIndex];
    sortedAppliedColumns[nextIndex] = movingCol;

    const updatedArr = sortedAppliedColumns.map((col, idx) => ({ ...col, sort: idx }));

    if (isCustomView) {
      saveViewColumns(updatedArr);
    } else {
      reorderBoardColumns(updatedArr);
    }
  };

  const onMoveColumnLeft = () => {
    moveColumn("left");
  };

  const onMoveColumnRight = () => {
    moveColumn("right");
  };

  const isAllowedToMove = (direction: "left" | "right") => {
    if (!allowedToEdit) {
      return false;
    }

    const visibleColumns = appliedColumns.sort((a, b) => a.sort - b.sort).filter(a => !a.hidden);
    const colIndex = visibleColumns.findIndex(a => a.sort === sort);

    return !((direction === "left" && colIndex <= 1) ||
      (direction === "right" && (colIndex === 0 || colIndex >= visibleColumns.length - 1)));
  };

  if (!lite && allowedToEdit && type === TableElementType.addBtn) {
    return (
      <Th {...getHeaderProps()} isNoSelect>
        <TaskFieldsModal />
      </Th>
    );
  }

  const headerMenu = (
    <Menu>
      {menuConfig({
        boardId: board?.id,
        id,
        columnClass,
        code,
        lite,
        allowedToEdit,
        onEditClick,
        onHideClick,
        onAscending,
        onDescending,
        deleteColumnFn: onColumnDelete,
        deactivateColumnFn: onColumnDeactivate,
        moveColumnLeft: isAllowedToMove("left") ? onMoveColumnLeft : undefined,
        moveColumnRight: isAllowedToMove("right") ? onMoveColumnRight : undefined,
      })
        .map((menuItem, key) => (
          <React.Fragment key={key}>
            <Menu.Item
              style={{ width: "100%" }}
              key={key}
              onClick={() => menuItem.onClick({ title, code })}
              disabled={menuItem.disabled}
            >
              <DropdownWrapper>
                <DropdownIcon src={process.env.PUBLIC_URL + menuItem.icon} alt={menuItem.code} />
                {menuItem.label}
              </DropdownWrapper>
            </Menu.Item>
            {menuItem.withDivider && <MenuDivider />}
          </React.Fragment>
        ))
      }
    </Menu>
  );

  return (
    <Th {...getHeaderProps()} isNoSelect>
      <Dropdown
        overlay={headerMenu}
        trigger={["click", "contextMenu"]}
        getPopupContainer={(target) => (headerRef?.current || target.parentElement) as HTMLElement}
      >
        <ThContent>
          <Tooltip title={ColumnTypeDisplayMessages(type, columnClass)} placement="top">
            <SvgSelectWrapper>
              <TaskFieldIcon propertyValueType={type as PropertyValueType} color="#a7a8a9" />
            </SvgSelectWrapper>
          </Tooltip>
          <Tooltip title="" placement="top">
            <TableHeaderTitle>{title}</TableHeaderTitle>
          </Tooltip>
        </ThContent>
      </Dropdown>
      {isTaskFieldsModalVisible &&
        <TaskFieldsModal
          columnData={props.column}
          isVisible={isTaskFieldsModalVisible}
          setVisibility={setTaskFieldsModalVisible}
          noChildren
        />
      }
      {isResize && <Resizer {...getResizerProps()} />}
    </Th>
  );
};

export default TableHeader;
