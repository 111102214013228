import { FormResponseInterface } from "lib/types/form";
import React from "react";

interface TaskFormContextProps {
  form: FormResponseInterface | null;
}

const TaskFormContext = React.createContext<TaskFormContextProps>({
  form: null
});

export const useTaskFormContext = () => React.useContext(TaskFormContext);

export const TaskFormProvider: React.FC<TaskFormContextProps> = (props) => {
  return (
    <TaskFormContext.Provider value={{ ...props }}>
      {props.children}
    </TaskFormContext.Provider>
  );
};
