import { FormInstance } from "antd";
import React, { FC } from "react";
import { TaskFieldInterface } from "../FieldsConfig";
import { TaskFieldsModalFormProps } from "../lib";
import { FieldAdditionalContainer, FieldAdditionalSettings, FieldAdditionalDescription } from "./styled";

interface FieldAdditionalProps {
  data: Partial<TaskFieldInterface>;
  form: FormInstance<TaskFieldsModalFormProps>
}

const FieldAdditional: FC<FieldAdditionalProps> = (props) => {
  const { data, form } = props;

  return (
    <FieldAdditionalContainer>
      <FieldAdditionalDescription>
        {data.comment}
      </FieldAdditionalDescription>
      <FieldAdditionalSettings>
        {data.settings && React.cloneElement(data.settings, { form })}
      </FieldAdditionalSettings>
    </FieldAdditionalContainer>
  );
};

export default FieldAdditional;
