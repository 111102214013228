import { DateFormats } from "lib/theme/lib";
import { TaskPropertyDateTimeFormat } from "./kanbanTypes";

export const DateFormatsMap = {
  [TaskPropertyDateTimeFormat.Default]: DateFormats.Default,
  [TaskPropertyDateTimeFormat.Friendly]: DateFormats.Friendly,
  [TaskPropertyDateTimeFormat.Usa]: DateFormats.US,
  [TaskPropertyDateTimeFormat.Europe]: DateFormats.Europe,
  [TaskPropertyDateTimeFormat.Iso]: DateFormats.ISO,
};
