import { EntityId } from "lib/types/entity";
import { TaskInterface } from "lib/types/tasksTypes";

export const ADD_CARD = "ADD_CARD";

export interface AssigneesType {
  id: EntityId;
  email: string;
  firstName?: string;
  lastName?: any;
  picture?: any;
}

export type CardType = TaskInterface & {
  id: string;
  onDelete?: any;
  onChange?: any;
  laneId?: EntityId;
  isDetailsVisible?: any;
  handleDetailsVisibility?: any;
};
